<template>
    <div class="signalo-playlist-player-container">
        <div class="playlist-menu">
            <SignaloPlaylistPlayerMenu
                ref="SignaloPlaylistPlayerMenuRef"
                :sections="playlist"
                @itemSelected="onAssetSelected"
                :activeId="`${selectedSectionIndex}-${selectedItem?.id}`"
            />
        </div>
        <div class="asset-preview">
            <SignaloPageLoading v-if="assetLoading" />
            <SignaloVideoPlayer
                v-else-if="selectedItem?.type === 'video'"
                :video="selectedItem"
                :key="selectedItem?.id"
                @ended="onVideoEndedHandler"
                :autoplay="
                    !(
                        this.$refs.SignaloPlaylistPlayerMenuRef
                            ?.sectionIndex === 0 &&
                        this.$refs.SignaloPlaylistPlayerMenuRef?.assetIndex ===
                            0
                    )
                "
            />
            <SignaloWopiDocumentPreview
                v-else-if="selectedItem?.type === 'document'"
                :documentUrl="selectedItem?.wopi?.url"
                @close="onPreviewClose"
                :documentName="selectedItem?.name"
                :hideCloseButton="true"
            />
            <img
                ref="assetPreviewImage"
                v-else-if="selectedItem?.type === 'image'"
                :class="
                    imageLongerSide === 'heigth'
                        ? 'asset-preview-image longer-height'
                        : 'asset-preview-image longer-width'
                "
                @click="() => (imagePreview = true)"
                :src="selectedItem?.url"
                @load="onImageLoaded"
            />
            <SignaloImagePreview
                v-if="imagePreview"
                :url="selectedItem?.url"
                :open="imagePreview"
                @close="imagePreview = false"
                :allowDraw="false"
            />
        </div>
    </div>
</template>

<script>
import SignaloPlaylistPlayerMenu from './SignaloPlaylistPlayerMenu.vue';
import SignaloVideoPlayer from './SignaloVideoPlayer.vue';

export default {
    props: ['playlist'],
    components: { SignaloPlaylistPlayerMenu, SignaloVideoPlayer },
    data() {
        return {
            selectedItem: null,
            selectedSectionIndex: null,
            imagePreview: false,
            imageLongerSide: null,
            assetLoading: false,
        };
    },
    methods: {
        onImageLoaded() {
            if (!this.$refs?.assetPreviewImage) return;
            this.imageLongerSide =
                this.$refs.assetPreviewImage.clientWidth >
                this.$refs.assetPreviewImage.clientHeight
                    ? 'width'
                    : 'heigth';
        },
        onAssetSelected(asset, sectionIndex) {
            if (!asset || !asset?.id) return;
            this.selectedSectionIndex = sectionIndex;
            this.loadAsset(asset.id);
        },
        async loadAsset(id) {
            try {
                this.assetLoading = true;
                const data = await this.axios.get(`files/${id}`);
                this.selectedItem = data?.data?.item;
            } catch (err) {
                console.error(err);
            } finally {
                this.assetLoading = false;
            }
        },
        onVideoEndedHandler() {
            if (
                typeof this.$refs?.SignaloPlaylistPlayerMenuRef?.next ===
                'function'
            ) {
                this.$refs.SignaloPlaylistPlayerMenuRef.next();
            }
        },
    },
};
</script>

<style>
.signalo-playlist-player-container {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
}

.asset-preview-image {
    cursor: pointer;
}

.longer-height {
    height: 100%;
}

.longer-width {
    width: 100%;
}
.asset-preview {
    width: 100%;
    min-width: 300px;
}
.playlist-menu {
    padding: 1rem 0;
    background: var(--greyscale-30);
}
@media screen and (orientation: portrait) {
    .asset-preview {
        max-height: 90vh;
        height: 90vh;
    }

    .longer-height {
        width: 100%;
    }
}

@media screen and (orientation: landscape) {
    .asset-preview {
        max-height: 80vh;
        height: 80vh;
        position: relative;
    }
}

@media screen and (max-width: 600px) {
    .asset-preview {
        width: 100%;
    }
    .playlist-menu {
        width: 100%;
        padding-left: 0;
        margin-top: 0.5rem;
    }
    .signalo-playlist-player-container {
        flex-direction: column;
        max-height: unset;
    }
}

.asset-preview .wopi-document-preview-container {
    height: 100%;
}
</style>
