<template>
    <SignaloAppBar
        search
        v-model:searchOpen="searchOpen"
        :searchFieldCount="searchFieldCount"
    >
        <template #title>
            {{ $t('dashboard.components.myIssues.history') }}
        </template>
        <template #bar>
            <v-btn
                @click="() => loadTable(lastSearch)"
                class="submain-button renew-button"
            >
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloSearchContainer
            v-model:open="searchOpen"
            :config="searchConfig"
            @search="loadTable"
            v-model:fieldCount="searchFieldCount"
        />
        <SignaloPageLoading v-if="loading" />

        <EasyDataTable buttons-pagination
            v-else
            :headers="tableHeaders"
            :items="items"
            :search-value="searchText"
            :empty-message="$t('common.emptyTableMessage')"
            @click-row="handleClickRow"
            table-class-name="easy-table-style"
            sort-by="created_at"
            sort-type="desc"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
        >
            <template #item-title="item">
                <p class="issue-title">{{ item.title }}</p>
            </template>
            <template #item-equipment_id="item">
                {{ item?.equipment?.name ?? '-' }}
            </template>
            <template #item-equipment_symbol="item">
                {{ item?.equipment?.symbol ?? '-' }}
            </template>
            <template #item-kind="item">
                {{ $getDictionaryItemName('issue_kind', item?.kind) || '-' }}
            </template>
            <template #item-prod_line="item">
                {{
                    $getDictionaryItemName('prod_lines', item?.prod_line) || '-'
                }}
            </template>
            <template #item-category_id="item">
                <div class="category-name-template">
                    <div
                        class="category-color"
                        v-if="getCategoryColor(item.category_id)"
                        :style="`background: ${getCategoryColor(
                            item.category_id
                        )}; height: 18px; width: 18px; min-width: 18px; border-radius:50%; margin-top: auto; margin-bottom: auto; margin-right: 4px;`"
                    ></div>
                    <v-icon
                        class="category-icon"
                        v-if="!!getCategoryIcon(item.category_id)"
                        v-bind:key="getCategoryIcon(item.category_id)"
                    >
                        {{ getCategoryIcon(item.category_id) }}
                    </v-icon>
                    <div>
                        {{
                            $getPropertyTranslation(
                                dataStore.categories.getById(item.category_id)
                                    ?.name
                            )
                        }}
                    </div>
                </div>
            </template>
            <template #item-criticality="item">
                {{
                    issue_criticality.find((x) => x.keyname == item.criticality)
                        ?.keyname
                }}
                <span
                    v-if="
                        issue_criticality.find(
                            (x) => x.keyname == item.criticality
                        )?.keyname
                    "
                    >({{
                        issue_criticality.find(
                            (x) => x.keyname == item.criticality
                        )?.value
                    }})</span
                >
            </template>
            <template #item-department="item">
                {{
                    this.departments?.find((x) => x.keyname === item.department)
                        ?.value ?? '-'
                }}
            </template>
            <template #item-flow_state_name="item">
                {{ $getPropertyTranslation(item.flow_state?.name) ?? '-' }}
            </template>
            <template #item-created_by="item">
                {{ item?.created_by_user?.name }}
            </template>
            <template #item-formal_confirm_at="item">
                <v-icon v-if="item.formal_confirm_at"> mdi-check </v-icon>
                <v-badge
                    v-else-if="
                        item.formal_confirm_required && item.flow_state_finished
                    "
                    color="error"
                    :content="$t('issues.formalConfirmationRequired')"
                    inline
                ></v-badge>
            </template>
            <template #item-scheduled_on="item">
                {{
                    isPlannedCategory(item?.category_id)
                        ? item?.scheduled_on
                        : '-'
                }}
            </template>
            <template #item-flags="item">
                <SignaloTableItemFlagsComponent
                    :flags="item.flags"
                    :key="item.id + JSON.stringify(item.flags)"
                />
            </template>
            <template #item-responsible_user="item">
                {{ item?.responsible_user?.name }}
            </template>
        </EasyDataTable>
    </v-main>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';
// import FLAG_TYPES from '@/CONSTANTS/FLAG_TYPES';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: false,
            items: [],
            searchOpen: false,
            searchFieldCount: 0,
            lastSearch: null,
            COMMON_TABLE_HEADERS_TABLE_NAME: 'issuesIndexTable',
        };
    },
    computed: {
        departments() {
            return Object.values(
                this.dataStore.dictionary.group('departments')
            );
        },
        issue_criticality() {
            return this.dataStore.dictionary.group('issue_criticality');
        },
        tableHeaders() {
            let ths = this.$getPreferredTableColumns(
                this.COMMON_TABLE_HEADERS_TABLE_NAME
            );
            ths.unshift({ value: 'flags', fixed: true, width: 8 });
            return ths.filter((th) => th.value !== 'responsible_user');
        },
        searchConfig() {
            return [
                {
                    id_equals: {
                        type: 'text',
                        label: this.$t('searchConfig.id'),
                        cols: 2,
                    },
                    category_path: { 
                        type: 'category-picker',
                        label: this.$t('searchConfig.category'),
                        cols: 2,
                        bind: { returns: 'path' },
                    },
                    equipment_id_equals: {
                        type: 'equipment-picker',
                        label: this.$t('searchConfig.object'),
                        cols: 4,
                        bind: {
                            kind: 'machine',
                        },
                    },
                    criticality: {
                        type: 'dictionary:issue_criticality',
                        label: this.$t('searchConfig.criticality'),
                        cols: 3,
                    },
                    prod_line: {
                        type: 'dictionary:prod_lines',
                        cols: 3,
                        label: this.$t('common.productionLine') + '...',
                    },
                    // index_id: {
                    //     type: SEARCH_FIELD_TYPES.INDEX,
                    //     label: this.$t('common.index'),
                    //     cols: 3,
                    // },
                    // flag_id_wi: {
                    //     type: SEARCH_FIELD_TYPES.FLAGS || 'flags',
                    //     label: this.$t('common.flags'),
                    //     cols: 3,
                    //     flag_type: FLAG_TYPES.ISSUE,
                    // },
                },
            ];
        },
    },
    created() {
        this.loading = true;
        Promise.allSettled([
            this.dataStore.dictionary.promise(),
            this.dataStore.categories.promise(),
        ]).then(() => {
            this.loading = false;
            this.loadTable();
        });
    },
    methods: {
        loadTable(search = null) {
            if (search !== null) {
                this.lastSearch = search;
            }
            this.loading = true;
            this.axios
                .get('/me/issues_history', {
                    params: {
                        ...this.lastSearch,
                    },
                })
                .then((x) => {
                    this.items = Object.values(x.data.items);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleClickRow(row) {
            this.$router.push({ name: 'issues.show', params: { id: row.id } });
        },
        getCategoryIcon(categoryId) {
            return this.dataStore.categories.find((c) => c.id === categoryId)
                ?.icon;
        },
        getCategoryColor(categoryId) {
            return this.dataStore.categories.find((c) => c.id === categoryId)
                ?.color;
        },
        isPlannedCategory(id) {
            return !!this.dataStore.categories.getById(id)?.planned;
        },
    },
};
</script>
<style>
.category-icon {
    margin-right: 8px;
}
.category-name-template {
    display: flex;
    align-items: center;
}
</style>
