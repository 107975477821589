export default Object.freeze({
    TEXT: 'TEXT',
    LONGTEXT: 'LONGTEXT',
    NUMBER: 'NUMBER',
    RATING: 'RATING',
    CHECK: 'CHECK',
    RADIO: 'RADIO',
    PHOTO: 'PHOTO',
    SIGNATURE: 'SIGNATURE',
    DOCUMENT: 'DOCUMENT'
});
