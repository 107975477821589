<template>
    <EasyDataTable buttons-pagination
        :headers="tableHeaders"
        :items="items"
        :empty-message="$t('common.emptyTableMessage')"
        table-class-name="easy-table-style"
        :rows-per-page-message="$t('common.rowsPerPage')"
        :rows-of-page-separator-message="$t('common.rowsOfPage')"
        :rows-items="[5, 10, 25, 50, 75, 100]"
        name="surveysTextAnswersTable"
    >
        <template #item-user="item"> {{ item?.user?.name }} </template>
        <template #item-answer="item">
            <SurveyImageAnswer
                v-if="field.type === $QUESTION_TYPES_ENUM.PHOTO"
                :field="{answer: item.answers}"
                :disabled="true"
            />
            <div v-else>{{ item?.value || '-' }}</div>
        </template>
    </EasyDataTable>
</template>

<script>
import SurveyImageAnswer from '../SurveyAnswers/SurveyImageAnswer.vue';

export default {
    props: ['usersAnswers', 'field'],
    data() {
        return {
            items: this.usersAnswers || [],
        };
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('common.user'),
                    value: 'user',
                },
                {
                    text: this.$t('cluer.answer'),
                    value: 'answer',
                },
            ];
        },
    },
    components: { SurveyImageAnswer }
};
</script>
