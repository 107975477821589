<template>
    <SignaloAppBar
        search
        v-model:searchOpen="searchOpen"
        :searchFieldCount="searchFieldCount"
    >
        <template #title v-if="!helpersStore.isMobileView">
            {{ $t('maintenance.maintenance') }}
        </template>
        <template #bar>
            <div class="calendar-actions-container">
                <v-btn-toggle
                    v-if="!helpersStore.isMobileView"
                    v-model="calendarViewOption"
                    color="primary"
                    @update:modelValue="onCalendarViewChange"
                    mandatory
                >
                    <v-btn value="dayGridMonth">
                        {{ $t('common.month') }}
                    </v-btn>
                    <v-btn value="timeGridWeek">{{ $t('common.week') }}</v-btn>
                    <v-btn value="timeGridDay">{{ $t('common.day') }}</v-btn>
                </v-btn-toggle>

                <div class="change-week-container">
                    <v-btn
                        icon
                        color="primary"
                        variant="elevated"
                        @click="prev"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn color="primary" variant="elevated" @click="today">{{
                        $t('common.today')
                    }}</v-btn>
                    <v-btn
                        icon
                        color="primary"
                        variant="elevated"
                        @click="next"
                    >
                        <v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                </div>
            </div>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloSearchContainer
            v-model:open="searchOpen"
            :config="searchConfig"
            @search="() => loadTable"
            v-model:fieldCount="searchFieldCount"
        />
        <SignaloBreadCrumbs />
        <div class="page-calendar-container">
            <div
                class="calendar-actions-container mobile-calendar-actions-container"
                v-if="helpersStore.isMobileView"
            >
                <v-btn-toggle
                    v-model="calendarViewOption"
                    color="primary"
                    @update:modelValue="onCalendarViewChange"
                    mandatory
                >
                    <v-btn value="dayGridMonth">
                        {{ $t('common.month') }}
                    </v-btn>
                    <v-btn value="timeGridWeek">{{ $t('common.week') }}</v-btn>
                    <v-btn value="timeGridDay">{{ $t('common.day') }}</v-btn>
                </v-btn-toggle>
            </div>
            <PageLoading v-if="loading" />
            <FullCalendar
                ref="calendar"
                :options="calendarOptions"
                class="calendar"
            >
                <template v-slot:eventContent="eventInfo">
                    <SingleEventInfo
                        v-if="!eventInfo.event.extendedProps.group"
                        :eventInfo="eventInfo"
                    />
                    <GroupEventsInfo
                        v-else
                        :events="eventInfo.event.extendedProps.events"
                        :ctx="this"
                        @groupEventClick="groupEventClicked"
                    /> </template
            ></FullCalendar>
        </div>
        <div
            class="issue-preview-container"
            :style="previewIssueId !== null ? 'right:0' : 'right:-300px'"
        >
            <IssuePreview
                v-if="previewIssueId !== null"
                :previewIssueId="previewIssueId"
                :previewEvents="previewEvents"
                @close="
                    () => {
                        previewIssueId = null;
                        previewEvents = [];
                    }
                "
            />
        </div>
    </v-main>
</template>

<script async>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction';

import allLocales from '@fullcalendar/core/locales-all';

import IssuePreview from '@/components/IssuePreview.vue';
import SingleEventInfo from './SingleEventInfo.vue';
import GroupEventsInfo from './GroupEventsInfo.vue';

import { toMySqlDate } from 'signalo-vue-utils';
import * as _ from 'lodash';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    components: {
        FullCalendar,
        IssuePreview,
        SingleEventInfo,
        GroupEventsInfo,
    },
    data() {
        return {
            loading: false,
            items: [],
            searchOpen: false,
            searchFieldCount: 0,
            lastSearch: null,
            currentLocale: null,
            calendarViewOption: this.helpersStore.isMobileView
                ? 'timeGridDay'
                : 'timeGridWeek',
            previewIssueId: null,
            startDate: null,
            endDate: null,

            weekViewItems: [],
            previewEvents: [],
        };
    },
    computed: {
        calendarOptions() {
            let res = {
                titleFormat: 'YYYY-MM-DD',
                locales: allLocales,
                locale: this.$i18n.locale,
                height: '100%',
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    momentPlugin,
                    interactionPlugin,
                ],
                eventBackgroundColor: '#ffffff',
                eventBorderColor: '#98a2b3',
                eventTextColor: '#000000',
                initialView: this.helpersStore.isMobileView
                    ? 'timeGridDay'
                    : 'timeGridWeek',
                eventClick: this.eventClicked,
                datesSet: this.onViewChange,
            };
            res.events =
                this.calendarViewOption !== 'dayGridMonth'
                    ? this.weekViewItems
                    : this.items;

            return res;
        },
        searchConfig() {
            return [
                {
                    id: {
                        type: 'text',
                        label: this.$t('searchConfig.id'),
                        cols: 2,
                    },
                    category_path: {
                        type: 'category-picker',
                        label: this.$t('searchConfig.category'),
                        cols: 2,
                        bind: { returns: 'path' },
                    },
                    equipment_id_equals: {
                        type: 'equipment-picker',
                        label: this.$t('searchConfig.object'),
                        cols: 4,
                        bind: {
                            kind: 'machine',
                        },
                    },
                    criticality: {
                        type: 'dictionary:issue_criticality',
                        label: this.$t('searchConfig.criticality'),
                    },
                    status: {
                        type: 'dictionary:issue_status',
                        label: this.$t('searchConfig.status'),
                    },
                },
            ];
        },
    },
    created() {
        this.loading = true;

        Promise.allSettled([
            this.dataStore.dictionary.promise(),
            this.dataStore.categories.promise(),
        ]).then(() => {
            this.loading = false;
        });
    },
    methods: {
        onViewChange(info) {
            const startSqlDate = toMySqlDate(info.start);
            const endSqlDate = toMySqlDate(info.end);
            if (startSqlDate === this.startDate && endSqlDate === this.endDate)
                return;
            this.startDate = startSqlDate;
            this.endDate = endSqlDate;
            this.loadTable();
        },
        loadTable(search = null) {
            if (!this.startDate || !this.endDate) return;
            if (search !== null) {
                this.lastSearch = search;
            }

            this.loading = true;
            this.axios
                .get('/issues', {
                    params: {
                        ...this.lastSearch,
                        created_at_dtmin: this.startDate,
                        created_at_dtmax: this.endDate,
                        finished_at_equals: 'null', //it must be string null!
                    },
                })
                .then((x) => {
                    this.items = this.initItems([
                        ...Object.values(x.data.items),
                    ]);
                    this.initWeekViewItems([...Object.values(x.data.items)]);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        eventClicked(e) {
            if (!e.event?._def?.extendedProps?.issue?.id) return;
            this.previewEvents = [];
            this.previewIssueId =
                e.event?._def?.extendedProps?.issue?.id || null;
        },
        groupEventClicked(event, events) {
            this.previewIssueId = event.id;
            this.previewEvents = events;
        },
        prev() {
            this.$refs?.calendar?.calendar?.prev();
        },
        next() {
            this.$refs?.calendar?.calendar?.next();
        },
        today() {
            this.$refs?.calendar?.calendar?.today();
        },
        onCalendarViewChange(val) {
            const cal = document.getElementsByClassName('fc-view-harness')?.[0];
            if (
                (val === 'timeGridWeek' || val === 'dayGridMonth') &&
                this.helpersStore.isMobile &&
                cal
            ) {
                cal.style.width = '1280px';
            } else {
                cal.style.width = '100%';
            }
            this.$refs?.calendar?.calendar?.changeView(val);
        },
        initWeekViewItems(items) {
            const withStartDay = items.map((i) => {
                return {
                    ...i,
                    startDay: i.start?.split(' ')[0],
                    startHour: i.start?.split(' ')[1]?.split(':')[0],
                };
            });
            const grouppedByDay = _.groupBy(withStartDay, 'startDay');
            for (let eventsInDay in grouppedByDay) {
                grouppedByDay[eventsInDay] = _.groupBy(
                    grouppedByDay[eventsInDay],
                    'startHour'
                );
            }

            const flatten = _.flattenDeep(
                Object.values(grouppedByDay).map((dayEvents) => {
                    return _.flattenDeep(
                        Object.values(dayEvents).map((eventsAtHour) => {
                            if (eventsAtHour.length === 1) {
                                return {
                                    ...eventsAtHour[0],
                                    categoryName: this.$getPropertyTranslation(
                                        eventsAtHour[0]?.category?.name
                                    ),
                                    issue: eventsAtHour[0],
                                };
                            } else {
                                return {
                                    id: 'GROUP',
                                    title: 'GROUP',
                                    date: eventsAtHour[0]?.date,
                                    icon: 'mdi-format-list-group',
                                    categoryName: 'GROUP',
                                    start: new Date(
                                        eventsAtHour[0]?.start
                                    ).setHours(
                                        eventsAtHour[0].startHour,
                                        0,
                                        0
                                    ) /*to prevent tiles cover*/,
                                    issues: eventsAtHour,
                                    group: true,
                                    created_at: eventsAtHour[0].created_at,
                                    events: this.initItems(eventsAtHour),
                                };
                            }
                        })
                    );
                })
            );
            this.weekViewItems = flatten;
        },
        initItems(items) {
            return items.map((issue) => {
                const category = Object.values(
                    this.dataStore.categories.items
                )?.find((c) => c.id === issue.category_id);
                return {
                    ...issue,
                    id: issue.id,
                    title: issue.title,
                    date: issue.created_at,
                    icon: category?.icon,
                    categoryName: this.$getPropertyTranslation(category?.name),
                    categoryColor: category?.color,
                    issue: issue,
                    start: issue.start,
                };
            });
            /*TODO API BĘDZIE ZWRACAĆ elementy na podstawie cyklicznych issues*/
            // console.log(
            //     'FILTROWANE',
            //     i[i.length - 1].issue.periodics.rules[0].ruleObject
            //         .all()
            //         .filter(
            //             (d) =>
            //                 d >= new Date(this.startDate) &&
            //                 d <= new Date(this.endDate)
            //         )
            // );
        },
    },
};
</script>
<style>
.page-calendar-container {
    height: 100vh;
    background-color: #fff !important;
    overflow: scroll;
}
.page-calendar-container .fc-header-toolbar {
    padding: 8px;
    margin: 0 !important;
}
.page-calendar-container .fc-header-toolbar .fc-toolbar-title {
    font-size: 14px;
}
.calendar-actions-container {
    display: flex;
    width: 60%;
    height: 100%;
}

.change-week-container button {
    height: 100% !important;
    margin: 0 2px;
}
.change-week-container {
    margin-left: auto;
}
.fc-header-toolbar .fc-toolbar-chunk:nth-of-type(3) {
    visibility: hidden;
}
.issue-preview-container {
    position: fixed;
    z-index: 99999;
    width: 300px;
    height: calc(100% - 65px);
    top: 65px;
    background: white;
    border-left: 1px solid var(--greyscale-50);
}

.mobile-calendar-actions-container {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: unset;
    position: absolute;
}
.mobile-calendar-actions-container .change-week-container {
    margin: unset;
}
</style>
