<template>
    <v-card class="mt-3 mx-3 mb-3">
        <template v-slot:title>
            <v-toolbar class="preview-header">
                <v-icon class="preview-header-icon">mdi-image-multiple-outline</v-icon>
                {{ $t('common.images') }}
            </v-toolbar>
        </template>
        <SignaloFilesImagesContainer
            :endpoint="`/issues/${item.id}/photos`"
            :allowUpload="!disabled && (checkAllowUpdate ? (authStore?.tenant?.attributes?.issue_update_photos || authStore?.isAllowed('issues.details', 'update')) : true)"
            :allow-remove="!disabled && (checkAllowUpdate ? (authStore?.tenant?.attributes?.issue_update_photos || authStore?.isAllowed('issues.details', 'update')) : true)"
            :allow-edit="!disabled && (checkAllowUpdate ? (authStore?.tenant?.attributes?.issue_update_photos || authStore?.isAllowed('issues.details', 'update')) : true)"
            :allowImagesFromDeviceCamera="true"
            :allowDraw="!disabled"
            />
    </v-card>
</template>
<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';

export default {
    setup() {
        const authStore = AUTH_STORE.default();
        return {authStore};
    }, 
    props: ['item', 'checkAllowUpdate', 'disabled'],
};
</script>
