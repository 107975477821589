<template>
    <SignaloDialog
        :open="true"
        :title="$t('common.takeHandover')"
        @close="closeDialog"
        :constHeight="true"
    >
        <template #body>
            <v-card-text>
                <v-autocomplete
                    v-model="selectedTemplate"
                    variant="outlined"
                    :label="$t('common.handoverTemplate')"
                    :items="handoverTemplates"
                    :loading="loading"
                    :item-title="(item) => $getPropertyTranslation(item.name)"
                    item-value="id"
                    :rules="requiredRules"
                    class="required-field"
                >
                </v-autocomplete>
                <EasyDataTable buttons-pagination
                    v-if="selectedTemplate"
                    table-class-name="easy-table-style no-margin"
                    :items="handoverTemplatesEntries"
                    :headers="tableHeaders"
                    :loading="loading"
                    :empty-message="$t('common.emptyTableMessage')"
                    :rows-items="[5, 10, 25, 50, 75, 100]"
                    :rows-per-page="5"
                    :rows-per-page-message="$t('common.rowsPerPage')"
                    :rows-of-page-separator-message="$t('common.rowsOfPage')"
                >
                    <template #item-part_category="item">
                        {{
                            $getDictionaryItemName(
                                'part_category',
                                item.part_category
                            ) || '-'
                        }}
                    </template>
                    <template #item-replace_after_interval="item">
                        {{ $displayISO8601(item.replace_after_interval) }}
                    </template>
                    <!-- <template #item-quantity="item">

                        <v-text-field
                            :label="$t('common.quantity')"
                            v-model="item.quantity"
                            variant="outlined"
                            color="var(--greyscale-100)"
                            type="number"
                            @update:model-value="value => "
                        >
                        </v-text-field>
                    </template> -->
                    <template #item-equipment="item">
                        <SignaloPartPicker
                            :modelValue="parts?.[item.id]?.part_id"
                            @update:modelValue="
                                (part) =>
                                    onPartSelected(part, item.quantity, item.id)
                            "
                            v-slot="props"
                            return-object
                            :disableNotAvailable="true"
                            :partCategory="item.part_category"
                            :gte="item.quantity"
                            :with_entries_in_allowed_locations="true"
                            :key="
                                JSON.stringify(item) +
                                JSON.stringify(parts?.[item.id]?.part_id)
                            "
                        >
                            <v-text-field
                                ref="equipmentPickerTextField"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                :modelValue="props.text"
                                @click:control="props.onClick"
                                @click:clear="props.onClear"
                                readonly
                                :label="$t('navDrawer.parts')"
                                :loading="props.loading"
                            >
                            </v-text-field>
                        </SignaloPartPicker>
                    </template>
                </EasyDataTable>
                <SignaloErrorMessage v-if="error" :errorMessage="error" />
            </v-card-text>
        </template>
        <template #buttons>
            <v-btn @click="closeDialog">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="async () => await onSave()"
                :loading="saveLoading"
                :disabled="!Object.values(parts)?.length"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>

    <SignaloSingleValueDialog
        v-if="awaitingSignature"
        :title="signatureDialogTitle"
        :valueType="'signature'"
        @update:modelValue="signatureUpdated"
        :open="awaitingSignature"
        @close="onSignatureDialogClose"
        :signatureCheckUserId="checkUserSignatureId"
        :signatureUsername="signatureUsername"
        :required="true"
    />
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';

export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return {
            helpersStore,
            authStore,
        };
    },
    props: ['user'],
    data() {
        return {
            loading: false,
            handoverTemplates: [],
            handoverTemplatesEntries: [],
            selectedTemplate: null,
            clientSignature: undefined,
            approvedSignature: undefined,
            parts: {},
            awaitingSignature: null,
            saveLoading: false,
            error: null,
            checkUserSignatureId: null,
            signatureUsername: null,
        };
    },
    async mounted() {
        await this.loadHandoverTemplates();
    },
    methods: {
        onSignatureDialogClose() {
            this.clientSignature = 'abort';
            this.approvedSignature = 'abort';
            this.awaitingSignature = null;
            this.checkUserSignatureId = null;
            this.signatureUsername = null;
            this.saveLoading = false;
        },
        async loadHandoverTemplates() {
            try {
                this.loading = true;
                let endpoint = `handover_templates`;
                const templatesData = await this.axios.get(endpoint);
                this.handoverTemplates = templatesData?.data?.items || [];
                this.loading = false;
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
        },
        async loadTemplateEntries(templateId) {
            if (!templateId) return;
            try {
                this.loading = true;
                const entriesData = await this.axios.get(
                    `handover_templates/${templateId}/entries`
                );
                this.handoverTemplatesEntries = entriesData?.data?.items || [];

                this.loading = false;
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
        },
        onPartSelected(part, quantity, entryId) {
            const partToAdd = {
                part_id: part.id,
                quantity: quantity,
                unit: part.preferred_unit,
                handover_template_entry_id: entryId,
            };

            this.parts[entryId] = { ...partToAdd };
        },
        async waitForClientSignature(resolve, returning = false) {
            await new Promise((res) => setTimeout(res, 500));
            return await new Promise(() => {
                if (this.clientSignature) {
                    resolve(true);
                } else if (this.clientSignature !== undefined) {
                    resolve(true);
                } else {
                    return this.waitForClientSignature(resolve, returning);
                }
            });
        },
        async waitForApprovedSignature(resolve, returning = false) {
            await new Promise((res) => setTimeout(res, 500));
            return await new Promise(() => {
                if (this.approvedSignature) {
                    resolve(true);
                } else if (this.approvedSignature !== undefined) {
                    resolve(true);
                } else {
                    return this.waitForApprovedSignature(resolve, returning);
                }
            });
        },
        signatureUpdated(value) {
            if (this.awaitingSignature === 'client') {
                this.clientSignature = value;
            } else if (this.awaitingSignature === 'approved') {
                this.approvedSignature = value;
            }

            this.awaitingSignature = false;
        },
        closeDialog() {
            this.$emit('close');
        },
        async onSave() {
            if (this.saveLoading) return;
            try {
                this.clientSignature = undefined;
                this.approvedSignature = undefined;
                this.error = null;
                this.saveLoading = true;
                this.awaitingSignature = 'client';
                this.checkUserSignatureId = this.user.id;
                this.signatureUsername = this.user.name;
                await new Promise((resolve) => {
                    this.waitForClientSignature(resolve);
                });
                if (this.clientSignature === 'abort') return;
                this.awaitingSignature = null;

                this.awaitingSignature = 'approved';
                this.checkUserSignatureId = this.authStore?.user?.id;
                this.signatureUsername = this.authStore?.user?.name;
                await new Promise((resolve) => {
                    this.waitForApprovedSignature(resolve);
                });
                if (this.approvedSignature === 'abort') return;
                this.awaitingSignature = null;

                await this.axios.post('storage/parts_exchange', {
                    user_id: this.user?.id,
                    user_signature: this.clientSignature,
                    approved_by_signature: this.approvedSignature,
                    parts: this.parts,
                });

                this.helpersStore.snackbarSaved();
                this.saveLoading = false;
                this.clientSignature = undefined;
                this.approvedSignature = undefined;
                this.closeDialog();
            } catch (err) {
                if (err?.response?.status === 422) {
                    if (
                        Object.values(err?.response?.data?.items).some((err) =>
                            err.status.includes('quantity_too_low')
                        )
                    ) {
                        const message = this.$t('errors.noEnoughParts');
                        this.helpersStore.sanckbarError(message);
                        this.error = message;
                    }
                }
                console.error(err);
                this.saveLoading = false;
            }
        },
    },
    watch: {
        selectedTemplate: {
            handler(id) {
                if (id) {
                    this.parts = {};
                    this.loadTemplateEntries(id);
                }
            },
            deep: true,
        },
    },
    computed: {
        signatureDialogTitle() {
            if (this.awaitingSignature === 'client')
                return this.$t('common.clientSignatureEnabled');
            if (this.awaitingSignature === 'approved')
                return this.$t('common.warehousemanSignatureEnabled');
            return this.$t('common.signature');
        },
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.category'),
                    value: 'part_category',
                },
                { text: this.$t('common.quantity'), value: 'quantity' },
                {
                    text: this.$t('common.replaceTimespan'),
                    value: 'replace_after_interval',
                },
                {
                    text: this.$t('common.Equipment'),
                    value: 'equipment',
                    width: 260,
                },
            ];
        },
    },
};
</script>
