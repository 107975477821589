<template>
    <div class="survey-builder-section-question-answer">
        <div class="remove-answer-button-container">
            <v-btn @click="() => removeAnswer()" color="error" type="button" variant="text">
                {{ $t('cluer.removeAnswer') }}
            </v-btn>
        </div>
        <v-form v-model="answerFormValid" lazy-validation ref="answerForm">
            <SignaloTranslatableTabs v-slot="{ value, update }" v-model="answer.name" :currentLanguage="$i18n?.locale"
                :availableLocales="getAvailableLocales()">
                <v-card-text>
                    <v-text-field :label="$t('cluer.answerOption')" :modelValue="value" @update:modelValue="update"
                        :rules="translatableRequired" required variant="outlined" color="var(--greyscale-100)"
                        class="required-field">
                    </v-text-field>
                    <v-text-field type="number" :label="$t('common.order')" variant="outlined" min="1"
                        v-model="answer.order" class="required-field" clearable></v-text-field>
                    <v-text-field type="number" :label="$t('common.points')" variant="outlined" v-model="answer.points"
                        class="required-field" :rules="[$REQUIRED_RULE]"></v-text-field>
                </v-card-text>
            </SignaloTranslatableTabs>
        </v-form>
    </div>
</template>

<script>
/* eslint-disable */
import * as AUTH_STORE from 'signalo-vue-auth-store';
export default {
    setup() {
        const authStore = AUTH_STORE.default();
        return { authStore };
    },
    props: ['modelValue', 'surveyId', 'sectionId', 'questionId'],
    emits: ['update:modelValue', 'removeAnswer'],
    data() {
        return {
            answer: { ...this.modelValue },
            answerFormValid: false,
        };
    },
    methods: {
        async validate() {
            const validators = [];
            if (typeof this.$refs.answerForm?.validate === 'function') {
                const questionAnswerValidationResult = await this.$refs.answerForm.validate();
                validators.push(questionAnswerValidationResult);
            }
            return validators;
        },
        removeAnswer() {
            this.$emit('removeAnswer', this.answer.id);
        },
    },
    computed: {
        translatableRequired() {
            return [
                () =>
                    this.authStore.tenant.supportedLocales.every(
                        (v) => this.answer?.name?.[v]
                    ) || this.$t('errors.allLanguagesRequired'),
            ];
        },
    },
    watch: {
        answerFormValid(val) {
            this.$emit('update:modelValue', {
                ...this.answer,
                valid: val,
            });
        },
        answer: {
            handler(val) {
                this.$refs?.answerForm?.validate().then((isAnwerValid) => {
                    this.$emit('update:modelValue', {
                        ...val,
                        valid: isAnwerValid.valid
                    });
                });
            },
            deep: true,
        },
    },
};
</script>

<style>
.survey-builder-section-question-answer {
    border: 1px solid var(--greyscale-50);
    border-radius: 8px;
    margin-bottom: 16px;
    margin-left: 64px;
    margin-right: 8px;
}

.remove-answer-button-container {
    display: flex;
    justify-content: flex-end;
}
</style>
