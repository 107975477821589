<template>
    <SignaloAppBar
        search
        v-model:searchOpen="searchOpen"
        :searchFieldCount="searchFieldCount"
    >
        <template #title> {{ $t('common.changeSuggestions') }} </template>
        <template #bar>
            <v-btn
                @click="() => loadTable()"
                class="submain-button renew-button"
            >
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-btn
                @click="onCreate"
                color="primary"
                variant="elevated"
                class="add-button"
            >
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloSearchContainer
            v-model:open="searchOpen"
            :config="searchConfig"
            @search="loadTable"
        />
        <SignaloBreadCrumbs />
        <PageLoading v-if="loading" />
        <div v-else>
            <EasyDataTable buttons-pagination
                :headers="tableHeaders"
                :items="items"
                :empty-message="$t('common.emptyTableMessage')"
                table-class-name="easy-table-style"
                :rows-per-page-message="$t('common.rowsPerPage')"
                :rows-of-page-separator-message="$t('common.rowsOfPage')"
                v-model:server-options="serverOptions"
                :server-items-length="serverItemsLength"
                :rows-items="[5, 10, 25, 50, 75, 100]"
            >
                <template #item-created_at="item">
                    {{ new Date(item.created_at).toLocaleString() }}
                </template>
                <template #item-type="item">
                    {{ $t('common.' + item.type) }}
                </template>
                <template #item-operation="item">
                    <SignaloDynamicMenu :rowItem="item" :menuItems="tableActions" />
                </template>
            </EasyDataTable>
        </div>
    </v-main>
    <ReportChangeSuggestionDialog
        v-if="manageDialog"
        v-model:open="manageDialog"
        :mode="dialogMode"
        :editedItem="editedItem"
        @closeDialog="onCloseDialogHandler"
        :preview="preview"
    />
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import ReportChangeSuggestionDialog from './ReportChangeSuggestionDialog.vue';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: true,
            items: [],
            manageDialog: false,
            dialogMode: null,
            editedItem: null,
            searchOpen: false,
            searchFieldCount: 0,
            lastSearch: null,
            serverOptions: {
                page: 1,
                rowsPerPage: 5,
                sortBy: 'id',
                sortType: 'asc',
                query: '',
            },
            serverItemsLength: 0,
            preview: false,
        };
    },
    computed: {
        tableHeaders() {
            return [
                { text: this.$t('tableHeaders.data'), value: 'created_at' },
                { text: this.$t('common.type'), value: 'type' },
                {
                    text: this.$t('tableHeaders.description'),
                    value: 'description',
                },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
        searchConfig() {
            return [
                {
                    type_wni: {
                        type: 'custom_select',
                        label: this.$t('tableHeaders.type'),
                        cols: 12,
                        default: 'front',
                        selectOptions: [
                            {
                                value: 'front',
                                label: this.$t('common.all'),
                            },
                            {
                                value: 'front,user_kaizen',
                                label: this.$t('common.user_bug'),
                            },
                            {
                                value: 'front,user_bug',
                                label: this.$t('common.user_kaizen'),
                            },
                        ],
                    },
                },
            ];
        },
        tableActions() {
            return [
                {
                    icon: 'mdi-eye',
                    label: this.$t('common.preview'),
                    action: this.onPreview,
                },
                {
                    icon: 'mdi-pencil',
                    label: this.$t('common.edit'),
                    action: this.onEdit
                },
            ];
        },
    },
    watch: {
        serverOptions() {
            this.loadTable();
        },
    },
    methods: {
        async loadTable(search = null) {
            if (search !== null) {
                this.lastSearch = search;
            }
            this.loading = true;
            const options = this.dataStore.errorLog.makeServerOptions(
                this.serverOptions,
                {
                    ...this.lastSearch,
                }
            );
            try {
                const data = await this.dataStore.errorLog.indexRequest(
                    options
                );
                this.items = Object.values(data.data.items);
                this.serverItemsLength = data?.data?.meta?.total;
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        onCreate() {
            this.dialogMode = 'create';
            this.manageDialog = true;
        },
        onEdit(item) {
            this.dialogMode = 'edit';
            this.editedItem = item;
            this.manageDialog = true;
        },
        onPreview(item) {
            this.dialogMode = 'edit';
            this.preview = true;
            this.editedItem = item;
            this.manageDialog = true;
        },
        async onCloseDialogHandler(refetchData) {
            if (refetchData) {
                await this.loadTable();
            }
            this.manageDialog = false;
            this.editedItem = null;
            this.dialogMode = null;
            this.preview = false;
        },
        handleClickRow(row) {
            this.onEdit(row);
        },
    },
    components: { ReportChangeSuggestionDialog },
};
</script>
