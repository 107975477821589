import i18n from '@/i18n';

export default Object.freeze({
    TEXT: {
        value: 'text',
        get label() {
            return i18n.global.t('paramTypes.text');
        },
    },
    NOTE: {
        value: 'note',
        get label() {
            return i18n?.global?.t('paramTypes.note');
        },
    },
    INTEGER: {
        value: 'integer',
        get label() {
            return i18n?.global?.t('paramTypes.integer');
        },
    },
    DECIMAL: {
        value: 'decimal',
        get label() {
            return i18n?.global?.t('paramTypes.decimal');
        },
    },
    DICTIONARY: {
        value: 'dictionary',
        get label() {
            return i18n?.global?.t('common.dictionary');
        },
    },
    DATE: {
        value: 'date',
        get label() {
            return i18n?.global?.t('paramTypes.date');
        },
    },
    BOOLEAN: {
        value: 'boolean',
        get label() {
            return i18n?.global?.t('paramTypes.boolean');
        },
    },
    USER: {
        value: 'user',
        get label() {
            return i18n?.global?.t('common.user');
        },
    },
    RADIO_GROUP: {
        value: 'radio_group',
        get label() {
            return i18n?.global?.t('paramTypes.radioGroup');
        },
    },
    CHECKBOX_GROUP: {
        value: 'checkbox_group',
        get label() {
            return i18n?.global?.t('paramTypes.checkboxGroup');
        },
    },
    SIGNATURE: {
        value: 'signature',
        get label() {
            return i18n?.global?.t('paramTypes.signature');
        },
    },
    COST_CENTER: {
        value: 'cost_center',
        get label() {
            return i18n?.global?.t('costCenter.costCenter');
        },
    },
    PRODUCT: {
        value: 'product',
        get label() {
            return i18n?.global?.t('common.product');
        },
    },
    BARCODE: {
        value: 'barcode',
        get label() {
            return i18n?.global?.t('tableHeaders.barCode');
        },
    },
    DOCUMENT: {
        value: 'document',
        get label() {
            return i18n?.global?.t('paramTypes.document');
        },
    },
    IMAGE: {
        value: 'image',
        get label() {
            return i18n?.global?.t('paramTypes.image');
        },
    },
});
