export const pl = {
  common: {
    PIN_CODE_LABEL: 'KOD PIN',
    comment: 'Komentarz',
    year: 'Rok',
    configuration: 'Konfiguracja',
    cancel: 'Anuluj',
    search: 'Szukaj',
    remove: 'Usuń',
    confirmationDialogTitle: 'Czy chcesz usunąć tę pozycję?',
    selectDate: 'Wybierz datę',
    select: 'Wybierz',
    notFound: 'Nie znaleziono',
    equipmentPickerPlaceholder: 'Obiekt wyposażenia',
    searchPlaceholder: 'Szukaj',
    equipmentTypePickerPlaceholder: 'Typ wyposażenia',
    equipmentTypePickerTitle: 'Wybierz typ wyposażenia',
    emptyTableMessage: 'Brak danych',
    flowPikcerTitle: 'Wybierz przepływ',
    selectIcon: 'Wybierz ikonę',
    locationPickerTitle: 'Wybierz lokalizację',
    locationPlaceholder: 'Lokalizacja',
    noSublocations: 'Brak lokalizacji podrzędnych',
    locationsTitle: 'Lokalizacje',
    edit: 'Edytuj',
    save: 'Zapisz',
    added: 'Dodano',
    updated: 'Zaktualizowano',
    add: 'Dodaj',
    removed: 'Usunięto',
    reload: 'Odśwież',
    confirm: 'Potwierdź',
    rollBack: 'Przywróć',
    images: 'Zdjęcia',
    documents: 'Dokumenty',
    download: 'Pobierz',
    saved: 'Zapisano',
    date: 'Data',
    hour: 'Godzina',
    preview: 'Podgląd',
    data: 'Dane',
    attributes: 'Atrybuty',
    checkList: 'Lista kontrolna',
    name: 'Nazwa',
    type: 'Typ',
    status: 'Status',
    designation: 'Oznaczenie',
    symbol: 'Symbol',
    serialNumber: 'Nr fabryczny',
    registryNumber: 'Nr ewidencyjny',
    machineNumber: 'Nr maszyny',
    udtNumber: 'Nr UDT',
    manufacturedYear: 'Rok produkcji',
    manufacturer: 'Producent',
    supplier: 'Dostawca',
    mainSupplier: 'Główny dostawca',
    attribute: 'Cecha',
    nameRequired: 'Nazwa jest wymagana',
    yearGreaterThan: 'Rok musi być większy niż',
    location: 'Lokalizacja',
    fieldRequired: 'To pole jest wymagane',
    description: 'Opis',
    minimumQuantity: 'Minimalna ilość',
    optimalQuantity: 'Optymalna ilość',
    priority: 'Ważność (priorytet)',
    formatYYYY: 'Format YYYY',
    storageQuantity: 'Stan magazynowy',
    quantity: 'Ilość',
    quantityGreaterThan: 'Ilość musi być większa niż',
    machine: 'Maszyna',
    tool: 'Narzędzie',
    order: 'Kolejność',
    required: 'Wymagany',
    dateFormat: 'Format daty',
    selectDictionary: 'Wybierz słownik',
    return: 'Powrót',
    parameters: 'Parametry',
    editCategory: 'Edytuj kategorię',
    addCategory: 'Dodaj nową kategorię',
    changeColor: 'Zmień kolor',
    noColor: 'Brak koloru',
    shift: 'Zmiana',
    start: 'Początek',
    end: 'Koniec',
    shiftSchema: 'Schemat zmiany',
    shifts: 'Zmiany',
    dictionary: 'Słownik',
    subEntries: 'Wpisy podrzędne',
    key: 'Klucz',
    value: 'Wartość',
    moveUp: 'Przenieś w górę',
    moveDown: 'Przenieś w dół',
    addLocation: 'Dodaj nową lokalizację',
    editLocation: 'Edytuj lokalizację',
    companiesDatabase: 'Baza firm',
    flows: 'Przepływy',
    read: 'Oczyt',
    write: 'Zapis',
    noImage: 'Brak zdjęcia',
    email: 'E-mail',
    function: 'Funkcja',
    active: 'Aktywne',
    notActive: 'Nie aktywowane',
    disabled: 'Zablokowane',
    entryNumber: 'Numer wpisu',
    issuedDate: 'Data uzyskania',
    expiresDate: 'Data ważności',
    move: 'Przenieś',
    separateForm: 'Oddziel z',
    separate: 'Oddziel',
    warehouse: 'Magazyn',
    actions: 'Akcje',
    putInCurrentLocation: 'Umieść w bieżącej lokalizacji',
    selectLocationFormRightMenu: 'Wybierz lokalizację w prawym menu',
    showUnassignedItems: ' Wyświetl nieprzypisane przedmioty',
    separated: 'Oddzielono',
    moved: 'Przeniesiono',
    categories: 'Kategorie',
    welcome: 'Witaj',
    loginToSignaloTpm: 'Zaloguj się do Signalo TPM',
    login: 'Zaloguj się',
    loginByEmailAndPassword: 'Zaloguj się za pomocą adresu e-mail i hasła',
    loginByCode: 'Zaloguj się za pomocą @:common.PIN_CODE_LABEL',
    accessCode: 'Kod dostępu',
    enterEmail: 'Wpisz email',
    enterPassword: 'Wpisz hasło',
    enterCode: 'Wpisz @:common.PIN_CODE_LABEL',
    userNotFound: 'Nie znaleziono użytkownika',
    userNotActivated: 'Użytkownik nie został aktywowany',
    userDisabled: 'Użytkownik zablokowany',
    wrongPassword: 'Złe hasło',
    locations: 'Lokalizacje',
    pageNotFound: 'Nie znaleziono strony',
    group: 'Grupa',
    yes: 'Tak',
    no: 'Nie',
    hourlyCost: 'Koszt roboczogodziny',
    price: 'Cena',
    newEntry: 'Nowy wpis',
    predictedFinishTime: 'Spodziewany czas realizacji',
    ok: 'Ok',
    archive: 'Archiwizuj',
    costs: 'Koszta',
    companyManagement: 'Zarządzanie firmą',
    companyData: 'Dane firmy',
    supportedLanguages: 'Dostępne języki',
    en: 'Angielski',
    pl: 'Polski',
    es: 'Hiszpański',
    de: 'Niemiecki',
    it: 'Włoski',
    fr: 'Francuski',
    ru: 'Rosyjski',
    uk: 'Ukraiński',
    pt: 'Portugalski',
    nl: 'Niderlandzki',
    tr: 'Turecki',
    sr: 'Serbski',
    ar: 'Arabski',
    he: 'Hebrajski',
    zh: 'Chiński',
    defaultLanguage: 'Domyślny język',
    companyLogo: 'Logo firmy',
    notificationsManagement: 'Zarządzanie powiadomieniami',
    category: 'Kategoria',
    today: 'Dziś',
    month: 'Miesiąc',
    week: 'Tydzień',
    day: 'Dzień',
    close: 'Zamknij',
    show: 'Wyświetl',
    rollBackFormalConfirmation: 'Anuluj formalne potwierdzenie',
    signUp: 'Zarejestruj się',
    nameSurname: 'Imię i nazwisko',
    phone: 'Numer telefonu',
    password: 'Hasło',
    password_confirmation: 'Powtórz hasło',
    unassignedItems: 'Nieprzypisane przedmioty',
    currency: 'Waluta',
    general: 'Ogólne',
    timezone: 'Strefa czasowa',
    users: 'Użytkownicy',
    groups: 'Grupy',
    productionLine: 'Linia produkcyjna',
    part: 'Część',
    fittingParts: 'Pasujące części',
    workSchema: 'Schemat pracy',
    holiday: 'Święta',
    workSchemas: 'Schematy pracy',
    changePassword: 'Zmień hasło',
    showRest: 'Zobacz pozostałe',
    pageNotPermitted: 'Nie masz uprawnień, aby wyświetlić tę stronę',
    waitingForActivation: 'Oczekujące na aktywację',
    editGroup: 'Edytuj grupę',
    addGroup: 'Dodaj grupę',
    reportProblem: 'Zgłoś problem',
    describeOccuredIssue: 'Opisz napotkany problem',
    markAllAsRead: 'Oznacz wszystkie jako przeczytane',
    unitType: 'Typ jednostki',
    unit: 'Jednostka',
    unitPrice: 'Cena jednostkowa',
    notConfigured: 'Nieskonfigurowany',
    rowsPerPage: 'Wierszy na stronę',
    rowsOfPage: 'z',
    true: 'Prawda',
    false: 'Fałsz',
    export: 'Eksportuj',
    chooseFileFormat: 'Wybierz format pliku',
    exported: 'Wyeksportowano',
    terminals: 'Terminale',
    terminal: 'Terminal',
    selectEquipment: 'Wybierz wyposażenie',
    futureIssues: 'Przyszłe zdarzenia',
    scheduledOn: 'Zaplanowano na',
    planned: 'Planowane',
    notPlanned: 'Nieplanowane',
    showOpenIssues: 'Wyświetl otwarte zgłoszenia',
    pin: 'PIN',
    assignedEquipment: 'Przypisane wyposażenie',
    terminalEquipment: 'Wysposażenie terminala',
    detach: 'Usuń',
    attach: 'Dodaj',
    dashboardConfig: 'Konfiguracja dashboardu',
    addColumn: 'Dodaj kolumnę',
    addRow: 'Dodaj wiersz',
    removeRow: 'Usuń wiersz',
    registerTerminal: 'Zarejestruj terminal',
    issueGeneratesCost: 'Przestój generuje koszt',
    selectColor: 'Wybierz kolor',
    belowMinimum: 'Poniżej minimum',
    belowOptimum: 'Poniżej optimum',
    synchronization: 'Synchronizacja',
    synchronizationExport: 'Eksport części magazynowych',
    synchronizationImport: 'Import części magazynowych',
    mapping: 'Mapowanie',
    returnTo: 'Wróć do',
    jobStatusses: 'Procesy',
    hidden: 'Ukryty',
    editEvent: 'Edytuj wydarzenie',
    threeDays: '3 dni',
    addEvent: 'Dodaj wydarzenie',
    plannedIssues: 'Zadania zaplanowane',
    currentService: 'Bieżąca obsługa',
    searchLocation: 'Szukaj lokalizacji',
    all: 'Wszystkie',
    unfinished: 'Nieukończone',
    numberOfIssues: 'Ilość zgłoszeń',
    unknown: 'Nieznany',
    partEntry: 'Część (wpis)',
    foundItems: 'Znalezione elementy',
    output: 'Wynik',
    copied: 'Skopiowano',
    link: 'Link',
    authorizationRequired: 'Wymagana autoryzacja',
    authorizationRequiredDescriptionUser: 'Niestety nie masz wystarczających uprawnień. Poproś osobę uprawnioną o zatwierdzenie operacji.',
    authorizationRequiredDescriptionTerminal: 'Użyj swojego @:common.PIN_CODE_LABEL w celu autoryzacji operacji.',
    showAll: 'Pokaż wszystkie',
    showClassicView: 'Widok klasyczny',
    externalSystems: 'Systemy zewnętrzne',
    noIssuesInCategory: 'Brak zgłoszeń w tej kategorii.',
    numberOfIssuesInThisCategoryAndSubcategories: 'Ilość zgłoszeń w tej kategorii i jej podkategoriach.',
    numberOfIssuesInThisCategory: 'Ilość zgłoszeń dokładnie w tej kategorii.',
    minBarcodeLength: 'Minimalna długość kodu kreskowego.',
    currentPage: 'Bieżąca strona',
    allPages: 'Wszystkie strony',
    selectFormat: 'Wybierz format',
    provided_currency: 'Wprowadzona waluta',
    provided_quantity: 'Wprowadzona ilość',
    provided_unit: 'Wprowadzona jednostka',
    provided_unit_price: 'Wprowadzona cena jednostkowa',
    unit_price: 'Cena jednostkowa',
    quantity_available: 'Dostępna ilość',
    editBarcode: 'Edytuj kod kreskowy',
    crews: 'Brygady',
    scheduleEventTypes: 'Rodzaje zdarzeń kalendarzowych',
    crew: 'Brygada',
    scheduleEvent: 'Zdarzenie kalendarzowe',
    available: 'Dostępne',
    'App\\Models\\Crew': 'Brygada',
    'App\\Models\\User': 'Użytkownik',
    'App\\Models\\Equipment': 'Wyposażenie',
    Crew: 'Brygada',
    User: 'Użytkownik',
    Equipment: 'Wyposażenie',
    scheduleEvents: 'Planowane wydarzenia',
    event: 'Wydarzenie',
    user: 'Użytkownik',
    availability: 'Dostępność',
    inheritColorFromParentIfCustomNotSet: 'Ustaw kolor rodzica, jeśli nie jest ustawiony własny',
    pickFromAllParts: 'Wybierz z wszystkich rzeczy',
    takeTool: 'Wydaj narzędzie',
    returnTool: 'Zwróć narzędzie',
    personWhoTake: 'Osoba pobierająca',
    personWhoReturn: 'Osoba zwracająca',
    takenAt: 'Data pobrania',
    takenBy: 'Osoba pobierająca',
    toolIssuedBy: 'Osoba wydająca',
    commentTake: 'Komentarz przy pobraniu',
    commentReturn: 'Komentarz przy zwrocie',
    change: 'Zmień',
    onlyWithActiveRMIssues: 'Tylko z aktywnymi zgłoszeniami RM',
    externalSystem: 'System zewnętrzny',
    rejectIssue: 'Odrzuć zgłoszenie',
    withdrawIssueRejection: 'Wycofaj odrzucenie zgłoszenia',
    rejectionReason: 'Powód odrzucenia',
    rejected: 'Odrzucone',
    showCrewMembers: 'Pokaż członków brygady',
    crewMembers: 'Członkowie brygady',
    helpGroup: 'Służba',
    partsTakenByUser: 'Rzeczy pobrane przez pracownika',
    pickParts: 'Wydaj rzeczy',
    selectPersonWhoTakes: 'Wybierz osobę która pobiera',
    changePersonWhoTakes: 'Zmień osobę która pobiera',
    quantityToPick: 'Ilość do pobrania',
    pickLocation: 'Lokalizacja z której pobiera',
    selectPartsToPick: 'Wybierz rzeczy',
    partSelected: 'Wybrano rzecz',
    scanCodeOr: 'Zeskanuj kod lub',
    returnPart: 'Zwróć rzecz',
    myTakenParts: 'Rzeczy pobrane przeze mnie',
    takenFromLocation: 'Lokalizacja pobrania rzeczy',
    takenQuantity: 'Pobrana ilość',
    providedUnit: 'Wprowadzona jednostka',
    providedQuantity: 'Wprowadzona ilość',
    selectFromStorage: 'Wybierz z magazynu',
    selectFromMyPickedParts: 'Wybierz z moich rzeczy',
    exportProcessInProgressMessage: 'Proces eksportu danych właśnie się rozpoczął, gdy zostanie zakończony zobaczysz odpowiednie powiadomienie zawierające link do pliku.',
    yourDocumentIsReady: 'Twój dokument jest gotowy',
    rotateScreen: 'Obróć ekran',
    addRule: 'Dodaj regułę',
    editRule: 'Edytuj regułę',
    summary: 'Podsumowanie',
    onlyAvailableEmployees: 'Pokaż tylko dostępnych pracowników',
    calculated_unit_price: 'Przeliczona cena jednostkowa',
    calculated_quantity: 'Przeliczona ilość',
    next: 'Dalej',
    maintenanceMode: 'Tryb konserwacji',
    applicationMayBeUnavailableForFewMinutes: 'Aplikacja może być niedostępna przez kilka minut',
    appWillRefreshIn: 'Aplikacja zostanie przeładowana za',
    landscape: 'Poziomo',
    portrait: 'Pionowo',
    orientation: 'Orientacja',
    format: 'Format',
    service: 'Służba',
    leader: 'Brygadziści',
    defaultView: 'Domyślny widok',
    view: 'Widok',
    oneDay: '1 dzień',
    object: 'Obiekt',
    finished: 'Ukończone',
    onlyNotFinished: 'Tylko nieukończone',
    generate: 'Generuj',
    error: 'Błąd',
    cell: 'Komórka',
    resolutionSummary: 'Podsumowanie zakończenia',
    rejected_at: 'Data odrzucenia',
    rejected_by: 'Odrzucono przez',
    finishedByUser: 'Ukończone przez',
    formalConfirmByUser: 'Formalne potwierdzenie przez',
    formalConfirmAt: 'Data formalnego potwierdzenia',
    valueDeleted: '[Wartość usunięta]',
    hasNotPlannedIssue: 'Ma nieplanowane zgłoszenie',
    showInStorage: 'Pokaż w magazynie',
    changeCode: 'Zmień @:common.PIN_CODE_LABEL',
    staffProfiles: 'Profile pracowników',
    profile: 'Profil',
    user_bug: 'Błąd aplikacji',
    user_kaizen: 'Sugestia zmiany',
    changeSuggestions: 'Sugestie zmian',
    showOnlyCurrentlyAvailable: 'Pokaż tylko obecnie dostępnych',
    resetPassword: 'Resetuj hasło',
    forgotPassword: 'Zapomniałem hasła',
    resetPasswordMailSent: 'Na podany adres e-mail wysłaliśmy link do ustawienia nowego hasła',
    manyEventsThisTime: 'Wiele zdarzeń w tym czasie',
    importScheduleCheckboxLabel: 'Usuń zaplanowane zdarzenia w wybranym miesiącu dla pracowników wymienionych w pliku',
    inheritedEvent: 'Wydarzenie dziedziczone',
    inheritedModifiedEvent: 'Zmodyfikowane wydarzenie dziedziczone',
    equipmentId: 'ID wyposażenia',
    entryId: 'ID wpisu',
    partId: 'ID części',
    suggestionsEmail: 'Adres e-mail, na który będą wysyłane sugestie zmian',
    meters: 'Liczniki',
    scanCardToAuth: 'Przyłóż kartę do czytnika lub wpisz swój @:common.PIN_CODE_LABEL , aby potwierdzić tożsamość.',
    scanCard: 'Zeskanuj kartę',
    cart: 'Skrzynka wymiany',
    emptyCardMessage: 'Zacznij skanować produkty lub wybierz je z listy, aby dodać je do skrzynki wymiany.',
    terminalMode: 'Typ terminala',
    askPermittedUserToConfirmPartsExchange: 'Poproś osobę uprawnioną o potwierdzenie wydania / zwrotu części poprzez zeskanowanie karty lub wpisanie @:common.PIN_CODE_LABEL',
    partsIssued: 'Wydano rzeczy',
    areYouSureAboutCancel: 'Czy na pewno chcesz zakończyć?',
    areYouSureAboutRemoveFromCart: 'Czy na pewno chcesz usunąć ze skrzynki wymiany?',
    standard: 'Standardowy',
    idNumber: 'Numer identyfikacyjny',
    takeParts: 'Pobierz',
    returnParts: 'Zwróć',
    quantityToReturn: 'Ilość do zwrotu',
    partsPendingApproval: 'Zwrócone rzeczy oczekujące na zatwierdzenie',
    partsReturned: 'Zwrócono rzeczy',
    partsAddedToPendingApproval: 'Rzeczy dodano na listę oczekujących na zatwierdzenie',
    approvePartsReturn: 'Zatwierdź zwrot rzeczy',
    partReturnApproved: 'Zwrot rzeczy zatwierdzony',
    partUsage: 'Użycie rzeczy',
    awaitsReturn: 'Oczekuje na potwierdzenie zwrotu',
    entrySelection: 'Wybieranie wpisów',
    correctQuantity: 'Poprawna ilość',
    quantityCorrection: 'Korekta stanu',
    entrySelectionAsc: 'Od najmniejszej ilości',
    entrySelectionDesc: 'Od największej ilości',
    metersSettings: 'Ustawienia liczników',
    countableFields: 'Zliczane pola',
    equipmentTypes: 'Typy maszyn',
    fieldName: 'Nazwa pola',
    countableField: 'Zliczane pole',
    partsStatusTaken: 'Pobrano',
    rejectPartsReturn: 'Odrzuć zwrot rzeczy',
    areYouSureAboutRejectPartsReturn: 'Czy na pewno chcesz odrzucić zwrot rzeczy?',
    partReturnRejected: 'Zwrot rzeczy odrzucony',
    counterFields: 'Pola liczników',
    counterName: 'Nazwa licznika',
    counterFieldName: 'Nazwa pola',
    counterId: 'ID licznika',
    counterUUID: 'UUID licznika',
    counterPickerPlaceholder: 'Wybierz licznik',
    pickFromPartsList: 'Wybierz z listy rzeczy',
    changeLocation: 'Zmień lokalizację',
    isPartStorageRoot: 'Główny magazyn rzeczy',
    areYouSureAboutArchiveIssue: 'Czy na pewno chcesz zarchiwizować to zgłoszenie?',
    ganttChart: 'Wykres Gantta',
    table: 'Tabela',
    fittingEquipment: 'Pasujące wyposażenie',
    notAssigned: 'Niepowiązane',
    countersValues: 'Wartości liczników',
    counters: 'Liczniki',
    machineHourlyCostNotProvided: 'Maszyna nie ma ustawionego kosztu godzinnego',
    eventTypesMightBeDefinedHere: 'Rodzaje zdarzeń możesz zdefiniować tutaj',
    toolsTakenByUser: 'Narzędzia pobrane przez użytkownika',
    predictedReturnDate: 'Przewidywana data zwrotu',
    estimatedTimeOfUsingToolInHours: 'Szacowany czas używania narzędzia (w godzinach)',
    showOnlyAvailable: 'Pokaż tylko dostępne',
    toolTakeLog: 'Historia pobrań i zwrotów',
    takeDate: 'Data pobrania',
    returnDate: 'Data zwrotu',
    personWhoAcceptReturn: 'Osoba przyjmująca zwrot',
    statisticStrategy: 'Strategia dostępności',
    EquipmentPlannedStrategy: 'Według planu pracy',
    EquipmentConstantAvailabilityStrategy: 'Stała dostępność',
    actionAfterIssueFinish: 'Akcja po zakończeniu zgłoszenia',
    enabled: 'Włączone',
    categoryOfNewIssue: 'Kategoria nowego zgłoszenia',
    workNotStarted: 'Praca nie rozpoczęta',
    colorInherited: 'Kolor dziedziczony',
    abcClassification: 'Klasyfikacja ABC',
    xyzClassification: 'Klasyfikacja XYZ',
    classification: 'Klasyfikacja',
    filters: 'Filtry',
    wipeFilters: 'Wyczyść filtry',
    takePartsFromUser: 'Przyjmij rzeczy',
    selectPersonWhoReturns: 'Wybierz osobę która zwraca',
    changePersonWhoReturns: 'Zmień osobę która zwraca',
    returnLocation: 'Lokalizacja zwrotu',
    returnQuantity: 'Zwracana ilość',
    globalSettings: 'Ustawienia globalne',
    delimiter: 'Separator',
    fieldRules: 'Reguły pola',
    fieldValue: 'Wartość pola',
    user_email_regex: 'Reguła e-mail',
    barcodeLength: 'Długość kodu kreskowego.',
    partLine: 'Linia części',
    partFamily: 'Rodzina części',
    partBatch: 'Partia części',
    testValueValid: 'Wartość testowa spełnia regułę',
    testValueNotValid: 'Wartość testowa nie spełnia reguły',
    canBePicked: 'Wybieralna',
    issue_create_photos: 'Dodawanie zdjęć do nowego zgłoszenia',
    issue_create_documents: 'Dodawanie dokumentów do nowego zgłoszenia',
    propagateValues: 'Propaguj wartości',
    archived: 'Zarchiwizowane',
    escalations_tab: 'Eskalacje',
    minute: 'Minuta',
    days: 'Dni',
    hours: 'Godziny',
    minutes: 'Minuty',
    attachment: 'Załącznik',
    attachments: 'Załączniki',
    licenseType: 'Rodzaj uprawnień',
    licenseGroup: 'Grupa uprawnień',
    warnDays: 'Alert przed wygasnieciem (dni)',
    instructionGroup: 'Grupa instrukcji',
    revisionChanges: 'Wprowadzone zmiany',
    document: 'Dokument',
    noInstructionSelected: 'Nie wybrano instrukcji',
    instructionGroups: 'Grupy instrukcji',
    addOption: 'Dodaj opcję',
    ordinal: 'Wartość porządkowa',
    parameterName: 'Nazwa parametru',
    fontColor: 'Kolor czcionki',
    backgroundColor: 'Kolor tła',
    columns: 'Liczba kolumn',
    colspan: 'Szerokość (kolumny)',
    products: 'Produkty',
    controlsTemplates: 'Szablony kontroli',
    controlType: 'Typ kontroli',
    controls: 'Kontrole',
    controlTemplate: 'Szablon kontroli',
    product: 'Produkt',
    checklistsAttachedToControlTemplate: 'Listy kontrolne przypisane do kontroli',
    removeChecklistFromControlTitle: 'Czy na pewno chcesz usunąć tę listę z kontroli?',
    attachChecklistToControl: 'Przypisz listę do kontroli',
    pickedItem: 'Wybrany element',
    pick: 'Wybierz',
    fillControl: 'Uzupełnij kontrolę',
    back: 'Wróć',
    control: 'Kontrola',
    duplicate: 'Duplikuj',
    manageStorageAccess: 'Zarządzaj dostępem do magazynów',
    isStorageRoot: 'Lokalizacja główna magazynu',
    manageVisibleColumns: 'Dostosuj widoczność kolumn',
    columnName: 'Nazwa kolumny',
    visible: 'Widoczne',
    entriesLocation: 'Lokalizacja wpisów',
    details: 'Szczegóły',
    otherData: 'Inne dane',
    moveAllEntriesFromCurrentLocation: 'Przenieś wszystkie wpisy z tej lokalizacji',
    searchInCurrentLocation: 'Szukaj w obecnej lokalizacji',
    transition: 'Przejście',
    notification: 'Powiadomienie',
    emails: 'Adresy e-mail',
    phones: 'Numery telefonów',
    addAction: 'Dodaj akcję',
    message: 'Wiadomość',
    application: 'Aplikacja',
    always: 'Zawsze',
    sms: 'SMS',
    loginTo: 'Zaloguj się do',
    appName: 'Signalo TPM',
    index: 'Indeks',
    showCodeOwnerNameDuringParing: 'Pokaż nazwę właściciela @:common.PIN_CODE_LABEL podczas parowania z kartą / tagiem',
    showUsernameInCart: 'Pokaż nazwę użytkownika w koszyku',
    sampleButtonPlaceholder: 'Przykładowy przycisk',
    preferences: 'Preferencje',
    buttonsSize: 'Wielkość przycisków',
    erase: 'Wymaż',
    selectDictionaryValue: 'Wybierz wartość słownika',
    conditions: 'Warunki',
    partsCartTerminalRequireApproveOnPartsReturn: 'Wymagaj potwierdzenia lub odrzucenia przy zwrocie pobranych rzeczy',
    issueCreated: 'Utworzono zgłoszenie',
    allowedIssuesCategories: 'Dozwolone kategorie zgłoszeń',
    hiddenFields: 'Ukryte pola',
    defaultValues: 'Domyślne wartości',
    paddingBottom: 'Puste miejsce z dołu',
    allVisibleFieldsRequired: 'Wymagaj uzupełnienia wszystkich widocznych pól',
    url: 'URL',
    kind: 'Rodzaj',
    forkfleetConfigId: 'Konfiguracja Forkfleet',
    create: 'Utwórz',
    terminate: 'Zakończ',
    parent: 'Rodzic',
    phoneNumbers: 'Numery telefonów',
    storageDefaults: 'Domyślne wartości magazynu',
    clientSignatureEnabled: 'Podpis osoby pobierającej',
    warehousemanSignatureEnabled: 'Podpis osoby wydającej',
    storage_client_signature_enabled: 'Podpis osoby pobierającej rzeczy z magazynu',
    storage_client_signature_required: 'Wymagaj podpisu osoby pobierającej rzeczy z magazynu',
    storage_warehouseman_signature_enabled: 'Podpis osoby wydającej rzeczy z magazynu',
    storage_warehouseman_signature_required: 'Wymagaj podpisu osoby wydającej rzeczy z magazynu',
    clientReturnSignatureEnabled: 'Podpis osoby zwracającej',
    warehousemanReturnSignatureEnabled: 'Podpis osoby przyjmującej zwrot',
    storage_client_return_signature_enabled: 'Podpis osoby zwracającej rzeczy',
    storage_client_return_signature_required: 'Wymagaj podpisu osoby zwracającej rzeczy',
    storage_warehouseman_return_signature_enabled: 'Podpis osoby przyjmującej zwrot rzeczy',
    storage_warehouseman_return_signature_required: 'Wymagaj podpisu osoby przyjmującej zwrot rzeczy',
    channels: 'Kanały',
    manageState: 'Zarządzaj stanem',
    assignWorker: 'Przypisz pracownika',
    mergeEntriesOfTheSameLocation: 'Scal wpisy z tej samej lokalizacji',
    permission: 'Uprawnienie',
    areYouSureAboutMergeAllEntriesOfThisLocation: 'Czy na pewno chcesz scalić wszystkie wpisy w tej lokalizacji?',
    manageFilters: 'Zarządzaj filtrami',
    responsible_assign_creator_when_no_rule: '[Zgłoszenia] - Przypisz tworzącego jako odpowiedzialnego, jeżeli nie dopasowano reguły',
    recreateIssue: 'Utwórz ponownie zgłoszenie',
    addNewDictionary: 'Dodaj nowy słownik',
    label: 'Etykieta',
    issuesWithRelatedCategories: 'Uwzględnij kategorie podrzędne',
    issuesLimit: 'Limit zgłoszeń',
    issuesLimits: 'Zgłoszenia - limity',
    issueDefaults: 'Zgłoszenia - domyślne wartości',
    flatCategoryPickerLabel: 'Płaska struktura kategorii',
    statementsTemplates: 'Szablony oświadczeń',
    statementTemplate: 'Szablon oświadczenia',
    content: 'Treść',
    readonly: 'Tylko do odczytu',
    defaultValue: 'Domyślna wartość',
    fillParametersToChangeState: 'Wypełnij wartości poniższych parametrów, aby zmienić status',
    countableId: 'ID zliczanego pola',
    formatPattern: 'Wzór formatu',
    countableIncFields: 'Konfiguracja numeracji dokumentów',
    mainShift: 'Zmiana główna',
    singleEvent: 'Pojedynczy wpis',
    seriesOfEvents: 'Seria wpisów',
    omitWeekends: 'Omiń weekendy',
    allEntriesSaved: 'Wszystkie wpisy zostały dodane',
    surveyResults: 'Wyniki ankiet',
    qrActionsSettings: 'Ustawienia akcji kodów QR',
    pendingStatements: 'Oświadczenia do podpisania',
    statementsResults: 'Podpisane oświadczenia',
    statements: 'Oświadczenia',
    statement: 'Oświadczenie',
    userCanChangeContent: 'Użytkownik może zmienić treść',
    statementsEvents: 'Konfiguracja zdarzeń - oświadczenia',
    helpdesk: 'Pomoc techniczna',
    terminalId: 'ID terminala',
    operatorId: 'ID operatora',
    newConversation: 'Nowy wątek',
    formula: 'Formuła',
    fill: 'Uzupełnij',
    webMessage: 'Wiadomość web',
    noResponsibleUser: 'Bez osoby odpowiedzialnej',
    filledAtDate: 'Data wypełnienia',
    filledBy: 'Wypełnione przez',
    parts_quantity_alert_rule: '[Ilość części i materiałów] - Ostrzeżenie',
    employeeNumber: 'Numer pracownika',
    defaultValuesForCategory: 'Domyślne wartości dla kategorii',
    groupsAssignedToCategory: 'Grupy przypisane do kategorii',
    number: 'Numer',
    customers: 'Klienci',
    customer: 'Klient',
    projects: 'Projekty',
    project: 'Projekt',
    report: 'Raport',
    reportGenerationInProgress: 'Trwa generowanie raportu',
    storageStatementGroup: 'Grupa oświadczeń magazynowych',
    simplifiedHandling: 'Uproszczona obsługa zgłoszeń',
    selectWorkArea: 'Wybierz obszar pracy',
    workAreaSelection: 'Wybór obszaru pracy',
    access: 'Dostęp',
    restrictAccess: 'Ogranicz dostęp',
    anchor: 'Zakotwiczenie',
    issueCreator: 'Osoba tworząca zgłoszenie',
    hideSideMenu: 'Ukryj menu boczne',
    appendGroups: 'Grupy do dodania',
    revokeGroups: 'Grupy do usunięcia',
    override: 'Nadpisz',
    restrictGroupAccess: 'Ogranicz dostęp grupom',
    flag: 'Flaga',
    flags: 'Flagi',
    manageFlags: 'Zarządzaj flagami',
    icon: 'Ikona',
    appendFlags: 'Flagi do dodania',
    revokeFlags: 'Flagi do usunięcia',
    checkIsTerminal: 'Sprawdzanie terminala',
    noConditionsDefined: 'Nie zdefiniowano warunków',
    checkIsResponsible: 'Sprawdzenie odpowiedzialności',
    createdOn: 'Zgłoszono z',
    replaceTimespan: 'Okres przydatności od momentu wydania',
    months: 'Miesiące',
    handoversTemplates: 'Szablony wydawek',
    handoverTemplate: 'Szablon wydawki',
    employeeFunction: 'Funkcja pracownika',
    takeHandover: 'Wydaj wydawkę',
    handovers: 'Wydawki',
    expiringHandovers: 'Rzeczy zbliżające się do końca terminu ważności',
    years: 'Lata',
    taken_parts_expiry_warning: 'Ostrzeżenie przed upływem terminu ważności pobranych rzeczy',
    andonView: 'Widok Andon',
    nfc: 'NFC',
    rfid: 'RFID',
    other: 'Inny',
    tags: 'Karty / Tagi',
    tag: 'Karta / Tag',
    userPinCode: '@:common.PIN_CODE_LABEL użytkownika karty',
    emailConfirmation: 'Potwierdzenie adresu email',
    from: 'Od',
    to: 'Do',
    handoversHistory: 'Historia pobrań',
    partUsagesHistory: 'Historia pobrań',
    signaturePinTagConfirmationText1: 'Podpisz się @:common.PIN_CODE_LABEL',
    signaturePinTagConfirmationText2: 'lub zeskanuj swoją kartę.',
    signedWithPinCode: 'Podpisano @:common.PIN_CODE_LABEL',
    signedWithTAG: 'Podpisano Kartą / Tagiem ',
    test_mode: 'Tryb testowy',
    confirmWorkFinish: 'Potwierdź zakończenie pracy',
    workStarted: 'Rozpoczęto pracę',
    workFinished: 'Zakończono pracę',
    workTimeRegister: 'Rejestr czasu pracy',
    measureTypes: 'Typy pomiarów',
    measureType: 'Typ pomiaru',
    measurable: 'Wskaźnik mierzalny',
    measurables: 'Wskaźniki mierzalne',
    measurements: 'Pomiary',
    measurement: 'Pomiar',
    measuredAt: 'Data pomiaru',
    currentVersion: 'Obecna wersja',
    employee: 'Pracownik',
    pin_code_label: 'Nazwa kodu PIN',
    orders: 'Zamówienia',
    productionPlan: 'Plan produkcyjny',
    productionPlans: 'Plany produkcyjne',
    taktTime: 'Takt time',
    target: 'Cel',
    seconds: 'Sekundy',
    inheritValuesFromTenantSettings: 'Dziedzicz domyślne wartości z ustawień globalnych',
    matrices: 'Macierze',
    manageMatrice: 'Zarządzaj macierzą',
    deletionOfMatrixColumnWarnMessage: 'Usunięcie kolumny skutkuje usunięciem wartości.',
    removeColumn: 'Usuń kolumnę',
    editColumn: 'Edytuj kolumnę',
    column: 'Kolumna',
    row: 'Wiersz',
    evaluated: 'Wyliczany',
    of: 'z',
    auto_pin_generation: '@:common.PIN_CODE_LABEL - Automatyczne generowanie',
    or: 'lub',
    rename: 'Zmień nazwę',
    check_only_on_transition: 'Sprawdzaj wyłącznie podczas zmiany statusu',
    license: 'Uprawnienie',
    confirmWorkStart: 'Potwierdź rozpoczęcie pracy',
    workStartAt: 'Rozpoczynasz pracę o',
    workFinishAt: 'Kończysz pracę o',
    workStartedAt: 'Rozpoczęcie pracy',
    workTimeMessage: 'Przepracowałeś',
    haveANiceDay: 'Miłego dnia!',
    farewellText: 'Tekst na ostatnim ekranie',
    namedayLabel: 'Imieniny obchodzą',
    sportEventNearby: 'Najbliższe wydarzenie sportowe',
    autoTranslate: 'Automatyczne tłumaczenie',
    auto_translation: 'Automatyczne tłumaczenia',
    awaitingAutoTranslation: 'Oczekiwanie na automatyczne tłumaczenie',
    inheritAllDefaultValues: 'Dziedzicz wszystkie domyślne wartości z ustawień globalnych',
    escalations: 'Eskalacje',
    escalation: 'Eskalacja',
    notTaken: 'Nierozpoczęte',
    notFinished: 'Niezakończone',
    issueLastChange: 'Ostatnia zmiana',
    mode: 'Tryb',
    repeatLastEscalation: 'Powtarzaj ostatnią wartość eskalacji',
    manageActions: 'Zarządzaj akcjami',
    productionLines: 'Linie produkcyjne',
    productionTerminal: 'Terminal produkcyjny',
    currently: 'Aktualnie',
    currentPlan: 'Obecny plan',
    currentlyInProduction: 'Aktualnie produkowany',
    setProduct: 'Ustaw produkt',
    second: 'Sekund',
    work: 'Praca',
    chartConfiguration: 'Konfiguracja wykresu',
    revisionsToConsider: 'Rewizje do rozpatrzenia',
    allInstructions: 'Wszystkie instrukcje',
    decider: 'Rozpatrujący',
    considerRevision: 'Rozpatrz rewizję',
    and: 'i',
    acceptInstruction: 'Zaakceptuj instrukcję',
    accept: 'Zaakceptuj',
    instructionsToAccept: 'Instrukcje do zapoznania się',
    open: 'Otwórz',
    assignedAt: 'Data przypisania',
    confirmationAt: 'Data potwierdzenia',
    mainInstruction: 'Instrukcja domyślna',
    setAsDefault: 'Ustaw jako domyślną',
    instructionsFlows: 'Instrukcje - przepływy',
    newRevision: 'Nowa rewizja',
    playlist: 'Lista materiałów',
    selectVideo: 'Wybierz film',
    selectVideos: 'Wybierz filmy',
    uploadNewVideo: 'Wgraj nowy film',
    upload_date: 'Data dodania',
    thumbnail: 'Miniaturka',
    duration: 'Czas trwania',
    uploadFromDrive: 'Wgraj z dysku',
    selectAssets: 'Wybierz materiały',
    assets: 'Materiały',
    video: 'Film',
    doYouWantToAddNewMarker: 'Czy chcesz dodać nowy znacznik w tym miejscu?',
    areYouSureAboutRemoveMarker: 'Czy na pewno chcesz usunąć ten znacznik?',
    plants: 'Zakłady fabryczne',
    plant: 'Zakład',
    buildings: 'Budynki',
    building: 'Budynek',
    floors: 'Piętra',
    floor: 'Piętro',
    map: 'Mapa',
    areYouSureAboutMoveMarker: 'Czy na pewno chcesz przesunąć ten znacznik?',
    marker: 'Znacznik',
    addNewMarkerTooltipMessage: 'Aby dodać nowy znacznik kliknij na mapę prawym przyciskiem myszki.',
    skillmatrix: 'Skillmatrix',
    indirectProcess: 'Umiejętności nieliniowe',
    skillLevelConfig: 'Poziomy umiejętności',
    indirectSkillGroups: 'Grupy umiejętności nieliniowych',
    approved: 'Zatwierdzone',
    approvedBy: 'Zatwierdzający',
    skillLevel: 'Poziom umiejętności',
    requiredSkillLevel: 'Wymagany poziom umiejętności',
    step: 'Krok',
    image: 'Zdjęcie',
    skills: 'Umiejętności',
    visits: 'Awizacje',
    responsiblePerson: 'Osoba odpowiedzialna',
    company: 'Firma',
    contactData: 'Dane kontaktowe',
    visit: 'Awizacja',
    inProgress: 'W trakcie',
    gatehouse: 'Stróżówka',
    confirmEnter: 'Potwiedź wejście',
    confirmExit: 'Potwierdź wyjście',
    enter: 'Wejście',
    exit: 'Wyjście',
    entryGuard: 'Osoba wpuszczająca',
    exitGuard: 'Osoba wypuszczająca',
    loginAsThisUser: 'Zaloguj się jako ten użytkownik',
    minBrowserVersion: 'Minimalna wymagana wersja przeglądarki',
    approve: 'Zatwierdź',
    cancelApproval: 'Anuluj zatwierdzenie',
    workPermit: 'Zezwolenie na pracę',
    workPermits: 'Zezwolenia na pracę',
    visit_summary: 'Podsumowanie wizyty',
    createVisit: 'Utwórz wizytę na podstawie zezwolenia',
    employeesAtThePlant: 'Pracownicy na zakładzie',
    purpose: 'Cel',
    allowedAreas: 'Dozwolone obszary',
    switchEmployees: 'Zamień pracownika',
    localIssues: 'Zgłoszenia lokalne',
    cmmsCategoriesSync: 'Synchronizacja kategorii CMMS',
    terminalsGroups: 'Grupy terminali',
    isTree: 'Drzewo',
    isReporter: 'Czy zgłaszający',
    isAnchored: 'Czy zakotwiczone',
    closable: 'Zamykanie zgłoszeń',
    areYouSureAboutCloseIssue: 'Czy na pewno chcesz zamknąć zgłoszenie?',
    check_user: 'Czy użytkownik',
    check_terminal: 'Czy terminal',
    simplifiedIssuesRefreshLabel: 'Czas odświeżania listy - sekundy',
    reloadApp: 'Załaduj ponownie',
    hideWorkAreasButton: 'Ukryj przycisk wyboru obszaru pracy',
    hideClassicViewButton: 'Ukryj przycisk widoku klasycznego',
    hideAddIssueButton: 'Ukryj przycisk dodawania nowego zgłoszenia',
    filesManager: 'Manager plików',
    addFolder: 'Dodaj folder',
    upload: 'Prześlij',
    noFolders: 'Brak folderów',
    noFiles: 'Brak plików',
    folders: 'Foldery',
    folder: 'Folder',
    files: 'Pliki',
    file: 'Plik',
    removeFolderFilesQuestion: 'Co zrobić z plikami znajdującymi się w tym folderze?',
    targetFolder: 'Folder docelowy',
    addInstruction: 'Dodaj instrukcję',
    goToFolder: 'Idź do folderu',
    defaultProdLine: 'Domyślna linia produkcyjna',
    pin_code_field_type: 'Typ kodu pin',
    instructionsAccess: 'Uprawnienia',
    noSections: 'Bez sekcji',
    requireReadingInstruction: 'Wymagaj zapoznania się z instrukcją',
    hide_parameter_types: 'Nie wyświetlaj typu parametru',
    unknownVersion: 'Nieznana',
    displayInstructionEveryTime: 'Wyświetlaj instrukcję za każdym razem',
    requireSigningInstructionEveryTime: 'Wymagaj podpisania instrukcji za każdym razem',
    signedInstructions: 'Podpisane instrukcje',
    signedAt: 'Data podpisania',
    signatures: 'Podpisy',
    signature: 'Podpis',
    systemGroup: 'Grupa systemowa',
    pickable: 'Wybieralne',
    leaf: 'Wybrany element',
    path: 'Ścieżka',
    displayMode: 'Tryb wyświetlania',
    chart: 'Wykres',
    preSignedStatement: 'Oświadczenie podpisane papierowo (kompletne)',
    myOpenIssues: 'Moje otwarte zgłoszenia',
    myAssignedIssues: 'Zgłoszenia przypisane do mnie',
    bannedEmployees: 'Pracownicy z zakazem wstępu',
    banEmployee: 'Zakaż wstępu',
    banned: 'Z zakazem wstępu',
    employeeBans: 'Zakazy wstępu',
    commentAboutEmployee: 'Odnosi się do pracownika',
    comments: 'Komentarze',
    visitLog: 'Historia wejść',
    displayParametersOnMainScreen: 'Wyświetlaj parametry na głównym ekranie',
    lockPin: 'Zablokuj zmianę PINU',
    pinLocked: 'PIN zablokowany',
    sortChange: 'Kolejność wyświetlania',
    asc: 'Rosnąco',
    desc: 'Malejąco',
    alphanumeric: 'Alfanumeryczny',
    numeric: 'Numeryczny',
    modificationDate: 'Data modyfikacji',
    categoryStructure: 'Struktura kategorii',
    appVersionMismatch: 'Nieprawidłowa wersja aplikacji',
    appVersionMismatchMessage: 'Odśwież aplikację, aby zaktualizować wersję.',
    fromNewest: 'Od najnowszych',
    fromOldest: 'Od najstarszych',
    negation: 'Negacja',
    sortOrderService: 'Obsługa',
    newIssueIgnoreFilters: 'Ignoruj zastosowane filtry podczas tworzenia nowego zgłoszenia',
    refreshInterval: 'Czas odświeżania listy',
    multiline: 'Wiele wierszy',
    expanded: 'Rozwinięte',
    quizzes: 'Quizy',
    courses: 'Kursy',
    course: 'Kurs',
    quiz: 'Quiz',
    maxPoints: 'Maksymalna liczba punktów',
    minPointsToPass: 'Minimalna liczba punktów do zaliczenia',
    questionFailQuiz: 'Niezaliczenie pytania dyskwalifikuje cały quiz',
    points: 'Punkty',
    currentVersionPreview: 'Podgląd aktualnej wersji',
    docs_sidebar_hidden_by_default: 'Ukryj panel boczny w dokumentach',
    allCategories: 'Wszystkie kategorie',
    test: 'Test',
    tests: 'Testy',
    copy: "Skopiuj",
    trusted_device_code_login_users_limit: "Szybkie logowanie - liczba wyświetlanych użytkowników"
  },
  placeholders: {
    part: 'Wybierz część',
    nameSurname: 'Imię i nazwisko',
    phone: 'Numer telefonu',
    password: 'Hasło',
    password_confirmation: 'Powtórz hasło',
    email: 'E-mail'
  },
  auth: {
    notYourAccountQuestion: 'To nie ty?'
  },
  signup: {
    registered: 'Zarejestrowano!',
    emailConfirmed: 'Email został potwierdzony.',
    registered_info: 'Poproś przełożonego o aktywację konta',
    confirmEmailAddress: 'Twoje konto zostało utworzone, kliknij w link w wiadomości email, aby potwierdzić adres.',
    tokenNotFoundError: 'Email został już potwierdzony lub link jest niepoprawny.'
  },
  paramTypes: {
    text: 'Tekst',
    note: 'Notatka',
    integer: 'Liczba całkowita',
    decimal: 'Liczba rzeczywista',
    date: 'Data',
    time: 'Godzina',
    datetime: 'Data i godzina',
    color: 'Kolor',
    icon: 'Ikona',
    boolean: 'Nie / Tak',
    dictionary: 'Słownik',
    user: 'Użytkownik',
    radioGroup: 'Jeden do wyboru',
    checkboxGroup: 'Wiele do wyboru',
    signature: 'Podpis',
    number: 'Liczba',
    rating: 'Ocena',
    document: 'Dokument',
    image: 'Zdjęcie'
  },
  tableHeaders: {
    id: 'ID',
    name: 'Nazwa',
    path: 'Ścieżka',
    category: 'Kategoria',
    equipment: 'Wyposażenie',
    criticality: 'Krytyczność',
    status: 'Status',
    created: 'Utworzone',
    createdBy: 'Utworzone przez',
    fp: 'FP',
    type: 'Typ',
    designation: 'Oznaczenie',
    symbol: 'Symbol',
    serialNumber: 'Nr fabryczny',
    registryNumber: 'Nr ewidencyjny',
    udtNumber: 'Nr UDT',
    manufacturedYear: 'Rok produkcji',
    actions: 'Akcje',
    title: 'Tytuł',
    data: 'Data',
    archived: 'Zarchiwizowane',
    manufacturerSymbol: 'Symbol producenta',
    min: 'Min',
    opt: 'Opt',
    quantity: 'Stan (ilość)',
    priority: 'Priorytet',
    price: 'Cena',
    kind: 'Rodzaj',
    description: 'Opis',
    default: 'Domyślny',
    warning: 'Ostrzeżenie',
    problem: 'Problem',
    fixing: 'Rozwiązywanie',
    changeOrder: 'Zmień kolejność',
    required: 'Wymagane',
    icon: 'Ikona',
    color: 'Kolor',
    subCategories: 'Kategorie podrzędne',
    barCode: 'Kod kreskowy',
    subLocations: 'Lokalizacje podrzędne',
    categories: 'Kategorie',
    image: 'Zdjęcie',
    nameSurname: 'Imię i nazwisko',
    email: 'Email',
    phone: 'Numer telefonu',
    function: 'Funkcja',
    department: 'Wydział',
    accountStatus: 'Status konta',
    shift: 'Zmiana',
    licenseNumber: 'Number uprawnień',
    issuedDate: 'Data wydania',
    expiresDate: 'Data ważności',
    entries: 'Wpisy',
    entryNumber: 'Numer wpisu',
    buyPrice: 'Cena zakupu',
    currentLocationQuantity: 'Ilość w bieżącej lokalizacji',
    group: 'Grupa',
    string: 'Tekst',
    integer: 'Liczba',
    toolsGroup: 'Grupa narzędzi',
    manufacturer: 'Producent',
    shortName: 'Nazwa skrócona',
    locationName: 'Nazwa lokalizacji',
    subLocationsQuantity: 'Lokalizacje podrzędne',
    isWorkingOnIssue: 'Ma podjęte zgłoszenie',
    openIssues: 'Otwarte zgłoszenia',
    notificationDate: 'Data powiadomienia',
    titleAndDescription: 'Tytuł i opis',
    read: 'Odczytane',
    created_at: 'Przypisanie',
    received_at: 'Odczytanie',
    started_at: 'Podjęcie',
    arrived_at: 'Przybycie',
    finished_at: 'Zakończenie',
    country: 'Kraj',
    city: 'Miasto',
    progress: 'Progres',
    deleted_at: 'Data usunięcia',
    about_issue: 'Dotycząca zgłoszenia',
    action: 'Akcja'
  },
  searchConfig: {
    id: 'Id',
    name: 'Nazwa',
    shortName: 'Nazwa skrócona',
    country: 'Kraj',
    email: 'Email',
    group: 'Grupa',
    planned: 'Planowane',
    category: 'Kategoria',
    object: 'Obiekt',
    criticality: 'Krytyczność',
    fp: 'FP',
    type: 'Typ',
    designation: 'Oznaczenie',
    symbol: 'Symbol',
    serialNumber: 'Numer fabryczny',
    registryNumber: 'Numer ewidencyjny',
    udtNumber: 'Numer UDT',
    manufacturedYear: 'Rok produkcji',
    manufacturerSymbol: 'Symbol producenta',
    priority: 'Priorytet',
    quantity: 'Ilość',
    buyPrice: 'Cena zakupu',
    kind: 'Rodzaj',
    toolsGroup: 'Grupa narzędzi',
    function: 'Funkcja',
    department: 'Wydział',
    status: 'Status',
    nameSurname: 'Imię i nazwisko',
    phone: 'Telefon',
    barCode: 'Kod kreskowy',
    color: 'Kolor',
    icon: 'Ikona',
    machine: 'Maszyna',
    tool: 'Narzędzie',
    urgentAndOutdated: 'Pilne i przeterminowane',
    urgent: 'Pilne',
    expired: 'Przeterminowane',
    manufacturer: 'Producent'
  },
  errors: {
    partMustHaveEntriesToCorrectQuantity: 'Część musi mieć wpisy, aby skorygować ilość.',
    error: 'Wystąpił błąd',
    wrongEmail: 'Niewłaściwy adres email',
    wrongPasswordLength: 'Hasło musi zawierać co najmniej 8 znaków.',
    wrongCodeLength: '@:common.PIN_CODE_LABEL musi zawierać co najmniej 5 znaków.',
    passwordsNotMatch: 'Hasła nie są takie same',
    codesNotMatch: '@:common.PIN_CODE_LABEL nie są takie same',
    codeAlreadyInUse: 'Ten @:common.PIN_CODE_LABEL jest już w użyciu',
    expiresDateLowerThanIssued: 'Data ważności nie może być mniejsza niż data wydania',
    cannotMoveToTheSameLocation: 'Nie można przenieść do tej samej lokalizacji.',
    locationRequired: 'Lokalizacja jest wymagana.',
    putQuantity: 'Podaj ilość którą chcesz oddzielić.',
    valueGreaterThanQuantity: 'Wartość przekracza całkowitą ilość.',
    wholeCannotBeDistinguished: 'Nie można wydzielić całości.',
    yearMustBeGreaterThan: 'Rok musi być większy niż ',
    passwordConfirmationNotMatch: 'Hasła nie są takie same',
    mustBeInteger: 'Wartość musi być liczbą całkowitą',
    valueTooLong: 'Za długa wartość',
    wrongPhoneNumber: 'Zły numer telefonu',
    phoneNumberAlreadyTaken: 'Ten numer telefonu jest już używany',
    emailAlreadyTaken: 'Ten adres email jest już używany',
    notFound: 'Nie znaleziono',
    sessionExpired: 'Sesja wygasła',
    tenantNotFound: 'Wskazana konfiguracja nie istnieje. Popraw adres strony i spróbuj ponownie.',
    selectEquipmentTypeToConfigureParameters: 'Wybierz typ wyposażenia, aby skonfigurować parametry',
    noParametersToConfigure: 'Wybrany typ wyposażenia nie ma parametrów do skonfigurowania',
    terminalAlreadyRegistered: 'Ten terminal został już zarejestrowany.',
    invalidPin: 'Niepoprawny @:common.PIN_CODE_LABEL',
    eventCollision: 'Czas wydarzenia które chcesz dodać nakłada się na czas trwania innego wydarzenia.',
    mustStartWithSlash: 'Musi zaczynać się od /',
    mustBeBetween: 'Wartość musi się mieścić pomiędzy:',
    minLength: 'Minimalna długość: ',
    maxLength: 'Maksymalna długość: ',
    invalidInputForCodeType: 'Niepoprawna wartość dla wybranego typu kodu',
    invalidCodeOrNoPermission: '@:common.PIN_CODE_LABEL jest nieprawidłowy lub nie masz wystarczających uprawnień do wykonania tej akcji.',
    endDateLowerThanStart: 'Data końcowa nie może być wcześniejsza niż data początkowa',
    iconAlreadyTaken: 'Ikona jest już w użyciu',
    notEnoughQuantityInStorage: 'Niewystarczająca ilość w magazynie.',
    valueTooLow: 'Zbyt niska wartość',
    valueTooHigh: 'Zbyt duża wartość',
    maxAvailableQuantity: 'Maksymalna ilość',
    someFilesRejectedDueToSize: 'Niektóre pliki zostały odrzucone ze względu na rozmiar. Maksymalny rozmiar pojedynczego pliku:',
    cameraDeviceNotFound: 'Nie wykryto kamery',
    cameraPermissionDenied: 'Dostęp do kamery urządzenia został zablokowany w ustawieniach strony, zresetuj ustawienia witryny i zezwól na używanie kamery.',
    uploadError: 'Błąd przesyłania pliku',
    finishedSuccessfully: 'Zakończono pomyślnie',
    passwordResetLinkExpired: 'Ważność linku do zmiany hasła wygasła',
    noInternetConnection: 'Brak połączenia z internetem',
    partsAvailabilityError: 'Dostępność niektórych z wybranych elementów uległa zmianie. Edytuj zawartość swojej skrzynki wymiany i spróbuj ponownie.',
    minimum: 'Minimalna wartość',
    mustBeGreaterThan: 'Wartość musi być większa niż',
    noEventTypesDefined: 'Nie zdefiniowano typów wydarzeń',
    regexPatternDelimiterError: 'Separator musi być pominięty znakiem `\\` np: \\/',
    emailNotMatchPatternDefiniedInTenant: 'Adres email nie pasuje do wzorca zdefiniowanego w konfiguracji',
    max: 'Maksymalna wartość',
    min: 'Minimalna wartość',
    allLanguagesRequired: 'Uzupełnij wartość we wszystkich językach',
    fileTooBigToOpenWillBeDownloaded: 'Plik jest duży, aby go wyświetlić, plik zostanie pobrany na twoje urządzenie.',
    surveyNotFound: 'Nie znaleziono ankiety',
    actionNotEnabled: 'Kod jest nieaktywny',
    loginRequired: 'Wymagane logowanie',
    noPermissionForSelectedFolder: 'Nie masz uprawnień do wyświetlenia zawartości tego katalogu.',
    noFoldersAssignedForTerminal: 'Brak przypisanych katalogów z instrukcjami',
    noChecklistsAssignedToControl: 'Wybrana kontrola nie posiada przypisanej listy kontrolnej',
    maximum: 'Maksymalna wartość',
    versionErrorMessage: 'Twoje ostatnie zmiany nie zostały zapisane, ponieważ ktoś inny dokonał zmian w tym samym czasie. Odśwież stronę i spróbuj ponownie.',
    duplicateError: 'Taka wartość już istnieje',
    fillAllRequiredParameters: 'Uzupełnij wszystkie wymagane parametry.',
    wrongValue: 'Błędna wartość',
    noParametersDefined: 'Nie zdefiniowano parametrów',
    noFlagsConfigured: 'Nie zdefiniowano flag',
    noEnoughParts: 'Brak wystarczającej ilości przedmiotów',
    pinNotBelongsTo: 'Ten @:common.PIN_CODE_LABEL nie należy do',
    tagNotBelongsTo: 'Ta Karta / Tag nie należy do',
    noDictionaryEntries: 'Wybrany słownik nie zawiera wpisów',
    noConnectionWithServer: 'Brak połączenia z serwerem'
  },
  navDrawer: {
    dashboard: 'Dashboard',
    issues: 'Zgłoszenia',
    timetable: 'Harmonogram',
    machines: 'Maszyny',
    tools: 'Narzędzia',
    parts: 'Części i materiały',
    storage: 'Magazyn',
    staff: 'Personel',
    configuration: 'Konfiguracja',
    notes: 'Notatki',
    notifications: 'Powiadomienia',
    profile: 'Profil',
    supervision: 'Dozór'
  },
  barcodeScanner: {
    barCode: 'Kod kreskowy',
    id: 'ID'
  },
  categoryPicker: {
    categoryPlaceholder: 'Kategoria',
    selectCategory: 'Wybierz kategorię',
    noSubcategories: 'Brak kategorii podrzędnych'
  },
  companies: {
    addCompany: 'Dodaj firmę',
    editCompany: 'Edytuj firmę',
    fullName: 'Pełna nazwa',
    shortName: 'Nazwa skrócona',
    group: 'Grupa',
    country: 'Kraj',
    city: 'Miasto',
    street: 'Ulica',
    postCode: 'Kod pocztowy',
    phone: 'Telefon',
    email: 'Email',
    website: 'Strona internetowa',
    NIP: 'NIP',
    REGON: 'REGON',
    active: 'Aktywna',
    nameRequired: 'Nazwa jest wymagana',
    shortNameRequired: 'Nazwa jest wymagana'
  },
  issues: {
    tookAt: 'Podjęte',
    changeHistory: 'Historia zmian',
    load: 'Załaduj',
    date: 'Data',
    createdBy: 'Wprowadził',
    action: 'Akcja',
    data: 'Dane',
    created: 'Utworzono',
    updated: 'Zaktualizowano',
    deleted: 'Usunięto',
    restored: 'Przywrócono',
    issues: 'Zgłoszenia',
    issue: 'Zgłoszenie',
    by: 'przez',
    name: 'Nazwa',
    applicantsDescription: 'Opis zgłaszającego',
    accidentDescription: 'Opis awarii',
    jobDescription: 'Opis zlecenia',
    jobSummary: 'Podsumowanie zlecenia',
    category: 'Kategoria',
    status: 'Status',
    noFlow: '[Brak przepływu]',
    equipment: 'Wyposażenie',
    criticality: 'Krytyczność',
    hour: 'Godzina',
    formalConfirmation: 'Formalne potwierdzenie',
    formalConfirmationShortcut: 'FP',
    formalConfirmationRequired: 'FP wymagane',
    parameter: 'Parametr',
    value: 'Wartość',
    addIssue: 'Dodaj zgłoszenie',
    expectedCompletionDate: 'Przewidywany termin realizacji',
    issueDescription: 'Opis zdarzenia',
    partsUsage: 'Rozchód części',
    assignedEmployees: 'Przypisani pracownicy',
    requiredHelp: 'Potrzebne wsparcie',
    returnParts: 'Zwróć część',
    confirmUnassignEmployeeWhoTakenIssue: 'Ten użytkownik podjął wybrane zgłoszenie. Czy napewno chcesz go usunąć?',
    responsibleUser: 'Osoba odpowiedzialna za zgłoszenie',
    responsibleRules: 'Odpowiedzialni - reguły',
    received_decision_at: 'Data zapoznania się zgłaszającego z decyzją',
    groupRules: 'Grupy - reguły',
    watchersRules: 'Obserwujący - reguły'
  },
  machines: {
    machines: 'Maszyny',
    xIssuesNotPlanned: ' (nie planowanych)',
    noIssues: 'Brak zgłoszeń'
  },
  maintenance: {
    maintenance: 'Harmonogram'
  },
  notes: {
    notes: 'Notatki',
    note: 'Notatka',
    title: 'Tytuł',
    archived: 'Zarchiwizowane'
  },
  parts: {
    parts: 'Części i materiały',
    warehouseOutgoings: 'Rozchód magazynowy',
    fittingEquipmentTypes: 'Pasujące typy wyposażenia',
    goToEquipmentType: 'Przejdź do typu wyposażenia',
    selectPart: 'Wybierz część',
    usageReason: 'Powód',
    usageOtherReason: 'Inny',
    replacements: 'Zamienniki',
    partEntry: 'Wpis części',
    goToPart: 'Idź do części'
  },
  costCenter: {
    costCenter: 'Miejsce powstania kosztów'
  },
  equipmentTypes: {
    equipment: 'Wyposażenie',
    newEquipmentType: 'Nowy typ wyposażenia',
    fittingParts: 'Pasujące części i materiały',
    goToParts: 'Przejdź do części'
  },
  machinesAndToolsDefinitions: {
    machinesAndToolsDefinitions: 'Definicje typów maszyn i narzędzi'
  },
  flows: {
    flow: 'Przepływ',
    states: 'Stany',
    transitions: 'Przejścia',
    state: 'Stan',
    default: 'Domyślny',
    warning: 'Ostrzeżenie',
    problem: 'Problem',
    fixing: 'Naprawa',
    finished: 'Ukończone',
    access: {
      create: 'Tworzenie',
      read: 'Odczyt',
      update: 'Modyfikacja',
      delete: 'Usuwanie',
      read_unassigned: 'Odczyt nieprzypisanych',
      assign: 'Przypisanie',
      unassign: 'Usunięcie przypisania',
      close: 'Zamykanie'
    },
    instructions: {
      default: 'Domyślny',
      fixing: 'Rozpatrywanie',
      finished: 'Ukończone',
      creation: "Tworzenie",
      verification: "Weryfikacja",
      acceptation: "Akceptacja",
      publication: "Publikacja"
    },
    accessLabels: {
      terminal: 'Terminal który zgłosił problem',
      creator: 'Użytkownik który zgłosił problem',
      responsible: 'Użytkownik który jest odpowiedzialny za problem',
      group: 'Grupa użytkowników która jest odpowiedzialna za problem',
      anchor: 'Terminal do którego przypięte jest zgłoszenie',
      watchers: 'Użytkownicy obserwujący problem'
    },
    sync_returned_rejected: 'Synchronizacja - odrzucone',
    sync_returned_finished: 'Synchronizacja - zakończone',
    current: 'Bieżące'
  },
  parameters: {
    categoryParameter: 'Parametr kategorii',
    equipmentTypeParameter: 'Parametr typu wysposażenia'
  },
  staff: {
    staff: 'Personel',
    editUserAccess: 'Edytuj dostęp użytkownika',
    permissionsUpdated: 'Zaktualizowano uprawnienia',
    passwordUpdated: 'Zaktualizowano hasło',
    codeUpdated: 'Zaktualizowano @:common.PIN_CODE_LABEL',
    changePasswordAndCode: 'Zmień hasło i @:common.PIN_CODE_LABEL',
    goToLicenses: 'Idź do uprawnień',
    editAccess: 'Edytuj dostęp',
    editUser: 'Edytuj użytkownika',
    addUser: 'Dodaj nowego użytkownika',
    nameAndLastname: 'Imię i nazwisko',
    accountStatus: 'Status konta',
    phoneNumber: 'Numer telefonu',
    department: 'Wydział',
    passwordChange: 'Zmiana hasła',
    codeChange: 'Zmiana @:common.PIN_CODE_LABEL',
    newPassword: 'Nowe hasło',
    confirmNewPassword: 'Potwierdź nowe hasło',
    changePassword: 'Zmień hasło',
    newCode: 'Nowy @:common.PIN_CODE_LABEL',
    confirmNewCode: 'Potwierdź nowy @:common.PIN_CODE_LABEL',
    changeCode: 'Zmień @:common.PIN_CODE_LABEL',
    addEntry: 'Dodaj wpis',
    editEntry: 'Edytuj wpis',
    editLicense: 'Edytuj licencję',
    addLicense: 'Dodaj licencję',
    licenseNumber: 'Numer uprawnień',
    prefferedLocale: 'Preferowany język',
    oldPassword: 'Stare hasło',
    newPasswordConfirmation: 'Potwierdź nowe hasło',
    reading: 'Wyświetlanie',
    creating: 'Wprowadzanie',
    updating: 'Modyfikacja',
    deleting: 'Usuwanie',
    importScheduleFile: 'Importuj harmonogram z pliku',
    importSchedule: {
      errors: {
        user_not_found: 'Nie znaleziono użytkownika [{user}]',
        user_found_many: 'Znaleziono {count} użytkowników [{user}]',
        event_collision: 'Kolizja wydarzeń',
        hour_cell_parse: 'Błąd parsowania komórki'
      }
    },
    oldCode: 'Stary @:common.PIN_CODE_LABEL',
    newCodeConfirmation: 'Potwierdzenie nowego @:common.PIN_CODE_LABEL',
    setAccessBasedOnProfile: 'Nadaj dostęp na podstawie profilu',
    selectAccessMergeType: 'Wybierz sposób łączenia uprawnień',
    noAccessesInProfileMessage: 'Wybrany profil nie posiada żadnych uprawnień. Czy chcesz nadpisać mimo to?'
  },
  breadCrumb: {
    configuration: 'Konfiguracja',
    locations: 'Lokalizacje',
    home: 'Dashboard',
    machines: 'Maszyny',
    tools: 'Narzędzia',
    issues: 'Zgłoszenia',
    notes: 'Notatki',
    maintenance: 'Harmonogram',
    parts: 'Części i materiały',
    warehouse: 'Magazyn',
    dictionary: 'Słownik',
    categories: 'Kategorie',
    parameters: 'Parametry',
    costCenter: 'Miejsce powstania kosztów',
    flows: 'Przepływy',
    states: 'Stany',
    transitions: 'Przejścia',
    shiftSchemas: 'Schematy zmian',
    shifts: 'Zmiany',
    definitionsOfMachinesAndToolsTypes: 'Definicje typów maszyn i narzędzi',
    add: 'Dodawanie',
    edit: 'Edycja',
    staff: 'Personel',
    licenses: 'Uprawnienia',
    entries: 'Wpisy',
    companiesDatabase: 'Baza firm',
    companyManagement: 'Zarządzanie firmą',
    notifications: 'Powiadomienia',
    notificationsManagement: 'Zarządzanie powiadomieniami',
    myProfile: 'Mój profil'
  },
  attributeTypes: {
    text: 'Tekst',
    note: 'Notatka',
    integer: 'Liczba całkowita',
    decimal: 'Liczba zmiennoprzecinkowa',
    date: 'Data',
    time: 'Godzina',
    dateTime: 'Data i godzina',
    color: 'Kolor',
    icon: 'Ikona',
    boolean: 'Tak/Nie'
  },
  costCalculator: {
    costCalculator: 'Kalkulator kosztów',
    waitTime: 'Czas oczekiwania',
    serviceTime: 'Czas obsługi',
    suspendTime: 'Czas zawieszenia',
    totalTime: 'Czas całkowity',
    estimatedCost: 'Szacowany koszt',
    calculate: 'Przelicz',
    withoutParts: 'Bez części',
    timeOfReportingIssue: 'Zgłoszenie zdarzenia',
    timeOfServiceStart: 'Rozpoczęcie obsługi',
    timeOfReportingEnd: 'Zakończenie obsługi',
    days: 'Dni',
    hours: 'Godzin',
    minutes: 'Minut',
    enterNewTime: 'Podaj nowy czas',
    fromIssueReportToServiceStart: 'Czas od zgłoszenia do rozpoczęcia',
    fromServiceStartToServiceEnd: 'Czas od rozpoczęcia obsługi do zakończenia obsługi',
    workTime: 'Czas pracy',
    includeAwaitingTime: 'Wlicz czas oczekiwania'
  },
  dashboard: {
    components: {
      myIssues: {
        title: 'Moje zgłoszenia',
        assignedToMe: 'Przypisane do mnie',
        assignedToGroup: 'Przypisane do grupy',
        tookByMe: 'Podjęte przeze mnie',
        takeIssueBtn: 'Podejmij',
        arriveIssueBtn: 'Przybyłem',
        finishIssueBtn: 'Zakończ',
        history: 'Historia',
        myResponsible: 'Zgłoszenia za które jestem odpowiedzialny',
        unassignIssue: 'Anuluj podjęcie'
      }
    }
  },
  synchronization: {
    enabled: 'Uruchomione',
    hasHeaders: 'Posiada nagłówki',
    leaveFile: 'Pozostaw plik',
    host: 'Host',
    port: 'Port',
    protocol: 'Protokół',
    username: 'Nazwa użytkownika',
    password: 'Hasło',
    root: 'Ścieżka',
    encoding: 'Kodowanie',
    separator: 'Separator',
    externalSystemId: 'ID w systemie zewnętrznym',
    cronExpression: 'Wyrażenie Cron',
    appendFullStock: 'Dołącz całość',
    includeHeaders: 'Dołącz nagłówki',
    withTrashed: 'Wraz z usuniętymi danymi',
    filePattern: 'Wzór pliku',
    categoriesRootCategoryId: 'Kategoria domowa dla importowanych kategorii',
    systemKeyname: 'Identyfikator systemu',
    address: 'Adres',
    token: 'Token'
  },
  processes: {
    created_at: 'Data utworzenia',
    started_at: 'Data startu',
    updated_at: 'Data aktualizacji',
    finished_at: 'Data ukończenia',
    attempts: 'Ilość prób',
    queue: 'Kolejka',
    job_id: 'ID procesu',
    job_uuid: 'UUID procesu',
    progress_max: 'Progress maksymalny',
    progress_now: 'Progress obecny',
    outputMessage: 'Wiadomość',
    status: {
      queued: 'W kolejce',
      executing: 'Wykonywane',
      finished: 'Ukończone',
      failed: 'Nieudany',
      retrying: 'Ponawianie',
      undefined: 'Nieznany'
    }
  },
  weekDays: {
    mo: 'Poniedziałek',
    tu: 'Wtorek',
    we: 'Środa',
    th: 'Czwartek',
    fr: 'Piątek',
    sa: 'Sobota',
    su: 'Niedziela'
  },
  reports: {
    reports: 'Raporty',
    dateFrom: 'Data od',
    dateTo: 'Data do',
    types: {
      operation_sheet: 'Arkusz operacyjny',
      issue_summary: 'Podsumowanie zgłoszeń',
      grouped_summary: 'Raport zbiorczy',
      storage_operations: 'Operacje magazynowe',
      part_quantity_export: 'Stany magazynowe'
    },
    reportFor: 'Raport dla',
    includeQuantityLeft0: 'Uwzględnij części ze stanem zerowym',
    dayMinusN: 'Dzień minus n',
    currentWeek: 'Obecny tydzień',
    lastWeek: 'Zeszły tydzień',
    currentMonth: 'Obecny miesiąc',
    lastMonth: 'Zeszły miesiąc',
    currentYear: 'Obecny rok',
    lastYear: 'Zeszły rok',
    nDays: 'Liczba dni',
    todayMinusN: 'Dziś minus n'
  },
  logs: {
    comment: 'Komentarz',
    issues: {
      escalation: 'Eskalacje ({minutes} min) - wysłano powiadomienie'
    },
    issueEmployee: {
      assignedAt: 'Pracownik przypisany:',
      receivedAt: 'Pracownik odczytał:',
      tookAt: 'Pracownik podjął',
      finishedAt: 'Pracownik zakończył',
      deletedAt: 'Pracownik usunięty ze zgłoszenia'
    }
  },
  units: {
    no: 'Sztuka',
    st: 'Zestaw',
    pr: 'Para'
  },
  rrule: {
    frequency: 'Cykliczność',
    dtstart: 'Data rozpoczęcia',
    tzid: 'Strefa czasowa',
    until: 'Data zakończenia',
    count: 'Liczba powtórzeń',
    interval: 'Interwał',
    wkst: 'Dzień rozpoczęcią',
    byweekday: 'Tygodniowo',
    bymonth: 'Miesięcznie',
    yearly: 'Rocznie',
    monthly: 'Miesięcznie',
    weekly: 'Tygodniowo',
    daily: 'Dziennie',
    hourly: 'Co godzinę',
    minutely: 'Co minutę',
    secondly: 'Co sekundę',
    infinitePeriodicityMessage: 'Cykliczność jest nieskończona, ze względu na wydajność wyświetlanych jest tylko 500 wierszy'
  },
  months: {
    jan: 'Styczeń',
    feb: 'Luty',
    mar: 'Marzec',
    apr: 'Kwiecień',
    may: 'Maj',
    jun: 'Czerwiec',
    jul: 'Lipiec',
    aug: 'Sierpień',
    sep: 'Wrzesień',
    oct: 'Październik',
    nov: 'Listopad',
    dec: 'Grudzień'
  },
  charts: {
    charts: 'Wykresy',
    count: 'Liczba zgłoszeń',
    total: 'Łączny czas zgłoszeń (od zgłoszenia do zakończenia)',
    serw: 'Łączny czas naprawy (Ttotal - Twait)',
    mttr1: 'Średni czas od zgłoszenia do zakończenia',
    mttr2: 'Średni czas od podjęcia do zakończenia',
    mtbf: 'Średni czas pomiędzy awariami',
    mttf: 'Średni czas do wystąpienia awarii',
    type: 'Typ wykresu',
    pie: 'Wykres kołowy',
    bar: 'Wykres słupkowy',
    query: 'Zakres danych',
    groupBy: 'Grupuj po',
    sum: 'Suma',
    avg: 'Średnia',
    min: 'Minimum',
    max: 'Maksimum',
    eq: 'Równa się',
    neq: 'Nie równa się',
    gt: 'Więcej niż',
    lt: 'Mniej niż',
    gte: 'Więcej lub równe',
    lte: 'Mniej lub równe',
    in: 'Zawiera',
    nin: 'Nie zawiera',
    btw: 'Pomiędzy',
    chartOptions: 'Opcje wykresu',
    line: 'Wykres liniowy',
    mixed: 'Mieszany',
    chartDisplayOptions: 'Opcje wyświetlania',
    fillGaps: 'Uzupełnij braki'
  },
  regexBuilder: {
    regexValue: 'Wartość wyrażenia regularnego',
    global: 'Globalne',
    multiline: '^ i $ odpowiadają początkowi/końcowi linii',
    insensitive: 'Bez rozróżniania wielkości liter',
    extended: 'Ignoruj białe znaki',
    singleline: 'Kropka odpowiada nowej linii',
    unicode: 'Unicode',
    Ungreedy: 'Leniwe kwantyfikatory',
    Anchored: 'Zakotwiczenie na początku wzorca lub na końcu ostatniego dopasowania',
    Jchanged: 'Zezwalaj na duplikaty nazw podwzorców',
    DollarEndOnly: '$ pasuje tylko do końca wzorca',
    testValue: 'Wartość testowa'
  },
  tpm: {
    history: 'Historia',
    standardObstacle: 'Standardowa przeszkoda',
    customObstacle: 'Niestandardowa przeszkoda',
    bhp: 'BHP',
    returnToMainPage: 'Powrót do strony głównej',
    bhpMessage: 'Jeżeli widzisz zagrożenia bezpieczeństwa zgłoś to proszę bez zwłoki do bezpośredniego przełożonego i użyj formatki zgłoszenia która znajduje się pod tablicą ogłoszeń',
    ok: 'Ok',
    send: 'Wyślij',
    description: 'Opis',
    title: 'Tytuł',
    realization: 'Realizacja',
    answer: 'Odpowiedź',
    pendingSurveys: 'Trwające ankiety',
    surveys: 'Ankiety',
    systemSurvey: 'Ankieta systemowa',
    addPage: 'Dodaj stronę',
    createSurvey: 'Utwórz ankietę',
    addSection: 'Dodaj sekcję',
    addQuestion: 'Dodaj pytanie',
    questionContent: 'Treść pytania',
    answerOption: 'Opcja odpowiedzi',
    addQuestionAnswer: 'Dodaj opcję odpowiedzi',
    surveyName: 'Nazwa ankiety',
    sectionName: 'Nazwa sekcji',
    noSurveyAnswers: 'Brak odpowiedzi',
    removeSection: 'Usuń sekcję',
    published: 'Opublikowane',
    unpublished: 'Nieopublikowana',
    pendingPublication: 'Zmiany oczekują na publikację',
    removeQuestion: 'Usuń pytanie',
    removeAnswer: 'Usuń opcję odpowiedzi',
    publish: 'Opublikuj',
    unpublish: 'Cofnij publikacje',
    previewResults: 'Zobacz odpowiedzi',
    supervisior: 'Przełożony',
    surveyResults: 'Wyniki ankiet',
    surveysEvents: 'Konfiguracja zdarzeń - ankiety',
    operatedBy: 'Obsługiwane przez',
    confirmReadInformations: 'Zapoznałem się z informacjami',
    noticeContent: 'Treść obwieszczenia',
    notice: 'Obwieszczenie',
    surveyEventName: 'Nazwa zdarzenia',
    survey: 'Ankieta',
    newIssue: 'Nowe zgłoszenie',
    surveyEventConfigurationAlreadyExists: 'Taka konfiguracja już istnieje',
    lastComment: 'Ostatni komentarz osoby odpowiedzialnej',
    surveyNotConfigured: 'Ankieta nieskonfigurowana',
    fillSurvey: 'Uzupełnij ankietę',
    singleSurveyEntries: 'Pojedyncze rezultaty',
    groupedSurveyEntries: 'Zgrupowane rezultaty',
    publicationId: 'ID publikacji',
    publicationDate: 'Data publikacji',
    versionId: 'ID wersji',
    answersCount: 'Ilość odpowiedzi',
    answers: 'Odpowiedzi',
    withoutSupervisor: 'Bez przełożonego',
    defaultSupervisor: 'Domyślny przełożony',
    flowStateFlags: 'Flagi statusu',
    qrActionsSettings: 'Ustawienia akcji kodów QR',
    loginRequired: 'Wymaga logowania',
    questionContext: 'Kontekst pytania',
    groupableHeadersPlaceholder: 'Przeciągnij nagłówek tabeli, aby zgrupować dane.',
    currentSurveyVersionPreview: 'Podgląd obecnej wersji ankiety',
    surveyDescription: 'Opis ankiety',
    sectionDescription: 'Opis sekcji',
    instructions: 'Instrukcje',
    instruction: 'Instrukcja',
    instructionRevisions: 'Rewizje',
    instructionRevision: 'Rewizja',
    checklists: 'Listy kontrolne',
    checklist: 'Lista kontrolna',
    checklistItem: 'Element listy',
    manageChecklistFields: 'Zarządzaj elementami listy',
    checklistItemDisplayOptions: 'Wyświetlane pozycje',
    revisionAutoRejectedInfo: '[System] Odrzucono - utworzono nową rewizję',
    steps: 'Etapy',
    step: 'Etap',
    content: 'Zawartość'
  },
  accessMergeValues: {
    sum: 'Suma',
    product: 'Iloczyn',
    overwrite: 'Nadpisz'
  },
  aggregates: {
    count: 'Suma',
    min: 'Minimum',
    max: 'Maksimum'
  },
  checklists: {
    itemBasic: 'Podstawowy element',
    itemSeparator: 'Separator',
    itemSection: 'Sekcja',
    publish: 'Opublikuj',
    previewCurrentVersion: 'Podgląd obecnej wersji'
  },
  cluer: {
    history: 'Historia',
    standardObstacle: 'Standardowa przeszkoda',
    customObstacle: 'Niestandardowa przeszkoda',
    bhp: 'BHP',
    returnToMainPage: 'Powrót do strony głównej',
    bhpMessage: 'Jeżeli widzisz zagrożenia bezpieczeństwa zgłoś to proszę bez zwłoki do bezpośredniego przełożonego i użyj formatki zgłoszenia która znajduje się pod tablicą ogłoszeń',
    ok: 'Ok',
    send: 'Wyślij',
    description: 'Opis',
    title: 'Tytuł',
    realization: 'Realizacja',
    answer: 'Odpowiedź',
    pendingSurveys: 'Trwające ankiety',
    surveys: 'Ankiety',
    systemSurvey: 'Ankieta systemowa',
    addPage: 'Dodaj stronę',
    createSurvey: 'Utwórz ankietę',
    addSection: 'Dodaj sekcję',
    addQuestion: 'Dodaj pytanie',
    questionContent: 'Treść pytania',
    answerOption: 'Opcja odpowiedzi',
    addQuestionAnswer: 'Dodaj opcję odpowiedzi',
    surveyName: 'Nazwa ankiety',
    sectionName: 'Nazwa sekcji',
    noSurveyAnswers: 'Brak odpowiedzi',
    removeSection: 'Usuń sekcję',
    published: 'Opublikowana',
    unpublished: 'Nieopublikowana',
    pendingPublication: 'Zmiany oczekują na publikację',
    removeQuestion: 'Usuń pytanie',
    removeAnswer: 'Usuń opcję odpowiedzi',
    publish: 'Opublikuj',
    unpublish: 'Cofnij publikacje',
    previewResults: 'Zobacz odpowiedzi',
    supervisior: 'Przełożony',
    surveyResults: 'Wyniki ankiet',
    surveysEvents: 'Konfiguracja zdarzeń - ankiety',
    operatedBy: 'Obsługiwane przez',
    confirmReadInformations: 'Zapoznałem się z informacjami',
    noticeContent: 'Treść obwieszczenia',
    notice: 'Obwieszczenie',
    surveyEventName: 'Nazwa zdarzenia',
    survey: 'Ankieta',
    newIssue: 'Nowe zgłoszenie',
    surveyEventConfigurationAlreadyExists: 'Taka konfiguracja już istnieje',
    lastComment: 'Ostatni komentarz osoby odpowiedzialnej',
    surveyNotConfigured: 'Ankieta nieskonfigurowana',
    fillSurvey: 'Uzupełnij ankietę',
    singleSurveyEntries: 'Pojedyncze rezultaty',
    groupedSurveyEntries: 'Zgrupowane rezultaty',
    publicationId: 'ID publikacji',
    publicationDate: 'Data publikacji',
    versionId: 'ID wersji',
    answersCount: 'Ilość odpowiedzi',
    answers: 'Odpowiedzi',
    withoutSupervisor: 'Bez przełożonego',
    defaultSupervisor: 'Domyślny przełożony',
    flowStateFlags: 'Flagi statusu',
    qrActionsSettings: 'Ustawienia akcji kodów QR',
    loginRequired: 'Wymaga logowania',
    questionContext: 'Kontekst pytania',
    groupableHeadersPlaceholder: 'Przeciągnij nagłówek tabeli, aby zgrupować dane.',
    currentSurveyVersionPreview: 'Podgląd obecnej wersji ankiety',
    surveyDescription: 'Opis ankiety',
    sectionDescription: 'Opis sekcji'
  },
  orders: {
    order: 'Zamówienie',
    releaseDate: 'Data realizacji',
    orderDate: 'Data zamówienia'
  },
  production: {
    good: 'Ilość dobrych',
    bad: 'Ilość złych',
    productionRecords: 'Rejestr produkcji'
  }
};
