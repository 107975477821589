import QUESTION_TYPES_ENUM from '@/CONSTANTS/QUESTION_TYPES_ENUM';
import i18n from '@/i18n';

export const QuestionTypes = [
    {
        value: QUESTION_TYPES_ENUM.TEXT,
        get label() {
            return i18n.global.t('paramTypes.text');
        },
    },
    {
        value: QUESTION_TYPES_ENUM.LONGTEXT,
        get label() {
            return i18n.global.t('paramTypes.note');
        },
    },
    {
        value: QUESTION_TYPES_ENUM.NUMBER,
        get label() {
            return i18n.global.t('paramTypes.number');
        },
    },
    {
        value: QUESTION_TYPES_ENUM.RATING,
        get label() {
            return i18n.global.t('paramTypes.rating');
        },
    },
    {
        value: QUESTION_TYPES_ENUM.CHECK,
        get label() {
            return i18n.global.t('paramTypes.checkboxGroup');
        },
    },
    {
        value: QUESTION_TYPES_ENUM.RADIO,
        get label() {
            return i18n.global.t('paramTypes.radioGroup');
        },
    },
    {
        value: QUESTION_TYPES_ENUM.PHOTO,
        get label() {
            return i18n.global.t('common.image');
        },
    },
];
