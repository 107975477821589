<template>
    <SignaloAppBar>
        <template #title> {{ $t('breadCrumb.myProfile') }} </template>
        <template #bar> </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <v-row>
            <v-col>
                <PageLoading v-if="loading" />
                <v-card class="ma-2 general-data-card" v-else>
                    <template v-slot:title>
                        <v-toolbar class="preview-header">
                            {{ $getPropertyTranslation(authStore.user.name) }}
                        </v-toolbar>
                    </template>
                    <v-tabs v-model="tab" color="primary">
                        <v-tab value="general">{{
                            $t('common.general')
                            }}</v-tab>
                        <v-tab value="password" v-if="!authStore.isTerminal">{{
                            $t('common.password')
                            }}</v-tab>
                        <v-tab value="code"
                            v-if="!authStore.isTerminal && authStore?.terminal?.requires_user_login_authenticatable !== REQUIRES_USER_LOGIN_AUTHENTICATABLE.EMPLOYEE && authStore?.terminal?.requires_user_login_authenticatable !== REQUIRES_USER_LOGIN_AUTHENTICATABLE.ANY">{{
                                $t('common.accessCode')
                            }}</v-tab>
                        <v-tab value="notifications" v-if="
                            authStore.isAllowed('notifications', 'read') &&
                            !authStore.isTerminal
                        ">{{ $t('common.notificationsManagement') }}</v-tab>
                        <v-tab v-if="!authStore.isTerminal" value="dashboard">{{
                            $t('common.dashboardConfig')
                            }}</v-tab>
                        <v-tab v-if="!authStore.isTerminal" value="statements">{{ $t('common.statements') }}</v-tab>
                        <v-tab v-if="!authStore.isTerminal" value="instructionsToAccept">{{
                            $t('common.instructionsToAccept') }}</v-tab>
                    </v-tabs>

                    <v-window v-model="tab" :key="tab" style="overflow: visible">
                        <v-window-item value="general">
                            <v-form ref="userDataForm" v-model="formValid" v-if="user">
                                <v-row>
                                    <v-col cols="12" sm="8" md="8" v-if="user.id !== undefined">
                                        <div class="d-flex flex-wrap">
                                            <SignaloImageUpload v-if="userPhoto" v-model="userPhoto"
                                                :uploadEndpoint="`/me/photos`"
                                                :removeEndpoint="`/me/photos/${userPhoto.id}`" @image-removed="onRemoveImageHandler
                                                    " @image-uploaded="onImageUploadedHandler
                                                    " :storageUrl="this.getFileStorageUrl() +
                                                    '/'
                                                    " />
                                            <div v-else class="eq-photo d-flex justify-center align-center"
                                                @click="addPhotoClicked">
                                                <v-icon size="x-large">
                                                    mdi-plus-circle-outline
                                                </v-icon>
                                                <div class="cover"></div>
                                                <input type="file" ref="fileInput" @change="filesSelected"
                                                    style="display: none"
                                                    accept="image/png, image/jpeg, image/bmp, image/webp" />
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <div class="Text CAPTION-3 preffered-language-container">
                                            {{ $t('staff.prefferedLocale') }}:
                                            <SignaloLanguageChanger />
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field :label="$t('staff.nameAndLastname')" required :rules="nameRules"
                                            v-model="user.name" variant="outlined" color="var(--greyscale-100)">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select :label="$t('common.timezone')" v-model="user.timezone"
                                            :items="timezones" variant="outlined" color="var(--greyscale-100)"
                                            :no-data-text="$t('common.emptyTableMessage')
                                                ">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field :label="$t('staff.phoneNumber')" maxlength="13"
                                            v-model="user.phone" variant="outlined" color="var(--greyscale-100)"
                                            :rules="[$PHONE_NUMBER_RULE]" :disabled="!authStore.isAllowed(
                                                'staff.contact',
                                                'update'
                                            )
                                                ">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field :label="$t('common.email')" maxlength="255" :rules="_emailRules"
                                            v-model="user.email" variant="outlined" color="var(--greyscale-100)"
                                            :disabled="!authStore.isAllowed(
                                                'staff.contact',
                                                'update'
                                            )
                                                ">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                            <div class="general-data-form-actions">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" variant="text" @click="save">{{ $t('common.save') }}</v-btn>
                            </div>
                        </v-window-item>

                        <v-window-item value="password" v-if="!authStore.isTerminal">
                            <v-form ref="newPasswordForm" v-model="newPasswordFormValid">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field :label="$t('staff.oldPassword')" maxlength="255"
                                            :rules="passwordRules" v-model="oldPassword" variant="outlined"
                                            color="var(--greyscale-100)" type="password"
                                            :error-messages="oldPasswordErrors">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field :label="$t('staff.newPassword')" maxlength="255"
                                            :rules="passwordRules" v-model="newPassword" variant="outlined"
                                            color="var(--greyscale-100)" type="password">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field :label="$t(
                                            'staff.newPasswordConfirmation'
                                        )
                                            " maxlength="255" :rules="newPasswordConfirmationRules
                                                " v-model="newPasswordConfirmation" variant="outlined"
                                            color="var(--greyscale-100)" type="password">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                            <div class="general-data-form-actions">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" variant="text" @click="changePassword"
                                    :loading="passwordChangingLoading" :disabled="!oldPassword ||
                                        !newPassword ||
                                        !newPasswordConfirmation ||
                                        newPassword?.length < 8 ||
                                        newPasswordConfirmation?.length < 8 ||
                                        newPassword != newPasswordConfirmation
                                        ">{{ $t('common.changePassword') }}</v-btn>
                            </div>
                        </v-window-item>

                        <v-window-item value="code" v-if="!authStore.isTerminal">
                            <v-form ref="newCodeForm" v-model="newCodeFormValid">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field :label="$t('staff.newCode')" maxlength="255" :rules="codeRules"
                                            v-model="newCode" variant="outlined" color="var(--greyscale-100)"
                                            type="password" :error-messages="codeErrors">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field :label="$t('staff.newCodeConfirmation')
                                            " maxlength="255" :rules="newCodeConfirmationRules"
                                            v-model="newCodeConfirmation" variant="outlined"
                                            color="var(--greyscale-100)" type="password" :error-messages="codeErrors">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                            <div class="general-data-form-actions">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" variant="text" @click="changeCode" :loading="codeChangingLoading"
                                    :disabled="!newCode ||
                                        !newCodeConfirmation ||
                                        newCode?.length < 6 ||
                                        newCodeConfirmation?.length < 6 ||
                                        newCode !== newCodeConfirmation
                                        ">{{ $t('common.changeCode') }}</v-btn>
                            </div>
                        </v-window-item>

                        <v-window-item value="notifications" v-if="!authStore.isTerminal">
                            <SignaloNotificationsManagementIndex />
                        </v-window-item>

                        <v-window-item value="dashboard" v-if="!authStore.isTerminal">
                            <DashboardConfig :dialogMode="true" />
                        </v-window-item>

                        <v-window-item value="statements" v-if="!authStore.isTerminal">
                            <SignaloPendingStatements />
                        </v-window-item>
                        <v-window-item value="instructionsToAccept" v-if="!authStore.isTerminal">
                            <SignaloInstructionsToAccept />
                        </v-window-item>
                    </v-window>
                </v-card>
            </v-col>
        </v-row>
    </v-main>
</template>

<script>
import SignaloInstructionsToAccept from '@/components/SignaloInstructionsToAccept.vue';
import SignaloPendingStatements from '@/components/Statements/SignaloPendingStatements.vue';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

import DashboardConfig from '../settings/DashboardConfig/DashboardConfig.vue';
export default {
    components: { DashboardConfig, SignaloPendingStatements, SignaloInstructionsToAccept },
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    mounted() {
        this.loading = true;
        Promise.all([this.loadTimezones(), this.loadUser()]).finally(() => {
            this.loading = false;
        });
    },
    data() {
        return {
            formValid: false,
            user: null,
            userPhoto: null,
            tab: 'general',
            timezones: [],
            oldPassword: null,
            newPassword: null,
            newPasswordConfirmation: null,
            oldPasswordErrors: {},
            codeErrors: {},
            loading: true,
            newCode: null,
            newCodeConfirmation: null,
            passwordChangingLoading: false,
            codeChangingLoading: false,
        };
    },
    methods: {
        async loadTimezones() {
            try {
                const tzData = await this.axios.get('global/timezones');
                this.timezones = tzData?.data?.items;
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
        async loadUser() {
            try {
                const user = await this.axios(`me`);
                this.user = user?.data?.item;
                if (this.user.photo) {
                    this.userPhoto = this.user.photo;
                }
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
        onRemoveImageHandler() {
            this.userPhoto = null;
            this.loadUser();
        },
        onImageUploadedHandler() {
            this.loadUser();
        },
        addPhotoClicked() {
            this.$refs.fileInput.click();
        },
        filesSelected(e) {
            const file = e.target.files[0];
            if (file.size > this.helpersStore.MAX_FILE_SIZE) {
                this.helpersStore.snackbarWarning(
                    this.$t('errors.someFilesRejectedDueToSize') +
                    ` ${this.helpersStore.getMaxFileSizeInMB()}MB`
                );
                return;
            }
            this.userPhoto = {
                uploadFile: file,
            };
        },
        async save() {
            const userData = {
                name: this.user.name,
                email: this.user.email,
                phone: this.user.phone,
                timezone: this.user.timezone,
                preffered_locale: this.user.preffered_locale,
            };
            await this.$refs.userDataForm.validate();
            if (!this.formValid) return;
            if (!this.authStore.isAllowed('staff.contact', 'update')) {
                delete userData.email;
                delete userData.phone;
            }
            try {
                await this.axios.put(`me`, userData);
                await this.loadUser();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
        async changePassword() {
            this.passwordChangingLoading = true;
            this.oldPasswordErrors = {};
            try {
                await this.axios.put(`me/password`, {
                    old_password: this.oldPassword,
                    password: this.newPassword,
                });
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status == 401) return;
                if (err?.response?.status == 400) {
                    this.oldPasswordErrors = this.$t('common.wrongPassword');
                }
            }
            this.passwordChangingLoading = false;
        },
        async changeCode() {
            this.codeChangingLoading = true;
            this.codeErrors = {};
            try {
                await this.axios.put(`me/code`, {
                    code: this.newCode,
                });
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status == 401) {
                    this.codeErrors = this.$t('errors.codeAlreadyInUse');
                }
                if (err?.response?.status == 400) {
                    this.codeErrors = this.$t('errors.codeAlreadyInUse');
                }
            }
            this.codeChangingLoading = false;
        },
    },
    computed: {
        passwordRules() {
            return [
                (v) =>
                    v?.trim()?.length >= 8 ||
                    this.$t('errors.wrongPasswordLength'),
            ];
        },
        codeRules() {
            return [
                (v) =>
                    v?.trim()?.length >= 5 ||
                    this.$t('errors.minLength') + ' 5',
            ];
        },
        newPasswordConfirmationRules() {
            return [
                (v) =>
                    v?.trim()?.length >= 8 ||
                    this.$t('errors.wrongPasswordLength'),
                (v) =>
                    v === this.newPassword ||
                    this.$t('errors.passwordConfirmationNotMatch'),
            ];
        },
        newCodeConfirmationRules() {
            return [
                (v) =>
                    v?.trim()?.length >= 5 ||
                    this.$t('errors.minLength') + ' 5',
                (v) => v === this.newCode || this.$t('errors.codesNotMatch'),
            ];
        },
    },
};
</script>
<style>
.general-data-form-actions {
    display: flex;
}

.general-data-card {
    min-height: 90vh;
}

.general-data-card .v-window-item {
    padding-top: 32px;
}

.preffered-language-container {
    display: flex;
    align-items: center;
}

.preffered-language-container .v-field {
    padding: 0 !important;
}
</style>
