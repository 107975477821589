<template>
    <v-card style="padding: 0 !important" class="overflow-visible">
        <EasyDataTable buttons-pagination
            :loading="loading"
            :items="items"
            :headers="tableHeaders"
            :empty-message="$t('common.emptyTableMessage')"
            :table-class-name="'easy-table-style parts-taken-by-user-table'"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            @click-row="goToPartEntry"
            :rows-items="[5, 10, 25, 50]"
            v-model:server-options="tableServerOptions"
            :server-items-length="tableServerItemsLength"
        >
            <template #item-username="item">
                <router-link
                    v-if="authStore.isAllowed('staff', 'read')"
                    @click.stop="() => {}"
                    :to="{
                        name: 'staff.show',
                        params: {
                            id: item?.user?.id,
                        },
                    }"
                >
                    {{ item?.user?.name }}
                </router-link>
            </template>
            <template #item-start_time="item">
                {{ $displayDateWithTimezone(item?.start_time) }}
            </template>
            <template #item-end_time="item">
                {{ $displayDateWithTimezone(item?.end_time) }}
            </template>
        </EasyDataTable>
    </v-card>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
export default {
    name: 'WorkTimeRegister',
    setup() {
        const dataStore = DATA_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, authStore };
    },
    props: ['id'],
    data() {
        return {
            loading: false,
            items: [],
            tableName: 'USER_WORK_TIME_REGISTER',
            tableServerOptions: {
                ...this.$getServerOptions('USER_WORK_TIME_REGISTER'),
                sortBy: 'id',
                sortType: 'desc',
            },
            tableServerItemsLength: 0,
            lastSearch: null,
        };
    },
    async mounted() {
        await this.dataStore.locations.promise();
        this.loading = true;
        this.dataStore.locations.reload().then(() => {
            this.loading = false;
        });
        if (this.id) {
            await this.loadData();
        }
    },
    methods: {
        async loadData(search) {
            this.loading = true;
            let endpoint;
            if (search !== null) {
                this.lastSearch = search;
            }
            if (this.id) {
                endpoint = `users/${this.id}/work_time_registers`;
            } else {
                endpoint = '/work_time_registers';
            }
            try {
                const options = this.dataStore.makeServerOptions(
                    this.tableServerOptions,
                    this.lastSearch
                );
                const data = await this.axios.get(endpoint, options);
                this.items = data?.data?.items || [];

                this.tableServerItemsLength = data?.data?.meta?.total;
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
    },
    watch: {
        tableServerOptions: {
            handler(val) {
                this.authStore.patchTablePreferences(this.tableName, {
                    default_size: val.rowsPerPage,
                });
                this.loadData();
            },
            deep: true,
        },
    },
    computed: {
        tableHeaders() {
            const headers = [
                {
                    text: this.$t('common.workStarted'),
                    value: 'start_time',
                },
                {
                    text: this.$t('common.workFinished'),
                    value: 'end_time',
                },
                {
                    text: this.$t('paramTypes.note'),
                    value: 'note',
                },
            ];

            if (!this.id) {
                headers.unshift({
                    text: this.$t('common.user'),
                    value: 'username',
                });
            }

            return headers;
        },
    },
};
</script>

<style scoped>
.parts-taken-by-user-table {
    margin: 4px 0 !important;
}
</style>
