<template>
    <SignaloDialog
        :open="open"
        :icon="'mdi-note-edit-outline'"
        :title="$t('notes.note')"
        @close="closeDialog"
        :constHeight="false"
    >
        <template #body>
            <PageLoading v-if="loading" />
            <div v-else>
                <v-card-text>
                    <v-form
                        ref="dataForm"
                        v-model="dataValid"
                        lazy-validation
                        class="form-container"
                    >
                        <v-col>
                            <v-text-field
                                :label="$t('notes.title')"
                                v-model="title"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                required
                                :rules="rules"
                                class="required-field"
                            >
                            </v-text-field>
                            <v-textarea
                                :label="$t('notes.note')"
                                v-model="note"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                required
                            ></v-textarea>
                            <v-checkbox
                                v-model="archived"
                                :label="$t('notes.archived')"
                            ></v-checkbox>
                        </v-col>
                    </v-form>
                </v-card-text>
            </div>
        </template>
        <template #buttons>
            <v-btn class="mr-4" @click="closeDialog(false)">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                :loading="dataSaving"
                color="primary"
                @click="saveData"
                variant="elevated"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    props: ['open', 'mode', 'editedItem'],
    setup() {
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { authStore, helpersStore };
    },
    data() {
        return {
            dataSaving: false,
            rules: [(v) => !!v || this.$t('common.fieldRequired')],
            dataValid: true,
            title: this.editedItem?.title || null,
            note: this.editedItem?.note || null,
            archived: this.editedItem?.archived || false,
        };
    },
    methods: {
        async saveData() {
            await this.$refs.dataForm.validate();
            if (!this.dataValid) return;
            this.dataSaving = true;
            let method;
            this.mode === 'edit' ? (method = 'PUT') : (method = 'POST');
            let url = '/notes';
            let data = {
                title: this.title,
                note: this.note,
                archived: this.archived,
            };
            if (this.mode === 'edit') {
                url += '/' + this.editedItem.id;
            }
            this.axios
                .request({ url, method, data })
                .then((/*{ data }*/) => {
                    this.helpersStore.snackbar(
                        this.$t('common.saved'),
                        'success'
                    );
                    this.closeDialog(true);
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                })
                .finally(() => {
                    this.dataSaving = false;
                });
        },
        closeDialog(refetchData = false) {
            this.$emit('closeDialog', refetchData);
        },
    },
};
</script>
