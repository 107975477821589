<template>
    <v-app>
        <div class="maintenance-container">
            <div>
                <div class="Text HeadingH1-6472">
                    {{ $t('common.maintenanceMode') }}
                </div>
                <div class="Text HeadingH8-2024">
                    {{ $t('common.applicationMayBeUnavailableForFewMinutes') }}
                </div>
            </div>

            <v-icon class="maintenance-icon">mdi-wrench-clock</v-icon>
            <div class="Text HeadingH3-4860">
                {{ $t('common.appWillRefreshIn') }}:&nbsp;
                {{ redirectTimeout }}s
            </div>
        </div>
    </v-app>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    data() {
        return {
            redirectTimeout: 30,
            intervalId: null,
        };
    },
    mounted() {
        this.intervalId = setInterval(() => {
            this.redirectTimeout--;
            if (this.redirectTimeout < 1) {
                this.axios
                    .get('auth')
                    .then((res) => {
                        if (!res) {
                            this.redirectTimeout = 30;
                        } else {
                            clearInterval(this.intervalId);
                            this.$clearWebviewCacheAndForceReloadApp()

                        }
                    })
                    .catch((err) => {
                        if (err?.response?.status === 412) {
                            this.helpersStore.setMaintenance(false);
                        }
                        console.log("[PageMaintenance] err", err);
                        clearInterval(this.intervalId);
                        this.$clearWebviewCacheAndForceReloadApp()
                    });
            }
        }, 1000);
    },
    unmounted() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
};
</script>

<style>
.maintenance-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}
.maintenance-icon {
    font-size: 16rem !important;
    color: var(--ui-text-text-02);
}

.Text {
    flex-grow: 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.64px;
    text-align: center;
    color: var(--ui-text-text-02);
}

.HeadingH1-6472 {
    font-family: Inter;
    font-size: 2.25rem !important;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.64px;
}

.HeadingH3-4860 {
    font-family: Inter;
    font-size: 1.5rem !important;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.48px;
}
.HeadingH8-2024 {
    font-family: Inter;
    font-size: 1.25rem !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.4px;
}
</style>
