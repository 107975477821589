<template>
    <div class="signalo-file-tile-container" :id="`file-${file?.id}`">
        <div class="signalo-file-tile-actions">
            <v-tooltip top>
                <template v-slot:activator="{ props }">
                    <div class="signalo-file-tile-title" v-bind="props">
                        {{ file?.name }}
                    </div>
                </template>

                {{ file?.name }}
            </v-tooltip>
            <v-spacer />
            <SignaloDynamicMenu
                :menuItems="fileActions"
                class="signalo-file-actions-menu"
            />
        </div>
        <div class="signalo-file-tile-content">
            <div
                class="signalo-file-tile-preview"
                v-if="file?.path_thumbnail && previewExtensions.includes(ext)"
            >
                <img
                    draggable="false"
                    :src="storageUrl + file?.path_thumbnail"
                    class="signalo-file-tile-preview-img"
                />
            </div>
            <div class="preview-placeholder" v-else>
                <v-icon v-if="ext === 'pdf'">mdi-file-pdf-box</v-icon>
                <v-icon v-else>mdi-file-document</v-icon>
            </div>
        </div>
        <div class="signalo-file-tile-footer">
            {{ $displayDateWithTimezone(file?.created_at) }}
        </div>
        <div v-if="removeDialog">
            <SignaloConfirmationDialog
                :dialogOpened="true"
                @update:dialogOpened="(value) => onRemoveDialogClose(value)"
            />
        </div>
        <div v-if="moveDialog">
            <MoveFileDialog
                @remove="onRemove"
                @close="onMoveDialogClose"
                :file="file"
                @reload="$emit('reload')"
            />
        </div>
        <div v-if="documentPreviewDialog">
            <SignaloWopiDocumentDialog
                ref="signaloWopiDocumentPreviewRef"
                :documentUrl="file?.wopi?.url"
                :title="file?.name"
                :token="authStore._token"
                @close="documentPreviewDialog = false"
            />
        </div>
    </div>
</template>

<script>
import MoveFileDialog from './MoveFileDialog.vue';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';

export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { helpersStore, authStore };
    },
    props: ['file'],
    emits: ['goToFolder'],
    data() {
        return {
            storageUrl: this.getFileStorageUrl() + '/',
            ext:
                this.file.mime
                    .split('/')
                    ?.[this.file.mime.split('/')?.length - 1]?.toLowerCase() ||
                null,
            previewExtensions: ['png', 'jpg', 'jpeg', 'mp4'],
            moveDialog: false,
            removeDialog: false,
            documentPreviewDialog: false,
        };
    },
    methods: {
        onMove() {
            this.moveDialog = true;
        },
        onMoveDialogClose() {
            this.moveDialog = false;
        },
        onRemove() {
            this.removeDialog = true;
        },
        async onRemoveDialogClose(shouldRemove) {
            if (shouldRemove) {
                try {
                    await this.axios.delete(`files/${this.file?.id}`);
                    this.$emit('reload');
                    this.helpersStore.snackbarSaved();
                } catch (err) {
                    console.error(err);
                }
            }
            this.removeDialog = false;
        },
        onPreview() {
            if (this.file?.wopi?.url) {
                this.documentPreviewDialog = true;
            } else {
                window.open(this.storageUrl + this.file.path);
            }
        },
        onGoToFolder() {
            this.$emit('goToFolder');
        },
    },
    computed: {
        fileActions() {
            return [
                {
                    icon: 'mdi-eye',
                    label: this.$t('common.preview'),
                    action: this.onPreview,
                },
                {
                    icon: 'mdi-folder-move',
                    label: this.$t('common.move'),
                    action: this.onMove,
                },
                {
                    icon: 'mdi-folder-eye',
                    label: this.$t('common.goToFolder'),
                    action: this.onGoToFolder,
                },
                {
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    action: this.onRemove,
                },
            ];
        },
    },
    components: { MoveFileDialog },
};
</script>

<style scoped>
.signalo-file-tile-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--greyscale-30);
    min-height: 250px;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 2px solid transparent;
    transition: 0.25s;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.signalo-file-tile-actions {
    display: flex;
    width: 100%;
    height: 24px;
    overflow: hidden;
}
.signalo-file-tile-title {
    max-width: calc(100% - 32px);
    text-overflow: ellipsis;
    overflow: hidden;
}
.signalo-file-tile-title,
.signalo-file-tile-footer {
    font-size: 0.825rem;
}

.signalo-file-tile-content {
    flex: 1;
    width: 100%;
    display: flex;
    position: relative;
    margin: 8px 0;
}
.signalo-file-tile-preview {
    flex: 1;
    height: 100%;
    width: calc(100% + 20px);
    left: -10px;
    position: absolute;
    height: 168px;
    overflow: hidden;
}

.signalo-file-tile-preview-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.preview-placeholder {
    position: absolute;
    flex: 1;
    height: 100%;
    width: 100%;
    font-size: 4rem;
    align-items: center;
    justify-content: center;
    display: flex;
}

.signalo-file-tile-container:not(.selected-file):hover {
    background-color: var(--greyscale-40);
}

.signalo-file-actions-menu {
    align-self: baseline;
}

.dragstart.signalo-file-tile-container {
    background-color: var(--primary-10);
    border: 2px solid var(--primary-50);
}
.dragstart.signalo-file-tile-container .signalo-file-tile-preview {
    width: calc(100% + 16px);
    left: -8px;
}
</style>

<style>
.selected-file.signalo-file-tile-container {
    background-color: var(--success-10);
    border: 2px solid var(--success-50);
}
.selected-file.signalo-file-tile-container .signalo-file-tile-preview {
    width: calc(100% + 16px);
    left: -8px;
}
</style>
