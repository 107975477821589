<template>
    <SignaloAppBar>
        <template #title> {{ $t('tpm.steps') }} </template>
        <template #bar>
            <v-btn @click="() => loadData()" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" color="primary" variant="elevated" class="submain-button renew-button">
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="addButtonClicked">
                        <v-list-item-title>
                            <v-icon start> mdi-plus </v-icon>
                            <span>
                                {{ $t('common.add') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs v-if="breadcrumb" :data="breadcrumb" />
        <EasyDataTable :headers="tableHeaders" :items="items" :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style" :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')" :rows-items="[5, 10, 25, 50, 75, 100]"
            :rows-per-page="25" :loading="loading" name="courseQuizzesTable">
            <template #item-name="item">

                {{ $getPropertyTranslation(item?.name) }}


            </template>
            <template #item-description="item">

                {{ $getPropertyTranslation(item?.description) }}


            </template>
            <template #item-sort="item">
                <div class="sort-change-container">
                    <v-icon class="sort-change-arrow" @click="
                        onMove(
                            $event,
                            item,
                            'up',
                            items
                                .map((x) => x.id)
                                .indexOf(item.id)
                        )
                        " v-if="
                            items
                                .map((x) => x.id)
                                .indexOf(item.id) !== 0
                        ">mdi-arrow-up</v-icon>
                    <v-icon class="sort-change-arrow" @click="
                        onMove(
                            $event,
                            item,
                            'down',
                            items
                                .map((x) => x.id)
                                .indexOf(item.id)
                        )
                        " v-if="
                            items
                                .map((x) => x.id)
                                .indexOf(item.id) !==
                            items?.length - 1
                        ">mdi-arrow-down</v-icon>
                </div>
            </template>
            <template #item-operation="item">
                <SignaloDynamicMenu :rowItem="item" :menuItems="getTableActions(item)" />
            </template>
        </EasyDataTable>
    </v-main>


    <SignaloConfirmationDialog v-if="removeDialog" :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => removeQuiz(value)" />

    <ManageCourseStepDialog v-if="manageDialog" :open="true" :editedItem="editedItem" @close="onManageDialogClose"
        :courseId="courseId" />

    <StepContentDialog v-if="contentManageDialog" :open="true" :editedItem="editedItem" :courseId="courseId"
        @close="onContentManageDialogClose" />



</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import ManageCourseStepDialog from './ManageCourseStepDialog.vue';
import StepContentDialog from './StepContentDialog.vue';
export default {
    components: { ManageCourseStepDialog, StepContentDialog },
    setup() {
        const dataStore = DATA_STORE.default();
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { dataStore, authStore, helpersStore };
    },
    data() {
        return {
            loading: false,
            items: [],
            removeDialog: false,
            itemToRemove: null,
            editedItem: null,
            courseId: this.$route.params.id,
            breadcrumb: null,
            course: null,
            manageDialog: false,
            contentManageDialog: false,
        };
    },
    mounted() {
        this.loadData();
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                },
                {
                    text: this.$t('tableHeaders.description'),
                    value: 'description',
                },
                {
                    text: this.$t('common.sortChange'),
                    value: 'sort',
                },

                {
                    value: 'operation',
                    width: 54,
                    fixed: true,
                    text: this.$t('tableHeaders.actions'),
                },
            ];
        },
    },
    methods: {
        onMove($event, item, direction, rowIndex) {
            $event.stopPropagation();

            let siblingElement, siblingElementSort, siblingElementUpdate;
            direction === 'up'
                ? (siblingElement = this.items[rowIndex - 1])
                : (siblingElement = this.items[rowIndex + 1]);
            if (!siblingElement) return;

            siblingElementSort = siblingElement.sort;
            siblingElement.sort = item.sort;
            item.sort = siblingElementSort;
            siblingElementUpdate = this.axios.patch(
                // `categories/${siblingElement.id}`,
                `courses/${this.courseId}/steps/${siblingElement.id}`,
                { sort: siblingElement.sort }
            );
            const elementUpdate = this.axios.patch(`courses/${this.courseId}/steps/${item.id}`, { sort: item.sort });
            Promise.all([siblingElementUpdate, elementUpdate])
                .then(() => {
                    return this.loadData();
                })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('common.updated'),
                        'success'
                    );
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                });
        },
        async onManageDialogClose(shouldRefetch = false) {
            if (shouldRefetch) {
                await this.loadData();
            }
            this.manageDialog = false;
            this.editedItem = null;
        },
        getTableActions() {
            return [

                {
                    icon: 'mdi-help-box-multiple',
                    label: this.$t('common.tests'),
                    action: this.goToStepQuizzes,
                },
                {
                    icon: 'mdi-playlist-edit',
                    label: this.$t('common.assets'),
                    action: this.openStepContentDialog,
                },
                {
                    action: this.onEditItem,
                    icon: 'mdi-pencil',
                    label: this.$t('common.edit'),
                    access: [this.$route.meta.canUpdate],
                },
                {
                    action: this.openRemoveCourseDialog,
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    access: [this.$route.meta.canDelete],
                },

            ];
        },
        openStepContentDialog(item) {
            this.editedItem = item;
            this.contentManageDialog = true;
        },
        async onContentManageDialogClose(shouldRefetch = false) {
            if (shouldRefetch) {
                await this.loadData();
            }
            this.contentManageDialog = false;
            this.editedItem = null;
        },

        goToStepQuizzes(item) {
            this.$router.push({ name: 'settings.courses.steps.quizzes', params: { id: this.courseId, sid: item.id } });
        },
        addButtonClicked() {
            this.manageDialog = true;
        },
        onEditItem(item) {
            this.editedItem = item;
            this.manageDialog = true;
        },
        openRemoveCourseDialog(item) {
            this.removeDialog = true;
            this.itemToRemove = item;
        },
        async removeQuiz(shouldRemove) {
            try {
                if (shouldRemove) {
                    await this.axios.delete(`courses/${this.courseId}/steps/${this.itemToRemove.id}`);
                    this.helpersStore.snackbarSaved();
                    await this.loadData();
                }

                this.removeDialog = false;
                this.itemToRemove = null;
            } catch (err) {
                console.error(err);
            }
        },
        async loadData() {
            this.loading = true;
            try {

                const courseData = await this.axios.get(`courses/${this.courseId}`);

                this.course = courseData.data.item;
                const stepsData = await this.axios.get(`courses/${this.courseId}/steps`);
                this.items = stepsData.data.items || [];
                this.items.sort((a, b) => a.sort - b.sort);
                this.initBreadcrumb();
            } catch (err) {
                console.error(err);
            }
            this.loading = false;
        },
        initBreadcrumb() {
            let res = {};
            if (this.course) {
                res.id = {
                    text: `#${this.courseId}: ${this.$getPropertyTranslation(
                        this.course.name
                    )}`,
                    value: this.courseId,
                };
            } else {
                res.id = {
                    text: `#${this.courseId}`,
                    value: this.courseId,
                };
            }
            this.breadcrumb = res;
            return res;
        }


    },

};
</script>

<style scoped>
.hidden-picker {
    visibility: hidden;
}
</style>
