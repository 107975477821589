//TODO ciągnąc z backendu
import i18n from '@/i18n';
export const DASHBOARD_COMPONENTS = [
    {
        value: 'open_issues',
        get name() {
            return i18n?.global?.t('tableHeaders.openIssues');
        },
    },
    {
        value: 'my_open_issues',
        get name() {
            return i18n?.global?.t('common.myOpenIssues');
        },
    },
    {
        value: 'my_assigned_issues',
        get name() {
            return i18n?.global?.t('common.myAssignedIssues');
        },
    },
    {
        value: 'my_taken_parts',
        get name() {
            return i18n?.global?.t('common.myTakenParts');
        },
    },
    {
        value: 'chart',
        get name() {
            return i18n?.global?.t('common.chart');
        },
    },
    // {
    //     value: 'function_assignment_chart',
    //     name: {
    //         pl: 'Wykres - Przypisanie do funkcji',
    //     },
    // },
    // {
    //     value: 'open_issues_in_time_chart',
    //     name: {
    //         pl: 'Wykres - Otwarte zgłoszenia w czasie',
    //     },
    // },
    // {
    //     value: 'departments_chart',
    //     name: {
    //         pl: 'Wykres - Obszar',
    //     },
    // },
    // {
    //     value: 'shift_performance_chart',
    //     name: {
    //         pl: 'Wykres - Wydajność na zmianie',
    //     },
    // },
    // {
    //     value: 'pending_statements',
    //     name: {
    //         pl: 'Oświadczenia do podpisania',
    //         es: 'Statements to sign',
    //         en: 'Statements to sign',
    //     },
    // },
];
