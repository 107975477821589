import { h } from 'vue';
import cnc from './CncIcon.vue';

const customSvgNameToComponent = {
    cnc,
};

const customSVGs = {
    component: (props) => h(customSvgNameToComponent[props.icon]),
};

export { customSVGs /* aliases */ };
