export default [
    {
        path: '/settings/skillmatrix',
        name: 'settings.skillmatrix',
        component: () => import('@/views/settings/Skillmatrix/SkillmatrixSettingsIndex.vue'),
        meta: {
            currentAccessName: 'skillmatrix',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.skillmatrix',
                    to: { name: 'settings.skillmatrix' },
                },
            ],
        },
    },
    {
        path: '/settings/skillmatrix/indirect-process',
        name: 'settings.skillmatrix.indirect_process',
        component: () => import('@/views/settings/Skillmatrix/IndirectProcess/IndirectProcess.vue'),
        meta: {
            currentAccessName: 'skillmatrix',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.skillmatrix',
                    to: { name: 'settings.skillmatrix' },
                },
                {
                    text: 'common.indirectProcess',
                    to: { name: 'settings.skillmatrix.indirect_process' },
                },
            ],
        },
    },
    {
        path: '/settings/skillmatrix/skill-level-config',
        name: 'settings.skillmatrix.skill_level_config',
        component: () => import('@/views/settings/Skillmatrix/IndirectSkills/IndirectSkills.vue'),
        meta: {
            currentAccessName: 'skillmatrix',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.skillmatrix',
                    to: { name: 'settings.skillmatrix' },
                },
                {
                    text: 'common.skillLevelConfig',
                    to: { name: 'settings.skillmatrix.skill_level_config' },
                },
            ],
        },
    },
];
