<template>
    <div class="signalo-playlist-player-menu-container">
        <v-expansion-panels
            variant="accordion"
            v-if="playlistSections?.length"
            multiple
            v-model="sectionIndex"
            :readonly="playlistSections?.[0]?.singleSection"
            class="signalo-playlist-meny-v-expansion-panels"
        >
            <v-expansion-panel
                v-for="(section, si) in playlistSections"
                :key="section.id"
                :ref="`playlistPlayerMenu${si}`"
                :id="`playlistPlayerMenu${si}`"
                :value="si"
            >
                <template #title
                    ><div>{{ section.name }}</div></template
                >
                <template #text>
                    <v-list class="section-videos-list">
                        <v-list-item
                            v-for="(asset, assetIndex) in section.assets"
                            :key="asset.id"
                            :value="asset.id"
                            @click="
                                () => onAssetSelected(asset, si, assetIndex)
                            "
                            :active="`${sectionIndex}-${asset.id}` == activeId"
                            active-color="primary"
                            >{{
                                $getPropertyTranslation(asset?.name)
                            }}</v-list-item
                        >
                    </v-list>
                </template>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
export default {
    props: ['sections', 'activeId'],
    emits: ['itemSelected'],
    data() {
        return {
            playlistSections: this.sections?.length ? [...this.sections] : [],
            sectionIndex: 0,
            assetIndex: 0,
        };
    },
    mounted() {
        this.onAssetSelected(this.playlistSections?.[0]?.assets?.[0]);
    },
    methods: {
        onAssetSelected(asset, sectionIndex = 0, assetIndex = 0) {
            this.sectionIndex = sectionIndex;
            this.assetIndex = assetIndex;
            this.$emit('itemSelected', asset, this.sectionIndex);
        },
        next() {
            let nextAsset =
                this.playlistSections[this.sectionIndex]?.assets?.[
                    this.assetIndex + 1
                ];
            if (nextAsset) {
                this.onAssetSelected(
                    nextAsset,
                    this.sectionIndex,
                    this.assetIndex + 1
                );
                return;
            }
            nextAsset =
                this.playlistSections[this.sectionIndex + 1]?.assets?.[0];
            if (nextAsset) {
                this.onAssetSelected(nextAsset, this.sectionIndex + 1, 0);
                return;
            }
        },
    },
};
</script>

<style>
.signalo-playlist-player-menu-container .v-expansion-panel-text__wrapper {
    padding: 0;
}

.section-videos-list .v-list-item__content {
    font-size: 0.75rem;
    word-wrap: break-word;
}
.section-videos-list .v-card-item {
    padding-top: 0;
    padding-bottom: 0;
    min-height: unset;
}

.v-expansion-panel--active > .v-expansion-panel-title {
    min-height: unset;
}

.signalo-playlist-meny-v-expansion-panels
    .v-expansion-panel-title--active
    > .v-expansion-panel-title__overlay {
    background: var(--success-50);
}
</style>
