import i18n from '@/i18n';
export default Object.freeze([
    {
        get label() {
            return i18n?.global?.t('tpm.instructions');
        },
        value: 'instructions',
        view: {
            name: 'terminal-instructions',
            meta: {
                bc: [{ text: 'common.instructions' }],
            },
        },
    },
    {
        get label() {
            return i18n?.global?.t('navDrawer.storage');
        },
        value: 'storage',

        view: {
            name: 'storageCart',
            meta: {
                bc: [{ text: 'common.cart' }],
            },
        },
    },
    {
        get label() {
            return i18n?.global?.t('navDrawer.issues');
        },
        value: 'issues',
        view: {
            name: 'terminal_issues',
            meta: {
                bc: [{ text: 'common.newIssue' }],
            },
        },
    },
    {
        get label() {
            return i18n?.global?.t('common.simplifiedHandling');
        },
        value: 'simplified_handling',
        view: {
            name: 'simplified_handling',
            meta: {
                bc: [{ text: 'common.simplifiedHandling' }],
            },
        },
    },
    {
        get label() {
            return i18n?.global?.t('common.workTimeRegister');
        },
        value: 'work_time_register',
        view: {
            name: 'work_time_register',
            meta: {
                bc: [{ text: 'common.workTimeRegister' }],
            },
        },
    },
    {
        get label() {
            return i18n?.global?.t('common.productionTerminal');
        },
        value: 'production',
        view: {
            name: 'production_terminal',
            meta: {
                bc: [{ text: 'common.productionTerminal' }],
            },
        },
    },
    {
        get label() {
            return i18n?.global?.t('common.gatehouse');
        },
        value: 'visit_guard',
        view: {
            name: 'gatehouse',
            meta: {
                bc: [{ text: 'common.gatehouse' }],
            },
        },
    },
    {
        get label() {
            return i18n?.global?.t('common.courses');
        },
        value: 'visit_guard_courses',
        view: {
            name: 'visit_guard_courses',
            meta: {
                bc: [{ text: 'common.courses' }],
            },
        },
    },
]);
