<template>
    <div class="survey-builder-section-question">
        <div class="remove-question-button-container">
            <v-btn @click="() => removeQuestion()" color="error" type="button" variant="text">
                {{ $t('cluer.removeQuestion') }}
            </v-btn>
        </div>
        <v-form v-model="questionFormValid" ref="questionForm">
            <SignaloTranslatableTabs v-slot="{ value, update }" v-model="question.name" :currentLanguage="$i18n?.locale"
                :availableLocales="getAvailableLocales()">
                <v-card-text class="survey-question-name">
                    <v-text-field :label="$t('cluer.questionContent')" :modelValue="value" @update:modelValue="update"
                        :rules="translatableRequired" required variant="outlined" color="var(--greyscale-100)"
                        class="required-field">
                    </v-text-field>
                </v-card-text>
            </SignaloTranslatableTabs>
            <v-card-text>
                <v-select v-model="question.question_type" :label="$t('common.type')" :items="questionTypes"
                    variant="outlined" item-value="value" item-title="label"
                    @update:model-value="(type) => onQuestionTypeChange(type)" :rules="[$REQUIRED_RULE]"
                    class="required-field"></v-select>
                <!-- <div class="survey-question-context-picker-container">
                    <QuizQuestionContextPicker :surveyId="surveyId" v-model="question.context_parameter_id" />
                </div> -->
                <v-text-field type="number" :label="$t('common.order')" variant="outlined" min="1"
                    v-model="question.order" :rules="[$REQUIRED_RULE]" class="required-field" clearable></v-text-field>

                <v-switch :label="$t('common.required')" v-model="question.required" color="primary"></v-switch>

                <v-text-field type="number" :label="$t('common.maxPoints')" variant="outlined"
                    v-if="question.question_type !== $QUESTION_TYPES_ENUM.CHECK && question.question_type !== $QUESTION_TYPES_ENUM.RADIO"
                    v-model="question.max_points" class="required-field" :rules="[$REQUIRED_RULE]"
                    clearable></v-text-field>
                <v-text-field type="number" :label="$t('common.minPointsToPass')" variant="outlined"
                    v-model="question.min_points_to_pass" clearable
                    :rules="[question.max_points ? $MAX_RULE(question.min_points_to_pass, question.max_points) : null]"></v-text-field>
                <v-switch :label="$t('common.questionFailQuiz')" v-model="question.fail_quiz"
                    color="warning"></v-switch>


            </v-card-text>
            <div class="survey-builder-section-question-answers" v-if="question?.answers?.length">
                <QuizQuestionAnswerBuilder v-for="answer in [...question.answers].sort((a, b) => a.order - b.order)"
                    :modelValue="answer" :key="answer.id" ref="questionAnswer" @update:modelValue="onAnswerUpdate"
                    @removeAnswer="onRemoveAnswer" />
            </div>
            <div v-if="question.question_type === $QUESTION_TYPES_ENUM.CHECK || question.question_type === $QUESTION_TYPES_ENUM.RADIO"
                class="add-section-question-answers-button-container">
                <v-btn @click="() => addQuestionAnswer()" color="primary" variant="text">
                    {{ $t('cluer.addQuestionAnswer') }}
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
/* eslint-disable */
import * as AUTH_STORE from 'signalo-vue-auth-store';
import { QuestionTypes } from './QuestionTypes';
import QuizQuestionAnswerBuilder from './QuizQuestionAnswerBuilder.vue';
import { v4 as uuidv4 } from 'uuid';
// import QuizBuilderQuestionValidators from './QuizBuilderQuestionValidators.vue';
import { asyncForEach, CheckIsUUID } from 'signalo-vue-utils';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

import QuizQuestionContextPicker from './QuizQuestionContextPicker.vue';

export default {
    setup() {
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { authStore, helpersStore };
    },
    props: ['modelValue', 'surveyId', 'sectionId'],
    emits: ['update:modelValue'],
    components: {
        QuizQuestionAnswerBuilder,
        // QuizBuilderQuestionValidators,
        QuizQuestionContextPicker
    },
    data() {
        return {
            question: {
                ...this.modelValue,
                required: !!this.modelValue?.required,
            },
            questionFormValid: false,
            questionTypes: QuestionTypes,
            validatorsValid: true,
        };
    },
    methods: {
        onQuestionTypeChange(type) {
            if (type !== 'RADIO' && type !== 'CHECK') {
                this.question.answers = null;
            } else if (!this.question?.answers?.length) {
                this.question.answers = [];
            }
        },
        addQuestionAnswer() {
            this.question.answers.push({
                id: uuidv4(),
                name: null,
                order: this.question.answers?.length ? (Math.max(...this.question.answers?.map(qa => qa.order)) + 1) : 1,
            });
        },
        async validate() {
            const validators = [];
            if (typeof this.$refs.questionForm?.validate === 'function') {
                const questionValidationResult = await this.$refs.questionForm.validate();
                validators.push(questionValidationResult);
            }
            if (this.$refs?.questionAnswer?.length) {
                await asyncForEach(this.$refs.questionAnswer, async (qa) => {
                    if (typeof qa.validate === 'function') {
                        const questionAnswerValidationResult = await qa.validate();
                        validators.push(...questionAnswerValidationResult);
                    }
                });
            } else if (this.$refs?.questionAnswer) {
                if (typeof this.$refs?.questionAnswer?.validate === 'function') {
                    const questionAnswerValidationResult = await this.$refs?.questionAnswer?.validate();
                    validators.push(...questionAnswerValidationResult)
                }
            }
            return validators;
        },
        onAnswerUpdate(answer) {
            const answerIndex = this.question.answers.findIndex(
                (a) => a.id === answer.id
            );
            if (answerIndex === -1) throw new Error('Answer index not found');
            if (!answer.valid) return;
            delete answer.valid;
            if (JSON.stringify(this.question.answers[answerIndex]) === JSON.stringify(answer)) return
            this.question.answers[answerIndex] = { ...answer };


            this.helpersStore.debounce(async () => {
                if (
                    this.surveyId &&
                    !CheckIsUUID(this.sectionId) &&
                    !CheckIsUUID(this.question.id)
                ) {
                    const answerData = {
                        name: answer.name,
                        order: answer.order,
                        points: Number(answer.points)
                    };
                    if (CheckIsUUID(answer.id)) {
                        const createdAnswer = await this.axios.post(
                            `quizzes/${this.surveyId}/sections/${this.sectionId}/questions/${this.question.id}/answers`,
                            answerData
                        );
                        this.question.answers[answerIndex].id =
                            createdAnswer?.data?.item?.id;
                    } else {
                        await this.axios.put(
                            `quizzes/${this.surveyId}/sections/${this.sectionId}/questions/${this.question.id}/answers/${answer.id}`,
                            answerData
                        );
                    }
                    this.helpersStore.snackbarSaved();
                }
            });
        },
        removeAnswerLocally(answerId) {
            const answerIndex = this.question.answers.findIndex(
                (a) => a.id === answerId
            );
            if (answerIndex === -1) throw new Error('Answer not found');
            this.question.answers.splice(answerIndex, 1);
        },
        async onRemoveAnswer(answerId) {
            try {
                if (
                    this.surveyId &&
                    !CheckIsUUID(this.sectionId) &&
                    !CheckIsUUID(this.question.id) &&
                    !CheckIsUUID(answerId)
                ) {
                    await this.axios.delete(
                        `quizzes/${this.surveyId}/sections/${this.sectionId}/questions/${this.question.id}/answers/${answerId}`
                    );
                    this.removeAnswerLocally(answerId);
                    this.helpersStore.snackbarSaved();
                } else {
                    this.removeAnswerLocally(answerId);
                }
            } catch (err) {
                console.error(err);
            }
        },
        removeQuestion() {
            this.$emit('removeQuestion', this.question.id);
        },
    },
    computed: {
        translatableRequired() {
            return [
                () =>
                    this.authStore.tenant.supportedLocales.every(
                        (v) => this.question?.name?.[v]
                    ) || this.$t('errors.allLanguagesRequired'),
            ];
        },
        required() {
            return [(v) => !!v || this.$t('common.fieldRequired')];
        },
    },
    watch: {
        questionFormValid(val) {
            this.$emit('update:modelValue', {
                ...this.question,
                valid: val && this.validatorsValid !== false,
            });
        },
        // validatorsValid(val) {
        //     this.$emit('update:modelValue', {
        //         ...this.question,
        //         valid: val !== false && this.questionFormValid,
        //         answers: this.answers
        //     });
        // },
        question: {
            handler(val) {
                this.$refs?.questionForm?.validate();
                this.$emit('update:modelValue', {
                    ...val,
                    valid: this.questionFormValid,
                    // && this.validatorsValid !== false,
                });
            },
        },
        'question.question_type': {
            handler() {
                if (this.question.question_type !== 'RADIO' && this.question.question_type !== 'CHECK') {
                    this.question.max_points = null;
                }
                this.$refs?.questionForm?.validate();
                this.$emit('update:modelValue', {
                    ...this.question,
                    valid:
                        this.questionFormValid &&
                        this.validatorsValid !== false,
                });
            },
            deep: true
        },
        'question.name': {
            handler() {
                this.$refs?.questionForm?.validate();
                this.$emit('update:modelValue', {
                    ...this.question,
                    valid:
                        this.questionFormValid &&
                        this.validatorsValid !== false,
                });
            },
            deep: true
        },
        'question.required': {
            handler() {
                this.$refs?.questionForm?.validate();
                this.$emit('update:modelValue', {
                    ...this.question,
                    valid:
                        this.questionFormValid &&
                        this.validatorsValid !== false,
                });
            },
        },
        'question.context_parameter_id': {
            handler() {
                this.$refs?.questionForm?.validate();
                this.$emit('update:modelValue', {
                    ...this.question,
                    valid:
                        this.questionFormValid &&
                        this.validatorsValid !== false,
                });
            },
        },
        'question.order': {
            handler() {
                this.$refs?.questionForm?.validate();
                this.$emit('update:modelValue', {
                    ...this.question,
                    valid:
                        this.questionFormValid &&
                        this.validatorsValid !== false,
                });
            },
        },
        'question.max_points': {
            handler() {
                this.$refs?.questionForm?.validate();
                this.$emit('update:modelValue', {
                    ...this.question,
                    valid:
                        this.questionFormValid &&
                        this.validatorsValid !== false,
                });
            },
        },
        'question.min_points_to_pass': {
            handler() {
                this.$refs?.questionForm?.validate();
                this.$emit('update:modelValue', {
                    ...this.question,
                    valid:
                        this.questionFormValid &&
                        this.validatorsValid !== false,
                });
            },
        },
        'question.fail_quiz': {
            handler() {
                this.$refs?.questionForm?.validate();
                this.$emit('update:modelValue', {
                    ...this.question,
                    valid:
                        this.questionFormValid &&
                        this.validatorsValid !== false,
                });
            },
        },
    },
};
</script>

<style>
.survey-builder-section-question {
    border: 1px solid var(--greyscale-50);
    border-radius: 8px;
    margin-bottom: 16px;
    margin-left: 64px;
    margin-right: 8px;
}

.add-section-question-answers-button-container {
    display: flex;
    justify-content: flex-end;
}

.remove-question-button-container {
    display: flex;
    justify-content: flex-end;
}

.survey-question-context-picker-container {
    padding-bottom: 32px;
}

.v-card-text.survey-question-name {
    padding-bottom: 0;
}
</style>
