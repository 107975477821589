export const pt = {
    common: {
        ok: 'Ok',
        authorizationRequiredDescriptionTerminal:
            'Use seu @:common.PIN_CODE_LABEL de acesso para autorizar a ação.',
        countersValues: 'Valores dos contadores',
        countableId: 'ID do campo contável',
        defaultValuesForCategory: 'Valores padrão para a categoria',
        check_user: 'O usuário é',
        check_terminal: 'O terminal é',
        bannedEmployees: 'Funcionários banidos',
        banEmployee: 'Banir acesso',
        banned: 'Banido',
        employeeBans: 'Proibições de funcionários',
        commentAboutEmployee: 'Refere-se ao funcionário',
        comments: 'Comentários',
        visitLog: 'Registro de visitas',
        categoryStructure: 'Estrutura de categoria',
        appVersionMismatch: 'Versão do aplicativo incompatível',
        appVersionMismatchMessage:
            'Atualize o aplicativo para sincronizar a versão.',
        PIN_CODE_LABEL: 'Código PIN',
        comment: 'Comentário',
        year: 'Ano',
        configuration: 'Configuração',
        cancel: 'Cancelar',
        search: 'Pesquisar',
        remove: 'Remover',
        confirmationDialogTitle: 'Você tem certeza de remover este item?',
        selectDate: 'Selecionar data',
        select: 'Selecionar',
        notFound: 'Não encontrado',
        equipmentPickerPlaceholder: 'Objeto de equipamento',
        searchPlaceholder: 'Pesquisar',
        equipmentTypePickerPlaceholder: 'Tipo de equipamento',
        equipmentTypePickerTitle: 'Selecionar tipo de equipamento',
        emptyTableMessage: 'Sem dados',
        flowPikcerTitle: 'Selecionar fluxo',
        selectIcon: 'Selecionar ícone',
        locationPickerTitle: 'Selecionar localização',
        locationPlaceholder: 'Localização',
        noSublocations: 'Sem sublocalizações',
        locationsTitle: 'Localizações',
        edit: 'Editar',
        save: 'Salvar',
        added: 'Adicionado',
        updated: 'Atualizado',
        add: 'Adicionar',
        removed: 'Removido',
        reload: 'Recarregar',
        confirm: 'Confirmar',
        rollBack: 'Reverter',
        images: 'Imagens',
        documents: 'Documentos',
        download: 'Baixar',
        saved: 'Salvo',
        date: 'Data',
        hour: 'Hora',
        preview: 'Pré-visualização',
        data: 'Dados',
        attributes: 'Atributos',
        checkList: 'Lista de verificação',
        name: 'Nome',
        type: 'Tipo',
        status: 'Status',
        designation: 'Designação',
        symbol: 'Símbolo',
        serialNumber: 'Número de série',
        registryNumber: 'Número de registro',
        machineNumber: 'Número de máquina',
        udtNumber: 'Número UDT',
        manufacturedYear: 'Ano de fabricação',
        manufacturer: 'Fabricante',
        supplier: 'Fornecedor',
        mainSupplier: 'Fornecedor principal',
        attribute: 'Atributo',
        nameRequired: 'Nome é obrigatório',
        yearGreaterThan: 'O ano deve ser maior que',
        location: 'Localização',
        fieldRequired: 'Este campo é obrigatório',
        description: 'Descrição',
        minimumQuantity: 'Quantidade mínima',
        optimalQuantity: 'Quantidade ótima',
        priority: 'Prioridade',
        formatYYYY: 'Formato YYYY',
        storageQuantity: 'Quantidade em armazém',
        quantity: 'Quantidade',
        quantityGreaterThan: 'A quantidade deve ser maior que',
        machine: 'Máquina',
        tool: 'Ferramenta',
        order: 'Pedido',
        required: 'Obrigatório',
        dateFormat: 'Formato de data',
        selectDictionary: 'Selecionar dicionário',
        return: 'Retornar',
        parameters: 'Parâmetros',
        editCategory: 'Editar categoria',
        addCategory: 'Adicionar nova categoria',
        changeColor: 'Mudar cor',
        noColor: 'Sem cor',
        shift: 'Turno',
        start: 'Início',
        end: 'Fim',
        shiftSchema: 'Esquema de turnos',
        shifts: 'Turnos',
        dictionary: 'Dicionário',
        subEntries: 'Subentradas',
        key: 'Chave',
        value: 'Valor',
        moveUp: 'Mover para cima',
        moveDown: 'Mover para baixo',
        addLocation: 'Adicionar nova localização',
        editLocation: 'Editar localização',
        companiesDatabase: 'Banco de dados de empresas',
        flows: 'Fluxos',
        read: 'Ler',
        write: 'Escrever',
        noImage: 'Sem imagem',
        email: 'E-mail',
        function: 'Função',
        active: 'Ativo',
        notActive: 'Não ativado',
        disabled: 'Desativado',
        entryNumber: 'Número de entrada',
        issuedDate: 'Data de emissão',
        expiresDate: 'Data de expiração',
        move: 'Mover',
        separateForm: 'Separar de',
        separate: 'Separar',
        warehouse: 'Armazém',
        actions: 'Ações',
        putInCurrentLocation: 'Colocar na localização atual',
        selectLocationFormRightMenu: 'Selecionar localização do menu à direita',
        showUnassignedItems: 'Mostrar itens não atribuídos',
        separated: 'Separado',
        moved: 'Movido',
        categories: 'Categorias',
        welcome: 'Bem-vindo',
        loginToSignaloTpm: 'Entre no Signalo TPM',
        login: 'Entrar',
        loginByEmailAndPassword: 'Entrar por e-mail e senha',
        loginByCode: 'Entrar pelo @:common.PIN_CODE_LABEL',
        accessCode: 'Código de acesso',
        enterEmail: 'Insira o e-mail',
        enterPassword: 'Insira a senha',
        enterCode: 'Insira o @:common.PIN_CODE_LABEL',
        userNotFound: 'Usuário não encontrado',
        userNotActivated: 'Usuário não ativado',
        userDisabled: 'Usuário desativado',
        wrongPassword: 'Senha incorreta',
        locations: 'Localizações',
        pageNotFound: 'Página não encontrada',
        group: 'Grupo',
        yes: 'Sim',
        no: 'Não',
        hourlyCost: 'Custo por hora',
        price: 'Preço',
        newEntry: 'Nova entrada',
        predictedFinishTime: 'Tempo previsto de conclusão',
        archive: 'Arquivo',
        costs: 'Custos',
        companyManagement: 'Gerenciamento de empresas',
        companyData: 'Dados da empresa',
        supportedLanguages: 'Idiomas suportados',
        en: 'Inglês',
        pl: 'Polonês',
        es: 'Espanhol',
        de: 'Alemão',
        it: 'Italiano',
        fr: 'Francês',
        ru: 'Russo',
        uk: 'Ucraniano',
        pt: 'Português',
        tr: 'Turco',
        nl: 'Holandês',
        sr: 'Sérvio',
        ar: 'árabe',
        he: 'hebraico',
        zh: 'Chinês',
        defaultLanguage: 'Idioma padrão',
        companyLogo: 'Logotipo da empresa',
        notificationsManagement: 'Gerenciamento de notificações',
        category: 'Categoria',
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        close: 'Fechar',
        show: 'Mostrar',
        rollBackFormalConfirmation: 'Confirmação formal de reversão',
        signUp: 'Cadastre-se',
        nameSurname: 'Nome e sobrenome',
        phone: 'Número de telefone',
        password: 'Senha',
        password_confirmation: 'Confirme a senha',
        unassignedItems: 'Itens não atribuídos',
        currency: 'Moeda',
        general: 'Geral',
        timezone: 'Fuso horário',
        users: 'Usuários',
        groups: 'Grupos',
        productionLine: 'Linha de produção',
        part: 'Peça',
        fittingParts: 'Peças ajustáveis',
        workSchema: 'Esquema de trabalho',
        workSchemas: 'Esquemas de trabalho',
        holiday: 'Feriado',
        changePassword: 'Mudar senha',
        showRest: 'Mostrar o resto',
        pageNotPermitted: 'Página não permitida',
        waitingForActivation: 'Aguardando ativação',
        editGroup: 'Editar grupo',
        addGroup: 'Adicionar grupo',
        reportProblem: 'Relatar problema',
        describeOccuredIssue: 'Descreva o problema ocorrido',
        markAllAsRead: 'Marcar tudo como lido',
        unitType: 'Tipo de unidade',
        unit: 'Unidade',
        unitPrice: 'Preço por unidade',
        notConfigured: 'Não configurado',
        rowsPerPage: 'Linhas por página',
        rowsOfPage: 'de',
        true: 'Verdadeiro',
        false: 'Falso',
        export: 'Exportar',
        chooseFileFormat: 'Escolha o formato do arquivo',
        exported: 'Exportado',
        terminals: 'Terminais',
        terminal: 'Terminal',
        selectEquipment: 'Selecionar equipamento',
        futureIssues: 'Problemas futuros',
        scheduledOn: 'Agendado em',
        planned: 'Planejado',
        notPlanned: 'Não planejado',
        showOpenIssues: 'Mostrar problemas abertos',
        pin: 'PIN',
        assignedEquipment: 'Equipamento atribuído',
        terminalEquipment: 'Equipamento do terminal',
        detach: 'Desanexar',
        attach: 'Anexar',
        dashboardConfig: 'Configuração do painel',
        addColumn: 'Adicionar coluna',
        addRow: 'Adicionar linha',
        removeRow: 'Remover linha',
        registerTerminal: 'Registrar terminal',
        issueGeneratesCost: 'Tempo de inatividade gera custo',
        selectColor: 'Selecionar cor',
        belowMinimum: 'Abaixo do mínimo',
        belowOptimum: 'Abaixo do ótimo',
        synchronization: 'Sincronização',
        synchronizationExport: 'Exportação de peças em estoque',
        synchronizationImport: 'Importação de peças em estoque',
        mapping: 'Mapeamento',
        returnTo: 'Voltar para',
        jobStatusses: 'Processos',
        hidden: 'Oculto',
        editEvent: 'Editar evento',
        threeDays: '3 dias',
        addEvent: 'Adicionar evento',
        plannedIssues: 'Problemas planejados',
        currentService: 'Serviço atual',
        searchLocation: 'Procurar localização',
        all: 'Todos',
        unfinished: 'Incompleto',
        numberOfIssues: 'Quantidade de problemas',
        unknown: 'Desconhecido',
        partEntry: 'Peça (entrada)',
        foundItems: 'Itens encontrados',
        output: 'Saída',
        copied: 'Copiado',
        link: 'Link',
        authorizationRequired: 'Autorização necessária',
        authorizationRequiredDescriptionUser:
            'Infelizmente, você não tem autorização suficiente. Peça para uma pessoa autorizada aprovar a ação.',
        showAll: 'Mostrar tudo',
        showClassicView: 'Mostrar visão clássica',
        noIssuesInCategory: 'Não há problemas nesta categoria.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Número de problemas nesta categoria e subcategorias.',
        numberOfIssuesInThisCategory: 'Número de problemas nesta categoria.',
        minBarcodeLength: 'Comprimento mínimo de código de barras',
        currentPage: 'Página atual',
        allPages: 'Todas as páginas',
        selectFormat: 'Selecionar formato',
        provided_currency: 'Moeda fornecida',
        provided_quantity: 'Quantidade fornecida',
        provided_unit: 'Unidade fornecida',
        provided_unit_price: 'Preço por unidade fornecido',
        unit_price: 'Preço por unidade',
        quantity_available: 'Quantidade disponível',
        editBarcode: 'Editar código de barras',
        crews: 'Equipes',
        scheduleEventTypes: 'Tipos de eventos agendados',
        crew: 'Equipe',
        scheduleEvent: 'Evento agendado',
        available: 'Disponível',
        'App\\Models\\Crew': 'Equipe',
        'App\\Models\\User': 'Usuário',
        'App\\Models\\Equipment': 'Equipamento',
        Crew: 'Equipe',
        User: 'Usuário',
        Equipment: 'Equipamento',
        scheduleEvents: 'Eventos agendados',
        event: 'Evento',
        externalSystems: 'Sistemas externos',
        user: 'Usuário',
        availability: 'Disponibilidade',
        inheritColorFromParentIfCustomNotSet:
            'Herdar cor do pai se personalização não estiver definida',
        pickFromAllParts: 'Selecionar de todas as peças',
        takeTool: 'Pegar ferramenta',
        returnTool: 'Devolver ferramenta',
        personWhoTake: 'Pessoa que pega',
        personWhoReturn: 'Pessoa que devolve',
        takenAt: 'Pego em',
        takenBy: 'Pego por',
        toolIssuedBy: 'Ferramenta emitida por',
        commentTake: 'Comentário de pegar',
        commentReturn: 'Comentário de devolver',
        change: 'Mudar',
        onlyWithActiveRMIssues: 'Somente com solicitações de RM ativas',
        externalSystem: 'Sistema externo',
        rejectIssue: 'Rejeitar problema',
        withdrawIssueRejection: 'Retirar rejeição de problema',
        rejectionReason: 'Razão da rejeição',
        rejected: 'Rejeitado',
        showCrewMembers: 'Mostrar membros da equipe',
        crewMembers: 'Membros da equipe',
        helpGroup: 'Serviço',
        partsTakenByUser: 'Peças retiradas pelo usuário',
        pickParts: 'Emitir peças',
        selectPersonWhoTakes: 'Selecione a pessoa que retira',
        changePersonWhoTakes: 'Mudar pessoa que retira',
        quantityToPick: 'Quantidade para pegar',
        pickLocation: 'Localização de retirada',
        selectPartsToPick: 'Selecione as peças para pegar',
        partSelected: 'Peça selecionada',
        scanCodeOr: 'Escaneie o código ou',
        returnPart: 'Devolver parte',
        myTakenParts: 'Peças retiradas por mim',
        takenFromLocation: 'Retirado da localização',
        takenQuantity: 'Quantidade retirada',
        providedUnit: 'Unidade fornecida',
        providedQuantity: 'Quantidade fornecida',
        selectFromStorage: 'Selecionar do armazenamento',
        selectFromMyPickedParts: 'Selecionar de minhas peças retiradas',
        exportProcessInProgressMessage:
            'O processo de exportação de dados acabou de começar, quando estiver concluído, você verá uma notificação correspondente contendo um link para o arquivo.',
        yourDocumentIsReady: 'Seu documento está pronto',
        rotateScreen: 'Girar tela',
        addRule: 'Adicionar regra',
        editRule: 'Editar regra',
        summary: 'Resumo',
        onlyAvailableEmployees: 'Mostrar apenas empregados disponíveis',
        calculated_unit_price: 'Preço unitário convertido',
        calculated_quantity: 'Quantidade convertida',
        next: 'Seguinte',
        maintenanceMode: 'Modo de manutenção',
        applicationMayBeUnavailableForFewMinutes:
            'O pedido pode ficar indisponível durante alguns minutos',
        appWillRefreshIn: 'A aplicação será recarregada em',
        landscape: 'Paisagem',
        portrait: 'Retrato',
        orientation: 'Orientação',
        format: 'Formato',
        service: 'Criados',
        leader: 'Capatazes',
        defaultView: 'Vista por defeito',
        view: 'Vista',
        oneDay: '1 dia',
        object: 'Objecto',
        finished: 'Terminado',
        onlyNotFinished: 'Só não acabou',
        generate: 'Gerar',
        error: 'Erro',
        cell: 'Célula',
        resolutionSummary: 'Resumo final',
        rejected_at: 'Data de rejeição',
        rejected_by: 'Rejeitado por',
        finishedByUser: 'Completado por',
        formalConfirmByUser: 'Confirmação formal por',
        formalConfirmAt: 'Data de confirmação formal',
        valueDeleted: '[Valor excluído]',
        hasNotPlannedIssue: 'Não planejou problemas',
        showInStorage: 'Mostrar no armazenamento',
        changeCode: 'Mude o @:common.PIN_CODE_LABEL',
        staffProfiles: 'Perfis de funcionários',
        profile: 'Perfil',
        user_bug: 'Błąd aplikacji',
        user_kaizen: 'Sugestia zmiany',
        changeSuggestions: 'Alterar sugestões',
        showOnlyCurrentlyAvailable: 'Mostrar apenas disponível no momento',
        resetPassword: 'Lembrar senha',
        forgotPassword: 'Esqueceu sua senha',
        resetPasswordMailSent:
            'Enviamos um link para definir uma nova senha para o endereço de e-mail fornecido',
        manyEventsThisTime: 'Muitos eventos neste momento',
        importScheduleCheckboxLabel:
            'Excluir eventos agendados no mês selecionado para funcionários listados no arquivo',
        inheritedEvent: 'Evento herdado',
        inheritedModifiedEvent: 'Evento herdado modificado',
        equipmentId: 'ID do equipamento',
        entryId: 'ID de entrada',
        partId: 'ID da peça',
        suggestionsEmail:
            'E-mail para o qual serão enviadas as sugestões de alteração',
        meters: 'Contadores',
        scanCardToAuth:
            'Toque o cartão no leitor ou digite seu @:common.PIN_CODE_LABEL de acesso para confirmar sua identidade.',
        scanCard: 'Escaneie o cartão',
        cart: 'Caixa de troca',
        emptyCardMessage:
            'Comece a digitalizar produtos ou selecione-os na lista para adicioná-los à cesta.',
        terminalMode: 'tipo de terminal',
        askPermittedUserToConfirmPartsExchange:
            'Peça à pessoa autorizada para confirmar a liberação da peça digitalizando o cartão ou digitando o @:common.PIN_CODE_LABEL',
        partsIssued: 'Peças lançadas',
        areYouSureAboutCancel: 'Tem certeza de que deseja cancelar?',
        areYouSureAboutRemoveFromCart:
            'Tem certeza de que deseja remover este item do carrinho?',
        standard: 'Padrão',
        idNumber: 'Número de identificação',
        takeParts: 'Retirar peças',
        returnParts: 'Devolver peças',
        quantityToReturn: 'Quantidade para devolver',
        partsPendingApproval: 'Peças pendentes de aprovação',
        partsReturned: 'Peças devolvidas',
        partsAddedToPendingApproval: 'Peças adicionadas à aprovação pendente',
        approvePartsReturn: 'Aprovar devolução de peças',
        partReturnApproved: 'Devolução de peças aprovada',
        partUsage: 'Uso de peças',
        awaitsReturn: 'Aguarda devolução',
        entrySelection: 'Seleção de entrada',
        correctQuantity: 'Corrigir quantidade',
        quantityCorrection: 'Correção de quantidade',
        entrySelectionAsc: 'Seleção de entrada ascendente',
        entrySelectionDesc: 'Seleção de entrada descendente',
        metersSettings: 'Configurações de contadores',
        countableFields: 'Campos contáveis',
        equipmentTypes: 'Tipos de equipamento',
        fieldName: 'Nome do campo',
        countableField: 'Campo contável',
        partsStatusTaken: 'Retirado',
        rejectPartsReturn: 'Rejeitar devolução de peças',
        areYouSureAboutRejectPartsReturn:
            'Tem certeza de que deseja rejeitar a devolução de peças?',
        partReturnRejected: 'Devolução de peças rejeitada',
        counterFields: 'Campos de contadores',
        counterName: 'Nome do contador',
        counterFieldName: 'Nome do campo do contador',
        counterId: 'ID do contador',
        counterUUID: 'UUID do contador',
        counterPickerPlaceholder: 'Selecione o contador',
        pickFromPartsList: 'Escolha da lista de peças',
        changeLocation: 'Mudar localização',
        isPartStorageRoot: 'Raiz do armazenamento de peças',
        areYouSureAboutArchiveIssue:
            'Tem certeza de que deseja arquivar este problema?',
        ganttChart: 'Gráfico de Gantt',
        table: 'Tabela',
        fittingEquipment: 'Equipamento de montagem',
        notAssigned: 'Não atribuído',
        counters: 'Contadores',
        machineHourlyCostNotProvided:
            'O custo horário da máquina não foi fornecido',
        eventTypesMightBeDefinedHere:
            'Os tipos de eventos podem ser definidos aqui',
        toolsTakenByUser: 'Ferramentas retiradas pelo usuário',
        predictedReturnDate: 'Data de devolução prevista',
        estimatedTimeOfUsingToolInHours:
            'Tempo estimado de uso da ferramenta (em horas)',
        showOnlyAvailable: 'Mostrar apenas disponível',
        toolTakeLog: 'Registro de retirada de ferramentas',
        takeDate: 'Data de retirada',
        returnDate: 'Data de devolução',
        personWhoAcceptReturn: 'Pessoa que aceita a devolução',
        statisticStrategy: 'estratégia de acessibilidade',
        EquipmentPlannedStrategy: 'De acordo com o plano de trabalho',
        EquipmentConstantAvailabilityStrategy: 'Sempre disponível',
        actionAfterIssueFinish: 'Ação após o término do problema',
        enabled: 'Habilitado',
        categoryOfNewIssue: 'Categoria de novo problema',
        workNotStarted: 'Trabalho não iniciado',
        colorInherited: 'Cor herdada',
        abcClassification: 'Classificação ABC',
        xyzClassification: 'Classificação XYZ',
        classification: 'Classificação',
        filters: 'Filtros',
        wipeFilters: 'Limpar filtros',
        takePartsFromUser: 'Retirar peças do usuário',
        selectPersonWhoReturns: 'Selecione a pessoa que devolve as peças',
        changePersonWhoReturns: 'Alterar a pessoa que devolve as peças',
        returnLocation: 'Local de devolução',
        returnQuantity: 'Quantidade de devolução',
        globalSettings: 'Configurações globais',
        delimiter: 'Delimitador',
        fieldRules: 'Regras de campo',
        fieldValue: 'Valor do campo',
        user_email_regex: 'Regex de e-mail do usuário',
        barcodeLength: 'Comprimento do código de barras',
        partLine: 'Linha de peças',
        partFamily: 'Família de peças',
        partBatch: 'Lote de peças',
        testValueValid: 'valor de teste válido',
        testValueNotValid: 'Valor de teste inválido',
        canBePicked: 'Pode ser escolhido',
        issue_create_photos: 'Adicionando fotos a um novo problema',
        issue_create_documents: 'Adicionando documentos a um novo problema',
        propagateValues: 'Propagação de valor',
        archived: 'Arquivado',
        escalations_tab: 'Escalações',
        minute: 'Minutos',
        days: 'Dias',
        hours: 'Horas',
        minutes: 'Minutos',
        warnDays: 'Alerta antes da expiração (dias)',
        licenseType: 'Tipo de licença',
        licenseGroup: 'Grupo de licença',
        attachments: 'Anexos',
        attachment: 'Anexo',
        revisionChanges: 'Mudanças introduzidas',
        document: 'Documento',
        noInstructionSelected: 'Nenhuma instrução selecionada',
        instructionGroups: 'Grupos de instruções',
        instructionGroup: 'Grupo de instrução',
        addOption: 'Adicione uma opção',
        ordinal: 'Valor ordinal',
        parameterName: 'Nome do parâmetro',
        fontColor: 'Cor da fonte',
        backgroundColor: 'Cor de fundo',
        columns: 'Numero de colunas',
        colspan: 'Largura (colunas)',
        products: 'Produtos',
        controlsTemplates: 'Modelos de controle',
        controlType: 'Tipo de controlo',
        controls: 'Inspeções',
        controlTemplate: 'Modelo de controle',
        product: 'Produtos',
        checklistsAttachedToControlTemplate:
            'Listas de verificação atribuídas às inspeções',
        removeChecklistFromControlTitle:
            'Tem certeza de que deseja remover esta lista do seu controle?',
        attachChecklistToControl: 'Atribuir uma lista para controlar',
        pickedItem: 'Item selecionado',
        pick: 'Escolher',
        fillControl: 'Conclua a verificação',
        back: 'Retornar',
        control: 'Ao controle',
        duplicate: 'Duplicado',
        isStorageRoot: 'Localização principal do armazém',
        manageStorageAccess: 'Gerenciar o acesso aos armazéns',
        manageVisibleColumns: 'Ajustar a visibilidade da coluna',
        columnName: 'Nome da coluna',
        visible: 'Visível',
        entriesLocation: 'localização das entradas',
        details: 'Detalhes',
        otherData: 'Outros dados',
        moveAllEntriesFromCurrentLocation:
            'Mover todas as entradas deste local',
        searchInCurrentLocation: 'Pesquise na sua localização atual',
        transition: 'Transição',
        notification: 'Notificação',
        emails: 'Endereço de e-mail',
        phones: 'Números de telefone',
        addAction: 'Adicione uma ação',
        message: 'Mensagem',
        application: 'Aplicativo',
        always: 'Sempre',
        sms: 'SMS',
        loginTo: 'Logar em',
        appName: 'Signalo TPM',
        index: 'Índice',
        showCodeOwnerNameDuringParing:
            'Mostrar o nome do proprietário do @:common.PIN_CODE_LABEL ao emparelhar com cartão/etiqueta',
        showUsernameInCart: 'Mostrar nome de usuário no carrinho',
        sampleButtonPlaceholder: 'Botão de amostra',
        preferences: 'Preferências',
        buttonsSize: 'Tamanho do botão',
        erase: 'Apagar',
        selectDictionaryValue: 'Selecione o valor do dicionário',
        conditions: 'Condições',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Exigir confirmação ou rejeição ao devolver peças baixadas',
        issueCreated: 'Um ticket foi criado',
        allowedIssuesCategories: 'Categorias de problemas permitidos',
        hiddenFields: 'Campos ocultos',
        defaultValues: 'Valores padrão',
        paddingBottom: 'Espaço vazio na parte inferior',
        allVisibleFieldsRequired:
            'Exigir que todos os campos visíveis sejam preenchidos',
        url: 'URL',
        kind: 'Tipo',
        forkfleetConfigId: 'Configuração Forkfleet',
        create: 'Criar',
        terminate: 'Terminar',
        parent: 'Pai',
        phoneNumbers: 'Números de telefone',
        storageDefaults: 'Valores de armazenamento padrão',
        clientSignatureEnabled: 'Assinatura da pessoa que coleta',
        warehousemanSignatureEnabled: 'Assinatura do Armazém',
        storage_client_signature_enabled:
            'Assinatura da pessoa que retira os itens do armazém',
        storage_client_signature_required:
            'Exigir a assinatura da pessoa que retira os itens do armazém',
        storage_warehouseman_signature_enabled:
            'Assinatura da pessoa que libera os itens do armazém',
        storage_warehouseman_signature_required:
            'Exigir a assinatura da pessoa que libera os itens do armazém',
        clientReturnSignatureEnabled: 'Assinatura da pessoa que retorna',
        warehousemanReturnSignatureEnabled:
            'Assinatura da pessoa que aceita a devolução',
        storage_client_return_signature_enabled:
            'Assinatura da pessoa que devolve os itens',
        storage_client_return_signature_required:
            'Exigir uma assinatura da pessoa que devolve os itens',
        storage_warehouseman_return_signature_enabled:
            'Assinatura da pessoa que aceita a devolução do item',
        storage_warehouseman_return_signature_required:
            'Exigir a assinatura da pessoa que aceita a devolução do item',
        channels: 'Canais',
        manageState: 'Gerenciar estado',
        assignWorker: 'Atribuir um funcionário',
        mergeEntriesOfTheSameLocation: 'Mesclar entradas do mesmo local',
        permission: 'Permissão',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'Tem certeza de que deseja mesclar todas as entradas neste local?',
        manageFilters: 'Gerenciar filtros',
        responsible_assign_creator_when_no_rule:
            '[Relatórios] - Atribua o criador como responsável se a regra não corresponder',
        recreateIssue: 'Recrie o ticket',
        addNewDictionary: 'Adicionar um novo dicionário',
        label: 'Rótulo',
        issuesWithRelatedCategories: 'Incluir subcategorias',
        issuesLimit: 'Limite de problemas',
        issuesLimits: 'Problemas - limites',
        issueDefaults: 'Relatórios – valores padrão',
        flatCategoryPickerLabel: 'Estrutura de categoria plana',
        statementsTemplates: 'Modelos de declaração',
        statementTemplate: 'Modelo de declaração',
        content: 'Conteúdo',
        readonly: 'Somente leitura',
        defaultValue: 'Valor padrão',
        fillParametersToChangeState:
            'Preencha os valores dos parâmetros abaixo para alterar o status',
        formatPattern: 'Padrão de formato',
        countableIncFields: 'Configuração de numeração de documentos',
        mainShift: 'Turno principal',
        singleEvent: 'Entrada única',
        seriesOfEvents: 'Uma série de entradas',
        omitWeekends: 'Evite fins de semana',
        allEntriesSaved: 'Todas as entradas foram adicionadas',
        surveyResults: 'Resultados da pesquisa',
        qrActionsSettings: 'Configurações de ação do código QR',
        pendingStatements: 'Declarações para assinar',
        statementsResults: 'Declarações',
        statements: 'Declarações',
        statement: 'Declaração',
        userCanChangeContent: 'O usuário pode alterar o conteúdo',
        statementsEvents: 'Configuração de evento - instruções',
        helpdesk: 'Central de Ajuda',
        terminalId: 'ID do terminal',
        operatorId: 'ID do operador',
        newConversation: 'Novo tópico',
        formula: 'Fórmula',
        fill: 'Completo',
        webMessage: 'Mensagem da web',
        noResponsibleUser: 'Nenhum usuário responsável',
        filledAtDate: 'Data de conclusão',
        filledBy: 'Completado por',
        parts_quantity_alert_rule: '[Quantidade de peças e materiais] - Aviso',
        employeeNumber: 'Número de empregado',
        groupsAssignedToCategory: 'Grupos atribuídos a categorias',
        number: 'Número',
        customers: 'Clientes',
        customer: 'Cliente',
        projects: 'Projetos',
        project: 'Projeto',
        report: 'Relatório',
        reportGenerationInProgress: 'O relatório está gerando',
        storageStatementGroup: 'Grupo de declaração de armazém',
        simplifiedHandling: 'Tratamento simplificado de notificações',
        selectWorkArea: 'Selecione sua área de trabalho',
        workAreaSelection: 'Selecionando uma área de trabalho',
        access: 'Acesso',
        restrictAccess: 'Acesso restrito',
        anchor: 'Ancoragem',
        issueCreator: 'A pessoa que cria o relatório',
        hideSideMenu: 'Ocultar menu lateral',
        appendGroups: 'Grupos para adicionar',
        revokeGroups: 'Grupos a serem removidos',
        override: 'Substituir',
        restrictGroupAccess: 'Restringir o acesso a grupos',
        flag: 'Bandeira',
        flags: 'Bandeiras',
        manageFlags: 'Gerenciar sinalizadores',
        icon: 'Icone',
        appendFlags: 'Sinalizadores para adicionar',
        revokeFlags: 'Sinalizadores a serem removidos',
        noFlagsConfigured: 'Nenhuma sinalização definida',
        checkIsTerminal: 'Verificando o terminal',
        noConditionsDefined: 'Nenhuma condição definida',
        checkIsResponsible: 'Verificação de responsabilidade',
        createdOn: 'Relatado de',
        replaceTimespan: 'Prazo de validade a partir do momento da emissão',
        months: 'Meses',
        handoversTemplates: 'Modelos de transferência',
        handoverTemplate: 'Modelo de transferência',
        employeeFunction: 'Função do funcionário',
        takeHandover: 'Faça a transferência',
        handovers: 'Transferências',
        expiringHandovers: 'Itens que estão perto da data de validade',
        years: 'Anos',
        taken_parts_expiry_warning:
            'Aviso antes da data de expiração dos itens baixados',
        andonView: 'Vista Andon',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'outro',
        tags: 'Cartões / Tags',
        tag: 'Cartão / Tag',
        userPinCode: '@:common.PIN_CODE_LABEL do usuário do cartão',
        emailConfirmation: 'Confirmação de endereço de e-mail',
        from: 'De',
        to: 'Para',
        handoversHistory: 'Histórico de transferências',
        partUsagesHistory: 'Histórico de uso de peças',
        signaturePinTagConfirmationText1: 'Assinar com @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'ou escaneie seu cartão.',
        signedWithPinCode: 'Assinado com @:common.PIN_CODE_LABEL',
        signedWithTAG: 'Assinado com cartão/etiqueta',
        test_mode: 'Modo de teste',
        confirmWorkFinish: 'Confirme o término do trabalho',
        workStarted: 'Início do trabalho',
        workFinished: 'Acabamento de trabalho',
        workTimeRegister: 'Registro de horário de trabalho',
        measureTypes: 'Tipos de medições',
        measureType: 'Tipo de medição',
        measurable: 'Indicador mensurável',
        measurables: 'Indicadores mensuráveis',
        measurements: 'Medição',
        measurement: 'Medição',
        measuredAt: 'Data de medição',
        currentVersion: 'Versão Atual',
        employee: 'Funcionário',
        pin_code_label: 'Nome do PIN',
        orders: 'Pedidos',
        productionPlan: 'Plano de produção',
        productionPlans: 'Planos de produção',
        taktTime: 'Tempo takt',
        target: 'Alvo',
        seconds: 'Segundos',
        inheritValuesFromTenantSettings:
            'Herdar valores padrão das configurações globais',
        matrices: 'Matrizes',
        manageMatrice: 'Gerenciar Matriz',
        deletionOfMatrixColumnWarnMessage:
            'Excluir uma coluna exclui os valores.',
        removeColumn: 'Excluir uma coluna',
        editColumn: 'Editar coluna',
        column: 'Coluna',
        row: 'Linha',
        evaluated: 'avaliado',
        of: 'de',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - Geração automática',
        or: 'ou',
        rename: 'Renomear',
        check_only_on_transition: 'Verifique apenas quando o status mudar',
        license: 'Poderes',
        confirmWorkStart: 'Confirme o início do trabalho',
        workStartAt: 'Você começa a trabalhar em',
        workFinishAt: 'Você termina seu trabalho',
        workStartedAt: 'Começando',
        workTimeMessage: 'Você trabalhou demais',
        haveANiceDay: 'Tenha um bom dia!',
        farewellText: 'Texto na última tela',
        namedayLabel: 'Eles comemoram o dia do seu nome',
        sportEventNearby: 'O próximo evento esportivo',
        autoTranslate: 'Tradução automática',
        auto_translation: 'Tradução automática',
        awaitingAutoTranslation: 'Aguardando tradução automática',
        inheritAllDefaultValues:
            'Herdar todos os valores padrão das configurações globais',
        escalations: 'Escalações',
        escalation: 'Escalação',
        notTaken: 'Não foi iniciado',
        notFinished: 'Inacabado',
        issueLastChange: 'Última mudança',
        mode: 'Modo',
        repeatLastEscalation: 'Repetir o último valor de escalonamento',
        manageActions: 'Gerencie suas ações',
        productionLines: 'Linhas de produção',
        productionTerminal: 'Terminal de produção',
        currently: 'Atualmente',
        currentPlan: 'Plano atual',
        currentlyInProduction: 'Atualmente produzido',
        setProduct: 'Definir produto',
        second: 'Segundos',
        work: 'Trabalhar',
        chartConfiguration: 'Configuração do gráfico',
        revisionsToConsider: 'Revisões para consideração',
        allInstructions: 'Todas as instruções',
        decider: 'Determinador',
        considerRevision: 'Considere a revisão',
        and: 'e',
        acceptInstruction: 'Aceite as instruções',
        accept: 'aceitar',
        instructionsToAccept: 'Instruções para ler',
        open: 'Abrir',
        assignedAt: 'Data da atribuição',
        confirmationAt: 'Data de confirmação',
        mainInstruction: 'Instrução padrão',
        setAsDefault: 'Definir como padrão',
        instructionsFlows: 'Instruções - fluxos',
        newRevision: 'Nova revisão',
        playlist: 'Lista de vídeos',
        selectVideo: 'Selecione um filme',
        selectVideos: 'Selecione Filmes',
        uploadNewVideo: 'Carregar novo vídeo',
        upload_date: 'Data adicionada',
        thumbnail: 'miniatura',
        duration: 'Duração',
        uploadFromDrive: 'Carregar do disco',
        selectAssets: 'Escolha materiais',
        assets: 'Materiais',
        video: 'Film',
        doYouWantToAddNewMarker: 'Gostaria de adicionar uma nova tag aqui?',
        areYouSureAboutRemoveMarker:
            'Tem certeza de que deseja remover esta tag?',
        plants: 'Plantas fabris',
        plant: 'Fábrica',
        buildings: 'Edifícios',
        building: 'Prédio',
        floors: 'Pisos',
        floor: 'Chão',
        map: 'Mapa',
        areYouSureAboutMoveMarker:
            'Tem certeza de que deseja mover este marcador?',
        marker: 'Marcador',
        addNewMarkerTooltipMessage:
            'Para adicionar um novo marcador, clique com o botão direito no mapa.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Competências não lineares',
        skillLevelConfig: 'Níveis de habilidade',
        indirectSkillGroups: 'Grupos de competências não lineares',
        approved: 'Confirmado',
        approvedBy: 'Aprovador',
        skillLevel: 'Nível de habilidade',
        requiredSkillLevel: 'Nível de competências necessário',
        step: 'Etapa',
        image: 'Foto',
        skills: 'Habilidades',
        visits: 'Visitas',
        responsiblePerson: 'Pessoa responsáve',
        company: 'Empresa',
        contactData: 'Detalhes de contacto',
        visit: 'Visitar',
        inProgress: 'Em curso',
        gatehouse: 'Portaria',
        confirmEnter: 'Confirmar entrada',
        confirmExit: 'Confirmar saída',
        enter: 'Entrada',
        exit: 'Sair',
        entryGuard: 'A pessoa que o está a deixar entrar',
        exitGuard: 'A pessoa que o está a deixar sair',
        loginAsThisUser: 'Faça login como este utilizador',
        minBrowserVersion: 'Versão mínima do browser necessária',
        approve: 'Confirmar',
        cancelApproval: 'Cancelar confirmação',
        workPermit: 'Autorização de trabalho',
        workPermits: 'Autorizações de trabalho',
        visit_summary: 'Resumo da visita',
        createVisit: 'Crie uma visita com base numa licença',
        employeesAtThePlant: 'Trabalhadores na fábrica',
        purpose: 'Alvo',
        allowedAreas: 'Áreas permitidas',
        switchEmployees: 'Substituir funcionário',
        localIssues: 'Relatórios locais',
        cmmsCategoriesSync: 'Sincronização de categoria CMMS',
        terminalsGroups: 'Grupos de terminais',
        isTree: 'Árvore',
        isReporter: 'É o repórter',
        isAnchored: 'Está ancorado',
        closable: 'Fechar tickets',
        areYouSureAboutCloseIssue:
            'Tem a certeza de que pretende fechar o ticket?',
        simplifiedIssuesRefreshLabel:
            'Tempo de atualização da lista - segundos',
        reloadApp: 'Recarregar',
        hideWorkAreasButton: 'Ocultar botão de seleção de área de trabalho',
        hideClassicViewButton: 'Ocultar botão de visualização clássica',
        hideAddIssueButton: 'Ocultar botão de adicionar novo problema',
        filesManager: 'Gerenciador de arquivos',
        addFolder: 'Adicionar pasta',
        upload: 'Carregar',
        noFolders: 'Sem pastas',
        noFiles: 'Sem arquivos',
        folders: 'Pastas',
        folder: 'Pasta',
        files: 'Arquivos',
        file: 'Arquivo',
        removeFolderFilesQuestion: 'O que fazer com os arquivos nesta pasta?',
        targetFolder: 'Pasta de destino',
        addInstruction: 'Adicionar instrução',
        goToFolder: 'Ir para a pasta',
        defaultProdLine: 'Linha de produção padrão',
        pin_code_field_type: 'Tipo de código PIN',
        instructionsAccess: 'Permissões',
        noSections: 'Sem seções',
        requireReadingInstruction: 'Exigir leitura da instrução',
        hide_parameter_types: 'Não exibir tipos de parâmetros',
        unknownVersion: 'Desconhecido',
        displayInstructionEveryTime: 'Exibir a instrução toda vez',
        requireSigningInstructionEveryTime:
            'Exigir assinatura da instrução toda vez',
        signedInstructions: 'Instruções assinadas',
        signedAt: 'Data da assinatura',
        signatures: 'Assinaturas',
        signature: 'Assinatura',
        systemGroup: 'Grupo do sistema',
        pickable: 'Selecionável',
        leaf: 'Item selecionado',
        path: 'Caminho',
        displayMode: 'Modo de exibição',
        chart: 'Gráfico',
        preSignedStatement: 'Declaração assinada em papel (completa)',
        myOpenIssues: 'Meus problemas abertos',
        myAssignedIssues: 'Problemas atribuídos a mim',
        displayParametersOnMainScreen: 'Veja os parâmetros no ecrã principal',
        lockPin: 'Bloquear mudança de PIN',
        pinLocked: 'PIN bloqueado',
        sortChange: 'Ordem de exibição',
        asc: 'Ascendente',
        desc: 'Descendente',
        alphanumeric: 'Alfanumérico',
        numeric: 'Numérico',
        modificationDate: 'Data de modificação',
        fromNewest: 'Dos mais recentes',
        fromOldest: 'Dos mais antigos',
        negation: 'Negação',
        sortOrderService: 'Serviço',
        newIssueIgnoreFilters:
            'Ignorar os filtros aplicados ao criar um novo problema',
        refreshInterval: 'Tempo de atualização da lista',
        multiline: 'Múltiplas linhas',
        expanded: 'Expandido',
        quizzes: 'Questionários',
        courses: 'Cursos',
        course: 'Curso',
        quiz: 'Questionário',
        maxPoints: 'Pontos máximos',
        minPointsToPass: 'Pontos mínimos para passar',
        questionFailQuiz:
            'Falhar uma pergunta desqualifica todo o questionário',
        points: 'Pontos',
        currentVersionPreview: 'Pré-visualização da versão atual',
        docs_sidebar_hidden_by_default:
            'Ocultar a barra lateral nos documentos por padrão',
        allCategories: 'Todas as categorias',
        test: 'Teste',
        tests: 'Testes',
        copy: 'Cópia',
        trusted_device_code_login_users_limit: "Login rápido - número de utilizadores apresentados"
    },
    placeholders: {
        part: 'Selecionar parte',
        nameSurname: 'Nome e sobrenome',
        phone: 'Número de telefone',
        password: 'Senha',
        password_confirmation: 'Confirme a senha',
        email: 'E-mail',
    },
    auth: {
        notYourAccountQuestion: 'Não é sua conta?',
    },
    signup: {
        registered: 'Sucesso!',
        emailConfirmed: 'O e-mail foi confirmado.',
        registered_info: 'Peça ao seu supervisor para ativar sua conta',
        confirmEmailAddress:
            'Sua conta foi criada, clique no link do e-mail para confirmar seu endereço.',
        tokenNotFoundError:
            'O e-mail já foi confirmado ou o link de confirmação é inválido.',
    },
    paramTypes: {
        text: 'Texto',
        note: 'Nota',
        integer: 'Número inteiro',
        decimal: 'Número decimal',
        date: 'Data',
        time: 'Hora',
        datetime: 'Data e hora',
        color: 'Cor',
        icon: 'Ícone',
        boolean: 'Não / Sim',
        dictionary: 'Dicionário',
        user: 'Usuário',
        radioGroup: 'Escolha única',
        checkboxGroup: 'Escolhas múltiplas',
        signature: 'Assinatura',
        number: 'Número',
        rating: 'Avaliação',
        document: 'Documento',
        image: 'Imagem',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Nome',
        path: 'Caminho para arquivo',
        category: 'Categoria',
        equipment: 'Equipamento',
        criticality: 'Criticidade',
        status: 'Status',
        created: 'Criado',
        createdBy: 'Criado por',
        fp: 'FP',
        type: 'Tipo',
        designation: 'Designação',
        symbol: 'Símbolo',
        serialNumber: 'Número de série',
        registryNumber: 'Número de registro',
        udtNumber: 'Número UDT',
        manufacturedYear: 'Ano de fabricação',
        actions: 'Ações',
        title: 'Título',
        data: 'Data',
        archived: 'Arquivado',
        manufacturerSymbol: 'Símbolo do fabricante',
        min: 'Min',
        opt: 'Óptimo',
        quantity: 'Quantidade',
        priority: 'Prioridade',
        price: 'Preço',
        kind: 'Tipo',
        description: 'Descrição',
        default: 'Padrão',
        warning: 'Aviso',
        problem: 'Problema',
        fixing: 'Resolvendo',
        changeOrder: 'Ordem de mudança',
        required: 'Necessário',
        icon: 'Icone',
        color: 'Cor',
        subCategories: 'Subcategorias',
        barCode: 'Código de barras',
        subLocations: 'Sublocalizações',
        categories: 'Categorias',
        image: 'Imagem',
        nameSurname: 'Nome e sobrenome',
        email: 'Email',
        phone: 'Número de telefone',
        function: 'Função',
        department: 'Departamento',
        accountStatus: 'Status da conta',
        shift: 'Turno',
        licenseNumber: 'Número de licença',
        issuedDate: 'Data de emissão',
        expiresDate: 'Data de expiração',
        entries: 'Entradas',
        entryNumber: 'Número de entrada',
        buyPrice: 'Preço de compra',
        currentLocationQuantity: 'Quantidade na localização atual',
        group: 'Grupo',
        string: 'Texto',
        integer: 'Inteiro',
        toolsGroup: 'Grupo de ferramentas',
        manufacturer: 'Fabricante',
        shortName: 'Nome curto',
        locationName: 'Nome da localização',
        subLocationsQuantity: 'Quantidade de sublocalizações',
        isWorkingOnIssue: 'Trabalhando em problema',
        openIssues: 'Problemas abertos',
        notificationDate: 'Data de notificação',
        titleAndDescription: 'Título e descrição',
        read: 'Ler',
        created_at: 'Criado',
        received_at: 'Recebido',
        started_at: 'Começou',
        arrived_at: 'Chegou',
        finished_at: 'Concluído',
        country: 'País',
        city: 'Cidade',
        progress: 'Progresso',
        deleted_at: 'Excluir data',
        about_issue: 'Aplica-se ao relatório.',
        action: 'Compartilhar',
    },
    searchConfig: {
        id: 'Id',
        name: 'Nome',
        shortName: 'Nome curto',
        country: 'País',
        email: 'E-mail',
        group: 'Grupo',
        planned: 'Planejado',
        category: 'Categoria',
        object: 'Objeto',
        criticality: 'Criticidade',
        fp: 'FP',
        type: 'Tipo',
        designation: 'Designação',
        symbol: 'Símbolo',
        serialNumber: 'Número de série',
        registryNumber: 'Número de registro',
        udtNumber: 'Número UDT',
        manufacturedYear: 'Ano de fabricação',
        manufacturerSymbol: 'Símbolo do fabricante',
        priority: 'Prioridade',
        quantity: 'Quantidade',
        buyPrice: 'Preço de compra',
        kind: 'Tipo',
        toolsGroup: 'Grupo de ferramentas',
        function: 'Função',
        department: 'Departamento',
        status: 'Status',
        nameSurname: 'Nome e sobrenome',
        phone: 'Telefone',
        barCode: 'Código de barras',
        color: 'Cor',
        icon: 'Ícone',
        machine: 'Máquina',
        tool: 'Ferramenta',
        urgentAndOutdated: 'Urgente e obsoleto',
        urgent: 'Urgente',
        expired: 'Expirado',
        manufacturer: 'Fabricante',
    },
    errors: {
        uploadError: 'Erro no upload',
        finishedSuccessfully: 'Concluído com sucesso',
        partsAvailabilityError:
            'A disponibilidade de alguns itens selecionados mudou. Edite o conteúdo da sua caixa de troca e tente novamente.',
        noFlagsConfigured: 'Nenhuma bandeira configurada',
        partMustHaveEntriesToCorrectQuantity:
            'A peça deve ter entradas para corrigir a quantidade.',
        error: 'Ocorreu um erro',
        wrongEmail: 'Email inválido',
        wrongPasswordLength:
            'A senha deve ter pelo menos 8 caracteres de comprimento.',
        wrongCodeLength:
            'O @:common.PIN_CODE_LABEL deve ter pelo menos 5 caracteres de comprimento.',
        passwordsNotMatch: 'As senhas não correspondem',
        codesNotMatch: 'Os @:common.PIN_CODE_LABEL não correspondem',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL já utilizado',
        expiresDateLowerThanIssued:
            'A data de expiração não pode ser menor que a data de emissão.',
        cannotMoveToTheSameLocation: 'Não é possível mover para o mesmo local.',
        locationRequired: 'Localização é obrigatória.',
        putQuantity: 'Coloque a quantidade para separar.',
        valueGreaterThanQuantity: 'O valor é maior que a quantidade total.',
        wholeCannotBeDistinguished: 'O todo não pode ser distinguido.',
        yearMustBeGreaterThan: 'O ano deve ser maior que ',
        passwordConfirmationNotMatch: 'As senhas não correspondem',
        mustBeInteger: 'O valor deve ser um número inteiro',
        valueTooLong: 'Valor muito longo',
        wrongPhoneNumber: 'Número de telefone incorreto',
        phoneNumberAlreadyTaken: 'Número de telefone já utilizado',
        emailAlreadyTaken: 'Email já utilizado',
        notFound: 'Não encontrado',
        sessionExpired: 'Sessão expirada',
        tenantNotFound:
            'A configuração indicada não existe. Verifique o endereço do site e tente novamente.',
        selectEquipmentTypeToConfigureParameters:
            'Selecione o tipo de equipamento para configurar os parâmetros.',
        noParametersToConfigure:
            'O tipo de equipamento selecionado não tem parâmetros para configurar.',
        terminalAlreadyRegistered: 'Terminal já registrado.',
        invalidPin: '@:common.PIN_CODE_LABEL inválido',
        eventCollision:
            'O horário do evento que você deseja adicionar se sobrepõe ao horário de outro evento.',
        mustStartWithSlash: 'Deve começar com /',
        mustBeBetween: 'O valor deve estar entre:',
        minLength: 'Comprimento mínimo: ',
        maxLength: 'Comprimento máximo: ',
        invalidInputForCodeType:
            'Entrada inválida para o tipo de código selecionado',
        invalidCodeOrNoPermission:
            'O @:common.PIN_CODE_LABEL é inválido ou você não tem permissões suficientes para realizar esta ação.',
        endDateLowerThanStart:
            'A data final não pode ser inferior à data inicial',
        iconAlreadyTaken: 'O ícone já foi escolhido',
        notEnoughQuantityInStorage: 'Quantidade insuficiente no estoque.',
        valueTooLow: 'Valor muito baixo',
        valueTooHigh: 'Valor muito alto',
        maxAvailableQuantity: 'Número máximo',
        someFilesRejectedDueToSize:
            'Alguns ficheiros foram rejeitados devido ao seu tamanho. Tamanho máximo de ficheiro único:',
        cameraDeviceNotFound: 'Dispositivo de câmara não encontrado',
        cameraPermissionDenied:
            'O acesso à câmara do dispositivo foi bloqueado nas definições do local, reiniciar as definições do local e permitir que a câmara seja utilizada.',
        passwordResetLinkExpired: 'O link de redefinição de senha expirou',
        noInternetConnection: 'Sem conexão com a internet',
        minimum: 'Mínimo',
        mustBeGreaterThan: 'Deve ser maior que',
        noEventTypesDefined: 'Nenhum tipo de evento definido',
        regexPatternDelimiterError:
            'O separador deve ser omitido com o carácter \\ npor exemplo: \\/',
        emailNotMatchPatternDefiniedInTenant:
            'O email não corresponde ao padrão definido no inquilino',
        max: 'Valor máximo',
        min: 'Valor mínimo',
        allLanguagesRequired: 'Preencha o valor em todos os idiomas',
        fileTooBigToOpenWillBeDownloaded:
            'O arquivo é muito grande para abrir, o arquivo será baixado para o seu dispositivo.',
        surveyNotFound: 'Pesquisa não encontrada',
        actionNotEnabled: 'O código está inativo',
        loginRequired: 'Login necessário',
        noPermissionForSelectedFolder:
            'Você não tem permissão para visualizar o conteúdo deste diretório',
        noFoldersAssignedForTerminal:
            'Não há diretórios atribuídos com instruções',
        noChecklistsAssignedToControl:
            'O controle selecionado não possui uma lista de verificação atribuída a ele',
        maximum: 'Máximo',
        versionErrorMessage:
            'Suas alterações recentes não foram salvas porque outra pessoa fez alterações ao mesmo tempo. Atualize a página e tente novamente.',
        duplicateError: 'Este valor já existe',
        fillAllRequiredParameters: 'Preencha todos os parâmetros necessários.',
        wrongValue: 'Valor inválido',
        noParametersDefined: 'Nenhum parâmetro definido',
        noEnoughParts: 'Itens insuficientes',
        pinNotBelongsTo: 'Este @:common.PIN_CODE_LABEL não pertence ao',
        tagNotBelongsTo: 'Este cartão/etiqueta não pertence à',
        noDictionaryEntries: 'O dicionário selecionado não contém entradas',
        noConnectionWithServer: 'Sem ligação com o servidor',
    },
    navDrawer: {
        dashboard: 'Painel de Controle',
        issues: 'Questões',
        timetable: 'Agenda',
        machines: 'Máquinas',
        tools: 'Ferramentas',
        parts: 'Peças',
        storage: 'Armazém',
        staff: 'Funcionários',
        configuration: 'Configuração',
        notes: 'Notas',
        notifications: 'Notificações',
        profile: 'Perfil',
        supervision: 'Supervisão',
    },
    barcodeScanner: {
        barCode: 'Código de barras',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Categoria',
        selectCategory: 'Selecione a categoria',
        noSubcategories: 'Não há subcategorias',
    },
    companies: {
        addCompany: 'Adicionar empresa',
        editCompany: 'Editar empresa',
        fullName: 'Nome completo',
        shortName: 'Nome curto',
        group: 'Grupo',
        country: 'País',
        city: 'Cidade',
        street: 'Rua',
        postCode: 'CEP',
        phone: 'Telefone',
        email: 'Email',
        website: 'Website',
        NIP: 'NIP',
        REGON: 'Registro Nacional de Empresas',
        active: 'Ativo',
        nameRequired: 'Nome é obrigatório',
        shortNameRequired: 'Nome curto é obrigatório',
    },
    issues: {
        tookAt: 'Tirada às',
        changeHistory: 'Histórico de alterações',
        load: 'Carregar',
        date: 'Data',
        createdBy: 'Criado por',
        action: 'Ação',
        data: 'Dados',
        created: 'Criado',
        updated: 'Atualizado',
        deleted: 'Excluído',
        restored: 'Restaurado',
        issues: 'Questões',
        issue: 'Questão',
        by: 'por',
        name: 'Nome',
        applicantsDescription: 'Descrição do solicitante',
        accidentDescription: 'Descrição do acidente',
        jobDescription: 'Descrição do trabalho',
        jobSummary: 'Resumo do trabalho',
        category: 'Categoria',
        status: 'Status',
        noFlow: '[Sem fluxo]',
        equipment: 'Equipamento',
        criticality: 'Criticidade',
        hour: 'Hora',
        formalConfirmation: 'Confirmação formal',
        formalConfirmationShortcut: 'CF',
        formalConfirmationRequired: 'CF necessária',
        parameter: 'Parâmetro',
        value: 'Valor',
        addIssue: 'Adicionar questão',
        expectedCompletionDate: 'Data prevista de conclusão',
        issueDescription: 'Descrição da questão',
        partsUsage: 'Uso de peças',
        assignedEmployees: 'Funcionários atribuídos',
        requiredHelp: 'Ajuda necessária',
        returnParts: 'Retornar peças',
        confirmUnassignEmployeeWhoTakenIssue:
            'Este funcionário pegou a questão selecionada. Você deseja removê-lo?',
        responsibleUser: 'Usuário responsável',
        responsibleRules: 'Usuários responsáveis - regras',
        received_decision_at:
            'Data do conhecimento da decisão pelo notificador',
        groupRules: 'Grupos - regras',
        watchersRules: 'Vigilantes - regras',
    },
    machines: {
        machines: 'Máquinas',
        xIssuesNotPlanned: ' (não planejado)',
        noIssues: 'Sem questões',
    },
    maintenance: {
        maintenance: 'Manutenção',
    },
    notes: {
        notes: 'Notas',
        note: 'Nota',
        title: 'Título',
        archived: 'Arquivado',
    },
    parts: {
        parts: 'Peças',
        warehouseOutgoings: 'Saídas do armazém',
        fittingEquipmentTypes: 'Tipos de equipamentos adequados',
        goToEquipmentType: 'Ir para tipo de equipamento',
        selectPart: 'Selecionar peça',
        usageReason: 'Razão',
        usageOtherReason: 'Outro',
        replacements: 'Substituições',
        partEntry: 'Entrada de peças',
        goToPart: 'Ir para peça',
    },
    costCenter: {
        costCenter: 'Centro de custos',
    },
    equipmentTypes: {
        equipment: 'Equipamento',
        newEquipmentType: 'Novo tipo de equipamento',
        fittingParts: 'Peças adequadas',
        goToParts: 'Ir para peças',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'Definições de máquinas e ferramentas',
    },
    flows: {
        flow: 'Fluxo',
        states: 'Estados',
        transitions: 'Transições',
        state: 'Estado',
        default: 'Padrão',
        warning: 'Aviso',
        problem: 'Problema',
        fixing: 'Correção',
        finished: 'Finalizado',
        access: {
            create: 'Criação',
            read: 'Leitura',
            update: 'Modificação',
            delete: 'Remoção',
            read_unassigned: 'Ler não atribuído',
            assign: 'Atribuição',
            unassign: 'Excluir uma tarefa',
            close: 'Fechando',
        },
        instructions: {
            default: 'Padrão',
            fixing: 'Consideração',
            finished: 'Finalizado',
            creation: 'Criação',
            verification: 'Verificação',
            acceptation: 'Aceitação',
            publication: 'Publicação',
        },
        accessLabels: {
            terminal: 'O terminal que reportou o problema',
            creator: 'O utilizador que relatou o problema',
            responsible: 'O utilizador responsável pelo problema',
            group: 'O grupo de utilizadores responsável pelo problema',
            anchor: 'O terminal no qual o relatório está fixado',
            watchers: 'Utilizadores observando o problema',
        },
        sync_returned_rejected: 'Sincronização - rejeitada',
        sync_returned_finished: 'Sincronização - concluída',
        current: 'Atual',
    },
    parameters: {
        categoryParameter: 'Parâmetro da categoria',
        equipmentTypeParameter: 'Parâmetro do tipo de equipamento',
    },
    staff: {
        staff: 'Funcionários',
        editUserAccess: 'Editar acesso de usuário',
        permissionsUpdated: 'Permissões atualizadas',
        passwordUpdated: 'Senha atualizada',
        codeUpdated: '@:common.PIN_CODE_LABEL atualizado',
        changePasswordAndCode: 'Alterar senha e @:common.PIN_CODE_LABEL',
        goToLicenses: 'Ir para licenças',
        editAccess: 'Editar acesso',
        editUser: 'Editar usuário',
        addUser: 'Adicionar novo usuário',
        nameAndLastname: 'Nome e sobrenome',
        accountStatus: 'Status da conta',
        phoneNumber: 'Número de telefone',
        department: 'Departamento',
        passwordChange: 'Alteração de senha',
        codeChange: 'Alteração de @:common.PIN_CODE_LABEL',
        newPassword: 'Nova senha',
        confirmNewPassword: 'Confirmar nova senha',
        changePassword: 'Alterar senha',
        newCode: 'Novo @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Confirmar novo @:common.PIN_CODE_LABEL',
        changeCode: 'Alterar @:common.PIN_CODE_LABEL',
        addEntry: 'Adicionar entrada',
        editEntry: 'Editar entrada',
        editLicense: 'Editar licença',
        addLicense: 'Adicionar licença',
        licenseNumber: 'Número da licença',
        prefferedLocale: 'Idioma preferido',
        oldPassword: 'Senha antiga',
        newPasswordConfirmation: 'Confirmar nova senha',
        reading: 'Exibindo',
        creating: 'Criando',
        updating: 'Atualizando',
        deleting: 'Excluindo',
        importScheduleFile: 'Importar um cronograma de um arquivo',
        importSchedule: {
            errors: {
                user_not_found: 'Usuário não encontrado [{user}]',
                user_found_many: '{count} usuários encontrados [{user}]',
                event_collision: 'Colisão de eventos',
                hour_cell_parse: 'Erro de análise de célula',
            },
        },
        oldCode: '@:common.PIN_CODE_LABEL antigo',
        newCodeConfirmation: 'Confirmação do novo @:common.PIN_CODE_LABEL',
        setAccessBasedOnProfile: 'Conceda acesso com base no perfil',
        selectAccessMergeType: 'Escolha como combinar permissões',
        noAccessesInProfileMessage:
            'O perfil selecionado não tem permissões. Você deseja substituir mesmo assim?',
    },
    breadCrumb: {
        configuration: 'Configuração',
        locations: 'Locais',
        home: 'Início',
        machines: 'Máquinas',
        tools: 'Ferramentas',
        issues: 'Questões',
        notes: 'Notas',
        maintenance: 'Manutenção',
        parts: 'Peças',
        warehouse: 'Armazém',
        dictionary: 'Dicionário',
        categories: 'Categorias',
        parameters: 'Parâmetros',
        costCenter: 'Centro de custo',
        flows: 'Fluxos',
        states: 'Estados',
        transitions: 'Transições',
        shiftSchemas: 'Esquemas de turnos',
        shifts: 'Turnos',
        definitionsOfMachinesAndToolsTypes:
            'Definições de tipos de máquinas e ferramentas',
        add: 'Adicionar',
        edit: 'Editar',
        staff: 'Equipe',
        licenses: 'Licenças',
        entries: 'Entradas',
        companiesDatabase: 'Banco de dados de empresas',
        companyManagement: 'Gerenciamento de empresas',
        companyData: 'Dados da empresa',
        supportedLanguages: 'Línguas suportadas',
        en: 'Inglês',
        pl: 'Polonês',
        es: 'Espanhol',
        de: 'Alemão',
        it: 'Italiano',
        fr: 'Francês',
        ru: 'Russo',
        uk: 'Ucraniano',
        pt: 'Português',
        defaultLanguage: 'Língua padrão',
        notifications: 'Notificações',
        notificationsManagement: 'Gerenciamento de notificações',
        myProfile: 'Meu perfil',
    },
    attributeTypes: {
        text: 'Texto',
        note: 'Nota',
        integer: 'Inteiro',
        decimal: 'Decimal',
        date: 'Data',
        time: 'Hora',
        dateTime: 'Data e hora',
        color: 'Cor',
        icon: 'Ícone',
        boolean: 'Sim/Não',
    },
    costCalculator: {
        costCalculator: 'Calculadora de custo',
        waitTime: 'Tempo de espera',
        serviceTime: 'Tempo de serviço',
        suspendTime: 'Tempo de suspensão',
        totalTime: 'Tempo total',
        estimatedCost: 'Custo estimado',
        calculate: 'Calcular',
        withoutParts: 'Sem peças',
        timeOfReportingIssue: 'Hora de relatório de problema',
        timeOfServiceStart: 'Hora de início do serviço',
        timeOfReportingEnd: 'Hora de término do serviço',
        days: 'Dias',
        hours: 'Horas',
        minutes: 'Minutos',
        enterNewTime: 'Inserir novo tempo',
        fromIssueReportToServiceStart:
            'Tempo do relatório de problema até o início do serviço',
        fromServiceStartToServiceEnd:
            'Tempo do início do serviço até o término do serviço',
        workTime: 'Tempo de trabalho',
        includeAwaitingTime: 'Incluir tempo de espera',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'Meus problemas',
                assignedToMe: 'Atribuído a mim',
                assignedToGroup: 'Atribuído ao meu grupo',
                tookByMe: 'Pego por mim',
                takeIssueBtn: 'Pegar',
                arriveIssueBtn: 'Cheguei',
                finishIssueBtn: 'Finalizar',
                history: 'Histórico',
                myResponsible: 'Eu estou responsável por',
                unassignIssue: 'Cancelar tomada',
            },
        },
    },
    synchronization: {
        enabled: 'Habilitado',
        hasHeaders: 'Tem cabeçalhos',
        leaveFile: 'Deixar arquivo',
        host: 'Anfitrião',
        port: 'Porta',
        protocol: 'Protocolo',
        username: 'Nome de usuário',
        password: 'Senha',
        root: 'Raiz',
        encoding: 'Codificação',
        separator: 'Separador',
        externalSystemId: 'ID do sistema externo',
        cronExpression: 'Expressão Cron',
        appendFullStock: 'Anexar estoque completo',
        includeHeaders: 'Incluir cabeçalhos',
        withTrashed: 'Com dados excluídos',
        filePattern: 'Padrão de arquivo',
        categoriesRootCategoryId: 'Categoria raiz para categorias importadas',
        systemKeyname: 'Identificador do sistema',
        address: 'Endereço',
        token: 'Token',
    },
    processes: {
        created_at: 'Criado em',
        started_at: 'Iniciado em',
        updated_at: 'Atualizado em',
        finished_at: 'Finalizado em',
        attempts: 'Tentativas',
        queue: 'Fila',
        job_id: 'ID do trabalho',
        job_uuid: 'UUID do trabalho',
        progress_max: 'Progresso máximo',
        progress_now: 'Progresso atual',
        outputMessage: 'Mensagem de saída',
        status: {
            queued: 'em fila',
            executing: 'executando',
            finished: 'finalizado',
            failed: 'falhou',
            retrying: 'tentando novamente',
            undefined: 'indefinido',
        },
    },
    weekDays: {
        mo: 'Segunda-feira',
        tu: 'Terça-feira',
        we: 'Quarta-feira',
        th: 'Quinta-feira',
        fr: 'Sexta-feira',
        sa: 'Sábado',
        su: 'Domingo',
    },
    reports: {
        reports: 'Relatórios',
        dateFrom: 'Data de início',
        dateTo: 'Data de término',
        types: {
            operation_sheet: 'Planilha operacional',
            issue_summary: 'Resumo da questão',
            grouped_summary: 'Relatório resumido',
            storage_operations: 'Operações de armazém',
            part_quantity_export: 'Quantidade de peças',
        },
        reportFor: 'Relatório para',
        includeQuantityLeft0: 'Incluir peças com quantidade nula',
        dayMinusN: 'Dia menos n',
        currentWeek: 'Semana atual',
        lastWeek: 'Semana passada',
        currentMonth: 'Mês atual',
        lastMonth: 'Mês passado',
        currentYear: 'Ano atual',
        lastYear: 'Ano passado',
        nDays: 'Número de dias',
        todayMinusN: 'Hoje menos n',
    },
    logs: {
        comment: 'Comente',
        issues: {
            escalation: 'Escalation ({minutes} min) - notificação enviada',
        },
        issueEmployee: {
            assignedAt: 'Funcionário atribuído:',
            receivedAt: 'Funcionário recebeu:',
            tookAt: 'Funcionário assumiu:',
            finishedAt: 'Funcionário concluiu:',
            deletedAt: 'Funcionário excluído da denúncia',
        },
    },
    units: {
        no: 'Pedaço',
        st: 'Definir',
        pr: 'Par',
    },
    rrule: {
        frequency: 'Frequência',
        dtstart: 'Data de início',
        tzid: 'Fuso horário',
        until: 'Até',
        count: 'Contar',
        interval: 'Intervalo',
        wkst: 'Dia de início',
        byweekday: 'No dia da semana',
        bymonth: 'Por mês',
        yearly: 'Anual',
        monthly: 'Por mês',
        weekly: 'Semanalmente',
        daily: 'Diário',
        hourly: 'De hora em hora',
        minutely: 'Minuciosamente',
        secondly: 'em segundo lugar',
        infinitePeriodicityMessage:
            'A periodicidade é infinita, devido ao desempenho, apenas 500 linhas são exibidas',
    },
    months: {
        jan: 'Janeiro',
        feb: 'Fevereiro',
        mar: 'Marchar',
        apr: 'Abril',
        may: 'Poderia',
        jun: 'Junho',
        jul: 'Julho',
        aug: 'Agosto',
        sep: 'Setembro',
        oct: 'Outubro',
        nov: 'Novembro',
        dec: 'Dezembro',
    },
    charts: {
        charts: 'Gráficos',
        count: 'Número de problemas',
        total: 'Tempo total de problemas (do relatório ao fim)',
        serw: 'Tempo total de reparo (Ttotal - Twait)',
        mttr1: 'Tempo médio do relatório ao fim',
        mttr2: 'Tempo médio da tomada ao fim',
        mtbf: 'Tempo médio de atividade entre problemas',
        mttf: 'Tempo médio para falha',
        type: 'Tipo de Gráfico',
        pie: 'Gráfico de pizza',
        bar: 'Um gráfico de barras',
        query: 'O âmbito dos dados',
        groupBy: 'Agrupar por',
        sum: 'Soma',
        avg: 'Significar',
        min: 'Mínimo',
        max: 'Máximo',
        eq: 'É igual a',
        neq: 'Não é igual',
        gt: 'Mais do que',
        lt: 'Menor que',
        gte: 'Mais ou igual',
        lte: 'Menor ou igual',
        in: 'Contém',
        nin: 'Não contenha',
        btw: 'Entre',
        chartOptions: 'Opções de gráfico',
        line: 'Gráfico de linha',
        mixed: 'Misturado',
        chartDisplayOptions: 'Opções de visualização',
        fillGaps: 'Preencha nos espaços',
    },
    regexBuilder: {
        regexValue: 'Valor',
        global: 'Global',
        multiline: 'Multilinha',
        insensitive: 'Insensível a maiúsculas/minúsculas',
        extended: 'Estendido',
        singleline: 'Linha única',
        unicode: 'Unicode',
        Ungreedy: 'Quantificadores preguiçosos',
        Anchored: 'Ancorado no início do padrão ou no final do último ajuste',
        Jchanged: 'Permitir nomes duplicados de subpadrões',
        DollarEndOnly: '$ corresponde apenas ao final do padrão',
        testValue: 'Valor de teste',
    },
    tpm: {
        groupableHeadersPlaceholder:
            'Arraste o cabeçalho da tabela para agrupar os dados.',
        steps: 'Etapas',
        step: 'Etapa',
        content: 'Conteúdo',
        history: 'História',
        standardObstacle: 'Obstáculo padrão',
        customObstacle: 'Obstáculo personalizado',
        bhp: 'Segurança e Saúde no Trabalho',
        returnToMainPage: 'Voltar para a página principal',
        bhpMessage:
            'Se você identificar qualquer risco à segurança, por favor, informe imediatamente ao seu supervisor direto e utilize o formulário de relato localizado abaixo do mural de avisos.',
        ok: 'Ok',
        send: 'Enviar',
        description: 'Descrição',
        title: 'Título',
        realization: 'Realização',
        answer: 'Resposta',
        pendingSurveys: 'Pesquisas pendentes',
        surveys: 'Pesquisas',
        systemSurvey: 'Pesquisa de sistema',
        addPage: 'Adicionar página',
        createSurvey: 'Criar pesquisa',
        addSection: 'Adicionar seção',
        addQuestion: 'Adicionar pergunta',
        questionContent: 'Conteúdo da pergunta',
        answerOption: 'Opção de resposta',
        addQuestionAnswer: 'Adicionar opção de resposta',
        surveyName: 'Nome da pesquisa',
        sectionName: 'Nome da seção',
        noSurveyAnswers: 'Sem respostas',
        removeSection: 'Remover seção',
        published: 'Publicado',
        unpublished: 'Não publicado',
        pendingPublication: 'Alterações pendentes de publicação',
        removeQuestion: 'Remover pergunta',
        removeAnswer: 'Remover opção de resposta',
        publish: 'Publicar',
        unpublish: 'Despublicar',
        previewResults: 'Visualizar resultados',
        supervisior: 'Supervisor',
        surveyResults: 'Resultados da pesquisa',
        surveysEvents: 'Configuração de eventos - pesquisas',
        operatedBy: 'Operado por',
        confirmReadInformations: 'Confirmo que li as informações',
        noticeContent: 'Conteúdo do aviso',
        notice: 'Aviso',
        surveyEventName: 'Nome do evento',
        survey: 'Pesquisa',
        newIssue: 'Nova ocorrência',
        surveyEventConfigurationAlreadyExists:
            'Essa configuração de evento já existe',
        lastComment: 'Último comentário da pessoa responsável',
        surveyNotConfigured: 'Pesquisa não configurada',
        fillSurvey: 'Preencher pesquisa',
        singleSurveyEntries: 'resultados únicos',
        groupedSurveyEntries: 'Resultados agrupados',
        publicationId: 'ID da publicação',
        publicationDate: 'Data da publicação',
        versionId: 'ID da versão',
        answersCount: 'Número de respostas',
        answers: 'Respostas',
        withoutSupervisor: 'Sem supervisor',
        defaultSupervisor: 'Supervisor padrão',
        flowStateFlags: 'sinalizadores de status',
        qrActionsSettings: 'Configurações de ação do código QR',
        loginRequired: 'Requer login',
        questionContext: 'O contexto da pergunta',
        currentSurveyVersionPreview: 'Visualização da versão atual da pesquisa',
        surveyDescription: 'Descrição da pesquisa',
        sectionDescription: 'Descrição da seção',
        instructions: 'Instruções',
        instruction: 'Instrução',
        instructionRevisions: 'Revisões',
        instructionRevision: 'Revisão',
        checklists: 'Lista de verificação',
        checklist: 'Lista de controlo',
        checklistItem: 'Item da lista',
        manageChecklistFields: 'Gerir itens da lista',
        checklistItemDisplayOptions: 'Itens exibidos',
        revisionAutoRejectedInfo: '[Sistema] Rejeitado - criada nova revisão',
    },
    accessMergeValues: {
        sum: 'Soma',
        product: 'Produtos',
        overwrite: 'Substituir',
    },
    aggregates: {
        count: 'Soma',
        min: 'Mínimo',
        max: 'Máximo',
    },
    checklists: {
        itemBasic: 'Elemento básico',
        itemSeparator: 'Separador',
        itemSection: 'Seção',
        publish: 'Publicar',
        previewCurrentVersion: 'Pré-visualização da versão atual',
    },
    cluer: {
        groupableHeadersPlaceholder:
            'Arraste o cabeçalho da tabela para agrupar os dados.',
        history: 'História',
        standardObstacle: 'Obstáculo padrão',
        customObstacle: 'Obstáculo personalizado',
        bhp: 'Segurança e Saúde no Trabalho',
        returnToMainPage: 'Voltar para a página principal',
        bhpMessage:
            'Se você identificar qualquer risco à segurança, por favor, informe imediatamente ao seu supervisor direto e utilize o formulário de relato localizado abaixo do mural de avisos.',
        ok: 'Ok',
        send: 'Enviar',
        description: 'Descrição',
        title: 'Título',
        realization: 'Realização',
        answer: 'Resposta',
        pendingSurveys: 'Pesquisas pendentes',
        surveys: 'Pesquisas',
        systemSurvey: 'Pesquisa de sistema',
        addPage: 'Adicionar página',
        createSurvey: 'Criar pesquisa',
        addSection: 'Adicionar seção',
        addQuestion: 'Adicionar pergunta',
        questionContent: 'Conteúdo da pergunta',
        answerOption: 'Opção de resposta',
        addQuestionAnswer: 'Adicionar opção de resposta',
        surveyName: 'Nome da pesquisa',
        sectionName: 'Nome da seção',
        noSurveyAnswers: 'Sem respostas',
        removeSection: 'Remover seção',
        published: 'Publicado',
        unpublished: 'Não publicado',
        pendingPublication: 'Alterações pendentes de publicação',
        removeQuestion: 'Remover pergunta',
        removeAnswer: 'Remover opção de resposta',
        publish: 'Publicar',
        unpublish: 'Despublicar',
        previewResults: 'Visualizar resultados',
        supervisior: 'Supervisor',
        surveyResults: 'Resultados da pesquisa',
        surveysEvents: 'Configuração de eventos - pesquisas',
        operatedBy: 'Operado por',
        confirmReadInformations: 'Confirmo que li as informações',
        noticeContent: 'Conteúdo do aviso',
        notice: 'Aviso',
        surveyEventName: 'Nome do evento',
        survey: 'Pesquisa',
        newIssue: 'Nova ocorrência',
        surveyEventConfigurationAlreadyExists:
            'Essa configuração de evento já existe',
        lastComment: 'Último comentário da pessoa responsável',
        surveyNotConfigured: 'Pesquisa não configurada',
        fillSurvey: 'Preencher pesquisa',
        singleSurveyEntries: 'resultados únicos',
        groupedSurveyEntries: 'Resultados agrupados',
        publicationId: 'ID da publicação',
        publicationDate: 'Data da publicação',
        versionId: 'ID da versão',
        answersCount: 'Número de respostas',
        answers: 'Respostas',
        withoutSupervisor: 'Sem supervisor',
        defaultSupervisor: 'Supervisor padrão',
        flowStateFlags: 'sinalizadores de status',
        qrActionsSettings: 'Configurações de ação do código QR',
        loginRequired: 'Requer login',
        questionContext: 'O contexto da pergunta',
        currentSurveyVersionPreview: 'Visualização da versão atual da pesquisa',
        surveyDescription: 'Descrição da pesquisa',
        sectionDescription: 'Descrição da seção',
    },
    orders: {
        order: 'Ordem',
        releaseDate: 'Data de conclusão',
        orderDate: 'data do pedido',
    },
    production: {
        good: 'Número de bons',
        bad: 'Número de ruins',
        productionRecords: 'Registro de produção',
    },
};
