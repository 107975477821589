export const nl = {
    common: {
        ok: 'Ok',
        authorizationRequiredDescriptionTerminal:
            'Gebruik uw @:common.PIN_CODE_LABEL om de actie te autoriseren.',
        countersValues: 'Tellerwaarden',
        countableId: 'ID van het telbare veld',
        defaultValuesForCategory: 'Standaardwaarden voor categorie',
        check_user: 'Controleer gebruiker',
        check_terminal: 'Controleer terminal',
        bannedEmployees: 'Medewerkers met toegangsverbod',
        banEmployee: 'Verbied toegang',
        banned: 'Met toegangsverbod',
        employeeBans: 'Toegangsverboden',
        commentAboutEmployee: 'Betrekking op medewerker',
        comments: 'Opmerkingen',
        visitLog: 'Bezoekgeschiedenis',
        categoryStructure: 'Categorie structuur',
        appVersionMismatch: 'Onjuiste appversie',
        appVersionMismatchMessage: 'Ververs de app om de versie bij te werken.',
        PIN_CODE_LABEL: 'Pincode',
        comment: 'Opmerking',
        year: 'Jaar',
        configuration: 'Configuratie',
        cancel: 'Annuleren',
        search: 'Zoeken',
        remove: 'Verwijderen',
        confirmationDialogTitle:
            'Weet je zeker dat je dit item wilt verwijderen?',
        selectDate: 'Selecteer datum',
        select: 'Selecteren',
        notFound: 'Niet gevonden',
        equipmentPickerPlaceholder: 'Uitrustingsobject',
        searchPlaceholder: 'Zoeken',
        equipmentTypePickerPlaceholder: 'Uitrustingstype',
        equipmentTypePickerTitle: 'Selecteer uitrustingstype',
        emptyTableMessage: 'Geen gegevens',
        flowPikcerTitle: 'Selecteer stroom',
        selectIcon: 'Selecteer pictogram',
        locationPickerTitle: 'Selecteer locatie',
        locationPlaceholder: 'Locatie',
        noSublocations: 'Geen sublocaties',
        locationsTitle: 'Locaties',
        edit: 'Bewerken',
        save: 'Opslaan',
        added: 'Toegevoegd',
        updated: 'Bijgewerkt',
        add: 'Toevoegen',
        removed: 'Verwijderd',
        reload: 'Herladen',
        confirm: 'Bevestigen',
        rollBack: 'Terugdraaien',
        images: 'Afbeeldingen',
        documents: 'Documenten',
        download: 'Downloaden',
        saved: 'Opgeslagen',
        date: 'Datum',
        hour: 'Uur',
        preview: 'Voorbeeld',
        data: 'Gegevens',
        attributes: 'Eigenschappen',
        checkList: 'Controlelijst',
        name: 'Naam',
        type: 'Type',
        status: 'Status',
        designation: 'Aanduiding',
        symbol: 'Symbool',
        serialNumber: 'Serienummer',
        registryNumber: 'Registratienummer',
        machineNumber: 'Machine nummer',
        udtNumber: 'UDT-nummer',
        manufacturedYear: 'Fabrieksjaar',
        manufacturer: 'Fabrikant',
        supplier: 'Leverancier',
        mainSupplier: 'Hoofdleverancier',
        attribute: 'Eigenschap',
        nameRequired: 'Naam is verplicht',
        yearGreaterThan: 'Jaar moet groter zijn dan',
        location: 'Locatie',
        fieldRequired: 'Dit veld is verplicht',
        description: 'Beschrijving',
        minimumQuantity: 'Minimum hoeveelheid',
        optimalQuantity: 'Optimale hoeveelheid',
        priority: 'Prioriteit',
        formatYYYY: 'Formaat YYYY',
        storageQuantity: 'Voorraad hoeveelheid',
        quantity: 'Hoeveelheid',
        quantityGreaterThan: 'Hoeveelheid moet groter zijn dan',
        machine: 'Machine',
        tool: 'Gereedschap',
        order: 'Bestelling',
        required: 'Vereist',
        dateFormat: 'Datum formaat',
        selectDictionary: 'Selecteer woordenboek',
        return: 'Terug',
        parameters: 'Parameters',
        editCategory: 'Categorie bewerken',
        addCategory: 'Nieuwe categorie toevoegen',
        changeColor: 'Kleur veranderen',
        noColor: 'Geen kleur',
        shift: 'Shift',
        start: 'Start',
        end: 'Einde',
        shiftSchema: 'Schift schema',
        shifts: 'Shifts',
        dictionary: 'Woordenboek',
        subEntries: 'Subvermeldingen',
        key: 'Sleutel',
        value: 'Waarde',
        moveUp: 'Omhoog bewegen',
        moveDown: 'Omlaag bewegen',
        addLocation: 'Nieuwe locatie toevoegen',
        editLocation: 'Locatie bewerken',
        companiesDatabase: 'Bedrijven database',
        flows: 'Stromen',
        read: 'Lezen',
        write: 'Schrijven',
        noImage: 'Geen afbeelding',
        email: 'E-mail',
        function: 'Functie',
        active: 'Actief',
        notActive: 'Niet geactiveerd',
        disabled: 'Uitgeschakeld',
        entryNumber: 'Invoernummer',
        issuedDate: 'Uitgegeven datum',
        expiresDate: 'Verloopt datum',
        move: 'Verplaatsen',
        separateForm: 'Scheiden van',
        separate: 'Scheiden',
        warehouse: 'Magazijn',
        actions: 'Acties',
        putInCurrentLocation: 'Plaats in huidige locatie',
        selectLocationFormRightMenu: 'Selecteer locatie uit het rechter menu',
        showUnassignedItems: 'Toon niet-toegewezen items',
        separated: 'Gescheiden',
        moved: 'Verplaatst',
        categories: 'Categorieën',
        welcome: 'Welkom',
        loginToSignaloTpm: 'Log in bij Signalo TPM',
        login: 'Inloggen',
        loginByEmailAndPassword: 'Inloggen met e-mail en wachtwoord',
        loginByCode: 'Inloggen met @:common.PIN_CODE_LABEL',
        accessCode: 'Toegangscode',
        enterEmail: 'Voer e-mail in',
        enterPassword: 'Voer wachtwoord in',
        enterCode: 'Voer @:common.PIN_CODE_LABEL in',
        userNotFound: 'Gebruiker niet gevonden',
        userNotActivated: 'Gebruiker niet geactiveerd',
        userDisabled: 'Gebruiker uitgeschakeld',
        wrongPassword: 'Verkeerd wachtwoord',
        locations: 'Locaties',
        pageNotFound: 'Pagina niet gevonden',
        group: 'Groep',
        yes: 'Ja',
        no: 'Nee',
        hourlyCost: 'Uurkosten',
        price: 'Prijs',
        newEntry: 'Nieuwe invoer',
        predictedFinishTime: 'Voorspelde eindtijd',
        archive: 'Archief',
        costs: 'Kosten',
        companyManagement: 'Bedrijfsbeheer',
        companyData: 'Bedrijfsgegevens',
        supportedLanguages: 'Ondersteunde talen',
        en: 'Engels',
        pl: 'Pools',
        es: 'Spaans',
        de: 'Duits',
        it: 'Italiaans',
        fr: 'Frans',
        ru: 'Russisch',
        uk: 'Oekraïens',
        pt: 'Portugees',
        tr: 'Turks',
        nl: 'Nederlands',
        sr: 'Servisch',
        ar: 'Arabisch',
        he: 'Hebreeuws',
        zh: 'Chinees',
        defaultLanguage: 'Standaard taal',
        companyLogo: 'Bedrijfslogo',
        notificationsManagement: 'Notificatiebeheer',
        category: 'Categorie',
        today: 'Vandaag',
        month: 'Maand',
        week: 'Week',
        day: 'Dag',
        close: 'Sluiten',
        show: 'Tonen',
        rollBackFormalConfirmation: 'Formele terugzetbevestiging',
        signUp: 'Registreren',
        nameSurname: 'Naam en achternaam',
        phone: 'Telefoonnummer',
        password: 'Wachtwoord',
        password_confirmation: 'Bevestig wachtwoord',
        unassignedItems: 'Niet toegewezen items',
        currency: 'Valuta',
        general: 'Algemeen',
        timezone: 'Tijdzone',
        users: 'Gebruikers',
        groups: 'Groepen',
        productionLine: 'Productielijn',
        part: 'Onderdeel',
        fittingParts: 'Passende onderdelen',
        workSchema: 'Werkrooster',
        workSchemas: 'Werkroosters',
        holiday: 'Vakantie',
        changePassword: 'Wijzig wachtwoord',
        showRest: 'Toon overige',
        pageNotPermitted: 'Pagina niet toegestaan',
        waitingForActivation: 'Wachtend op activatie',
        editGroup: 'Bewerk groep',
        addGroup: 'Voeg groep toe',
        reportProblem: 'Meld probleem',
        describeOccuredIssue: 'Beschrijf opgetreden probleem',
        markAllAsRead: 'Markeer alles als gelezen',
        unitType: 'Eenheidstype',
        unit: 'Eenheid',
        unitPrice: 'Eenheidsprijs',
        notConfigured: 'Niet geconfigureerd',
        rowsPerPage: 'Rijen per pagina',
        rowsOfPage: 'van',
        true: 'Waar',
        false: 'Onwaar',
        export: 'Exporteren',
        chooseFileFormat: 'Kies bestandsformaat',
        exported: 'Geëxporteerd',
        terminals: 'Terminals',
        terminal: 'Terminal',
        selectEquipment: 'Selecteer apparatuur',
        futureIssues: 'Toekomstige problemen',
        scheduledOn: 'Gepland op',
        planned: 'Gepland',
        notPlanned: 'Niet gepland',
        showOpenIssues: 'Toon open issues',
        pin: 'Pincode',
        assignedEquipment: 'Toegewezen uitrusting',
        terminalEquipment: 'Terminal uitrusting',
        detach: 'Loskoppelen',
        attach: 'Koppelen',
        dashboardConfig: 'Dashboard configuratie',
        addColumn: 'Kolom toevoegen',
        addRow: 'Rij toevoegen',
        removeRow: 'Rij verwijderen',
        registerTerminal: 'Registreer terminal',
        issueGeneratesCost: 'Downtime genereert kosten',
        selectColor: 'Kies kleur',
        belowMinimum: 'Beneden minimum',
        belowOptimum: 'Beneden optimaal',
        synchronization: 'Synchronisatie',
        synchronizationExport: 'Export van voorraad onderdelen',
        synchronizationImport: 'Import van voorraad onderdelen',
        mapping: 'Mapping',
        returnTo: 'Terug naar',
        jobStatusses: 'Processen',
        hidden: 'Verborgen',
        editEvent: 'Gebeurtenis bewerken',
        threeDays: '3 dagen',
        addEvent: 'Gebeurtenis toevoegen',
        plannedIssues: 'Geplande problemen',
        currentService: 'Huidige service',
        searchLocation: 'Locatie zoeken',
        all: 'Alles',
        unfinished: 'Onafgemaakt',
        numberOfIssues: 'Aantal problemen',
        unknown: 'Onbekend',
        partEntry: 'Onderdeel (invoer)',
        foundItems: 'Gevonden items',
        output: 'Output',
        copied: 'Gekopieerd',
        link: 'Link',
        authorizationRequired: 'Autorisatie vereist',
        authorizationRequiredDescriptionUser:
            'Helaas heeft u niet voldoende autorisatie. Vraag een geautoriseerde persoon om de actie te goedkeuren.',
        showAll: 'Toon alles',
        showClassicView: 'Toon klassieke weergave',
        noIssuesInCategory: 'Geen problemen in deze categorie.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Aantal problemen in deze categorie en subcategorieën.',
        numberOfIssuesInThisCategory: 'Aantal problemen in deze categorie.',
        minBarcodeLength: 'Minimum barcode lengte',
        currentPage: 'Huidige pagina',
        allPages: 'Alle pagina`s',
        selectFormat: 'Selecteer formaat',
        provided_currency: 'Aangeboden valuta',
        provided_quantity: 'Aangeboden hoeveelheid',
        provided_unit: 'Aangeboden eenheid',
        provided_unit_price: 'Aangeboden eenheidsprijs',
        unit_price: 'Eenheidsprijs',
        quantity_available: 'Beschikbare hoeveelheid',
        editBarcode: 'Barcode bewerken',
        crews: 'Teams',
        scheduleEventTypes: 'Planningsevent types',
        crew: 'Team',
        scheduleEvent: 'Planningsevent',
        available: 'Beschikbaar',
        'App\\Models\\Crew': 'Team',
        'App\\Models\\User': 'Gebruiker',
        'App\\Models\\Equipment': 'Uitrusting',
        Crew: 'Team',
        User: 'Gebruiker',
        Equipment: 'Uitrusting',
        scheduleEvents: 'Planningsevents',
        event: 'Event',
        externalSystems: 'Externe systemen',
        user: 'Gebruiker',
        availability: 'Beschikbaarheid',
        inheritColorFromParentIfCustomNotSet:
            'Erf kleur van ouder als aangepaste niet ingesteld is',
        pickFromAllParts: 'Kies uit alle onderdelen',
        takeTool: 'Gereedschap nemen',
        returnTool: 'Gereedschap retourneren',
        personWhoTake: 'Persoon die neemt',
        personWhoReturn: 'Persoon die retourneert',
        takenAt: 'Opgenomen op',
        takenBy: 'Opgenomen door',
        toolIssuedBy: 'Gereedschap uitgegeven door',
        commentTake: 'Opmerking bij nemen',
        commentReturn: 'Opmerking bij retourneren',
        change: 'Veranderen',
        onlyWithActiveRMIssues: 'Alleen bij actieve RM-verzoeken',
        externalSystem: 'Extern systeem',
        rejectIssue: 'Probleem afwijzen',
        withdrawIssueRejection: 'Intrekken afwijzing probleem',
        rejectionReason: 'Reden van afwijzing',
        rejected: 'Afgewezen',
        showCrewMembers: 'Toon teamleden',
        crewMembers: 'Teamleden',
        helpGroup: 'Service',
        partsTakenByUser: 'Onderdelen opgenomen door gebruiker',
        pickParts: 'Onderdelen uitgeven',
        selectPersonWhoTakes: 'Selecteer persoon die neemt',
        changePersonWhoTakes: 'Verander persoon die neemt',
        quantityToPick: 'Hoeveelheid om op te halen',
        pickLocation: 'Opname locatie',
        selectPartsToPick: 'Selecteer onderdelen om op te halen',
        partSelected: 'Geselecteerd onderdeel',
        scanCodeOr: 'Scan code of',
        returnPart: 'Onderdeel retourneren',
        myTakenParts: 'Onderdelen opgenomen door mij',
        takenFromLocation: 'Opgenomen van locatie',
        takenQuantity: 'Opgenomen hoeveelheid',
        providedUnit: 'Aangeboden eenheid',
        providedQuantity: 'Aangeboden hoeveelheid',
        selectFromStorage: 'Selecteer uit opslag',
        selectFromMyPickedParts: 'Selecteer uit mijn opgehaalde onderdelen',
        exportProcessInProgressMessage:
            'Het exportproces van gegevens is zojuist gestart, zodra het is voltooid, ziet u een bijbehorende melding met een link naar het bestand.',
        yourDocumentIsReady: 'Uw document is klaar',
        rotateScreen: 'Draai scherm',
        addRule: 'Regel toevoegen',
        editRule: 'Regel bewerken',
        summary: 'Samenvatting',
        onlyAvailableEmployees: 'Toon alleen beschikbare werknemers',
        calculated_unit_price: 'Omgerekende eenheidsprijs',
        calculated_quantity: 'Omgerekende hoeveelheid',
        next: 'Nächste',
        maintenanceMode: 'Onderhoudsmodus',
        applicationMayBeUnavailableForFewMinutes:
            'Toepassing kan enkele minuten niet beschikbaar zijn',
        appWillRefreshIn: 'De toepassing wordt vernieuwd in',
        landscape: 'Landschap',
        portrait: 'Portret',
        orientation: 'Oriëntatie',
        format: 'Formaat',
        service: 'Bedienden',
        leader: 'Voormannen',
        defaultView: 'Standaardweergave',
        view: 'Kijk',
        oneDay: '1 dag',
        object: 'Object',
        finished: 'Klaar',
        onlyNotFinished: 'Alleen niet af',
        generate: 'Genereren',
        error: 'Fout',
        cell: 'Cel',
        resolutionSummary: 'Einde samenvatting',
        rejected_at: 'Afwijzingsdatum',
        rejected_by: 'Afgewezen door',
        finishedByUser: 'Afgemaakt door',
        formalConfirmByUser: 'Formele bevestiging door',
        formalConfirmAt: 'Datum van formele bevestiging',
        valueDeleted: '[Waarde verwijderd]',
        hasNotPlannedIssue: 'Heeft geen geplande problemen',
        showInStorage: 'In opslag laten zien',
        changeCode: 'Wijzig de @:common.PIN_CODE_LABEL',
        staffProfiles: 'Werknemers profielen',
        profile: 'Profiel',
        user_bug: 'Applicatiefout',
        user_kaizen: 'Suggestie voor verandering',
        changeSuggestions: 'Suggesties wijzigen',
        showOnlyCurrentlyAvailable: 'Toon alleen momenteel beschikbaar',
        resetPassword: 'Herinner wachtwoord',
        forgotPassword: 'Wachtwoord vergeten',
        resetPasswordMailSent:
            'We hebben een link naar het opgegeven e-mailadres gestuurd om een ​​nieuw wachtwoord in te stellen',
        manyEventsThisTime: 'Veel evenementen in deze tijd',
        importScheduleCheckboxLabel:
            'Verwijder geplande gebeurtenissen in de geselecteerde maand voor werknemers die in het bestand staan ​​vermeld',
        inheritedEvent: 'Overgeërfde gebeurtenis',
        inheritedModifiedEvent: 'Gewijzigde overgeërfde gebeurtenis',
        equipmentId: 'Apparatuur ID',
        entryId: 'Invoer ID',
        partId: 'Onderdeel ID',
        suggestionsEmail:
            'E-mail waarnaar wijzigingssuggesties worden verzonden',
        meters: 'Tellers',
        scanCardToAuth:
            'Houd de kaart tegen de lezer of voer uw @:common.PIN_CODE_LABEL in om uw identiteit te bevestigen',
        scanCard: 'Scan de kaart',
        cart: 'Uitwisselingsbox',
        emptyCardMessage:
            'Begin met het scannen van producten of selecteer ze uit de lijst om ze aan het winkelmandje toe te voegen.',
        terminalMode: 'eindtype',
        askPermittedUserToConfirmPartsExchange:
            'Vraag de geautoriseerde persoon om de vrijgave van het onderdeel te bevestigen door de kaart te scannen of de code in te voeren.',
        partsIssued: 'Onderdelen vrijgegeven',
        areYouSureAboutCancel: 'Weet je zeker dat je wilt annuleren?',
        areYouSureAboutRemoveFromCart:
            'Weet je zeker dat je dit item uit je winkelwagen wilt verwijderen?',
        standard: 'Standaard',
        idNumber: 'Identificatienummer',
        takeParts: 'Onderdelen nemen',
        returnParts: 'Onderdelen terugbrengen',
        quantityToReturn: 'Hoeveelheid om terug te brengen',
        partsPendingApproval: 'Onderdelen in afwachting van goedkeuring',
        partsReturned: 'Onderdelen teruggebracht',
        partsAddedToPendingApproval:
            'Onderdelen toegevoegd aan wachtende goedkeuring',
        approvePartsReturn: 'Keur onderdelenretour goed',
        partReturnApproved: 'Onderdeel retour goedgekeurd',
        partUsage: 'Onderdeelgebruik',
        awaitsReturn: 'Wacht op terugkeer',
        entrySelection: 'Selectie invoeren',
        correctQuantity: 'Hoeveelheid corrigeren',
        quantityCorrection: 'Hoeveelheidcorrectie',
        entrySelectionAsc: 'Selectie invoeren (oplopend)',
        entrySelectionDesc: 'Selectie invoeren (aflopend)',
        metersSettings: 'Meterinstellingen',
        countableFields: 'Aantal velden',
        equipmentTypes: 'Apparatuursoorten',
        fieldName: 'Veldnaam',
        countableField: 'Aantal veld',
        partsStatusTaken: 'Onderdelen genomen',
        rejectPartsReturn: 'Keur onderdelenretour af',
        areYouSureAboutRejectPartsReturn:
            'Weet je zeker dat je de onderdelenretour wilt afwijzen?',
        partReturnRejected: 'Onderdeel retour afgewezen',
        counterFields: 'Teller velden',
        counterName: 'Teller naam',
        counterFieldName: 'Teller veldnaam',
        counterId: 'Teller ID',
        counterUUID: 'Teller UUID',
        counterPickerPlaceholder: 'Selecteer een teller',
        pickFromPartsList: 'Kies uit onderdelenlijst',
        changeLocation: 'Verplaatsen',
        isPartStorageRoot: 'Onderdeel opslag root',
        areYouSureAboutArchiveIssue:
            'Weet je zeker dat je de uitgifte wilt archiveren?',
        ganttChart: 'Gantt-diagram',
        table: 'Tafel',
        fittingEquipment: 'Passende apparatuur',
        notAssigned: 'Niet toegewezen',
        counters: 'Tellers',
        machineHourlyCostNotProvided: 'Uurloon van de machine niet opgegeven',
        eventTypesMightBeDefinedHere:
            'Evenementtypes kunnen hier worden gedefinieerd',
        toolsTakenByUser: 'Gereedschap genomen door gebruiker',
        predictedReturnDate: 'Voorspelde retourdatum',
        estimatedTimeOfUsingToolInHours:
            'Geschatte tijd om de tool te gebruiken (in uren)',
        showOnlyAvailable: 'Toon alleen beschikbaar',
        toolTakeLog: 'Tool Take Log',
        takeDate: 'Neem datum',
        returnDate: 'Retourdatum',
        personWhoAcceptReturn: 'Persoon die de retour accepteert',
        statisticStrategy: 'Bereikbaarheidsstrategie',
        EquipmentPlannedStrategy: 'Volgens het werkplan',
        EquipmentConstantAvailabilityStrategy: 'Altijd beschikbaar',
        actionAfterIssueFinish: 'Actie na uitgifte voltooid',
        enabled: 'Ingeschakeld',
        categoryOfNewIssue: 'Categorie van nieuwe uitgifte',
        workNotStarted: 'Werk niet begonnen',
        colorInherited: 'Kleur geërfd',
        abcClassification: 'ABC-classificatie',
        xyzClassification: 'XYZ-classificatie',
        classification: 'Classificatie',
        filters: 'Filters',
        wipeFilters: 'Filters wissen',
        takePartsFromUser: 'Neem onderdelen van gebruiker',
        selectPersonWhoReturns: 'Selecteer persoon die terugkeert',
        changePersonWhoReturns: 'Persoon die terugkeert wijzigen',
        returnLocation: 'Retourlocatie',
        returnQuantity: 'Retourhoeveelheid',
        globalSettings: 'Algemene instellingen',
        delimiter: 'Scheidingsteken',
        fieldRules: 'Veldregels',
        fieldValue: 'Veldwaarde',
        user_email_regex: 'Gebruiker e-mail regex',
        barcodeLength: 'Barcode lengte',
        partLine: 'Onderdeel lijn',
        partFamily: 'Onderdeel familie',
        partBatch: 'Onderdeel batch',
        testValueValid: 'Testwaarde geldig',
        testValueNotValid: 'Testwaarde niet geldig',
        canBePicked: 'Kan gekozen worden',
        issue_create_photos: 'Foto`s toevoegen aan een nieuw nummer',
        issue_create_documents: 'Documenten toevoegen aan een nieuwe uitgave',
        propagateValues: 'Waarde verspreiding',
        archived: 'Gearchiveerd',
        escalations_tab: 'Escalaties',
        minute: 'Minuut',
        days: 'Dagen',
        hours: 'Uren',
        minutes: 'Minuten',
        warnDays: 'Waarschuwing vóór vervaldatum (dagen)',
        licenseType: 'Licentie type',
        licenseGroup: 'Licentiegroep',
        attachments: 'Bijlagen',
        attachment: 'Bijlage',
        revisionChanges: 'Veranderingen geïntroduceerd',
        document: 'Document',
        noInstructionSelected: 'Geen instructie geselecteerd',
        instructionGroups: 'Groepen instructies',
        instructionGroup: 'Instructie groep',
        addOption: 'Voeg een optie toe',
        ordinal: 'Ordinale waarde',
        parameterName: 'Parameternaam',
        fontColor: 'Lettertype kleur',
        backgroundColor: 'Achtergrond kleur',
        columns: 'Aantal columns',
        colspan: 'Breedte (kolommen)',
        products: 'Producten',
        controlsTemplates: 'Controlesjablonen',
        controlType: 'Controlerende type',
        controls: 'Inspecties',
        controlTemplate: 'Controle sjabloon',
        product: 'Product',
        checklistsAttachedToControlTemplate:
            'Controlelijsten toegewezen aan inspecties',
        removeChecklistFromControlTitle:
            'Weet u zeker dat u deze lijst uit uw beheer wilt verwijderen?',
        attachChecklistToControl: 'Wijs een lijst toe aan controle',
        pickedItem: 'Geselecteerd item',
        pick: 'Kiezen',
        fillControl: 'Voltooi de controle',
        back: 'Opbrengst',
        control: 'Controle',
        duplicate: 'Duplicaat',
        isStorageRoot: 'Hoofdlocatie van het magazijn',
        manageStorageAccess: 'Beheer de toegang tot magazijnen',
        manageVisibleColumns: 'Pas de zichtbaarheid van kolommen aan',
        columnName: 'Kolomnaam',
        visible: 'Zichtbaar',
        entriesLocation: 'inzendingen locatie',
        details: 'Details',
        otherData: 'Andere gegevens',
        moveAllEntriesFromCurrentLocation:
            'Verplaats alle vermeldingen vanaf deze locatie',
        searchInCurrentLocation: 'Zoek op uw huidige locatie',
        transition: 'Overgang',
        notification: 'Kennisgeving',
        emails: 'E-mailadressen',
        phones: 'Telefoonnummers',
        addAction: 'Voeg een actie toe',
        message: 'Bericht',
        application: 'App',
        always: 'Altijd',
        sms: 'SMS',
        loginTo: 'Inloggen',
        appName: 'Signalo TPM',
        index: 'Inhoudsopgave',
        showCodeOwnerNameDuringParing:
            'Toon de naam van de code-eigenaar bij het koppelen met een kaart/tag',
        showUsernameInCart: 'Toon gebruikersnaam in winkelwagen',
        sampleButtonPlaceholder: 'Voorbeeldknop',
        preferences: 'Voorkeuren',
        buttonsSize: 'Knoopgrootte',
        erase: 'wissen',
        selectDictionaryValue: 'Selecteer woordenboekwaarde',
        conditions: 'Voorwaarden',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Bevestiging of afwijzing vereisen bij het retourneren van gedownloade onderdelen',
        issueCreated: 'Er is een ticket aangemaakt',
        allowedIssuesCategories: 'Toegestane probleemcategorieën',
        hiddenFields: 'Verborgen velden',
        defaultValues: 'Standaard waarden',
        paddingBottom: 'Lege ruimte onderaan',
        allVisibleFieldsRequired:
            'Vereisen dat alle zichtbare velden zijn ingevuld',
        url: 'URL',
        kind: 'Vriendelijk',
        forkfleetConfigId: 'Forkfleet Configuratie',
        create: 'Creëren',
        terminate: 'Beëindigen',
        parent: 'Ouder',
        phoneNumbers: 'Telefoonnummers',
        storageDefaults: 'Standaard opslagwaarden',
        clientSignatureEnabled: 'Handtekening van de persoon die het ophaalt',
        warehousemanSignatureEnabled: 'Handtekening van de magazijnier',
        storage_client_signature_enabled:
            'Handtekening van de persoon die de artikelen uit het magazijn haalt',
        storage_client_signature_required:
            'Vereist de handtekening van de persoon die de artikelen uit het magazijn haalt',
        storage_warehouseman_signature_enabled:
            'Handtekening van de persoon die de artikelen uit het magazijn vrijgeeft',
        storage_warehouseman_signature_required:
            'Vereist de handtekening van de persoon die de artikelen uit het magazijn vrijgeeft',
        clientReturnSignatureEnabled:
            'Handtekening van de terugkerende persoon',
        warehousemanReturnSignatureEnabled:
            'Handtekening van de persoon die de retourzending accepteert',
        storage_client_return_signature_enabled:
            'Handtekening van de persoon die de artikelen retourneert',
        storage_client_return_signature_required:
            'Vereist een handtekening van de persoon die de artikelen retourneert',
        storage_warehouseman_return_signature_enabled:
            'Handtekening van de persoon die de retourzending van het artikel accepteert',
        storage_warehouseman_return_signature_required:
            'Vereist de handtekening van de persoon die de retourzending van het artikel accepteert',
        channels: 'Kanalen',
        manageState: 'Beheer staat',
        assignWorker: 'Wijs een medewerker aan',
        mergeEntriesOfTheSameLocation:
            'Voeg vermeldingen van dezelfde locatie samen',
        permission: 'Toestemming',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'Weet u zeker dat u alle vermeldingen op deze locatie wilt samenvoegen?',
        manageFilters: 'Beheer filters',
        responsible_assign_creator_when_no_rule:
            '[Rapporten] - Wijs de maker toe als verantwoordelijk als de regel niet overeenkomt',
        recreateIssue: 'Maak het ticket opnieuw aan',
        addNewDictionary: 'Voeg een nieuw woordenboek toe',
        label: 'Etiket',
        issuesWithRelatedCategories: 'Voeg subcategorieën toe',
        issuesLimit: 'Beperking van problemen',
        issuesLimits: 'Problemen - limieten',
        issueDefaults: 'Rapporten - standaardwaarden',
        flatCategoryPickerLabel: 'Platte categoriestructuur',
        statementsTemplates: 'Sjablonen voor verklaringen',
        statementTemplate: 'Verklaring sjabloon',
        content: 'Inhoud',
        readonly: 'Alleen lezen',
        defaultValue: 'Standaardwaarde',
        fillParametersToChangeState:
            'Vul de waarden voor de onderstaande parameters in om de status te wijzigen',
        formatPattern: 'Formaat patroon',
        countableIncFields: 'Configuratie van documentnummering',
        mainShift: 'Belangrijkste verschuiving',
        singleEvent: 'Eenmalige toegang',
        seriesOfEvents: 'Een reeks inzendingen',
        omitWeekends: 'Vermijd weekenden',
        allEntriesSaved: 'Alle vermeldingen zijn toegevoegd',
        surveyResults: 'Enquêteresultaten',
        qrActionsSettings: 'Actie-instellingen voor QR-codes',
        pendingStatements: 'Verklaringen om te ondertekenen',
        statementsResults: 'Verklaringen',
        statements: 'Verklaringen',
        statement: 'Stelling',
        userCanChangeContent: 'De gebruiker kan de inhoud wijzigen',
        statementsEvents: 'Gebeurtenisconfiguratie - instructies',
        helpdesk: 'Helpdesk',
        terminalId: 'Terminal-ID',
        operatorId: 'Operator-ID',
        newConversation: 'Nieuwe draad',
        formula: 'Formule',
        fill: 'Compleet',
        webMessage: 'Webbericht',
        noResponsibleUser: 'Geen verantwoordelijke gebruiker',
        filledAtDate: 'Datum van voltooiing',
        filledBy: 'Afgemaakt door',
        parts_quantity_alert_rule:
            '[Aantal onderdelen en materialen] - Waarschuwing',
        employeeNumber: 'Werknemersnummer',
        groupsAssignedToCategory: 'Groepen toegewezen aan categorieën',
        number: 'Nummer',
        customers: 'Klanten',
        customer: 'Klant',
        projects: 'Projecten',
        project: 'Project',
        report: 'Rapport',
        reportGenerationInProgress: 'Het rapport genereert',
        storageStatementGroup: 'Magazijnaangiftegroep',
        simplifiedHandling: 'Vereenvoudigde afhandeling van meldingen',
        selectWorkArea: 'Selecteer uw werkgebied',
        workAreaSelection: 'Een werkgebied selecteren',
        access: 'Toegang',
        restrictAccess: 'Beperkte toegang',
        anchor: 'Verankering',
        issueCreator: 'De persoon die het rapport maakt',
        hideSideMenu: 'Zijmenu verbergen',
        appendGroups: 'Groepen om toe te voegen',
        revokeGroups: 'Groepen om te verwijderen',
        override: 'Overschrijven',
        restrictGroupAccess: 'Beperk de toegang tot groepen',
        flag: 'Vlag',
        flags: 'Vlaggen',
        manageFlags: 'Beheer vlaggen',
        icon: 'Icoon',
        appendFlags: 'Vlaggen om toe te voegen',
        revokeFlags: 'Vlaggen die verwijderd moeten worden',
        noFlagsConfigured: 'Geen vlaggen geconfigureerd',
        checkIsTerminal: 'Het controleren van de terminal',
        noConditionsDefined: 'Geen voorwaarden gedefinieerd',
        checkIsResponsible: 'Verantwoordelijkheidscontrole',
        createdOn: 'Gerapporteerd van',
        replaceTimespan: 'Houdbaarheid vanaf het moment van uitgifte',
        months: 'Maanden',
        handoversTemplates: 'Sjablonen voor overdrachten',
        handoverTemplate: 'Sjabloon voor overdracht',
        employeeFunction: 'Functie medewerker',
        takeHandover: 'Neem de overdracht',
        handovers: 'Overdrachten',
        expiringHandovers: 'Artikelen waarvan de vervaldatum bijna is bereikt',
        years: 'Jaren',
        taken_parts_expiry_warning:
            'Waarschuwing vóór de vervaldatum van gedownloade items',
        andonView: 'Andon-weergave',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'Ander',
        tags: 'Kaarten/ Tags',
        tag: 'Kaart / Tag',
        userPinCode: '@:common.PIN_CODE_LABEL van kaartgebruiker',
        emailConfirmation: 'Bevestiging van e-mailadres',
        from: 'Van',
        to: 'Naar',
        handoversHistory: 'Geschiedenis van overdrachten',
        partUsagesHistory: 'Geschiedenis van onderdeelgebruik',
        signaturePinTagConfirmationText1: 'Bordje met @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'of scan uw kaart.',
        signedWithPinCode: 'Gesigneerd met @:common.PIN_CODE_LABEL',
        signedWithTAG: 'Gesigneerd met kaart/tag',
        test_mode: 'Testmodus',
        confirmWorkFinish: 'Bevestig de voltooiing van het werk',
        workStarted: 'Het werk begint',
        workFinished: 'Werk klaar',
        workTimeRegister: 'Werktijdenregistratie',
        measureTypes: 'Soorten metingen',
        measureType: 'Metingstype',
        measurable: 'Meetbare indicator',
        measurables: 'Meetbare indicatoren',
        measurements: 'Meting',
        measurement: 'Meting',
        measuredAt: 'Meetdatum',
        currentVersion: 'Huidige versie',
        employee: 'Medewerker',
        pin_code_label: 'PIN-naam',
        orders: 'Bestellingen',
        productionPlan: 'Productieplan',
        productionPlans: 'Productieplannen',
        taktTime: 'Takt tijd',
        target: 'Doel',
        seconds: 'Seconden',
        inheritValuesFromTenantSettings:
            'Standaardwaarden overnemen van algemene instellingen',
        matrices: 'Matrices',
        manageMatrice: 'Beheer Matrix',
        deletionOfMatrixColumnWarnMessage:
            'Als u een kolom verwijdert, worden de waarden verwijderd.',
        removeColumn: 'Verwijder een kolom',
        editColumn: 'Kolom bewerken',
        column: 'Kolom',
        row: 'Rij',
        evaluated: 'geëvalueerd',
        of: 'van',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - Automatische generatie',
        or: 'of',
        rename: 'Hernoemen',
        check_only_on_transition:
            'Controleer dit alleen als de status verandert',
        license: 'Bevoegdheden',
        confirmWorkStart: 'Bevestig het begin van de werkzaamheden',
        workStartAt: 'Je begint te werken bij',
        workFinishAt: 'Je maakt je werk af o',
        workStartedAt: 'Aan de slag',
        workTimeMessage: 'Je hebt overwerkt',
        haveANiceDay: 'Prettige dag!',
        farewellText: 'Tekst op het laatste scherm',
        namedayLabel: 'Ze vieren hun naamdag',
        sportEventNearby: 'Het volgende sportevenement',
        autoTranslate: 'Automatische vertaling',
        auto_translation: 'Automatische vertaling',
        awaitingAutoTranslation: 'Wachten op automatische vertaling',
        inheritAllDefaultValues:
            'Neem alle standaardwaarden over van de algemene instellingen',
        escalations: 'Escalaties',
        escalation: 'Escalatie',
        notTaken: 'Niet begonnen',
        notFinished: 'Onvoltooid',
        issueLastChange: 'Laatste wijziging',
        mode: 'Modus',
        repeatLastEscalation: 'Herhaal de laatste escalatiewaarde',
        manageActions: 'Beheer uw acties',
        productionLines: 'Productielijnen',
        productionTerminal: 'Productieterminal',
        currently: 'Momenteel',
        currentPlan: 'Huidige plan',
        currentlyInProduction: 'Momenteel geproduceerd',
        setProduct: 'Product instellen',
        second: 'Seconden',
        work: 'Werk',
        chartConfiguration: 'Kaartconfiguratie',
        revisionsToConsider: 'Herzieningen ter overweging',
        allInstructions: 'Alle instructies',
        decider: 'Bepaler',
        considerRevision: 'Overweeg herziening',
        and: 'En',
        acceptInstruction: 'Accepteer de instructies',
        accept: 'aanvaarden',
        instructionsToAccept: 'Instructies om te lezen',
        open: 'Open',
        assignedAt: 'Datum van opdracht',
        confirmationAt: 'Bevestigingsdatum',
        mainInstruction: 'Standaardinstructie',
        setAsDefault: 'Instellen als standaard',
        instructionsFlows: 'Instructies - stromen',
        newRevision: 'Nieuwe revisie',
        playlist: 'Videolijst',
        selectVideo: 'Selecteer een film',
        selectVideos: 'Selecteer films',
        uploadNewVideo: 'Upload nieuwe video',
        upload_date: 'Datum toegevoegd',
        thumbnail: 'miniatuur',
        duration: 'Duur',
        uploadFromDrive: 'Uploaden vanaf schijf',
        selectAssets: 'Kies materialen',
        assets: 'Materialen',
        video: 'Film',
        doYouWantToAddNewMarker: 'Wilt u hier een nieuwe tag toevoegen?',
        areYouSureAboutRemoveMarker:
            'Weet u zeker dat u deze tag wilt verwijderen?',
        plants: 'Fabrieksinstallaties',
        plant: 'Fabriek',
        buildings: 'Gebouwen',
        building: 'Gebouw',
        floors: 'Vloeren',
        floor: 'Vloer',
        map: 'Kaart',
        areYouSureAboutMoveMarker:
            'Weet u zeker dat u deze markering wilt verplaatsen?',
        marker: 'Markeerstift',
        addNewMarkerTooltipMessage:
            'Om een ​​nieuwe markering toe te voegen, klikt u met de rechtermuisknop op de kaart.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Niet-lineaire vaardigheden',
        skillLevelConfig: 'Vaardigheidsniveaus',
        indirectSkillGroups: 'Niet-lineaire vaardigheidsgroepen',
        approved: 'Bevestigd',
        approvedBy: 'Goedkeurder',
        skillLevel: 'Vaardigheidsniveau',
        requiredSkillLevel: 'Vereist vaardigheidsniveau',
        step: 'Stap',
        image: 'Foto',
        skills: 'Vaardigheden',
        visits: 'Bezoeken',
        responsiblePerson: 'Verantwoordelijke persoon',
        company: 'Bedrijf',
        contactData: 'Contactgegevens',
        visit: 'Bezoek',
        inProgress: 'In uitvoering',
        gatehouse: 'Poortgebouw',
        confirmEnter: 'Invoer bevestigen',
        confirmExit: 'Bevestig afsluiten',
        enter: 'Invoer',
        exit: 'Afsluiten',
        entryGuard: 'De persoon die binnenlaat',
        exitGuard: 'De persoon die verhuur',
        loginAsThisUser: 'Log in als deze gebruiker',
        minBrowserVersion: 'Minimale browserversie vereist',
        approve: 'Bevestigen',
        cancelApproval: 'Vastlegging annuleren',
        workPermit: 'Werkvergunning',
        workPermits: 'Werkvergunningen',
        visit_summary: 'Samenvatting van het bezoek',
        createVisit: 'Maak een bezoek aan op basis van een vergunning',
        employeesAtThePlant: 'Werknemers in de fabriek',
        purpose: 'Doel',
        allowedAreas: 'Toegestane gebieden',
        switchEmployees: 'Vervang medewerker',
        localIssues: 'Lokale rapportage',
        cmmsCategoriesSync: 'Synchronisatie van CMMS-categorieën',
        terminalsGroups: 'Terminalgroepen',
        isTree: 'Boom',
        isReporter: 'Is de verslaggever',
        isAnchored: 'Ligt verankerd',
        closable: 'Kaartjes sluiten',
        areYouSureAboutCloseIssue:
            'Weet u zeker dat u het ticket wilt sluiten?',
        simplifiedIssuesRefreshLabel: 'Lijstvernieuwingstijd - seconden',
        reloadApp: 'Herladen',
        hideWorkAreasButton: 'Verberg werkgebiedselectieknop',
        hideClassicViewButton: 'Verberg klassieke weergaveknop',
        hideAddIssueButton: 'Verberg knop voor nieuw probleem toevoegen',
        filesManager: 'Bestandsbeheer',
        addFolder: 'Map toevoegen',
        upload: 'Uploaden',
        noFolders: 'Geen mappen',
        noFiles: 'Geen bestanden',
        folders: 'Mappen',
        folder: 'Map',
        files: 'Bestanden',
        file: 'Bestand',
        removeFolderFilesQuestion: 'Wat te doen met de bestanden in deze map?',
        targetFolder: 'Doelmap',
        addInstruction: 'Instructie toevoegen',
        goToFolder: 'Ga naar map',
        defaultProdLine: 'Standaard productielijn',
        pin_code_field_type: 'PIN-code type',
        instructionsAccess: 'Machtigingen',
        noSections: 'Geen secties',
        requireReadingInstruction: 'Vereist het lezen van de instructie',
        hide_parameter_types: 'Parameter typen niet weergeven',
        unknownVersion: 'Onbekend',
        displayInstructionEveryTime: 'Toon de instructie elke keer',
        requireSigningInstructionEveryTime:
            'Elke keer ondertekening van de instructie vereisen',
        signedInstructions: 'Ondertekende instructies',
        signedAt: 'Ondertekend op',
        signatures: 'Handtekeningen',
        signature: 'Handtekening',
        systemGroup: 'Systeemgroep',
        pickable: 'Selecteerbaar',
        leaf: 'Geselecteerd element',
        path: 'Pad',
        displayMode: 'Weergavemodus',
        chart: 'Grafiek',
        preSignedStatement: 'Op papier ondertekende verklaring (volledig)',
        myOpenIssues: 'Mijn openstaande problemen',
        myAssignedIssues: 'Aan mij toegewezen problemen',
        displayParametersOnMainScreen: 'Bekijk parameters op het hoofdscherm',
        lockPin: 'Vergrendel PIN-wijziging',
        pinLocked: 'PIN vergrendeld',
        sortChange: 'Weergavevolgorde',
        asc: 'Oplopend',
        desc: 'Aflopend',
        alphanumeric: 'Alfanumeriek',
        numeric: 'Numeriek',
        modificationDate: 'Wijzigingsdatum',
        fromNewest: 'Van de nieuwste',
        fromOldest: 'Van de oudste',
        negation: 'Negatie',
        sortOrderService: 'Dienst',
        newIssueIgnoreFilters:
            'Toegepaste filters negeren bij het aanmaken van een nieuw probleem',
        refreshInterval: 'Lijstvernieuwingstijd',
        multiline: 'Meerdere regels',
        expanded: 'Uitgebreid',
        quizzes: 'Quizzen',
        courses: 'Cursussen',
        course: 'Cursus',
        quiz: 'Quiz',
        maxPoints: 'Maximale punten',
        minPointsToPass: 'Minimale punten om te slagen',
        questionFailQuiz: 'Een fout bij een vraag diskwalificeert de hele quiz',
        points: 'Punten',
        currentVersionPreview: 'Voorbeeld van de huidige versie',
        docs_sidebar_hidden_by_default:
            'De zijbalk in documenten standaard verbergen',
        allCategories: 'Alle categorieën',
        test: 'Test',
        tests: 'Testen',
        copy: 'Kopiëren',
        trusted_device_code_login_users_limit: "Snel inloggen - aantal weergegeven gebruikers"
    },
    placeholders: {
        part: 'Selecteer onderdeel',
        nameSurname: 'Naam en achternaam',
        phone: 'Telefoonnummer',
        password: 'Wachtwoord',
        password_confirmation: 'Bevestig wachtwoord',
        email: 'E-mail',
    },
    auth: {
        notYourAccountQuestion: 'Niet uw account?',
    },
    signup: {
        registered: 'Succes!',
        emailConfirmed: 'De e-mail is bevestigd.',
        registered_info: 'Vraag uw supervisor om uw account te activeren',
        confirmEmailAddress:
            'Il tuo account è stato creato, fai clic sul collegamento nell`e-mail per confermare il tuo indirizzo.',
        tokenNotFoundError:
            'De e-mail is al bevestigd of de bevestigingslink is ongeldig.',
    },
    paramTypes: {
        text: 'Tekst',
        note: 'Notitie',
        integer: 'Geheel getal',
        decimal: 'Decimaal getal',
        date: 'Datum',
        time: 'Tijd',
        datetime: 'Datum en tijd',
        color: 'Kleur',
        icon: 'Icoon',
        boolean: 'Nee / Ja',
        dictionary: 'Woordenboek',
        user: 'Gebruiker',
        radioGroup: 'Enkele keuze',
        checkboxGroup: 'Meerdere keuzes',
        signature: 'Handtekening',
        number: 'Getal',
        rating: 'Beoordeling',
        document: 'Document',
        image: 'Afbeelding',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Naam',
        path: 'Pad naar bestand',
        category: 'Categorie',
        equipment: 'Uitrusting',
        criticality: 'Kritiek',
        status: 'Status',
        created: 'Gemaakt',
        createdBy: 'Gemaakt door',
        fp: 'FP',
        type: 'Type',
        designation: 'Benaming',
        symbol: 'Symbool',
        serialNumber: 'Serienummer',
        registryNumber: 'Registratienummer',
        udtNumber: 'UDT-nummer',
        manufacturedYear: 'Fabricagejaar',
        actions: 'Acties',
        title: 'Titel',
        data: 'Datum',
        archived: 'Gearchiveerd',
        manufacturerSymbol: 'Fabrikantsymbool',
        min: 'Min',
        opt: 'Opt',
        quantity: 'Hoeveelheid',
        priority: 'Prioriteit',
        price: 'Prijs',
        kind: 'Soort',
        description: 'Beschrijving',
        default: 'Standaard',
        warning: 'Waarschuwing',
        problem: 'Probleem',
        fixing: 'Oplossen',
        changeOrder: 'Verander bestelling',
        required: 'Vereist',
        icon: 'Icoon',
        color: 'Kleur',
        subCategories: 'Subcategorieën',
        barCode: 'Barcode',
        subLocations: 'Sublocaties',
        categories: 'Categorieën',
        image: 'Afbeelding',
        nameSurname: 'Naam en achternaam',
        email: 'E-mail',
        phone: 'Telefoonnummer',
        function: 'Functie',
        department: 'Afdeling',
        accountStatus: 'Accountstatus',
        shift: 'Dienst',
        licenseNumber: 'Rijbewijsnummer',
        issuedDate: 'Uitgiftedatum',
        expiresDate: 'Vervaldatum',
        entries: 'Invoeren',
        entryNumber: 'Invoernummer',
        buyPrice: 'Inkoopprijs',
        currentLocationQuantity: 'Hoeveelheid op huidige locatie',
        group: 'Groep',
        string: 'Tekst',
        integer: 'Geheel getal',
        toolsGroup: 'Gereedschapsgroep',
        manufacturer: 'Fabrikant',
        shortName: 'Korte naam',
        locationName: 'Locatienaam',
        subLocationsQuantity: 'Hoeveelheid sublocaties',
        isWorkingOnIssue: 'Werkt aan kwestie',
        openIssues: 'Open kwesties',
        notificationDate: 'Meldingsdatum',
        titleAndDescription: 'Titel en beschrijving',
        read: 'Gelezen',
        created_at: 'Gemaakt',
        received_at: 'Ontvangen',
        started_at: 'Begon',
        arrived_at: 'Aangek',
        finished_at: 'Afgerond',
        country: 'Land',
        city: 'Stad',
        progress: 'Voortgang',
        deleted_at: 'Datum verwijderen',
        about_issue: 'Geldt voor de melding.',
        action: 'Deel',
    },
    searchConfig: {
        id: 'Id',
        name: 'Naam',
        shortName: 'Korte naam',
        country: 'Land',
        email: 'E-mail',
        group: 'Groep',
        planned: 'Gepland',
        category: 'Categorie',
        object: 'Object',
        criticality: 'Kritiek',
        fp: 'FP',
        type: 'Type',
        designation: 'Aanduiding',
        symbol: 'Symbool',
        serialNumber: 'Serienummer',
        registryNumber: 'Registernummer',
        udtNumber: 'UDT-nummer',
        manufacturedYear: 'Fabrieksjaar',
        manufacturerSymbol: 'Fabrikantssymbool',
        priority: 'Prioriteit',
        quantity: 'Aantal',
        buyPrice: 'Inkoop prijs',
        kind: 'Soort',
        toolsGroup: 'Gereedschapsgroep',
        function: 'Functie',
        department: 'Afdeling',
        status: 'Status',
        nameSurname: 'Naam en achternaam',
        phone: 'Telefoon',
        barCode: 'Barcode',
        color: 'Kleur',
        icon: 'Icoon',
        machine: 'Machine',
        tool: 'Gereedschap',
        urgentAndOutdated: 'Urgent en verouderd',
        urgent: 'Urgent',
        expired: 'Verlopen',
        manufacturer: 'Fabrikant',
    },
    errors: {
        uploadError: 'Bestandsuploadfout',
        finishedSuccessfully: 'Succesvol voltooid',
        partsAvailabilityError:
            'De beschikbaarheid van sommige geselecteerde onderdelen is gewijzigd. Bewerk de inhoud van uw ruildoos en probeer opnieuw.',
        noFlagsConfigured: 'Geen vlaggen gedefinieerd',
        partMustHaveEntriesToCorrectQuantity:
            'Het onderdeel moet vermeldingen hebben om de hoeveelheid te corrigeren.',
        error: 'Er is een fout opgetreden',
        wrongEmail: 'Ongeldig e-mailadres',
        wrongPasswordLength: 'Wachtwoord moet minimaal 8 tekens lang zijn.',
        wrongCodeLength:
            '@:common.PIN_CODE_LABEL moet minimaal 5 tekens lang zijn.',
        passwordsNotMatch: 'Wachtwoorden komen niet overeen',
        codesNotMatch: '@:common.PIN_CODE_LABEL komen niet overeen',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL is al in gebruik',
        expiresDateLowerThanIssued:
            'Vervaldatum kan niet lager zijn dan uitgiftedatum.',
        cannotMoveToTheSameLocation:
            'Kan niet naar dezelfde locatie verplaatsen.',
        locationRequired: 'Locatie is vereist.',
        putQuantity: 'Voer hoeveelheid in om te scheiden.',
        valueGreaterThanQuantity: 'Waarde is groter dan totale hoeveelheid.',
        wholeCannotBeDistinguished: 'Geheel kan niet worden onderscheiden.',
        yearMustBeGreaterThan: 'Jaar moet groter zijn dan ',
        passwordConfirmationNotMatch: 'Wachtwoorden komen niet overeen',
        mustBeInteger: 'Waarde moet een geheel getal zijn',
        valueTooLong: 'Waarde is te lang',
        wrongPhoneNumber: 'Onjuist telefoonnummer',
        phoneNumberAlreadyTaken: 'Telefoonnummer is al in gebruik',
        emailAlreadyTaken: 'E-mailadres is al in gebruik',
        notFound: 'Niet gevonden',
        sessionExpired: 'Sessie is verlopen',
        tenantNotFound:
            'De aangegeven configuratie bestaat niet. Controleer het siteadres en probeer het opnieuw.',
        selectEquipmentTypeToConfigureParameters:
            'Selecteer het type uitrusting om parameters te configureren.',
        noParametersToConfigure:
            'Het geselecteerde uitrustingstype heeft geen parameters om te configureren.',
        terminalAlreadyRegistered: 'Terminal is al geregistreerd.',
        invalidPin: 'Ongeldige @:common.PIN_CODE_LABEL',
        eventCollision:
            'De tijd van het evenement dat u wilt toevoegen overlapt de tijd van een ander evenement.',
        mustStartWithSlash: 'Moet beginnen met /',
        mustBeBetween: 'Waarde moet liggen tussen:',
        minLength: 'Minimale lengte: ',
        maxLength: 'Maximale lengte: ',
        invalidInputForCodeType: 'Ongeldige invoer voor geselecteerde codetype',
        invalidCodeOrNoPermission:
            'De @:common.PIN_CODE_LABEL is ongeldig of u heeft niet voldoende rechten om deze actie uit te voeren.',
        endDateLowerThanStart: 'Einddatum mag niet lager zijn dan startdatum',
        iconAlreadyTaken: 'Icoon is al in gebruik',
        notEnoughQuantityInStorage: 'Onvoldoende hoeveelheid in opslag',
        valueTooLow: 'Waarde te laag',
        valueTooHigh: 'Waarde te hoog',
        maxAvailableQuantity: 'Maximum aantal',
        someFilesRejectedDueToSize:
            'Sommige bestanden werden geweigerd vanwege de grootte. Maximale grootte van een enkel bestand:',
        cameraDeviceNotFound: 'Camera-apparaat niet gevonden',
        cameraPermissionDenied:
            'De toegang tot de apparaatcamera is geblokkeerd in de site-instellingen, reset de site-instellingen en sta het gebruik van de camera toe.',
        passwordResetLinkExpired:
            'De link voor het opnieuw instellen van het wachtwoord is verlopen',
        noInternetConnection: 'Geen internet verbinding',
        minimum: 'Minimum',
        mustBeGreaterThan: 'Moet groter zijn dan',
        noEventTypesDefined: 'Geen gebeurtenistypen gedefinieerd',
        regexPatternDelimiterError:
            'Het scheidingsteken moet worden weggelaten met het teken \\ bijvoorbeeld: \\/',
        emailNotMatchPatternDefiniedInTenant:
            'E-mailadres komt niet overeen met het patroon gedefinieerd in de configuratie',
        max: 'Maximale waarde',
        min: 'Minimale waarde',
        allLanguagesRequired: 'Vul de waarde in alle talen in',
        fileTooBigToOpenWillBeDownloaded:
            'Het bestand is te groot om te openen, het bestand wordt naar uw apparaat gedownload.',
        surveyNotFound: 'Enquête niet gevonden',
        actionNotEnabled: 'De code is inactief',
        loginRequired: 'Aanmelden vereist',
        noPermissionForSelectedFolder:
            'U heeft geen toestemming om de inhoud van deze map te bekijken',
        noFoldersAssignedForTerminal:
            'Er zijn geen toegewezen mappen met instructies',
        noChecklistsAssignedToControl:
            'Aan de geselecteerde controle is geen checklist toegewezen',
        maximum: 'Maximaal',
        versionErrorMessage:
            'Uw recente wijzigingen zijn niet opgeslagen omdat iemand anders tegelijkertijd wijzigingen heeft aangebracht. Vernieuw de pagina en probeer het opnieuw.',
        duplicateError: 'Deze waarde bestaat al',
        fillAllRequiredParameters: 'Vul alle vereiste parameters in.',
        wrongValue: 'Ongeldige waarde',
        noParametersDefined: 'Geen parameters gedefinieerd',
        noEnoughParts: 'Niet genoeg voorwerpen',
        pinNotBelongsTo: 'Deze @:common.PIN_CODE_LABEL hoort niet bij',
        tagNotBelongsTo: 'Deze kaart/tag behoort niet tot',
        noDictionaryEntries:
            'Het geselecteerde woordenboek bevat geen vermeldingen',
        noConnectionWithServer: 'Geen verbinding met de server',
    },
    navDrawer: {
        dashboard: 'Dashboard',
        issues: 'Problemen',
        timetable: 'Rooster',
        machines: 'Machines',
        tools: 'Gereedschappen',
        parts: 'Onderdelen',
        storage: 'Magazijn',
        staff: 'Personeel',
        configuration: 'Configuratie',
        notes: 'Notities',
        notifications: 'Meldingen',
        profile: 'Profiel',
        supervision: 'Toezicht',
    },
    barcodeScanner: {
        barCode: 'Barcode',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Categorie',
        selectCategory: 'Selecteer categorie',
        noSubcategories: 'Geen subcategorieën',
    },
    companies: {
        addCompany: 'Bedrijf toevoegen',
        editCompany: 'Bedrijf bewerken',
        fullName: 'Volledige naam',
        shortName: 'Afkorting',
        group: 'Groep',
        country: 'Land',
        city: 'Stad',
        street: 'Straat',
        postCode: 'Postcode',
        phone: 'Telefoon',
        email: 'E-mail',
        website: 'Website',
        NIP: 'NIP',
        REGON: 'Nationale Bedrijfsregistratie',
        active: 'Actief',
        nameRequired: 'Naam is verplicht',
        shortNameRequired: 'Afkorting is verplicht',
    },
    issues: {
        tookAt: 'Genomen om',
        changeHistory: 'Wijzigingsgeschiedenis',
        load: 'Laden',
        date: 'Datum',
        createdBy: 'Aangemaakt door',
        action: 'Actie',
        data: 'Gegevens',
        created: 'Aangemaakt',
        updated: 'Bijgewerkt',
        deleted: 'Verwijderd',
        restored: 'Hersteld',
        issues: 'Problemen',
        issue: 'Probleem',
        by: 'door',
        name: 'Naam',
        applicantsDescription: 'Omschrijving aanvrager',
        accidentDescription: 'Omschrijving ongeval',
        jobDescription: 'Omschrijving werkzaamheden',
        jobSummary: 'Samenvatting werkzaamheden',
        category: 'Categorie',
        status: 'Status',
        noFlow: '[Geen stroom]',
        equipment: 'Uitrusting',
        criticality: 'Kritiek',
        hour: 'Uur',
        formalConfirmation: 'Formele bevestiging',
        formalConfirmationShortcut: 'FB',
        formalConfirmationRequired: 'FB vereist',
        parameter: 'Parameter',
        value: 'Waarde',
        addIssue: 'Probleem toevoegen',
        expectedCompletionDate: 'Verwachte voltooiingsdatum',
        issueDescription: 'Probleemomschrijving',
        partsUsage: 'Verwerking onderdelen',
        assignedEmployees: 'Toegewezen medewerkers',
        requiredHelp: 'Benodigde hulp',
        returnParts: 'Onderdeel retourneren',
        confirmUnassignEmployeeWhoTakenIssue:
            'Deze medewerker heeft het geselecteerde probleem opgepakt. Wilt u hem verwijderen?',
        responsibleUser: 'Verantwoordelijke gebruiker',
        responsibleRules: 'Verantwoordelijke gebruikers - regels',
        received_decision_at:
            'Datum waarop de kennisgever op de hoogte is van het besluit',
        groupRules: 'Groepen - regels',
        watchersRules: 'Watchers - regels',
    },
    machines: {
        machines: 'Machines',
        xIssuesNotPlanned: ' (niet gepland)',
        noIssues: 'Geen problemen',
    },
    maintenance: {
        maintenance: 'Onderhoud',
    },
    notes: {
        notes: 'Notities',
        note: 'Notitie',
        title: 'Titel',
        archived: 'Gearchiveerd',
    },
    parts: {
        parts: 'Onderdelen',
        warehouseOutgoings: 'Magazijnuitgangen',
        fittingEquipmentTypes: 'Passende apparatuurtypes',
        goToEquipmentType: 'Ga naar apparatuurtype',
        selectPart: 'Selecteer onderdeel',
        usageReason: 'Reden',
        usageOtherReason: 'Anders',
        replacements: 'Vervangingen',
        partEntry: 'Onderdeel-invoer',
        goToPart: 'Ga naar onderdeel',
    },
    costCenter: {
        costCenter: 'Kostencentrum',
    },
    equipmentTypes: {
        equipment: 'Uitrusting',
        newEquipmentType: 'Nieuw apparatuurtype',
        fittingParts: 'Passende onderdelen',
        goToParts: 'Ga naar onderdeel',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'Definities machines en gereedschappen',
    },
    flows: {
        flow: 'Stroom',
        states: 'Staten',
        transitions: 'Overgangen',
        state: 'Staat',
        default: 'Standaard',
        warning: 'Waarschuwing',
        problem: 'Probleem',
        fixing: 'Repareren',
        finished: 'Afgerond',
        access: {
            create: 'Creatie',
            read: 'Lezing',
            update: 'Wijziging',
            delete: 'Verwijdering',
            read_unassigned: 'Lees niet-toegewezen',
            assign: 'Opdracht',
            unassign: 'Een opdracht verwijderen',
            close: 'Sluiten',
        },
        instructions: {
            default: 'Standaard',
            fixing: 'Overweging',
            finished: 'Afgerond',
            creation: 'Creatie',
            verification: 'Verificatie',
            acceptation: 'Acceptatie',
            publication: 'Publicatie',
        },
        accessLabels: {
            terminal: 'De terminal die het probleem heeft gemeld',
            creator: 'De gebruiker die het probleem heeft gemeld',
            responsible:
                'De gebruiker die verantwoordelijk is voor het probleem',
            group: 'De gebruikersgroep die verantwoordelijk is voor het probleem',
            anchor: 'De terminal waaraan het rapport is vastgemaakt',
            watchers: 'Gebruikers observeren het probleem',
        },
        sync_returned_rejected: 'Synchronisatie - afgewezen',
        sync_returned_finished: 'Synchronisatie - voltooid',
        current: 'Huidig',
    },
    parameters: {
        categoryParameter: 'Categorieparameter',
        equipmentTypeParameter: 'Apparatuurtypeparameter',
    },
    staff: {
        staff: 'Personeel',
        editUserAccess: 'Bewerk gebruikerstoegang',
        permissionsUpdated: 'Permissies bijgewerkt',
        passwordUpdated: 'Wachtwoord bijgewerkt',
        codeUpdated: '@:common.PIN_CODE_LABEL bijgewerkt',
        changePasswordAndCode: 'Wijzig wachtwoord en @:common.PIN_CODE_LABEL',
        goToLicenses: 'Ga naar licenties',
        editAccess: 'Bewerk toegang',
        editUser: 'Bewerk gebruiker',
        addUser: 'Voeg nieuwe gebruiker toe',
        nameAndLastname: 'Naam en achternaam',
        accountStatus: 'Accountstatus',
        phoneNumber: 'Telefoonnummer',
        department: 'Afdeling',
        passwordChange: 'Wachtwoord wijzigen',
        codeChange: '@:common.PIN_CODE_LABEL wijzigen',
        newPassword: 'Nieuw wachtwoord',
        confirmNewPassword: 'Bevestig nieuw wachtwoord',
        changePassword: 'Wijzig wachtwoord',
        newCode: 'Nieuwe @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Bevestig nieuwe @:common.PIN_CODE_LABEL',
        changeCode: 'Wijzig @:common.PIN_CODE_LABEL',
        addEntry: 'Voeg invoer toe',
        editEntry: 'Bewerk invoer',
        editLicense: 'Bewerk licentie',
        addLicense: 'Voeg licentie toe',
        licenseNumber: 'Licentienummer',
        prefferedLocale: 'Voorkeurstaal',
        oldPassword: 'Oud wachtwoord',
        newPasswordConfirmation: 'Bevestig nieuw wachtwoord',
        reading: 'Weergave',
        creating: 'Aanmaken',
        updating: 'Bijwerken',
        deleting: 'Verwijderen',
        importScheduleFile: 'Importeer een schema uit een bestand',
        importSchedule: {
            errors: {
                user_not_found: 'Gebruiker niet gevonden [{user}]',
                user_found_many: '{count} gebruikers gevonden [{user}]',
                event_collision: 'Botsing van gebeurtenissen',
                hour_cell_parse: 'Fout bij het parseren van cellen',
            },
        },
        oldCode: 'Oude @:common.PIN_CODE_LABEL',
        newCodeConfirmation:
            'Bevestiging van de nieuwe @:common.PIN_CODE_LABEL',
        setAccessBasedOnProfile: 'Toegang verlenen op basis van profiel',
        selectAccessMergeType: 'Kies hoe u machtigingen wilt combineren',
        noAccessesInProfileMessage:
            'Het geselecteerde profiel heeft geen rechten. Wilt u toch overschrijven?',
    },
    breadCrumb: {
        configuration: 'Configuratie',
        locations: 'Locaties',
        home: 'Startpagina',
        machines: 'Machines',
        tools: 'Gereedschappen',
        issues: 'Problemen',
        notes: 'Notities',
        maintenance: 'Onderhoud',
        parts: 'Onderdelen',
        warehouse: 'Magazijn',
        dictionary: 'Woordenboek',
        categories: 'Categorieën',
        parameters: 'Parameters',
        costCenter: 'Kostencentrum',
        flows: 'Stromen',
        states: 'Staten',
        transitions: 'Overgangen',
        shiftSchemas: 'Dienstroosterschema`s',
        shifts: 'Diensten',
        definitionsOfMachinesAndToolsTypes:
            'Definities machines en gereedschappen types',
        add: 'Toevoegen',
        edit: 'Bewerken',
        staff: 'Personeel',
        licenses: 'Licenties',
        entries: 'Invoeren',
        companiesDatabase: 'Bedrijfsdatabank',
        companyManagement: 'Bedrijfsbeheer',
        companyData: 'Bedrijfsgegevens',
        supportedLanguages: 'Ondersteunde talen',
        en: 'Engels',
        pl: 'Pools',
        es: 'Spaans',
        de: 'Duits',
        defaultLanguage: 'Standaardtaal',
        notifications: 'Meldingen',
        notificationsManagement: 'Meldingenbeheer',
        myProfile: 'Mijn profiel',
    },
    attributeTypes: {
        text: 'Tekst',
        note: 'Notitie',
        integer: 'Geheel getal',
        decimal: 'Decimaal',
        date: 'Datum',
        time: 'Tijd',
        dateTime: 'Datum en tijd',
        color: 'Kleur',
        icon: 'Icoon',
        boolean: 'Ja/Nee',
    },
    costCalculator: {
        costCalculator: 'Kostencalculator',
        waitTime: 'Wachttijd',
        serviceTime: 'Servicetijd',
        suspendTime: 'Onderbrekentijd',
        totalTime: 'Totale tijd',
        estimatedCost: 'Geschatte kosten',
        calculate: 'Berekenen',
        withoutParts: 'Zonder onderdelen',
        timeOfReportingIssue: 'Tijd van melding van probleem',
        timeOfServiceStart: 'Tijd van start service',
        timeOfReportingEnd: 'Tijd van einde service',
        days: 'Dagen',
        hours: 'Uren',
        minutes: 'Minuten',
        enterNewTime: 'Voer nieuwe tijd in',
        fromIssueReportToServiceStart:
            'Tijd van melding van probleem tot start service',
        fromServiceStartToServiceEnd:
            'Tijd vanaf het begin van de dienst tot het einde van de dienst',
        workTime: 'Werktijd',
        includeAwaitingTime: 'Inclusief wachttijd',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'Mijn problemen',
                assignedToMe: 'Aan mij toegewezen',
                assignedToGroup: 'Aan mijn groep toegewezen',
                tookByMe: 'Door mij genomen',
                takeIssueBtn: 'Nemen',
                arriveIssueBtn: 'Ik ben gearriveerd',
                finishIssueBtn: 'Voltooid',
                history: 'Geschiedenis',
                myResponsible: 'Ik ben verantwoordelijk voor',
                unassignIssue: 'Afname annuleren',
            },
        },
    },
    synchronization: {
        enabled: 'Ingeschakeld',
        hasHeaders: 'Hebben koppen',
        leaveFile: 'Bestand achterlaten',
        host: 'Host',
        port: 'Poort',
        protocol: 'Protocol',
        username: 'Gebruikersnaam',
        password: 'Wachtwoord',
        root: 'Root',
        encoding: 'Encoding',
        separator: 'Scheidingsteken',
        externalSystemId: 'Extern systeem ID',
        cronExpression: 'Cron-uitdrukking',
        appendFullStock: 'Volledige voorraad toevoegen',
        includeHeaders: 'Koppen opnemen',
        withTrashed: 'Met verwijderde gegevens',
        filePattern: 'Bestandspatroon',
        categoriesRootCategoryId:
            'Hoofdcategorie voor geïmporteerde categorieën',
        systemKeyname: 'Systeemidentificator',
        address: 'Adres',
        token: 'Token',
    },
    processes: {
        created_at: 'Aangemaakt op',
        started_at: 'Begonnen op',
        updated_at: 'Bijgewerkt op',
        finished_at: 'Afgerond op',
        attempts: 'Pogingen',
        queue: 'Wachtrij',
        job_id: 'Taak-ID',
        job_uuid: 'Taak-UUID',
        progress_max: 'Maximale voortgang',
        progress_now: 'Huidige voortgang',
        outputMessage: 'Uitvoerbericht',
        status: {
            queued: 'in wachtrij',
            executing: 'uitvoerend',
            finished: 'afgerond',
            failed: 'mislukt',
            retrying: 'opnieuw proberen',
            undefined: 'niet gedefinieerd',
        },
    },
    weekDays: {
        mo: 'Maandag',
        tu: 'Dinsdag',
        we: 'Woensdag',
        th: 'Donderdag',
        fr: 'Vrijdag',
        sa: 'Zaterdag',
        su: 'Zondag',
    },
    reports: {
        reports: 'Rapporten',
        dateFrom: 'Datum vanaf',
        dateTo: 'Datum tot',
        types: {
            operation_sheet: 'Werkblad',
            issue_summary: 'Samenvatting van het probleem',
            grouped_summary: 'Samenvattingsverslag',
            storage_operations: 'Magazijnwerkzaamheden',
            part_quantity_export: 'Aantal onderdelen',
        },
        reportFor: 'Verslag voor',
        includeQuantityLeft0: 'Voeg onderdelen met een nulhoeveelheid toe',
        dayMinusN: 'Dag min n',
        currentWeek: 'Huidige week',
        lastWeek: 'Vorige week',
        currentMonth: 'Huidige maand',
        lastMonth: 'Vorige maand',
        currentYear: 'Huidig jaar',
        lastYear: 'Vorig jaar',
        nDays: 'Aantal dagen',
        todayMinusN: 'Vandaag min n',
    },
    logs: {
        comment: 'Opmerking',
        issues: {
            escalation: 'Escalatie ({minutes} min) - notificatie verzonden',
        },
        issueEmployee: {
            assignedAt: 'Medewerker toegewezen:',
            receivedAt: 'Medewerker heeft ontvangen:',
            tookAt: 'Medewerker heeft overgenomen:',
            finishedAt: 'Medewerker heeft voltooid:',
            deletedAt: 'Medewerker verwijderd uit de melding',
        },
    },
    units: {
        no: 'Deel',
        st: 'Set',
        pr: 'Paar',
    },
    rrule: {
        frequency: 'Frequentie',
        dtstart: 'Begin datum',
        tzid: 'Tijdzone',
        until: 'Tot',
        count: 'Graaf',
        interval: 'Interval',
        wkst: 'Start dag',
        byweekday: 'doordeweeks',
        bymonth: 'Bij maand',
        yearly: 'Jaarlijks',
        monthly: 'Maandelijks',
        weekly: 'Wekelijks',
        daily: 'Dagelijks',
        hourly: 'per uur',
        minutely: 'Minutieus',
        secondly: 'ten tweede',
        infinitePeriodicityMessage:
            'De periodiciteit is oneindig, vanwege de prestaties worden slechts 500 rijen weergegeven',
    },
    months: {
        jan: 'Januari',
        feb: 'Februari',
        mar: 'Maart',
        apr: 'April',
        may: 'Kunnen',
        jun: 'Juni',
        jul: 'Juli',
        aug: 'Augustus',
        sep: 'September',
        oct: 'Oktober',
        nov: 'November',
        dec: 'December',
    },
    charts: {
        charts: 'Grafieken',
        count: 'Aantal nummers',
        total: 'Totale uitgiftetijd (van rapport tot afwerking)',
        serw: 'Totale reparatietijd (Ttotal - Twait)',
        mttr1: 'Gemiddelde tijd van rapport tot finish',
        mttr2: 'Gemiddelde tijd van opname tot finish',
        mtbf: 'Gemiddelde uptime tussen problemen',
        mttf: 'Gemiddelde tijd tot de fout',
        type: 'Diagramtype',
        pie: 'Cirkeldiagram',
        bar: 'Een staafdiagram',
        query: 'De reikwijdte van de gegevens',
        groupBy: 'Groepeer op',
        sum: 'Som',
        avg: 'Gemeen',
        min: 'Minimum',
        max: 'Maximaal',
        eq: 'Is gelijk aan',
        neq: 'Het is niet gelijk',
        gt: 'Meer dan',
        lt: 'Minder dan',
        gte: 'Meer of gelijk',
        lte: 'Minder of gelijk',
        in: 'Bevat',
        nin: 'Niet bevatten',
        btw: 'Tussen',
        chartOptions: 'Grafiekopties',
        line: 'Lijn grafiek',
        mixed: 'Gemengd',
        chartDisplayOptions: 'weergaveopties',
        fillGaps: 'Vul de gaten in',
    },
    regexBuilder: {
        regexValue: 'Waarde',
        global: 'Globaal',
        multiline: 'Meerdere regels',
        insensitive: 'Niet hoofdlettergevoelig',
        extended: 'Uitgebreid',
        singleline: 'Enkele regel',
        unicode: 'Unicode',
        Ungreedy: 'Lui kwantoren',
        Anchored:
            'Verankering aan het begin van het patroon of aan het einde van de laatste match',
        Jchanged: 'Duplicaten van subpatroonnaam toestaan',
        DollarEndOnly: '$ komt alleen overeen met het einde van het patroon',
        testValue: 'Testwaarde',
    },
    tpm: {
        groupableHeadersPlaceholder:
            'Sleep een tabelkop om gegevens te groeperen.',
        steps: 'Stappen',
        step: 'Stap',
        content: 'Inhoud',
        history: 'Geschiedenis',
        standardObstacle: 'Standaardobstakel',
        customObstacle: 'Aangepast obstakel',
        bhp: 'Veiligheid en gezondheid op het werk',
        returnToMainPage: 'Terug naar hoofdpagina',
        bhpMessage:
            'Als u veiligheidsrisico`s opmerkt, meld deze dan onmiddellijk aan uw directe leidinggevende en gebruik het meldingsformulier onder het publicatiebord.',
        ok: 'OK',
        send: 'Versturen',
        description: 'Beschrijving',
        title: 'Titel',
        realization: 'Uitvoering',
        answer: 'Antwoord',
        pendingSurveys: 'In behandeling zijnde enquêtes',
        surveys: 'Enquêtes',
        systemSurvey: 'Systeemenquête',
        addPage: 'Pagina toevoegen',
        createSurvey: 'Enquête maken',
        addSection: 'Sectie toevoegen',
        addQuestion: 'Vraag toevoegen',
        questionContent: 'Vraaginhoud',
        answerOption: 'Antwoordoptie',
        addQuestionAnswer: 'Antwoordoptie toevoegen',
        surveyName: 'Naam enquête',
        sectionName: 'Sectienaam',
        noSurveyAnswers: 'Geen antwoorden',
        removeSection: 'Sectie verwijderen',
        published: 'Gepubliceerd',
        unpublished: 'Niet gepubliceerd',
        pendingPublication: 'Wachten op publicatie',
        removeQuestion: 'Vraag verwijderen',
        removeAnswer: 'Antwoordoptie verwijderen',
        publish: 'Publiceren',
        unpublish: 'Publicatie intrekken',
        previewResults: 'Resultaten bekijken',
        supervisior: 'Leidinggevende',
        surveyResults: 'Enquêteresultaten',
        surveysEvents: 'Gebeurtenisconfiguratie - enquêtes',
        operatedBy: 'Geëxploiteerd door',
        confirmReadInformations: 'Ik bevestig dat ik de informatie heb gelezen',
        noticeContent: 'Berichtinhoud',
        notice: 'Bericht',
        surveyEventName: 'Gebeurtenisnaam',
        survey: 'Enquête',
        newIssue: 'Nieuw probleem',
        surveyEventConfigurationAlreadyExists:
            'De configuratie voor deze gebeurtenis bestaat al',
        lastComment: 'Laatste opmerking van de verantwoordelijke',
        surveyNotConfigured: 'Enquête niet geconfigureerd',
        fillSurvey: 'Enquête invullen',
        singleSurveyEntries: 'enkele resultaten',
        groupedSurveyEntries: 'Gegroepeerde resultaten',
        publicationId: 'Publicatie-ID',
        publicationDate: 'Datum van publicatie',
        versionId: 'versie-ID',
        answersCount: 'Aantal reacties',
        answers: 'Antwoorden',
        withoutSupervisor: 'Geen begeleider',
        defaultSupervisor: 'Standaard supervisor',
        flowStateFlags: 'Status vlaggen',
        qrActionsSettings: 'Actie-instellingen voor QR-codes',
        loginRequired: 'Vereist inloggen',
        questionContext: 'De context van de vraag',
        currentSurveyVersionPreview:
            'Preview van de huidige versie van de enquête',
        surveyDescription: 'Enquêtebeschrijving',
        sectionDescription: 'Sectiebeschrijving',
        instructions: 'Instructies',
        instruction: 'Instructie',
        instructionRevisions: 'Revisies',
        instructionRevision: 'Herziening',
        checklists: 'Controle lijsten',
        checklist: 'Controlelijst',
        checklistItem: 'Lijstitem',
        manageChecklistFields: 'Beheer lijstitems',
        checklistItemDisplayOptions: 'Getoonde artikelen',
        revisionAutoRejectedInfo:
            '[Systeem] Afgewezen - nieuwe revisie gemaakt',
    },
    accessMergeValues: {
        sum: 'Som',
        product: 'Product',
        overwrite: 'Overschrijven',
    },
    aggregates: {
        count: 'Som',
        min: 'Minimum',
        max: 'Maximaal',
    },
    checklists: {
        itemBasic: 'Basis element',
        itemSeparator: 'Scheidingsteken',
        itemSection: 'Sectie',
        publish: 'Publiceren',
        previewCurrentVersion: 'Preview van de huidige versie',
    },
    cluer: {
        groupableHeadersPlaceholder:
            'Sleep een tabelkop om gegevens te groeperen.',
        history: 'Geschiedenis',
        standardObstacle: 'Standaardobstakel',
        customObstacle: 'Aangepast obstakel',
        bhp: 'Veiligheid en gezondheid op het werk',
        returnToMainPage: 'Terug naar hoofdpagina',
        bhpMessage:
            'Als u veiligheidsrisico`s opmerkt, meld deze dan onmiddellijk aan uw directe leidinggevende en gebruik het meldingsformulier onder het publicatiebord.',
        ok: 'OK',
        send: 'Versturen',
        description: 'Beschrijving',
        title: 'Titel',
        realization: 'Uitvoering',
        answer: 'Antwoord',
        pendingSurveys: 'In behandeling zijnde enquêtes',
        surveys: 'Enquêtes',
        systemSurvey: 'Systeemenquête',
        addPage: 'Pagina toevoegen',
        createSurvey: 'Enquête maken',
        addSection: 'Sectie toevoegen',
        addQuestion: 'Vraag toevoegen',
        questionContent: 'Vraaginhoud',
        answerOption: 'Antwoordoptie',
        addQuestionAnswer: 'Antwoordoptie toevoegen',
        surveyName: 'Naam enquête',
        sectionName: 'Sectienaam',
        noSurveyAnswers: 'Geen antwoorden',
        removeSection: 'Sectie verwijderen',
        published: 'Gepubliceerd',
        unpublished: 'Niet gepubliceerd',
        pendingPublication: 'Wachten op publicatie',
        removeQuestion: 'Vraag verwijderen',
        removeAnswer: 'Antwoordoptie verwijderen',
        publish: 'Publiceren',
        unpublish: 'Publicatie intrekken',
        previewResults: 'Resultaten bekijken',
        supervisior: 'Leidinggevende',
        surveyResults: 'Enquêteresultaten',
        surveysEvents: 'Gebeurtenisconfiguratie - enquêtes',
        operatedBy: 'Geëxploiteerd door',
        confirmReadInformations: 'Ik bevestig dat ik de informatie heb gelezen',
        noticeContent: 'Berichtinhoud',
        notice: 'Bericht',
        surveyEventName: 'Gebeurtenisnaam',
        survey: 'Enquête',
        newIssue: 'Nieuw probleem',
        surveyEventConfigurationAlreadyExists:
            'De configuratie voor deze gebeurtenis bestaat al',
        lastComment: 'Laatste opmerking van de verantwoordelijke',
        surveyNotConfigured: 'Enquête niet geconfigureerd',
        fillSurvey: 'Enquête invullen',
        singleSurveyEntries: 'enkele resultaten',
        groupedSurveyEntries: 'Gegroepeerde resultaten',
        publicationId: 'Publicatie-ID',
        publicationDate: 'Datum van publicatie',
        versionId: 'versie-ID',
        answersCount: 'Aantal reacties',
        answers: 'Antwoorden',
        withoutSupervisor: 'Geen begeleider',
        defaultSupervisor: 'Standaard supervisor',
        flowStateFlags: 'Status vlaggen',
        qrActionsSettings: 'Actie-instellingen voor QR-codes',
        loginRequired: 'Vereist inloggen',
        questionContext: 'De context van de vraag',
        currentSurveyVersionPreview:
            'Preview van de huidige versie van de enquête',
        surveyDescription: 'Enquêtebeschrijving',
        sectionDescription: 'Sectiebeschrijving',
    },
    orders: {
        order: 'Volgorde',
        releaseDate: 'Einddatum',
        orderDate: 'besteldatum',
    },
    production: {
        good: 'Aantal goede',
        bad: 'Aantal slechte',
        productionRecords: 'Productieregister',
    },
};
