<template>
    <SignaloAppBar
        search
        v-model:searchOpen="searchOpen"
        :searchFieldCount="searchFieldCount"
    >
        <template #title> {{ $t('staff.staff') }} </template>
        <template #bar>
            <v-btn
                @click="() => loadTable()"
                class="submain-button renew-button"
            >
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>

            <v-menu offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn
                        v-bind="props"
                        color="primary"
                        variant="elevated"
                        class="submain-button renew-button"
                    >
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <!-- <v-list-item @click="onExport()">
                        <v-list-item-title>
                            <v-icon> mdi-file-download </v-icon>
                            <span>
                                {{ $t('common.export') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item
                        @click="() => openDialog()"
                        v-if="$route.meta.canCreate"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon"> mdi-plus </v-icon>
                            <span class="Text Navigation-2">
                                {{ $t('common.add') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="importScheduleDialog = true"
                        v-if="$route.meta.canUpdate"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon"> mdi-file-import-outline </v-icon>
                            <span class="Text Navigation-2">
                                {{ $t('staff.importScheduleFile') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="authStore.isAllowed('staff.schedule', 'read')"
                        @click="
                            $router.push({
                                name: 'staff.all-scheduled-events',
                            })
                        "
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-calendar-month
                            </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('common.availability')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="
                            () =>
                                $openManageTableColumnsvisibilityDialog(
                                    TABLE_NAME
                                )
                        "
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon"> mdi-table-cog </v-icon>
                            <span class="Text Navigation-2">
                                {{ $t('common.manageVisibleColumns') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="authStore.isAllowed('work-time-register', 'read')"
                        @click="
                            () =>
                                $router.push({
                                    name: 'staff_work-time-register',
                                })
                        "
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon"> mdi-clock-time-eight-outline </v-icon>
                            <span class="Text Navigation-2">
                                {{ $t('common.workTimeRegister') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloSearchContainer
            ref="searchContainerComponent"
            v-model:open="searchOpen"
            :config="searchConfig"
            @search="loadTable"
            v-model:fieldCount="searchFieldCount"
            :tableName="TABLE_NAME"
        />
        <SignaloBreadCrumbs />
        <PageLoading v-if="loading" />
        <div v-else>
            <div class="tabs-buttons-container">
                <SignaloTabsToggle
                    :modelValue="currentTab"
                    :buttons="tabsButtons"
                    :defaultTab="currentTab"
                    @update:modelValue="(val) => (currentTab = val)"
                    :mandatory="false"
                />
            </div>
            <EasyDataTable
                buttons-pagination
                :headers="tableHeaders"
                :items="items"
                :loading="loadingTable"
                :empty-message="$t('common.emptyTableMessage')"
                table-class-name="easy-table-style"
                @click-row="onStaffShow"
                :rows-per-page-message="$t('common.rowsPerPage')"
                :rows-of-page-separator-message="$t('common.rowsOfPage')"
                v-model:server-options="serverOptions"
                :server-items-length="serverItemsLength"
                :rows-items="[5, 10, 25, 50, 75, 100]"
                name="staffIndexTable"
            >
                <template #item-photo="item">
                    <div class="image-container" v-if="item.photo?.path">
                        <img
                            :src="`${storageUrl + item.photo?.path}`"
                            class="table-image"
                        />
                    </div>
                    <div class="image-placeholder" v-else>
                        {{ $t('common.noImage') }}
                    </div>
                </template>
                <template #item-name="item">
                    <div class="flex">
                        <NetworkStatusDot
                            :networkStatus="item?.network_status"
                        />
                        <div>
                            {{ item.name ?? '-' }}
                        </div>
                    </div>
                </template>
                <template #item-email="item">
                    {{ item.email ?? '-' }}
                </template>
                <template #item-phone="item">
                    {{ item.phone ?? '-' }}
                </template>
                <template #item-function="item">
                    {{ getUserFunctionName(item.function) }}
                </template>
                <template #item-department="item">
                    {{ item?.department || '-' }}
                </template>
                <template #item-user_criticality="item">
                    {{ getUserCriticality(item.user_criticality) }}
                </template>
                <template #item-status="item">
                    {{ getUserStatusName(item.status) }}
                </template>
                <template #item-account_status="item">
                    {{ getAccountStatusName(item.account_status) }}
                </template>
                <template #item-crew_id="item">
                    {{
                        $getPropertyTranslation(
                            crews?.find((c) => c.id == item.crew_id)?.name
                        )
                    }}
                </template>
                <template #item-operation="item">
                    <SignaloDynamicMenu
                        :rowItem="item"
                        :menuItems="getTableActions(item)"
                    />
                </template>
            </EasyDataTable>
            <SignaloExportDialog
                v-if="exportDialog"
                :open="exportDialog"
                :endpoint="exportEndpoint"
                @close="exportDialog = false"
                :serverOptions="serverOptions"
                :allPagesAllowed="exportAllPagesAllowed"
            />
            <ManageStaffModal
                v-if="userDialog"
                :open="userDialog"
                @close="(shouldRefetch) => closeDialog(shouldRefetch)"
                :user="currentlyEditedUser"
            />
            <ImportScheduleOptionsDialog
                v-if="importScheduleDialog"
                :open="importScheduleDialog"
                @close="importScheduleDialog = false"
            />
            <SignaloConfirmationDialog
                v-if="removeUserDialog"
                :dialogOpened="removeUserDialog"
                @update:dialogOpened="(value) => closeRemoveUserDialog(value)"
            />
            <!--CREDENTIALS Dialog-->
            <SignaloDialog
                v-if="changeUserCredentialsDialog"
                :icon="'mdi-key-variant'"
                :title="$t('staff.changePasswordAndCode')"
                :open="changeUserCredentialsDialog"
                @close="closeChangeUserCredentialsDialog"
                :constHeight="false"
            >
                <template #body>
                    <v-col class="tabs-container-col">
                        <v-row>
                            <v-tabs v-model="tabs" color="primary">
                                <v-tab value="password">
                                    {{ $t('staff.passwordChange') }}
                                </v-tab>
                                <v-tab value="code">
                                    {{ $t('staff.codeChange') }}
                                </v-tab>
                            </v-tabs>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-window
                            v-model="tabs"
                            class="ma-0 password-change-window"
                        >
                            <v-window-item
                                value="password"
                                class="password-change-window-item"
                            >
                                <div
                                    class="password-change-container"
                                    v-if="
                                        newCredentials &&
                                        newCredentials.userId !== undefined &&
                                        newCredentials.userId !== null
                                    "
                                >
                                    <v-form ref="dataForm" v-model="dataValid">
                                        <v-row class="change-credentials-row">
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field
                                                    :label="
                                                        $t('staff.newPassword')
                                                    "
                                                    v-model="
                                                        newCredentials.password
                                                    "
                                                    :rules="passwordRules"
                                                    type="password"
                                                    variant="outlined"
                                                    color="var(--greyscale-100)"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field
                                                    :label="
                                                        $t(
                                                            'staff.confirmNewPassword'
                                                        )
                                                    "
                                                    v-model="
                                                        newCredentials.confirmation
                                                    "
                                                    :rules="
                                                        passwordConfirmationRules
                                                    "
                                                    type="password"
                                                    variant="outlined"
                                                    color="var(--greyscale-100)"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </div>
                            </v-window-item>
                            <v-window-item
                                value="code"
                                class="password-change-window-item"
                            >
                                <div
                                    class="password-change-container"
                                    v-if="
                                        newCredentials &&
                                        newCredentials?.userId !== undefined &&
                                        newCredentials?.userId !== null
                                    "
                                >
                                    <v-form
                                        ref="codeForm"
                                        v-model="codeDataValid"
                                    >
                                        <v-row class="change-credentials-row">
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field
                                                    :label="$t('staff.newCode')"
                                                    v-model="
                                                        newCredentials.code
                                                    "
                                                    :rules="codeRules"
                                                    type="password"
                                                    :error-messages="
                                                        codeAlreadyTakenErrorMessage
                                                    "
                                                    variant="outlined"
                                                    color="var(--greyscale-100)"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field
                                                    :label="
                                                        $t(
                                                            'staff.confirmNewCode'
                                                        )
                                                    "
                                                    v-model="
                                                        newCredentials.codeConfirmation
                                                    "
                                                    :rules="
                                                        codeConfirmationRules
                                                    "
                                                    type="password"
                                                    variant="outlined"
                                                    color="var(--greyscale-100)"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </div>
                            </v-window-item>
                        </v-window>
                    </v-col>
                </template>
                <template #buttons>
                    <div v-if="changeUserCredentialsDialog && tabs === 'code'">
                        <v-btn text @click="closeChangeUserCredentialsDialog">
                            {{ $t('common.cancel') }}
                        </v-btn>
                        <v-btn
                            variant="elevated"
                            @click="changeUserCode"
                            color="primary"
                            :disabled="
                                newCredentials?.code === '' ||
                                newCredentials?.code !==
                                    newCredentials.codeConfirmation
                            "
                        >
                            {{ $t('staff.changeCode') }}
                        </v-btn>
                    </div>
                    <div
                        v-if="
                            changeUserCredentialsDialog && tabs === 'password'
                        "
                    >
                        <v-btn text @click="closeChangeUserCredentialsDialog">
                            {{ $t('common.cancel') }}
                        </v-btn>
                        <v-btn
                            variant="elevated"
                            @click="changeUserCredentials"
                            color="primary"
                            :disabled="
                                newCredentials?.password === '' ||
                                newCredentials?.password !==
                                    newCredentials.confirmation
                            "
                        >
                            {{ $t('staff.changePassword') }}
                        </v-btn>
                    </div>
                </template>
            </SignaloDialog>
            <!--END OF CREDENTIALS Dialog-->

            <!--PREMISSIONS DIALOG-->
            <SignaloAppAccessComponent
                v-if="permissionsDialog"
                :title="$t('staff.editAccess')"
                :open="permissionsDialog"
                @close="closePermissionsDialog"
                mode="user"
                v-model="item"
            />
            <!--END OF PREMISSIONS DIALOG-->

            <SignaloManageStorageAccessDialog
                v-if="item?.id && storageAccessManageDialogOpened"
                :id="item?.id"
                @close="storageAccessManageDialogOpened = false"
            />

            <ManageUserNotificationsDialog
                v-if="userNotificationsDialogOpened"
                :user="currentlyEditedUser"
                @close="onManageUserNotificationsDialogClose"
            />

            <TakeHandoverDialog
                v-if="takeHandoverDialogOpened"
                :user="currentlyEditedUser"
                @close="
                    () => {
                        takeHandoverDialogOpened = false;
                        currentlyEditedUser = null;
                    }
                "
            />
        </div>
    </v-main>
</template>
<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import ManageStaffModal from './ManageStaffModal.vue';
import ImportScheduleOptionsDialog from './ImportScheduleOptionsDialog.vue';
import SignaloManageStorageAccessDialog from '@/components/SignaloManageStorageAccessDialog/SignaloManageStorageAccessDialog.vue';
import ManageUserNotificationsDialog from './ManageUserNotificationsDialog.vue';
import TakeHandoverDialog from '@/components/TakeHandoverDialog.vue';
import { SEARCH_FIELD_TYPES } from 'signalo-vue-search-container';
import NetworkStatusDot from '@/components/NetworkStatusDot.vue';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    components: {
        ManageStaffModal,
        ImportScheduleOptionsDialog,
        SignaloManageStorageAccessDialog,
        ManageUserNotificationsDialog,
        TakeHandoverDialog,
        NetworkStatusDot,
    },
    data() {
        return {
            loading: false,
            loadingTable: false,
            formValid: true,
            items: [],
            item: null,
            userDialog: false,
            currentlyEditedUser: null,
            importScheduleDialog: false,

            storageUrl: this.getFileStorageUrl() + '/',
            selectedImagePath: null,
            removeUserDialog: false,
            userToRemove: null,
            changeUserCredentialsDialog: false,
            newCredentials: null,
            searchOpen: false,
            searchFieldCount: 0,
            permissionsDialog: false,
            dataValid: true,
            codeDataValid: true,
            tabs: 'password',
            codeAlreadyTakenErrorMessage: [],
            dataSaving: false,
            currentTab: null,
            workSchemas: null,
            timezones: null,
            // shiftSchemas: null,
            // shifts: null,
            console: console,
            exportEndpoint: null,
            exportDialog: false,
            TABLE_NAME: 'staffIndexTable',
            serverOptions: this.$getServerOptions('staffIndexTable'),
            serverItemsLength: 0,
            lastSearch: null,
            exportAllPagesAllowed: false,
            storageAccessManageDialogOpened: false,
            userNotificationsDialogOpened: false,
            crews: [],
            takeHandoverDialogOpened: false,
            firstLoadCompleted: false,
        };
    },
    computed: {
        userFunctions() {
            return Object.values(
                this.dataStore.dictionary.group('user_functions')
            );
        },
        departments() {
            return Object.values(
                this.dataStore.dictionary.group('departments')
            );
        },
        statuses() {
            return Object.values(
                this.dataStore.dictionary.group('employee_status')
            );
        },
        passwordConfirmationRules() {
            return [
                (c) =>
                    !c ||
                    c === this.newCredentials.password ||
                    this.$t('errors.passwordsNotMatch'),
            ];
        },
        codeConfirmationRules() {
            return [
                (c) =>
                    !c ||
                    c === this.newCredentials.code ||
                    this.$t('errors.codesNotMatch'),
            ];
        },
        accountStatusOptions() {
            return [
                { value: 'active', label: this.$t('common.active') },
                { value: 'not_activated', label: this.$t('common.notActive') },
                { value: 'disabled', label: this.$t('common.disabled') },
            ];
        },

        tableHeaders() {
            return this.$getPreferredTableColumns(this.TABLE_NAME);
        },
        searchConfig() {
            return {
                id_equals: {
                    type: 'text',
                    label: this.$t('searchConfig.id'),
                    cols: 2,
                },
                name: {
                    type: 'text',
                    label: this.$t('searchConfig.nameSurname'),
                    cols: 2,
                },
                email: {
                    type: 'text',
                    label: this.$t('searchConfig.email'),
                    cols: 2,
                },
                phone: {
                    type: 'text',
                    label: this.$t('searchConfig.phone'),
                    cols: 2,
                },
                account_status: {
                    default: this.currentTab,
                    type: 'hidden',
                    cols: 2,
                },
                supervisor_user_id_equals: {
                    type: 'check',
                    label: this.$t('tpm.withoutSupervisor'),
                    cols: 3,
                    checkedValue: 'null',
                },
                license: {
                    type: 'license',
                    label: this.$t('common.license'),
                    cols: 5,
                },
                license_name: {
                    type: SEARCH_FIELD_TYPES.HIDDEN,
                },
                license_number: {
                    type: SEARCH_FIELD_TYPES.HIDDEN,
                },
                license_group_equals: {
                    type: SEARCH_FIELD_TYPES.HIDDEN,
                },
                license_type_equals: {
                    type: SEARCH_FIELD_TYPES.HIDDEN,
                },
            };
        },
        passwordRules() {
            return [
                (v) =>
                    v?.trim()?.length >= 8 ||
                    this.$t('errors.wrongPasswordLength'),
            ];
        },

        codeRules() {
            return [
                (v) =>
                    v?.trim()?.length >= 5 || this.$t('errors.wrongCodeLength'),
            ];
        },
        tabsButtons() {
            return [
                { value: 'active', label: this.$t('common.active') },
                {
                    value: 'not_activated',
                    label: this.$t('common.waitingForActivation'),
                },
                { value: 'disabled', label: this.$t('common.disabled') },
            ];
        },
    },
    mounted() {
        if (!Object.values(this.$route.query)?.length) {
            this.currentTab = 'active';
        } else if (this.$route.query['account_status']) {
            this.currentTab = this.$route.query['account_status'];
        }
        if (this.currentTab) {
            this.$refs.searchContainerComponent.search.account_status =
                this.currentTab;
        }
        if (this.firstLoadCompleted) {
            this.$refs.searchContainerComponent.searchClick(true);
        }
        this.load();
    },
    watch: {
        changeUserCredentialsDialog(val) {
            if (!val) {
                this.newCredentials = null;
                this.codeAlreadyTakenErrorMessage = [];
                this.tabs = 'password';
            }
        },
        currentTab(val) {
            if (val) {
                this.$refs.searchContainerComponent.search = {
                    ...this.$refs.searchContainerComponent.search,
                    account_status: val,
                };
            } else {
                delete this.$refs.searchContainerComponent.search
                    .account_status;
            }
            if (this.firstLoadCompleted) {
                this.$refs.searchContainerComponent.searchClick(true);
            }
        },
        serverOptions(val) {
            this.authStore.patchTablePreferences('staffIndexTable', {
                default_size: val.rowsPerPage,
            });
            this.loadTable();
        },
    },
    methods: {
        load() {
            this.loading = true;
            Promise.allSettled([
                this.dataStore.dictionary.promise(),
                this.axios.get('global/timezones'),
                this.axios.get('crews'),
            ]).then((res) => {
                this.timezones = res[1]?.value?.data?.items;
                this.crews = res[2]?.value?.data?.items;
                this.loading = false;
            });
        },
        loadTable(search, resetPage) {
            if (this.loadingTable) return;
            this.loadingTable = true;
            if (resetPage) {
                this.serverOptions.page = 1;
            }
            if (search !== null) {
                this.lastSearch = search;
            }

            const config = this.dataStore.equipment_types.makeServerOptions(
                this.serverOptions,
                {
                    ...this.lastSearch,
                    account_status: this.currentTab,
                }
            );
            this.$router.replace({ query: config.params });
            return this.dataStore.users
                .indexRequest(config)
                .then((x) => {
                    if (x.data?.status === 'ok') {
                        this.items = Object.values(x.data.items);
                        this.serverItemsLength = x?.data?.meta?.total;
                    }
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                })
                .finally(() => {
                    this.loadingTable = false;
                    this.firstLoadCompleted = true;
                });
        },
        async openDialog(user = null) {
            if (!user) {
                if (!this.$route.meta.canCreate) return;
                this.currentlyEditedUser = {
                    name: '',
                    function: null,
                    department: null,
                    status: null,
                    email: null,
                    phone: null,
                    // work_schema_id: null,
                    shift_schema_id: null,
                    default_shift_id: null,
                    timezone: this.authStore.tenant.default_timezone,
                };
            } else {
                if (!this.$route.meta.canUpdate) return;
                this.currentlyEditedUser = user;
            }
            this.userDialog = true;
        },
        async closeDialog(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadTable();
                this.helpersStore.snackbarSaved();
            }
            this.currentlyEditedUser = null;
            this.userDialog = false;
        },
        getUserFunctionName(functionId) {
            if (functionId === null || functionId === undefined) return '-';
            return (
                this.userFunctions.find((x) => x.keyname === functionId)
                    ?.value || '-'
            );
        },
        getUserStatusName(statusId) {
            if (statusId === null || statusId === undefined) return '-';
            return (
                this.statuses.find((x) => x.keyname === statusId)?.value || '-'
            );
        },

        openChangeUserCredentialsDialog(item) {
            this.newCredentials = {
                userId: item.id,
                password: '',
                confirmation: '',
                code: '',
                codeConfirmation: '',
                user: item,
            };
            this.changeUserCredentialsDialog = true;
        },
        openRemoveUserDialog(item) {
            this.userToRemove = item;
            this.removeUserDialog = true;
        },
        async closeRemoveUserDialog(shouldRemove) {
            if (shouldRemove) {
                return await this.removeUser();
            }
            this.userToRemove = null;
            this.removeUserDialog = false;
        },
        async impersonateUser(item) {
            try {
                const res = await this.axios.post(
                    `/auth/impersonate/${item.id}`,
                    {},
                    { permitAll: true }
                );
                await this.authStore.logout();
                await this.authStore.login(res.data);

                location.href = '/';
            } catch (err) {
                console.error(err);
            }
        },
        async removeUser() {
            if (!this.userToRemove) return;

            return this.axios
                .delete(`/users/${this.userToRemove.id}`)
                .then(() => {
                    return this.loadTable(
                        this.$refs.searchContainerComponent.search
                    );
                })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('common.removed'),
                        'success'
                    );
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                })
                .finally(() => {
                    this.userToRemove = null;
                    this.removeUserDialog = false;
                });
        },
        closeChangeUserCredentialsDialog() {
            this.changeUserCredentialsDialog = false;
            this.newCredentials = null;
        },
        async changeUserCredentials() {
            if (
                this.newCredentials.password !==
                this.newCredentials.confirmation
            )
                return;

            await this.$refs.dataForm.validate();
            if (!this.dataValid) return;
            this.axios
                .patch(`/users/${this.newCredentials.userId}`, {
                    password: this.newCredentials.password,
                })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('staff.passwordUpdated'),
                        'success'
                    );
                    this.changeUserCredentialsDialog = false;
                    this.newCredentials = null;
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                });
        },
        async changeUserCode() {
            this.codeAlreadyTakenErrorMessage = [];
            if (
                this.newCredentials.code !==
                this.newCredentials.codeConfirmation
            )
                return;
            await this.$refs.codeForm.validate();
            if (!this.codeDataValid) return;
            this.axios
                .patch(`/users/${this.newCredentials.userId}`, {
                    code: this.newCredentials.code,
                })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('staff.codeUpdated'),
                        'success'
                    );
                    this.changeUserCredentialsDialog = false;
                    this.newCredentials = null;
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                    if (
                        e?.response?.status === 422 &&
                        e?.response?.data?.errors?.code[0] ===
                            'The code has already been taken.'
                    ) {
                        this.codeAlreadyTakenErrorMessage = [
                            this.$t('errors.codeAlreadyInUse'),
                        ];
                    }
                });
        },
        getUserShiftName(id) {
            return (
                this.$getPropertyTranslation(
                    this.workSchemas.find((ws) => ws.id === id)?.name
                ) || '-'
            );
        },
        permissionsButtonClicked(item) {
            this.item = item;
            this.permissionsDialog = true;
        },
        closePermissionsDialog() {
            this.permissionsDialog = false;
            this.item = null;
        },
        getAccountStatusName(status) {
            switch (status) {
                case 'not_activated':
                    return this.$t('common.notActive');
                case 'active':
                    return this.$t('common.active');
                case 'disabled':
                    return this.$t('common.disabled');
                default:
                    return '-';
            }
        },
        onExport(id = null) {
            this.exportAllPagesAllowed = !id;
            this.exportEndpoint = 'users';
            if (id !== null) {
                this.exportEndpoint += `/${id}`;
            }
            this.exportDialog = true;
        },
        onStaffShow(staff) {
            this.$router.push({
                name: 'staff.show',
                params: {
                    id: staff.id,
                },
            });
        },
        onGoToUserAvailability(item) {
            this.$router.push({
                name: 'staff.scheduled-events',
                params: {
                    id: item.id,
                },
            });
        },
        getUserCriticality(keyname) {
            return this.$getPropertyTranslation(
                this.dataStore.dictionary
                    .group('user_criticality')
                    .find((uc) => uc.keyname === keyname)?.value || '-'
            );
        },
        licensesButtonClicked(item) {
            this.$router.push({
                name: 'staff.licenses',
                params: {
                    id: item.id,
                },
            });
        },
        openStorageAccessManage(item) {
            this.item = item;
            this.storageAccessManageDialogOpened = true;
        },
        onManageUserNotifications(item) {
            this.currentlyEditedUser = item;
            this.userNotificationsDialogOpened = true;
        },
        async onManageUserNotificationsDialogClose(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadTable();
            }
            this.userNotificationsDialogOpened = false;
            this.currentlyEditedUser = null;
        },
        goToUserStatementsResults(user) {
            this.$router.push({
                name: 'statements_results',
                query: {
                    user_id_equals: user.id,
                    s_id: 'null',
                },
            });
        },
        goToUserStatements(user) {
            this.$router.push({
                name: 'staff.statements',
                params: {
                    id: user.id,
                },
            });
        },
        onTakeHandover(user) {
            this.currentlyEditedUser = user;
            this.takeHandoverDialogOpened = true;
        },
        goToTags(item) {
            this.$router.push({
                name: 'staff.tags',
                params: {
                    id: item.id,
                },
            });
        },
        goToInstructions(item) {
            this.$router.push({
                name: 'staff.instructions',
                params: {
                    id: item.id,
                },
            });
        },
        getTableActions(item) {
            return [
                {
                    icon: 'mdi-eye',
                    label: this.$t('common.preview'),
                    action: this.onStaffShow,
                },
                {
                    label: this.$t('common.availability'),
                    icon: 'mdi-calendar-month',
                    action: this.onGoToUserAvailability,
                    access: [
                        this.authStore.isAllowed('staff.schedule', 'read'),
                    ],
                },
                {
                    icon: 'mdi-key-variant',
                    label: this.$t('staff.changePasswordAndCode'),
                    action: this.openChangeUserCredentialsDialog,
                    access: [this.$route.meta.canUpdate],
                },
                {
                    icon: 'mdi-card-account-details-outline',
                    label: this.$t('staff.goToLicenses'),
                    action: this.licensesButtonClicked,
                    access: [this.$route.meta.canUpdate],
                },
                {
                    icon: 'mdi-account-key',
                    label: this.$t('staff.editAccess'),
                    action: this.permissionsButtonClicked,
                    access: [this.$route.meta.canUpdate],
                },
                {
                    icon: 'mdi-key-chain',
                    label: this.$t('common.manageStorageAccess'),
                    action: this.openStorageAccessManage,
                    access: [this.$route.meta.canUpdate],
                },
                {
                    label: this.$t('common.notificationsManagement'),
                    icon: 'mdi-bell-cog',
                    action: this.onManageUserNotifications,
                    // access: [
                    //     this.authStore.isAllowed('staff.schedule', 'read'), //TODO jaki zaccess
                    // ],
                },
                // {
                //     icon: 'mdi-file-document-check-outline',
                //     label: this.$t('common.statementsResults'),
                //     action: this.goToUserStatementsResults,
                //     access: [this.authStore.isAllowed('statements', 'read')], //TODO access zmienić na statements
                // },
                {
                    icon: 'mdi-text-box-multiple-outline',
                    label: this.$t('common.statements'),
                    action: this.goToUserStatements,
                    access: [this.authStore.isAllowed('staff', 'read')],
                },
                {
                    icon: 'mdi-tshirt-crew-outline',
                    label: this.$t('common.takeHandover'),
                    action: this.onTakeHandover,
                    access: [this.authStore.isAllowed('parts', 'update')],
                },
                {
                    icon: 'mdi-pencil',
                    label: this.$t('common.edit'),
                    action: this.openDialog,
                    access: [this.$route.meta.canUpdate],
                },
                // {
                //     icon: 'mdi-file-download',
                //     label: this. $t('common.export'),
                //     action: this.onExport,
                //     access: [this.$route.meta.canRead]
                // },
                {
                    icon: 'mdi-card',
                    label: this.$t('common.tags'),
                    action: this.goToTags,
                    access: [this.$route.meta.canUpdate],
                },
                {
                    icon: 'mdi-list-status',
                    label: this.$t('tpm.instructions'),
                    action: this.goToInstructions,
                    access: [this.$route.meta.canUpdate],
                },
                {
                    icon: 'mdi-account-switch',
                    label: this.$t('common.loginAsThisUser'),
                    action: this.impersonateUser,
                    access: [
                        this.authStore?.user?.superuser &&
                            item?.id !== this.authStore?.user?.id,
                    ],
                },
                {
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    action: this.openRemoveUserDialog,
                    access: [this.$route.meta.canDelete],
                },
                // {
                //     label: this.$t('common.availability'),
                //     icon: 'mdi-calendar-month',
                //     action: this.onGoToUserAvailability,
                //     access: [
                //         this.authStore.isAllowed('staff.schedule', 'read'),
                //     ],
                // },
            ];
        },
    },
};
</script>

<style>
.image-container,
.image-placeholder {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--easy-table-row-border);
}

.image-placeholder {
    border-radius: 0;
    cursor: unset;
}

.table-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.tabs-container-col {
    flex-grow: 0 !important;
}

.password-change-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.password-change-window,
.password-change-window-item {
    height: 100%;
}

.change-password-card-actions {
    margin-top: auto;
}
.tabs-buttons-container {
    padding: 8px;
    padding-top: 0;
}
.change-credentials-row {
    padding-top: 32px;
}
</style>
