<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.configuration') }} </template>
        <template #bar>
            <v-btn
                @click="() => openDialog()"
                v-if="$route.meta.canCreate"
                class="add-button"
                color="primary"
                variant="elevated"
            >
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>

    <v-main>
        <SignaloBreadCrumbs />
        <EasyDataTable buttons-pagination
            :headers="tableHeaders"
            :items="items"
            :empty-message="$t('common.emptyTableMessage')"
            :loading="loading"
            table-class-name="easy-table-style"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
        >
            <template #item-name="item">
                {{ $getPropertyTranslation(item.name) || item.name }}
            </template>
            <template #item-enabled="item">
                <v-icon v-if="item?.enabled">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <template #item-operation="item">
                <div class="actions-column">
                    <v-menu offset-y v-if="$route.meta.canUpdate || $route.meta.canDelete">
                        <template v-slot:activator="{ props }">
                            <v-btn
                                color="primary"
                                v-bind="props"
                                variant="outlined"
                                icon
                                class="table-action-icon-button"
                            >
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="onEdit(item)"
                                v-if="$route.meta.canUpdate"
                            >
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-pencil
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.edit')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click="onRemove(item)"
                                v-if="$route.meta.canDelete"
                            >
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-delete
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.remove')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </EasyDataTable>
    </v-main>

    <ExternalSystemManage
        v-if="manageDialog"
        v-model:open="manageDialog"
        :mode="dialogMode"
        :editedItem="item"
        @closeDialog="closeDialog"
    />

    <SignaloConfirmationDialog
        :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => remove(value)"
    />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import ExternalSystemManage from './ExternalSystemManage.vue';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { dataStore, helpersStore };
    },
    components: {
        ExternalSystemManage,
    },
    data() {
        return {
            loading: false,
            item: null,
            manageDialog: false,
            removeDialog: false,
            searchOpen: false,
            searchFieldCount: 0,
            items: [],
        };
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                },

                {
                    text: this.$t('common.active'),
                    value: 'enabled',
                },
                // {
                //     text: this.$t('common.key'),
                //     value: 'key',
                // },
                {
                    text: this.$t('common.timezone'),
                    value: 'timezone',
                },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
    },
    mounted() {
        this.loading = true;
        Promise.allSettled([this.loadTable()]).finally(() => {
            this.loading = false;
        });
    },
    methods: {
        openDialog() {
            this.item = {};
            this.manageDialog = true;
        },
        onEdit(item) {
            this.item = item;
            this.manageDialog = true;
        },
        onRemove(item) {
            this.item = item;
            this.removeDialog = true;
        },
        closeDialog(shouldRefetchData) {
            if (shouldRefetchData) {
                this.loadTable().finally(() => {
                    this.item = null;
                    this.manageDialog = false;
                });

                return;
            }
            this.item = null;
            this.manageDialog = false;
        },
        closePermissionsDialog() {
            this.permissionsDialog = false;
            this.item = null;
        },
        loadTable() {
            this.loading = true;
            return this.axios
                .get('systems')
                .then((x) => {
                    if (x.data?.status === 'ok') {
                        this.items = Object.values(x.data.items);
                    }
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async remove(shouldRemove) {
            if (shouldRemove) {
                try {
                    await this.axios.delete(`systems/${this.item.id}`);
                    await this.loadTable();
                    this.helpersStore.snackbarSaved();
                } catch (err) {
                    if (err?.response.status === 401) return;
                }
            }

            this.item = null;
            this.removeDialog = false;
        },
    },
};
</script>
