<template>
    <div class="survey-question">
        <div class="Text Label-Text">
            <span class="Required-Asterisk" v-if="field?.required">*</span>
            {{ $getPropertyTranslation(field?.name) }}
            <span v-if="field?.context_parameter_id"
                >({{ $t('issues.parameter') }}:&nbsp;{{
                    getParameterName(field?.context_parameter_id)
                }})</span
            >
        </div>
    </div>
    <SurveyGroupedAnswer :field="field" v-if="groupedPreview" />
    <SurveyAnswer :field="field" @answer="onAnswer" v-else :userContext="userContext"/>
</template>

<script>
import SurveyAnswer from './SurveyAnswers/SurveyAnswer.vue';
import SurveyGroupedAnswer from './SurveyGroupedAnswers/SurveyGroupedAnswer.vue';

export default {
    props: ['field', 'groupedPreview', 'parameters', 'userContext'],
    emits: ['answer'],
    components: { SurveyAnswer, SurveyGroupedAnswer },
    methods: {
        onAnswer(val) {
            this.$emit('answer', val);
        },
        getParameterName(parameterId) {
            const param = this.parameters?.find((p) => p.id === parameterId);

            if (!param) return '';
            return this.$getPropertyTranslation(param?.name);
        },
    },
};
</script>

<style>
.survey-question {
    padding-top: 16px;
    margin-bottom: 16px;
}
</style>
