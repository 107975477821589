<template>
    <v-text-field
        v-model="value"
        @click:control="controlClicked"
        @click:clear="clearClicked"
        clearable
        clear-icon="mdi-close-circle-outline"
        variant="outlined"
        :label="$t('cluer.answer')"
        type="number"
        :rules="rules"
        :disabled="disabled"
    >
    </v-text-field>
</template>

<script>
export default {
    props: ['field', 'disabled'],
    emits: ['answer'],
    mounted() {
        this.createRules();
    },
    data() {
        return {
            value: this.field?.answer || null,
            rules: []
        };
    },
    watch: {
        value(val) {
            this.$emit('answer', val);
        }
    },
    methods: {
        createRules() {
            if (Number.isInteger(this.field.validators?.max)) {
                this.rules.push((v) => v <= this.field.validators.max || this.$t('errors.max') + ` ${this.field.validators.max}`)
            }
            if (Number.isInteger(this.field.validators?.min)) {
                this.rules.push((v) => v >= this.field.validators.min || this.$t('errors.min') + ` ${this.field.validators.min}`)
            }
        }
    }
};
</script>
