<template>
    <SignaloDialog
        :open="true"
        :title="$t('issues.issue')"
        @close="$emit('close', false)"
        @save="saveClicked"
        :constHeight="true"
        :loading="saveLoading"
        :disableClose="disableClose"
    >
        <template #body>
            <v-card-text>
                <NewIssueForm
                    ref="newIssueFormRef"
                    @setLoading="onSetLoading"
                    @update:open="(val) => $emit('update:open', val)"
                    @item:created="(val) => $emit('item:created', val)"
                    @response="res => $emit('response', res)"
                />
            </v-card-text>
        </template>
    </SignaloDialog>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

import * as AUTH_STORE from 'signalo-vue-auth-store';
import NewIssueForm from './NewIssueForm.vue';

export default {
    props: ['open', 'editedItem'],
    emits: ['close'],
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: false,
            saveLoading: false,
            dataValid: false,
            item: {},
            categoryParameters: [],
            parameters: [],
            tab: 'parameters',
            paramsError: null,
        };
    },
    mounted() {
        this.dataStore.categories.promise();
    },
    methods: {
        onSetLoading(val) {
            this.saveLoading = val;
        },
        async saveClicked() {
            await this.$refs.newIssueFormRef.saveClicked();
        },
    },
    components: { NewIssueForm },
};
</script>

<style scoped>
.new-issue-tabs-content-row .v-window {
    width: 100%;
}
.new-issue-tabs-content-row {
    padding-top: 8px;
}
</style>
