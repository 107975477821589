<template>
    <SignaloAppBar>
        <template #title>
            {{ $t('navDrawer.notifications') }}
        </template>
        <template #bar>
            <v-btn
                id="refresh-notifications-button"
                @click="() => loadNotifications()"
                class="submain-button renew-button"
            >
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-btn
                v-bind="props"
                color="primary"
                variant="elevated"
                class="submain-button renew-button"
                @click="() => markAllAsRead()"
            >
                <v-icon> mdi-eye </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.markAllAsRead') }}
                </span>
            </v-btn>
            <!-- <v-menu offset-y v-if="notifications?.length">
                <template v-slot:activator="{ props }">
                    <v-btn
                        v-bind="props"
                        color="primary"
                        variant="elevated"
                        class="submain-button renew-button"
                    >
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="markAllAsRead">
                        <v-list-item-title>
                            <v-icon> mdi-eye </v-icon>
                            <span class="icon-button-label">
                                {{ $t('common.markAllAsRead') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu> -->
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <PageLoading v-if="isLoading" />
        <EasyDataTable buttons-pagination
            v-else
            :items="notifications"
            :headers="tableHeaders"
            :loading="isLoading"
            :empty-message="$t('common.emptyTableMessage')"
            :table-class-name="
                helpersStore.isMobileView
                    ? 'easy-table-style notifications-table'
                    : 'easy-table-style'
            "
            :rows-per-page="notificationsSize"
            hide-footer
            :body-row-class-name="
                (item) => (item?.read_at ? 'read-row' : 'not-read-row')
            "
            @click-row="navigateNotificationOrigin"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
        >
            <template #item-title_description="item">
                <div class="title-description-container">
                    <div class="title">
                        {{ item?.data?.params?.title }}
                    </div>
                    <div class="description">
                        {{ item?.data?.params?.status }}
                    </div>
                </div>
            </template>
            <template #item-date="item">
                <div class="date-container" v-if="item?.created_at">
                    <div class="date">
                        <span v-if="item?.created_at?.date">
                            {{
                                new Date(
                                    item.created_at.date
                                ).toLocaleDateString($i18n.locale, {
                                    timeZone: authStore.tenant.default_timezone,
                                })
                            }}
                        </span>
                        <span v-else>
                            {{
                                new Date(item.created_at).toLocaleDateString(
                                    $i18n.locale,
                                    {
                                        timeZone:
                                            authStore.tenant.default_timezone,
                                    }
                                )
                            }}
                        </span>
                    </div>
                    <div class="time">
                        <span v-if="item?.created_at?.date">
                            {{
                                new Date(
                                    item.created_at.date
                                ).toLocaleTimeString($i18n.locale, {
                                    timeZone: authStore.tenant.default_timezone,
                                })
                            }}
                        </span>
                        <span v-else>
                            {{
                                new Date(item.created_at).toLocaleTimeString(
                                    $i18n.locale,
                                    {
                                        timeZone:
                                            authStore.tenant.default_timezone,
                                    }
                                )
                            }}
                        </span>
                    </div>
                </div>
            </template>
            <template #item-status="item">
                <div
                    class="icon-and-color-container"
                    v-if="item?.data?.params?.category_id !== undefined"
                >
                    <div
                        class="icon"
                        v-if="
                            dataStore.categories.getById(
                                item?.data?.params?.category_id
                            )?.icon
                        "
                    >
                        <v-icon>
                            {{
                                dataStore.categories.getById(
                                    item?.data?.params?.category_id
                                )?.icon
                            }}</v-icon
                        >
                    </div>
                    <div class="color">
                        <div
                            class="color-icon"
                            :style="`background: ${
                                dataStore.categories.getById(
                                    item?.data?.params?.category_id
                                )?.color
                            }`"
                        ></div>
                    </div>
                </div>
                <div
                    class="category-name"
                    v-if="item?.data?.params?.category_id !== undefined"
                >
                    {{
                        $getPropertyTranslation(
                            dataStore.categories.getById(
                                item?.data?.params?.category_id
                            )?.name
                        )
                    }}
                </div>
            </template>
            <template #item-criticality="item">
                <div
                    class="criticality-container"
                    v-if="item?.data?.params?.criticality"
                >
                    <v-icon class="criticality-icon">mdi-exclamation</v-icon>
                    <div class="criticality-keyname">
                        {{ item?.data?.params?.criticality }}
                    </div>
                </div>
            </template>
            <template #item-read="item">
                <div
                    class="read-container"
                    @click="($event) => toggleRead(item, $event)"
                >
                    <v-switch v-model="item.read" color="primary"></v-switch>
                </div>
            </template>
        </EasyDataTable>
        <div
            :class="
                helpersStore.isMobileView
                    ? 'load-more-button-container load-more-button-container-mobile'
                    : 'load-more-button-container'
            "
            v-if="moreNotificationsAvailable"
        >
            <v-btn
                class="load-more-button"
                color="primary"
                @click="loadMore"
                :loading="isLoading"
            >
                <v-icon>mdi-autorenew</v-icon>
                {{ $t('common.showRest') }}
                ({{ notificationsLeft }})
            </v-btn>
        </div>
    </v-main>
</template>

<script>
import PageLoading from '@/components/global/PageLoading.vue';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            isLoading: false,
            moreNotificationsAvailable: true,
            notifications: [],
            currentSize: 0,
            notificationsLeft: 0,
            notificationsChunkSize: 10,
            notificationsSize: 10,
        };
    },
    mounted() {
        this.loadNotifications();
    },
    methods: {
        async loadNotifications() {
            this.isLoading = true;
            try {
                const notificationsData = await this.axios.get(
                    'notifications',
                    {
                        params: {
                            limit: this.notificationsSize,
                        },
                    }
                );
                this.notifications = notificationsData.data.data.map((n) => {
                    return {
                        ...n,
                        read: !!n.read_at,
                    };
                });
                this.currentSize = notificationsData.data.size;
                this.notificationsLeft =
                    notificationsData.data.all - notificationsData.data.size;
                this.moreNotificationsAvailable = this.notificationsLeft > 0;
                this.authStore.setUnreadNotifications(
                    notificationsData?.data.unread
                );
                this.isLoading = false;
                if (this.notificationsSize > this.notificationsChunkSize) {
                    setTimeout(() => {
                        window.scrollTo(0, document.body.scrollHeight);
                    }, 150);
                }
            } catch (err) {
                if (err?.response?.status === 401) return;
                this.isLoading = false;
            }
            this.isLoading = false;
        },
        async loadMore() {
            this.notificationsSize =
                this.currentSize + this.notificationsChunkSize;
            await this.loadNotifications();
        },
        async toggleRead(item, event) {
            event.stopPropagation();
            this.isLoading = true;
            try {
                if (item?.read_at) {
                    await this.axios.put(`notifications/${item.id}/markUnread`);
                } else {
                    await this.axios.put(`notifications/${item.id}/markRead`);
                }
                await this.loadNotifications();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.isLoading = false;
        },
        async markAllAsRead() {
            this.isLoading = true;
            try {
                await this.axios.post('notifications/markAllRead');
                await this.loadNotifications();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
                this.helpersStore.snackbarError(err);
            }
            this.isLoading = false;
        },
        async navigateNotificationOrigin(val) {
            if (!val?.read) {
                try {
                    await this.axios.put(`notifications/${val.id}/markRead`);
                    await this.loadNotifications();
                } catch (err) {
                    console.error(err);
                    return;
                }
            }
            switch (val.data.params.origin) {
                case 'MyIssue':
                    this.$router.push({
                        name: 'my_issues.show',
                        params: {
                            id: val.data.params.id,
                        },
                    });
                    return;
                case 'Issue':
                    this.$router.push({
                        name: 'issues.show',
                        params: {
                            id: val.data.params.id,
                        },
                    });
                    return;
                case 'IssueEmployee':
                    this.$router.push({
                        name: 'issues.show',
                        params: {
                            id: val.data.params.issue_id,
                        },
                    });
                    return;
                case 'Machine':
                    this.$router.push({
                        name: 'machines.show',
                        params: {
                            id: val.data.params.id,
                        },
                    });
                    return;
                case 'Tool':
                    this.$router.push({
                        name: 'tools.show',
                        params: {
                            id: val.data.params.id,
                        },
                    });
                    return;
                case 'Processes':
                    this.$router.push({
                        name: 'jobStatusses.show',
                        params: {
                            id: Number(val.data.params.job_status_id),
                        },
                    });
                    return;
                case 'Part':
                    this.$router.push({
                        name: 'parts.show',
                        params: {
                            id: val.data.params?.part_id || val.data.params.id,
                        },
                    });
                    return;
                case 'Document':
                    window.open(val.data?.url, '_blank');
                    return;
                // TODO bo nie mamy users.show
                case 'User':
                    this.$router.push({
                        name: 'issues.show',
                        params: {
                            id: val.data.params.id,
                        },
                    });
                    return;
                case 'InstructionRevision':
                    if (
                        this.authStore.isAllowed(
                            'settings.instructions.preview',
                            'read'
                        )
                    ) {
                        window.location.href = val?.data?.url;
                    }
                    break;

                default:
                    break;
            }
        },
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.titleAndDescription'),
                    value: 'title_description',
                },
                {
                    text: this.$t('tableHeaders.notificationDate'),
                    value: 'date',
                },
                // {
                //     text: this.$t("tableHeaders.status"),
                //     value: "status",
                // },
                // {
                //     text: this.$t("tableHeaders.criticality"),
                //     value: "criticality",
                // },
                {
                    text: this.$t('tableHeaders.read'),
                    value: 'read',
                },
            ];
        },
    },
    components: { PageLoading },
};
</script>
<style>
.load-more-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}
.load-more-button-container-mobile {
    position: fixed;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
}
.date-container .date {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: var(--ui-text-text-01);
}
.date-container .time {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: var(--ui-text-text-02);
}
.title-description-container .title {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: var(--ui-text-text-01);
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on;
}
.icon-and-color-container {
    display: flex;
    align-items: center;
}
.color-icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-left: 6px;
}
.criticality-container .criticality-icon {
    font-weight: bold;
}

.criticality-container {
    display: flex;
    align-items: center;
    color: var(--ui-text-text-01);
}
.criticality-container .criticality-keyname {
    font-weight: 600;
    font-size: 16px;
}
.read-container {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.read-container .read-message {
    margin-left: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--ui-text-text-01);
}

.read-container .read-icon {
    color: var(--primary-main);
}
.read-container .not-read-icon {
    color: var(--ui-icon-icon-deactivated);
}
.read-row td:first-of-type {
    border-left: 4px solid transparent !important;
}

.not-read-row td:first-of-type {
    border-left: 4px solid var(--primary-main) !important;
}

.not-read-row td {
    background-color: var(--primary-5) !important;
}

.notifications-table {
    margin-bottom: 64px !important;
}
</style>
