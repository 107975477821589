<template>
    <div
        style="
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: center;
            height: 100%;
        "
    >
        <v-progress-circular indeterminate color="primary" style="margin: auto">
        </v-progress-circular>
    </div>
</template>
