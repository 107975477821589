<template>
    <slot
        :text="$getPropertyTranslation(selected?.name) ?? ''"
        :onClick="controlClicked"
        :onClear="clearClicked"
        v-if="items?.length"
    >
        <v-text-field
            :modelValue="$getPropertyTranslation(selected?.name) ?? ''"
            @click:control="controlClicked"
            @click:clear="clearClicked"
            clearable
            clear-icon="mdi-close-circle-outline"
            readonly
            variant="outlined"
            hide-details
            :placeholder="placeholder ?? $t('cluer.questionContext')"
            :label="selected ? (placeholder ?? $t('cluer.questionContext')) : ''"
        >
        </v-text-field>
    </slot>

    <SignaloDialog
        :open="dialog"
        :title="$t('common.parameters')"
        @close="closeDialog"
        :constHeight="false"
    >
        <template #body>
            <v-card-text>
                <EasyDataTable
                    :items="items"
                    :headers="tableHeaders"
                    :loading="loading"
                    :empty-message="$t('common.emptyTableMessage')"
                    table-class-name="easy-table-style user-picker-table"
                    :rows-items="[5, 10, 25, 50, 75, 100]"
                    :rows-per-page="5"
                    :rows-per-page-message="$t('common.rowsPerPage')"
                    :rows-of-page-separator-message="$t('common.rowsOfPage')"
                    v-model:server-options="serverOptions"
                    :server-items-length="serverItemsLength"
                    @click-row="select"
                >
                    <template #item-name="item">
                        {{ $getPropertyTranslation(item.name) }}
                    </template>
                    <template #item-type="item">
                        {{
                            $getPropertyTranslation(
                                parametersTypes.find(
                                    (t) => t.value === item.type
                                )?.label
                            )
                        }}
                    </template>
                </EasyDataTable>
            </v-card-text>
        </template>
    </SignaloDialog>
</template>

<script>
// import { PARAMETERS_TYPES } from '../SurveyParameters/parameterTypes';
import AVAILABLE_PARAMETER_TYPES from '@/CONFIG/AVAILABLE_PARAMETER_TYPES';
export default {
    name: 'SurveyQuestionContextPicker',
    emits: ['update:modelValue'],
    props: ['surveyId', 'modelValue'],
    data() {
        return {
            serverOptions: {
                page: 1,
                rowsPerPage: 5,
                sortBy: 'id',
                sortType: 'asc',
                query: '',
            },
            serverItemsLength: 0,
            dialog: false,
            loading: false,
            items: [],
        };
    },
    mounted() {
        this.selected = this.modelValue;
        this.loadData();
    },
    methods: {
        async loadData() {
            if(!this.surveyId) return;
            try {
                const surveyParameters = await this.axios.get(
                    `quizzes/${this.surveyId}/parameters`
                );
                this.items = surveyParameters?.data?.items;
                if (this.modelValue && Number.isInteger(this.modelValue)) {
                    this.selected = this.items.find(p => p.id === this.modelValue);
                }
            } catch (err) {
                console.error(err);
            }
        },
        controlClicked() {
            console.log("controlClicked");
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        clearClicked() {
            this.selected = null;
            this.$emit('update:modelValue', null);
        },
        select(item) {
            this.selected = item;
            if (this.returnObject) {
                this.$emit('update:modelValue', item);
            } else {
                this.$emit('update:modelValue', item.id);
            }
            this.closeDialog();
        },
    },
    computed: {
        parametersTypes() {
            return Object.values(AVAILABLE_PARAMETER_TYPES);
        },
        tableHeaders() {
            return [
                { text: this.$t('tableHeaders.name'), value: 'name' },
                { text: this.$t('tableHeaders.type'), value: 'type' },
            ];
        },
    },
};
</script>
