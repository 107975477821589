<template>
    <v-app>
        <div class="maintenance-container">
            <div>
                <div class="Text HeadingH1-6472 app-version-header">
                    {{ $t('common.appVersionMismatch') }}:<span
                        v-if="version"
                        class="app-current-version"
                        ><br />{{ version }}</span
                    >
                </div>
                <div class="Text HeadingH8-2024">
                    {{ $t('common.appVersionMismatchMessage') }}
                </div>
            </div>

            <v-icon class="maintenance-icon">mdi-code-not-equal</v-icon>
            <div class="Text HeadingH3-4860">
                {{ $t('common.appWillRefreshIn') }}:&nbsp;
                {{ redirectTimeout }}s
            </div>
        </div>
    </v-app>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
const APP_VERSION = process.env.VUE_APP_VERSION;
export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    data() {
        return {
            redirectTimeout: 5,
            intervalId: null,
            version: APP_VERSION,
        };
    },
    mounted() {
        this.intervalId = setInterval(() => {
            this.redirectTimeout--;
            if (this.redirectTimeout < 1) {
                clearInterval(this.intervalId);
                this.redirectTimeout = 5;
                this.$clearWebviewCacheAndForceReloadApp();
            }
        }, 1000);
    },
    unmounted() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
};
</script>

<style>
.maintenance-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}
.maintenance-icon {
    font-size: 16rem !important;
    color: var(--ui-text-text-02);
}

.Text {
    flex-grow: 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.64px;
    text-align: center;
    color: var(--ui-text-text-02);
}

.HeadingH1-6472 {
    font-family: Inter;
    font-size: 2.25rem !important;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.64px;
}

.HeadingH3-4860 {
    font-family: Inter;
    font-size: 1.5rem !important;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.48px;
}
.HeadingH8-2024 {
    font-family: Inter;
    font-size: 1.25rem !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.4px;
}

.app-current-version {
    font-weight: bold;
    display: block;
    font-size: 1rem;
}

.app-version-header {
    font-size: 1.5rem !important;
    margin-bottom: 16px;
}
</style>
