<template>
    <v-radio-group v-if="field?.answers?.length" v-model="selected">
        <v-radio
            v-for="item in [...field.answers].sort((a,b) => a.order - b.order)"
            :label="$getPropertyTranslation(item.name) + ((disabled && item.points) ? ` | (${$t('common.points')}: ${item.points})` : '')"
            :value="item.id"
            :key="item.id"
            color="primary"
            hide-details
            single-line
            :disabled="disabled"
        ></v-radio>
    </v-radio-group>
    <div v-else class="error-message">{{ $t('cluer.noSurveyAnswers') }}</div>
</template>

<script>
export default {
    props: ['field', 'disabled'],
    emits: ['answer'],
    data() {
        return {
            selected: this.field?.answer,
        };
    },
    watch: {
        selected(val) {
            this.$emit('answer', val);
        },
    },
};
</script>
