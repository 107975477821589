<template>
    <v-card class="x-padding-0">
        <template v-slot:title>
            <v-toolbar class="preview-header">
                <v-icon class="preview-header-icon">mdi-account-group</v-icon>
                {{ $t('issues.assignedEmployees') }}
                <SignaloUserPicker
                    class="mb-3"
                    @update:modelValue="userSelected"
                    v-slot="props"
                    return-object
                    :filteredItems="modelValue?.employees?.map((e) => e.user_id)"
                    :ormWithcountEnabled="false"
                >
                    <SignaloDynamicMenu
                        :menuItems="[
                            {
                                icon: 'mdi-plus',
                                label: $t('common.add'),
                                action: props.onClick,
                                access: [authStore.isAllowed('issues.employees', 'create')]
                            },
                            // {
                            //     icon: 'mdi-chart-gantt',
                            //     label: $t('common.ganttChart'),
                            //     action: this.showIssueChart,
                            //     access: [modelValue.employees.length > 0],
                            // },
                        ]"
                        style="margin-left: auto"
                    />
                </SignaloUserPicker>
            </v-toolbar>
        </template>
        <SignaloStepper />

        <EasyDataTable buttons-pagination
            :items="modelValue?.employees || []"
            :headers="tableHeaders"
            :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style equipment-table"
            style="margin: 0"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
        >
            <template #item-id="item">
                {{ item.user.id }}
            </template>
            <template #item-name="item">
                {{ item.user.name }}
            </template>
            <template #item-received_at="item">
                {{ item.received_at || '-' }}
            </template>
            <template #item-started_at="item">
                {{ item.started_at || '-' }}
            </template>
            <template #item-arrived_at="item">
                {{ item.arrived_at || '-' }}
            </template>
            <template #item-finished_at="item">
                {{ item.finished_at || '-' }}
            </template>
            <template #item-comment="item">
                <span v-if="!item.comment || item.comment.length == 0">-</span>
                <span v-else-if="item.comment.length < 10">{{
                    item.comment
                }}</span>
                <v-btn
                    v-else
                    size="x-small"
                    icon
                    @click="showCommentClicked(item)"
                >
                    <v-icon> mdi-comment-text </v-icon>
                </v-btn>
            </template>
            <template #item-operation="item">
                <!-- <v-tooltip
                    :text="$t('common.remove')"
                    open-on-click
                    open-on-focus
                >
                    <template v-slot:activator="{ props }"> 
                        <v-btn
                            v-bind="props"
                            color="warning"
                            @click="removeEmployeeClicked(item)"
                            icon
                            class="table-action-icon-button"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn> 

                        <v-btn
                                    v-bind="props"
                                    color="primary"
                                    @click="takeIssue(item)"
                                    icon
                                    class="table-action-icon-button"
                                >
                                    <v-icon>mdi-account-arrow-left</v-icon>
                                </v-btn>
                    </template>
                </v-tooltip> -->
                <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="primary"
                            v-bind="props"
                            variant="outlined"
                            icon
                            class="table-action-icon-button"
                        >
                            <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            @click="takeIssue(item)"
                            v-if="
                                !item?.started_at &&
                                (authStore?.isTerminal ||
                                    item?.user_id === authStore.user.id)
                            "
                        >
                            <v-list-item-title class="menu-action">
                                <v-icon class="operation-icon">
                                    mdi-account-arrow-left
                                </v-icon>
                                <span class="Text Navigation-2">{{
                                    $t(
                                        'dashboard.components.myIssues.takeIssueBtn'
                                    )
                                }}</span>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click.stop="arriveIssue(item.issue)"
                            v-if="
                                !item?.arrived_at &&
                                item?.started_at &&
                                (authStore?.isTerminal ||
                                    item?.user_id === authStore.user.id)
                            "
                        >
                            <v-list-item-title class="menu-action">
                                <v-icon class="operation-icon">
                                    mdi-account-plus
                                </v-icon>
                                <span class="Text Navigation-2">
                                    {{
                                        $t(
                                            'dashboard.components.myIssues.arriveIssueBtn'
                                        )
                                    }}
                                </span>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click.stop="finishIssue(item.issue)"
                            v-if="
                                !item?.finished_at &&
                                item?.arrived_at &&
                                (authStore?.isTerminal ||
                                    item?.user_id === authStore.user.id)
                            "
                        >
                            <v-list-item-title class="menu-action">
                                <v-icon class="operation-icon">
                                    mdi-check
                                </v-icon>
                                <span class="Text Navigation-2">
                                    {{
                                        $t(
                                            'dashboard.components.myIssues.finishIssueBtn'
                                        )
                                    }}
                                </span>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="removeEmployeeClicked(item)">
                            <v-list-item-title class="menu-action">
                                <v-icon class="operation-icon">
                                    mdi-delete
                                </v-icon>
                                <span class="Text Navigation-2">{{
                                    $t('common.remove')
                                }}</span>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </EasyDataTable>
    </v-card>

    <SignaloConfirmationDialog
        :dialogOpened="removeDialog"
        :text="$t('common.warning')"
        :message="$t('issues.confirmUnassignEmployeeWhoTakenIssue')"
        @update:dialogOpened="updateDialog"
    />
    <!-- <GanttChart
        v-if="issueChartDialog"
        :open="issueChartDialog"
        :issue="issueChartData"
        @close="issueChartDialogClose"
    /> -->
    <SignaloCommentDialog v-model="showCommentText" v-model:open="showCommentOpen" />
    <IssueFinishDialog
        v-model:open="finishDialogOpen"
        :title="$t('dashboard.components.myIssues.finishIssueBtn')"
        @reload="$emit('reload')"
        :issueId="modelValue.id"
    />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store'; 

import * as AUTH_STORE from 'signalo-vue-auth-store'
// import GanttChart from '@/components/GanttChart.vue';
import IssueFinishDialog from '@/components/me/IssueFinishDialog.vue';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    components: {
    IssueFinishDialog
},
    props: ['modelValue'],
    emits: ['reload'],
    data() {
        return {
            removeDialog: false,
            removeItem: null,
            issueChartDialog: false,
            issueChartData: this.modelValue,

            showCommentOpen: false,
            showCommentText: null,
            finishDialogOpen: false
        };
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.id'),
                    value: 'id',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.created_at'),
                    value: 'created_at',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.received_at'),
                    value: 'received_at',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.started_at'),
                    value: 'started_at',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.arrived_at'),
                    value: 'arrived_at',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.finished_at'),
                    value: 'finished_at',
                    sortable: true,
                },
                {
                    text: this.$t('common.comment'),
                    value: 'comment',
                    sortable: false,
                },
                {
                    value: 'operation',
                    fixed: true,
                    width: 54,
                },
            ];
        },
    },
    methods: {
        userSelected(user) {
            this.dataStore.issues
                .addEmployee(this.modelValue.id, user.id)
                .then(() => {
                    this.helpersStore.snackbarSaved();
                    this.$emit('reload');
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                });
        },

        removeEmployeeClicked(item) {
            if (item.started_at !== null) {
                this.removeItem = item;
                this.removeDialog = true;
            } else {
                this.removeEmployee(item);
            }
        },
        updateDialog(value) {
            if (!value) {
                this.removeItem = false;
                this.removeDialog = false;
                return;
            }

            this.removeEmployee(this.removeItem);
        },

        removeEmployee(assignment) {
            this.dataStore.issues
                .removeEmployee(this.modelValue.id, assignment.id)
                .then(() => {
                    this.helpersStore.snackbarSaved();
                    this.$emit('reload');
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                    this.helpersStore.snackbarError();
                })
                .finally(() => {
                    this.removeItem = false;
                    this.removeDialog = false;
                });
        },
        takeIssue() {
            this.axios
                .post(`/me/issues/${this.modelValue.id}/take`)
                .then(() => {
                    this.helpersStore.snackbarSaved();
                    this.$emit('reload');
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                });
        },
        arriveIssue() {
            this.axios
                .post(`/me/issues/${this.modelValue.id}/arrive`)
                .then(() => {
                    this.helpersStore.snackbarSaved();
                    this.$emit('reload');
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                });
        },
        finishIssue() {
            this.finishDialogOpen = true;
        },
        showIssueChart() {
            this.issueChartData = this.modelValue;
            this.issueChartDialog = true;
        },
        issueChartDialogClose() {
            this.issueChartDialog = false;
            this.issueChartData = null;
        },
        showCommentClicked(item) {
            this.showCommentText = item.comment;
            this.showCommentOpen = true;
        },
    },
};
</script>
<style>
.issue-employees-add-button {
    margin-left: auto;
    margin-inline-end: 0 !important;
}
</style>
