<template>
    <div v-if="!this.field.file">
        <SignaloFilesImagesContainer
            v-if="!disabled"
            :allowUpload="true"
            :allowRemove="true"
            :allowEdit="true"
            :newItemImages="true"
            ref="surveyAnswerImages"
            :allowImagesFromDeviceCamera="true"
            @filesChanged="onFilesChanged"
        />
        <SignaloFilesImagesContainer
            v-else-if="Array.from(field.answer)?.every((a) => a !== undefined)"
            :inheritedFiles="this.field.answer"
        />
    </div>
    <SignaloFilesImagesContainer :inheritedFiles="[this.field.file]" v-else/>
</template>

<script>
export default {
    props: ['field', 'disabled'],
    emits: ['answer'],
    methods: {
        onFilesChanged(files) {
            this.$emit('answer', files);
        },
    },
    mounted() {
        if (this.field?.file?.id) {
            this.$emit('answer', this.field.file.id);
        }
    },
};
</script>
