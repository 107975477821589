<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.tests') }} </template>
        <template #bar>
            <v-btn @click="() => loadData()" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" color="primary" variant="elevated" class="submain-button renew-button">
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="addButtonClicked">
                        <v-list-item-title>
                            <v-icon start> mdi-plus </v-icon>
                            <span>
                                {{ $t('common.add') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <EasyDataTable :headers="tableHeaders" :items="items" :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style" :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')" @click-row="handleClickRow"
            v-model:server-options="serverOptions" :server-items-length="serverItemsLength"
            :rows-items="[5, 10, 25, 50, 75, 100]" :rows-per-page="5" :loading="loading" name="quizzesTable">
            <template #item-quiz="item">
                <p class="pre">
                    {{ $getPropertyTranslation(item?.name) }}
                </p>
            </template>
            <template #item-system="item">
                <v-icon v-if="item?.system">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <template #item-status="item">
                <span v-if="item?.editable_id !== null">{{
                    $t('cluer.pendingPublication')
                }}</span>
                <span v-else-if="item?.published">{{
                    $t('cluer.published')
                }}</span>
                <span v-else>{{ $t('cluer.unpublished') }}</span>
            </template>
            <template #item-operation="item">
                <SignaloDynamicMenu :rowItem="item" :menuItems="getTableActions(item)" />
            </template>
        </EasyDataTable>
    </v-main>

    <QuizBuilderDialog v-if="quizBuilderDialogOpened" :courseId="courseId" :open="quizBuilderDialogOpened" @close="() => {
        quizBuilderDialogOpened = false;
        quizToEdit = null;
        loadData();
    }
        " :quizTemplate="quizToEdit" @reload="loadData" />
    <SignaloConfirmationDialog v-if="removeDialog" :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => removeQuiz(value)" />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import QuizBuilderDialog from './QuizBuilder/QuizBuilderDialog.vue';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { dataStore, authStore, helpersStore };
    },
    data() {
        return {
            loading: false,
            items: [],
            serverItemsLength: 0,
            serverOptions: this.$getServerOptions('quizzesTable'),
            quizBuilderDialogOpened: false,
            removeDialog: false,
            itemToRemove: null,
            quizToEdit: null,

        };
    },
    async mounted() {
        this.loading = true;
        await this.loadData();
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.id'),
                    value: 'id',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'quiz',
                    sortable: true,
                },
                // {
                //     text: this.$t('cluer.systemSurvey'),
                //     value: 'system',
                //     sortable: true,
                // },
                {
                    text: this.$t('common.status'),
                    value: 'status',
                },
                {
                    value: 'operation',
                    width: 120,
                    fixed: true,
                    text: this.$t('tableHeaders.actions'),
                },
            ];
        },
    },
    methods: {

        getTableActions(item) {
            return [
                {
                    icon: 'mdi-eye',
                    label: this.$t('common.currentVersionPreview'),
                    action: this.previewQuiz,
                    access: [],
                },
                {
                    icon: 'mdi-pencil',
                    label: this.$t('common.edit'),
                    action: this.editQuiz,
                    access: [this.$route.meta.canCreate],
                },
                {
                    action: this.openRemoveQuizDialog,
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    access: [this.$route.meta.canDelete],
                },
                {
                    action: this.goToQuizResults,
                    icon: 'mdi-text-box-multiple-outline',
                    label: this.$t('cluer.previewResults'),
                    access: [
                        this.authStore.isAllowed('quiz_results', 'read'),
                    ],
                },
                {
                    action: this.publishQuiz,
                    icon: 'mdi-publish',
                    label: this.$t('cluer.publish'),
                    access: [!item.published || item.editable_id],
                },
                // {
                //     action: this.goToQuizParameters,
                //     icon: 'mdi-tune',
                //     label: this.$t('common.parameters'),
                //     access: [this.$route.meta.canCreate],
                // },
            ];
        },
        goToQuizResults(quiz) {
            this.$router.push({
                name: 'quizzes_results',
                query: {
                    quiz_name: this.$getPropertyTranslation(quiz.name),
                },
            });
        },
        goToQuizParameters(quiz) {
            this.$router.push({
                name: 'settings.quiz.parameters',
                params: {
                    id: quiz.id,
                },
            });
        },
        addButtonClicked() {
            this.quizBuilderDialogOpened = true;
        },
        openRemoveQuizDialog(item) {
            this.removeDialog = true;
            this.itemToRemove = item;
        },
        async publishQuiz(quiz) {
            try {
                let id = quiz.editable_id || quiz.id;
                await this.axios.patch(`quizzes/${id}`, {
                    published: true,
                });
                await this.loadData();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                console.error(err);
            }
        },
        async removeQuiz(shouldRemove) {
            try {
                if (shouldRemove) {
                    await this.axios.delete(`quizzes/${this.itemToRemove.id}`);
                    this.helpersStore.snackbarSaved();
                    await this.loadData();
                }

                this.removeDialog = false;
                this.itemToRemove = null;
            } catch (err) {
                console.error(err);
            }
        },
        async loadData(search, resetPage) {
            this.loading = true;
            try {
                if (resetPage) {
                    this.serverOptions.page = 1;
                }
                if (search !== null) {
                    this.lastSearch = search;
                }
                const config = this.dataStore.makeServerOptions(
                    this.serverOptions,
                    this.lastSearch
                );
                const quizzesData = await this.axios.get(`quizzes`, config);

                this.items = quizzesData.data.items || [];
                this.serverItemsLength = quizzesData?.data?.meta?.total;
            } catch (err) {
                console.error(err);
            }
            this.loading = false;
        },
        async editQuiz(quizTemplate) {
            try {
                let id;
                if (!quizTemplate.published) {
                    id = quizTemplate.id;
                } else if (!quizTemplate.editable_id) {
                    //TODO nie wiem czy nie strzelać pod edit przed zapisem zmiany, bo teraz otwieram dialog i robi sie nowa wersja, moge w niej nic nie zmienić i mam status zmiany oczekują na publikację.
                    const editableQuizData = await this.axios.post(
                        `quizzes/${quizTemplate.id}/edit`
                    );
                    id = editableQuizData.data?.item.id;
                } else {
                    id = quizTemplate.editable_id;
                }
                const fullTemplateData = await this.axios.get(`quizzes/${id}`);
                this.quizToEdit = fullTemplateData?.data?.item;
                this.quizBuilderDialogOpened = true;
            } catch (err) {
                console.error(err);
            }
        },
        async previewQuiz(quiz) {
            const fullTemplateData = await this.axios.get(
                `quizzes/${quiz.id}`
            );
            // const parameters = await this.axios.get(
            //     `quizzes/${quiz.id}/parameters`
            // );
            const quizToPreview = {
                ...fullTemplateData?.data?.item,
                // parameters: parameters?.data?.items,
            };
            this.helpersStore.runQuiz(quizToPreview, null, null, true);
        },
    },
    components: {
        QuizBuilderDialog,
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences('quizzesTable', {
                default_size: val.rowsPerPage,
            });
            this.loadData();
        },
    },
};
</script>
