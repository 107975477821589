<template>
    <v-rating v-model="rating" color="var(--primary-main)" :disabled="disabled">
        <template #item="props">
            <v-icon
                v-if="props.index === 0"
                :color="
                    rating - 1 === props.index
                        ? 'var(--primary-main)'
                        : 'var(--ui-icon-icon-deactivated)'
                "
                size="large"
                @click="props.onClick"
                :key="props.index"
            >
                {{
                    rating - 1 === props.index
                        ? 'mdi-emoticon-angry'
                        : 'mdi-emoticon-angry-outline'
                }}
            </v-icon>
            <v-icon
                v-if="props.index === 1"
                :color="
                    rating - 1 === props.index
                        ? 'var(--primary-main)'
                        : 'var(--ui-icon-icon-deactivated)'
                "
                size="large"
                @click="props.onClick"
                :key="props.index"
            >
                {{
                    rating - 1 === props.index
                        ? 'mdi-emoticon-sad'
                        : 'mdi-emoticon-sad-outline'
                }}
            </v-icon>
            <v-icon
                v-if="props.index === 2"
                :color="
                    rating - 1 === props.index
                        ? 'var(--primary-main)'
                        : 'var(--ui-icon-icon-deactivated)'
                "
                size="large"
                @click="props.onClick"
                :key="props.index"
            >
                {{
                    rating - 1 === props.index
                        ? 'mdi-emoticon-neutral'
                        : 'mdi-emoticon-neutral-outline'
                }}
            </v-icon>
            <v-icon
                v-if="props.index === 3"
                :color="
                    rating - 1 === props.index
                        ? 'var(--primary-main)'
                        : 'var(--ui-icon-icon-deactivated)'
                "
                size="large"
                @click="props.onClick"
                :key="props.index"
            >
                {{
                    rating - 1 === props.index
                        ? 'mdi-emoticon-happy'
                        : 'mdi-emoticon-happy-outline'
                }}
            </v-icon>
            <v-icon
                v-if="props.index === 4"
                :color="
                    rating - 1 === props.index
                        ? 'var(--primary-main)'
                        : 'var(--ui-icon-icon-deactivated)'
                "
                size="large"
                @click="props.onClick"
                :key="props.index"
            >
                {{
                    rating - 1 === props.index
                        ? 'mdi-emoticon-excited'
                        : 'mdi-emoticon-excited-outline'
                }}
            </v-icon>
        </template>
    </v-rating>
</template>

<script>
export default {
    props: ['field', 'disabled'],
    emits: ['answer'],
    data() {
        return {
            rating: this.field?.answer || null,
        };
    },
    watch: {
        rating(val) {
            this.$emit('answer', val);
        },
    },
};
</script>
