<template>
    <slot
        :text="textFieldValue ?? ''"
        :onClick="controlClicked"
        :onClear="clearClicked"
    >
        <v-text-field
            :modelValue="textFieldValue || ''"
            :class="required ? 'required-field' : ''"
            :rules="rules"
            @click:control="controlClicked"
            @click:clear="clearClicked"
            clearable
            clear-icon="mdi-close-circle-outline"
            readonly
            variant="outlined"
            :label="
                label ||
                `${$t('tpm.instruction')} - ${$t('tpm.instructionRevision')}`
            "
            :loading="loading"
        >
        </v-text-field>
    </slot>

    <SignaloDialog
        :open="dialog"
        :title="
            label ||
            `${$t('tpm.instruction')} - ${$t('tpm.instructionRevision')}`
        "
        @close="closeDialog"
        :constHeight="true"
    >
        <template #body>
            <v-card-text>
                <!-- <v-text-field
                    :placeholder="$t('common.searchPlaceholder')"
                    v-model="searchValue"
                    @click:clear="searchText = ''"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                    height="60"
                    variant="outlined"
                >
                </v-text-field> -->
                <div class="table-and-preview-container">
                    <div class="instuction-revision-picker-table-container">
                        <div
                            class="instructions-breadcrumbs-container"
                            :style="
                                currentFolderKeyname ||
                                (availableIds?.length && selectedInstruction)
                                    ? 'visibility:visible;'
                                    : 'visibility:hidden;'
                            "
                        >
                            <v-btn
                                icon
                                class="return-button"
                                color="primary"
                                variant="elevated"
                                @click="onReturnHandler"
                                :disabled="
                                    (!availableIds?.length ||
                                        !selectedInstruction) &&
                                    (!this.currentFolderKeyname ||
                                        this.currentFolderKeyname == 'null')
                                "
                            >
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                            <SignaloBreadCrumbs
                                :items="breadcrumb"
                                :localBreadcrumb="true"
                            />
                        </div>
                        <v-row>
                            <v-col>
                                <EasyDataTable buttons-pagination
                                    :loading="loading"
                                    :headers="tableHeaders"
                                    :items="currentFolderAssets"
                                    :empty-message="
                                        $t('common.emptyTableMessage')
                                    "
                                    table-class-name="easy-table-style"
                                    :rows-per-page-message="
                                        $t('common.rowsPerPage')
                                    "
                                    :rows-of-page-separator-message="
                                        $t('common.rowsOfPage')
                                    "
                                    v-model:server-options="serverOptions"
                                    :server-items-length="serverItemsLength"
                                    :rows-items="[5, 10, 25, 50, 75, 100]"
                                    @click-row="
                                        (item) => handleSelectGroup(null, item)
                                    "
                                    :hide-footer="
                                        !currentFolderKeyname ||
                                        currentFolderKeyname == 'null'
                                    "
                                >
                                    <template #item-name="item">
                                        <span
                                            v-if="
                                                item?.dictionary_id !==
                                                undefined
                                            "
                                        >
                                            {{ item?.value }}
                                        </span>
                                        <span v-else>{{
                                            $getPropertyTranslation(item.name)
                                        }}</span>
                                    </template>
                                    <template #item-description="item">
                                        <span
                                            v-if="
                                                item?.dictionary_id !==
                                                undefined
                                            "
                                        >
                                            {{
                                                $getPropertyTranslation(
                                                    item.description
                                                )
                                            }}
                                        </span>
                                    </template>
                                    <template #item-instruction_group="item">
                                        {{
                                            getInstructionGroupName(
                                                item.instruction_group
                                            )
                                        }}
                                    </template>
                                    <template #item-changes="item">{{
                                        $getPropertyTranslation(item.changes)
                                    }}</template>
                                    <template #item-operation="item">
                                        <SignaloDynamicMenu
                                            v-if="
                                                !instructionPicker &&
                                                item?.dictionary_id ===
                                                    undefined
                                            "
                                            :rowItem="item"
                                            :menuItems="tableActions"
                                        />
                                        <v-icon
                                            v-else-if="!item?.instruction_group"
                                            class="operation-icon"
                                            @click="
                                                handleSelectGroup($event, item)
                                            "
                                        >
                                            mdi-folder-arrow-right-outline
                                        </v-icon>

                                        <v-btn
                                            color="primary"
                                            class="table-action-icon-button"
                                            v-if="
                                                availableIds?.length &&
                                                selectedInstruction
                                            "
                                            @click="
                                                previewRevision($event, item)
                                            "
                                        >
                                            <v-icon class="operation-icon">
                                                mdi-eye
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                </EasyDataTable>
                            </v-col>
                        </v-row>
                    </div>
                    <div
                        class="instuction-revision-picker-preview-container"
                        v-if="currentFile && documentUrl"
                        :key="documentUrl"
                    >
                        <SignaloWopiDocumentPreview
                            :documentUrl="documentUrl"
                            @close="onPreviewClose"
                            :documentName="currentFile?.name"
                        />
                    </div>
                </div>
            </v-card-text>
        </template>
        <template #buttons>
            <v-btn @click="closeDialog">
                {{ $t('common.cancel') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
export default {
    props: [
        'rules',
        'required',
        'clearable',
        'returnObject',
        'returns',
        'modelValue',
        'open',
        'availableIds',
        'availableGroups',
        'instructionPicker',
        'label',
        'instructionId',
        'withGroupPath',
        'withInstructionId',
    ],
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: false,
            items: [],
            serverOptions: this.$getServerOptions('instructionsIndexTable'),
            serverItemsLength: 0,
            allInstructions: [],
            currentFolderKeyname: null,
            instructionGroups: [],
            currentInstructionGroup: null,
            dialog: this.open || false,
            selectedInstruction: null,
            revisions: [],
            selected: null,
            currentFile: null,
            documentUrl: null,
        };
    },
    async mounted() {
        await this.loadInstructionsGroupDictionary();
        await this.loadAllInstructions();
        await this.loadTable();
        await this.initModelValue();
    },
    methods: {
        async initModelValue() {
            if (!this.modelValue) {
                this.selectedInstruction = null;
                this.currentInstructionGroup = null;
                this.currentFolderKeyname = null;
                this.selected = null;
                return;
            }
            this.loading = true;
            if (isNaN(this.modelValue)) {
                //obiekt rewizji
                //TODO
            }
            if (
                Number.isInteger(this.instructionId) &&
                (Number.isInteger(this.modelValue) ||
                (Number.isInteger(this.modelValue?.id) &&
                    !this.instructionPicker))
            ) {
                const selectedData = await this.axios.get(
                    `/instruction_revisions/${
                        this.modelValue?.id || this.modelValue
                    }`
                );
                this.selected = selectedData?.data?.item;
                this.selectedInstruction = this.allInstructions.find(
                    (i) => i.id == this.selected.instruction_id
                );
                this.currentFolderKeyname =
                    this.selectedInstruction.instruction_group;
                await this.goToRevisions(this.selectedInstruction);
            }
            this.loading = false;
        },
        picked(item) {
            this.selected = item;
            let val = item.id;
            if (this.returnObject) val = item;
            else if (this.returns) val = item[this.returns];

            if (this.withGroupPath) {
                const currentGroup = this.instructionGroups.find(
                    (ig) => ig.keyname == this.currentFolderKeyname
                );
                if (currentGroup) {
                    if (this.returnObject || this.returns) {
                        val.group_path = currentGroup?.path;
                    } else {
                        val = {
                            id: item.id,
                            group_path: currentGroup?.path,
                        };
                    }
                }
            }

            if (this.withInstructionId) {
                if (Number.isInteger(Number(val))) {
                    val = {
                        id: item.id,
                        instruction_id: this.selectedInstruction.id,
                    };
                } else {
                    val.instruction_id = this.selectedInstruction.id;
                }
            }
            this.$emit('update:modelValue', val);
            this.dialog = false;
        },
        controlClicked() {
            this.dialog = true;
        },
        async loadInstructionsGroupDictionary() {
            await this.dataStore.dictionary.promise();
            this.instructionGroups = Object.values(
                this.dataStore.dictionary.group('instruction_group')
            );
        },
        async loadAllInstructions() {
            let query = 'instructions';
            if (this.availableIds?.length && Array.isArray(this.availableIds)) {
                query += `?id_wi=${this.availableIds.toString()}`;
            }
            const allInstructionsData = await this.axios.get(query);
            this.allInstructions = allInstructionsData?.data?.items || [];
            if (this.modelValue && this.instructionId) {
                this.selectedInstruction = this.allInstructions.find(
                    (i) => i.id == this.instructionId
                );
                if (this.selectedInstruction) {
                    const revisionsData = await this.axios.get(
                        `instructions/${this.instructionId}/revisions?state_finished_equals=1&available=1`
                    );
                    const revision = revisionsData?.data?.items?.find(
                        (r) => r.id == this.modelValue
                    );
                    if (revision) {
                        this.selected = revision;
                    }
                }
            }
        }, // eslint-disable-next-line
        async loadTable(search = this.lastSearch, resetPage = false) {
            if (this.currentFolderKeyname == null) {
                //ZAKLADAMY ŻE INSTRUKCJA MUSI MIEC GRUPE PRZYPISANA
                this.items = [];
                return;
            }

            if (resetPage) {
                this.serverOptions.page = 1;
            }
            try {
                this.loading = true;
                const opts = this.dataStore.instructions.makeServerOptions(
                    this.serverOptions
                );
                opts.params.instruction_group = this.currentFolderKeyname;
                opts.params.available = 1;
                const instructionsData = await this.axios.get(
                    `instructions`,
                    opts
                );
                this.items = instructionsData?.data?.items || [];
                this.serverItemsLength = instructionsData?.data?.meta?.total;
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
            this.loading = false;
        },
        getInstructionGroupName(keyname) {
            return Object.values(
                this.dataStore.dictionary.group('instruction_group')
            ).find((d) => d.keyname == keyname)?.value;
        },
        handleSelectGroup($event, item) {
            $event?.preventDefault();
            $event?.stopPropagation();
            if (this.instructionPicker && item?.instruction_group) {
                this.picked(item);
            }
            if (item?.instruction_id) {
                //rewizja
                this.picked(item);
            } else if (item?.dictionary_id === undefined) {
                this.goToRevisions(item);
            } else {
                this.currentFolderKeyname = item.keyname;
            }
        },
        onReturnHandler() {
            if (this.selectedInstruction) {
                this.selectedInstruction = null;
                this.revisions = [];
                return;
            }
            if (!this.currentFolderKeyname) {
                return;
            }
            const parentKeyname = this.instructionGroups.find(
                (ig) => ig.keyname === this.currentFolderKeyname
            )?.parent_keyname;
            if (this.availableGroups?.length && parentKeyname) {
                const parent = this.instructionGroups.find(
                    (ig) => ig.keyname === parentKeyname
                );
                if (!parent) {
                    this.currentFolderKeyname = null;
                } else if (
                    this.availableGroups.find((ag) =>
                        parent.path.startsWith(ag)
                    )
                ) {
                    this.currentFolderKeyname = parentKeyname;
                } else {
                    this.currentFolderKeyname = null;
                }

                return;
            }
            if (parentKeyname) {
                this.currentFolderKeyname = parentKeyname;
            } else {
                this.currentFolderKeyname = null;
            }
        },
        async goToRevisions(instruction) {
            try {
                const revisions = await this.axios.get(
                    `instructions/${instruction.id}/revisions?state_finished_equals=1&available=1`
                );
                this.revisions = revisions?.data?.items;
            } catch (err) {
                console.error(err);
            }
            this.selectedInstruction = instruction;
        },
        closeDialog() {
            this.dialog = false;
        },
        previewRevision($event, revision) {
            $event.preventDefault();
            $event.stopPropagation();
            this.onWopiPreview(revision);
        },
        async onWopiPreview(revision) {
            const fileData = await this.axios.get(
                `instructions/${revision.instruction_id}/revisions/${revision.id}`
            );
            const file = fileData?.data?.item?.document;
            if (!file.wopi?.url) {
                return this.openDocumentPreview(file);
            }
            let currentFile;

            currentFile = { ...file };
            this.documentUrl = file.wopi.url;
            this.currentFile = currentFile;
        },
        openDocumentPreview(file) {
            if (!file?.path) return;
            window.open(this.storageUrl + file.path);
        },
        onPreviewClose() {
            this.documentUrl = null;
            this.currentFile = null;
        },
    },
    computed: {
        textFieldValue() {
            if (!this.selected) return '';
            let value = this.$getPropertyTranslation(
                this.selectedInstruction?.name
            );
            if (this.instructionPicker) return value;

            value += ' - ' + this.selected.revision;
            if (this.$getPropertyTranslation(this.selected.changes)) {
                value += ` (${this.$getPropertyTranslation(
                    this.selected.changes
                )})`;
            }
            return value;
        },
        tableHeaders() {
            if (this.selectedInstruction) {
                const ths = [
                    {
                        text: this.$t('tpm.instructionRevision'),
                        value: 'revision',
                    },
                    {
                        text: this.$t('common.revisionChanges'),
                        value: 'changes',
                    },
                ];
                if (this.availableIds?.length) {
                    ths.push({ value: 'operation', width: 54, fixed: true });
                }
                return ths;
            }
            return [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                },
                {
                    text: this.$t('tableHeaders.description'),
                    value: 'description',
                },
                {
                    text: this.$t('common.instructionGroup'),
                    value: 'instruction_group',
                },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
        tableActions() {
            if (this.selectedInstruction) return [];
            return [
                {
                    icon: 'mdi-format-list-numbered',
                    label: this.$t('tpm.instructionRevisions'),
                    action: this.goToRevisions,
                    access: [],
                },
            ];
        },
        breadcrumb() {
            let bcs = [];
            let nextID = this.currentFolderKeyname;

            if (this.availableIds?.length && this.selectedInstruction) {
                bcs.push({
                    text: this.$getPropertyTranslation(
                        this.selectedInstruction?.name
                    ),
                    onClick: ($event) => {
                        $event.preventDefault();
                        $event.stopPropagation();
                        this.selectedInstruction = null;
                        this.revisions = [];
                    },
                });
                bcs.push({
                    text: this.$t('tpm.instructionRevisions'),
                    href: '#',
                });

                return bcs;
            }

            if (!nextID) return [];

            do {
                let next = this.instructionGroups.find(
                    (l) => l.keyname == nextID
                );

                bcs.unshift({
                    text: next?.value || '',
                    href: '#',
                    onClick: ($event) => {
                        $event.preventDefault();
                        $event.stopPropagation();
                        this.currentFolderKeyname = next.keyname;
                    },
                    disabled: this.availableGroups?.length
                        ? !this.availableGroups.find((ag) =>
                              next.path.startsWith(ag)
                          )
                        : false,
                });
                nextID = next?.parent_keyname;
            } while (nextID);
            if (this.selectedInstruction) {
                bcs.push({
                    text: this.$getPropertyTranslation(
                        this.selectedInstruction?.name
                    ),
                    onClick: ($event) => {
                        $event.preventDefault();
                        $event.stopPropagation();
                        this.selectedInstruction = null;
                        this.revisions = [];
                    },
                });
                bcs.push({
                    text: this.$t('tpm.instructionRevisions'),
                    href: '#',
                });
            }
            return bcs;
        },
        currentFolderAssets() {
            //PODFOLDERY I WSZYSTKIE INSTRUKCJE KTÓRE MAJĄ ta grupe
            if (this.selectedInstruction) {
                return this.revisions;
            }
            if (this.availableIds) {
                return this.allInstructions;
            }
            if (this.availableGroups?.length) {
                return [
                    ...this.instructionGroups.filter((ig) => {
                        if (this.currentFolderKeyname) {
                            return (
                                ig.parent_keyname ==
                                (this.currentFolderKeyname
                                    ? this.currentFolderKeyname
                                    : null)
                            );
                        } else {
                            return this.availableGroups.includes(ig.path);
                        }
                    }),
                    ...this.items,
                ];
            }
            return [
                ...this.instructionGroups.filter(
                    (ig) =>
                        ig.parent_keyname ==
                        (this.currentFolderKeyname
                            ? this.currentFolderKeyname
                            : null)
                ),
                ...this.items,
            ];
        },
    },
    watch: {
        currentFolderKeyname() {
            this.loadTable(this.lastSearch, true);
        },
        serverOptions(val) {
            this.authStore.patchTablePreferences(this.TABLE_NAME, {
                default_size: val.rowsPerPage,
            });
            this.loadTable(this.lastSearch, false);
        },
        modelValue() {
            this.initModelValue();
        },
    },
};
</script>
<style scoped>
.instructions-breadcrumbs-container {
    display: flex;
    align-items: center;
    min-height: 40px;
}

.instructions-breadcrumbs-container .v-btn.return-button {
    width: 32px;
    height: 32px !important;
    font-size: 12px !important;
    margin-right: 4px;
}

.table-and-preview-container {
    display: flex;
}

.instuction-revision-picker-table-container,
.instuction-revision-picker-preview-container {
    flex: 1;
}
</style>
