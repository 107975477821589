import { SEARCH_FIELD_TYPES } from 'signalo-vue-search-container';
import i18n from '@/i18n';
import FLAG_TYPES from '@/CONSTANTS/FLAG_TYPES';
export default [
    {
        id_equals: {
            type: 'text',
            get label() { return i18n?.global?.t('searchConfig.id')},
            cols: 2,
        },
        title: {
            type: 'text',
            get label() { return i18n?.global?.t('tableHeaders.name')},
            cols: 2,
        },
        category_path: {
            type: 'category-picker',
            get label() { return i18n?.global?.t('searchConfig.category')},
            cols: 2,
            bind: { returns: 'path' },
        },
        equipment_id_equals: {
            type: SEARCH_FIELD_TYPES.EQUIPMENT_PICKER,
            get label() { return i18n?.global?.t('searchConfig.object')},
            cols: 3,
            bind: {
                kind: 'machine',
            },
        },
        criticality: {
            type: 'dictionary:issue_criticality',
            get label() { return i18n?.global?.t('searchConfig.criticality')},
            cols: 2,
        },
        kind: {
            type: 'dictionary:issue_kind',
            get label() { return i18n?.global?.t('searchConfig.kind')},
            cols: 3,
        },
        prod_line: {
            type: 'dictionary:prod_lines',
            cols: 3,
            get label() { return i18n?.global?.t('common.productionLine')},
        },
        department_equals: {
            type: 'dictionary:departments',
            get label() { return i18n?.global?.t('searchConfig.department')},
            cols: 3,
        },
        responsible_user_id_equals: {
            type: SEARCH_FIELD_TYPES.USER_PICKER,
            get label() { return i18n?.global?.t('issues.responsibleUser')},
            cols: 4,
        },
        // eslint-disable-next-line
        no_responsible: {
            type: SEARCH_FIELD_TYPES.CHECK,
            get label() { return i18n?.global?.t('common.noResponsibleUser')},
            cols: 3,
            checkedValue: true,
        },
        finished_at_equals: {
            type: SEARCH_FIELD_TYPES.CHECK,
            get label() { return i18n?.global?.t('common.onlyNotFinished')},
            cols: 3,
            checkedValue: 'null',
        },
        trashed: {
            type: SEARCH_FIELD_TYPES.CHECK,
            get label() { return i18n?.global?.t('common.archived')},
            cols: 3,
            checkedValue: '1',
        },
        flag_id_wi: {
            type: SEARCH_FIELD_TYPES.FLAGS || 'flags',
            get label() { return i18n?.global?.t('common.flags')},
            cols: 3,
            flag_type: FLAG_TYPES.ISSUE,
        },

        created_at_dtmin: {
            type: SEARCH_FIELD_TYPES.DATE,
            get label() { return String(
                i18n?.global?.t('common.issueCreated') +
                    ' ' +
                    i18n?.global?.t('reports.dateFrom').toLowerCase()
            )},
            cols: 3,
        },
        created_at_dtmax: {
            type: SEARCH_FIELD_TYPES.DATE,
            get label() { return String(
                i18n?.global?.t('common.issueCreated') +
                    ' ' +
                    i18n?.global?.t('reports.dateTo').toLowerCase()
            )},
            cols: 3,
            maxDate: true,
        },
        finished_at_dtmin: {
            type: SEARCH_FIELD_TYPES.DATE,
            get label() { return String(
                i18n?.global?.t('common.finished') +
                    ' ' +
                    i18n?.global?.t('reports.dateFrom').toLowerCase()
            )},
            cols: 3,
        },
        finished_at_dtmax: {
            type: SEARCH_FIELD_TYPES.DATE,
            get label() { return String(
                i18n?.global?.t('common.finished') +
                    ' ' +
                    i18n?.global?.t('reports.dateFrom').toLowerCase()
            )},
            cols: 3,
            maxDate: true,
        },
    },
];
