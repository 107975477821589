<template>
    <SignaloDialog
        v-if="open"
        :open="open"
        :title="
            $t('common.test') +
            ': ' +
            $getPropertyTranslation(survey.name) +
            (preview?.userName && preview?.type === 'single'
                ? `, ${$t('common.user')}: ${preview?.userName}`
                : '') + (userContext ? `, ${$t('common.user')}: ${userContext.name}` : '')
        "
        :icon="'mdi-list-box-outline'"
        :dialogClasses="['survey-dialog-container']"
        :disableClose="!preview && !userContext"
    >
        <template #body>
            <SignaloPageError v-if="error" :errorMessage="error" />
            <SignaloPageLoading v-else-if="loading" />
            <SignaloQuizContainer :quiz="survey" :preview="preview" :userContext="userContext" :currentPageSections="currentPageSections"/>
        </template>
        <template #buttons>
            <v-btn @click="$emit('close', false)" v-if="preview">
                {{ $t('common.cancel') }}
            </v-btn>

            <v-btn
                color="primary"
                @click="onSaveClick"
                variant="elevated"
                v-if="(!preview || preview?.type === 'statement') && step == steps[steps.length - 1]"
                :loading="loading"
                :disabled="!allRequiredQuestionsFilled"
            >
                {{ $t('common.save') }}
            </v-btn>
            <!-- <v-btn
                color="primary"
                @click="onNextPage"
                variant="elevated"
                :disabled="!pageValid"
                v-else
            >
                {{ $t('common.next') }}
            </v-btn> -->
        </template>
    </SignaloDialog>
</template>

<script>
import * as _ from 'lodash';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import { asyncForEach } from 'signalo-vue-utils';
import SignaloQuizContainer from './SignaloQuizContainer.vue';
export default {
    name: 'SignaloSurveyDialogComponent',
    props: [
        'open',
        'surveyTemplate',
        'surveyAnswers',
        'mySurveyId',
        'preview',
        'photos',
        'userContext'
    ],
    setup() {
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { helpersStore, authStore };
    },
    data() {
        return {
            survey: { ...this.surveyTemplate },
            isMultiPageSurvey: false,
            steps: [],
            step: undefined,
            pageValid: false,
            currentPageSections: [],
            error: null,
            loading: false,
            userAnswers: null,
        };
    },
    components: {
        SignaloQuizContainer
    },
    mounted() {
        if (this.surveyAnswers && (this.preview?.type === 'single' || this.preview?.type === 'statement')) {
            this.userAnswers = _.groupBy(
                _.flatten(this.surveyAnswers),
                'section_id'
            );

            this.survey.sections.forEach((section) => {
                section.questions.forEach((q) => {
                    const userAnswer = this.userAnswers?.[section.id]?.find(
                        (ua) => ua.question_id === q.id
                    );
                    let answer;
                    if (
                        q.type === this.$QUESTION_TYPES_ENUM.CHECK ||
                        q.type === this.$QUESTION_TYPES_ENUM.RADIO
                    ) {
                        answer = userAnswer?.answer_id;
                    } else if (q.type === this.$QUESTION_TYPES_ENUM.PHOTO && this.photos?.length) {
                        answer = userAnswer.images_ids.map((pId) =>
                            this.photos.find((p) => p.id === pId)
                        );
                    } else {
                        answer = userAnswer?.value;
                    }
                    q.answer = answer;
                    q.disabled = userAnswer?.disabled || false;
                });
            });
        } else if (this.surveyAnswers && this.preview?.type === 'grouped') {
            this.survey.sections.forEach((section) => {
                section.questions.forEach((q) => {
                    const usersAnswers = [];

                    this.surveyAnswers.forEach((singleUserResults) => {
                        for (
                            let i = 0;
                            i < singleUserResults?.data?.length;
                            i++
                        ) {
                            let currentQuestionUserAnswer = null;

                            for (
                                let j = 0;
                                j < singleUserResults?.data[i].length;
                                j++
                            ) {
                                if (
                                    singleUserResults?.data[i][j].section_id ===
                                        section.id &&
                                    singleUserResults?.data[i][j]
                                        .question_id === q.id
                                ) {
                                    currentQuestionUserAnswer = {
                                        ...singleUserResults?.data[i][j],
                                        user: singleUserResults.user,
                                    };
                                    if (
                                        currentQuestionUserAnswer?.images_ids
                                            ?.length && this.photos?.length
                                    ) {
                                        currentQuestionUserAnswer.answers = currentQuestionUserAnswer.images_ids.map(
                                            (pId) =>
                                                this.photos.find(
                                                    (p) => p.id === pId
                                                )
                                        );
                                    }
                                    usersAnswers.push(
                                        currentQuestionUserAnswer
                                    );
                                    break;
                                }
                            }
                            if (currentQuestionUserAnswer) break;
                        }
                    });
                    if (q.type === this.$QUESTION_TYPES_ENUM.RATING) {
                        q.answers = [
                            { value: 1 },
                            { value: 2 },
                            { value: 3 },
                            { value: 4 },
                            { value: 5 },
                        ]; //TODO przerobić jak rating będzie konfigurowalny
                    }
                    q.usersAnswers = usersAnswers;
                });
            });
        }
        this.initSurvey();
    },
    methods: {
        initSurvey() {
            if (!this.survey?.sections?.length) {
                this.error = this.$t('cluer.surveyNotConfigured');
                return;
            }
            const pagesSet = new Set(this.survey.sections.map((s) => s.page));
            this.isMultiPageSurvey = pagesSet.size > 1;
            if (this.isMultiPageSurvey) {
                this.steps = Array.from(pagesSet);
                this.step = this.steps[0];
                this.setCurrentPageSections();
            } else {
                this.steps = pagesSet.values();
                this.step = this.steps[0];
                this.setCurrentPageSections();
            }

            this.checkPageValid();
        },
        onQuestionAnswer(sectionId, questionId, answerValue) {
            const q = this.survey.sections
                .find((s) => s.id === sectionId)
                ?.questions.find((q) => q.id == questionId);
            q.answer = answerValue;
            this.checkPageValid();
        },
        checkPageValid() {
            const allPageRequiredQuestions = _.flatten(
                this.currentPageSections.map((s) =>
                    s.questions.filter((q) => q?.required)
                )
            );
            this.pageValid = allPageRequiredQuestions.every(
                (q) =>
                    q.answer !== undefined &&
                    q.answer !== null &&
                    q.answer !== ''
            );
        },
        onNextPage() {
            const currentPageIndex = this.steps.indexOf(this.step);
            this.step = this.steps[currentPageIndex + 1];
            this.setCurrentPageSections();
            const dialogContentContainer = document
                .getElementById('survey-container')
                ?.closest('.app-dialog-content-container');
            if (dialogContentContainer) {
                dialogContentContainer.scrollTop = 0;
            }
        },
        setCurrentPageSections() {
            if (this.step !== undefined) {
                this.currentPageSections = this.survey.sections
                    .filter((section) => section.page == this.step)
                    .sort((a, b) => a.order - b.order);
                return;
            }
            this.currentPageSections = this.survey.sections.sort(
                (a, b) => a.order - b.order
            );
        },
        async onSaveClick() {
            this.loading = true;
            let saveEndpoint;
            if (this.userContext?.id) { //
                saveEndpoint = `users/${this.userContext?.id}/statement_results/${this.mySurveyId}`;
            } else {
                saveEndpoint = `me/surveys/${this.mySurveyId}`;
            }
            try {
                let answers = [];
                await asyncForEach(this.survey.sections, async (section) => {
                    let sectionAnswers = [];
                    await asyncForEach(section.questions, async (question) => {
                        let data = {
                            section_id: section.id,
                            question_id: question.id,
                        };
                        if (
                            question.type === this.$QUESTION_TYPES_ENUM.RADIO ||
                            question.type === this.$QUESTION_TYPES_ENUM.CHECK
                        ) {
                            data.answer_id = question.answer;
                        } else if (
                            question.type === this.$QUESTION_TYPES_ENUM.PHOTO
                        ) {
                            if (Array.isArray(question.answer) && question.answer.every(file => file.uploadFile)) {
                                const imagesUploadResults =
                                    await Promise.allSettled(
                                        question.answer.map((file) =>
                                            this.axios.request({
                                                url: `${saveEndpoint}/photos`,
                                                method: 'POST',
                                                data: {
                                                    file: file.uploadFile,
                                                },
                                                headers: {
                                                    'Content-Type':
                                                        'multipart/form-data',
                                                },
                                            })
                                        )
                                    );
                                data.images_ids = imagesUploadResults.map(
                                    (iur) => iur.value.data.item.id
                                );
                            } else {
                                data.value = question.answer
                            }
                        } else {
                            data.value = question.answer;
                        }
                        sectionAnswers.push(data);
                    });
                    // answers.push(sectionAnswers); //Rafał chciał żeby nie było [[]], czy sie nie wywali jakby było więcej sekcji / stron?
                    sectionAnswers.forEach(sa => answers.push(sa));
                });
                await this.axios.put(saveEndpoint, {
                    survey_id: this.survey.id,
                    data: answers,
                    started: true,
                    completed: true,
                });
                this.loading = false;
                this.helpersStore.snackbarSaved();
                this.$emit('close');
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
            this.loading = false;
        },
    },
    computed: {
        allRequiredQuestionsFilled() {
            return this.survey?.sections?.every((section) =>
                section.questions
                    .filter((q) => q.required)
                    .every(
                        (q) =>
                            q.answer !== null &&
                            q.answer !== '' &&
                            q.answer !== undefined &&
                            (typeof q.answer === 'object'
                                ? q.answer?.length
                                : true)
                    )
            );
        },
    },
    watch: {
        surveyTemplate(val) {
            this.error = false;
            this.survey = { ...val };
            this.initSurvey();
        },
    },
};
</script>

<style>
.survey-container {
    padding: 1rem 0;
}
.survey-section {
    padding: 0 1rem;
}

.centercontainer {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.section-description {
    margin-top: 8px;
    font-size: 12px !important;
}
.survey-description {
    padding: 16px 0;
    font-size: 14px !important;
    margin-bottom: 16px;
}

.survey-dialog-container .v-toolbar__content .flex-grow-1 {
    display: none !important;
}
</style>
