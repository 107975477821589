<template>
    <SignaloDialog
        :open="true"
        :title="$t('common.acceptInstruction')"
        @close="closeDialog"
        :constHeight="false"
        :fullscreen="true"
        :dialogClasses="'signalo-instruction-revision-acceptation-dialog'"
        :disableClose="!canCloseWindow"
    >
        <template #body>
            <SignaloPageLoading v-if="loading" />
            <div
                class="signalo-instruction-revision-acceptation-dialog-content"
                v-else-if="revisionsData"
            >
                <SignaloStepper
                    class="mt-3"
                    :steps="revisionsLabels"
                    v-model="revisionStep"
                />
                <div v-if="revisionStep < revisionsData?.length">
                    <div
                        v-for="(revision, index) in revisionsData"
                        :key="revision?.id"
                    >
                        <v-window
                            v-if="index === revisionStep"
                            v-model="window"
                            :touch="false"
                            :key="window"
                            eager
                        >
                            <v-window-item
                                :key="window"
                                :value="'playlist'"
                                eager
                            >
                                <SignaloPlaylistPlayer
                                    :playlist="revision?.playlist"
                                />
                            </v-window-item>
                        </v-window>
                    </div>
                </div>
                <v-window
                    :style="
                        window === 'signature'
                            ? 'display: block'
                            : 'display: none'
                    "
                >
                    <v-window-item :key="window" :value="'signature'">
                        <v-card-text>
                            <SignaloSignatureComponent
                                ref="signatureComponent"
                                @update:modelValue="(val) => (signature = val)"
                                :value="val"
                                @save="acceptInstructions"
                            />
                        </v-card-text>
                    </v-window-item>
                </v-window>
            </div>
        </template>
        <template #buttons>
            <SignaloPageLoading v-if="loading" />
            <div class="buttons" v-else>
                <v-btn @click="() => $emit('close')" v-if="canCloseWindow">
                    {{ $t('common.close') }}
                </v-btn>
                <v-btn
                    v-if="hasNextStep"
                    color="primary"
                    variant="elevated"
                    @click="onNextStep"
                >
                    {{ $t('common.next') }}
                </v-btn>
                <v-btn
                    v-if="window === 'signature'"
                    color="primary"
                    variant="elevated"
                    @click="acceptInstructions"
                    :disabled="!signature"
                >
                    {{ $t('common.accept') }}
                </v-btn>
            </div>
        </template>
    </SignaloDialog>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import SignaloPlaylistPlayer from './SignaloPlaylistPlayer/SignaloPlaylistPlayer.vue';
import { asyncForEach } from 'signalo-vue-utils';
export default {
    setup() {
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { authStore, helpersStore };
    },
    emits: ['close'],
    props: ['revisions', 'disableClose'],
    data() {
        return {
            loading: false,
            signature: null,
            saveLoading: false,
            step: 0,
            window: null,
            revisionsLabels: [],
            revisionStep: null,
            revisionsData: [],
            currentRevision: null,
            revisionsToSign: [],
        };
    },
    async mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            try {
                this.loading = true;
                this.initRevisionsLabels();

                await asyncForEach(this.revisions, async (revision) => {
                    const revisionData = { ...revision };
                    const playlist = await this.loadRevisionPlaylist(revision);
                    revisionData.playlist = playlist;

                    this.revisionsData.push(revisionData);
                });
                this.step = 0;
                this.currentRevision = this.revisionsData[0];
                this.window = 'playlist';
                this.loading = false;
            } catch (err) {
                console.error(err);
            }
        },
        initRevisionsLabels() {
            this.revisionsLabels = [];
            this.revisions.forEach((r) => {
                let text;
                if (r?.instruction?.name) {
                    text =
                        this.$getPropertyTranslation(r?.instruction?.name) +
                        ' - ' +
                        r.revision;
                } else {
                    text = r.revision;
                }
                this.revisionsLabels.push({
                    text: text,
                });

                const requiredOrAlwaysSign =
                    Boolean(r?.required) || Boolean(r.show_always_sign_again);
                const shouldSign =
                    r?.required === undefined &&
                    r?.show_always_sign_again === undefined;
                if (requiredOrAlwaysSign || shouldSign) {
                    this.revisionsToSign.push(r.id);
                }
            });
            if (this.revisionsToSign?.length > 0) {
                this.revisionsLabels.push({
                    text: this.$t('common.signature'),
                });
            }
            this.revisionStep = 0;
        },
        initRevisionSteps(revision) {
            const steps = [];
            if (revision.playlist) {
                steps.push({
                    text:
                        this.$t('tpm.instruction') +
                        ' - ' +
                        this.$t('common.playlist'),
                    value: 'playlist',
                });
            }

            return steps;
        },
        closeDialog() {
            this.$emit('close');
        },
        async acceptInstructions() {
            try {
                this.saveLoading = true;
                await asyncForEach(this.revisionsToSign, async (id) => {
                    const revision = this.revisionsData.find((r) => r.id == id);
                    if (revision) {
                        await this.axios.post('me/instructions', {
                            instruction_revision_id: revision.id,
                            user_signature: this.signature,
                        });
                    }
                });
                this.helpersStore.snackbarSaved();
                this.$emit('close', true);
            } catch (err) {
                console.error(err);
            } finally {
                this.saveLoading = false;
            }
        },
        async loadRevisionPlaylist(revision) {
            if (!revision?.instruction_id || !revision.id) return;
            try {
                const revisionData = await this.axios.get(
                    `instructions/${revision?.instruction_id}/revisions/${revision.id}`
                );
                const playlist = revisionData?.data?.item?.playlist?.content;
                return playlist;
            } catch (err) {
                console.error(err);
            }
        },
        onNextStep() {
            if (
                this.revisionsToSign?.length > 0 &&
                this.revisionStep === this.revisions?.length - 1
            ) {
                this.window = 'signature';
            }
            this.revisionStep++;
        },
    },
    components: { SignaloPlaylistPlayer },
    watch: {
        step(val) {
            this.window = this.currentRevision?.steps?.[val]?.value;
        },
    },
    computed: {
        hasNextStep() {
            if (this.window === 'signature') return false;

            if (this.revisionStep === this.revisionsData?.length - 1) {
                return this.revisionsToSign?.length > 0;
            }

            return this.revisionStep < this.revisionsData?.length - 1;
        },
        canCloseWindow() {
            return this.revisionsToSign?.length === 0;
        },
    },
};
</script>
