<template>
    <SignaloDialog v-if="open" :open="open" :title="$t('common.test')">
        <template #customToolbarActions>
            <v-btn variant="text" :icon="preview ? 'mdi-eye-off' : 'mdi-eye'" @click="preview = !preview">
            </v-btn>
        </template>
        <template #body>
            <SignaloPageLoading v-if="loading" />
            <div class="quiz-builder-container" v-else>
                <div class="quiz-col">
                    <v-form v-model="surveyFormDataValid" ref="surveyForm" lazy-validation>
                        <SignaloTranslatableTabs v-slot="{ value, update }" v-model="name"
                            :currentLanguage="$i18n?.locale" :availableLocales="getAvailableLocales()">
                            <v-card-text>
                                <v-text-field :label="$t('common.name')" :modelValue="value" @update:modelValue="update"
                                    :rules="translatableRequired" required variant="outlined"
                                    color="var(--greyscale-100)" class="required-field">
                                </v-text-field>
                            </v-card-text>
                        </SignaloTranslatableTabs>

                        <div class="survey-section-builder-container">
                            <QuizSectionBuilder v-for="section in currentPageSections" :key="section.id"
                                :modelValue="section" ref="surveySection" @update:modelValue="onSectionUpdate"
                                @removeSection="onRemoveSection" :surveyId="quizTemplate?.id" />
                            <div class="add-section-button-container">
                                <v-btn @click="addSection" color="primary" variant="text">
                                    {{ $t('cluer.addSection') }}
                                </v-btn>
                            </div>
                        </div>
                    </v-form>

                </div>
                <div class="quiz-col" v-if="preview">
                    <SignaloQuizContainer :quiz="quizTemplate" :preview="preview" :userContext="userContext"
                        :currentPageSections="currentPageSections" />
                </div>
            </div>
        </template>
        <template #buttons>
            <div></div>
            <v-btn @click="$emit('close')" v-if="!quizTemplate?.id">{{
                $t('common.cancel')
                }}</v-btn>
            <v-btn color="primary" variant="elevated" @click="saveSurvey" :loading="loading" v-if="!quizTemplate?.id">
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
// import MyStepper from '@/components/MyStepper.vue';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import QuizSectionBuilder from './QuizSectionBuilder.vue';
import { v4 as uuidv4 } from 'uuid';
import { asyncForEach, CheckIsUUID } from 'signalo-vue-utils';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import SignaloQuizContainer from '../../SignaloQuizContainer.vue';
export default {
    setup() {
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { authStore, helpersStore };
    },
    props: ['open', 'quizTemplate', 'reload'],
    components: {
        // MyStepper,
        QuizSectionBuilder,
        SignaloQuizContainer
    },
    data() {
        return {
            loading: false,
            system: this.quizTemplate?.system || false,
            step: 0,
            steps: [0],
            // currentPageSections: [],
            sections: this.quizTemplate?.sections || [],

            name: this.quizTemplate?.name,
            surveyFormDataValid: false,
            surveyDescription: this.quizTemplate?.description,
            panel: this.quizTemplate?.description?.[this.$i18n.locale]
                ? [1]
                : [],
            preview: false,
        };
    },

    methods: {
        async onRemoveSection(sectionId) {
            try {
                if (this.quizTemplate?.id && !CheckIsUUID(sectionId)) {
                    await this.axios.delete(
                        // `surveys/${this.quizTemplate.id}/sections/${sectionId}`
                        `quizzes/${this.quizTemplate.id}/sections/${sectionId}`
                    );
                    this.removeSectionLocally(sectionId);
                    this.helpersStore.snackbarSaved();
                } else {
                    this.removeSectionLocally(sectionId);
                }

            } catch (err) {
                console.error(err);
            }
        },
        removeSectionLocally(sectionId) {
            const sectionIndex = this.sections.findIndex(
                (s) => s.id === sectionId
            );
            if (sectionIndex === -1) throw new Error('Section not found');
            this.sections.splice(sectionIndex, 1);
        },
        addPage() {
            this.steps.push(this.steps.length);
            this.step = this.steps[this.steps.length - 1];
        },
        addSection() {
            this.sections.push({
                id: uuidv4(),
                page: this.step,
                order: this.sections?.length
                    ? Math.max(...this.sections.map((s) => s.order)) + 1
                    : 1,
                name: null,
                questions: [],
            });
        },
        async validateAll() {
            const validators = [];
            if (typeof this.$refs.surveyForm.validate === 'function') {
                validators.push(this.$refs.surveyForm.validate);
            }
            if (this.$refs.surveySection?.length) {
                Array.from(this.$refs.surveySection).forEach((ss) => {
                    if (typeof ss.validate === 'function') {
                        validators.push(ss.validate);
                    }
                });
            } else if (
                this.$refs?.surveySection &&
                typeof this.$refs?.surveySection?.validate === 'function'
            ) {
                validators.push(this.$refs?.surveySection?.validate);
            }


            const validatorsResults = [];
            await asyncForEach(validators, async (v) => {
                if (typeof v === 'function') {
                    const validationResult = await v();
                    if (Array.isArray(validationResult)) {
                        validatorsResults.push(...validationResult);
                    } else {
                        validatorsResults.push(validationResult);
                    }
                }
            });

            const validationStatuses = validatorsResults.map((r) => r.valid);
            return validationStatuses.every((vs) => vs === true);
        },
        async onSectionUpdate(section) {
            try {
                this.helpersStore.debounce(async () => {
                    const sectionIndex = this.sections.findIndex(
                        (s) => s.id === section.id
                    );
                    if (sectionIndex === -1) throw new Error('Section not found');
                    if (!section.valid) return;
                    delete section.valid;
                    if (
                        JSON.stringify(this.sections[sectionIndex]) ===
                        JSON.stringify(section)
                    )
                        return;
                    this.sections[sectionIndex] = section;


                    if (this.quizTemplate?.id) {
                        const sectionData = {
                            name: section.name,
                            order: section.order,
                            page: section.page,
                        };
                        if (CheckIsUUID(section.id)) {
                            const createdSection = await this.axios.post(
                                `quizzes/${this.quizTemplate.id}/sections`,
                                sectionData
                            );
                            this.sections[sectionIndex].id =
                                createdSection?.data?.item?.id;
                        } else {
                            await this.axios.put(

                                `quizzes/${this.quizTemplate.id}/sections/${section.id}`,
                                sectionData
                            );
                        }
                        this.helpersStore.snackbarSaved();
                    }
                }, 2000);
            } catch (err) {
                console.error(err);
            }
        },
        async saveSurvey() {
            await this.validateAll();
            if (!this.allValid) return;

            this.loading = true;
            const sectionsRequests = [];

            try {
                const quizData = await this.axios.post(
                    `quizzes`,
                    {
                        name: this.name,
                        description: this.surveyDescription,
                        system: this.system,
                        published: false,
                    });

                this.sections.forEach((section) => {
                    sectionsRequests.push(
                        this.axios
                            .post(
                                // `surveys/${surveyData.data.item.id}/sections`,
                                `quizzes/${quizData.data.item.id}/sections`,
                                {
                                    name: section.name,
                                    description: section.description,
                                    page: section.page,
                                    order: section.order,
                                }
                            )
                            .then((createdSection) => {
                                if (!section.questions?.length) return;
                                const questionsRequests = [];
                                section.questions.forEach((question) => {
                                    questionsRequests.push(
                                        this.axios
                                            .post(
                                                // `surveys/${surveyData.data.item.id}/sections/${createdSection.data.item.id}/questions`,
                                                `quizzes/${quizData.data.item.id}/sections/${createdSection.data.item.id}/questions`,
                                                {
                                                    name: question.name,
                                                    question_type: question.question_type, //TODO walidatory
                                                    required:
                                                        question.required ||
                                                        false,
                                                    order: question.order,
                                                    max_points: question.max_points !== undefined ? Number(question.max_points) : null,
                                                    min_points_to_pass: question.min_points_to_pass !== undefined ? Number(question.min_points_to_pass) : null,
                                                    fail_quiz: question.fail_quiz,
                                                }
                                            )
                                            .then((createdQuestion) => {
                                                if (!question?.answers?.length)
                                                    return;
                                                const answersRequests = [];
                                                question.answers.forEach(
                                                    (answerOption) => {
                                                        answersRequests.push(
                                                            this.axios.post(
                                                                // `surveys/${surveyData.data.item.id}/sections/${createdSection.data.item.id}/questions/${createdQuestion.data.item.id}/answers`,
                                                                `quizzes/${quizData.data.item.id}/sections/${createdSection.data.item.id}/questions/${createdQuestion.data.item.id}/answers`,
                                                                {
                                                                    name: answerOption.name,
                                                                    order: answerOption.order,
                                                                }
                                                            )
                                                        );
                                                    }
                                                );
                                                return Promise.allSettled(
                                                    answersRequests
                                                );
                                            })
                                    );
                                    return Promise.allSettled(
                                        questionsRequests
                                    );
                                });
                            })
                    );
                });
                return Promise.allSettled(sectionsRequests).then(() => {
                    this.$emit('reload');
                    this.$emit('close');
                    this.helpersStore.snackbarSaved();
                    //emit reload
                });
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
            this.loading = false;
        },
        async putSurvey() {
            if (!this.quizTemplate?.id || !this.surveyFormDataValid) return;
            this.loading = true;
            try {
                await this.axios.put(
                    // `surveys/${this.quizTemplate?.id}`,
                    `quizzes/${this.quizTemplate.id}`,

                    {
                        name: this.name,
                        system: this.system,
                    });
            } catch (err) {
                console.error(err);
            }
            this.loading = false;
            this.helpersStore.snackbarSaved();
        },
    },
    watch: {

        name: {
            handler() {
                this.$refs?.surveyForm?.validate();
                this.helpersStore.debounce(async () => {
                    await this.putSurvey();
                }, 2000);
            },
            deep: true,
        },
        system() {
            this.helpersStore.debounce(async () => {
                await this.putSurvey();
            }, 2000);
        },
    },
    computed: {
        currentPageSections() {
            // if (this.step !== undefined) {
            //     return  this.sections.filter(
            //         (section) => section.page == this.step
            //     )
            //     .sort((a, b) => a.order - b.order);
            // }
            return [...this.sections].sort((a, b) => a.order - b.order);
        },
        maxLengthRules() {
            return [
                (v) => {
                    if (v?.length > 1024)
                        return this.$t('errors.maxLength') + '1024';
                    return false;
                },
            ];
        },
        required() {
            return [(v) => !!v || this.$t('common.fieldRequired')];
        },
        translatableRequired() {
            return [
                () => {
                    return (
                        this.authStore.tenant.supportedLocales.every(
                            (v) => this?.name?.[v]
                        ) || this.$t('errors.allLanguagesRequired')
                    );
                },
            ];
        },
        allValid() {
            return (
                this.surveyFormDataValid &&
                this.sections.every((s) => {
                    return (
                        s.valid &&
                        (!s.questions?.length ||
                            s.questions.every((sq) => {
                                return (
                                    sq.valid &&
                                    (sq.type ===
                                        this.$QUESTION_TYPES_ENUM.RADIO ||
                                        sq.type === this.$QUESTION_TYPES_ENUM.CHECK
                                        ? sq.answers?.length &&
                                        sq.answers?.every((sqa) => sqa.valid)
                                        : sq.valid)
                                );
                            }))
                    );
                })
            );
        },
    },
};
</script>

<style>
.add-page-button-container {
    display: flex;
    justify-content: flex-end;
}

.add-section-button-container {
    display: flex;
    justify-content: flex-end;
}
</style>

<style scoped>
.quiz-builder-container {
    display: flex;
    height: 80vh;
    overflow: hidden;
    position: relative;
}

.quiz-col {
    flex: 1;
    height: 100%;
    overflow: scroll
}

.quiz-col:first-of-type {
    padding: 8px;
}
</style>
