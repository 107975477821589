import i18n from '@/i18n';

export default Object.freeze({
    TODAY: {
        value: 'today',
        get label() {
            return i18n.global.t(`common.today`);
        },
    },
    DAY_MINUS_N: {
        value: 'day_minus_n',
        get label() {
            return i18n.global.t(`reports.dayMinusN`);
        },
    },
    CURRENT_WEEK: {
        value: 'current_week',
        get label() {
            return i18n.global.t(`reports.currentWeek`);
        },
    },
    LAST_WEEK: {
        value: 'last_week',
        get label() {
            return i18n.global.t(`reports.lastWeek`);
        },
    },
    CURRENT_MONTH: {
        value: 'current_month',
        get label() {
            return i18n.global.t(`reports.currentMonth`);
        },
    },
    LAST_MONTH: {
        value: 'last_month',
        get label() {
            return i18n.global.t(`reports.lastMonth`);
        },
    },
    CURRENT_YEAR: {
        value: 'current_year',
        get label() {
            return i18n.global.t(`reports.currentYear`);
        },
    },
    LAST_YEAR: {
        value: 'last_year',
        get label() {
            return i18n.global.t(`reports.lastYear`);
        },
    },
});
