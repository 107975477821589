export default [
    {
        text: 'tableHeaders.id',
        value: 'id',
        sortable: true,
    },
    {
        text: 'tableHeaders.name',
        value: 'name',
        sortable: true,
    },
    {
        text: 'tableHeaders.category',
        value: 'category',
        sortable: true,
    },
    {
        text: 'tableHeaders.manufacturerSymbol',
        value: 'symbol',
        sortable: true,
    },
    {
        text: 'common.entriesLocation',
        value: 'entries_location',
    },
    {
        text: 'tableHeaders.registryNumber',
        value: 'registry_no',
        sortable: true,
    },
    {
        text: 'tableHeaders.min',
        value: 'min_quantity',
        sortable: true,
    },
    {
        text: 'tableHeaders.opt',
        value: 'opt_quantity',
        sortable: true,
    },
    {
        text: 'tableHeaders.quantity',
        value: 'quantity',
        sortable: true,
    },
    {
        text: 'common.classification',
        value: 'classification',
    },
    {
        text: 'tableHeaders.price',
        value: 'max_price',
        sortable: true,
    },
    {
        text: 'common.unit',
        value: 'preferred_unit',
    },
    {
        text: 'tableHeaders.manufacturer',
        value: 'manufacturer_id',
        sortable: true,
    },
    { value: 'operation', width: 54, fixed: true },
];
