export const es = {
    common: {
        ok: 'Ok',
        authorizationRequiredDescriptionTerminal:
            'Usa tu @:common.PIN_CODE_LABEL para autorizar la operación.',
        countersValues: 'Valores de los contadores',
        countableId: 'ID del campo contable',
        defaultValuesForCategory: 'Valores predeterminados para la categoría',
        check_user: '¿Es el usuario?',
        check_terminal: '¿Es el terminal?',
        bannedEmployees: 'Empleados prohibidos',
        banEmployee: 'Prohibir entrada',
        banned: 'Prohibido',
        employeeBans: 'Prohibiciones de empleados',
        commentAboutEmployee: 'Se refiere al empleado',
        comments: 'Comentarios',
        visitLog: 'Registro de visitas',
        categoryStructure: 'Estructura de la categoría',
        appVersionMismatch: 'Versión de la aplicación incorrecta',
        appVersionMismatchMessage:
            'Actualiza la aplicación para sincronizar la versión.',
        PIN_CODE_LABEL: 'Código PIN',
        comment: 'Comentario',
        year: 'Año',
        configuration: 'Configuración',
        cancel: 'Cancelar',
        search: 'Buscar',
        remove: 'Eliminar',
        confirmationDialogTitle: '¿Está seguro de eliminar este elemento?',
        selectDate: 'Seleccionar fecha',
        select: 'Seleccionar',
        notFound: 'No se ha encontrado',
        equipmentPickerPlaceholder: 'Objeto de equipo',
        searchPlaceholder: 'Buscar',
        equipmentTypePickerPlaceholder: 'Tipo de equipo',
        equipmentTypePickerTitle: 'Selecciona el tipo de equipo',
        emptyTableMessage: 'No hay datos',
        flowPikcerTitle: 'Seleccionar flujo',
        selectIcon: 'Seleccionar icono',
        locationPickerTitle: 'Seleccionar ubicación',
        locationPlaceholder: 'Ubicación',
        noSublocations: 'No hay sublocalizaciones',
        locationsTitle: 'Ubicaciones',
        edit: 'Editar',
        save: 'Guardar',
        added: 'Añadido',
        updated: 'Actualizado',
        add: 'Añadir',
        reload: 'Recargar',
        removed: 'Eliminado',
        confirm: 'Confirmar',
        rollBack: 'Retroceder',
        images: 'Imágenes',
        documents: 'Documentos',
        download: 'Descargar',
        saved: 'Guardado',
        date: 'Fecha',
        hour: 'Hora',
        preview: 'Vista previa',
        data: 'Datos',
        attributes: 'Atributos',
        checkList: 'Lista de comprobación',
        name: 'Nombre',
        type: 'Tipo',
        status: 'Estado',
        designation: 'Designación',
        symbol: 'Símbolo',
        serialNumber: 'Número de serie',
        registryNumber: 'Número de registro',
        machineNumber: 'Número de máquina',
        udtNumber: 'Número UDT',
        manufacturedYear: 'Año de fabricación',
        manufacturer: 'Fabricante',
        supplier: 'Proveedor',
        mainSupplier: 'Proveedor principal',
        attribute: 'Atributo',
        nameRequired: 'El nombre es obligatorio',
        yearGreaterThan: 'El año debe ser mayor que',
        location: 'Ubicación',
        fieldRequired: 'Este campo es obligatorio',
        description: 'Descripción',
        minimumQuantity: 'Cantidad mínima',
        optimalQuantity: 'Cantidad óptima',
        priority: 'Prioridad',
        formatYYYY: 'Formato YYYY',
        storageQuantity: 'Cantidad de almacén',
        quantity: 'Cantidad',
        quantityGreaterThan: 'La cantidad debe ser mayor que',
        machine: 'Máquina',
        tool: 'Herramienta',
        order: 'Pedido',
        required: 'Requerido',
        dateFormat: 'Formato de fecha',
        selectDictionary: 'Seleccionar diccionario',
        return: 'Retorno',
        parameters: 'Parámetros',
        editCategory: 'Editar categoría',
        addCategory: 'Añadir nueva categoría',
        changeColor: 'Cambiar el color',
        noColor: 'Sin color',
        shift: 'Desplazamiento',
        start: 'Inicio',
        end: 'Fin',
        shiftSchema: 'Schift schema',
        shifts: 'Turnos',
        dictionary: 'Diccionario',
        subEntries: 'Subentradas',
        key: 'Clave',
        value: 'Valor',
        moveUp: 'Mover hacia arriba',
        moveDown: 'Mover hacia abajo',
        addLocation: 'Añadir nueva ubicación',
        editLocation: 'Editar ubicación',
        companiesDatabase: 'Base de datos de empresas',
        flows: 'Flujos',
        read: 'Leer',
        write: 'Escribir',
        noImage: 'Sin imagen',
        email: 'E-mail',
        function: 'Función',
        active: 'Activo',
        notActive: 'No activado',
        disabled: 'Desactivado',
        entryNumber: 'Número de entrada',
        issuedDate: 'Fecha de emisión',
        expiresDate: 'Fecha de caducidad',
        move: 'Movimiento',
        separateForm: 'Separado de',
        separate: 'Separado de',
        warehouse: 'Almacén',
        actions: 'Acciones',
        putInCurrentLocation: 'Poner en la ubicación actual',
        selectLocationFormRightMenu:
            'Seleccionar ubicación en el menú de la derecha',
        showUnassignedItems: 'Mostrar elementos no asignados',
        separated: 'Separado',
        moved: 'Movido',
        categories: 'Categorías',
        welcome: '¡Bienvenido',
        loginToSignaloTpm: 'Iniciar sesión en Signalo TPM',
        login: 'Entrar',
        loginByEmailAndPassword: 'Entrar por correo electrónico y contraseña',
        loginByCode: 'Entrar por @:common.PIN_CODE_LABEL',
        accessCode: 'Código de acceso',
        enterEmail: 'Introduzca el correo electrónico',
        enterPassword: 'Introduzca la contraseña',
        enterCode: 'Introduzca el @:common.PIN_CODE_LABEL',
        userNotFound: 'Usuario no encontrado',
        userNotActivated: 'Usuario no activado',
        userDisabled: 'Usuario desactivado',
        wrongPassword: 'Contraseña incorrecta',
        locations: 'Ubicaciones',
        pageNotFound: 'Página no encontrada',
        group: 'Grupo',
        yes: 'Sí',
        no: 'No',
        hourlyCost: 'Coste por hora',
        price: 'Precio',
        newEntry: 'Nueva entrada',
        predictedFinishTime: 'Tiempo previsto para la finalización',
        archive: 'Archivo',
        costs: 'Costes',
        companyManagement: 'Dirección de la empresa',
        companyData: 'Datos de la empresa',
        supportedLanguages: 'Idiomas admitidos',
        en: 'Inglés',
        pl: 'Polaco',
        es: 'Español',
        de: 'Alemán',
        it: 'Italiano',
        fr: 'Francés',
        ru: 'Ruso',
        uk: 'Ucraniano',
        pt: 'Portugués',
        tr: 'Turco',
        nl: 'Neerlandés',
        sr: 'Serbio',
        ar: 'Árabe',
        he: 'Hebreo',
        zh: 'Chino',
        defaultLanguage: 'Idioma por defecto',
        companyLogo: 'Logotipo de la empresa',
        notificationsManagement: 'Gestión de las notificaciones',
        category: 'Categoría',
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        close: 'Cerrar',
        show: 'Mostrar',
        rollBackFormalConfirmation: 'Cancelar la confirmación formal',
        signUp: 'Registrarse',
        nameSurname: 'Nombre y apellido',
        phone: 'Número de teléfono',
        password: 'Contraseña',
        password_confirmation: 'Repita la contraseña',
        unassignedItems: 'Partidas no asignadas',
        currency: 'Moneda',
        general: 'General',
        timezone: 'Zona horaria',
        users: 'Usuarios',
        groups: 'Grupos',
        productionLine: 'Línea de producción',
        part: 'Pieza',
        fittingParts: 'Piezas de repuesto que coinciden',
        workSchema: 'Esquema de trabajo',
        workSchemas: 'Esquemas de trabajo',
        holiday: 'Vacaciones',
        changePassword: 'Cambiar contraseña',
        showRest: 'Mostrar descanso',
        pageNotPermitted: 'Página no permitida',
        waitingForActivation: 'A la espera de la activación',
        editGroup: 'Editar grupo',
        addGroup: 'Añadir el grupo',
        reportProblem: 'Informar del problema',
        describeOccuredIssue: 'Describa el problema ocurrido',
        markAllAsRead: 'Marcar todo como leído',
        unitType: 'Tipo de unidad',
        unit: 'Unidad',
        unitPrice: 'Precio por unidad',
        notConfigured: 'No está configurado',
        rowsPerPage: 'Líneas por página',
        rowsOfPage: 'de',
        true: 'Verdadero',
        false: 'Falso',
        export: 'Exportar',
        chooseFileFormat: 'Seleccione el formato del archivo',
        exported: 'Exportado',
        terminals: 'Terminales',
        terminal: 'Terminal',
        selectEquipment: 'Seleccione el equipo',
        futureIssues: 'Cuestiones de futuro',
        scheduledOn: 'Programado el',
        planned: 'Planificado',
        notPlanned: 'No planificado',
        showOpenIssues: 'Mostrar cuestiones abiertas',
        pin: 'PIN',
        assignedEquipment: 'Equipo asignado',
        terminalEquipment: 'Equipos terminales',
        detach: 'Separe',
        attach: 'Adjuntar',
        dashboardConfig: 'Configuración del salpicadero',
        addColumn: 'Añadir  columna',
        addRow: 'Añadir una línea',
        removeRow: 'Eliminar línea',
        registerTerminal: 'Registrar terminal',
        issueGeneratesCost: 'El tiempo de inactividad genera costes',
        selectColor: 'Elija un color',
        belowMinimum: 'Por debajo del mínimo',
        belowOptimum: 'Por debajo del óptimo',
        synchronization: 'Sincronización',
        synchronizationExport: 'Exportación de piezas de stock',
        synchronizationImport: 'Importación de piezas de stock',
        mapping: 'Cartografía',
        returnTo: 'Volver a',
        jobStatusses: 'Procesos',
        hidden: 'Oculto',
        editEvent: 'Editar evento',
        threeDays: '3 días',
        addEvent: 'Añadir evento',
        plannedIssues: 'Tareas programadas',
        currentService: 'Servicio actual',
        searchLocation: 'Buscar ubicación',
        all: 'Todos',
        unfinished: 'Sin terminar',
        numberOfIssues: 'Cantidad de asuntos',
        unknown: 'Desconocido',
        partEntry: 'Pieza (Entrada)',
        foundItems: 'Objetos encontrados',
        output: 'Salida',
        copied: 'Copiado',
        link: 'Enlace',
        authorizationRequired: 'Autorización necesaria',
        authorizationRequiredDescriptionUser:
            'Lamentablemente, no dispone de autorización suficiente. Pida a una persona autorizada que apruebe la acción.',
        authorizationRequiredTerminal:
            'Utilice su @:common.PIN_CODE_LABEL de acceso para autorizar la operación.',
        showAll: 'Mostrar todo',
        showClassicView: 'Mostrar vista clásica',
        externalSystems: 'Sistemas externos',
        noIssuesInCategory: 'No hay problemas en esta categoría.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Número de temas en esta categoría y subcategorías.',
        numberOfIssuesInThisCategory: 'Número de números en esta categoría.',
        minBarcodeLength: 'Longitud mínima del código de barras',
        currentPage: 'Página actual',
        allPages: 'Todas las páginas',
        selectFormat: 'Seleccionar formato',
        provided_currency: 'Moneda suministrada',
        provided_quantity: 'Cantidad suministrada',
        provided_unit: 'Unidad suministrada',
        provided_unit_price: 'Precio unitario proporcionado',
        unit_price: 'Precio unitario',
        quantity_available: 'Cantidad disponible',
        editBarcode: 'Editar código de barras',
        crews: 'Equipos',
        scheduleEventTypes: 'Tipos de eventos programados',
        crew: 'Equipo',
        scheduleEvent: 'Programar evento',
        available: 'Disponible',
        'App\\Models\\Crew': 'Equipo',
        'App\\Models\\User': 'Usuario',
        'App\\Models\\Equipment': 'Equipamiento',
        Crew: 'Equipo',
        User: 'Usuario',
        Equipment: 'Equipamiento',
        scheduleEvents: 'Programar eventos',
        event: 'Evento',
        user: 'Usuario',
        availability: 'Disponibilidad',
        inheritColorFromParentIfCustomNotSet:
            'Hereda el color del padre si no se ha definido personalizado',
        pickFromAllParts: 'Elige entre todas las partes',
        takeTool: 'Toma la herramienta',
        returnTool: 'Herramienta de retorno',
        personWhoTake: 'Persona que toma',
        personWhoReturn: 'Persona que devuelve',
        takenAt: 'Fecha de descarga',
        takenBy: 'Colector',
        toolIssuedBy: 'Persona expedidora',
        commentTake: 'Comentario al descargar',
        commentReturn: 'Devolver comentario',
        change: 'Cambia',
        onlyWithActiveRMIssues: 'Solo con solicitudes de RM activas',
        externalSystem: 'Sistema externo',
        rejectIssue: 'Cuestión rechazada',
        withdrawIssueRejection: 'Retirar el rechazo de emisión',
        rejectionReason: 'Motivo del rechazo',
        rejected: 'Rechazado',
        showCrewMembers: 'Miembros del equipo',
        crewMembers: 'Miembros de la tripulación',
        helpGroup: 'Servicio',
        partsTakenByUser: 'Piezas tomadas por el usuario',
        pickParts: 'Rendimiento de piezas',
        selectPersonWhoTakes: 'Seleccionar persona que toma',
        changePersonWhoTakes: 'Cambiar persona que toma',
        quantityToPick: 'Cantidad a elegir',
        pickLocation: 'Lugar de recogida',
        selectPartsToPick: 'Seleccione las piezas a recoger',
        partSelected: 'Parte seleccionada',
        scanCodeOr: 'Escanear código o',
        returnPart: 'Pieza de retorno',
        myTakenParts: 'Piezas descargadas por mí',
        takenFromLocation: 'Tomado del lugar',
        takenQuantity: 'Cantidad tomada',
        providedUnit: 'Unidad proporcionada',
        providedQuantity: 'Cantidad proporcionada',
        selectFromStorage: 'Seleccione el almacenamiento',
        selectFromMyPickedParts: 'Seleccionar de mis partes elegidas',
        exportProcessInProgressMessage:
            'El proceso de exportación de datos acaba de comenzar, cuando finalice verá la notificación correspondiente con un enlace al archivo.',
        yourDocumentIsReady: 'El documento está listo',
        rotateScreen: 'Girar pantalla',
        addRule: 'Añadir regla',
        editRule: 'Editar regla',
        summary: 'Resumen',
        onlyAvailableEmployees: 'Mostrar sólo los empleados disponibles',
        calculated_unit_price: 'Precio unitario convertido',
        calculated_quantity: 'Cantidad convertida',
        next: 'Siguiente',
        maintenanceMode: 'Modo de mantenimiento',
        applicationMayBeUnavailableForFewMinutes:
            'La aplicación puede no estar disponible durante unos minutos',
        appWillRefreshIn: 'La aplicación se actualizará en',
        landscape: 'Horizontal',
        portrait: 'Vertical',
        orientation: 'Orientación',
        format: 'Formato',
        service: 'Criados',
        leader: 'Capataces',
        defaultView: 'Vista por defecto',
        view: 'Vista',
        oneDay: '1 día',
        object: 'Objeto',
        finished: 'Terminado',
        onlyNotFinished: 'Solo que no termino',
        generate: 'Generar',
        error: 'Error',
        cell: 'Celda',
        resolutionSummary: 'Resumen final',
        rejected_at: 'Fecha de rechazo',
        rejected_by: 'Rechazado por',
        finishedByUser: 'Completado por',
        formalConfirmByUser: 'Confirmación formal por',
        formalConfirmAt: 'Fecha de confirmación formal',
        valueDeleted: '[Valor eliminado]',
        hasNotPlannedIssue: 'No ha planificado problemas',
        showInStorage: 'Mostrar en almacenamiento',
        changeCode: 'Cambiar el @:common.PIN_CODE_LABEL',
        staffProfiles: 'Perfiles de empleados',
        profile: 'Perfil',
        user_bug: 'Error de la aplicación',
        user_kaizen: 'Sugerencia de cambio',
        changeSuggestions: 'Cambiar sugerencias',
        showOnlyCurrentlyAvailable: 'Mostrar solo disponible actualmente',
        resetPassword: 'Recordar contraseña',
        forgotPassword: 'Has olvidado tu contraseña',
        resetPasswordMailSent:
            'Hemos enviado un enlace para establecer una nueva contraseña a la dirección de correo electrónico proporcionada',
        manyEventsThisTime: 'Muchos eventos en este momento.',
        importScheduleCheckboxLabel:
            'Eliminar eventos programados en el mes seleccionado para los empleados que figuran en el archivo',
        inheritedEvent: 'Evento heredado',
        inheritedModifiedEvent: 'Evento heredado modificado',
        equipmentId: 'ID del equipo',
        entryId: 'ID de entrada',
        partId: 'ID de la pieza',
        suggestionsEmail: 'Email al que se enviarán las sugerencias de cambio',
        meters: 'Contadores',
        scanCardToAuth:
            'Acerque la tarjeta al lector o ingrese su @:common.PIN_CODE_LABEL de acceso para confirmar su identidad',
        scanCard: 'Escanea la tarjeta',
        cart: 'Caja de intercambio',
        emptyCardMessage:
            'Comience a escanear productos o selecciónelos de la lista para agregarlos a la cesta.',
        terminalMode: 'tipo de terminales',
        askPermittedUserToConfirmPartsExchange:
            'Pida a la persona autorizada que confirme la liberación de la pieza escaneando la tarjeta o ingresando el @:common.PIN_CODE_LABEL',
        partsIssued: 'Piezas liberadas',
        areYouSureAboutCancel: '¿Estás seguro de que quieres cancelar?',
        areYouSureAboutRemoveFromCart:
            '¿Estás seguro de que quieres eliminar este producto del carrito?',
        standard: 'Estándar',
        idNumber: 'Número de identificación',
        takeParts: 'Tomar piezas',
        returnParts: 'Devolver piezas',
        quantityToReturn: 'Cantidad a devolver',
        partsPendingApproval: 'Piezas pendientes de aprobación',
        partsReturned: 'Piezas devueltas',
        partsAddedToPendingApproval:
            'Piezas agregadas a la lista de piezas pendientes de aprobación',
        approvePartsReturn: 'Aprobar devolución de piezas',
        partReturnApproved: 'Devolución de piezas aprobada',
        partUsage: 'Uso de piezas',
        awaitsReturn: 'Espera devolución',
        entrySelection: 'Selección de entrada',
        correctQuantity: 'Cantidad correcta',
        quantityCorrection: 'Corrección de cantidad',
        entrySelectionAsc: 'Ascendente',
        entrySelectionDesc: 'Descendente',
        metersSettings: 'Configuración de contadores',
        countableFields: 'Campos contables',
        equipmentTypes: 'Tipos de equipos',
        fieldName: 'Nombre del campo',
        countableField: 'Campo contable',
        partsStatusTaken: 'Tomado',
        rejectPartsReturn: 'Rechazar devolución de piezas',
        areYouSureAboutRejectPartsReturn:
            '¿Estás seguro de que quieres rechazar la devolución de piezas?',
        partReturnRejected: 'Devolución de piezas rechazada',
        counterFields: 'Campos de contadores',
        counterName: 'Nombre del contador',
        counterFieldName: 'Nombre del campo del contador',
        counterId: 'ID del contador',
        counterUUID: 'UUID del contador',
        counterPickerPlaceholder: 'Seleccione un contador',
        pickFromPartsList: 'Elegir de la lista de piezas',
        changeLocation: 'Cambiar ubicación',
        isPartStorageRoot: 'Raíz del almacenamiento de piezas',
        areYouSureAboutArchiveIssue:
            '¿Estás seguro de que quieres archivar este problema?',
        ganttChart: 'Diagrama de Gantt',
        table: 'Tabla',
        fittingEquipment: 'Equipo de ajuste',
        notAssigned: 'No asignado',
        counters: 'Contadores',
        machineHourlyCostNotProvided:
            'El costo por hora de la máquina no se proporcionó',
        eventTypesMightBeDefinedHere:
            'Los tipos de eventos se pueden definir aquí',
        toolsTakenByUser: 'Herramientas tomadas por el usuario',
        predictedReturnDate: 'Fecha de devolución prevista',
        estimatedTimeOfUsingToolInHours:
            'Tiempo estimado de uso de la herramienta (en horas)',
        showOnlyAvailable: 'Mostrar solo disponible',
        toolTakeLog: 'Registro de toma de herramientas',
        takeDate: 'Fecha de toma',
        returnDate: 'Fecha de devolución',
        personWhoAcceptReturn: 'Persona que acepta la devolución',
        statisticStrategy: 'estrategia de accesibilidad',
        EquipmentPlannedStrategy: 'Según el plan de trabajo',
        EquipmentConstantAvailabilityStrategy: 'Siempre disponible',
        actionAfterIssueFinish: 'Acción después de que finalice el problema',
        enabled: 'Habilitado',
        categoryOfNewIssue: 'Categoría de nuevo problema',
        workNotStarted: 'Trabajo no iniciado',
        colorInherited: 'Color heredado',
        abcClassification: 'Clasificación ABC',
        xyzClassification: 'Clasificación XYZ',
        classification: 'Clasificación',
        filters: 'Filtros',
        wipeFilters: 'Borrar filtros',
        takePartsFromUser: 'Tomar piezas del usuario',
        selectPersonWhoReturns: 'Seleccione la persona que devuelve las piezas',
        changePersonWhoReturns: 'Cambiar la persona que devuelve las piezas',
        returnLocation: 'Ubicación de devolución',
        returnQuantity: 'Cantidad de devolución',
        globalSettings: 'Ajustes globales',
        delimiter: 'Delimitador',
        fieldRules: 'Reglas de campo',
        fieldValue: 'Valor del campo',
        user_email_regex: 'Expresión regular de correo electrónico de usuario',
        barcodeLength: 'Longitud del código de barras',
        partLine: 'Línea de piezas',
        partFamily: 'Familia de piezas',
        partBatch: 'Lote de piezas',
        testValueValid: 'Valor de prueba válido',
        testValueNotValid: 'Valor de prueba no válido',
        canBePicked: 'Se puede elegir',
        issue_create_photos: 'Añadir fotos a un número nuevo',
        issue_create_documents: 'Adición de documentos a un problema nuevo',
        propagateValues: 'Propagación de valor',
        archived: 'Archivado',
        escalations_tab: 'Escalaciones',
        minute: 'Minuto',
        days: 'Días',
        hours: 'Horas',
        minutes: 'Minuto',
        warnDays: 'Alerta antes del vencimiento (días)',
        licenseType: 'Tipo de licencia',
        licenseGroup: 'Grupo de licencia',
        attachments: 'Adjuntos',
        attachment: 'Adjunto',
        revisionChanges: 'Cambios introducidos',
        document: 'Documento',
        noInstructionSelected: 'Ninguna instrucción seleccionada',
        instructionGroups: 'Grupos de instrucciones',
        instructionGroup: 'Grupo de instrucción',
        addOption: 'Agregar una opción',
        ordinal: 'valor ordinal',
        parameterName: 'Nombre del parámetro',
        fontColor: 'Color de fuente',
        backgroundColor: 'Color de fondo',
        columns: 'Número de columnas',
        colspan: 'Ancho (columnas)',
        products: 'Productos',
        controlsTemplates: 'Plantillas de control',
        controlType: 'Tipo de control',
        controls: 'Inspecciones',
        controlTemplate: 'Plantilla de control',
        product: 'Producto',
        checklistsAttachedToControlTemplate:
            'Listas de verificación asignadas a las inspecciones',
        removeChecklistFromControlTitle:
            '¿Está seguro de que desea eliminar esta lista de su control?',
        attachChecklistToControl: 'Asignar una lista para controlar',
        pickedItem: 'Item seleccionado',
        pick: 'Elegir',
        fillControl: 'Completa el cheque',
        back: 'Devolver',
        control: 'Control',
        duplicate: 'Duplicar',
        isStorageRoot: 'Ubicación principal del almacén',
        manageStorageAccess: 'Gestionar el acceso a los almacenes',
        manageVisibleColumns: 'Ajustar la visibilidad de la columna',
        columnName: 'Nombre de columna',
        visible: 'Visible',
        entriesLocation: 'ubicación de entradas',
        details: 'Detalles',
        otherData: 'Otros datos',
        moveAllEntriesFromCurrentLocation:
            'Mover todas las entradas de esta ubicación',
        searchInCurrentLocation: 'Buscar en su ubicación actual',
        transition: 'Transición',
        notification: 'Notificación',
        emails: 'Correos electrónicos',
        phones: 'Números de teléfono',
        addAction: 'Agregar una acción',
        message: 'Mensaje',
        application: 'Aplicación',
        always: 'Siempre',
        sms: 'SMS',
        loginTo: 'Iniciar sesión en',
        appName: 'Signalo TPM',
        index: 'Índice',
        showCodeOwnerNameDuringParing:
            'Mostrar el nombre del propietario del @:common.PIN_CODE_LABEL al vincularlo con una tarjeta/etiqueta',
        showUsernameInCart: 'Mostrar nombre de usuario en el carrito',
        sampleButtonPlaceholder: 'Botón de muestra',
        preferences: 'Preferencias',
        buttonsSize: 'Tamaño del botón',
        erase: 'Borrar',
        selectDictionaryValue: 'Seleccione el valor del diccionario',
        conditions: 'Condiciones',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Requerir confirmación o rechazo al devolver piezas descargadas',
        issueCreated: 'Se ha creado un ticket',
        allowedIssuesCategories: 'Categorías de problemas permitidos',
        hiddenFields: 'Campos ocultos',
        defaultValues: 'Valores predeterminados',
        paddingBottom: 'Espacio vacío en la parte inferior',
        allVisibleFieldsRequired:
            'Requerir que se completen todos los campos visibles',
        url: 'URL',
        kind: 'Amable',
        forkfleetConfigId: 'Configuración Forkfleet',
        create: 'Crear',
        terminate: 'Terminar',
        parent: 'Padre',
        phoneNumbers: 'Números de teléfono',
        storageDefaults: 'Valores de almacenamiento predeterminados',
        clientSignatureEnabled: 'Firma de la persona que recoge',
        warehousemanSignatureEnabled: 'Firma del almacenista',
        storage_client_signature_enabled:
            'Firma de la persona que retira los artículos del almacén.',
        storage_client_signature_required:
            'Requerir la firma de la persona que retira los artículos del almacén.',
        storage_warehouseman_signature_enabled:
            'Firma de la persona que retira los artículos del almacén.',
        storage_warehouseman_signature_required:
            'Requerir la firma de la persona que libera los artículos del almacén.',
        clientReturnSignatureEnabled: 'Firma de la persona que regresa',
        warehousemanReturnSignatureEnabled:
            'Firma de la persona que acepta la devolución',
        storage_client_return_signature_enabled:
            'Firma de la persona que devuelve los artículos.',
        storage_client_return_signature_required:
            'Requerir una firma de la persona que devuelve los artículos.',
        storage_warehouseman_return_signature_enabled:
            'Firma de la persona que acepta la devolución del artículo.',
        storage_warehouseman_return_signature_required:
            'Requerir la firma de la persona que acepta la devolución del artículo.',
        channels: 'Canales',
        manageState: 'Administrar estado',
        assignWorker: 'Asignar un empleado',
        mergeEntriesOfTheSameLocation:
            'Fusionar entradas de la misma ubicación',
        permission: 'Permiso',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            '¿Está seguro de que desea fusionar todas las entradas en esta ubicación?',
        manageFilters: 'Administrar filtros',
        responsible_assign_creator_when_no_rule:
            '[Informes] - Asignar creador como responsable si la regla no coincide',
        recreateIssue: 'Vuelva a crear el billete',
        addNewDictionary: 'Agregar un nuevo diccionario',
        label: 'Etiqueta',
        issuesWithRelatedCategories: 'Incluir subcategorías',
        issuesLimit: 'Límite de emisiones',
        issuesLimits: 'Problemas - límites',
        issueDefaults: 'Informes: valores predeterminados',
        flatCategoryPickerLabel: 'Estructura de categoría plana',
        statementsTemplates: 'Plantillas de estados de cuenta',
        statementTemplate: 'Plantilla de declaración',
        content: 'Contenido',
        readonly: 'Solo lectura',
        defaultValue: 'Valor por defecto',
        fillParametersToChangeState:
            'Complete los valores de los parámetros a continuación para cambiar el estado',
        formatPattern: 'Patrón de formato',
        countableIncFields: 'Configuración de numeración de documentos',
        mainShift: 'Turno principal',
        singleEvent: 'Sola entrada',
        seriesOfEvents: 'Una serie de entradas',
        omitWeekends: 'Evite los fines de semana',
        allEntriesSaved: 'Todas las entradas han sido agregadas.',
        surveyResults: 'Resultados de la encuesta',
        qrActionsSettings: 'Configuración de la acción del código QR',
        pendingStatements: 'Declaraciones a firmar',
        statementsResults: 'Declaraciones',
        statements: 'Declaraciones',
        statement: 'Declaración',
        userCanChangeContent: 'El usuario puede cambiar el contenido.',
        statementsEvents: 'Configuración de eventos - declaraciones',
        helpdesk: 'Mesa de ayuda',
        terminalId: 'Identificación del terminal',
        operatorId: 'Identificación del operador',
        newConversation: 'Nuevo hilo',
        formula: 'Fórmula',
        fill: 'Completo',
        webMessage: 'mensaje web',
        noResponsibleUser: 'Ningún usuario responsable',
        filledAtDate: 'Fecha de realización',
        filledBy: 'Completado por',
        parts_quantity_alert_rule:
            '[Cantidad de piezas y materiales] - Advertencia',
        employeeNumber: 'Número de empleado',
        groupsAssignedToCategory: 'Grupos asignados a categorías',
        number: 'Número',
        customers: 'Clientes',
        customer: 'Cliente',
        projects: 'Proyectos',
        project: 'Proyecto',
        report: 'Informe',
        reportGenerationInProgress: 'El informe está generando',
        storageStatementGroup: 'Grupo de declaración de almacén',
        simplifiedHandling: 'Manejo de notificaciones simplificado',
        selectWorkArea: 'Selecciona tu área de trabajo',
        workAreaSelection: 'Seleccionar un área de trabajo',
        access: 'Acceso',
        restrictAccess: 'Acceso restringido',
        anchor: 'Anclaje',
        issueCreator: 'La persona que crea el informe.',
        hideSideMenu: 'Ocultar menú lateral',
        appendGroups: 'Grupos para agregar',
        revokeGroups: 'Grupos para eliminar',
        override: 'Sobrescribir',
        restrictGroupAccess: 'Restringir el acceso a grupos',
        flag: 'Bandera',
        flags: 'Banderas',
        manageFlags: 'Administrar banderas',
        icon: 'Icono',
        appendFlags: 'Banderas para agregar',
        revokeFlags: 'Banderas que se eliminarán',
        noFlagsConfigured: 'No hay banderas definidas',
        checkIsTerminal: 'Comprobando el terminal',
        noConditionsDefined: 'No hay condiciones definidas',
        checkIsResponsible: 'control de responsabilidad',
        createdOn: 'Reportado desde',
        replaceTimespan: 'Vida útil desde el momento de su emisión.',
        months: 'Meses',
        handoversTemplates: 'Plantillas de traspasos',
        handoverTemplate: 'Plantilla de entrega',
        employeeFunction: 'Función de empleado',
        takeHandover: 'tomar el traspaso',
        handovers: 'Traspasos',
        expiringHandovers:
            'Artículos que están cerca de su fecha de vencimiento',
        years: 'Años',
        taken_parts_expiry_warning:
            'Advertencia antes de la fecha de vencimiento de los elementos descargados',
        andonView: 'Vista de Andón',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'Otro',
        tags: 'Tarjetas / Etiquetas',
        tag: 'Tarjeta / Etiqueta',
        userPinCode: '@:common.PIN_CODE_LABEL de usuario de tarjeta',
        emailConfirmation: 'Confirmación de dirección de correo electrónico',
        from: 'De',
        to: 'A',
        handoversHistory: 'Historial de traspasos',
        partUsagesHistory: 'Historial de usos de piezas',
        signaturePinTagConfirmationText1: 'Firmar con @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'o escanea tu tarjeta.',
        signedWithPinCode: 'Firmado con @:common.PIN_CODE_LABEL',
        signedWithTAG: 'Firmado con tarjeta/etiqueta',
        test_mode: 'Modo de prueba',
        confirmWorkFinish: 'Confirmar el final del trabajo',
        workStarted: 'Inicio de trabajo',
        workFinished: 'Trabajo terminado',
        workTimeRegister: 'Registro de tiempo de trabajo',
        measureTypes: 'Tipos de medidas',
        measureType: 'Tipo de medida',
        measurable: 'Indicador medible',
        measurables: 'Indicadores medibles',
        measurements: 'Medición',
        measurement: 'Medición',
        measuredAt: 'Fecha de medición',
        currentVersion: 'Versión actual',
        employee: 'Empleado',
        pin_code_label: 'nombre PIN',
        orders: 'Pedidos',
        productionPlan: 'Plan de producción',
        productionPlans: 'Planes de producción',
        taktTime: 'tiempo takt',
        target: 'Objetivo',
        seconds: 'Segundos',
        inheritValuesFromTenantSettings:
            'Heredar valores predeterminados de la configuración global',
        matrices: 'Matrices',
        manageMatrice: 'Manage Matrix',
        deletionOfMatrixColumnWarnMessage:
            'Deleting a column deletes the values.',
        removeColumn: 'Delete a column',
        editColumn: 'Edit column',
        column: 'Column',
        row: 'Row',
        evaluated: 'Calculated',
        of: 'Of',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - Generación automática',
        or: 'o',
        rename: 'Rebautizar',
        check_only_on_transition: 'Verifique solo cuando cambie el estado',
        license: 'Potestades',
        confirmWorkStart: 'Confirmar inicio de obra',
        workStartAt: 'Empiezas a trabajar en',
        workFinishAt: 'Terminas tu trabajo o',
        workStartedAt: 'Empezando',
        workTimeMessage: 'trabajaste demasiado',
        haveANiceDay: '¡Que tenga un lindo día!',
        farewellText: 'Texto en la última pantalla',
        namedayLabel: 'Celebran su onomástica',
        sportEventNearby: 'El próximo evento deportivo',
        autoTranslate: 'Traducción automática',
        auto_translation: 'Traducción automática',
        awaitingAutoTranslation: 'Esperando traducción automática',
        inheritAllDefaultValues:
            'Heredar todos los valores predeterminados de la configuración global',
        escalations: 'Escaladas',
        escalation: 'Escalada',
        notTaken: 'No empezado',
        notFinished: 'Inconcluso',
        issueLastChange: 'Ultimo cambio',
        mode: 'Modo',
        repeatLastEscalation: 'Repita el último valor de escalada',
        manageActions: 'Gestiona tus acciones',
        productionLines: 'Líneas de producción',
        productionTerminal: 'Terminal de producción',
        currently: 'Actualmente',
        currentPlan: 'Plan actual',
        currentlyInProduction: 'Actualmente producido',
        setProduct: 'Establecer producto',
        second: 'Segundos',
        work: 'Trabajar',
        chartConfiguration: 'Configuración del gráfico',
        revisionsToConsider: 'Revisiones para consideración',
        allInstructions: 'Todas las instrucciones',
        decider: 'Determinante',
        considerRevision: 'Considere la revisión',
        and: 'y',
        acceptInstruction: 'Acepta las instrucciones',
        accept: 'aceptar',
        instructionsToAccept: 'Instrucciones para leer',
        open: 'Abierto',
        assignedAt: 'Fecha de cesión',
        confirmationAt: 'Fecha de confirmación',
        mainInstruction: 'Instrucción predeterminada',
        setAsDefault: 'Establecer por defecto',
        instructionsFlows: 'Instrucciones - flujos',
        newRevision: 'Nueva revisión',
        playlist: 'Lista de vídeos',
        selectVideo: 'Seleccione una película',
        selectVideos: 'Seleccionar películas',
        uploadNewVideo: 'Subir nuevo vídeo',
        upload_date: 'Fecha Agregada',
        thumbnail: 'miniatura',
        duration: 'Duración',
        uploadFromDrive: 'Subir desde disco',
        selectAssets: 'Elige materiales',
        assets: 'Materiales',
        video: 'Película',
        doYouWantToAddNewMarker:
            '¿Le gustaría agregar una nueva etiqueta aquí?',
        areYouSureAboutRemoveMarker:
            '¿Estás seguro de que deseas eliminar esta etiqueta?',
        plants: 'Plantas de fábrica',
        plant: 'Fábrica',
        buildings: 'Edificios',
        building: 'Edificio',
        floors: 'Pisos',
        floor: 'Piso',
        map: 'Mapa',
        areYouSureAboutMoveMarker:
            '¿Estás seguro de que quieres mover este marcador?',
        marker: 'Marcador',
        addNewMarkerTooltipMessage:
            'Para agregar un nuevo marcador, haga clic derecho en el mapa.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Habilidades no lineales',
        skillLevelConfig: 'Niveles de habilidad',
        indirectSkillGroups: 'Grupos de habilidades no lineales',
        approved: 'Confirmado',
        approvedBy: 'Aprobador',
        skillLevel: 'Nivel de habilidad',
        requiredSkillLevel: 'Nivel de habilidades requerido',
        step: 'Paso',
        image: 'Foto',
        skills: 'Habilidades',
        visits: 'Visitas',
        responsiblePerson: 'Persona responsable',
        company: 'Empresa',
        contactData: 'Detalles de contacto',
        visit: 'Visita',
        inProgress: 'En progreso',
        gatehouse: 'puerta de entrada',
        confirmEnter: 'Confirmar entrada',
        confirmExit: 'Confirmar salida',
        enter: 'Entrada',
        exit: 'Salir',
        entryGuard: 'La persona que deja entrar',
        exitGuard: 'La persona que deja salir',
        loginAsThisUser: 'Inicia sesión como este usuario',
        minBrowserVersion: 'Versión mínima del navegador requerida',
        approve: 'Confirmar',
        cancelApproval: 'Cancelar confirmación',
        workPermit: 'permiso de trabajo',
        workPermits: 'Permisos de trabajo',
        visit_summary: 'Resumen de la visita',
        createVisit: 'Crear una visita basada en un permiso',
        employeesAtThePlant: 'Trabajadores en la planta',
        purpose: 'Objetivo',
        allowedAreas: 'Áreas permitidas',
        switchEmployees: 'Reemplazar empleado',
        localIssues: 'Informes locales',
        cmmsCategoriesSync: 'Sincronización de categorías CMMS',
        terminalsGroups: 'Grupos de terminales',
        isTree: 'Árbol',
        isReporter: 'es el reportero',
        isAnchored: 'esta anclado',
        closable: 'Entradas de cierre',
        areYouSureAboutCloseIssue:
            '¿Estás seguro de que quieres cerrar el ticket?',
        simplifiedIssuesRefreshLabel:
            'Tiempo de actualización de la lista: segundos',
        reloadApp: 'Recargar',
        hideWorkAreasButton: 'Ocultar botón de selección de área de trabajo',
        hideClassicViewButton: 'Ocultar botón de vista clásica',
        hideAddIssueButton: 'Ocultar botón de añadir nuevo problema',
        filesManager: 'Gestor de archivos',
        addFolder: 'Añadir carpeta',
        upload: 'Subir',
        noFolders: 'No hay carpetas',
        noFiles: 'No hay archivos',
        folders: 'Carpetas',
        folder: 'Carpeta',
        files: 'Archivos',
        file: 'Archivo',
        removeFolderFilesQuestion:
            '¿Qué hacer con los archivos en esta carpeta?',
        targetFolder: 'Carpeta de destino',
        addInstruction: 'Añadir instrucción',
        goToFolder: 'Ir a la carpeta',
        defaultProdLine: 'Línea de producción predeterminada',
        pin_code_field_type: 'Tipo de código PIN',
        instructionsAccess: 'Permisos',
        noSections: 'Sin secciones',
        requireReadingInstruction: 'Requerir lectura de la instrucción',
        hide_parameter_types: 'No mostrar tipos de parámetros',
        unknownVersion: 'Desconocido',
        displayInstructionEveryTime: 'Mostrar la instrucción cada vez',
        requireSigningInstructionEveryTime:
            'Requerir firma de la instrucción cada vez',
        signedInstructions: 'Instrucciones firmadas',
        signedAt: 'Fecha de la firma',
        signatures: 'Firmas',
        signature: 'Firma',
        systemGroup: 'Grupo del sistema',
        pickable: 'Seleccionable',
        leaf: 'Elemento seleccionado',
        path: 'Ruta',
        displayMode: 'Modo de visualización',
        chart: 'Cuadro',
        preSignedStatement: 'Declaración firmada en papel (completa)',
        myOpenIssues: 'Mis problemas abiertos',
        myAssignedIssues: 'Problemas asignados a mí',
        displayParametersOnMainScreen:
            'Ver parámetros en la pantalla principal.',
        lockPin: 'Bloquear cambio de PIN',
        pinLocked: 'PIN bloqueado',
        sortChange: 'Orden de visualización',
        asc: 'Ascendente',
        desc: 'Descendente',
        alphanumeric: 'Alfanumérico',
        numeric: 'Numérico',
        modificationDate: 'Fecha de modificación',
        fromNewest: 'Desde los más nuevos',
        fromOldest: 'Desde los más antiguos',
        negation: 'Negación',
        sortOrderService: 'Servicio',
        newIssueIgnoreFilters:
            'Ignorar los filtros aplicados al crear un nuevo problema',
        refreshInterval: 'Tiempo de actualización de la lista',
        multiline: 'Múltiples líneas',
        expanded: 'Expandido',
        quizzes: 'Cuestionarios',
        courses: 'Cursos',
        course: 'Curso',
        quiz: 'Cuestionario',
        maxPoints: 'Puntos máximos',
        minPointsToPass: 'Puntos mínimos para aprobar',
        questionFailQuiz:
            'Fallar una pregunta descalifica todo el cuestionario',
        points: 'Puntos',
        currentVersionPreview: 'Vista previa de la versión actual',
        docs_sidebar_hidden_by_default:
            'Ocultar la barra lateral en los documentos de forma predeterminada',
        allCategories: 'Todas las categorías',
        test: 'Test',
        tests: 'Tests',
        copy: 'Copiar',
        trusted_device_code_login_users_limit: "Inicio de sesión rápido: número de usuarios mostrados"
    },
    placeholders: {
        part: 'Seleccione una parte',
        nameSurname: 'Nombre y apellido',
        phone: 'Número de teléfono',
        password: 'Contraseña',
        password_confirmation: 'Repita la contraseña',
        email: 'E-mail',
    },
    auth: {
        notYourAccountQuestion: '¿No es tu cuenta?',
    },
    signup: {
        registered: 'Registrado!',
        emailConfirmed: 'El correo electrónico ha sido confirmado.',
        registered_info: 'Pida a su supervisor que active su cuenta',
        confirmEmailAddress:
            'Su cuenta ha sido creada, haga clic en el enlace del correo electrónico para confirmar su dirección.',
        tokenNotFoundError:
            'El correo electrónico ya ha sido confirmado o el enlace de confirmación no es válido.',
    },
    paramTypes: {
        text: 'Texto',
        note: 'Nota',
        integer: 'Entero',
        decimal: 'Decimal',
        date: 'Fecha',
        time: 'Hora',
        datetime: 'Fecha y hora',
        color: 'Color',
        icon: 'Ícono',
        boolean: 'No / Sí',
        dictionary: 'Diccionario',
        user: 'Usuario',
        radioGroup: 'Elección única',
        checkboxGroup: 'Elección múltiple',
        signature: 'Firma',
        number: 'Número',
        rating: 'Calificación',
        document: 'Documento',
        image: 'Imagen',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Nombre',
        path: 'Rura al archivo',
        category: 'Categoría',
        equipment: 'Equipo',
        criticality: 'Criticidad',
        status: 'Estado',
        created: 'Creado',
        createdBy: 'Creado por',
        fp: 'FP',
        type: 'Tipo',
        designation: 'Designación',
        symbol: 'Símbolo',
        serialNumber: 'Número de serie',
        registryNumber: 'Número de registro',
        udtNumber: 'Número UDT',
        manufacturedYear: 'Año de fabricación',
        actions: 'Acciones',
        title: 'Título',
        data: 'Fecha',
        archived: 'Archivado',
        manufacturerSymbol: 'Símbolo del fabricante',
        min: 'Min',
        opt: 'Opt',
        quantity: 'Cantidad',
        priority: 'Prioridad',
        price: 'Precio',
        kind: 'Tipo',
        description: 'Descripción',
        default: 'Por defecto',
        warning: 'Advertencia',
        problem: 'Problema',
        fixing: 'Resolviendo',
        changeOrder: 'Orden de cambio',
        required: 'Requerido',
        icon: 'Icono',
        color: 'Color',
        subCategories: 'Subcategorías',
        barCode: 'Código de barras',
        subLocations: 'Sublocalizaciones',
        categories: 'Categorías',
        image: 'Imagen',
        nameSurname: 'Nombre y apellido',
        email: 'Correo electrónico',
        phone: 'Número de teléfono',
        function: 'Función',
        department: 'Departamento',
        accountStatus: 'Estado de la cuenta',
        shift: 'Turno',
        licenseNumber: 'Número de licencia',
        issuedDate: 'Fecha de emisión',
        expiresDate: 'Fecha de caducidad',
        entries: 'Entradas',
        entryNumber: 'Número de entrada',
        buyPrice: 'Precio de compra',
        currentLocationQuantity: 'Cantidad en la ubicación actual',
        group: 'Grupo',
        string: 'Texto',
        integer: 'Número entero',
        toolsGroup: 'Grupo de herramientas',
        manufacturer: 'Fabricante',
        shortName: 'Nombre corto',
        locationName: 'Nombre del lugar',
        subLocationsQuantity: 'Cantidad de sublocalizaciones',
        isWorkingOnIssue: 'Tomó el asunto',
        openIssues: 'Cuestiones pendientes',
        notificationDate: 'Fecha de notificación',
        titleAndDescription: 'Título y descripción',
        read: 'Leer',
        created_at: 'Creado',
        received_at: 'Recibió',
        started_at: 'Tomado',
        arrived_at: 'Llegado',
        finished_at: 'Acabado',
        country: 'País',
        city: 'Ciudad',
        progress: 'Progreso',
        deleted_at: 'Eliminar fecha',
        about_issue: 'Se aplica al informe.',
        action: 'Compartir',
    },
    searchConfig: {
        id: 'Id',
        name: 'Nombre',
        shortName: 'Nombre corto',
        country: 'País',
        email: 'Correo electrónico',
        group: 'Grupo',
        planned: 'Planeado',
        category: 'Categoría',
        object: 'Objeto',
        criticality: 'Criticidad',
        fp: 'FP',
        type: 'Tipo',
        designation: 'Designación',
        symbol: 'Símbolo',
        serialNumber: 'Número de serie',
        registryNumber: 'Número de registro',
        udtNumber: 'Número de la UDT',
        manufacturedYear: 'Año de fabricación',
        manufacturerSymbol: 'Símbolo del fabricante',
        priority: 'Prioridad',
        quantity: 'Cantidad',
        buyPrice: 'Precio de compra',
        kind: 'Tipo',
        toolsGroup: 'Grupo de herramientas',
        function: 'Función',
        department: 'Departamento',
        status: 'Estado',
        nameSurname: 'Nombre y apellidos',
        phone: 'Teléfono',
        barCode: 'Código de barras',
        color: 'Color',
        icon: 'Icono',
        machine: 'Máquina',
        tool: 'Herramienta',
        urgentAndOutdated: 'Urgente y atrasado',
        urgent: 'Urgente',
        expired: 'Atrasado',
        manufacturer: 'Fabricante',
    },
    errors: {
        partMustHaveEntriesToCorrectQuantity:
            'La pieza debe tener entradas para corregir la cantidad.',
        error: 'Se ha producido un error',
        wrongEmail: 'Correo electrónico no válido',
        wrongPasswordLength:
            'La contraseña debe tener al menos 8 caracteres de longitud',
        wrongCodeLength:
            'El @:common.PIN_CODE_LABEL debe tener al menos 5 caracteres de longitud',
        passwordsNotMatch: 'Las contraseñas no coinciden',
        codesNotMatch: '@:common.PIN_CODE_LABEL no coincidentes',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL ya utilizado',
        expiresDateLowerThanIssued:
            'La fecha de caducidad no puede ser inferior a la fecha de emisión',
        cannotMoveToTheSameLocation: 'No se puede mover a la misma ubicación',
        locationRequired: 'Se requiere la ubicación.',
        putQuantity: 'Poner cantidad para separar.',
        valueGreaterThanQuantity: 'El valor es mayor que la cantidad total.',
        wholeCannotBeDistinguished: 'No se puede distinguir el entero.',
        yearMustBeGreaterThan: 'El año debe ser mayor que ',
        passwordConfirmationNotMatch: 'Las contraseñas no son iguales',
        mustBeInteger: 'El valor debe ser un número entero',
        valueTooLong: 'Valor demasiado largo',
        wrongPhoneNumber: 'Número de teléfono erróneo',
        phoneNumberAlreadyTaken: 'Número de teléfono ya ocupado',
        emailAlreadyTaken: 'Correo electrónico ya tomado',
        notFound: 'No encontrado',
        sessionExpired: 'Sesión expirada',
        tenantNotFound:
            'La configuración indicada no existe. Corrija la dirección de la página y vuelva a intentarlo.',
        selectEquipmentTypeToConfigureParameters:
            'Seleccione el tipo de equipo para configurar los parámetros.',
        noParametersToConfigure:
            'El tipo de equipo seleccionado no tiene parámetros que configurar.',
        terminalAlreadyRegistered: 'Este terminal ya ha sido registrado.',
        invalidPin: '@:common.PIN_CODE_LABEL no válido',
        eventCollision:
            'La hora del evento que desea añadir coincide con la hora de otro evento.',
        mustStartWithSlash: 'Debe empezar por /',
        mustBeBetween: 'El valor debe estar comprendido entre:',
        minLength: 'Longitud mínima: ',
        maxLength: 'Longitud máxima: ',
        invalidInputForCodeType:
            'Entrada no válida para el tipo de código seleccionado',
        invalidCodeOrNoPermission:
            'El @:common.PIN_CODE_LABEL no es válido o no tiene autoridad suficiente para realizar esta acción.',
        endDateLowerThanStart:
            'La fecha final no puede ser inferior a la fecha inicial',
        iconAlreadyTaken: 'Icono ya ocupado',
        notEnoughQuantityInStorage: 'Cantidad insuficiente en stock.',
        valueTooLow: 'Valor demasiado bajo',
        valueTooHigh: 'Valor demasiado alto',
        maxAvailableQuantity: 'Número máximo',
        someFilesRejectedDueToSize:
            'Algunos archivos han sido rechazados debido a su tamaño. Tamaño máximo de un archivo:',
        cameraDeviceNotFound: 'Dispositivo de cámara no encontrado',
        cameraPermissionDenied:
            'El acceso a la cámara del dispositivo ha sido bloqueado en la configuración del sitio, restablece la configuración del sitio y permite el uso de la cámara.',
        passwordResetLinkExpired:
            'El enlace de restablecimiento de contraseña ha caducado',
        noInternetConnection: 'Sin conexión a Internet',
        minimum: 'Mínimo',
        mustBeGreaterThan: 'Debe ser mayor que',
        noEventTypesDefined: 'No hay tipos de eventos definidos',
        regexPatternDelimiterError:
            'El separador debe omitirse con el carácter \\npor ejemplo: \\/',
        emailNotMatchPatternDefiniedInTenant:
            'El correo electrónico no coincide con el patrón definido en la configuración del sitio',
        max: 'Valor máximo',
        min: 'Valor mínimoe',
        allLanguagesRequired: 'Completa el valor en todos los idiomas',
        fileTooBigToOpenWillBeDownloaded:
            'El archivo es demasiado grande para abrirlo, se descargará en su dispositivo.',
        surveyNotFound: 'Encuesta no encontrada',
        actionNotEnabled: 'El código está inactivo.',
        loginRequired: 'Necesario iniciar sesión',
        noPermissionForSelectedFolder:
            'No tienes permiso para ver el contenido de este directorio.',
        noFoldersAssignedForTerminal:
            'No hay directorios asignados con instrucciones.',
        noChecklistsAssignedToControl:
            'El control seleccionado no tiene una lista de verificación asignada.',
        maximum: 'Máximo',
        versionErrorMessage:
            'Tus cambios recientes no se guardaron porque otra persona realizó cambios al mismo tiempo. Actualiza la página y vuelve a intentarlo.',
        duplicateError: 'Este valor ya existe',
        fillAllRequiredParameters: 'Complete todos los parámetros requeridos.',
        wrongValue: 'Valor no válido',
        noParametersDefined: 'No hay parámetros definidos',
        noEnoughParts: 'No hay suficientes elementos',
        pinNotBelongsTo: 'Este @:common.PIN_CODE_LABEL no pertenece a',
        tagNotBelongsTo: 'Esta tarjeta/etiqueta no pertenece a',
        noDictionaryEntries:
            'El diccionario seleccionado no contiene entradas.',
        noConnectionWithServer: 'Sin conexión al servidor',
        uploadError: 'Error al cargar el archivo',
        finishedSuccessfully: 'Finalizado con éxito',
        partsAvailabilityError:
            'La disponibilidad de algunos elementos seleccionados ha cambiado. Edita el contenido de tu caja de intercambio e inténtalo de nuevo.',
        noFlagsConfigured: 'No se configuraron banderas',
    },
    navDrawer: {
        dashboard: 'Tablero de mandos',
        issues: 'Asuntos',
        timetable: 'Horario',
        machines: 'Máquinas',
        tools: 'Herramientas',
        parts: 'Piezas',
        storage: 'Almacén',
        staff: 'Personal',
        configuration: 'Configuración',
        notes: 'Notas',
        notifications: 'Notificaciones',
        profile: 'Perfil',
        supervision: 'Supervisión',
    },
    barcodeScanner: {
        barCode: 'Código de barras',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Cateogoría',
        selectCategory: 'Seleccionar categoría',
        noSubcategories: 'No hay subcategorías',
    },
    companies: {
        addCompany: 'Añadir empresa',
        editCompany: 'Editar empresa',
        fullName: 'Nombre completo',
        shortName: 'Nombre corto',
        group: 'Grupo',
        country: 'País',
        city: 'Ciudad',
        street: 'Calle',
        postCode: 'Código postal',
        phone: 'Teléfono',
        email: 'Email',
        website: 'Sitio web',
        NIP: 'NIP',
        REGON: 'INE',
        active: 'Activo',
        nameRequired: 'El nombre es obligatorio',
        shortNameRequired: 'Se requiere el nombre',
    },
    issues: {
        tookAt: 'Tomado a las',
        changeHistory: 'Historial de cambios',
        load: 'Carga',
        date: 'Fecha',
        createdBy: 'Creado por',
        action: 'Acción',
        data: 'Datos',
        created: 'Creado',
        updated: 'Actualizado',
        deleted: 'Eliminado',
        restored: 'Restaurado',
        issues: 'Asuntos',
        issue: 'Asunto',
        by: 'por',
        name: 'Nombre',
        applicantsDescription: 'Descripción del solicitante',
        accidentDescription: 'Descripción del accidente',
        jobDescription: 'Descripción del trabajo',
        jobSummary: 'Resumen del trabajo',
        category: 'Categoría',
        status: 'Estado',
        noFlow: '[Sin flujo]',
        equipment: 'Equipo',
        criticality: 'Criticidad',
        hour: 'Hora',
        formalConfirmation: 'Confirmación formal',
        formalConfirmationShortcut: 'CF',
        formalConfirmationRequired: 'CF requerida',
        parameter: 'Parámetro',
        value: 'Valor',
        addIssue: 'Añadir asunto',
        expectedCompletionDate: 'Fecha de finalización prevista',
        issueDescription: 'Descripción de la incidencia',
        partsUsage: 'Eliminación de piezas',
        assignedEmployees: 'Empleados asignados',
        requiredHelp: 'Ayuda necesaria',
        returnParts: 'Devolver la pieza',
        confirmUnassignEmployeeWhoTakenIssue:
            'Este empleado ha tomado el asunto seleccionado. ¿Quieres eliminarlo?',
        responsibleUser: 'Usuario responsable',
        responsibleRules: 'Responsables - reglas',
        received_decision_at:
            'Fecha en que el notificante tuvo conocimiento de la decisión',
        groupRules: 'Grupos - reglas',
        watchersRules: 'Vigilantes - reglas',
    },
    machines: {
        machines: 'Máquinas',
        xIssuesNotPlanned: ' (no planificados)',
        noIssues: 'No hay assuntos',
    },
    maintenance: {
        maintenance: 'Mantenimiento',
    },
    notes: {
        notes: 'Notas',
        note: 'Nota',
        title: 'Título',
        archived: 'Archivado',
    },
    parts: {
        parts: 'Partes',
        warehouseOutgoings: 'Salidas de almacén',
        fittingEquipmentTypes: 'Tipos de equipos a juego',
        goToEquipmentType: 'ir a tipo de equipo',
        selectPart: 'Seleccione una parte',
        usageReason: 'Razón',
        usageOtherReason: 'Otro',
        replacements: 'Recambios',
        partEntry: 'Entrada de piezas',
        goToPart: 'Ir a parte',
    },
    costCenter: {
        costCenter: 'Centro de costes',
    },
    equipmentTypes: {
        equipment: 'Equipo',
        newEquipmentType: 'Nuevo tipo de equipo',
        fittingParts: 'Piezas y materiales a juego',
        goToParts: 'ir al apartado',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions:
            'Definiciones de los tipos de máquinas y herramientas',
    },
    flows: {
        flow: 'Flujo',
        states: 'Estados',
        transitions: 'Transiciones',
        state: 'Estado',
        default: 'Por defecto',
        warning: 'Advertencia',
        problem: 'Problema',
        fixing: 'Fijación',
        finished: 'Finalizado',
        access: {
            create: 'Creación',
            read: 'Lectura',
            update: 'Modificación',
            delete: 'Eliminación',
            read_unassigned: 'Leer sin asignar',
            assign: 'Asignación',
            unassign: 'Eliminar una tarea',
            close: 'Cierre',
        },
        instructions: {
            default: 'Por defecto',
            fixing: 'Consideración',
            finished: 'Finalizado',
            creation: 'Creación',
            verification: 'Verificación',
            acceptation: 'Aceptación',
            publication: 'Publicación',
        },
        accessLabels: {
            terminal: 'El terminal que reportó el problema',
            creator: 'El usuario que reportó el problema.',
            responsible: 'El usuario responsable del problema.',
            group: 'El grupo de usuarios responsable del problema.',
            anchor: 'El terminal al que está anclado el informe.',
            watchers: 'Usuarios observando el problema.',
        },
        sync_returned_rejected: 'Sincronización - rechazada',
        sync_returned_finished: 'Sincronización - finalizada',
        current: 'Actual',
    },
    parameters: {
        categoryParameter: 'Parámetro de categoría',
        equipmentTypeParameter: 'Parámetro del tipo de equipo',
    },
    staff: {
        staff: 'Personal',
        editUserAccess: 'Editar acceso de usuario',
        permissionsUpdated: 'Permisos actualizados',
        passwordUpdated: 'Contraseña actualizada',
        codeUpdated: '@:common.PIN_CODE_LABEL actualizado',
        changePasswordAndCode: 'Cambiar contraseña y @:common.PIN_CODE_LABEL',
        goToLicenses: 'Ir a licencias',
        editAccess: 'Editar acceso',
        editUser: 'Editar usuario',
        addUser: 'Añadir nuevo usuario',
        nameAndLastname: 'Nombre y apellidos',
        accountStatus: 'Estado de la cuenta',
        phoneNumber: 'Número de teléfono',
        department: 'Departamento',
        passwordChange: 'Cambio de contraseña',
        codeChange: 'Cambio de @:common.PIN_CODE_LABEL',
        newPassword: 'Nueva contraseña',
        confirmNewPassword: 'Confirmar nueva contraseña',
        changePassword: 'Cambio de contraseña',
        newCode: 'Nuevo @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Confirmar nuevo @:common.PIN_CODE_LABEL',
        changeCode: 'Cambiar @:common.PIN_CODE_LABEL',
        addEntry: 'Añadir entrada',
        editEntry: 'Editar entrada',
        editLicense: 'Editar licencia',
        addLicense: 'Añadir licencia',
        licenseNumber: 'Número de licencia',
        prefferedLocale: 'Localidad preferida',
        oldPassword: 'Contraseña antigua',
        newPasswordConfirmation: 'Confirmar la nueva contraseña',
        reading: 'Viendo',
        creating: 'Creación',
        updating: 'Edición',
        deleting: 'Eliminando',
        importScheduleFile: 'Importar un horario desde un archivo',
        importSchedule: {
            errors: {
                user_not_found: 'Usuario no encontrado [{user}]',
                user_found_many: '{count} usuarios encontrados [{user}]',
                event_collision: 'Colisión de eventos',
                hour_cell_parse: 'Error de análisis de celda',
            },
        },
        oldCode: '@:common.PIN_CODE_LABEL antiguo',
        newCodeConfirmation: 'Confirmación del nuevo @:common.PIN_CODE_LABEL',
        setAccessBasedOnProfile: 'Otorgar acceso según el perfil',
        selectAccessMergeType: 'Elija cómo combinar permisos',
        noAccessesInProfileMessage:
            'El perfil seleccionado no tiene permisos. ¿Quieres sobrescribir de todos modos?',
    },
    breadCrumb: {
        configuration: 'Configuración',
        locations: 'Localizaciones',
        home: 'Salpicadero',
        machines: 'Máquinas',
        tools: 'Herramientas',
        issues: 'Asuntos',
        notes: 'Notas',
        maintenance: 'Mantenimiento',
        parts: 'Piezas',
        warehouse: 'Almacén',
        dictionary: 'Diccionario',
        categories: 'Categorías',
        parameters: 'Parámetros',
        costCenter: 'Centro de costes',
        flows: 'Flujos',
        states: 'Estados',
        transitions: 'Transiciones',
        shiftSchemas: 'Esquemas de cambio',
        shifts: 'Cambios',
        definitionsOfMachinesAndToolsTypes:
            'Definiciones de los tipos de máquinas y herramientas',
        add: 'Añadir',
        edit: 'Editar',
        staff: 'Personal',
        licenses: 'Licencias',
        entries: 'Entradas',
        companiesDatabase: 'Base de datos de empresas',
        companyManagement: 'Dirección de la empresa',
        companyData: 'Datos de la empresa',
        supportedLanguages: 'Idiomas admitidos',
        en: 'Inglés',
        pl: 'Polaco',
        es: 'Español',
        de: 'Alemán',
        it: 'Italiano',
        fr: 'Francés',
        ru: 'Ruso',
        uk: 'Ucraniano',
        pt: 'Portugués',
        defaultLanguage: 'Idioma por defecto',
        notifications: 'Notificaciones',
        notificationsManagement: 'Gestión de las notificaciones',
        myProfile: 'Mi perfil',
    },
    attributeTypes: {
        text: 'Texto',
        note: 'Nota',
        integer: 'Entero',
        decimal: 'Decimal',
        date: 'Fecha',
        time: 'Hora',
        dateTime: 'Fecha y hora',
        color: 'Color',
        icon: 'Icono',
        boolean: 'No / Sí',
    },
    costCalculator: {
        costCalculator: 'Calculadora de costes',
        waitTime: 'Tiempo de espera',
        serviceTime: 'Tiempo de servicio',
        suspendTime: 'Tiempo de suspensión',
        totalTime: 'Tiempo total',
        estimatedCost: 'Coste estimado',
        calculate: 'Calcular',
        withoutParts: 'Sin piezas',
        timeOfReportingIssue: 'Momento de la notificación del problema',
        timeOfServiceStart: 'Hora de inicio del servicio',
        timeOfReportingEnd: 'Hora de finalización del servicio',
        days: 'Días',
        hours: 'Horas',
        minutes: 'Minutos',
        enterNewTime: 'Introducir nueva hora',
        fromIssueReportToServiceStart:
            'Tiempo desde el informe de emisión hasta el inicio del servicio',
        fromServiceStartToServiceEnd:
            'Tiempo desde el inicio del servicio hasta su finalización',
        workTime: 'Tiempo de trabajo',
        includeAwaitingTime: 'Incluir el tiempo de espera',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'Mi asuntos',
                assignedToMe: 'Asignado a mí',
                assignedToGroup: 'Asignado a mi grupo',
                tookByMe: 'Asuntos tomado por mí',
                takeIssueBtn: 'Tomar',
                arriveIssueBtn: 'He llegado',
                finishIssueBtn: 'Finalizar',
                history: 'Archivado',
                myResponsible: 'Soy responsable de',
                unassignIssue: 'Cancelar toma',
            },
        },
    },
    synchronization: {
        enabled: 'Lanzado',
        hasHeaders: 'Tiene títulos',
        leaveFile: 'Dejar un archivo',
        host: 'Host',
        port: 'Puerto',
        protocol: 'Protocolo',
        username: 'Nombre de usuario',
        password: 'Contraseña',
        root: 'Ruta',
        encoding: 'Codificación',
        separator: 'Separador',
        externalSystemId: 'ID en el sistema externo',
        cronExpression: 'Expresión de cron',
        appendFullStock: 'Añadir existencias completas',
        includeHeaders: 'Incluir cabeceras',
        withTrashed: 'Con datos eliminados',
        filePattern: 'Patrón de archivo',
        categoriesRootCategoryId: 'Categoría raíz para categorías importadas',
        systemKeyname: 'Identificador del sistema',
        address: 'Dirección',
        token: 'Token',
    },
    processes: {
        created_at: 'Creado en',
        started_at: 'Comenzó en',
        updated_at: 'Actualizado en',
        finished_at: 'Terminado en',
        attempts: 'Intentos',
        queue: 'Cola',
        job_id: 'ID del puesto',
        job_uuid: 'UUID del puesto',
        progress_max: 'Progreso máximo',
        progress_now: 'Progresar ahora',
        outputMessage: 'Mensaje de salida',
        status: {
            queued: 'en cola',
            executing: 'ejecutando',
            finished: 'terminado',
            failed: 'fallido',
            retrying: 'reintento',
            undefined: 'nedefinisano',
        },
    },
    weekDays: {
        mo: 'Lunes',
        tu: 'Martes',
        we: 'Miércoles',
        th: 'Jueves',
        fr: 'Viernes',
        sa: 'Sábado',
        su: 'Domingo',
    },
    reports: {
        reports: 'Informes',
        dateFrom: 'Fecha de inicio',
        dateTo: 'Fecha de fin',
        types: {
            operation_sheet: 'Hoja de operaciones',
            issue_summary: 'Resumen del problema',
            grouped_summary: 'Informe resumido',
            storage_operations: 'Operaciones de almacén',
            part_quantity_export: 'Cantidad de piezas',
        },
        reportFor: 'Informe para',
        includeQuantityLeft0: 'Incluir piezas con cantidad nula',
        dayMinusN: 'Día menos n',
        currentWeek: 'Semana actual',
        lastWeek: 'Semana pasada',
        currentMonth: 'Mes actual',
        lastMonth: 'Mes pasado',
        currentYear: 'Año actual',
        lastYear: 'Año pasado',
        nDays: 'Número de días',
        todayMinusN: 'Hoy menos n',
    },
    logs: {
        comment: 'Comentario',
        issues: {
            escalation: 'Escalación ({minutes} min) - notificación enviada',
        },
        issueEmployee: {
            assignedAt: 'Empleado asignado:',
            receivedAt: 'Empleado recibió:',
            tookAt: 'Empleado asumió:',
            finishedAt: 'Empleado completó:',
            deletedAt: 'Empleado eliminado de la denuncia',
        },
    },
    units: {
        no: 'Pedazo',
        st: 'Colocar',
        pr: 'Par',
    },
    rrule: {
        frequency: 'Frecuenci',
        dtstart: 'Fecha de inicio',
        tzid: 'Zona horaria',
        until: 'Hasta',
        count: 'Contar',
        interval: 'Intervalo',
        wkst: 'día de inicio',
        byweekday: 'por día laborable',
        bymonth: 'Por mes',
        yearly: 'Anual',
        monthly: 'Mensual',
        weekly: 'Semanalmente',
        daily: 'A diario',
        hourly: 'Cada hora',
        minutely: 'Minuciosamente',
        secondly: 'En segundo lugar',
        infinitePeriodicityMessage:
            'La periodicidad es infinita, debido al rendimiento, solo se muestran 500 filas',
    },
    months: {
        jan: 'Enero',
        feb: 'Febrero',
        mar: 'Marzo',
        apr: 'Abril',
        may: 'Puede',
        jun: 'Junio',
        jul: 'Julio',
        aug: 'Agosto',
        sep: 'Septiembre',
        oct: 'Octubre',
        nov: 'Noviembre',
        dec: 'Diciembre',
    },
    charts: {
        charts: 'Gráficos',
        count: 'Número de problemas',
        total: 'Tiempo total de emisión (desde el informe hasta el final)',
        serw: 'Tiempo total de reparación (Ttotal - Twait)',
        mttr1: 'Tiempo promedio desde el informe hasta el final',
        mttr2: 'Tiempo medio desde la toma hasta el final',
        mtbf: 'Tiempo de actividad promedio entre problemas',
        mttf: 'Tiempo medio hasta el fallo',
        type: 'Tipo de gráfico',
        pie: 'Gráfico circular',
        bar: 'un gráfico de barras',
        query: 'El alcance de los datos.',
        groupBy: 'Agrupar por',
        sum: 'Suma',
        avg: 'Significar',
        min: 'Mínimo',
        max: 'Máximo',
        eq: 'Es igual a',
        neq: 'no es igual',
        gt: 'Más que',
        lt: 'Menos que',
        gte: 'Más o igual',
        lte: 'menor o igual',
        in: 'Contiene',
        nin: 'No contiene',
        btw: 'Entre',
        chartOptions: 'Opciones de gráfico',
        line: 'Gráfico de líneas',
        mixed: 'Mezclado',
        chartDisplayOptions: 'opciones de pantalla',
        fillGaps: 'Rellena los huecos',
    },
    regexBuilder: {
        regexValue: 'Valor',
        global: 'Global',
        multiline: 'Multilínea',
        insensitive: 'Insensible a mayúsculas',
        extended: 'Extendido',
        singleline: 'Línea única',
        unicode: 'Unicode',
        Ungreedy: 'Cuantificadores perezosos',
        Anchored: 'Anclado al inicio del patrón o al final del último ajuste',
        Jchanged: 'Permitir duplicados de nombres de subpatrones',
        DollarEndOnly: '$ solo coincide con el final del patrón',
        testValue: 'Valor de prueba',
    },
    tpm: {
        groupableHeadersPlaceholder:
            'Arrastra el encabezado de la tabla para agrupar los datos.',
        steps: 'Pasos',
        step: 'Paso',
        content: 'Contenido',
        history: 'Historia',
        standardObstacle: 'Obstáculo estándar',
        customObstacle: 'Obstáculo personalizado',
        bhp: 'Seguridad y salud laboral',
        returnToMainPage: 'Volver a la página principal',
        bhpMessage:
            'Si observas riesgos para la seguridad, por favor, infórmalos de inmediato a tu supervisor directo y utiliza el formulario de informe que se encuentra debajo del tablón de anuncios.',
        ok: 'Aceptar',
        send: 'Enviar',
        description: 'Descripción',
        title: 'Título',
        realization: 'Realización',
        answer: 'Respuesta',
        pendingSurveys: 'Encuestas pendientes',
        surveys: 'Encuestas',
        systemSurvey: 'Encuesta de sistema',
        addPage: 'Agregar página',
        createSurvey: 'Crear encuesta',
        addSection: 'Agregar sección',
        addQuestion: 'Agregar pregunta',
        questionContent: 'Contenido de la pregunta',
        answerOption: 'Opción de respuesta',
        addQuestionAnswer: 'Agregar opción de respuesta',
        surveyName: 'Nombre de la encuesta',
        sectionName: 'Nombre de la sección',
        noSurveyAnswers: 'Sin respuestas',
        removeSection: 'Eliminar sección',
        published: 'Publicada',
        unpublished: 'No publicada',
        pendingPublication: 'Cambios pendientes de publicación',
        removeQuestion: 'Eliminar pregunta',
        removeAnswer: 'Eliminar opción de respuesta',
        publish: 'Publicar',
        unpublish: 'Despublicar',
        previewResults: 'Previsualizar resultados',
        supervisior: 'Supervisor',
        surveyResults: 'Resultados de la encuesta',
        surveysEvents: 'Configuración de eventos: encuestas',
        operatedBy: 'Operado por',
        confirmReadInformations: 'Confirmo que he leído la información',
        noticeContent: 'Contenido del aviso',
        notice: 'Aviso',
        surveyEventName: 'Nombre del evento',
        survey: 'Encuesta',
        newIssue: 'Nuevo informe',
        surveyEventConfigurationAlreadyExists:
            'Ya existe una configuración para este evento',
        lastComment: 'Último comentario del responsable',
        surveyNotConfigured: 'Encuesta no configurada',
        fillSurvey: 'Completar encuesta',
        singleSurveyEntries: 'resultados únicos',
        groupedSurveyEntries: 'resultados agrupados',
        publicationId: 'ID de publicación',
        publicationDate: 'Fecha de publicación',
        versionId: 'identificación de la versión',
        answersCount: 'Número de respuestas',
        answers: 'respuestas',
        withoutSupervisor: 'Sin supervisor',
        defaultSupervisor: 'Supervisor predeterminado',
        flowStateFlags: 'Indicadores de estado',
        qrActionsSettings: 'Configuración de la acción del código QR',
        loginRequired: 'Requiere inicio de sesión',
        questionContext: 'El contexto de la pregunta',
        currentSurveyVersionPreview:
            'Vista previa de la versión actual de la encuesta',
        surveyDescription: 'Descripción de la encuesta',
        sectionDescription: 'Descripción de la sección',
        instructions: 'Instrucciones',
        instruction: 'Instrucción',
        instructionRevisions: 'Revisiones',
        instructionRevision: 'Revisión',
        checklists: 'Listas de verificación',
        checklist: 'Lista de Verificación',
        checklistItem: 'Elemento de lista',
        manageChecklistFields: 'Administrar elementos de la lista',
        checklistItemDisplayOptions: 'Artículos mostrados',
        revisionAutoRejectedInfo: '[Sistema] Rechazado - nueva revisión creada',
    },
    accessMergeValues: {
        sum: 'Suma',
        product: 'Producto',
        overwrite: 'Sobrescribir',
    },
    aggregates: {
        count: 'Suma',
        min: 'Mínimo',
        max: 'Máximo',
    },
    checklists: {
        itemBasic: 'Elemento básico',
        itemSeparator: 'Separador',
        itemSection: 'Sección',
        publish: 'Publicar',
        previewCurrentVersion: 'Vista previa de la versión actual',
    },
    cluer: {
        groupableHeadersPlaceholder:
            'Arrastra el encabezado de la tabla para agrupar los datos.',
        history: 'Historia',
        standardObstacle: 'Obstáculo estándar',
        customObstacle: 'Obstáculo personalizado',
        bhp: 'Seguridad y salud laboral',
        returnToMainPage: 'Volver a la página principal',
        bhpMessage:
            'Si observas riesgos para la seguridad, por favor, infórmalos de inmediato a tu supervisor directo y utiliza el formulario de informe que se encuentra debajo del tablón de anuncios.',
        ok: 'Aceptar',
        send: 'Enviar',
        description: 'Descripción',
        title: 'Título',
        realization: 'Realización',
        answer: 'Respuesta',
        pendingSurveys: 'Encuestas pendientes',
        surveys: 'Encuestas',
        systemSurvey: 'Encuesta de sistema',
        addPage: 'Agregar página',
        createSurvey: 'Crear encuesta',
        addSection: 'Agregar sección',
        addQuestion: 'Agregar pregunta',
        questionContent: 'Contenido de la pregunta',
        answerOption: 'Opción de respuesta',
        addQuestionAnswer: 'Agregar opción de respuesta',
        surveyName: 'Nombre de la encuesta',
        sectionName: 'Nombre de la sección',
        noSurveyAnswers: 'Sin respuestas',
        removeSection: 'Eliminar sección',
        published: 'Publicada',
        unpublished: 'No publicada',
        pendingPublication: 'Cambios pendientes de publicación',
        removeQuestion: 'Eliminar pregunta',
        removeAnswer: 'Eliminar opción de respuesta',
        publish: 'Publicar',
        unpublish: 'Despublicar',
        previewResults: 'Previsualizar resultados',
        supervisior: 'Supervisor',
        surveyResults: 'Resultados de la encuesta',
        surveysEvents: 'Configuración de eventos: encuestas',
        operatedBy: 'Operado por',
        confirmReadInformations: 'Confirmo que he leído la información',
        noticeContent: 'Contenido del aviso',
        notice: 'Aviso',
        surveyEventName: 'Nombre del evento',
        survey: 'Encuesta',
        newIssue: 'Nuevo informe',
        surveyEventConfigurationAlreadyExists:
            'Ya existe una configuración para este evento',
        lastComment: 'Último comentario del responsable',
        surveyNotConfigured: 'Encuesta no configurada',
        fillSurvey: 'Completar encuesta',
        singleSurveyEntries: 'resultados únicos',
        groupedSurveyEntries: 'resultados agrupados',
        publicationId: 'ID de publicación',
        publicationDate: 'Fecha de publicación',
        versionId: 'identificación de la versión',
        answersCount: 'Número de respuestas',
        answers: 'respuestas',
        withoutSupervisor: 'Sin supervisor',
        defaultSupervisor: 'Supervisor predeterminado',
        flowStateFlags: 'Indicadores de estado',
        qrActionsSettings: 'Configuración de la acción del código QR',
        loginRequired: 'Requiere inicio de sesión',
        questionContext: 'El contexto de la pregunta',
        currentSurveyVersionPreview:
            'Vista previa de la versión actual de la encuesta',
        surveyDescription: 'Descripción de la encuesta',
        sectionDescription: 'Descripción de la sección',
    },
    orders: {
        order: 'Orden',
        releaseDate: 'Fecha de Terminación',
        orderDate: 'Fecha de pedido',
    },
    production: {
        good: 'Número de buenos',
        bad: 'Número de malos',
        productionRecords: 'registro de producción',
    },
};
