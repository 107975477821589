<template>
    <SignaloAppBar
        search
        v-model:searchOpen="searchOpen"
        :searchFieldCount="searchFieldCount"
    >
        <template #title> {{ $t('reports.reports') }} </template>
        <template #bar>
            <v-btn
                @click="() => loadTable()"
                class="submain-button renew-button"
            >
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloSearchContainer
            ref="searchContainerComponent"
            v-model:open="searchOpen"
            :config="searchConfig"
            @search="loadTable"
            v-model:fieldCount="searchFieldCount"
            :tableName="TABLE_NAME"
        />
        <SignaloBreadCrumbs />
        <SignaloPageLoading v-if="loading" />
        <div v-else>
            <v-card
                class="ma-3 ovisible v-tab-card-container"
                style="padding: 0 !important"
            >
                <v-tabs v-model="currentTab" color="primary">
                    <v-tab :value="REPORT_TYPES.STORAGE_OPERATIONS?.value">{{
                        $t(REPORT_TYPES.STORAGE_OPERATIONS.label)
                    }}</v-tab>
                    <v-tab
                        :value="REPORT_TYPES.PART_QUANTITY_EXPORT.value"
                        v-if="authStore.isAllowed('reports-storage', 'read')"
                        >{{
                            $t(REPORT_TYPES.PART_QUANTITY_EXPORT?.label)
                        }}</v-tab
                    >
                </v-tabs>

                <v-card-text>
                    <v-window v-model="currentTab" class="ovisible">
                        <v-window-item
                            :value="REPORT_TYPES.STORAGE_OPERATIONS?.value"
                        >
                            <v-row>
                                <v-col cols="12" md="12" xl="12">
                                    <SignaloTabsToggle
                                        :buttons="QUICK_DATES_OPTIONS"
                                        v-model="quickDate"
                                        :mandatory="false"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="12"
                                    xl="12"
                                    v-if="
                                        quickDate ===
                                        QUICK_DATES?.DAY_MINUS_N?.value
                                    "
                                >
                                    <v-text-field
                                        v-model="nDays"
                                        @click:control="controlClicked"
                                        @click:clear="clearClicked"
                                        clear-icon="mdi-close-circle-outline"
                                        variant="outlined"
                                        :label="$t('reports.nDays')"
                                        type="number"
                                        :rules="[
                                            $REQUIRED_RULE,
                                            $MIN_RULE(nDays, 0),
                                            $MAX_RULE(nDays, maxDaysPast),
                                        ]"
                                        class="required-field"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" xl="6">
                                    <SignaloDatePicker
                                        @update="(val) => (date_from = val)"
                                        :date="date_from"
                                        :label="$t('reports.dateFrom')"
                                        variant="outlined"
                                        color="var(--greyscale-100)"
                                        :error="incorrectDateError"
                                        :rules="[$REQUIRED_RULE]"
                                        class="required-field"
                                    />
                                </v-col>
                                <v-col cols="12" md="6" xl="6">
                                    <SignaloDatePicker
                                        @update="
                                            (val) => (date_to = getToDate(val))
                                        "
                                        :date="date_to"
                                        :label="$t('reports.dateTo')"
                                        variant="outlined"
                                        color="var(--greyscale-100)"
                                        :error="incorrectDateError"
                                        :rules="[$REQUIRED_RULE]"
                                        class="required-field"
                                    />
                                </v-col>
                                <v-col cols="12" md="6" xl="6">
                                    <v-autocomplete
                                        variant="outlined"
                                        :label="$t('common.warehouse')"
                                        :items="allowedWarehouses"
                                        v-model="storage_keyname"
                                        item-title="value"
                                        item-value="keyname"
                                        class="required-field"
                                        :no-data-text="
                                            $t('common.emptyTableMessage')
                                        "
                                        :rules="[$REQUIRED_RULE]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6" xl="6">
                                    <SignaloNewValueSelect
                                        :key="user_id"
                                        :selectableType="SELECTABLE_TYPES.USER"
                                        :label="$t('common.user')"
                                        v-model="user_id"
                                        :modificationDisabled="true"
                                        :clearable="true"
                                    /> </v-col
                            ></v-row>
                        </v-window-item>
                        <v-window-item
                            :value="REPORT_TYPES.PART_QUANTITY_EXPORT.value"
                        >
                            <v-row>
                                <v-col cols="12" md="6" xl="6">
                                    <v-autocomplete
                                        v-model="quantity_level"
                                        :items="quantityLevelOptions"
                                        variant="outlined"
                                        color="var(--greyscale-100)"
                                        item-title="label"
                                        item-value="value"
                                        clear-icon="mdi-close-circle-outline"
                                        :label="$t('flows.state')"
                                        :rules="[$REQUIRED_RULE]"
                                        class="required-field"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6" xl="6">
                                    <v-switch
                                        color="primary"
                                        v-model="where_quantity_left_0"
                                        :label="
                                            $t('reports.includeQuantityLeft0')
                                        "
                                    /> </v-col
                            ></v-row>
                        </v-window-item>
                    </v-window>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="
                            authStore.isAllowed('reports', 'create') ||
                            authStore.isAllowed('reports-storage', 'create')
                        "
                        variant="elevated"
                        @click="addClicked"
                        :disabled="generateButtonDisabled"
                    >
                        {{ $t('common.generate') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <EasyDataTable
                buttons-pagination
                :headers="tableHeaders"
                :items="items"
                :loading="loadingTable"
                :empty-message="$t('common.emptyTableMessage')"
                table-class-name="easy-table-style"
                @click-row="onStaffShow"
                :rows-per-page-message="$t('common.rowsPerPage')"
                :rows-of-page-separator-message="$t('common.rowsOfPage')"
                v-model:server-options="serverOptions"
                :server-items-length="serverItemsLength"
                :rows-items="[5, 10, 25]"
            >
                <template #item-report_type="item">
                    {{
                        reportTypes?.[item.report_type]
                            ? $t(reportTypes?.[item.report_type])
                            : item.report_type
                    }}
                </template>
                <template #item-status="item">
                    <span :key="item.job_status?.status">
                        {{ $t(`processes.status.${item.job_status?.status}`) }}
                    </span>
                </template>
                <template #item-progress_now="item">
                    <SignaloProcessProgressBar
                        :key="
                            item.job_status_id + item.job_status?.progress_now
                        "
                        :jobStatusId="item.job_status_id"
                        :progress_now="item.job_status?.progress_now"
                        @update:jobstatus="(x) => (item.job_status = x)"
                        @finished="loadTable"
                    />
                </template>
                <template #item-operation="item">
                    <div class="actions-column">
                        <v-menu offset-y v-if="item.file !== null">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    color="primary"
                                    v-bind="props"
                                    variant="outlined"
                                    icon
                                    class="table-action-icon-button"
                                >
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="
                                        downloadDocument(
                                            item.file,
                                            'report.' +
                                                item.id +
                                                '.' +
                                                item.file.split('.').pop()
                                        )
                                    "
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-download
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.download')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </EasyDataTable>
        </div>
    </v-main>
</template>
<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import { SEARCH_FIELD_TYPES } from 'signalo-vue-search-container';
import { toMySqlDate } from 'signalo-vue-utils';
import REPORT_TYPES from './REPORT_TYPES';
import QUICK_DATES from './QUICK_DATES';
import {
    getFromDate,
    getToDate,
    getMondayOfWeek,
    addDaysToDate,
    getFirstDayOfMonth,
    getLastDayOfCurrentWeek,
    getLastDayOfMonth,
    getFirstDayOfYear,
    getLastDayOfYear
} from '@/utils/dates';

export default {
    setup() {
        window.report_sync = false;

        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            REPORT_TYPES: Object.freeze(REPORT_TYPES),
            loading: false,
            loadingTable: false,
            items: [],
            currentTab: null,

            date_from: null,
            date_to: null,
            os_year: null,
            os_years: [],
            filters: {
                equipment_id: null,
                category_id: null,
            },
            serverOptions: {
                ...this.$getServerOptions('reportsTable'),
                sortType: 'desc',
                sortBy: 'id',
            },
            serverItemsLength: 0,
            lastSearch: null,
            incorrectDateError: [],
            searchOpen: false,
            reportFor: null,
            reportForItems: [
                { value: 'machine', label: this.$t('common.machine') },
                { value: 'prod_line', label: this.$t('common.productionLine') },
                {
                    value: 'department',
                    label: this.$t('tableHeaders.department'),
                },
                {
                    value: 'index',
                    label: this.$t('common.index'),
                },
            ],
            quantityLevelOptions: [
                { value: 'all', label: this.$t('common.all') },
                { value: 'below_opt', label: this.$t('common.belowOptimum') },
                { value: 'below_min', label: this.$t('common.belowMinimum') },
            ],
            TABLE_NAME: 'reportsTable',
            where_quantity_left_0: false,
            defaultCategoryId: null,
            quantity_level: null,
            storage_keyname: null,
            user_id: null,
            allowedWarehouses: [],
            QUICK_DATES: QUICK_DATES,
            QUICK_DATES_OPTIONS: Object.values(QUICK_DATES),
            quickDate: null,
            nDays: null,
            maxDaysPast: 99999,
        };
    },
    computed: {
        storageUrl() {
            return this.getFileStorageUrl() + '/';
        },
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.id'),
                    value: 'id',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.type'),
                    value: 'report_type',
                    sortable: true,
                },
                {
                    text: this.$t('reports.dateFrom'),
                    value: 'date_from',
                    sortable: true,
                },
                {
                    text: this.$t('reports.dateTo'),
                    value: 'date_to',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.progress'),
                    value: 'progress_now',
                },
                {
                    text: this.$t('tableHeaders.status'),
                    value: 'status',
                    sortable: true,
                },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
        reportTypes() {
            const rts = {};
            Object?.values(this.REPORT_TYPES).forEach((rt) => {
                rts[rt?.value] = rt.label;
            });
            return rts;
        },
        searchConfig() {
            return {
                id_equals: {
                    type: SEARCH_FIELD_TYPES.TEXT,
                    label: this.$t('searchConfig.id'),
                    cols: 2,
                },
                report_type: {
                    type: SEARCH_FIELD_TYPES.CUSTOM_SELECT,
                    label: this.$t('common.type'),
                    selectOptions: Object.keys(this.reportTypes).map((key) => {
                        return {
                            value: key.replaceAll('\\', '\\\\'),
                            label: this.reportTypes[key],
                        };
                    }),
                },
            };
        },
        prod_lines() {
            return this.dataStore.dictionary.group('prod_lines');
        },
        departments() {
            return this.dataStore.dictionary.group('departments');
        },
        generateButtonDisabled() {
            if (this.currentTab === REPORT_TYPES.STORAGE_OPERATIONS?.value) {
                return (
                    !this.storage_keyname ||
                    this.incorrectDateError?.length ||
                    !this.date_from ||
                    !this.date_to
                );
            } else if (
                this.currentTab === REPORT_TYPES.PART_QUANTITY_EXPORT.value
            ) {
                return !this.quantity_level;
            }
            return false;
        },
    },
    mounted() {
        this.load();
        this.initAllowedWarehouses();
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences('reportsTable', {
                default_size: val.rowsPerPage,
            });
            this.loadTable();
        },
        date_from(val) {
            this.incorrectDateError = [];
            if (!val || !this.date_to) return;
            if (val > this.date_to)
                this.incorrectDateError = [
                    this.$t('errors.endDateLowerThanStart'),
                ];
        },
        date_to(val) {
            this.incorrectDateError = [];
            if (
                this.quickDate === QUICK_DATES.DAY_MINUS_N.value &&
                this.nDays > 0 &&
                this.nDays < this.maxDaysPast
            ) {
                this.date_from = addDaysToDate(
                    this.date_to,
                    this.nDays * -1,
                    'from'
                );
                return;
            }
            if (!val || !this.date_from) return;
            if (val < this.date_from)
                this.incorrectDateError = [
                    this.$t('errors.endDateLowerThanStart'),
                ];
        },
        nDays() {
            if (
                this.quickDate === QUICK_DATES.DAY_MINUS_N.value &&
                this.nDays > 0 &&
                this.nDays < this.maxDaysPast
            ) {
                this.date_from = addDaysToDate(
                    this.date_to,
                    this.nDays * -1,
                    'from'
                );
                return;
            }
        },
        currentTab(val) {
            this.filters = {};
            if (
                val === REPORT_TYPES.GROUPED_SUMMARY?.value &&
                this.defaultCategoryId
            ) {
                this.filters.category_id = this.defaultCategoryId;
            }
            this.incorrectDateError = [];
            this.date_from = null;
            this.date_to = null;
        },
        quickDate(val) {
            this.onQuickDateChange(val);
        },
    },
    methods: {
        async initAllowedWarehouses() {
            await this.dataStore.dictionary.promise();
            await this.dataStore.locations.promise();
            const userPermittedWarehousesData = await this.axios.get('me');
            const permittedWarehousesKeynames =
                userPermittedWarehousesData?.data?.item?.storage_access || [];
            this.allowedWarehouses = this.dataStore.dictionary
                .group('storages')
                .filter((storage) =>
                    permittedWarehousesKeynames.find(
                        (pwk) => pwk.storage === storage.keyname && pwk.allowed
                    )
                )
                .filter(
                    (allowedStorage) =>
                        !!this.dataStore.locations.items.find(
                            (location) =>
                                allowedStorage.keyname == location.storage_root
                        )
                )
                .map((allowedStorage) => {
                    return {
                        ...allowedStorage,
                        location_id: this.dataStore.locations.items.find(
                            (location) =>
                                allowedStorage.keyname == location.storage_root
                        )?.id,
                        location_path: this.dataStore.locations.items.find(
                            (location) =>
                                allowedStorage.keyname == location.storage_root
                        )?.path,
                    };
                });
        },
        load() {
            this.loading = true;
            Promise.allSettled([
                this.dataStore.dictionary.promise(),
                this.loadTable(),
            ]).then(() => {
                this.loading = false;
            });
        },
        loadTable(search, resetPage) {
            this.loadingTable = true;
            if (resetPage) {
                this.serverOptions.page = 1;
            }
            if (search !== null) {
                this.lastSearch = search;
            }

            const params = {
                ...this.lastSearch,
            };

            const config = this.dataStore.reports.makeServerOptions(
                this.serverOptions,
                params
            );
            this.$router.replace({ query: config.params });
            return this.dataStore.reports
                .indexRequest(config)
                .then((x) => {
                    if (x.data?.status === 'ok') {
                        if (x?.data?.grouped_summary_default_category?.id) {
                            this.defaultCategoryId =
                                x?.data?.grouped_summary_default_category?.id;
                        }
                        this.items = Object?.values(x.data.items);
                        this.serverItemsLength = x?.data?.meta?.total;
                    }
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                    this.helpersStore.snackbarError(e);
                })
                .finally(() => {
                    this.loadingTable = false;
                });
        },
        addClicked() {
            let data = {
                sync: window.report_sync,
            };
            switch (this.currentTab) {
                case REPORT_TYPES.STORAGE_OPERATIONS?.value:
                    data = {
                        report_type: REPORT_TYPES.STORAGE_OPERATIONS?.value,
                        date_from: toMySqlDate(this.date_from),
                        date_to: toMySqlDate(this.date_to),
                        filters: {
                            storage_keyname: this.storage_keyname,
                            user_id: this.user_id,
                        },
                    };
                    break;
                case REPORT_TYPES.PART_QUANTITY_EXPORT.value:
                    data = {
                        report_type: REPORT_TYPES.PART_QUANTITY_EXPORT.value,
                        date_from: toMySqlDate(new Date(null)), //BACKEND POKI CO IGNORUJE TE POLA, ALE MUSZE JE WYSLAC ZEBY WALIDACJA PRZESZLA
                        date_to: toMySqlDate(new Date()),
                        filters: {
                            where_quantity_left_0: this.where_quantity_left_0
                                ? 1
                                : 0,
                            quantity_level: this.quantity_level,
                        },
                    };
                    break;
                default:
                    return;
            }
            this.dataStore.reports
                .createRequest(data)
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('common.reportGenerationInProgress'),
                        'success'
                    );
                    return this.loadTable();
                })
                .catch((e) => {
                    if (e.response?.status == 401) return;
                    this.helpersStore.snackbarError(e);
                    this.loadTable();
                });
        },
        downloadDocument(path, name) {
            this.axios
                .get(this.storageUrl + path, {
                    responseType: 'blob',
                })
                .then((res) => {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );

                    var a = document.createElement('a');
                    a.href = url;
                    a.download = name;
                    document.body.append(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                });
        },
        onQuickDateChange(val) {
            this.nDays = null;
            switch (val) {
                case QUICK_DATES.TODAY.value:
                    this.setToday();
                    break;
                case QUICK_DATES.DAY_MINUS_N.value:
                    this.date_from = null;
                    if (!this.date_to) {
                        this.date_to = getToDate(new Date());
                    }
                    break;
                case QUICK_DATES.CURRENT_WEEK.value:
                    this.setCurrentWeek();
                    break;
                case QUICK_DATES.LAST_WEEK.value:
                    this.setLastWeek();
                    break;
                case QUICK_DATES.CURRENT_MONTH.value:
                    this.setCurrentMonth();
                    break;
                case QUICK_DATES.LAST_MONTH.value:
                    this.setLastMonth();
                    break;
                case QUICK_DATES.CURRENT_YEAR.value:
                    this.setCurrentYear();
                    break;
                case QUICK_DATES.LAST_YEAR.value:
                    this.setYear();
                    break;

                default:
                    break;
            }
        },
        toMySqlDate(date) {
            return toMySqlDate(date);
        },
        setToday() {
            const today = new Date();
            this.date_from = getFromDate(today);
            this.date_to = getToDate(today);
        },
        setCurrentWeek() {
            const today = new Date();
            this.date_from = getFromDate(getMondayOfWeek(today));
            this.date_to = getToDate(getLastDayOfCurrentWeek(today));
        },
        setLastWeek() {
            const lastWeek = addDaysToDate(new Date(), -7, 'from');
            this.date_from = getFromDate(getMondayOfWeek(lastWeek));
            this.date_to = getToDate(getLastDayOfCurrentWeek(lastWeek));
        },
        setCurrentMonth() {
            const today = new Date();
            this.date_from = getFromDate(
                getFirstDayOfMonth(today.getFullYear(), today.getMonth())
            );
            this.date_to = getToDate(
                getLastDayOfMonth(today.getFullYear(), today.getMonth())
            );
        },
        setLastMonth() {
            const today = new Date();
            const firstDayOfLastMonth = getFirstDayOfMonth(
                today.getFullYear(),
                today.getMonth()
            );

            const firstDayOfLastMonthMinusOne = addDaysToDate(
                firstDayOfLastMonth,
                -1,
                'from'
            );
            this.date_from = getFromDate(
                getFirstDayOfMonth(
                    firstDayOfLastMonthMinusOne.getFullYear(),
                    firstDayOfLastMonthMinusOne.getMonth()
                )
            );
            this.date_to = getToDate(
                getLastDayOfMonth(
                    firstDayOfLastMonthMinusOne.getFullYear(),
                    firstDayOfLastMonthMinusOne.getMonth()
                )
            );
        },
        setCurrentYear() {
            const today = new Date();

            this.date_from = getFromDate(
                getFirstDayOfYear(today.getFullYear())
            );
            this.date_to = getToDate(getLastDayOfYear(today.getFullYear()));
        },
        setYear(yearsFromNow = 1) {
            const today = new Date();
            const year = today.getFullYear();
            const y = year - yearsFromNow;
            this.date_from = getFromDate(getFirstDayOfYear(y));
            this.date_to = getToDate(getLastDayOfYear(y));
        },
    },
};
</script>

<style>
.v-card.v-tab-card-container {
    overflow: visible !important;
    z-index: 9;
}
.ovisible {
    overflow: visible !important;
}
.report-date-ranges-container {
    display: flex;
}
.report-date-ranges-container .date-picker-container {
    flex: 1;
    padding: 0 8px;
    cursor: pointer !important;
}
.report-date-ranges-container .date-picker-container input {
    cursor: pointer !important;
}

.report-date-ranges-container-summary-report-tab {
    flex-direction: column;
}
.report-date-ranges-container-summary-report-tab .date-picker-container {
    padding: 0;
}
</style>
