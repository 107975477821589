<template>
    <SignaloDialog
        :open="open"
        :title="title"
        @close="() => closeDialog(false)"
        :constHeight="false"
        :dialogClasses="['issue-resolution-dialog']"
    >
        <template #body>
            <v-form ref="resolutionForm" v-model="dataValid" lazy-validation>
                <v-col cols="12" md="12" xl="12">
                    <v-textarea
                        :label="$t('common.summary')"
                        variant="outlined"
                        v-model="reason"
                        class="required-field"
                        :rules="required"
                    ></v-textarea>
                </v-col>
            </v-form>
        </template>
        <template #buttons>
            <v-btn @click="closeDialog(false)">{{ $t('common.cancel') }}</v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="save"
                :loading="saveLoading"
                :disabled="!reason"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
    <NewIssueDialog
        v-if="postIssueActionDialog"
        v-model:open="postIssueActionDialog"
        :predefinedIssueData="predefinedIssueData"
        :disableClose="disableClose"
        @item:created="onNewIssueCreated"
    />
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import NewIssueDialog from './NewIssueDialog.vue';

export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    props: ['open', 'title', 'issueId', 'stateId', 'version'],
    data() {
        return {
            dataValid: false,
            reason: null,
            saveLoading: false,
            required: [(v) => !!v || this.$t('common.fieldRequired')],
            postIssueActionDialog: false,
            predefinedIssueData: null,
            disableClose: false,
        };
    },
    methods: {
        async save() {
            await this.$refs?.resolutionForm.validate();
            if (!this.dataValid) return;
            this.saveLoading = true;
            try {
                const patchData = await this.axios.patch(
                    `issues/${this.issueId}`,
                    {
                        flow_state_id: this.stateId,
                        resolution: this.reason,
                        version: this.version
                    }
                );
                if (patchData?.data?.post_issue_action?.enabled) {
                    this.predefinedIssueData = patchData?.data?.new_issue;
                    this.disableClose =
                        patchData?.data?.post_issue_action?.required;

                    this.postIssueActionDialog = true;
                } else {
                    this.helpersStore.snackbarSaved();
                    this.closeDialog(true);
                }
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.saveLoading = false;
        },
        closeDialog(shouldRefetch = false) {
            this.$emit('close', shouldRefetch);
        },
        onNewIssueCreated() {
            this.helpersStore.snackbarSaved();
            this.closeDialog(true);
        },
    },
    components: { NewIssueDialog },
};
</script>
<style>
.issue-resolution-dialog {
    max-width: 600px;
}
</style>
