<template>
    <SignaloDialog :open="true" :title="$t('tpm.step')" :icon="'mdi-cog-outline'" @close="closeDialog" @save="save">
        <template #body>
            <v-card-text>
                <v-form ref="dataForm" v-model="dataValid" lazy-validation>
                    <SignaloTranslatableTabs v-slot="{ value, update }" v-model="item.name"
                        :currentLanguage="$i18n?.locale" :availableLocales="getAvailableLocales()"
                        @validate="this.$refs.name.validate()">
                        <v-text-field :label="$t('common.name')" :modelValue="value" @update:modelValue="update"
                            variant="outlined" color="var(--greyscale-100)" :rules="[
                                $REQUIRED_RULE,
                                $TRANSLATABLE_RULE(item.name),
                            ]" class="required-field" ref="name">
                        </v-text-field>
                    </SignaloTranslatableTabs>
                    <SignaloTranslatableTabs v-slot="{ value, update }" v-model="item.description"
                        :currentLanguage="$i18n?.locale" :availableLocales="getAvailableLocales()" @validate="this.$refs.description.validate()">
                        <v-textarea :label="$t('common.description')" :modelValue="value" @update:modelValue="update"
                            variant="outlined" color="var(--greyscale-100)"
                            :rules="[ $REQUIRED_RULE, $TRANSLATABLE_RULE(item.description)]" class="required-field" ref="description">
                        </v-textarea>
                    </SignaloTranslatableTabs>
                </v-form>
            </v-card-text> </template>
    </SignaloDialog>
</template>
<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    props: ['editedItem', 'courseId'],
    data() {
        return {
            loading: false,
            dataValid: false,
            item: this.editedItem ? { ...this.editedItem } : {},
        };
    },
    methods: {

        async save() {
            try {
                await this.$refs.dataForm.validate();
                if (!this.dataValid) return;
                this.loading = true;

                if (this.editedItem?.id) {
                    await this.axios.put(
                        `courses/${this.courseId}/steps/${this.editedItem.id}`,
                        this.item
                    );
                } else {
                    await this.axios.post(`courses/${this.courseId}/steps`, this.item);
                }
                this.helpersStore.snackbarSaved();
                this.loading = false;
                this.closeDialog(true);
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
        },

        closeDialog(shouldRefetch = false) {
            this.$emit('close', shouldRefetch);
        },
    },
};
</script>
