<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.courses') }} </template>
        <template #bar>
            <v-btn @click="() => loadData()" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" color="primary" variant="elevated" class="submain-button renew-button">
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="addButtonClicked">
                        <v-list-item-title>
                            <v-icon start> mdi-plus </v-icon>
                            <span>
                                {{ $t('common.add') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs v-if="breadcrumb" :data="breadcrumb" />
        <EasyDataTable :headers="tableHeaders" :items="items" :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style" :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')" :rows-items="[5, 10, 25, 50, 75, 100]"
            :rows-per-page="5" :loading="loading" name="courseQuizzesTable">
            <template #item-name="item">

                {{ $getPropertyTranslation(item?.name) }}


            </template>
            <template #item-operation="item">
                <SignaloDynamicMenu :rowItem="item" :menuItems="getTableActions(item)" />
            </template>
        </EasyDataTable>
    </v-main>


    <SignaloConfirmationDialog v-if="removeDialog" :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => removeQuiz(value)" />


    <div class="hidden-picker">
        <SignaloItemPicker v-if="pickerOpened" :dialogTitle="$t('common.quiz')" :endpoint="'quizzes'"
            :placeholder="$t('common.quiz')" :nameValue="'name'" clearable :disableInitLoad="false" :reloadOnOpen="true"
            :rules="[$REQUIRED_RULE]" class="required-field" :required="true" :defaultOpened="true"
            :searchConfig="itemPickerSearchConfig" @dialogChange="onDialogChange" @update:modelValue="onSelectQuiz" :filteredItems="this.items.map(i => i.id)" />
    </div>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import { SEARCH_FIELD_TYPES } from 'signalo-vue-search-container';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { dataStore, authStore, helpersStore };
    },
    data() {
        return {
            loading: false,
            items: [],
            removeDialog: false,
            itemToRemove: null,
            editedItem: null,
            courseId: this.$route.params.id,
            breadcrumb: null,
            course: null,
            pickerOpened: false,
            stepId: this.$route.params.sid,
            step: null
        };
    },
    mounted() {
        this.loadData();
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.id'),
                    value: 'id',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                    sortable: true,
                },

                {
                    value: 'operation',
                    width: 54,
                    fixed: true,
                    text: this.$t('tableHeaders.actions'),
                },
            ];
        },
        itemPickerSearchConfig() {
            return {
                name: {
                    type: SEARCH_FIELD_TYPES.TEXT,
                    cols: 12,
                    label: this.$t('common.name'),
                },
            };

        },
    },
    methods: {
        async onSelectQuiz(val) {
            try {
                await this.axios.put(`courses/${this.courseId}/steps/${this.stepId}/quizzes/${val}/attach`);
                await this.loadData();
                this.helpersStore.snackbarSaved();

            } catch (err) {
                console.error(err)
            }
        },
        onDialogChange(val) {
            if (!val) {
                this.pickerOpened = val;
            }
        },

        getTableActions() {
            return [

                {
                    action: this.openRemoveCourseDialog,
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    access: [this.$route.meta.canDelete],
                },

            ];
        },


        addButtonClicked() {
            this.pickerOpened = true;
        },
        openRemoveCourseDialog(item) {
            this.removeDialog = true;
            this.itemToRemove = item;
        },
        async removeQuiz(shouldRemove) {
            try {
                if (shouldRemove) {
                    await this.axios.delete(`courses/${this.courseId}/steps/${this.stepId}/quizzes/${this.itemToRemove.id}/detach`);
                    this.helpersStore.snackbarSaved();
                    await this.loadData();
                }

                this.removeDialog = false;
                this.itemToRemove = null;
            } catch (err) {
                console.error(err);
            }
        },
        async loadData() {
            this.loading = true;
            try {

                const courseData = await this.axios.get(`courses/${this.courseId}`);
                const stepData = await this.axios.get(`courses/${this.courseId}/steps/${this.stepId}`);

                this.course = courseData.data.item;
                this.step = stepData.data.item;
                this.items = stepData.data.item.quizes || [];
                this.initBreadcrumb();
            } catch (err) {
                console.error(err);
            }
            this.loading = false;
        },
        initBreadcrumb() {
            let res = {};
            if (this.course) {
                res.id = {
                    text: `#${this.courseId}: ${this.$getPropertyTranslation(
                        this.course.name
                    )}`,
                    value: this.courseId,
                };
            } else {
                res.id = {
                    text: `#${this.courseId}`,
                    value: this.courseId,
                };
            }

            if (this.step) {
                res.sid = {
                    text: `#${this.stepId}: ${this.$getPropertyTranslation(this.step.name)}`,
                    value: this.stepId,
                };
            } else {
                res.sid = {
                    text: `#${this.stepId}`,
                    value: this.stepId,
                };
            }
            this.breadcrumb = res;
            return res;
        }


    },

};
</script>

<style scoped>
.hidden-picker {
    visibility: hidden;
}
</style>
