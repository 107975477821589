<template>
    <PageLoading v-if="!item || loading" />
    <div class="issue-preview-inner-container" v-else>
        <div class="issue-general-info">
            <v-row v-if="previewEvents?.length" class="preview-items-bar">
                <v-icon
                    @click="onPrevIssue"
                    :style="
                        currentIndex > 0
                            ? 'visibility: visible'
                            : 'visibility: hidden'
                    "
                >
                    mdi-chevron-left
                </v-icon>
                <div>
                    {{ currentIndex + 1 }} {{ $t('common.rowsOfPage') }}
                    {{ previewEvents?.length }}
                </div>
                <v-icon
                    @click="onNextIssue"
                    :style="
                        currentIndex < previewEvents?.length - 1
                            ? 'visibility: visible'
                            : 'visibility: hidden'
                    "
                >
                    mdi-chevron-right
                </v-icon>
            </v-row>
            <v-row>
                <v-sheet
                    class="d-flex flex-column pa-3 mb-2"
                    style="border: 1px solid #ccc"
                    rounded
                >
                    <h4 class="mb-1">{{ $t('issues.created') }}</h4>
                    <p class="mb-1">
                        {{ item.created_at }}
                        <b> &nbsp; {{ $t('issues.by') }} &nbsp; </b>
                        <a
                            href="#"
                            @click.prevent="
                                $router.push({
                                    name: 'staff',
                                    query: {
                                        id_equals: item.created_by,
                                    },
                                })
                            "
                        >
                            {{ item?.created_by_user?.name }}
                        </a>
                    </p>
                    <EditBar
                        :title="$t('issues.name')"
                        field="title"
                        v-model="editingField"
                        @save="saveField"
                    />
                    <p class="mb-1 pre">
                        {{ item.title }}
                    </p>
                    <EditBar
                        :title="$t('issues.applicantsDescription')"
                        field="user_description"
                        v-model="editingField"
                        @save="saveField"
                    />

                    <p class="mb-1">
                        {{ item.user_description || '-' }}
                    </p>
                </v-sheet>
            </v-row>

            <v-row>
                <v-sheet
                    class="d-flex flex-column pa-3"
                    style="border: 1px solid #ccc"
                    rounded
                >
                    <SignaloCategoryPicker
                        v-model="item.category_id"
                        @update:modelValue="() => saveItem(true, 'category_id')"
                        v-slot="props"
                        :source="dataStore.categories_alter_structure.items"
                    >
                        <EditBar
                            :title="$t('issues.category')"
                            custom
                            field="category_id"
                            v-model="editingField"
                            @save="saveField"
                            @click:edit="props.onClick"
                        />
                    </SignaloCategoryPicker>
                    <p
                        class="mb-1 ps-1"
                        :style="
                            item.category?.color
                                ? 'border-left: 8px solid ' +
                                  item.category.color
                                : ''
                        "
                    >
                        {{ categoryPath }}
                        <v-icon
                            v-if="item.category?.icon"
                            :icon="item.category.icon"
                        />
                    </p>

                    <h4 class="mb-1">
                        {{ $t('issues.status') }}
                        <v-tooltip class="ms-2" bottom>
                            <template v-slot:activator="{ props }">
                                <v-icon color="primary" dark v-bind="props">
                                    mdi-information-outline
                                </v-icon>
                            </template>
                            <span>{{
                                $getPropertyTranslation(item.flow?.name) ||
                                $t('issues.noFlow')
                            }}</span>
                        </v-tooltip>
                    </h4>
                    <p v-if="item.flow_state" class="mb-1">
                        {{
                            this.$getPropertyTranslation(item.flow_state?.name)
                        }}
                    </p>
                    <p v-else class="mb-1">-</p>
                </v-sheet>
            </v-row>
            <v-row>
                <v-sheet
                    class="d-flex flex-column pa-3"
                    style="border: 1px solid #ccc"
                    rounded
                >
                    <p class="mb-1" v-if="item?.equipment?.id">
                        <a
                            href="#"
                            @click.prevent="
                                $router.push({
                                    name: 'machines.show',
                                    params: {
                                        id: item?.equipment?.id,
                                    },
                                })
                            "
                        >
                            #{{ item?.equipment?.id }}:
                            {{ item?.equipment?.name }}&nbsp;{{
                                item?.equipment?.symbol
                            }}
                        </a>
                    </p>
                </v-sheet>
                <v-sheet
                    class="d-flex flex-column pa-3"
                    style="border: 1px solid #ccc"
                    rounded
                    v-if="item.formal_confirm_at"
                >
                    <h4 class="mb-1">
                        {{ $t('issues.formalConfirmation') }}
                    </h4>
                    <p class="mb-1">
                        {{ item.formal_confirm_at }}
                        <br />
                        <b>{{ $t('issues.by') }}&nbsp;</b>
                        {{ item.formal_confirm_by_user?.name }}
                    </p>
                </v-sheet>
            </v-row>
        </div>
        <div class="issue-preview-actions">
            <v-btn variant="text" @click="$emit('close')">{{
                $t('common.close')
            }}</v-btn>
            <v-btn color="primary" @click="goToIssue">{{
                $t('common.show')
            }}</v-btn>
        </div>
    </div>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import EditBar from '@/views/Issues/EditBar.vue';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        return { dataStore };
    },
    components: {
        EditBar,
    },
    emits: ['close'],
    props: ['previewIssueId', 'previewEvents'],
    data() {
        return {
            loading: false,
            item: null,
            currentIndex: 0,
            displayedId: null,
        };
    },
    watch: {
        previewIssueId(val) {
            this.displayedId = val;
            if (this.previewEvents?.length) {
                this.currentIndex = this.previewEvents.findIndex(
                    (ev) => ev.id == this.displayedId
                );
            }
            this.loadIssue();
        },
        previewEvents() {
            if (this.previewEvents?.length) {
                this.currentIndex = this.previewEvents.findIndex(
                    (ev) => ev.id == this.displayedId
                );
            }
        },
    },
    async mounted() {
        await this.dataStore.categories_alter_structure.promise();
        this.displayedId = this.previewIssueId;
        if (this.previewEvents?.length) {
            this.currentIndex = this.previewEvents.findIndex(
                (ev) => ev.id == this.displayedId
            );
        }
        this.loadIssue();
    },
    computed: {
        statuses() {
            return this.item?.transitions
                .map((x) => {
                    return {
                        id: x.id,
                        name: this.$getPropertyTranslation(x.name),
                    };
                })
                .sort((x, y) => {
                    if (x.sort > y.sort) return 1;
                    if (x.sort < y.sort) return -1;
                    if (x.id > y.id) return 1;
                    if (x.id < y.id) return -1;
                    return 0;
                });
        },
        categoryPath() {
            return this.getCategoryPath(this.item.category_id);
        },
        issueCriticality() {
            return this.dataStore?.dictionary
                ?.group('issue_criticality')
                ?.filter(d => d.pickable)
                ?.find((x) => x.keyname === this.item.criticality)?.value;
        },
    },
    methods: {
        async loadIssue() {
            this.loading = true;
            const data = await this.axios.get(`issues/${this.displayedId}`);
            this.item = data?.data?.item;
            this.loading = false;
        },
        goToIssue() {
            this.$router.push({
                name: 'my_issues.show',
                params: { id: this.displayedId },
            });
        },
        getCategoryPath(id) {
            const item = this.dataStore.categories.getById(id);
            if (!item) return '-';
            if (item.parent_id)
                return (
                    this.getCategoryPath(item.parent_id) +
                    ' > ' +
                    this.$getPropertyTranslation(item.name)
                );
            else return this.$getPropertyTranslation(item.name);
        },
        onPrevIssue() {
            if (this.currentIndex === 0) return;
            this.currentIndex--;
            this.displayedId = this.previewEvents[this.currentIndex].id;
            this.loadIssue();
        },
        onNextIssue() {
            if (this.currentIndex === this.previewEvents.length - 1) return;
            this.currentIndex++;
            this.displayedId = this.previewEvents[this.currentIndex].id;
            this.loadIssue();
        },
    },
};
</script>
<style>
.issue-preview-inner-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 99999;
}
.issue-preview-inner-container .v-sheet {
    border: none !important;
}
.issue-general-info {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;
    box-sizing: border-box;
    font-size: 12px;
}
.issue-preview-actions {
    padding: 16px;
    margin-top: auto;
    display: flex;
    justify-content: space-around;
}

.issue-general-info .v-btn {
    display: none;
}
.preview-items-bar {
    margin: -16px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
