export const he = {
    common: {
        countersValues: 'ערכי מונים',
        instructionGroup: 'קבוצת הוראות',
        countableId: 'מזהה שדה ניתן לספירה',
        defaultValuesForCategory: 'ערכי ברירת מחדל לקטגוריה',
        check_user: 'האם משתמש',
        check_terminal: 'האם מסוף',
        hideWorkAreasButton: 'הסתר כפתור בחירת אזור עבודה',
        hideClassicViewButton: 'הסתר כפתור תצוגה קלאסית',
        hideAddIssueButton: 'הסתר כפתור הוספת נושא חדש',
        filesManager: 'מנהל קבצים',
        addFolder: 'הוסף תיקייה',
        upload: 'העלה',
        noFolders: 'אין תיקיות',
        noFiles: 'אין קבצים',
        folders: 'תיקיות',
        folder: 'תיקייה',
        files: 'קבצים',
        file: 'קובץ',
        removeFolderFilesQuestion: 'מה לעשות עם הקבצים שבתוך התיקייה הזו?',
        targetFolder: 'תיקיית יעד',
        addInstruction: 'הוסף הוראה',
        goToFolder: 'עבור לתיקייה',
        defaultProdLine: 'קו ייצור ברירת מחדל',
        pin_code_field_type: 'סוג קוד פין',
        instructionsAccess: 'הרשאות',
        noSections: 'ללא חלקים',
        requireReadingInstruction: 'דרוש קריאת הוראה',
        hide_parameter_types: 'אל תציג סוגי פרמטרים',
        unknownVersion: 'לא ידוע',
        displayInstructionEveryTime: 'הצג הוראה בכל פעם',
        requireSigningInstructionEveryTime: 'דרוש חתימה על ההוראה בכל פעם',
        signedInstructions: 'הוראות חתומות',
        signedAt: 'תאריך חתימה',
        signatures: 'חתימות',
        signature: 'חתימה',
        systemGroup: 'קבוצת מערכת',
        pickable: 'ניתן לבחור',
        leaf: 'עלה',
        path: 'נתיב',
        displayMode: 'מצב תצוגה',
        chart: 'תרשים',
        preSignedStatement: 'הצהרה חתומה מראש',
        bannedEmployees: 'עובדים אסורים',
        banEmployee: 'לאסור עובד',
        banned: 'אסור',
        employeeBans: 'איסורי עובדים',
        commentAboutEmployee: 'הערה לגבי עובד',
        comments: 'הערות',
        visitLog: 'יומן ביקורים',
        categoryStructure: 'מבנה קטגוריה',
        appVersionMismatch: 'גרסת אפליקציה לא תואמת',
        appVersionMismatchMessage: 'רענן את האפליקציה כדי לעדכן גרסה.',
        PIN_CODE_LABEL: 'קוד PIN',
        comment: 'תגובה',
        year: 'שנה',
        configuration: 'תצורה',
        cancel: 'בטל',
        search: 'חיפוש',
        remove: 'הסר',
        confirmationDialogTitle: 'האם אתה בטוח שברצונך להסיר פריט זה?',
        selectDate: 'בחר תאריך',
        select: 'בחר',
        notFound: 'לא נמצא',
        equipmentPickerPlaceholder: 'אובייקט ציוד',
        searchPlaceholder: 'חפש',
        equipmentTypePickerPlaceholder: 'סוג ציוד',
        equipmentTypePickerTitle: 'בחר סוג ציוד',
        emptyTableMessage: 'אין נתונים',
        flowPikcerTitle: 'בחר זרימה',
        selectIcon: 'בחר סמל',
        locationPickerTitle: 'בחר מיקום',
        locationPlaceholder: 'מיקום',
        noSublocations: 'אין תת-מיקומים',
        locationsTitle: 'מיקומים',
        edit: 'ערוך',
        save: 'שמור',
        added: 'נוסף',
        updated: 'עודכן',
        add: 'הוסף',
        removed: 'הוסר',
        reload: 'טען מחדש',
        confirm: 'אשר',
        rollBack: 'שחזר',
        images: 'תמונות',
        documents: 'מסמכים',
        download: 'הורד',
        saved: 'נשמר',
        date: 'תאריך',
        hour: 'שעה',
        preview: 'תצוגה מקדימה',
        data: 'נתונים',
        attributes: 'מאפיינים',
        checkList: 'רשימת בדיקה',
        name: 'שם',
        type: 'סוג',
        status: 'סטטוס',
        designation: 'ייעוד',
        symbol: 'סמל',
        serialNumber: 'מספר סידורי',
        registryNumber: 'מספר רישום',
        machineNumber: 'מספר מכונה',
        udtNumber: 'מספר UDT',
        manufacturedYear: 'שנת ייצור',
        manufacturer: 'יצרן',
        supplier: 'ספק',
        mainSupplier: 'ספק ראשי',
        attribute: 'תכונה',
        nameRequired: 'שם נדרש',
        yearGreaterThan: 'השנה חייבת להיות גדולה מ',
        location: 'מיקום',
        fieldRequired: 'שדה זה הוא חובה',
        description: 'תיאור',
        minimumQuantity: 'כמות מינימלית',
        optimalQuantity: 'כמות אופטימלית',
        priority: 'עדיפות',
        formatYYYY: 'פורמט YYYY',
        storageQuantity: 'כמות במחסן',
        quantity: 'כמות',
        quantityGreaterThan: 'הכמות חייבת להיות גדולה מ',
        machine: 'מכונה',
        tool: 'כלי',
        order: 'הזמנה',
        required: 'נדרש',
        dateFormat: 'פורמט תאריך',
        selectDictionary: 'בחר מילון',
        return: 'החזר',
        parameters: 'פרמטרים',
        editCategory: 'ערוך קטגוריה',
        addCategory: 'הוסף קטגוריה חדשה',
        changeColor: 'שנה צבע',
        noColor: 'אין צבע',
        shift: 'משמרת',
        start: 'התחלה',
        end: 'סיום',
        shiftSchema: 'סכמת משמרות',
        shifts: 'משמרות',
        dictionary: 'מילון',
        subEntries: 'ערכי משנה',
        key: 'מפתח',
        value: 'ערך',
        moveUp: 'העבר למעלה',
        moveDown: 'העבר למטה',
        addLocation: 'הוסף מיקום חדש',
        editLocation: 'ערוך מיקום',
        companiesDatabase: 'מאגר חברות',
        flows: 'זרימות',
        read: 'קריאה',
        write: 'כתיבה',
        noImage: 'אין תמונה',
        email: "דוא'ל",
        function: 'תפקיד',
        active: 'פעיל',
        notActive: 'לא הופעל',
        disabled: 'מנוטרל',
        entryNumber: 'מספר ערך',
        issuedDate: 'תאריך הוצאה',
        expiresDate: 'תאריך תפוגה',
        move: 'העבר',
        separateForm: 'הפרד מ',
        separate: 'הפרד',
        warehouse: 'מחסן',
        actions: 'פעולות',
        putInCurrentLocation: 'הכנס למיקום הנוכחי',
        selectLocationFormRightMenu: 'בחר מיקום מהתפריט בצד ימין',
        showUnassignedItems: 'הצג פריטים לא מוקצים',
        separated: 'מופרד',
        moved: 'הועבר',
        categories: 'קטגוריות',
        welcome: 'ברוך הבא',
        loginToSignaloTpm: 'התחבר ל-Signalo TPM',
        login: 'התחבר',
        loginByEmailAndPassword: "התחבר באמצעות דוא'ל וסיסמה",
        loginByCode: 'התחבר באמצעות @:common.PIN_CODE_LABEL',
        accessCode: 'קוד גישה',
        enterEmail: "הכנס דוא'ל",
        enterPassword: 'הכנס סיסמה',
        enterCode: 'הכנס @:common.PIN_CODE_LABEL',
        userNotFound: 'משתמש לא נמצא',
        userNotActivated: 'משתמש לא הופעל',
        userDisabled: 'משתמש מנוטרל',
        wrongPassword: 'סיסמה שגויה',
        locations: 'מיקומים',
        pageNotFound: 'הדף לא נמצא',
        group: 'קבוצה',
        yes: 'כן',
        no: 'לא',
        hourlyCost: 'עלות לשעה',
        price: 'מחיר',
        newEntry: 'ערך חדש',
        predictedFinishTime: 'זמן סיום משוער',
        ok: 'אישור',
        archive: 'ארכיון',
        costs: 'עלויות',
        companyManagement: 'ניהול חברה',
        companyData: 'נתוני חברה',
        supportedLanguages: 'שפות נתמכות',
        en: 'אנגלית',
        pl: 'פולנית',
        es: 'ספרדית',
        de: 'גרמנית',
        it: 'איטלקית',
        fr: 'צרפתית',
        ru: 'רוסית',
        uk: 'אוקראינית',
        pt: 'פורטוגזית',
        tr: 'טורקית',
        nl: 'הולנדית',
        sr: 'סרבית',
        ar: 'עֲרָבִית',
        he: 'עִברִית',
        zh: 'סינית',
        defaultLanguage: 'שפה ברירת מחדל',
        companyLogo: 'לוגו חברה',
        notificationsManagement: 'ניהול התראות',
        category: 'קטגוריה',
        today: 'היום',
        month: 'חודש',
        week: 'שבוע',
        day: 'יום',
        close: 'סגור',
        show: 'הצג',
        rollBackFormalConfirmation: 'ביטול אישור פורמלי',
        signUp: 'הרשמה',
        nameSurname: 'שם ושם משפחה',
        phone: 'מספר טלפון',
        password: 'סיסמה',
        password_confirmation: 'אשר סיסמה',
        unassignedItems: 'פריטים לא מוקצים',
        currency: 'מטבע',
        general: 'כללי',
        timezone: 'אזור זמן',
        users: 'משתמשים',
        groups: 'קבוצות',
        productionLine: 'קו ייצור',
        part: 'חלק',
        fittingParts: 'חלקים מתאימים',
        workSchema: 'סכמת עבודה',
        workSchemas: 'סכמות עבודה',
        holiday: 'חופשה',
        changePassword: 'שנה סיסמה',
        showRest: 'הצג שאר',
        pageNotPermitted: 'דף לא מורשה',
        waitingForActivation: 'ממתין להפעלה',
        editGroup: 'ערוך קבוצה',
        addGroup: 'הוסף קבוצה',
        reportProblem: 'דווח על בעיה',
        describeOccuredIssue: 'תאר את הבעיה שקרתה',
        markAllAsRead: 'סמן הכל כנקרא',
        unitType: 'סוג יחידה',
        unit: 'יחידה',
        unitPrice: 'מחיר יחידה',
        notConfigured: 'לא מוגדר',
        rowsPerPage: 'שורות לעמוד',
        rowsOfPage: 'מתוך',
        true: 'אמת',
        false: 'שקר',
        export: 'ייצא',
        chooseFileFormat: 'בחר פורמט קובץ',
        exported: 'יוצא',
        terminals: 'מסופים',
        terminal: 'מסוף',
        selectEquipment: 'בחר ציוד',
        futureIssues: 'בעיות עתידיות',
        scheduledOn: 'מתוזמן לתאריך',
        planned: 'מתוכנן',
        notPlanned: 'לא מתוכנן',
        showOpenIssues: 'הצג בעיות פתוחות',
        pin: 'PIN',
        assignedEquipment: 'ציוד מוקצה',
        terminalEquipment: 'ציוד מסוף',
        detach: 'נתק',
        attach: 'צרף',
        dashboardConfig: 'תצורת לוח מחוונים',
        addColumn: 'הוסף עמודה',
        addRow: 'הוסף שורה',
        removeRow: 'הסר שורה',
        registerTerminal: 'רשום מסוף',
        issueGeneratesCost: 'זמן השבתה יוצר עלות',
        selectColor: 'בחר צבע',
        belowMinimum: 'מתחת למינימום',
        belowOptimum: 'מתחת לאופטימום',
        synchronization: 'סנכרון',
        synchronizationExport: 'ייצוא חלקים מהמלאי',
        synchronizationImport: 'ייבוא חלקים למלאי',
        mapping: 'מיפוי',
        returnTo: 'חזור אל',
        jobStatusses: 'תהליכים',
        hidden: 'מוסתר',
        editEvent: 'ערוך אירוע',
        threeDays: '3 ימים',
        addEvent: 'הוסף אירוע',
        plannedIssues: 'בעיות מתוכננות',
        currentService: 'שירות נוכחי',
        searchLocation: 'מצא מיקום',
        all: 'הכל',
        unfinished: 'לא הושלם',
        numberOfIssues: 'כמות בעיות',
        unknown: 'לא ידוע',
        partEntry: 'חלק (כניסה)',
        foundItems: 'פריטים שנמצאו',
        output: 'פלט',
        copied: 'הועתק',
        link: 'קישור',
        authorizationRequired: 'נדרשת הרשאה',
        authorizationRequiredDescriptionUser:
            'לצערנו אין לך הרשאה מספקת. בקש מאדם מורשה לאשר את הפעולה.',
        authorizationRequiredDescriptionTerminal:
            'השתמש בקוד הגישה שלך @:common.PIN_CODE_LABEL כדי לאשר את הפעולה.',
        showAll: 'הצג הכל',
        showClassicView: 'הצג תצוגה קלאסית',
        noIssuesInCategory: 'אין בעיות בקטגוריה זו.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'מספר הבעיות בקטגוריה זו ותתי-הקטגוריות.',
        numberOfIssuesInThisCategory: 'מספר הבעיות בקטגוריה זו.',
        minBarcodeLength: 'אורך מינימלי של ברקוד',
        currentPage: 'עמוד נוכחי',
        allPages: 'כל העמודים',
        selectFormat: 'בחר פורמט',
        provided_currency: 'מטבע שסופק',
        provided_quantity: 'כמות שסופקה',
        provided_unit: 'יחידה שסופקה',
        provided_unit_price: 'מחיר יחידה שסופק',
        unit_price: 'מחיר יחידה',
        quantity_available: 'כמות זמינה',
        editBarcode: 'ערוך ברקוד',
        crews: 'צוותים',
        scheduleEventTypes: 'סוגי אירועים מתוזמנים',
        crew: 'צוות',
        scheduleEvent: 'אירוע מתוזמן',
        available: 'זמין',
        'App\\Models\\Crew': 'צוות',
        'App\\Models\\User': 'משתמש',
        'App\\Models\\Equipment': 'ציוד',
        Crew: 'צוות',
        User: 'משתמש',
        Equipment: 'ציוד',
        scheduleEvents: 'אירועים מתוזמנים',
        event: 'אירוע',
        externalSystems: 'מערכות חיצוניות',
        user: 'משתמש',
        availability: 'זמינות',
        inheritColorFromParentIfCustomNotSet:
            'ירש צבע מההורה אם לא הוגדר מותאם אישית',
        pickFromAllParts: 'בחר מכל החלקים',
        takeTool: 'קח כלי',
        returnTool: 'החזר כלי',
        personWhoTake: 'האדם שלקח',
        personWhoReturn: 'האדם שהחזיר',
        takenAt: 'נלקח בתאריך',
        takenBy: 'נלקח על ידי',
        toolIssuedBy: 'הכלי הונפק על ידי',
        commentTake: 'הערת לקיחה',
        commentReturn: 'הערת החזרה',
        change: 'שנה',
        onlyWithActiveRMIssues: 'רק עם בעיות RM פעילות',
        externalSystem: 'מערכת חיצונית',
        rejectIssue: 'דחה בעיה',
        withdrawIssueRejection: 'בטל דחיית בעיה',
        rejectionReason: 'סיבת הדחייה',
        rejected: 'נדחה',
        showCrewMembers: 'הצג חברי צוות',
        crewMembers: 'חברי צוות',
        helpGroup: 'שירות',
        partsTakenByUser: 'חלקים שנלקחו על ידי המשתמש',
        pickParts: 'הוציא חלקים',
        selectPersonWhoTakes: 'בחר אדם שלוקח',
        changePersonWhoTakes: 'שנה אדם שלוקח',
        quantityToPick: 'כמות לאיסוף',
        pickLocation: 'מיקום לאיסוף',
        selectPartsToPick: 'בחר חלקים לאיסוף',
        partSelected: 'חלק נבחר',
        scanCodeOr: 'סרוק קוד או',
        returnPart: 'החזר חלק',
        myTakenParts: 'חלקים שנלקחו על ידי',
        takenFromLocation: 'נלקח מהמיקום',
        takenQuantity: 'כמות שנלקחה',
        providedUnit: 'יחידה שסופקה',
        providedQuantity: 'כמות שסופקה',
        selectFromStorage: 'בחר מהמלאי',
        selectFromMyPickedParts: 'בחר מהחלקים שנלקחו על ידי',
        exportProcessInProgressMessage:
            'תהליך ייצוא הנתונים התחיל זה עתה, עם השלמתו תראה התראה הכוללת קישור לקובץ.',
        yourDocumentIsReady: 'המסמך שלך מוכן',
        rotateScreen: 'סובב מסך',
        addRule: 'הוסף כלל',
        editRule: 'ערוך כלל',
        summary: 'סיכום',
        onlyAvailableEmployees: 'רק עובדים זמינים',
        calculated_unit_price: 'מחיר יחידה מחושב',
        calculated_quantity: 'כמות מחושבת',
        next: 'הבא',
        maintenanceMode: 'מצב תחזוקה',
        applicationMayBeUnavailableForFewMinutes:
            'היישום עשוי להיות לא זמין למספר דקות',
        appWillRefreshIn: 'היישום ייטען מחדש בעוד',
        landscape: 'נוף',
        portrait: 'דיוקן',
        orientation: 'כיוון',
        format: 'פורמט',
        service: 'שירות',
        leader: 'מנהיגים',
        defaultView: 'תצוגת ברירת מחדל',
        view: 'תצוגה',
        oneDay: 'יום אחד',
        object: 'אובייקט',
        finished: 'הושלם',
        onlyNotFinished: 'רק לא הושלם',
        generate: 'צור',
        error: 'שגיאה',
        cell: 'תא',
        resolutionSummary: 'סיכום סיום',
        rejected_at: 'תאריך דחייה',
        rejected_by: 'נדחה על ידי',
        finishedByUser: 'הושלם על ידי',
        formalConfirmByUser: 'אושר פורמלית על ידי',
        formalConfirmAt: 'תאריך אישור פורמלי',
        valueDeleted: '[ערך נמחק]',
        hasNotPlannedIssue: 'אין בעיות מתוכננות',
        showInStorage: 'הצג במלאי',
        changeCode: 'שנה @:common.PIN_CODE_LABEL',
        staffProfiles: 'פרופילי צוות',
        profile: 'פרופיל',
        user_bug: 'באג ביישום',
        user_kaizen: 'הצעת שינוי',
        changeSuggestions: 'הצעות לשינוי',
        showOnlyCurrentlyAvailable: 'הצג רק את הזמינים כעת',
        resetPassword: 'אפס סיסמה',
        forgotPassword: 'שכחת סיסמה',
        resetPasswordMailSent: "שלחנו קישור לאיפוס סיסמה לדוא'ל שסופק",
        manyEventsThisTime: 'הרבה אירועים בזמן זה',
        importScheduleCheckboxLabel:
            'מחק אירועים מתוזמנים בחודש הנבחר לעובדים הרשומים בקובץ',
        inheritedEvent: 'אירוע ירושה',
        inheritedModifiedEvent: 'אירוע ירושה ששונה',
        equipmentId: 'מזהה ציוד',
        entryId: 'מזהה ערך',
        partId: 'מזהה חלק',
        suggestionsEmail: "דוא'ל אליו ישלחו הצעות לשינוי",
        meters: 'מטרים',
        scanCardToAuth:
            'סרוק כרטיס או הכנס את @:common.PIN_CODE_LABEL שלך כדי לאמת את הזהות',
        scanCard: 'סרוק כרטיס',
        cart: 'תיבת איסוף',
        emptyCardMessage:
            'התחל לסרוק מוצרים או בחר אותם מהרשימה כדי להוסיף אותם לתיבת האיסוף.',
        terminalMode: 'סוג מסוף',
        askPermittedUserToConfirmPartsExchange:
            'בקש מהמשתמש המורשה לאשר את שחרור/החזרת החלק על ידי סריקת הכרטיס או הכנס את @:common.PIN_CODE_LABEL',
        partsIssued: 'חלקים שהונפקו',
        areYouSureAboutCancel: 'האם אתה בטוח שברצונך לבטל?',
        areYouSureAboutRemoveFromCart:
            'האם אתה בטוח שברצונך להסיר פריט זה מתיבת האיסוף?',
        standard: 'סטנדרטי',
        idNumber: 'מספר זיהוי',
        takeParts: 'קח חלקים',
        returnParts: 'החזר חלקים',
        quantityToReturn: 'כמות להחזרה',
        partsPendingApproval: 'חלקים ממתינים לאישור',
        partsReturned: 'חלקים הוחזרו',
        partsAddedToPendingApproval: 'חלקים נוספו לאישור ממתין',
        approvePartsReturn: 'אשר החזרת חלקים',
        partReturnApproved: 'החזרת החלק אושרה',
        partUsage: 'שימוש בחלק',
        awaitsReturn: 'ממתין להחזרה',
        entrySelection: 'בחירת ערך',
        correctQuantity: 'כמות נכונה',
        quantityCorrection: 'תיקון כמות',
        entrySelectionAsc: 'בחירת ערך (עולה)',
        entrySelectionDesc: 'בחירת ערך (יורד)',
        metersSettings: 'הגדרות מטרים',
        countableFields: 'שדות ניתנים לספירה',
        equipmentTypes: 'סוגי ציוד',
        fieldName: 'שם שדה',
        countableField: 'שדה ניתן לספירה',
        partsStatusTaken: 'נלקח',
        rejectPartsReturn: 'דחה החזרת חלקים',
        areYouSureAboutRejectPartsReturn:
            'האם אתה בטוח שברצונך לדחות את החזרת החלקים?',
        partReturnRejected: 'החזרת החלק נדחתה',
        counterFields: 'שדות מונה',
        counterName: 'שם מונה',
        counterFieldName: 'שם שדה מונה',
        counterId: 'מזהה מונה',
        counterUUID: 'UUID של המונה',
        counterPickerPlaceholder: 'בחר מונה',
        pickFromPartsList: 'בחר מרשימת החלקים',
        changeLocation: 'שנה מיקום',
        isPartStorageRoot: 'שורש אחסון החלקים',
        areYouSureAboutArchiveIssue: 'האם אתה בטוח שברצונך לארכב את הבעיה הזו?',
        ganttChart: 'תרשים גאנט',
        table: 'טבלה',
        fittingEquipment: 'ציוד מתאים',
        notAssigned: 'לא הוקצה',
        counters: 'מונהים',
        machineHourlyCostNotProvided: 'לא הוגדרה עלות שעתית למכונה',
        eventTypesMightBeDefinedHere: 'סוגי אירועים עשויים להיות מוגדרים כאן',
        toolsTakenByUser: 'כלים שנלקחו על ידי המשתמש',
        predictedReturnDate: 'תאריך החזרה משוער',
        estimatedTimeOfUsingToolInHours: 'זמן שימוש משוער בכלי (בשעות)',
        showOnlyAvailable: 'הצג רק את הזמינים',
        toolTakeLog: 'יומן לקיחות והחזרות',
        takeDate: 'תאריך לקיחה',
        returnDate: 'תאריך החזרה',
        personWhoAcceptReturn: 'האדם שמאשר את ההחזרה',
        statisticStrategy: 'אסטרטגיית נגישות',
        EquipmentPlannedStrategy: 'לפי תכנית עבודה',
        EquipmentConstantAvailabilityStrategy: 'זמינות מתמשכת',
        actionAfterIssueFinish: 'פעולה לאחר סיום הבעיה',
        enabled: 'מופעל',
        categoryOfNewIssue: 'קטגוריה של בעיה חדשה',
        workNotStarted: 'העבודה לא התחילה',
        colorInherited: 'הצבע נרש מההורה',
        abcClassification: 'סיווג ABC',
        xyzClassification: 'סיווג XYZ',
        classification: 'סיווג',
        filters: 'מסננים',
        wipeFilters: 'נקה מסננים',
        takePartsFromUser: 'קח חלקים מהמשתמש',
        selectPersonWhoReturns: 'בחר אדם שמחזיר חלקים',
        changePersonWhoReturns: 'שנה אדם שמחזיר חלקים',
        returnLocation: 'מיקום החזרה',
        returnQuantity: 'כמות להחזרה',
        globalSettings: 'הגדרות כלליות',
        delimiter: 'תוחם',
        fieldRules: 'חוקי שדה',
        fieldValue: 'ערך שדה',
        user_email_regex: "רגקס של דוא'ל משתמש",
        barcodeLength: 'אורך ברקוד',
        partLine: 'קו חלקים',
        partFamily: 'משפחת חלקים',
        partBatch: 'אצוות חלקים',
        testValueValid: 'ערך מבחן תקף',
        testValueNotValid: 'ערך מבחן לא תקף',
        canBePicked: 'ניתן לבחירה',
        issue_create_photos: 'הוספת תמונות לבעיה חדשה',
        issue_create_documents: 'הוספת מסמכים לבעיה חדשה',
        propagateValues: 'הפץ ערכים',
        archived: 'מוארכב',
        escalations_tab: 'הסלמות',
        minute: 'דקה',
        days: 'ימים',
        hours: 'שעות',
        minutes: 'דקות',
        warnDays: 'התראה לפני תפוגה (ימים)',
        licenseType: 'סוג רישיון',
        licenseGroup: 'קבוצת רישיון',
        attachments: 'קבצים מצורפים',
        attachment: 'קובץ מצורף',
        revisionChanges: 'שינויים שנעשו',
        document: 'מסמך',
        noInstructionSelected: 'לא נבחרה הנחיה',
        instructionGroups: 'קבוצות הנחיות',
        addOption: 'הוסף אפשרות',
        ordinal: 'ערך סדרתי',
        parameterName: 'שם פרמטר',
        fontColor: 'צבע גופן',
        backgroundColor: 'צבע רקע',
        columns: 'מספר עמודות',
        colspan: 'רוחב (עמודות)',
        products: 'מוצרים',
        controlsTemplates: 'תבניות בדיקה',
        controlType: 'סוג בדיקה',
        controls: 'בדיקות',
        controlTemplate: 'תבנית בדיקה',
        product: 'מוצר',
        checklistsAttachedToControlTemplate: 'רשימות ביקורת שהוקצו לבדיקה',
        removeChecklistFromControlTitle:
            'האם אתה בטוח שברצונך להסיר את הרשימה מבדיקה זו?',
        attachChecklistToControl: 'הקצה רשימה לבדיקה',
        pickedItem: 'פריט נבחר',
        pick: 'בחר פריט',
        fillControl: 'השלם את הבדיקה',
        back: 'חזרה',
        control: 'בדיקה',
        duplicate: 'שכפל',
        isStorageRoot: 'מיקום שורש אחסון',
        manageStorageAccess: 'נהל גישה למחסנים',
        manageVisibleColumns: 'התאם את נראות העמודות',
        columnName: 'שם עמודה',
        visible: 'גלוי',
        entriesLocation: 'מיקום רשומות',
        details: 'פרטים',
        otherData: 'נתונים אחרים',
        moveAllEntriesFromCurrentLocation: 'העבר את כל הרשומות ממיקום זה',
        searchInCurrentLocation: 'חפש במיקום הנוכחי',
        transition: 'מעבר',
        notification: 'התראה',
        emails: "כתובות דוא'ל",
        phones: 'מספרי טלפון',
        addAction: 'הוסף פעולה',
        message: 'הודעה',
        application: 'אפליקציה',
        always: 'תמיד',
        sms: 'SMS',
        loginTo: 'התחבר אל',
        appName: 'Signalo TPM',
        index: 'אינדקס',
        showCodeOwnerNameDuringParing:
            'הצג את שם הבעלים של @:common.PIN_CODE_LABEL בעת הצימוד עם כרטיס/תג',
        showUsernameInCart: 'הצג את שם המשתמש בעגלת האיסוף',
        sampleButtonPlaceholder: 'כפתור לדוגמה',
        preferences: 'העדפות',
        buttonsSize: 'גודל הכפתורים',
        erase: 'מחק',
        selectDictionaryValue: 'בחר ערך ממילון',
        conditions: 'תנאים',
        partsCartTerminalRequireApproveOnPartsReturn:
            'נדרש אישור או דחייה בעת החזרת חלקים שהורדו',
        issueCreated: 'נוצר כרטיס עבודה',
        allowedIssuesCategories: 'קטגוריות בעיות מותרות',
        hiddenFields: 'שדות מוסתרים',
        defaultValues: 'ערכי ברירת מחדל',
        paddingBottom: 'רווח ריק מלמטה',
        allVisibleFieldsRequired: 'נדרש להשלים את כל השדות הגלויים',
        url: 'כתובת URL',
        kind: 'סוג',
        forkfleetConfigId: 'תצורת Forkfleet',
        create: 'צור',
        terminate: 'סיים',
        parent: 'הורה',
        phoneNumbers: 'מספרי טלפון',
        storageDefaults: 'ערכי ברירת מחדל למחסן',
        clientSignatureEnabled: 'חתימת האוסף',
        warehousemanSignatureEnabled: 'חתימת המחסנאי',
        storage_client_signature_enabled: 'חתימת האוסף מהמחסן',
        storage_client_signature_required: 'דרוש חתימת האוסף מהמחסן',
        storage_warehouseman_signature_enabled: 'חתימת המשחרר מהמחסן',
        storage_warehouseman_signature_required: 'דרוש חתימת המשחרר מהמחסן',
        clientReturnSignatureEnabled: 'חתימת המחזיר',
        warehousemanReturnSignatureEnabled: 'חתימת מקבל ההחזרה',
        storage_client_return_signature_enabled: 'חתימת המחזיר מהמחסן',
        storage_client_return_signature_required: 'דרוש חתימת המחזיר מהמחסן',
        storage_warehouseman_return_signature_enabled:
            'חתימת מקבל ההחזרה מהמחסן',
        storage_warehouseman_return_signature_required:
            'דרוש חתימת מקבל ההחזרה מהמחסן',
        channels: 'ערוצים',
        manageState: 'נהל מצב',
        assignWorker: 'הקצה עובד',
        mergeEntriesOfTheSameLocation: 'מזג רשומות מאותו מיקום',
        permission: 'הרשאה',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'האם אתה בטוח שברצונך למזג את כל הרשומות במיקום זה?',
        manageFilters: 'נהל מסננים',
        responsible_assign_creator_when_no_rule:
            '[בעיות] - הקצה את היוצר כאחראי אם לא נמצאה התאמה לכלל',
        recreateIssue: 'צור מחדש את הבעיה',
        addNewDictionary: 'הוסף מילון חדש',
        label: 'תווית',
        issuesWithRelatedCategories: 'כלול תתי קטגוריות',
        issuesLimit: 'מגבלת בעיות',
        issuesLimits: 'בעיות - מגבלות',
        issueDefaults: 'בעיות - ערכי ברירת מחדל',
        flatCategoryPickerLabel: 'מבנה קטגוריות שטוח',
        statementsTemplates: 'תבניות הצהרה',
        statementTemplate: 'תבנית הצהרה',
        content: 'תוכן',
        readonly: 'קריאה בלבד',
        defaultValue: 'ערך ברירת מחדל',
        fillParametersToChangeState:
            'מלא את הערכים עבור הפרמטרים למטה לשינוי המצב',
        formatPattern: 'תבנית פורמט',
        countableIncFields: 'תצורת מספור מסמכים',
        mainShift: 'משמרת ראשית',
        singleEvent: 'רשומה יחידה',
        seriesOfEvents: 'סדרת רשומות',
        omitWeekends: 'התעלם מסופי שבוע',
        allEntriesSaved: 'כל הרשומות נוספו',
        surveyResults: 'תוצאות סקר',
        qrActionsSettings: 'הגדרות פעולות קוד QR',
        pendingStatements: 'הצהרות לחתימה',
        statementsResults: 'הצהרות',
        statements: 'הצהרות',
        statement: 'הצהרה',
        userCanChangeContent: 'המשתמש יכול לשנות את התוכן',
        statementsEvents: 'תצורת אירועים - הצהרות',
        helpdesk: 'תמיכה טכנית',
        terminalId: 'מזהה מסוף',
        operatorId: 'מזהה מפעיל',
        newConversation: 'שירשור חדש',
        formula: 'נוסחה',
        fill: 'מלא',
        webMessage: 'הודעת אינטרנט',
        noResponsibleUser: 'אין משתמש אחראי',
        filledAtDate: 'תאריך השלמה',
        filledBy: 'הושלם על ידי',
        parts_quantity_alert_rule: '[כמות חלקים וחומרים] - אזהרה',
        employeeNumber: 'מספר עובד',
        groupsAssignedToCategory: 'קבוצות שהוקצו לקטגוריות',
        number: 'מספר',
        customers: 'לקוחות',
        customer: 'לקוח',
        projects: 'פרויקטים',
        project: 'פרויקט',
        report: "דו'ח",
        reportGenerationInProgress: "הדו'ח נוצר",
        storageStatementGroup: 'קבוצת הצהרות מחסן',
        simplifiedHandling: 'טיפול בהתראות מופשטות',
        selectWorkArea: 'בחר את אזור העבודה שלך',
        workAreaSelection: 'בחירת אזור עבודה',
        access: 'גישה',
        restrictAccess: 'הגבל גישה',
        anchor: 'עיגון',
        issueCreator: 'יוצר הבעיה',
        hideSideMenu: 'הסתר תפריט צדדי',
        appendGroups: 'קבוצות להוספה',
        revokeGroups: 'קבוצות להסרה',
        override: 'דרוס',
        restrictGroupAccess: 'הגבל גישת קבוצה',
        flag: 'דגל',
        flags: 'דגלים',
        manageFlags: 'נהל דגלים',
        icon: 'סמל',
        appendFlags: 'דגלים להוספה',
        revokeFlags: 'דגלים להסרה',
        checkIsTerminal: 'בודק את המסוף',
        noConditionsDefined: 'לא הוגדרו תנאים',
        checkIsResponsible: 'בדיקת אחריות',
        createdOn: 'דווח מ',
        replaceTimespan: 'חיי מדף מזמן הבעיה',
        months: 'חודשים',
        handoversTemplates: 'תבניות העברת משמרת',
        handoverTemplate: 'תבנית העברת משמרת',
        employeeFunction: 'תפקיד העובד',
        takeHandover: 'קח העברת משמרת',
        handovers: 'העברות משמרת',
        expiringHandovers: 'פריטים שמתקרבים לתאריך תפוגה',
        years: 'שנים',
        taken_parts_expiry_warning: 'אזהרה לפני תאריך התפוגה של פריטים שהורדו',
        andonView: 'תצוגת אנדון',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'אחר',
        tags: 'כרטיסים / תגיות',
        tag: 'כרטיס / תג',
        userPinCode: 'קוד PIN משתמש כרטיס @:common.PIN_CODE_LABEL',
        emailConfirmation: "אישור כתובת דוא'ל",
        from: 'מאת',
        to: 'אל',
        handoversHistory: 'היסטוריית העברת משמרות',
        partUsagesHistory: 'היסטוריית שימוש בחלקים',
        signaturePinTagConfirmationText1: 'חתום עם @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'או סרוק את הכרטיס שלך.',
        signedWithPinCode: 'נחתם עם @:common.PIN_CODE_LABEL',
        signedWithTAG: 'נחתם עם כרטיס / תג',
        test_mode: 'מצב בדיקה',
        confirmWorkFinish: 'אשר סיום עבודה',
        workStarted: 'תחילת עבודה',
        workFinished: 'סיום עבודה',
        workTimeRegister: 'רישום זמן עבודה',
        measureTypes: 'סוגי מדידות',
        measureType: 'סוג מדידה',
        measurable: 'מדד מדיד',
        measurables: 'מדדים מדידים',
        measurements: 'מדידות',
        measurement: 'מדידה',
        measuredAt: 'תאריך מדידה',
        currentVersion: 'גרסה נוכחית',
        employee: 'עובד',
        pin_code_label: 'שם קוד PIN',
        orders: 'הזמנות',
        productionPlan: 'תוכנית ייצור',
        productionPlans: 'תוכניות ייצור',
        taktTime: 'זמן טאקט',
        target: 'מטרה',
        seconds: 'שניות',
        inheritValuesFromTenantSettings: 'ירש ערכי ברירת מחדל מהגדרות גלובליות',
        matrices: 'מטריצות',
        manageMatrice: 'נהל מטריצה',
        deletionOfMatrixColumnWarnMessage: 'מחיקת עמודה תמחק את הערכים.',
        removeColumn: 'מחק עמודה',
        editColumn: 'ערוך עמודה',
        column: 'עמודה',
        row: 'שורה',
        evaluated: 'הוערך',
        of: 'מתוך',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - יצירה אוטומטית',
        or: 'או',
        rename: 'שנה שם',
        check_only_on_transition: 'בדוק רק כאשר הסטטוס משתנה',
        license: 'הרשאות',
        confirmWorkStart: 'אשר תחילת עבודה',
        workStartAt: 'אתה מתחיל את העבודה בשעה',
        workFinishAt: 'אתה מסיים את העבודה בשעה',
        workStartedAt: 'התחלת עבודה',
        workTimeMessage: 'עבדת שעות נוספות',
        haveANiceDay: 'שיהיה לך יום נעים!',
        farewellText: 'טקסט במסך האחרון',
        namedayLabel: 'חוגגים את יום השם שלהם',
        sportEventNearby: 'האירוע הספורט הקרוב',
        autoTranslate: 'תרגום אוטומטי',
        auto_translation: 'תרגום אוטומטי',
        awaitingAutoTranslation: 'ממתין לתרגום אוטומטי',
        inheritAllDefaultValues: 'ירש את כל ערכי ברירת המחדל מהגדרות גלובליות',
        escalations: 'הסלמות',
        escalation: 'הסלמה',
        notTaken: 'לא התחיל',
        notFinished: 'לא הושלם',
        issueLastChange: 'שינוי אחרון בבעיה',
        mode: 'מצב',
        repeatLastEscalation: 'חזור על ערך ההסלמה האחרון',
        manageActions: 'נהל פעולות',
        productionLines: 'קווי ייצור',
        productionTerminal: 'מסוף ייצור',
        currently: 'כרגע',
        currentPlan: 'תוכנית נוכחית',
        currentlyInProduction: 'מיוצר כרגע',
        setProduct: 'הגדר מוצר',
        second: 'שניות',
        work: 'עבודה',
        chartConfiguration: 'תצורת תרשים',
        revisionsToConsider: 'תיקונים לשקול',
        allInstructions: 'כל ההוראות',
        decider: 'קובע',
        considerRevision: 'שקול תיקון',
        and: 'וגם',
        acceptInstruction: 'קבל הוראה',
        accept: 'קבל',
        instructionsToAccept: 'הוראות לקריאה',
        open: 'פתח',
        assignedAt: 'תאריך הקצאה',
        confirmationAt: 'תאריך אישור',
        mainInstruction: 'הוראת ברירת מחדל',
        setAsDefault: 'הגדר כברירת מחדל',
        instructionsFlows: 'הוראות - זרימות',
        newRevision: 'תיקון חדש',
        playlist: 'רשימת וידאו',
        selectVideo: 'בחר סרט',
        selectVideos: 'בחר סרטים',
        uploadNewVideo: 'העלה וידאו חדש',
        upload_date: 'תאריך העלאה',
        thumbnail: 'תמונה ממוזערת',
        duration: 'משך',
        uploadFromDrive: 'העלה מהדיסק',
        selectAssets: 'בחר חומרים',
        assets: 'חומרים',
        video: 'וידאו',
        doYouWantToAddNewMarker: 'האם תרצה להוסיף סמן חדש כאן?',
        areYouSureAboutRemoveMarker: 'האם אתה בטוח שברצונך להסיר את הסמן הזה?',
        plants: 'מפעלים',
        plant: 'מפעל',
        buildings: 'בניינים',
        building: 'בניין',
        floors: 'קומות',
        floor: 'קומה',
        map: 'מפה',
        areYouSureAboutMoveMarker: 'האם אתה בטוח שברצונך להזיז את הסמן הזה?',
        marker: 'סמן',
        addNewMarkerTooltipMessage:
            'כדי להוסיף סמן חדש, לחץ לחיצה ימנית על המפה.',
        skillmatrix: 'מטריצת כישורים',
        indirectProcess: 'כישורים לא ליניאריים',
        skillLevelConfig: 'רמות כישורים',
        indirectSkillGroups: 'קבוצות כישורים לא ליניאריים',
        approved: 'מאושר',
        approvedBy: 'מאשר',
        skillLevel: 'רמת כישורים',
        requiredSkillLevel: 'רמת כישורים נדרשת',
        step: 'שלב',
        image: 'תמונה',
        skills: 'כישורים',
        visits: 'ביקורים',
        responsiblePerson: 'אדם אחראי',
        company: 'חברה',
        contactData: 'פרטי קשר',
        visit: 'ביקור',
        inProgress: 'בתהליך',
        gatehouse: 'שער כניסה',
        confirmEnter: 'אשר כניסה',
        confirmExit: 'אשר יציאה',
        enter: 'כניסה',
        exit: 'יציאה',
        entryGuard: 'האדם המכניס',
        exitGuard: 'האדם המוציא',
        loginAsThisUser: 'התחבר כמשתמש זה',
        minBrowserVersion: 'גרסת הדפדפן המינימלית הנדרשת',
        approve: 'אשר',
        cancelApproval: 'בטל אישור',
        workPermit: 'היתר עבודה',
        workPermits: 'היתרי עבודה',
        visit_summary: 'סיכום ביקור',
        createVisit: 'צור ביקור מבוסס על היתר',
        employeesAtThePlant: 'עובדים במפעל',
        purpose: 'מטרה',
        allowedAreas: 'אזורים מותרים',
        switchEmployees: 'החלף עובדים',
        localIssues: 'בעיות מקומיות',
        cmmsCategoriesSync: 'סנכרון קטגוריות CMMS',
        terminalsGroups: 'קבוצות מסופים',
        isTree: 'עץ',
        isReporter: 'הוא מדווח',
        isAnchored: 'הוא מעוגן',
        closable: 'סגירת בעיות',
        areYouSureAboutCloseIssue: 'האם אתה בטוח שברצונך לסגור את הבעיה?',
        reloadApp: 'טען מחדש את האפליקציה',
        simplifiedIssuesRefreshLabel: 'זמן רענון הרשימה - שניות',
        myOpenIssues: 'הבעיות הפתוחות שלי',
        myAssignedIssues: 'הבעיות שהוקצו לי',
        displayParametersOnMainScreen: 'הצג פרמטרים במסך הראשי',
        lockPin: 'נעל שינוי קוד סודי',
        pinLocked: 'קוד סודי נעול',
        sortChange: 'סדר הצגה',
        asc: 'עולה',
        desc: 'יורד',
        alphanumeric: 'אלפאנומרי',
        numeric: 'מספרי',
        modificationDate: 'תאריך שינוי',
        fromNewest: 'מהחדשים ביותר',
        fromOldest: 'מהישנים ביותר',
        negation: 'שלילה',
        sortOrderService: 'שירות',
        newIssueIgnoreFilters: 'התעלם מהמסננים שהוחלו בעת יצירת פנייה חדשה',
        refreshInterval: 'זמן רענון רשימה',
        multiline: 'רב-שורות',
        expanded: 'מורחב',
        quizzes: 'חידונים',
        courses: 'קורסים',
        course: 'קורס',
        quiz: 'חידון',
        maxPoints: 'מספר הנקודות המרבי',
        minPointsToPass: 'מספר הנקודות המינימלי לעבור',
        questionFailQuiz: 'שאלה שנכשלת פוסלת את כל החידון',
        points: 'נקודות',
        currentVersionPreview: 'תצוגה מקדימה של הגרסה הנוכחית',
        docs_sidebar_hidden_by_default: 'הסתר את סרגל הצד במסמכים כברירת מחדל',
        allCategories: 'כל הקטגוריות',
        test: 'מבחן',
        tests: 'מבחנים',
        copy: 'לְהַעְתִיק',
        trusted_device_code_login_users_limit: "כניסה מהירה - מספר המשתמשים המוצגים"
    },
    placeholders: {
        part: 'בחר חלק',
        nameSurname: 'שם ושם משפחה',
        phone: 'מספר טלפון',
        password: 'סיסמה',
        password_confirmation: 'אשר סיסמה',
        email: "דוא'ל",
    },
    auth: {
        notYourAccountQuestion: 'לא החשבון שלך?',
    },
    signup: {
        registered: 'הצלחה!',
        emailConfirmed: "הדוא'ל אושר.",
        registered_info: 'בקש מהמנהל שלך להפעיל את החשבון שלך',
        confirmEmailAddress:
            "החשבון שלך נוצר, לחץ על הקישור בדוא'ל כדי לאשר את כתובתך.",
        tokenNotFoundError: "הדוא'ל כבר אושר או שהקישור לאישור אינו תקף.",
    },
    paramTypes: {
        text: 'טקסט',
        note: 'הערה',
        integer: 'מספר שלם',
        decimal: 'מספר עשרוני',
        date: 'תאריך',
        time: 'שעה',
        datetime: 'תאריך ושעה',
        color: 'צבע',
        icon: 'אייקון',
        boolean: 'לא / כן',
        dictionary: 'מילון',
        user: 'משתמש',
        radioGroup: 'בחירה אחת',
        checkboxGroup: 'בחירות מרובות',
        signature: 'חתימה',
        number: 'מספר',
        rating: 'דירוג',
        document: 'מסמך',
        image: 'תמונה',
    },
    tableHeaders: {
        id: 'מזהה',
        name: 'שם',
        path: 'נתיב לקובץ',
        category: 'קטגוריה',
        equipment: 'ציוד',
        criticality: 'קריטיות',
        status: 'סטטוס',
        created: 'נוצר',
        createdBy: 'נוצר על ידי',
        fp: 'FP',
        type: 'סוג',
        designation: 'ייעוד',
        symbol: 'סמל',
        serialNumber: 'מספר סידורי',
        registryNumber: 'מספר רישום',
        udtNumber: 'מספר UDT',
        manufacturedYear: 'שנת ייצור',
        actions: 'פעולות',
        title: 'כותרת',
        data: 'תאריך',
        archived: 'מוארכב',
        manufacturerSymbol: 'סמל יצרן',
        min: 'מינימום',
        opt: 'אופטימום',
        quantity: 'כמות',
        priority: 'עדיפות',
        price: 'מחיר',
        kind: 'סוג',
        description: 'תיאור',
        default: 'ברירת מחדל',
        warning: 'אזהרה',
        problem: 'בעיה',
        fixing: 'פתרון',
        changeOrder: 'שנה סדר',
        required: 'נדרש',
        icon: 'סמל',
        color: 'צבע',
        subCategories: 'תת-קטגוריות',
        barCode: 'ברקוד',
        subLocations: 'תתי-מיקומים',
        categories: 'קטגוריות',
        image: 'תמונה',
        nameSurname: 'שם ושם משפחה',
        email: "דוא'ל",
        phone: 'מספר טלפון',
        function: 'תפקיד',
        department: 'מחלקה',
        accountStatus: 'סטטוס חשבון',
        shift: 'משמרת',
        licenseNumber: 'מספר רישיון',
        issuedDate: 'תאריך הנפקה',
        expiresDate: 'תאריך תפוגה',
        entries: 'רשומות',
        entryNumber: 'מספר רשומה',
        buyPrice: 'מחיר קנייה',
        currentLocationQuantity: 'כמות במיקום הנוכחי',
        group: 'קבוצה',
        string: 'טקסט',
        integer: 'מספר שלם',
        toolsGroup: 'קבוצת כלים',
        manufacturer: 'יצרן',
        shortName: 'שם קצר',
        locationName: 'שם מיקום',
        subLocationsQuantity: 'כמות תתי-מיקומים',
        isWorkingOnIssue: 'עובד על בעיה',
        openIssues: 'בעיות פתוחות',
        notificationDate: 'תאריך התראות',
        titleAndDescription: 'כותרת ותיאור',
        read: 'נקרא',
        created_at: 'נוצר ב',
        received_at: 'התקבל ב',
        started_at: 'התחיל ב',
        arrived_at: 'הגיע ב',
        finished_at: 'הסתיים ב',
        country: 'מדינה',
        city: 'עיר',
        progress: 'התקדמות',
        deleted_at: 'נמחק ב',
        about_issue: 'על הבעיה',
        action: 'פעולה',
    },
    searchConfig: {
        id: 'מזהה',
        name: 'שם',
        shortName: 'שם קצר',
        country: 'מדינה',
        email: "דוא'ל",
        group: 'קבוצה',
        planned: 'מתוכנן',
        category: 'קטגוריה',
        object: 'אובייקט',
        criticality: 'קריטיות',
        fp: 'FP',
        type: 'סוג',
        designation: 'ייעוד',
        symbol: 'סמל',
        serialNumber: 'מספר סידורי',
        registryNumber: 'מספר רישום',
        udtNumber: 'מספר UDT',
        manufacturedYear: 'שנת ייצור',
        manufacturerSymbol: 'סמל יצרן',
        priority: 'עדיפות',
        quantity: 'כמות',
        buyPrice: 'מחיר קנייה',
        kind: 'סוג',
        toolsGroup: 'קבוצת כלים',
        function: 'תפקיד',
        department: 'מחלקה',
        status: 'סטטוס',
        nameSurname: 'שם ושם משפחה',
        phone: 'טלפון',
        barCode: 'ברקוד',
        color: 'צבע',
        icon: 'סמל',
        machine: 'מכונה',
        tool: 'כלי',
        urgentAndOutdated: 'דחוף וישן',
        urgent: 'דחוף',
        expired: 'פג תוקף',
        manufacturer: 'יצרן',
    },
    errors: {
        uploadError: 'שגיאת העלאה',
        finishedSuccessfully: 'הושלם בהצלחה',
        partsAvailabilityError: 'שגיאה בזמינות חלקים',
        partMustHaveEntriesToCorrectQuantity:
            'על החלק להכיל רשומות כדי לתקן את הכמות.',
        error: 'אירעה שגיאה',
        wrongEmail: "דוא'ל לא חוקי",
        wrongPasswordLength: 'הסיסמה חייבת להיות באורך של לפחות 8 תווים.',
        wrongCodeLength:
            '@:common.PIN_CODE_LABEL חייב להיות באורך של לפחות 5 תווים.',
        passwordsNotMatch: 'הסיסמאות אינן תואמות',
        codesNotMatch: '@:common.PIN_CODE_LABEL אינו תואם',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL כבר בשימוש',
        expiresDateLowerThanIssued:
            'תאריך התפוגה לא יכול להיות נמוך מתאריך ההנפקה.',
        cannotMoveToTheSameLocation: 'לא ניתן להעביר לאותו מיקום.',
        locationRequired: 'מיקום נדרש.',
        putQuantity: 'הזן כמות להפרדה.',
        valueGreaterThanQuantity: 'הערך גדול מהכמות הכוללת.',
        wholeCannotBeDistinguished: 'השלם לא ניתן להבדלה.',
        yearMustBeGreaterThan: 'השנה חייבת להיות גדולה מ-',
        passwordConfirmationNotMatch: 'הסיסמאות אינן תואמות',
        mustBeInteger: 'הערך חייב להיות מספר שלם',
        valueTooLong: 'הערך ארוך מדי',
        wrongPhoneNumber: 'מספר טלפון שגוי',
        phoneNumberAlreadyTaken: 'מספר הטלפון כבר בשימוש',
        emailAlreadyTaken: "דוא'ל כבר בשימוש",
        notFound: 'לא נמצא',
        sessionExpired: 'הפעלה פגה',
        tenantNotFound:
            'התצורה שצוינה אינה קיימת. בדוק את כתובת האתר ונסה שוב.',
        selectEquipmentTypeToConfigureParameters:
            'בחר סוג ציוד להגדרת פרמטרים.',
        noParametersToConfigure: 'לסוג הציוד הנבחר אין פרמטרים להגדרה.',
        terminalAlreadyRegistered: 'המסוף כבר נרשם.',
        invalidPin: '@:common.PIN_CODE_LABEL אינו חוקי',
        eventCollision:
            'הזמן של האירוע שאתה רוצה להוסיף חופף עם הזמן של אירוע אחר.',
        mustStartWithSlash: 'חייב להתחיל עם /',
        mustBeBetween: 'הערך חייב להיות בין:',
        minLength: 'אורך מינימלי: ',
        maxLength: 'אורך מקסימלי: ',
        invalidInputForCodeType: 'קלט לא חוקי לסוג הקוד הנבחר',
        invalidCodeOrNoPermission:
            '@:common.PIN_CODE_LABEL אינו חוקי או שאין לך הרשאות מתאימות לביצוע פעולה זו.',
        endDateLowerThanStart: 'תאריך הסיום לא יכול להיות נמוך מתאריך ההתחלה',
        iconAlreadyTaken: 'הסמל כבר בשימוש',
        notEnoughQuantityInStorage: 'כמות לא מספקת במלאי.',
        valueTooLow: 'הערך נמוך מדי',
        valueTooHigh: 'הערך גבוה מדי',
        maxAvailableQuantity: 'כמות מרבית',
        someFilesRejectedDueToSize:
            'חלק מהקבצים נדחו עקב גודלם. גודל מקסימלי לקובץ יחיד:',
        cameraDeviceNotFound: 'לא נמצא מכשיר מצלמה',
        cameraPermissionDenied:
            'הגישה למצלמה נחסמה בהגדרות האתר. אפס את הגדרות האתר ואפשר שימוש במצלמה.',
        passwordResetLinkExpired: 'קישור לאיפוס הסיסמה פג תוקף',
        noInternetConnection: 'אין חיבור לאינטרנט',
        minimum: 'מינימום',
        mustBeGreaterThan: 'חייב להיות גדול מ',
        noEventTypesDefined: 'לא הוגדרו סוגי אירועים',
        regexPatternDelimiterError:
            'מפריד לא חוקי חייב להיות מוגן על ידי התו \\',
        emailNotMatchPatternDefiniedInTenant:
            "הדוא'ל לא תואם את התבנית המוגדרת אצל השוכר",
        max: 'ערך מקסימלי',
        min: 'ערך מינימלי',
        allLanguagesRequired: 'מלא את הערך בכל השפות',
        fileTooBigToOpenWillBeDownloaded:
            'הקובץ גדול מדי לפתיחה, הוא יירד למכשיר שלך.',
        surveyNotFound: 'הסקר לא נמצא',
        actionNotEnabled: 'הקוד אינו פעיל',
        loginRequired: 'נדרש התחברות',
        noPermissionForSelectedFolder: 'אין לך הרשאה לצפות בתוכן התיקייה הזו',
        noFoldersAssignedForTerminal: 'אין תיקיות מוקצות עם הוראות',
        noChecklistsAssignedToControl: 'לבקרה שנבחרה אין רשימת בדיקה מוקצית',
        maximum: 'מקסימום',
        versionErrorMessage:
            'השינויים האחרונים שלך לא נשמרו כי מישהו אחר ביצע שינויים באותו זמן. רענן את הדף ונסה שוב.',
        duplicateError: 'ערך זה כבר קיים',
        fillAllRequiredParameters: 'מלא את כל הפרמטרים הנדרשים.',
        wrongValue: 'ערך שגוי',
        noParametersDefined: 'לא הוגדרו פרמטרים',
        noFlagsConfigured: 'לא הוגדרו דגלים',
        noEnoughParts: 'אין מספיק חלקים',
        pinNotBelongsTo: '@:common.PIN_CODE_LABEL זה לא שייך ל',
        tagNotBelongsTo: 'כרטיס/תג זה אינו שייך ל',
        noDictionaryEntries: 'אין ערכים במילון שנבחר',
        noConnectionWithServer: 'אין חיבור לשרת',
    },
    navDrawer: {
        dashboard: 'לוח מחוונים',
        issues: 'בעיות',
        timetable: 'לוח זמנים',
        machines: 'מכונות',
        tools: 'כלים',
        parts: 'חלקים',
        storage: 'מחסן',
        staff: 'צוות',
        configuration: 'הגדרות',
        notes: 'הערות',
        notifications: 'התראות',
        profile: 'פרופיל',
        supervision: 'פיקוח',
    },
    barcodeScanner: {
        barCode: 'ברקוד',
        id: 'מזהה',
    },
    categoryPicker: {
        categoryPlaceholder: 'קטגוריה',
        selectCategory: 'בחר קטגוריה',
        noSubcategories: 'אין תתי-קטגוריות',
    },
    companies: {
        addCompany: 'הוסף חברה',
        editCompany: 'ערוך חברה',
        fullName: 'שם מלא',
        shortName: 'שם קצר',
        group: 'קבוצה',
        country: 'מדינה',
        city: 'עיר',
        street: 'רחוב',
        postCode: 'מיקוד',
        phone: 'טלפון',
        email: "דוא'ל",
        website: 'אתר אינטרנט',
        NIP: 'מספר NIP',
        REGON: 'מרשם העסקים הלאומי',
        active: 'פעיל',
        nameRequired: 'שם נדרש',
        shortNameRequired: 'שם נדרש',
    },
    issues: {
        tookAt: 'נלקח ב',
        changeHistory: 'היסטוריית שינויים',
        load: 'טען',
        date: 'תאריך',
        createdBy: 'נוצר על ידי',
        action: 'פעולה',
        data: 'נתונים',
        created: 'נוצר',
        updated: 'עודכן',
        deleted: 'הוסר',
        restored: 'שוחזר',
        issues: 'בעיות',
        issue: 'בעיה',
        by: 'על ידי',
        name: 'שם',
        applicantsDescription: 'תיאור המבקש',
        accidentDescription: 'תיאור התאונה',
        jobDescription: 'תיאור העבודה',
        jobSummary: 'סיכום העבודה',
        category: 'קטגוריה',
        status: 'סטטוס',
        noFlow: '[ללא זרימה]',
        equipment: 'ציוד',
        criticality: 'קריטיות',
        hour: 'שעה',
        formalConfirmation: 'אישור פורמלי',
        formalConfirmationShortcut: "א'פ",
        formalConfirmationRequired: 'נדרש אישור פורמלי',
        parameter: 'פרמטר',
        value: 'ערך',
        addIssue: 'הוסף בעיה',
        expectedCompletionDate: 'תאריך סיום צפוי',
        issueDescription: 'תיאור הבעיה',
        partsUsage: 'שימוש בחלקים',
        assignedEmployees: 'עובדים מוקצים',
        requiredHelp: 'עזרה נדרשת',
        returnParts: 'החזר חלק',
        confirmUnassignEmployeeWhoTakenIssue:
            'עובד זה לקח על עצמו את הבעיה שנבחרה. האם אתה רוצה להסיר אותו?',
        responsibleUser: 'משתמש אחראי',
        responsibleRules: 'משתמשים אחראיים - כללים',
        received_decision_at: 'תאריך קבלת ההחלטה על ידי המודיע',
        groupRules: 'קבוצות - כללים',
        watchersRules: 'צופים - כללים',
    },
    machines: {
        machines: 'מכונות',
        xIssuesNotPlanned: ' (לא מתוכנן)',
        noIssues: 'אין בעיות',
    },
    maintenance: {
        maintenance: 'תחזוקה',
    },
    notes: {
        notes: 'הערות',
        note: 'הערה',
        title: 'כותרת',
        archived: 'מוארכב',
    },
    parts: {
        parts: 'חלקים',
        warehouseOutgoings: 'יציאות מחסן',
        fittingEquipmentTypes: 'סוגי ציוד מתאימים',
        goToEquipmentType: 'עבור לסוג ציוד',
        selectPart: 'בחר חלק',
        usageReason: 'סיבה',
        usageOtherReason: 'אחר',
        replacements: 'תחליפים',
        partEntry: 'רישום חלק',
        goToPart: 'עבור לחלק',
    },
    costCenter: {
        costCenter: 'מרכז עלות',
    },
    equipmentTypes: {
        equipment: 'ציוד',
        newEquipmentType: 'סוג ציוד חדש',
        fittingParts: 'חלקים מתאימים',
        goToParts: 'עבור לחלקים',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'הגדרות מכונות וכלים',
    },
    flows: {
        flow: 'זרימה',
        states: 'מצבים',
        transitions: 'מעברים',
        state: 'מצב',
        default: 'ברירת מחדל',
        warning: 'אזהרה',
        problem: 'בעיה',
        fixing: 'תיקון',
        finished: 'הסתיים',
        access: {
            create: 'יצירה',
            read: 'קריאה',
            update: 'שינוי',
            delete: 'הסרה',
            read_unassigned: 'קריאה לא מוקצה',
            assign: 'הקצאה',
            unassign: 'הסר הקצאה',
            close: 'סגור',
        },
        instructions: {
            default: 'ברירת מחדל',
            fixing: 'שיקול',
            finished: 'הסתיים',
            creation: 'יצירה',
            verification: 'אימות',
            acceptation: 'קבלה',
            publication: 'פרסום',
        },
        accessLabels: {
            terminal: 'מסוף שדיווח על הבעיה',
            creator: 'משתמש שדיווח על הבעיה',
            responsible: 'משתמש אחראי על הבעיה',
            group: 'קבוצת משתמשים אחראית על הבעיה',
            anchor: 'מסוף שאליו מחוברת הבעיה',
            watchers: 'משתמשים שצופים בבעיה',
        },
        sync_returned_rejected: 'סינכרון - נדחה',
        sync_returned_finished: 'סינכרון - הושלם',
        current: 'נוֹכְחִי',
    },
    parameters: {
        categoryParameter: 'פרמטר קטגוריה',
        equipmentTypeParameter: 'פרמטר סוג ציוד',
    },
    staff: {
        staff: 'צוות',
        editUserAccess: 'ערוך גישת משתמש',
        permissionsUpdated: 'הרשאות עודכנו',
        passwordUpdated: 'הסיסמה עודכנה',
        codeUpdated: '@:common.PIN_CODE_LABEL עודכן',
        changePasswordAndCode: 'שנה סיסמה ו-@:common.PIN_CODE_LABEL',
        goToLicenses: 'עבור לרישיונות',
        editAccess: 'ערוך גישה',
        editUser: 'ערוך משתמש',
        addUser: 'הוסף משתמש חדש',
        nameAndLastname: 'שם ושם משפחה',
        accountStatus: 'סטטוס חשבון',
        phoneNumber: 'מספר טלפון',
        department: 'מחלקה',
        passwordChange: 'שינוי סיסמה',
        codeChange: 'שינוי @:common.PIN_CODE_LABEL',
        newPassword: 'סיסמה חדשה',
        confirmNewPassword: 'אשר סיסמה חדשה',
        changePassword: 'שנה סיסמה',
        newCode: '@:common.PIN_CODE_LABEL חדש',
        confirmNewCode: 'אשר @:common.PIN_CODE_LABEL חדש',
        changeCode: 'שנה @:common.PIN_CODE_LABEL',
        addEntry: 'הוסף רשומה',
        editEntry: 'ערוך רשומה',
        editLicense: 'ערוך רישיון',
        addLicense: 'הוסף רישיון',
        licenseNumber: 'מספר רישיון',
        prefferedLocale: 'שפה מועדפת',
        oldPassword: 'סיסמה ישנה',
        newPasswordConfirmation: 'אשר סיסמה חדשה',
        reading: 'הצגה',
        creating: 'יצירה',
        updating: 'עדכון',
        deleting: 'מחיקה',
        importScheduleFile: 'ייבא לוח זמנים מקובץ',
        importSchedule: {
            errors: {
                user_not_found: 'משתמש לא נמצא [{user}]',
                user_found_many: 'נמצאו {count} משתמשים [{user}]',
                event_collision: 'התנגשות של אירועים',
                hour_cell_parse: 'שגיאת ניתוח תא',
            },
        },
        oldCode: '@:common.PIN_CODE_LABEL ישן',
        newCodeConfirmation: 'אישור @:common.PIN_CODE_LABEL חדש',
        setAccessBasedOnProfile: 'הענק גישה בהתבסס על תבנית',
        selectAccessMergeType: 'בחר כיצד לשלב גישות',
        noAccessesInProfileMessage:
            'לפרופיל שנבחר אין הרשאות. האם ברצונך להמשיך בכל זאת?',
    },
    breadCrumb: {
        configuration: 'הגדרות',
        locations: 'מיקומים',
        home: 'דף הבית',
        machines: 'מכונות',
        tools: 'כלים',
        issues: 'בעיות',
        notes: 'הערות',
        maintenance: 'תחזוקה',
        parts: 'חלקים',
        warehouse: 'מחסן',
        dictionary: 'מילון',
        categories: 'קטגוריות',
        parameters: 'פרמטרים',
        costCenter: 'מרכז עלות',
        flows: 'זרימות',
        states: 'מצבים',
        transitions: 'מעברים',
        shiftSchemas: 'סכמות משמרות',
        shifts: 'משמרות',
        definitionsOfMachinesAndToolsTypes: 'הגדרות סוגי מכונות וכלים',
        add: 'הוסף',
        edit: 'ערוך',
        staff: 'צוות',
        licenses: 'רישיונות',
        entries: 'רשומות',
        companiesDatabase: 'מאגר חברות',
        companyManagement: 'ניהול חברה',
        companyData: 'נתוני חברה',
        supportedLanguages: 'שפות נתמכות',
        en: 'אנגלית',
        pl: 'פולנית',
        es: 'ספרדית',
        de: 'גרמנית',
        defaultLanguage: 'שפת ברירת מחדל',
        notifications: 'התראות',
        notificationsManagement: 'ניהול התראות',
        myProfile: 'הפרופיל שלי',
    },
    attributeTypes: {
        text: 'טקסט',
        note: 'הערה',
        integer: 'מספר שלם',
        decimal: 'עשרוני',
        date: 'תאריך',
        time: 'שעה',
        dateTime: 'תאריך ושעה',
        color: 'צבע',
        icon: 'סמל',
        boolean: 'כן/לא',
    },
    costCalculator: {
        costCalculator: 'מחשבון עלויות',
        waitTime: 'זמן המתנה',
        serviceTime: 'זמן שירות',
        suspendTime: 'זמן השהיה',
        totalTime: 'זמן כולל',
        estimatedCost: 'עלות משוערת',
        calculate: 'חשב',
        withoutParts: 'ללא חלקים',
        timeOfReportingIssue: 'זמן דיווח הבעיה',
        timeOfServiceStart: 'זמן תחילת השירות',
        timeOfReportingEnd: 'זמן סיום השירות',
        days: 'ימים',
        hours: 'שעות',
        minutes: 'דקות',
        enterNewTime: 'הזן זמן חדש',
        fromIssueReportToServiceStart: 'זמן מדיווח הבעיה עד תחילת השירות',
        fromServiceStartToServiceEnd: 'זמן מתחילת השירות עד סיום השירות',
        workTime: 'זמן עבודה',
        includeAwaitingTime: 'כלול זמן המתנה',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'הבעיות שלי',
                assignedToMe: 'מוקצה לי',
                assignedToGroup: 'מוקצה לקבוצה שלי',
                tookByMe: 'נלקח על ידי',
                takeIssueBtn: 'קח',
                arriveIssueBtn: 'הגעתי',
                finishIssueBtn: 'סיים',
                history: 'היסטוריה',
                myResponsible: 'אני אחראי על',
                unassignIssue: 'הסר הקצאה',
            },
        },
    },
    synchronization: {
        enabled: 'מופעל',
        hasHeaders: 'יש כותרות',
        leaveFile: 'השאר קובץ',
        host: 'מארח',
        port: 'פורט',
        protocol: 'פרוטוקול',
        username: 'שם משתמש',
        password: 'סיסמה',
        root: 'שורש',
        encoding: 'קידוד',
        separator: 'מפריד',
        externalSystemId: 'מזהה מערכת חיצונית',
        cronExpression: 'ביטוי Cron',
        appendFullStock: 'הוסף מלאי מלא',
        includeHeaders: 'הכלל כותרות',
        withTrashed: 'עם נתונים שהוסרו',
        filePattern: 'תבנית קובץ',
        categoriesRootCategoryId: 'קטגוריית שורש לקטגוריות מיובאות',
        systemKeyname: 'מזהה מערכת',
        address: 'כתובת',
        token: 'אסימון',
    },
    processes: {
        created_at: 'נוצר ב',
        started_at: 'התחיל ב',
        updated_at: 'עודכן ב',
        finished_at: 'הסתיים ב',
        attempts: 'ניסיונות',
        queue: 'תור',
        job_id: 'מזהה עבודה',
        job_uuid: 'UUID עבודה',
        progress_max: 'התקדמות מקסימלית',
        progress_now: 'התקדמות נוכחית',
        outputMessage: 'הודעת פלט',
        status: {
            queued: 'בתור',
            executing: 'מבצע',
            finished: 'הסתיים',
            failed: 'נכשל',
            retrying: 'מנסה שוב',
            undefined: 'לא מוגדר',
        },
    },
    weekDays: {
        mo: 'שני',
        tu: 'שלישי',
        we: 'רביעי',
        th: 'חמישי',
        fr: 'שישי',
        sa: 'שבת',
        su: 'ראשון',
    },
    reports: {
        reports: 'דוחות',
        dateFrom: 'תאריך התחלה',
        dateTo: 'תאריך סיום',
        types: {
            operation_sheet: 'דף תפעול',
            issue_summary: 'סיכום בעיות',
            grouped_summary: 'דוח מסכם',
            storage_operations: 'פעולות מחסן',
            part_quantity_export: 'כמות חלקים',
        },
        reportFor: 'דוח עבור',
        includeQuantityLeft0: 'הכלל חלקים עם כמות אפס',
        dayMinusN: 'יום פחות n',
        currentWeek: 'השבוע הנוכחי',
        lastWeek: 'השבוע שעבר',
        currentMonth: 'החודש הנוכחי',
        lastMonth: 'החודש שעבר',
        currentYear: 'השנה הנוכחית',
        lastYear: 'השנה שעברה',
        nDays: 'מספר הימים',
        todayMinusN: 'היום מינוס n',
    },
    logs: {
        comment: 'הערה',
        issues: {
            escalation: 'הסלמות ({minutes} דק) - נשלחה התראה',
        },
        issueEmployee: {
            assignedAt: 'עובד הוקצה:',
            receivedAt: 'עובד קרא ב:',
            tookAt: 'עובד לקח ב',
            finishedAt: 'עובד סיים ב',
            deletedAt: 'הוסר מהבעיה ב',
        },
    },
    units: {
        no: 'יחידה',
        st: 'סט',
        pr: 'זוג',
    },
    rrule: {
        frequency: 'מחזוריות',
        dtstart: 'תאריך התחלה',
        tzid: 'אזור זמן',
        until: 'עד',
        count: 'ספירה',
        interval: 'מרווח',
        wkst: 'יום התחלה',
        byweekday: 'לפי יום בשבוע',
        bymonth: 'לפי חודש',
        yearly: 'שנתי',
        monthly: 'חודשי',
        weekly: 'שבועי',
        daily: 'יומי',
        hourly: 'שעתי',
        minutely: 'כל דקה',
        secondly: 'כל שנייה',
        infinitePeriodicityMessage:
            'המחזוריות היא אינסופית, לצורך ביצועים מוצגים רק 500 שורות',
    },
    months: {
        jan: 'ינואר',
        feb: 'פברואר',
        mar: 'מרץ',
        apr: 'אפריל',
        may: 'מאי',
        jun: 'יוני',
        jul: 'יולי',
        aug: 'אוגוסט',
        sep: 'ספטמבר',
        oct: 'אוקטובר',
        nov: 'נובמבר',
        dec: 'דצמבר',
    },
    charts: {
        charts: 'תרשימים',
        count: 'מספר בעיות',
        total: 'זמן כולל של בעיות (מדיווח לסיום)',
        serw: 'זמן כולל של תיקון (Ttotal - Twait)',
        mttr1: 'זמן ממוצע מדיווח לסיום',
        mttr2: 'זמן ממוצע מקבלה לסיום',
        mtbf: 'זמן ממוצע בין תקלות',
        mttf: 'זמן ממוצע לכשל',
        type: 'סוג תרשים',
        pie: 'תרשים עוגה',
        bar: 'תרשים עמודות',
        query: 'היקף הנתונים',
        groupBy: 'קבץ לפי',
        sum: 'סכום',
        avg: 'ממוצע',
        min: 'מינימום',
        max: 'מקסימום',
        eq: 'שווה ל',
        neq: 'לא שווה ל',
        gt: 'יותר מ',
        lt: 'פחות מ',
        gte: 'יותר או שווה ל',
        lte: 'פחות או שווה ל',
        in: 'מכיל',
        nin: 'לא מכיל',
        btw: 'בין',
        chartOptions: 'אפשרויות תרשים',
        line: 'תרשים קו',
        mixed: 'מעורב',
        chartDisplayOptions: 'אפשרויות תצוגת תרשים',
        fillGaps: 'מלא את הפערים',
    },
    regexBuilder: {
        regexValue: 'ערך רגקס',
        global: 'גלובלי',
        multiline: '^ ו-$ מתאימים להתחלה/סיום של שורה',
        insensitive: 'לא תלוי רישיות',
        extended: 'התעלם מרווחים',
        singleline: 'הנקודה מתאימה לשורה חדשה',
        unicode: 'יוניקוד',
        Ungreedy: 'כמתנים עצלים',
        Anchored: 'מעוגן בתחילת התבנית או בסוף ההתאמה האחרונה',
        Jchanged: 'אפשר שמות תת-תבניות כפולים',
        DollarEndOnly: '$ מתאים רק לסוף התבנית',
        testValue: 'בדוק ערך',
    },
    tpm: {
        groupableHeadersPlaceholder: 'גרור כותרת טבלה כדי לקבץ נתונים',
        steps: 'שלבים',
        step: 'שלב',
        content: 'תוכן',
        history: 'היסטוריה',
        standardObstacle: 'מכשול סטנדרטי',
        customObstacle: 'מכשול מותאם',
        bhp: 'בריאות ובטיחות',
        returnToMainPage: 'חזור לדף הראשי',
        bhpMessage:
            'אם אתה רואה סכנות בטיחותיות, דווח על כך מיידית לממונה הישיר שלך והשתמש בטופס הדיווח הנמצא מתחת ללוח ההודעות.',
        ok: 'אישור',
        send: 'שלח',
        description: 'תיאור',
        title: 'כותרת',
        realization: 'ביצוע',
        answer: 'תשובה',
        pendingSurveys: 'סקרים ממתינים',
        surveys: 'סקרים',
        systemSurvey: 'סקר מערכת',
        addPage: 'הוסף עמוד',
        createSurvey: 'צור סקר',
        addSection: 'הוסף סעיף',
        addQuestion: 'הוסף שאלה',
        questionContent: 'תוכן השאלה',
        answerOption: 'אפשרות תשובה',
        addQuestionAnswer: 'הוסף אפשרות תשובה',
        surveyName: 'שם הסקר',
        sectionName: 'שם הסעיף',
        noSurveyAnswers: 'אין תשובות',
        removeSection: 'הסר סעיף',
        published: 'פורסם',
        unpublished: 'לא פורסם',
        pendingPublication: 'שינויים ממתינים לפרסום',
        removeQuestion: 'הסר שאלה',
        removeAnswer: 'הסר אפשרות תשובה',
        publish: 'פרסם',
        unpublish: 'הסר פרסום',
        previewResults: 'תצוגה מקדימה של תוצאות',
        supervisior: 'מפקח',
        surveyResults: 'תוצאות הסקר',
        surveysEvents: 'תצורת אירועים - סקרים',
        operatedBy: 'מופעל על ידי',
        confirmReadInformations: 'קראתי את המידע',
        noticeContent: 'תוכן הודעה',
        notice: 'הודעה',
        surveyEventName: 'שם האירוע',
        survey: 'סקר',
        newIssue: 'דיווח חדש',
        surveyEventConfigurationAlreadyExists: 'תצורה כזו כבר קיימת',
        lastComment: 'הערה אחרונה של האדם האחראי',
        surveyNotConfigured: 'הסקר לא הוגדר',
        fillSurvey: 'מלא את הסקר',
        singleSurveyEntries: 'תוצאות בודדות',
        groupedSurveyEntries: 'תוצאות מקובצות',
        publicationId: 'מזהה פרסום',
        publicationDate: 'תאריך פרסום',
        versionId: 'מזהה גרסה',
        answersCount: 'מספר תשובות',
        answers: 'תשובות',
        withoutSupervisor: 'ללא מפקח',
        defaultSupervisor: 'מפקח ברירת מחדל',
        flowStateFlags: 'דגלי סטטוס',
        qrActionsSettings: 'הגדרות פעולות קוד QR',
        loginRequired: 'נדרשת כניסה',
        questionContext: 'הקשר השאלה',
        currentSurveyVersionPreview: 'תצוגה מקדימה של הגרסה הנוכחית של הסקר',
        surveyDescription: 'תיאור הסקר',
        sectionDescription: 'תיאור הסעיף',
        instructions: 'הוראות',
        instruction: 'הוראה',
        instructionRevisions: 'תיקונים',
        instructionRevision: 'תיקון',
        checklists: 'רשימות ביקורת',
        checklist: 'רשימת ביקורת',
        checklistItem: 'פריט רשימה',
        manageChecklistFields: 'נהל פריטי רשימה',
        checklistItemDisplayOptions: 'פריטים מוצגים',
        revisionAutoRejectedInfo: '[מערכת] נדחה - נוצר תיקון חדש',
    },
    accessMergeValues: {
        sum: 'סכום',
        product: 'מכפלה',
        overwrite: 'דריסה',
    },
    aggregates: {
        count: 'סכום',
        min: 'מינימום',
        max: 'מקסימום',
    },
    checklists: {
        itemBasic: 'פריט בסיסי',
        itemSeparator: 'מפריד',
        itemSection: 'סעיף',
        publish: 'פרסם',
        previewCurrentVersion: 'תצוגה מקדימה של הגרסה הנוכחית',
    },
    cluer: {
        groupableHeadersPlaceholder: 'גרור כותרת טבלה כדי לקבץ נתונים',
        history: 'היסטוריה',
        standardObstacle: 'מכשול סטנדרטי',
        customObstacle: 'מכשול מותאם אישית',
        bhp: 'בריאות ובטיחות',
        returnToMainPage: 'חזור לדף הראשי',
        bhpMessage:
            'אם אתה רואה סכנות בטיחותיות, דווח עליהן מיידית לממונה הישיר שלך והשתמש בטופס הדיווח הנמצא מתחת ללוח המודעות.',
        ok: 'אישור',
        send: 'שלח',
        description: 'תיאור',
        title: 'כותרת',
        realization: 'ביצוע',
        answer: 'תשובה',
        pendingSurveys: 'סקרים ממתינים',
        surveys: 'סקרים',
        systemSurvey: 'סקר מערכת',
        addPage: 'הוסף עמוד',
        createSurvey: 'צור סקר',
        addSection: 'הוסף סעיף',
        addQuestion: 'הוסף שאלה',
        questionContent: 'תוכן השאלה',
        answerOption: 'אפשרות תשובה',
        addQuestionAnswer: 'הוסף אפשרות תשובה',
        surveyName: 'שם הסקר',
        sectionName: 'שם הסעיף',
        noSurveyAnswers: 'אין תשובות',
        removeSection: 'הסר סעיף',
        published: 'פורסם',
        unpublished: 'לא פורסם',
        pendingPublication: 'שינויים ממתינים לפרסום',
        removeQuestion: 'הסר שאלה',
        removeAnswer: 'הסר אפשרות תשובה',
        publish: 'פרסם',
        unpublish: 'בטל פרסום',
        previewResults: 'תצוגה מקדימה של תוצאות',
        supervisior: 'מפקח',
        surveyResults: 'תוצאות הסקר',
        surveysEvents: 'תצורת אירועים - סקרים',
        operatedBy: 'מופעל על ידי',
        confirmReadInformations: 'קראתי את המידע',
        noticeContent: 'תוכן הודעה',
        notice: 'הודעה',
        surveyEventName: 'שם האירוע',
        survey: 'סקר',
        newIssue: 'דיווח חדש',
        surveyEventConfigurationAlreadyExists: 'תצורה כזו כבר קיימת',
        lastComment: 'הערה אחרונה מאת האדם האחראי',
        surveyNotConfigured: 'הסקר לא הוגדר',
        fillSurvey: 'מלא את הסקר',
        singleSurveyEntries: 'תוצאות בודדות',
        groupedSurveyEntries: 'תוצאות מקובצות',
        publicationId: 'מזהה פרסום',
        publicationDate: 'תאריך פרסום',
        versionId: 'מזהה גרסה',
        answersCount: 'מספר תשובות',
        answers: 'תשובות',
        withoutSupervisor: 'ללא מפקח',
        defaultSupervisor: 'מפקח ברירת מחדל',
        flowStateFlags: 'דגלי סטטוס',
        qrActionsSettings: 'הגדרות פעולות קוד QR',
        loginRequired: 'נדרשת כניסה',
        questionContext: 'הקשר השאלה',
        currentSurveyVersionPreview: 'תצוגה מקדימה של הגרסה הנוכחית של הסקר',
        surveyDescription: 'תיאור הסקר',
        sectionDescription: 'תיאור הסעיף',
    },
    orders: {
        order: 'הזמנה',
        releaseDate: 'תאריך סיום',
        orderDate: 'תאריך הזמנה',
    },
    production: {
        good: 'מספר הפריטים הטובים',
        bad: 'מספר הפריטים הפגומים',
        productionRecords: 'רישום ייצור',
    },
};
