import * as VueI18n from 'vue-i18n';
import { pl } from './assets/i18n/pl';
import { en } from './assets/i18n/en';
import { es } from './assets/i18n/es';
import { de } from './assets/i18n/de';
import { fr } from './assets/i18n/fr';
import { it } from './assets/i18n/it';
import { pt } from './assets/i18n/pt';
import { ru } from './assets/i18n/ru';
import { uk } from './assets/i18n/uk';
import { nl } from './assets/i18n/nl';
import { sr } from './assets/i18n/sr';
import { ar } from './assets/i18n/ar';
import { he } from './assets/i18n/he';

const messages = {
    pl,
    en,
    es,
    de,
    fr,
    it,
    pt,
    ru,
    uk,
    nl,
    sr,
    ar,
    he,
};

const i18n = VueI18n.createI18n({
    locale: localStorage.getItem('locale') || 'pl',
    fallbacklocale: Object.keys(messages),
    messages,
    legacy: false,
    availableLocales: Object.keys(messages),
});

export default i18n;
