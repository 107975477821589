<style scoped>
th,
td {
    padding: 10px;
}
td {
    border-top: 1px solid gray;
}
</style>

<template>
    <v-card class="x-padding-0">
        <template v-slot:title>
            <v-toolbar class="preview-header">
                <v-icon class="preview-header-icon"
                    >mdi-clipboard-text-clock</v-icon
                >
                {{ $t('issues.changeHistory') }}
                <SignaloDynamicMenu
                    :menuItems="issueLogActions"
                    style="margin-left: auto"
                />
            </v-toolbar>
        </template>
        <EasyDataTable
            buttons-pagination
            :items="items"
            :headers="tableHeaders"
            :loading="loading"
            :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style"
            :rows-items="[5, 10, 25, 50, 75, 100]"
            :rows-per-page="5"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            v-model:server-options="serverOptions"
            :server-items-length="serverItemsLength"
        >
            <template #item-date="item"> {{ item.logged_at }}</template>
            <template #item-createdBy="item">
                {{
                    item.logged_by === 0 ? '[System]' : item.logged_by_user_name
                }}</template
            >
            <template #item-object="item">
                {{ $t(getTableName(item.loggable_type)) }}</template
            >
            <template #item-action="item">
                {{ actionLabel(item.action) }}</template
            >
            <template #item-data="item">
                <p v-for="(value, row) in item.content" :key="row">
                    <span v-if="row === 'attributes'">
                        <span
                            v-if="
                                value &&
                                typeof value === 'object' &&
                                'escalations' in value
                            "
                        >
                            {{
                                $t('logs.issues.escalation', {
                                    minutes: Math.max(
                                        value.escalationLast.total_minutes
                                    ),
                                })
                            }}
                        </span>
                    </span>
                    <span v-else-if="getTableFieldKey(item.loggable_type, row)">
                        {{ $t(getTableFieldKey(item.loggable_type, row)) }}:
                        <span v-if="!value">
                            <span v-if="item?.action === 'created'"> - </span>
                            <span
                                v-else-if="item?.action === 'updated'"
                                class="value-removed-span"
                            >
                                <!-- czerwono albo szaro [Wartość usunięta]-->
                                {{ $t('common.valueDeleted') }}
                            </span>
                        </span>
                        <span v-else-if="row === 'part'">
                            {{ value?.name || '-' }}</span
                        >
                        <!-- <span v-else-if="row === 'finishedByUser'">{{ value.name }}</span> -->
                        <span v-else-if="value?.pl || value?.en">
                            {{ $getPropertyTranslation(value) }}</span
                        >
                        <span v-else-if="row === 'unit'">
                            <span v-html="$displayDimension(value)"></span>
                        </span>
                        <span
                            v-else-if="
                                value?.date ||
                                (typeof value === 'string' &&
                                    new Date(value) != 'Invalid Date')
                            "
                        >
                            {{
                                new Date(value?.date || value).toLocaleString(
                                    $i18n.locale,
                                    {
                                        timeZone: authStore?.user.timezone,
                                    }
                                )
                            }}
                        </span>
                        <span v-else>{{ value }}</span>
                    </span>
                </p>
            </template>
        </EasyDataTable>
    </v-card>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';

export default {
    setup() {
        return {
            dataStore: DATA_STORE.default(),
            helpersStore: HELPERS_STORE.default(),
            authStore: AUTH_STORE.default(),
        };
    },
    props: ['modelValue', 'loaded'],
    emits: ['update:loaded'],
    data() {
        return {
            loading: false,
            items: [],
            TABLE_NAME: 'issueLogTable',
            serverOptions: {
                ...this.$getServerOptions('issueLogTable'),
            },
            serverItemsLength: 0,
        };
    },
    mounted() {
        this.load();
    },
    computed: {
        issueLogActions() {
            return [
                {
                    icon: 'mdi-refresh',
                    label: this.$t('issues.download'),
                    action: () => this.load(),
                },
            ];
        },
        tableHeaders() {
            return [
                {
                    text: this.$t('issues.date'),
                    value: 'date',
                },
                {
                    text: this.$t('issues.createdBy'),
                    value: 'createdBy',
                },
                {
                    text: this.$t('common.object'),
                    value: 'object',
                },
                {
                    text: this.$t('issues.action'),
                    value: 'action',
                },
                {
                    text: this.$t('common.data'),
                    value: 'data',
                },
            ];
        },
    },
    methods: {
        getTableName(table) {
            const tab = {
                Issue: 'issues.issue',
                IssueEmployee: 'issues.assignedEmployees',
                Document: 'common.documents',
                PartUsage: 'common.partUsage',
            };
            return tab[table] ?? table;
        },
        getTableFieldKey(table, field) {
            const tab = {
                Issue: {
                    //TODO wywalić id
                    id: 'tableHeaders.id',
                    // tenant_id: false,
                    // equipment_id: 'issues.equipment',
                    prod_line: 'common.productionLine',
                    department: 'tableHeaders.department',
                    title: 'issues.name',
                    // category_id: 'tableHeaders.category',
                    category: 'tableHeaders.category',
                    equipment: 'tableHeaders.equipment',
                    // flow_state_id: 'flows.state',
                    flowState: 'flows.state',
                    flow: 'flows.flow',
                    criticality: 'tableHeaders.criticality',
                    // resolution: 'issues.',
                    issue_description: 'common.description',
                    user_description: 'issues.applicantsDescription',
                    formal_confirm_at: 'common.formalConfirmAt',
                    predicted_finish: 'common.predictedFinishTime',
                    scheduled_on: 'common.scheduledOn',
                    created_at: 'issues.created',
                    // created_by: 'issues.createdBy',
                    took_at: 'issues.tookAt',
                    finished_at: 'tableHeaders.finished_at',
                    // finished_by: 'common.finishedByUser',
                    external: false,
                    // responsible_user_id: 'issues.responsibleUser',
                    rejection_reason: 'common.rejectionReason',
                    prodLineName: 'common.productionLine',
                    departmentName: 'tableHeaders.department',
                    criticalityName: 'tableHeaders.criticality',
                    responsibleUser: 'issues.responsibleUser',
                    createdByUser: 'tableHeaders.createdBy',
                    // attributes: 'common.attributes',
                    rejected_at: 'common.rejected_at',
                    finishedByUser: 'common.finishedByUser',
                    formalConfirmByUser: 'common.formalConfirmByUser',
                    resolution: 'common.resolutionSummary',
                },
                IssueEmployee: {
                    user: 'common.user',
                    created_at: 'logs.issueEmployee.assignedAt',
                    received_at: 'logs.issueEmployee.receivedAt',
                    took_at: 'logs.issueEmployee.tookAt',
                    finished_at: 'logs.issueEmployee.finishedAt',
                    deleted_at: 'logs.issueEmployee.deletedAt',
                    comment: 'common.comment',
                },
                Document: {
                    id: 'tableHeaders.id',
                    name: 'common.name',
                    tenant_id: false,
                    documentable_id: false,
                    documentable_type: false,
                    path: 'tableHeaders.path',
                    sort: 'common.order',
                },
                PartUsage: {
                    awaits_return: 'common.awaitsReturn',
                    createdBy: 'common.user',
                    quantity: 'common.quantity',
                    unit: 'common.unit',
                    part: 'common.part',
                },
            };
            return tab[table]?.[field] || null;
            //*/
        },
        load() {
            if (!this.modelValue) return;
            this.loading = true;
            const options = this.dataStore.makeServerOptions(
                this.serverOptions
            );
            delete options.params.sort_by;
            delete options.params.sort_dir;
            this.axios
                .get(`/issues/${this.modelValue.id}/log`, options)
                .then((x) => {
                    if (x.data) {
                        this.items = x.data.items;
                        this.serverItemsLength = x.data?.meta?.total;
                    }
                    this.loading = false;
                    this.$emit('update:loaded', true);
                })
                .catch((e) => {
                    console.error(e);
                    if (e.response?.status == 401) return;
                });
        },
        actionLabel(action) {
            switch (action) {
                case 'created':
                    return this.$t('issues.created');
                case 'updated':
                    return this.$t('issues.updated');
                case 'deleted':
                    return this.$t('issues.deleted');
                case 'restored':
                    return this.$t('issues.restored');
            }
            return '?';
        },
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences(this.TABLE_NAME, {
                default_size: val.rowsPerPage,
            });
            this.load();
        },
    },
};
</script>
<style>
.value-removed-span {
    color: var(--ui-text-text-error);
}
</style>
