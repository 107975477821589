<template>
    <SignaloDialog
        icon="mdi-file-import-outline"
        :title="$t('staff.importScheduleFile')"
        :open="open"
        @close="() => closeDialog()"
    >
        <template #body>
            <v-card-text v-if="!modalVisible">
                <v-form>
                    <SignaloDatePicker
                        :label="$t('common.month')"
                        variant="outlined"
                        color="var(--greyscale-100)"
                        @update="onDateSelect"
                        :minimumView="calendarViewMode"
                    >
                    </SignaloDatePicker>
                    <v-checkbox
                        :label="$t('common.importScheduleCheckboxLabel')"
                        v-model="wipe"
                        color="primary"
                        hide-details
                        single-line
                    ></v-checkbox>
                    <v-card-text>
                        <p class="Text Label-Text">{{ fileName }}</p>
                    </v-card-text>
                    <v-btn @click="$refs.fileInput.click()" color="primary">
                        <v-icon> mdi-plus-circle-outline </v-icon>
                        {{ $t('common.select') }}
                    </v-btn>
                    <input
                        type="file"
                        ref="fileInput"
                        @change="filesSelected"
                        style="display: none"
                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                </v-form>
            </v-card-text>
            <v-card-text v-else>
                <SignaloProcessProgressBar
                    v-if="jobStatusID"
                    :jobStatusId="jobStatusID"
                    :progress_now="0"
                    @update:jobstatus="(x) => (jobStatus = x)"
                    :refresh_rate="500"
                />
                <div
                    v-if="!jobStatus || jobStatus?.status !== 'finished'"
                ></div>
                <div v-else-if="jobStatus?.output?.errors?.length">
                    <v-table>
                        <thead>
                            <tr>
                                <th>{{ $t('common.cell') }}</th>
                                <th>{{ $t('common.error') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="e in jobStatus?.output?.errors"
                                :key="e.args.cell"
                            >
                                <td>{{ e.args.cell }}</td>
                                <td>
                                    {{
                                        $t(
                                            'staff.importSchedule.errors.' +
                                                e.error,
                                            e.args
                                        )
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </div>
                <div v-else>
                    {{ $t('errors.finishedSuccessfully') }}
                </div>
            </v-card-text>
        </template>
        <template #buttons>
            <v-btn text @click="() => closeDialog()">
                {{ $t('common.close') }}
            </v-btn>
            <v-btn
                v-if="!modalVisible"
                variant="elevated"
                @click="() => uploadFile()"
                :disabled="!file || !month"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import { toMySqlDate } from 'signalo-vue-utils';

export default {
    props: ['open'],
    emits: ['close'],
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            month: null,
            calendarViewMode: 'month',
            wipe: false,
            fileName: '',
            file: null,
            modalVisible: false,
            uploadProgress: 0,
            processProgress: 0,
            jobStatusID: null,
            jobStatus: null,
        };
    },
    mounted() {},
    watch: {},
    methods: {
        closeDialog() {
            this.$emit('close');
        },
        onDateSelect(date) {
            this.month = toMySqlDate(date).substring(0, 7);
        },

        filesSelected(e) {
            Array.from(e.target.files).forEach((x) => {
                this.fileName = x.name;
                this.file = x;
            });
        },
        uploadFile() {
            this.axios
                .request({
                    url: '/scheduled_events/import',
                    method: 'POST',
                    data: {
                        file: this.file, //document.querySelector('#fileInput').files
                        wipe: Number(this.wipe),
                        month: this.month,
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress =
                            (100 * progressEvent.loaded) / progressEvent.total;
                    },
                })
                .then((res) => {
                    if (res.data?.item?.job_status_id) {
                        this.jobStatusID = res.data?.item?.job_status_id;
                    } else throw true;

                    this.modalVisible = true;
                })
                .catch(() => {
                    this.helpersStore.snackbarError(
                        this.$t('errors.uploadError')
                    );
                    this.modalVisible = false;
                });
        },
    },
};
</script>
