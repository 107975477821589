<template>
    <div class="Text survey-long-text-answer-preview" v-if="disabled">
        {{ value }}
    </div>
    <v-textarea
        v-else
        v-model="value"
        @click:control="controlClicked"
        @click:clear="clearClicked"
        clearable
        clear-icon="mdi-close-circle-outline"
        variant="outlined"
        class="survey-long-text-answer-textarea"
        :rows="20"
        :no-resize="false"
    >
    </v-textarea>
</template>

<script>
export default {
    props: ['field', 'disabled'],
    emits: ['answer'],
    data() {
        return {
            value: this.field?.answer || null,
        };
    },
    watch: {
        value(val) {
            this.$emit('answer', val);
        },
    },
};
</script>

<style>
.survey-long-text-answer-textarea .v-field--disabled {
    opacity: 1 !important;
}

.survey-long-text-answer-preview {
    text-align: left !important;
    word-break: break-word !important;
}
</style>
