<template>
    <SignaloAppBar
        search
        v-model:searchOpen="searchOpen"
        :searchFieldCount="searchFieldCount"
    >
        <template #title>
            {{ $t('issues.issues') }}
        </template>
        <template #bar>
            <v-btn
                @click="() => loadItems()"
                class="submain-button renew-button"
            >
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="
                    () => $openManageTableColumnsvisibilityDialog(TABLE_NAME)
                "
                class="submain-button renew-button"
            >
                <v-icon> mdi-table-cog</v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.manageVisibleColumns') }}
                </span>
            </v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="() => addButtonClicked()"
                class="submain-button renew-button"
            >
                <v-icon> mdi-plus</v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloSearchContainer
            ref="searchContainerComponent"
            v-model:open="searchOpen"
            :config="searchConfig"
            @search="loadItems"
            v-model:fieldCount="searchFieldCount"
            :tableName="TABLE_NAME"
        />
        <SignaloBreadCrumbs />

        <div class="issues-content-wrapper">
            <EasyDataTable buttons-pagination
                :headers="tableHeaders"
                :items="items"
                :empty-message="$t('common.emptyTableMessage')"
                @click-row="handleClickRow"
                table-class-name="easy-table-style"
                :rows-per-page-message="$t('common.rowsPerPage')"
                :rows-of-page-separator-message="$t('common.rowsOfPage')"
                :loading="loading"
                v-model:server-options="serverOptions"
                :server-items-length="serverItemsLength"
                :rows-items="[5, 10, 25, 50, 75, 100]"
                name="issuesIndexTable"
            >
                <template #item-title="item">
                    <p>{{ item.title }}</p>
                </template>
                <template #item-category_id="item">
                    <div class="category-name-template">
                        <div
                            class="category-color"
                            v-if="getCategoryColor(item.category_id)"
                            :style="`background: ${getCategoryColor(
                                item.category_id
                            )}; height: 18px; width: 18px; min-width: 18px; border-radius:50%; margin-top: auto; margin-bottom: auto; margin-right: 4px;`"
                        ></div>
                        <v-icon
                            class="category-icon"
                            v-if="!!getCategoryIcon(item.category_id)"
                            v-bind:key="getCategoryIcon(item.category_id)"
                        >
                            {{ getCategoryIcon(item.category_id) }}
                        </v-icon>
                        <div>
                            {{
                                $getPropertyTranslation(
                                    dataStore.categories.getById(
                                        item.category_id
                                    )?.name
                                )
                            }}
                        </div>
                    </div>
                </template>
                <template #item-department="item">
                    {{
                        this.departments?.find(
                            (x) => x.keyname === item.department
                        )?.value ?? '-'
                    }}
                </template>
                <template #item-flow_state_name="item">
                    {{ $getPropertyTranslation(item.flow_state_name) ?? '-' }}
                </template>
                <template #item-created_by="item">
                    {{ item.created_by_user_name }}
                </template>
                <template #item-current_employee="item">
                    {{ item?.current_employee?.name || '-' }}</template
                >
                <template #item-responsible_user="item">
                    {{ item?.responsible_user?.name }}
                </template>
                <template #item-formal_confirm_at="item">
                    <v-icon v-if="item.formal_confirm_at"> mdi-check </v-icon>
                    <v-badge
                        v-else-if="
                            item.formal_confirm_required &&
                            item.flow_state_finished
                        "
                        color="error"
                        :content="$t('issues.formalConfirmationRequired')"
                        inline
                    ></v-badge>
                </template>
                <template #item-criticality="item">
                    {{
                        $getDictionaryItemName(
                            'issue_criticality',
                            item?.criticality
                        ) || '-'
                    }}
                </template>
                <template #item-prod_line="item">
                    {{
                        $getDictionaryItemName('prod_lines', item?.prod_line) ||
                        '-'
                    }}
                </template>
                <template #item-kind="item">
                    {{
                        $getDictionaryItemName('issue_kind', item?.kind) || '-'
                    }}
                </template>
                <template #item-equipment_symbol="item">
                    {{ item?.equipment?.symbol || '-' }}
                </template>
                <template #item-equipment_id="item">
                    {{ item?.equipment?.name || '-' }}
                </template>
            </EasyDataTable>
        </div>
    </v-main>
    <NewIssueDialog
        v-if="newDialogOpen"
        v-model:open="newDialogOpen"
        @item:created="loadItems"
        @close="onDialogClose"
    />
    <SignaloExportDialog
        v-if="exportDialog"
        :open="exportDialog"
        :endpoint="exportEndpoint"
        @close="exportDialog = false"
        :serverOptions="singleEntryExport ? null : serverOptions"
        :allPagesAllowed="exportAllPagesAllowed"
        :allowOrientationChange="true"
        :query="singleEntryExport ? null : $route.query"
    />
    <SignaloConfirmationDialog
        v-if="archiveDialog"
        :dialogOpened="archiveDialog"
        @update:dialogOpened="(value) => onArchiveHandler(value)"
        :confirmButtonText="$t('common.archive')"
        :text="$t('common.areYouSureAboutArchiveIssue')"
    />
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import { SEARCH_FIELD_TYPES } from 'signalo-vue-search-container';

import NewIssueDialog from './NewIssueDialog';

const _ = require('lodash');

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    components: {
        NewIssueDialog,
    },
    data() {
        return {
            loading: false,
            items: [],
            TABLE_NAME: 'myIssuesIndexTable',
            serverOptions: this.$getServerOptions('myIssuesIndexTable'),
            serverItemsLength: 0,
            searchOpen: false,
            searchFieldCount: 0,
            lastSearch: null,

            newDialogOpen: false,
            refreshIntervalId: null,
            refreshCounter: 60,
            exportEndpoint: null,
            exportDialog: false,
            issuesByCategory: [],
            notPlannedAll: 0,
            notPlannedUnfinished: 0,
            plannedAll: 0,
            plannedUnfinished: 0,
            currentTab: this.getCurrentTabInitValue(),
            exportAllPagesAllowed: false,
            singleEntryExport: false,
            desktopInitLoadDone: false,
            archiveDialog: false,
            itemToArchive: null,
            issueChartDialog: false,
            issueChartData: null,
        };
    },
    computed: {
        issue_criticality() {
            return this.dataStore.dictionary.group('issue_criticality');
        },
        tableHeaders() {
            return this.$getPreferredTableColumns(this.TABLE_NAME);
        },
        searchConfig() {
            return [
                {
                    id_equals: {
                        type: 'text',
                        label: this.$t('searchConfig.id'),
                        cols: 2,
                    },
                    title: {
                        type: 'text',
                        label: this.$t('tableHeaders.title'),
                        cols: 2,
                    },
                    category_path: {
                        type: 'category-picker',
                        label: this.$t('searchConfig.category'),
                        cols: 2,
                        bind: { returns: 'path' },
                    },
                    equipment_id_equals: {
                        type: SEARCH_FIELD_TYPES.EQUIPMENT_PICKER,
                        label: this.$t('searchConfig.object'),
                        cols: 3,
                        bind: {
                            kind: 'machine',
                        },
                    },
                    criticality: {
                        type: 'dictionary:issue_criticality',
                        label: this.$t('searchConfig.criticality'),
                        cols: 2,
                    },
                    kind: {
                        type: 'dictionary:issue_kind',
                        label: this.$t('searchConfig.kind'),
                        cols: 2,
                    },
                    prod_line: {
                        type: 'dictionary:prod_lines',
                        cols: 3,
                        label: this.$t('common.productionLine') + '...',
                    },
                    responsible_user_id_equals: {
                        type: SEARCH_FIELD_TYPES.USER_PICKER,
                        label: this.$t('issues.responsibleUser'),
                        cols: 3,
                    },
                },
            ];
        },
        departments() {
            return Object.values(
                this.dataStore.dictionary.group('departments')
            );
        },
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences('myIssuesIndexTable', {
                default_size: val.rowsPerPage,
            });
            this.loadItems();
        },
    },
    created() {
        this.loading = true;

        Promise.allSettled([
            this.dataStore.dictionary.promise(),
            this.dataStore.categories.promise(),
        ]).then(() => {
            this.setRefreshInterval();
        });
    },
    mounted() {
        if (window.location.href.includes('openDialog=true')) {
            this.addButtonClicked();
        }
    },
    unmounted() {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }
    },
    methods: {
        async loadItems(search = null, resetPage) {
            this.loading = true;
            if (resetPage) {
                this.serverOptions.page = 1;
            }
            if (search !== null) {
                this.lastSearch = search;
            }

            this.refreshCounter = 60;
            const config = this.dataStore.myIssues.makeServerOptions(
                this.serverOptions,
                this.lastSearch
            );
            try {
                const itemsData = await this.axios.get('me/issues', config);
                this.items = itemsData?.data?.items;
                this.issuesByCategory = _.groupBy(this.items, 'category_id');
                this.serverItemsLength = itemsData?.data?.meta?.total;
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        addButtonClicked() {
            this.newDialogOpen = true;
        },
        handleClickRow(row) {
            this.$router.push({
                name: 'my_issues.show',
                params: { id: row.id },
            });
        },
        getCategoryIcon(categoryId) {
            return this.dataStore.categories.find((c) => c.id === categoryId)
                ?.icon;
        },
        getCategoryColor(categoryId) {
            return this.dataStore.categories.find((c) => c.id === categoryId)
                ?.color;
        },
        setRefreshInterval() {
            if (this.refreshIntervalId) {
                clearInterval(this.refreshIntervalId);
            }
            this.refreshIntervalId = setInterval(() => {
                this.refreshCounter--;
                if (this.refreshCounter <= 0) {
                    this.refreshCounter = 20;
                    this.authStore.isLoggedIn
                        ? this.loadItems()
                        : clearInterval(this.refreshIntervalId);
                }
            }, 1000);
        },
        onExport(item = null) {
            const id = item?.id;
            this.singleEntryExport = !!id;
            this.exportAllPagesAllowed = !id;
            this.exportEndpoint = 'issues';
            if (id !== null && id !== undefined) {
                this.exportEndpoint += `/${id}`;
            }
            this.exportDialog = true;
        },
        getIssuesForCategory(id) {
            return this.items.filter((i) => i.category_id == id);
        },
        updateCategoryPlannedHandler(val) {
            this.currentTab = val;
        },
        getCurrentTabInitValue() {
            if (!this.helpersStore.isMobileView) {
                return this.$route.query['category_planned'] !== undefined
                    ? this.$route.query['category_planned'] == 1
                        ? 'planned'
                        : 'not-planned'
                    : null;
            }
            return null;
        },
        onArchive(item) {
            this.itemToArchive = item;
            this.archiveDialog = true;
        },
        async onArchiveHandler(shouldArchive) {
            if (shouldArchive) {
                try {
                    await this.axios.delete(`issues/${this.itemToArchive.id}`);
                    await this.loadItems();
                    this.helpersStore.snackbar(
                        this.$t('tableHeaders.archived'),
                        'success'
                    );
                } catch (err) {
                    console.error(err);
                }
            }
            this.archiveDialog = false;
            this.itemToArchive = null;
        },
        showIssueChart(issue) {
            this.issueChartData = issue;
            this.issueChartDialog = true;
        },
        issueChartDialogClose() {
            this.issueChartDialog = false;
            this.issueChartData = null;
        },
        async onDialogClose(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadItems();
            }
            this.newDialogOpen = false;
        },
    },
};
</script>
<style>
.category-icon {
    margin-right: 8px;
}
.category-name-template {
    display: flex;
    align-items: center;
}

.tabs-toggle-container {
    margin: 32px;
    margin-bottom: 0;
}

.issues-extension-panel-text .v-expansion-panel-text__wrapper {
    padding: 0;
}

.issues-v-expansion-panels {
    margin-top: 12px;
}
.tabs-toggle-margin-left {
    margin-left: 8px;
}
.show-desktop-view-switch-container {
    padding: 0 12px;
}
.no-data-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
