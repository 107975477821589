export default [
    {
        text: 'tableHeaders.id',
        value: 'id',
    },
    {
        text: 'common.employeeNumber',
        value: 'no',
        sortable: true,
    },
    { text: 'tableHeaders.image', value: 'photo' },
    {
        text: 'tableHeaders.nameSurname',
        value: 'name',
    },
    {
        text: 'tableHeaders.email',
        value: 'email',
        sortable: true,
    },
    { text: 'tableHeaders.phone', value: 'phone' },
    {
        text: 'common.crew',
        value: 'crew_id',
    },
    {
        text: 'common.function',
        value: 'function',
    },
    {
        text: 'tableHeaders.accountStatus',
        value: 'account_status',
        sortable: true,
    },
    { value: 'operation', width: 54, fixed: true },
];
