<template>
    <SignaloDialog
        :open="open"
        :icon="'mdi-note-edit-outline'"
        :title="$t('common.user_kaizen')"
        @close="closeDialog"
        :constHeight="false"
    >
        <template #body>
            <PageLoading v-if="loading" />
            <div v-else>
                <v-card-text>
                    <v-form
                        ref="dataForm"
                        v-model="dataValid"
                        lazy-validation
                        class="form-container change-suggestion-form"
                    >
                        <v-col>
                            <v-select
                                variant="outlined"
                                color="var(--greyscale-100)"
                                :label="$t('common.type')"
                                v-model="type"
                                :items="suggestionTypes"
                                item-title="label"
                                item-value="value"
                                class="required-field"
                                :rules="rules"
                                :disabled="preview"
                                :no-data-text="$t('common.emptyTableMessage')"
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-textarea
                                :label="$t('common.description')"
                                v-model="description"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                required
                                :rules="descriptionRules"
                                class="required-field"
                                :disabled="preview"
                            ></v-textarea>
                        </v-col>
                    </v-form>
                </v-card-text>
            </div>
        </template>
        <template #buttons>
            <v-btn class="mr-4" @click="closeDialog(false)">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                v-if="!preview"
                :loading="dataSaving"
                color="primary"
                @click="saveData"
                variant="elevated"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    props: ['open', 'mode', 'editedItem', 'preview'],
    setup() {
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { authStore, helpersStore };
    },
    data() {
        return {
            dataSaving: false,
            dataValid: true,
            type: this.editedItem?.type || null,
            description: this.editedItem?.description || null,
            suggestionTypes: [
                {
                    value: 'user_kaizen',
                    label: this.$t('common.user_bug'),
                },
                {
                    value: 'user_bug',
                    label: this.$t('common.user_kaizen'),
                },
            ],
        };
    },
    methods: {
        async saveData() {
            await this.$refs.dataForm.validate();
            if (!this.dataValid) return;
            this.dataSaving = true;
            let method;
            this.mode === 'edit' ? (method = 'PUT') : (method = 'POST');
            let url = '/error_log';
            let data = {
                description: this.description,
                type: this.type,
            };
            if (this.mode === 'edit') {
                url += '/' + this.editedItem.id;
            }
            this.axios
                .request({ url, method, data })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('common.saved'),
                        'success'
                    );
                    this.closeDialog(true);
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                })
                .finally(() => {
                    this.dataSaving = false;
                });
        },
        closeDialog(refetchData = false) {
            this.$emit('closeDialog', refetchData);
        },
    },
    computed: {
        rules() {
            return [(v) => !!v || this.$t('common.fieldRequired')];
        },
        descriptionRules() {
            return [(v) => !!v || this.$t('common.fieldRequired'), (v) => v.length <= 255 || this.$t('errors.maxLength') + ' 255'];
        },
    },
};
</script>
<style>
.change-suggestion-form .v-field--disabled {
    opacity: unset !important;
    cursor: not-allowed !important;
}
</style>