<template>
    <div class="issue-card-simple-mobile" @click="onCardClickHandler">
        <div
            class="issue-category-strip"
            :style="`background-color: ${issue?.category?.color}`"
        ></div>
        <div class="issue-card-main">
            <div class="issue-card-header">
                <div class="issue-card-header-prod-line">
                    {{ $getDictionaryItemName('prod_lines', issue?.prod_line) }}
                </div>
                <div class="issue-card-header-equipment">
                    <span
                        v-if="issue?.equipment_symbol"
                        class="equipment-symbol"
                        >({{ issue?.equipment_symbol }})&nbsp;</span
                    >{{ issue?.equipment_name }}
                </div>
                <div class="issue-card-header-action">
                    <SignaloDynamicMenu
                        ref="dynamicMenu"
                        :rowItem="issue"
                        :menuItems="getTableActions(issue)"
                    />
                </div>
            </div>
            <div class="issue-card-content issue-date">
                {{
                    isPlannedCategory(issue?.category_id)
                        ? issue?.scheduled_on
                        : issue?.created_at
                }}
            </div>

            <div
                class="issue-card-content"
                v-if="
                    issue.criticality ||
                    issue?.kind ||
                    issue?.responsible_user_id
                "
            >
                <div
                    v-if="issue.criticality"
                    class="issue-criticality-container simple-issue-card-content-margin-right"
                >
                    <div class="issue-criticality">
                        {{ issue.criticality }}
                    </div>
                </div>
                <div
                    v-if="issue?.kind"
                    class="issue-kind-container simple-issue-card-content-margin-right"
                >
                    <div class="issue-kind">
                        ({{ getIssueKindName(issue.kind) }})
                    </div>
                </div>
                <div class="issue-working-employees">
                    <v-tooltip
                        v-if="issue?.responsible_user_id"
                        :text="`${$t('issues.responsibleUser')}: ${
                            issue?.responsible_user?.name
                        }`"
                        open-on-click
                        open-on-focus
                        class="simple-issue-card-content-margin-right"
                        location="bottom"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon v-bind="props">mdi-account</v-icon>
                        </template>
                    </v-tooltip>
                </div>
            </div>
            <div class="issue-card-content">
                <div class="issue-card-content-category">
                    <v-icon
                        :color="issue?.category?.color"
                        class="simple-issue-card-content-margin-right"
                        >{{ issue?.category?.icon }}</v-icon
                    >
                    <div class="issue-card-content-category-name">
                        {{ $getPropertyTranslation(issue?.category?.name) }}
                    </div>
                </div>
            </div>
            <div
                :class="
                    expanded
                        ? 'issue-card-footer  expanded'
                        : 'issue-card-footer'
                "
            >
                <div class="issue-card-footer-info">
                    <div class="issue-card-footer-issue-title">
                        <span
                            v-if="
                                authStore?.tenant?.attributes?.auto_translation
                            "
                            :class="
                                shouldDisplayFieldMultiline('user_description') ? 'multiline' : ''
                            "
                            v-html="
                                (currentLocale
                                    ? $getPropertyTranslation(
                                          issue?.attributes?.translations
                                              ?.title,
                                          currentLocale,
                                          issue?.attributes?.auto_translate,
                                          'title'
                                      )
                                    : issue?.title) ||
                                issue?.title ||
                                '-'
                            "
                        ></span>
                        <span
                            v-else
                            :class="
                                shouldDisplayFieldMultiline('user_description') ? 'multiline' : ''
                            "
                            >{{ issue.title }}</span
                        >
                    </div>
                    <div class="issue-card-footer-issue-description">
                        <span
                            v-if="
                                authStore?.tenant?.attributes?.auto_translation
                            "
                            :class="
                                shouldDisplayFieldMultiline('user_description') ? 'multiline' : ''
                            "
                            v-html="
                                (currentLocale
                                    ? $getPropertyTranslation(
                                          issue?.attributes?.translations
                                              ?.issue_description,
                                          currentLocale,
                                          issue?.attributes?.auto_translate,
                                          'issue_description'
                                      )
                                    : issue?.issue_description) ||
                                issue?.issue_description
                            "
                        ></span>
                        <span
                            v-else
                            :class="
                                shouldDisplayFieldMultiline('user_description') ? 'multiline' : ''
                            "
                        >
                            {{ issue?.issue_description }}</span
                        >
                    </div>
                </div>
                <div
                    class="issue-card-footer-action"
                    v-if="issue.title || issue.user_description"
                >
                    <div
                        @click="
                            ($event) => {
                                $event.stopPropagation();
                                $event.preventDefault();
                                expanded = !expanded;
                            }
                        "
                    >
                        <v-icon v-if="!expanded">mdi-chevron-down</v-icon>
                        <v-icon v-else>mdi-chevron-up</v-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
export default {
    props: ['issue', 'currentLocale'],
    emits: ['reload'],
    setup() {
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        const dataStore = DATA_STORE.default();
        return { helpersStore, authStore, dataStore };
    },
    data() {
        return {
            expanded: false,
        };
    },
    created() {
        this.dataStore.categories.promise();
    },
    methods: {
        shouldDisplayFieldMultiline(fieldName) {
            return Boolean(
                this.authStore.tenant?.attributes?.issueDefaults?.[
                    this.issue?.category?.path
                ]?.displayOptions?.[fieldName]?.multiline
            );
        },
        isAllowedToIssueAction(action) {
            return Boolean(this.issue?.access?.[action]);
        },
        getIssueKindName(kindKey) {
            const kind = this.dataStore?.dictionary
                ?.group('issue_kind')
                ?.find((x) => x.keyname === kindKey);

            if (!kind) return '';
            return kind.value;
        },
        showIssue(issue) {
            this.$router.push({
                name: 'issues.show',
                params: { id: issue.id },
            });
        },
        getTableActions(item) {
            let actions = [
                {
                    icon: 'mdi-eye',
                    tooltipText: this.$t('common.preview'),
                    label: this.$t('common.preview'),
                    action: this.showIssue,
                },
                {
                    icon: 'mdi-account-arrow-left',
                    tooltipText: this.$t(
                        'dashboard.components.myIssues.takeIssueBtn'
                    ),
                    label: this.$t(
                        'dashboard.components.myIssues.takeIssueBtn'
                    ),
                    action: () => this.assignAsResponsible(item, 'assign'),
                    access: [
                        item?.access?.assign &&
                            item?.responsible_user_id != this.authStore.user.id,
                    ],
                },
                {
                    icon: 'mdi-account-arrow-right',
                    tooltipText: this.$t(
                        'dashboard.components.myIssues.unassignIssue'
                    ),
                    label: this.$t(
                        'dashboard.components.myIssues.unassignIssue'
                    ),
                    action: () => this.assignAsResponsible(item, 'unassign'),
                    access: [
                        item?.access?.unassign &&
                            item?.responsible_user_id == this.authStore.user.id,
                    ],
                },
            ];

            return actions;
        },
        getEmplyeesNames(issue, working = false) {
            if (!issue?.employees?.length) return;
            if (working) {
                return (
                    this.$t('issues.tookAt') +
                    ': ' +
                    issue.employees
                        .filter((e) => e.started_at)
                        .map((e) => e.user.name)
                        .toString()
                );
            }
            return (
                this.$t('issues.assignedEmployees') +
                ': ' +
                issue?.employees?.map((e) => e?.user?.name).toString()
            );
        },
        async assignAsResponsible(issue, action) {
            try {
                const userId =
                    action === 'assign' ? this.authStore.user.id : null;
                await this.axios.patch(`issues/${issue.id}`, {
                    id: issue.id,
                    responsible_user_id: userId,
                    version: issue.version,
                });
                await this.$emit('reload');
                this.helpersStore.snackbarSaved();
            } catch (err) {
                this.helpersStore.snackbarError(err);
                console.error(err);
            }
        },
        onCardClickHandler() {
            this.expanded = true;
            this.$refs?.dynamicMenu?.$refs?.menuActivatorRef?.$el?.click();
        },
        assignIssue(issue) {
            this.$emit('assignEmployeeToIssue', issue);
        },
        isPlannedCategory(id) {
            return !!this.dataStore.categories.getById(id)?.planned;
        },
    },
};
</script>

<style scoped>
.issue-card-simple-mobile {
    border-radius: 8px;
    border: 1px solid var(--ui-general-outline-01);
    background-color: var(--ui-general-card-01);
    display: flex;
}

.issue-category-strip {
    height: 100%;
    width: 4px;
    position: absolute;
    top: 0;
    left: 0;
}
.issue-card-simple-mobile {
    margin-bottom: 16px;
    position: relative;
    overflow: hidden;
}
.issue-card-main {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
}

.issue-card-header,
.issue-card-content {
    width: 100%;
    position: relative;
    min-height: 36px;
}
.issue-card-header .issue-card-header-prod-line {
    font-size: 24px;
    font-weight: bold;
    color: var(--ui-text-text-01);
    margin-right: 8px;
    float: left;
}
.issue-card-header .issue-card-header-equipment {
    font-size: 13px;
    font-weight: 500;
    color: var(--ui-text-text-01);
    height: 100%;
    display: flex;
    align-items: center;
    float: left;
    padding-right: 32px; /** width of signalodynamicmenu */
    height: 36px;
}

.issue-card-header-action {
    position: absolute;
    top: 0;
    right: 0;
}

.issue-criticality-container {
    width: 38px;
    height: 24px;
    border-radius: 80px;
    border: solid 1px #ffa0cd;
    background-color: #ffceeb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #a92147;
    float: left;
}

.issue-working-employees {
    float: left;
}
.issue-card-content {
    display: flex;
    align-items: center;
}
.simple-issue-card-content-margin-right {
    margin-right: 8px;
}
.issue-card-content-category {
    display: flex;
    align-items: center;
}
.issue-card-content-category-name {
    font-size: 12px;
}
.issue-card-footer {
    height: 18px;
    overflow: hidden;
    max-width: 90%;
}
.issue-card-footer-action {
    position: absolute;
    right: 12px;
    bottom: 12px;
}

.issue-card-footer-issue-title {
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.issue-card-footer-issue-description {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.expanded {
    height: auto;
}
.issue-kind {
    font-size: 13px;
    font-weight: bold;
}

.expanded .issue-card-footer-issue-title,
.expanded .issue-card-footer-issue-description {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
}

.issue-date {
    font-size: 13px;
}
.equipment-symbol {
    font-weight: bold;
}
</style>
