<template>
    <SignaloAppBar
        search
        v-model:searchOpen="searchOpen"
        :searchFieldCount="searchFieldCount"
    >
        <template #title v-if="!helpersStore.isMobileView">
            {{ $t('machinesAndToolsDefinitions.machinesAndToolsDefinitions') }}
        </template>
        <template #bar>
            <v-menu offset-y v-if="$route.meta.canCreate">
                <template v-slot:activator="{ props }">
                    <v-btn
                        v-bind="props"
                        color="primary"
                        variant="elevated"
                        class="submain-button renew-button"
                    >
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <!-- <v-list-item @click="onExport()">
                        <v-list-item-title>
                            <v-icon> mdi-file-download </v-icon>
                            <span>
                                {{ $t('common.export') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item
                        @click="
                            $router.push({
                                name: 'settings.equipment-types.create',
                            })
                        "
                        v-if="$route.meta.canCreate"
                    >
                        <v-list-item-title>
                            <v-icon> mdi-plus </v-icon>
                            <span>
                                {{ $t('common.add') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloSearchContainer
            v-model:open="searchOpen"
            :config="searchConfig"
            @search="loadTable"
            v-model:fieldCount="searchFieldCount"
        >
            <template #type="props">
                <v-select
                    density="compact"
                    variant="outlined"
                    color="var(--greyscale-100)"
                    hide-details
                    :label="$t('searchConfig.kind')"
                    :modelValue="props.value"
                    @update:modelValue="(x) => props.update(x)"
                    :items="equipmentTypes"
                    item-title="value"
                    item-value="keyname"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                    :no-data-text="$t('common.emptyTableMessage')"
                />
            </template>
        </SignaloSearchContainer>
        <SignaloBreadCrumbs />
        <EasyDataTable buttons-pagination
            :items="items"
            :headers="tableHeaders"
            :loading="loadingTable"
            :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style"
            @click-row="itemClicked"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            v-model:server-options="serverOptions"
            :server-items-length="serverItemsLength"
            :rows-items="[5, 10, 25, 50, 75, 100]"
            name="equipmentTypesIndexTable"
        >
            <template #item-type="item">
                {{
                    item?.type === 'machine'
                        ? $t('common.machine')
                        : $t('common.tool')
                }}
            </template>
            <template #item-item_group="item">
                {{ toolGroupName(item.item_group) ?? '-' }}
            </template>
            <template #item-operation="item">
                <div class="actions-column">
                    <v-menu
                        offset-y
                        v-if="$route.meta.canUpdate || $route.meta.canDelete"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                variant="outlined"
                                color="primary"
                                v-bind="props"
                                icon
                                class="table-action-icon-button"
                            >
                                <v-icon> mdi-menu-down </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="onEdit(item.id)"
                                v-if="$route.meta.canUpdate"
                            >
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-pencil
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.edit')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                            <!-- <v-list-item @click="() => onExport(item.id)">
                                <v-list-item-title
                                    class="menu-action"
                                    v-if="$route.meta.canRead"
                                >
                                    <v-icon class="operation-icon">
                                        mdi-file-download
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.export')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item> -->
                            <v-list-item
                                @click="onRemove(item)"
                                v-if="$route.meta.canDelete"
                            >
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-delete
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.remove')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </EasyDataTable>
    </v-main>
    <SignaloConfirmationDialog
        :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => closeRemoveDialog(value)"
    />
    <SignaloExportDialog
        v-if="exportDialog"
        :open="exportDialog"
        :endpoint="exportEndpoint"
        @close="exportDialog = false"
        :serverOptions="serverOptions"
        :allPagesAllowed="exportAllPagesAllowed"
    />
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store'
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store'; 

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loadingTable: true,
            items: [],
            item: null,
            removeDialog: false,
            searchOpen: false,
            searchFieldCount: 0,
            exportEndpoint: null,
            exportDialog: false,
            serverOptions: this.$getServerOptions('equipmentTypesIndexTable'),
            serverItemsLength: 0,
            lastSearch: null,
            exportAllPagesAllowed: false,
        };
    },
    computed: {
        equipmentTypes() {
            return [
                { keyname: 'tool', value: 'Narzędzie' },
                { keyname: 'machine', value: 'Maszyna' },
            ];
        },

        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.id'),
                    value: 'id',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.kind'),
                    value: 'type',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.toolsGroup'),
                    value: 'item_group',
                    sortable: true,
                },
                { value: 'operation', fixed: true, width: 54 },
            ];
        },
        searchConfig() {
            return {
                id_equals: {
                    type: 'text',
                    label: this.$t('searchConfig.id'),
                    cols: 2,
                },
                name: { type: 'text', label: this.$t('searchConfig.name') },
                type: {
                    type: 'template',
                    label: this.$t('searchConfig.kind'),
                },
                tool_group: {
                    type: 'dictionary:tool_groups',
                    label: this.$t('searchConfig.group'),
                },
            };
        },
    },
    created() {
        this.loadTable();
    },
    methods: {
        loadTable(search, resetPage) {
            this.loadingTable = true;
            if (resetPage) {
                this.serverOptions.page = 1;
            }
            if (search !== null) {
                this.lastSearch = search;
            }

            const config = this.dataStore.equipment_types.makeServerOptions(
                this.serverOptions,
                this.lastSearch
            );
            this.$router.replace({ query: config.params });
            return this.dataStore.equipment_types
                .indexRequest(config)
                .then((x) => {
                    if (x.data?.status === 'ok') {
                        this.items = Object.values(x.data.items);
                        this.serverItemsLength = x?.data?.meta?.total;
                    }
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                })
                .finally(() => {
                    this.loadingTable = false;
                });
        },
        itemClicked(item) {
            if (this.$route.meta.canUpdate) {
                this.onEdit(item.id);
            }
        },
        onEdit(id) {
            this.$router.push({
                name: 'settings.equipment-types.edit',
                params: { id },
            });
        },
        onRemove(item) {
            this.item = item;
            this.removeDialog = true;
        },
        async closeRemoveDialog(shouldRemove) {
            if (shouldRemove) {
                this.loadingTable = true;
                try {
                    await this.axios.delete(`equipment_types/${this.item.id}`);
                    await this.loadTable();
                    this.helpersStore.snackbar(
                        this.$t('common.removed'),
                        'success'
                    );
                } catch (err) {
                    if (err?.response?.status == 401) return;
                }
            }
            this.loadingTable = false;
            this.removeDialog = false;
            this.item = null;
        },
        toolGroupName(keyname) {
            return Object.values(
                this.dataStore.dictionary.group('tool_groups')
            ).find(
                (x) =>
                    (x.lang === this.$i18n.locale || x.lang === 'pl') &&
                    x.keyname === keyname
            )?.value;
        },
        onExport(id = null) {
            this.exportAllPagesAllowed = !id;
            this.exportEndpoint = 'equipment_types';
            if (id !== null) {
                this.exportEndpoint += `/${id}`;
            }
            this.exportDialog = true;
        },
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences('equipmentTypesIndexTable', {
                default_size: val.rowsPerPage,
            });
            this.loadTable();
        },
    },
};
</script>
