export default Object.freeze([
    {
        value: 'notifications',
        title: 'navDrawer.notifications',
        to: { name: 'notifications' },
        permission: 'notifications',
        icon: 'mdi-bell',
        notifications: true,
    },
    {
        value: 'dashboard',
        title: 'navDrawer.dashboard',
        to: { name: 'dashboard' },
        permission: null,
        icon: 'mdi-view-dashboard',
    },
    {
        value: 'issues',
        title: 'navDrawer.issues',
        to: { name: 'issues' },
        permission: 'issues',
        icon: 'mdi-clipboard-alert',
    },
    // {
    //     value: 'my_issues',
    //     title: 'dashboard.components.myIssues.title',
    //     to: { name: 'my_issues' },
    //     permission: null,
    //     icon: 'mdi-head-alert',
    // },
    {
        value: 'instructions',
        title: 'tpm.instructions',
        to: { name: 'instructions' },
        permission: 'settings.instructions',
        icon: 'mdi-list-status',
    },
    {
        value: 'staff',
        title: 'navDrawer.staff',
        to: { name: 'staff' },
        permission: 'staff',
        icon: 'mdi-account-group',
    },
    {
        value: 'process/job-statusses',
        title: 'common.jobStatusses',
        to: { name: 'jobStatusses' },
        permission: 'process',
        icon: 'mdi-cog-sync',
    },
    {
        value: 'reports',
        title: 'reports.reports',
        to: { name: 'reports' },
        permission: ['reports', 'reports-storage'],
        icon: 'mdi-file-chart-outline',
    },
    {
        value: 'parts',
        title: 'navDrawer.parts',
        to: { name: 'parts' },
        permission: 'parts',
        icon: 'mdi-screw-round-top',
    },
    {
        value: 'controls',
        icon: 'mdi-check-all',
        title: 'common.controls',
        to: { name: 'controls' },
        permission: 'controls',
    },
    {
        value: 'counters',
        title: 'common.counters',
        to: { name: 'counters' },
        permission: 'counters',
        icon: 'mdi-meter-electric',
    },
    {
        value: 'statements_results',
        title: 'common.statementsResults',
        to: { name: 'statements_results' },
        permission: 'statements',
        icon: 'mdi-text-box-multiple-outline',
    },
    {
        value: 'orders',
        title: 'common.orders',
        to: { name: 'orders' },
        permission: 'orders',
        icon: 'mdi-shopping',
    },
    {
        value: 'production_plans',
        title: 'common.productionPlans',
        to: { name: 'production_plans' },
        permission: 'production_plans',
        icon: 'mdi-folder-multiple',
    },
    {
        value: 'production_lines',
        title: 'common.productionLines',
        to: { name: 'production_lines' },
        permission: 'production_plans',
        icon: 'mdi-robot-industrial',
    },
    {
        value: 'skillmatrix',
        title: 'common.skillmatrix',
        to: { name: 'skillmatrix' },
        permission: 'skillmatrix',
        icon: 'mdi-text-account',
    },

    {
        value: 'work_permits',
        title: 'common.workPermits',
        to: { name: 'work_permits' },
        permission: 'work_permits',
        icon: 'mdi-card-account-details-outline',
    },
    {
        value: 'visits',
        title: 'common.visits',
        to: { name: 'visits' },
        permission: 'visits',
        icon: 'mdi-handshake-outline',
    },
    {
        value: 'gatehouse',
        title: 'common.gatehouse',
        to: { name: 'gatehouse' },
        permission: 'visits_guard',
        icon: 'mdi-boom-gate-outline',
    },

    {
        value: 'settings',
        title: 'navDrawer.configuration',
        to: { name: 'settings' },
        icon: 'mdi-hammer-screwdriver',
        settings: true,
    },
]);
