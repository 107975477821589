export default [
  { value: "CODE128", label: "C128" },
  { value: "EAN2", label: "EAN2" },
  { value: "EAN5", label: "EAN5" },
  { value: "EAN8", label: "EAN8" },
  { value: "EAN13", label: "EAN13" },
  { value: "CODE39", label: "C39" },
  { value: "MSI", label: "MSI" },
  { value: "CODABAR", label: "CODABAR" },
];
