<template>
    <SignaloAppBar>
        <template #title> {{ $t('tpm.instructions') }} </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs /> 
        <v-row no-gutters>
            <v-col
                v-for="(tab, index) in tabs"
                :key="index"
                cols="12"
                sm="6"
                class="py-2"
            >
                <v-card
                    class="ma-2 setting-card"
                    @click="$router.push(tab.to)"
                    v-if="canViewNavItem(tab.access)"
                >
                    <v-card-text class="text-center setting-icon">
                        <v-icon size="80" v-if="tab?.icon"> {{ tab.icon }} </v-icon>
                        <img v-if="tab?.iconPath" src="../../../assets/cmms-icon.png" />
                    </v-card-text>
                    <v-card-title class="text-center setting-title">
                        {{ tab.label }}
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
    </v-main>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
export default {
    setup() {
        const authStore = AUTH_STORE.default();
        return { authStore };
    },
    computed: {
        tabs() {
            return [
                {
                    icon: 'mdi-file-sign',
                    label: this.$t('common.instructionsFlows'),
                    to: { name: 'settings.instruction_flows' },
                    access: 'settings.instructions',
                },
                {
                    icon: 'mdi-folder-key',
                    label: this.$t('common.instructionsAccess'),
                    to: { name: 'settings.instructions.accesses' },
                    access: 'settings.instruction_access',
                },

            ];
        },
    },
    methods: {
        canViewNavItem(routeName) {
            if (
                this.authStore?.user?.access.find((a) => a?.access === 'admin')
            ) {
                return true;
            }
            return this.authStore.isAllowed(routeName, 'read');
        },
    },
};
</script>
