<template>
    <div
        style="
            display: flex;
            height: 100%;
            overflow: visible;
            position: relative;
            min-height: 48px;
        "
        @click="onGroupEventClick"
    >
        <div
            :style="`height: 100%; width: 4px; min-width: 4px; background: ${eventInfo.categoryColor}; position: absolute`"
        ></div>
        <div
            :style="`
                                height: 100%;
                                padding: 0 2px;
                                justify-content: center;
                                align-items: center;
                                font-size: 18px;
                                display: flex;
                                flex-direction: column;
                                margin-left: 4px;
                            `"
        >
            <i :class="`mdi ${eventInfo?.icon}`"></i>
            <i
                class="mdi mdi-account"
                :style="`display: ${
                    eventInfo?.employees_count &&
                    eventInfo?.employees_working_count
                        ? 'block'
                        : 'none'
                }`"
            ></i>
            <i
                class="mdi mdi-account-outline"
                :style="`display: ${
                    eventInfo.employees_count &&
                    !eventInfo?.employees_working_count
                        ? 'block'
                        : 'none'
                }`"
            ></i>
        </div>
        <div
            :style="`
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                font-size: 12px;
                            `"
        >
            <div
                :style="`
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-evenly;
                                `"
            >
                <div
                    :style="`
                                        display: flex;
                                        justify-content: space-evenly;
                                    `"
                >
                    <div>#{{ eventInfo?.id }}</div>
                    <div>
                        {{
                            new Date(eventInfo.created_at)
                                ?.toLocaleTimeString()
                                ?.substring(0, 5)
                        }}
                    </div>
                    <div :style="`display: flex; font-size: 12px`">
                        <i class="mdi mdi-exclamation-thick"></i
                        >{{ eventInfo?.criticality }}
                    </div>
                </div>
            </div>
            <div
                :style="`
                                    text-align: center;
                                    font-size: 10px;
                                    max-width: 100px;
                                    margin: 0 auto;
                                    text-overflow: ellipsis;
                                    word-break: break-all;
                                    white-space: nowrap;
                                    overflow: hidden;
                                `"
            >
                {{ eventInfo?.categoryName }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['eventInfo'],
    emits: ['groupEventClick'],
    methods: {
        onGroupEventClick($event) {
            $event.stopPropagation();
            this.$emit('groupEventClick', this.eventInfo);
        },
    },
};
</script>
