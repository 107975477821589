<template>
    <SignaloAppBar>
        <template #title>
            {{ $t('common.synchronization') }}&nbsp;-&nbsp;CMMS
        </template>
        <template #bar>
            <v-btn
                @click="() => loadData()"
                class="submain-button renew-button"
            >
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-btn
                @click="() => onManage()"
                v-if="$route.meta.canCreate"
                color="primary"
                variant="elevated"
                class="add-button"
            >
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <EasyDataTable
            buttons-pagination
            :headers="tableHeaders"
            :items="items"
            :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            :loading="loading"
        >
            <template #item-enabled="item">
                <v-icon v-if="item?.enabled">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <template #item-operation="item">
                <SignaloDynamicMenu :rowItem="item" :menuItems="tableActions" />
            </template>
            <template #item-attributes="item">
                <div
                    v-for="key in Object.keys(item?.attributes || {})"
                    :key="key"
                >
                    <span v-if="key === 'categories_root_category_id'">
                        <div>
                            {{
                                $t('synchronization.categoriesRootCategoryId')
                            }}:&nbsp;
                        </div>
                        <div style="display: flex">
                            <div
                                class="category-color"
                                v-if="
                                    getCategoryProperty(
                                        item?.attributes?.[key],
                                        'color'
                                    )
                                "
                                :style="`background: ${getCategoryProperty(
                                    item?.attributes?.[key],
                                    'color'
                                )}; height: 18px; width: 18px; min-width: 18px; border-radius:50%; margin-right: 4px;`"
                            ></div>
                            <v-icon
                                class="category-icon"
                                v-if="
                                    getCategoryProperty(
                                        item?.attributes?.[key],
                                        'icon'
                                    )
                                "
                            >
                                {{
                                    getCategoryProperty(
                                        item?.attributes?.[key],
                                        'icon'
                                    )
                                }}
                            </v-icon>
                            {{
                                $getPropertyTranslation(
                                    getCategoryProperty(
                                        item?.attributes?.[key],
                                        'name'
                                    )
                                )
                            }}
                        </div>
                    </span>
                    <span v-else></span>
                </div>
            </template>
        </EasyDataTable>
    </v-main>

    <ManageSynchronizationCMMSDialog
        v-if="manageDialog"
        :editedItem="editedItem"
        @close="onManageDialogClose"
    />

    <SignaloConfirmationDialog
        v-if="removeDialog"
        :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => onRemove(value)"
    />
</template>

<script>
import ManageSynchronizationCMMSDialog from './ManageSynchronizationCMMSDialog.vue';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();

        return { dataStore, helpersStore };
    },
    data() {
        return {
            loading: false,
            items: [],
            editedItem: null,
            manageDialog: false,
            removeDialog: false,
        };
    },
    async mounted() {
        await this.dataStore.categories.promise();
        this.loadData();
    },
    methods: {
        async loadData() {
            this.loading = true;
            try {
                const imports = await this.axios.get(
                    'external/signalo_cmms_config'
                );
                this.items = imports?.data?.items || [];
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        onManage(item = null) {
            this.editedItem = item;
            this.manageDialog = true;
        },
        remove(item) {
            this.editedItem = item;
            this.removeDialog = true;
        },
        async onRemove(shouldRemove) {
            if (shouldRemove) {
                try {
                    await this.axios.delete(
                        `external/signalo_cmms_config/${this.editedItem.id}`
                    );
                    await this.loadData();
                    this.helpersStore.snackbarSaved();
                } catch (err) {
                    if (err?.response?.status === 401) return;
                }
            }
            this.removeDialog = false;
            this.editedItem = null;
        },
        async onManageDialogClose(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadData();
            }
            this.manageDialog = false;
            this.editedItem = null;
        },
        getCategoryProperty(id, property) {
            return this.dataStore.categories.find((c) => c.id === id)?.[
                property
            ];
        },
        onCMMSCategoriesSync(item) {
            this.$router.push({
                name: 'settings.synchronization.cmms.cmms_categories_sync',
                params: {
                    id: item?.id,
                },
            });
        },
        onCategoriesStructure(item) {
            this.$router.push({
                name: 'settings.synchronization.cmms.cmms_category_structure',
                params: {
                    id: item?.id,
                },
            });
        },
    },
    computed: {
        tableHeaders() {
            return [
                { text: this.$t('synchronization.enabled'), value: 'enabled' },
                // {
                //     text: this.$t('synchronization.systemKeyname'),
                //     value: 'system_keyname',
                // },
                { text: this.$t('synchronization.address'), value: 'address' },
                { text: this.$t('common.attributes'), value: 'attributes' },
                {
                    value: 'operation',
                    width: 54,
                    fixed: true,
                },
            ];
        },
        tableActions() {
            return [
                {
                    icon: 'mdi-pencil',
                    label: this.$t('common.edit'),
                    action: this.onManage,
                },
                {
                    icon: 'mdi-format-list-text',
                    label: this.$t('common.categoryStructure'),
                    action: this.onCategoriesStructure,
                },
                {
                    icon: 'mdi-folder-sync',
                    label: this.$t('common.cmmsCategoriesSync'),
                    action: this.onCMMSCategoriesSync,
                },
                {
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    action: this.remove,
                },
            ];
        },
    },
    components: { ManageSynchronizationCMMSDialog },
};
</script>
