import storageIndexTable from './storageIndexTable';
import partsIndexTable from './partsIndexTable';
import issuesIndexTable from './issuesIndexTable';
import myIssuesIndexTable from './myIssuesIndexTable';
import staffIndexTable from './staffIndexTable';
import customersIndexTable from './customersIndexTable';
import plantsIndexTable from './plantsIndexTable';
import buildingsIndexTable from './buildingsIndexTable';
import floorsIndexTable from './floorsIndexTable';

const TABLE_HEADERS = {
    storageIndexTable,
    partsIndexTable,
    issuesIndexTable,
    myIssuesIndexTable,
    staffIndexTable,
    customersIndexTable,
    plantsIndexTable,
    buildingsIndexTable,
    floorsIndexTable,
};

export function getTableHeaders(tableName) {
    if (!TABLE_HEADERS?.[tableName]) {
        throw new Error(
            `Table headers for table name: [${tableName}] not defined!`
        );
    }
    return TABLE_HEADERS[tableName];
}
