<template>
    <div class="signalo-video-player-container" v-if="videoSrc">
        <video-player
            ref="videoPlayer"
            :src="videoSrc"
            controls
            :loop="false"
            :volume="1"
            :responsive="true"
            @ended="onVideoEnded"
            :autoplay="autoplay"
        />
    </div>
</template>

<script> 
import { VideoPlayer } from '@videojs-player/vue';
export default {
    components: { VideoPlayer },
    props: ['video', 'autoplay'],
    emits: ['ended'],
    data() {
        return {
            videoSrc: this.video?.url || this.video,
        };
    },
    methods: {
        onVideoEnded() {
            this.$emit('ended')
        }
    },
    watch: {
        video: {
            handler() {
                this.videoSrc = this.video?.url || this.video;
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.signalo-video-player-container {
    position: relative;
    width: 100%;
}

</style>

<style>
.signalo-video-player-container .video-js {
    width: 100% !important;
    max-height: 70vh;
}

.signalo-video-player-container .video-js video {
    width: 100% !important;
    position: relative;
    max-height: 100%;
}
</style>