<template>
    <SignaloDialog
        :icon="'mdi-cog-outline'"
        :title="editedItem ? $getPropertyTranslation(editedItem?.name) : ''"
        :open="open"
        @close="closeDialog"
        :constHeight="false"
    >
        <template #body>
            <PageLoading v-if="loading" />

            <div v-else>
                <v-form ref="dataForm" v-model="dataValid" lazy-validation>
                    <SignaloTranslatableTabs
                        v-slot="{ value, update }"
                        v-model="item.name"
                        :currentLanguage="$i18n?.locale"
                        :availableLocales="getAvailableLocales()"
                    >
                        <v-card-text>
                            <v-text-field
                                :label="$t('common.name')"
                                :modelValue="value"
                                @update:modelValue="update"
                                :rules="rules"
                                variant="outlined"
                                color="var(--greyscale-100)"
                            >
                            </v-text-field>
                        </v-card-text>
                    </SignaloTranslatableTabs>
                </v-form>
                <v-card-text>
                    <table class="work-schema-table">
                        <thead>
                            <tr>
                                <td class="Label-Text">
                                    {{ $t('common.weekDays.mo') }}
                                </td>
                                <td class="Label-Text">
                                    {{ $t('common.weekDays.tu') }}
                                </td>
                                <td class="Label-Text">
                                    {{ $t('common.weekDays.we') }}
                                </td>
                                <td class="Label-Text">
                                    {{ $t('common.weekDays.th') }}
                                </td>
                                <td class="Label-Text">
                                    {{ $t('common.weekDays.fr') }}
                                </td>
                                <td class="Label-Text">
                                    {{ $t('common.weekDays.sa') }}
                                </td>
                                <td class="Label-Text">
                                    {{ $t('common.weekDays.su') }}
                                </td>
                                <td class="Label-Text">
                                    {{ $t('common.holiday') }}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <td>
                                <v-switch
                                    hide-details
                                    inset
                                    color="primary"
                                    v-model="item.mo"
                                ></v-switch>
                            </td>
                            <td>
                                <v-switch
                                    hide-details
                                    inset
                                    color="primary"
                                    v-model="item.tu"
                                ></v-switch>
                            </td>
                            <td>
                                <v-switch
                                    hide-details
                                    inset
                                    color="primary"
                                    v-model="item.we"
                                ></v-switch>
                            </td>
                            <td>
                                <v-switch
                                    hide-details
                                    inset
                                    color="primary"
                                    v-model="item.th"
                                ></v-switch>
                            </td>
                            <td>
                                <v-switch
                                    hide-details
                                    inset
                                    color="primary"
                                    v-model="item.fr"
                                ></v-switch>
                            </td>
                            <td>
                                <v-switch
                                    hide-details
                                    inset
                                    color="primary"
                                    v-model="item.sa"
                                ></v-switch>
                            </td>
                            <td>
                                <v-switch
                                    hide-details
                                    inset
                                    color="primary"
                                    v-model="item.su"
                                ></v-switch>
                            </td>
                            <td>
                                <v-switch
                                    hide-details
                                    inset
                                    color="primary"
                                    v-model="item.holiday"
                                ></v-switch>
                            </td>
                        </tbody>
                    </table>
                </v-card-text>
            </div>
        </template>
        <template #buttons>
            <v-btn @click="closeDialog">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                variant="elevated"
                :loading="dataSaving"
                color="primary"
                @click="saveData"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
export default {
    props: ['open', 'mode', 'editedItem'],
    setup() {
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore, authStore };
    },
    data() {
        return {
            dataSaving: false,
            dataValid: true,
            item: this.editedItem,
        };
    },
    methods: {
        async saveData() {
            await this.$refs.dataForm.validate();
            if (!this.dataValid) return;
            this.dataSaving = true;
            let method;
            this.mode === 'edit' ? (method = 'PUT') : (method = 'POST');
            let url = `work_schemas`;

            if (this.mode === 'edit') {
                url += '/' + this.item.id;
            }

            try {
                await this.axios.request({
                    url,
                    method,
                    data: {
                        ...this.item,
                    },
                });
                this.helpersStore.snackbar(this.$t('common.saved'), 'success');
                this.closeDialog(true);
            } catch (err) {
                if (err?.response?.status == 401) return;
            }
            this.dataSaving = false;
        },
        closeDialog(refetchData = false) {
            this.$emit('closeDialog', refetchData);
        },
    },
    computed: {
        rules() {
            return [(v) => !!v || this.$t('common.fieldRequired')];
        },
    },
};
</script>
<style>
.work-schema-modal {
    display: flex !important;
    flex-direction: column;
}
.work-schema-modal .v-card-action {
    margin-top: auto;
}
.work-schema-table {
    width: 100%;
    padding-top: 16px;
}
.work-schema-table td {
    width: 12.5%;
    text-align: center;
}
.work-schema-table td .v-switch {
    display: flex;
    justify-content: center;
}
</style>
