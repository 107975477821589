export default Object.freeze([
    {
        value: 'changeSuggestions',
        title: 'common.changeSuggestions',
        to: { name: 'changeSuggestions.index' },
        permission: null,
        icon: 'mdi-bell',
    },
    {
        value: 'notes',
        title: 'navDrawer.notes',
        to: { name: 'notes' },
        permission: 'notes',
        icon: 'mdi-note-edit-outline',
    },
    {
        value: 'profile',
        title: 'navDrawer.profile',
        to: { name: 'my_profile' },
        permission: null,
        icon: 'mdi-account-tie',
    },
])