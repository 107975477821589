<template>
    <SignaloDialog
        :open="open"
        @close="closeDialog"
        :title="$t('common.terminal') + ' ' + (editedItem?.id || '') + ' ' + $getPropertyTranslation(editedItem?.name)"
        :icon="'mdi-cog-outline'"
        :constHeight="true"
    >
        <template #body>
            <SignaloPageLoading v-if="loading" />
            <div v-else>
                <v-form ref="dataForm" v-model="dataValid" lazy-validation>
                    <SignaloTranslatableTabs
                        v-slot="{ value, update }"
                        v-model="names"
                        :currentLanguage="$i18n?.locale"
                        :availableLocales="getAvailableLocales()"
                    >
                        <v-card-text>
                            <v-text-field
                                :label="$t('common.name')"
                                :modelValue="value"
                                @update:modelValue="update"
                                :rules="rules"
                                variant="outlined"
                                required
                                color="var(--greyscale-100)"
                                class="required-field"
                            >
                            </v-text-field>
                        </v-card-text>
                    </SignaloTranslatableTabs>

                    <v-card-text>
                        <v-select
                            v-model="terminalMode"
                            :items="terminalModes"
                            :label="$t('common.terminalMode')"
                            clearable
                            variant="outlined"
                            clear-icon="mdi-close-circle-outline"
                            :item-title="(item) => item.label"
                            return-object
                            @update:modelValue="onUpdateTerminalMode"
                            :no-data-text="$t('common.emptyTableMessage')"
                            class="required-field"
                            :rules="[$REQUIRED_RULE]"
                        >
                        </v-select>

                        <v-checkbox
                            v-if="
                                authStore.isAllowed(
                                    'terminals.pin-locking',
                                    'read'
                                )
                            "
                            color="primary"
                            single-line
                            hide-details
                            v-model="lock_pin"
                            :label="$t('common.lockPin')"
                            :disabled="isPinCodeCheckboxDisabled"
                        >
                        </v-checkbox>
                        <!-- <v-select
                            v-model="defaultView"
                            :items="terminalDefaultScreenOptions"
                            :label="$t('common.defaultView')"
                            clearable
                            variant="outlined"
                            clear-icon="mdi-close-circle-outline"
                            @click:clear="item.attributes.default_view = null"
                            :item-title="
                                (item) =>
                                    $t(
                                        item?.meta?.bc[
                                            item?.meta?.bc.length - 1
                                        ].text
                                    )
                            "
                            return-object
                            :disabled="terminalMode?.value != 'standard'"
                        >
                        </v-select> -->
                        <v-row
                            v-if="
                                terminalMode?.value === 'simplified_handling' ||
                                terminalMode?.value === 'issues' ||
                                terminalMode?.value === 'production'
                            "
                        >
                            <v-col cols="12" md="12" xl="12">
                                <v-checkbox
                                    color="primary"
                                    single-line
                                    hide-details
                                    v-model="local"
                                    :label="$t('common.localIssues')"
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="terminalMode?.value === 'simplified_handling'"
                        >
                            <v-col cols="12" md="12" xl="12">
                                <v-checkbox
                                    color="primary"
                                    single-line
                                    hide-details
                                    v-model="
                                        simplified_handling_terminal_selectWorkArea
                                    "
                                    :label="$t('common.workAreaSelection')"
                                >
                                </v-checkbox>
                            </v-col>

                            <v-col cols="12" md="12" xl="12">
                                <v-checkbox
                                    color="primary"
                                    single-line
                                    hide-details
                                    v-model="
                                        simplified_handling_terminal_hideSideMenu
                                    "
                                    :label="$t('common.hideSideMenu')"
                                >
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" md="12" xl="12">
                                <v-checkbox
                                    color="primary"
                                    single-line
                                    hide-details
                                    v-model="
                                        simplified_handling_hide_work_areas_button
                                    "
                                    :label="$t('common.hideWorkAreasButton')"
                                >
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" md="12" xl="12">
                                <v-checkbox
                                    color="primary"
                                    single-line
                                    hide-details
                                    v-model="
                                        simplified_handling_hide_classic_view_button
                                    "
                                    :label="$t('common.hideClassicViewButton')"
                                >
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" md="12" xl="12">
                                <v-checkbox
                                    color="primary"
                                    single-line
                                    hide-details
                                    v-model="
                                        simplified_handling_hide_add_issue_button
                                    "
                                    :label="$t('common.hideAddIssueButton')"
                                >
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" md="12" xl="12">
                                <v-checkbox
                                    color="primary"
                                    single-line
                                    hide-details
                                    v-model="
                                        simplified_display_params_on_main_tile
                                    "
                                    :label="
                                        $t(
                                            'common.displayParametersOnMainScreen'
                                        )
                                    "
                                >
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" md="12" xl="12">
                                <v-checkbox
                                    color="primary"
                                    single-line
                                    hide-details
                                    v-model="
                                        simplified_new_issue_ignore_filters
                                    "
                                    :label="$t('common.newIssueIgnoreFilters')"
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row v-if="terminalMode?.value === 'storage'">
                            <v-col cols="12" md="12" xl="12">
                                <v-autocomplete
                                    :items="storagesOptions"
                                    v-model="storageKeyname"
                                    :label="$t('common.warehouse')"
                                    color="primary"
                                    variant="outlined"
                                    item-value="keyname"
                                    item-title="value"
                                    clearable
                                    :no-data-text="
                                        $t('common.emptyTableMessage')
                                    "
                                    class="required-field"
                                    :rules="[$REQUIRED_RULE]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3" xl="3">
                                <v-switch
                                    v-model="
                                        storageTerminalClientSignatureEnabled
                                    "
                                    :label="$t('common.clientSignatureEnabled')"
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" md="9" xl="9">
                                <v-checkbox
                                    v-if="storageTerminalClientSignatureEnabled"
                                    color="primary"
                                    :label="$t('common.required')"
                                    v-model="
                                        storageTerminalClientSignatureRequired
                                    "
                                />
                            </v-col>
                            <v-col cols="12" md="3" xl="3">
                                <v-switch
                                    v-model="
                                        storageTerminalWarehousemanSignatureEnabled
                                    "
                                    :label="
                                        $t(
                                            'common.warehousemanSignatureEnabled'
                                        )
                                    "
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" md="9" xl="9">
                                <v-checkbox
                                    v-if="
                                        storageTerminalWarehousemanSignatureEnabled
                                    "
                                    color="primary"
                                    :label="$t('common.required')"
                                    v-model="
                                        storageTerminalWarehousemanSignatureRequired
                                    "
                                />
                            </v-col>
                            <!--Return-->
                            <v-col cols="12" md="3" xl="3">
                                <v-switch
                                    v-model="
                                        storageTerminalClientReturnSignatureEnabled
                                    "
                                    :label="
                                        $t(
                                            'common.clientReturnSignatureEnabled'
                                        )
                                    "
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" md="9" xl="9">
                                <v-checkbox
                                    v-if="
                                        storageTerminalClientReturnSignatureEnabled
                                    "
                                    color="primary"
                                    :label="$t('common.required')"
                                    v-model="
                                        storageTerminalClientReturnSignatureRequired
                                    "
                                />
                            </v-col>
                            <v-col cols="12" md="3" xl="3">
                                <v-switch
                                    v-model="
                                        storageTerminalWarehousemanReturnSignatureEnabled
                                    "
                                    :label="
                                        $t(
                                            'common.warehousemanReturnSignatureEnabled'
                                        )
                                    "
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" md="9" xl="9">
                                <v-checkbox
                                    v-if="
                                        storageTerminalWarehousemanReturnSignatureEnabled
                                    "
                                    color="primary"
                                    :label="$t('common.required')"
                                    v-model="
                                        storageTerminalWarehousemanReturnSignatureRequired
                                    "
                                />
                            </v-col>
                            <StorageTerminalDefaultValues
                                :defaults="storageTerminalDefaults"
                                :hiddenFields="storageTerminalHiddenFields"
                                @update:modelValue:defaults="
                                    (val) => (storageTerminalDefaults = val)
                                "
                                @update:modelValue:hidden_fields="
                                    (val) => (storageTerminalHiddenFields = val)
                                "
                            />
                        </v-row>

                        <v-row v-if="terminalMode?.value === 'tools'">
                            <v-col cols="12" md="12" xl="12">
                                <v-switch
                                    v-model="toolsReturnRequireApprove"
                                    :label="
                                        $t(
                                            'common.toolsCartTerminalRequireApproveOnToolReturn'
                                        )
                                    "
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                        </v-row>

                        <v-row v-if="terminalMode?.value === 'storage'">
                            <v-col cols="12" md="12" xl="12">
                                <v-switch
                                    v-model="partsReturnRequireApprove"
                                    :label="
                                        $t(
                                            'common.partsCartTerminalRequireApproveOnPartsReturn'
                                        )
                                    "
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                md="12"
                                xl="12"
                                v-if="
                                    terminalMode?.value === 'tools' ||
                                    terminalMode?.value === 'storage'
                                "
                            >
                                <v-switch
                                    v-model="tagsPairingShowCodeOwnerName"
                                    :label="
                                        $t(
                                            'common.showCodeOwnerNameDuringParing'
                                        )
                                    "
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                md="12"
                                xl="12"
                                v-if="
                                    terminalMode?.value === 'tools' ||
                                    terminalMode?.value === 'storage'
                                "
                            >
                                <v-switch
                                    v-model="showUsernameInCart"
                                    :label="$t('common.showUsernameInCart')"
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-if="terminalMode?.value === 'instructions'">
                            <v-col cols="12" md="12" xl="12">
                                <v-autocomplete
                                    variant="outlined"
                                    clearable
                                    chips
                                    :label="$t('common.instructionGroups')"
                                    :items="availableInstructionGroups"
                                    multiple
                                    item-value="path"
                                    item-title="value"
                                    v-model="selectedInstructionGroupsPaths"
                                    :no-data-text="
                                        $t('common.emptyTableMessage')
                                    "
                                    clear-icon="mdi-close-circle-outline"
                                ></v-autocomplete>
                            </v-col>
                            <!-- <v-col
                                cols="12"
                                md="12"
                                xl="12"
                                v-if="terminalMode?.value === 'production'"
                            >
                                <InstructionRevisionPicker
                                    :availableGroups="
                                        selectedInstructionGroupsPaths
                                    "
                                    v-model="
                                        production_terminal_defaultInstructionRevision
                                    "
                                    :withGroupPath="true"
                                    :withInstructionId="true"
                                />
                            </v-col> -->
                            <!-- <v-col
                                cols="12"
                                md="12"
                                xl="12"
                                v-if="terminalMode?.value === 'production'"
                            >
                                <SignaloNewValueSelect
                                    :selectableType="
                                        SELECTABLE_TYPES.DICTIONARY
                                    "
                                    :dictionary="'prod_lines'"
                                    :label="$t('common.productionLines')"
                                    v-model="production_terminal_prodLines"
                                    clearable
                                    multiple
                                    :modificationDisabled="true"
                                />
                            </v-col> -->
                        </v-row>

                        <v-row v-if="terminalMode?.value === 'production'">
                            <v-col
                                cols="12"
                                md="12"
                                xl="12"
                                v-if="terminalMode?.value === 'production'"
                            >
                                <v-autocomplete
                                    variant="outlined"
                                    color="var(--greyscale-100)"
                                    :label="$t('common.productionLines')"
                                    :items="lines"
                                    :item-title="
                                        (item) =>
                                            $getPropertyTranslation(
                                                item?.description
                                            )
                                    "
                                    item-value="id"
                                    v-model="production_terminal_prodLines"
                                    :clearable="true"
                                    :no-data-text="
                                        $t('common.emptyTableMessage')
                                    "
                                    multiple
                                    clear-icon="mdi-close-circle-outline"
                                ></v-autocomplete>
                                <v-autocomplete
                                    variant="outlined"
                                    color="var(--greyscale-100)"
                                    :label="$t('common.defaultProdLine')"
                                    :items="
                                        lines?.filter((d) =>
                                            production_terminal_prodLines.includes(
                                                d.id
                                            )
                                        )
                                    "
                                    :item-title="
                                        (item) =>
                                            $getPropertyTranslation(
                                                item?.description
                                            )
                                    "
                                    item-value="id"
                                    v-model="
                                        production_terminal_default_prod_line
                                    "
                                    :clearable="true"
                                    :no-data-text="
                                        $t('common.emptyTableMessage')
                                    "
                                    clear-icon="mdi-close-circle-outline"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row
                            v-if="terminalMode?.value === 'work_time_register'"
                        >
                            <v-col cols="12" md="12" xl="12">
                                <SignaloTranslatableTabs
                                    v-slot="{ value, update }"
                                    v-model="
                                        work_time_register_terminal_farewellText
                                    "
                                    :currentLanguage="$i18n?.locale"
                                    :availableLocales="getAvailableLocales()"
                                >
                                    <v-card-text>
                                        <v-text-field
                                            :label="$t('common.farewellText')"
                                            :modelValue="value"
                                            @update:modelValue="update"
                                            :rules="[
                                                $TRANSLATABLE_RULE(
                                                    work_time_register_terminal_farewellText
                                                ),
                                            ]"
                                            variant="outlined"
                                            required
                                            color="var(--greyscale-100)"
                                        >
                                        </v-text-field>
                                    </v-card-text>
                                </SignaloTranslatableTabs>
                                <v-switch
                                    v-model="
                                        work_time_register_terminal_noteEnabled
                                    "
                                    :label="$t('paramTypes.note')"
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                md="12"
                                xl="12"
                                v-if="barcodeLoaded"
                            >
                                <SignaloBarCode
                                    ref="barcodeComponent"
                                    :barcode="barcode"
                                    :editable="true"
                                    @onChange="onBarcodeChangeHandler"
                                />
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="
                                terminalMode?.value === 'issues' ||
                                terminalMode?.value === 'simplified_handling' ||
                                terminalMode?.value === 'production'
                            "
                        >
                            <v-col cols="12" md="12" xl="12">
                                <v-autocomplete
                                    v-model="selectedCategoriesPaths"
                                    :items="categoriesOptions"
                                    :label="
                                        $t('common.allowedIssuesCategories')
                                    "
                                    variant="outlined"
                                    clear-icon="mdi-close-circle-outline"
                                    :item-title="
                                        (item) =>
                                            $getPropertyTranslation(item?.name)
                                    "
                                    item-value="path"
                                    clearable
                                    chips
                                    multiple
                                    :no-data-text="
                                        $t('common.emptyTableMessage')
                                    "
                                >
                                    <template #chip="{ item }">
                                        <v-chip class="category-item-chip">
                                            <div
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                "
                                            >
                                                <div
                                                    class="category-color category-color-selection mr-1"
                                                    v-if="item?.raw?.color"
                                                    :style="`background-color: ${item?.raw?.color};`"
                                                ></div>
                                                <v-icon
                                                    v-if="item?.raw?.icon"
                                                    class="mr-1"
                                                    >{{ item.raw.icon }}</v-icon
                                                >
                                                {{ item.title }}
                                            </div>
                                        </v-chip>
                                    </template>
                                    <template #item="{ item }">
                                        <v-list-item
                                            style="cursor: pointer"
                                            class="category-v-list-item"
                                            @click="
                                                () =>
                                                    toggleCategorySelection(
                                                        item.value
                                                    )
                                            "
                                        >
                                            <v-checkbox
                                                color="primary"
                                                single-line
                                                hide-details
                                                :model-value="
                                                    selectedCategoriesPaths.includes(
                                                        item.raw.path
                                                    )
                                                "
                                            >
                                            </v-checkbox>
                                            <div style="display: flex">
                                                <div
                                                    class="category-color category-color-selection mr-1"
                                                    v-if="item?.raw?.color"
                                                    :style="`background-color: ${item?.raw?.color};`"
                                                ></div>
                                                <v-icon
                                                    v-if="item?.raw?.icon"
                                                    class="mr-1"
                                                    >{{ item.raw.icon }}</v-icon
                                                >
                                                {{ item.title }}
                                            </div>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="12" xl="12">
                                <v-switch
                                    v-model="issuesTerminalFlatCategoryPicker"
                                    :label="
                                        $t('common.flatCategoryPickerLabel')
                                    "
                                    color="primary"
                                    hide-details
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" md="12" xl="12">
                                <v-switch
                                    v-model="
                                        issue_terminal_inheritAllDefaultValues
                                    "
                                    :label="
                                        $t('common.inheritAllDefaultValues')
                                    "
                                    color="primary"
                                    hide-details
                                ></v-switch>
                                <IssuesPerCategoryDefaults
                                    v-if="
                                        !issue_terminal_inheritAllDefaultValues
                                    "
                                    ref="IssuesPerCategoryDefaultsRef"
                                    :defaults="issuesTerminalDefaults"
                                    @update:modelValue="onUpdateDefaults"
                                    @everyHiddenAndRequiredFieldHasDefaultValue="
                                        onEveryHiddenAndRequiredFieldHasDefaultValue
                                    "
                                    :allowedCategoriesPaths="
                                        selectedCategoriesPaths
                                    "
                                    :terminal="true"
                                />
                            </v-col>
                            <v-col cols="12" md="12" xl="12">
                                <v-text-field
                                    :label="
                                        $t('common.paddingBottom') + ' (px)'
                                    "
                                    v-model="terminalPaddingBottom"
                                    :rules="[
                                        $MIN_RULE(0, terminalPaddingBottom),
                                    ]"
                                    variant="outlined"
                                    color="var(--greyscale-100)"
                                    clearable
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" xl="12">
                                <SignaloColorPicker
                                    class="color-picker"
                                    v-model="terminalPrimaryColor"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </div>
        </template>
        <template #buttons>
            <v-btn @click="() => closeDialog()">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                variant="elevated"
                :loading="dataSaving"
                color="primary"
                @click="saveData"
                :disabled="
                    !authStore.tenant.supportedLocales.every(
                        (sl) => names[sl]
                    ) ||
                    !(terminalMode?.value === 'issues' ||
                    terminalMode?.value === 'simplified_handling'
                        ? issue_terminal_onEveryHiddenAndRequiredFieldHasDefaultValue
                        : true)
                "
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';
import * as DATA_STORE from 'signalo-vue-data-store';

import TERMINAL_MODES from '@/CONSTANTS/TERMINAL_MODES';
import StorageTerminalDefaultValues from './StorageTerminalDefaultValues.vue';
import IssuesPerCategoryDefaults from '@/views/temp/IssuesDefaults/IssuesPerCategoryDefaults.vue';
// import InstructionRevisionPicker from '@/components/InstructionRevisionPicker.vue';
export default {
    props: ['mode', 'editedItem'],
    setup() {
        const authStore = AUTH_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        const dataStore = DATA_STORE.default();
        return { authStore, helpersStore, dataStore };
    },
    data() {
        return {
            dataSaving: false,
            rules: [(v) => !!v || this.$t('common.fieldRequired')],
            dataValid: true,
            names: {},
            defaultView: null,
            terminalMode: null,
            barcode: this.editedItem?.barcode,
            barcodeLoaded: true,
            lines: [],
            lock_pin: this.editedItem?.lock_pin || false,
            terminalPrimaryColor: this.editedItem?.attributes?.primaryColor,
            terminalAttributes: this?.editedItem?.attributes
                ? { ...this.editedItem.attributes }
                : null,
            toolsReturnRequireApprove:
                this.editedItem?.attributes?.tools_require_return_approve ==
                false
                    ? false
                    : true,
            tagsPairingShowCodeOwnerName:
                !!this.editedItem?.attributes
                    ?.tags_pairing_show_code_owner_name,
            showUsernameInCart:
                !!this.editedItem?.attributes?.show_username_in_cart,
            selectedInstructionGroupsPaths:
                this.editedItem?.attributes
                    ?.available_instruction_groups_paths || [],
            storageKeyname: this.editedItem?.attributes?.storage_keyname,
            partsReturnRequireApprove:
                !!this.editedItem?.attributes?.parts_require_return_approve,
            selectedCategoriesPaths:
                this.editedItem?.attributes?.allowed_categories_paths || [],
            terminalPaddingBottom:
                this.editedItem?.attributes?.padding_bottom || [],
            issuesTerminalDefaults:
                this.editedItem?.attributes?.issueDefaults || {},
            issuesTerminalHiddenFields:
                this.editedItem?.attributes?.issue_hidden_fields || [],
            issuesTerminalRequiredFields:
                this.editedItem?.attributes?.issue_required_fields || [],
            storageTerminalDefaults:
                this.editedItem?.attributes?.storage_terminal_defaults,
            storageTerminalHiddenFields:
                this.editedItem?.attributes?.storage_terminal_hidden_fields,
            storageTerminalClientSignatureEnabled:
                this.editedItem?.attributes
                    ?.storage_terminal_client_signature_enabled,
            storageTerminalClientSignatureRequired:
                this.editedItem?.attributes
                    ?.storage_terminal_client_signature_required,
            storageTerminalWarehousemanSignatureEnabled:
                this.editedItem?.attributes
                    ?.storage_terminal_warehouseman_signature_enabled,
            storageTerminalWarehousemanSignatureRequired:
                this.editedItem?.attributes
                    ?.storage_terminal_warehouseman_signature_required,
            storageTerminalClientReturnSignatureEnabled:
                this.editedItem?.attributes
                    ?.storage_terminal_client_return_signature_enabled,
            storageTerminalClientReturnSignatureRequired:
                this.editedItem?.attributes
                    ?.storage_terminal_client_return_signature_required,
            storageTerminalWarehousemanReturnSignatureEnabled:
                this.editedItem?.attributes
                    ?.storage_terminal_warehouseman_return_signature_enabled,
            storageTerminalWarehousemanReturnSignatureRequired:
                this.editedItem?.attributes
                    ?.storage_terminal_warehouseman_return_signature_required,
            issuesTerminalFlatCategoryPicker:
                this.editedItem?.attributes?.flat_category_picker,
            issue_terminal_inheritAllDefaultValues:
                this.editedItem?.attributes?.inherit_all_default_values,
            issue_terminal_categoryPath: null,
            issue_terminal_onEveryHiddenAndRequiredFieldHasDefaultValue: true,
            simplified_handling_terminal_selectWorkArea:
                this.editedItem?.attributes?.selectWorkArea,
            simplified_handling_terminal_hideSideMenu:
                this.editedItem?.attributes?.hideSideMenu,
            simplified_handling_hide_work_areas_button:
                this.editedItem?.attributes?.hideWorkAreasButton,
            simplified_handling_hide_classic_view_button:
                this.editedItem?.attributes?.hideClassicViewButton,
            simplified_handling_hide_add_issue_button:
                this.editedItem?.attributes?.hideAddIssueButton,
            simplified_display_params_on_main_tile:
                this.editedItem?.attributes
                    ?.simplified_display_params_on_main_tile,
            simplified_new_issue_ignore_filters:
                this.editedItem?.attributes
                    ?.simplified_new_issue_ignore_filters,
            work_time_register_terminal_farewellText:
                this.editedItem?.attributes?.farewellText,
            work_time_register_terminal_noteEnabled:
                this.editedItem?.attributes?.noteEnabled || false,
            production_terminal_defaultInstructionRevision:
                this.editedItem?.attributes?.defaultInstructionRevision ||
                false,
            production_terminal_prodLines:
                this.editedItem?.attributes?.prodLines || [],
            production_terminal_default_prod_line:
                this?.editedItem?.attributes?.[
                    'production_terminal_default_prod_line'
                ] || null,
            local:
                this.editedItem?.attributes?.local === undefined
                    ? true
                    : this.editedItem?.attributes?.local,
        };
    },
    computed: {
        isPinCodeCheckboxDisabled() {
            if (this.editedItem?.id) {
                return !this.authStore.isAllowed(
                    'terminals.pin-locking',
                    'update'
                );
            }
            return !this.authStore.isAllowed('terminals.pin-locking', 'create');
        },
        terminalDefaultScreenOptions() {
            return this.$router.options.routes.filter(
                (r) =>
                    r.meta.availableAsTerminalDefaultView &&
                    !(
                        r.name === 'storageCart' ||
                        r.name === 'tools_cart' ||
                        r.name === 'terminal-instructions'
                    )
            );
        },
        terminalModes() {
            return TERMINAL_MODES;
        },
        availableInstructionGroups() {
            //JEŚLI MA UPRAWNIENIE DO np 1.1 to automatycznie ma uprawnienie do wszystkich dzieci;
            return [
                ...Object.values(
                    this.dataStore.dictionary
                        .group('instruction_group')
                        ?.filter((d) => d.pickable)
                ),
            ].filter(
                (d) =>
                    !this.selectedInstructionGroupsPaths?.find(
                        (selectedPath) =>
                            String(d.path).startsWith(selectedPath) &&
                            d.path !== selectedPath
                    )
            );
        },
        storagesOptions() {
            return this.dataStore.dictionary
                .group('storages')
                ?.filter((d) => d.pickable);
        },
        categoriesOptions() {
            return [...Object.values(this.dataStore.categories.items)].filter(
                (c) =>
                    !this.selectedCategoriesPaths.find(
                        (selectedPath) =>
                            String(c.path).startsWith(selectedPath) &&
                            c.path !== selectedPath
                    )
            );
        },
    },
    mounted() {
        this.loadLines();
        this.dataStore.dictionary.promise();
        this.dataStore.categories.promise();
        if (this.editedItem?.id) {
            this.loadItemBarcode(this.editedItem.id);
        }
        if (this.editedItem.name) {
            this.names = this.editedItem.name;
        }
        if (this.editedItem.pin) {
            this.pin = this.editedItem.pin;
        }
        if (this.editedItem?.attributes?.default_view) {
            this.defaultView = this.editedItem.attributes.default_view;
        }
        if (this.editedItem.terminal_mode) {
            this.terminalMode = this.terminalModes.find(
                (tm) => tm.value === this.editedItem.terminal_mode
            );
        }
    },
    methods: {
        async loadLines() {
            try {
                const linesData = await this.axios.get('lines');
                this.lines = linesData?.data?.items || [];
            } catch (err) {
                console.err(err);
            }
        },
        async loadItemBarcode(id) {
            this.barcodeLoaded = false;
            const terminalData = await this.axios.get(`terminals/${id}`);
            if (terminalData?.data?.item?.barcode) {
                this.barcode = terminalData.data.item.barcode;
                this.barcodeLoaded = true;
            }
        },
        async saveData() {
            await this.$refs.dataForm.validate();
            if (!this.dataValid) return;
            const data = {
                name: this.names,
                attributes: {
                    ...this.terminalAttributes,
                    default_view:
                        this.defaultView ||
                        this.terminalAttributes?.default_view,
                    primaryColor:
                        this.terminalPrimaryColor ||
                        this.terminalAttributes?.primaryColor,
                },
                terminal_mode: this.terminalMode?.value,
            };
            if (
                this.terminalMode?.value === 'tools' ||
                this.terminalMode.value === 'storage'
            ) {
                data.attributes['tags_pairing_show_code_owner_name'] =
                    this.tagsPairingShowCodeOwnerName;
                data.attributes['show_username_in_cart'] =
                    this.showUsernameInCart;
            }
            if (this.terminalMode?.value === 'tools') {
                data.attributes['tools_require_return_approve'] =
                    this.toolsReturnRequireApprove;
            }
            if (this.terminalMode?.value === 'instructions') {
                data.attributes['available_instruction_groups_paths'] =
                    this.selectedInstructionGroupsPaths;
            }
            if (this.terminalMode?.value === 'storage') {
                data.attributes['storage_keyname'] = this.storageKeyname;
                data.attributes['parts_require_return_approve'] =
                    this.partsReturnRequireApprove;
                data.attributes['storage_terminal_defaults'] =
                    this.storageTerminalDefaults;
                data.attributes['storage_terminal_hidden_fields'] =
                    this.storageTerminalHiddenFields;
                data.attributes['storage_terminal_client_signature_enabled'] =
                    this.storageTerminalClientSignatureEnabled;
                data.attributes['storage_terminal_client_signature_required'] =
                    this.storageTerminalClientSignatureRequired;
                data.attributes[
                    'storage_terminal_warehouseman_signature_enabled'
                ] = this.storageTerminalWarehousemanSignatureEnabled;
                data.attributes[
                    'storage_terminal_warehouseman_signature_required'
                ] = this.storageTerminalWarehousemanSignatureRequired;
                data.attributes[
                    'storage_terminal_client_return_signature_enabled'
                ] = this.storageTerminalClientReturnSignatureEnabled;
                data.attributes[
                    'storage_terminal_client_return_signature_required'
                ] = this.storageTerminalClientReturnSignatureRequired;
                data.attributes[
                    'storage_terminal_warehouseman_return_signature_enabled'
                ] = this.storageTerminalWarehousemanReturnSignatureEnabled;
                data.attributes[
                    'storage_terminal_warehouseman_return_signature_required'
                ] = this.storageTerminalWarehousemanReturnSignatureRequired;
            }
            if (
                this.terminalMode?.value === 'issues' ||
                this.terminalMode?.value === 'simplified_handling' ||
                this.terminalMode?.value === 'production'
            ) {
                data.attributes['local'] = this.local;
                data.attributes['allowed_categories_paths'] =
                    this.selectedCategoriesPaths;
                data.attributes['padding_bottom'] = this.terminalPaddingBottom;
                //issueDefaults musi się tak samo nazywać jak w atrybutach tenanta;
                data.attributes['issueDefaults'] = {
                    ...this.issuesTerminalDefaults,
                };
                data.attributes['flat_category_picker'] =
                    this.issuesTerminalFlatCategoryPicker;
                data.attributes['inherit_all_default_values'] =
                    this.issue_terminal_inheritAllDefaultValues;
            }
            if (this.terminalMode?.value === 'simplified_handling') {
                data.attributes['selectWorkArea'] =
                    this.simplified_handling_terminal_selectWorkArea;
                data.attributes['hideSideMenu'] =
                    this.simplified_handling_terminal_hideSideMenu;
                data.attributes['hideWorkAreasButton'] =
                    this.simplified_handling_hide_work_areas_button;
                data.attributes['hideClassicViewButton'] =
                    this.simplified_handling_hide_classic_view_button;
                data.attributes['hideAddIssueButton'] =
                    this.simplified_handling_hide_add_issue_button;
                data.attributes['simplified_display_params_on_main_tile'] =
                    this.simplified_display_params_on_main_tile;
                data.attributes['simplified_new_issue_ignore_filters'] =
                    this.simplified_new_issue_ignore_filters;
            }

            if (this.terminalMode?.value === 'work_time_register') {
                data.attributes['fareWellText'] =
                    this.work_time_register_terminal_farewellText;
                data.attributes['noteEnabled'] =
                    this.work_time_register_terminal_noteEnabled;
            }

            if (this.terminalMode?.value === 'production') {
                data.attributes['hideSideMenu'] = true;
                data.attributes['available_instruction_groups_paths'] =
                    this.selectedInstructionGroupsPaths;
                data.attributes['defaultInstructionRevision'] =
                    this.production_terminal_defaultInstructionRevision;
                data.attributes['prodLines'] =
                    this.production_terminal_prodLines;
                data.attributes['production_terminal_default_prod_line'] =
                    this.production_terminal_default_prod_line;
            }
            if (this.terminalMode?.value === 'visit_guard') {
                data.attributes['hideSideMenu'] = true;
            }

            if (!data.attributes['default_view']) {
                data.attributes['default_view'] = this.terminalModes.find(
                    (tm) =>
                        tm.value ===
                        (this.terminalMode?.value ||
                            this.editedItem?.terminal_mode)
                )?.view;
            }

            if (this.barcode?.valid) {
                data.barcode = this.barcode;
            }
            let url = 'terminals';
            let method = 'POST';
            if (
                this.editedItem?.id !== undefined &&
                this.editedItem.id !== null
            ) {
                (method = 'PUT'), (url += '/' + this.editedItem.id);
            }
            if (
                this.authStore.isAllowed('terminals.pin-locking', 'create') ||
                this.authStore.isAllowed('terminals.pin-locking', 'update')
            ) {
                data.lock_pin = this.lock_pin;
            }
            try {
                await this.axios.request({
                    url,
                    method,
                    data,
                });
                this.helpersStore.snackbarSaved();
                this.closeDialog(true);
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
        closeDialog(refetchData = false) {
            this.$emit('closeDialog', refetchData);
        },
        onUpdateTerminalMode(val) {
            const defaultView = this.terminalModes.find(
                (tm) => tm.value === val?.value
            )?.view;
            this.terminalAttributes = {};
            if (defaultView) {
                this.defaultView = defaultView;
            } else {
                this.defaultView = null;
            }
            if (val !== 'instructions') {
                this.selectedInstructionGroupsPaths = [];
            }
        },
        onBarcodeChangeHandler(val) {
            this.barcode = val;
        },
        toggleCategorySelection(path) {
            const index = this.selectedCategoriesPaths.indexOf(path);
            if (index > -1) {
                this.selectedCategoriesPaths.splice(index, 1);
            } else {
                this.selectedCategoriesPaths =
                    this.selectedCategoriesPaths.filter(
                        (p) => !String(p).startsWith(path)
                    );
                this.selectedCategoriesPaths.push(path);
            }
        },
        onEveryHiddenAndRequiredFieldHasDefaultValue(val) {
            this.issue_terminal_onEveryHiddenAndRequiredFieldHasDefaultValue =
                val;
        },
        onUpdateDefaults(val, categoryPath) {
            if (
                !this
                    .issue_terminal_onEveryHiddenAndRequiredFieldHasDefaultValue ||
                !categoryPath
            )
                return;
            this.issue_terminal_categoryPath = categoryPath;
            this.issuesTerminalDefaults[categoryPath] = { ...val };
        },
    },
    watch: {
        selectedInstructionGroupsPaths(val) {
            if (this.terminalMode?.value === 'production') {
                const revisionAllowed = Boolean(
                    this.production_terminal_defaultInstructionRevision
                        ?.group_path &&
                        val.find((path) =>
                            this.production_terminal_defaultInstructionRevision?.group_path.startsWith(
                                path
                            )
                        )
                );
                if (!revisionAllowed) {
                    this.production_terminal_defaultInstructionRevision = null;
                }
            }

            const lastSelected = val[val.length - 1];
            if (!lastSelected) return [];
            const allChildsOfSelected =
                this.selectedInstructionGroupsPaths.filter(
                    (path) =>
                        String(path).startsWith(lastSelected) &&
                        path !== lastSelected
                );
            if (allChildsOfSelected?.length) {
                this.selectedInstructionGroupsPaths =
                    this.selectedInstructionGroupsPaths.filter(
                        (path) => !allChildsOfSelected.includes(path)
                    );
            }
        },
        production_terminal_prodLines(val) {
            if (!val.includes(this.production_terminal_default_prod_line)) {
                this.production_terminal_default_prod_line = null;
            }
        },
    },
    components: {
        StorageTerminalDefaultValues,
        IssuesPerCategoryDefaults,
        // InstructionRevisionPicker,
    },
};
</script>

<style scoped>
.category-color {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    margin-right: 4px;
}
.category-item-chip {
    padding-left: 0 !important;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
</style>

<style>
.category-v-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 0;
}
.category-v-list-item .v-list-item__content {
    display: flex !important;
    align-items: center;
}
</style>
