<template>
    <QuizTextAnswer
        v-if="field.question_type === $QUESTION_TYPES_ENUM.TEXT"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.quizPreview?.type !== 'statement'"
    />
    <QuizCheckAnswer
        v-else-if="field.question_type === $QUESTION_TYPES_ENUM.CHECK"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.quizPreview?.type !== 'statement'"
    />
    <QuizRadioAnswer
        v-else-if="field.question_type === $QUESTION_TYPES_ENUM.RADIO"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.quizPreview?.type !== 'statement'"
    />
    <QuizRatingAnswer
        v-else-if="field.question_type === $QUESTION_TYPES_ENUM.RATING"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.quizPreview?.type !== 'statement'"
    />
    <QuizLongTextAnswer
        v-else-if="field.question_type === $QUESTION_TYPES_ENUM.LONGTEXT"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.quizPreview?.type !== 'statement' || field?.disabled"
    />
    <QuizNumberAnswer
        v-else-if="field.question_type === $QUESTION_TYPES_ENUM.NUMBER"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.quizPreview?.type !== 'statement'"
    />

    <QuizImageAnswer
        v-else-if="field.question_type === $QUESTION_TYPES_ENUM.PHOTO"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.quizPreview?.type !== 'statement'"
    />

    <QuizDocumentAnswer
        v-else-if="field.question_type === $QUESTION_TYPES_ENUM.DOCUMENT"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.quizPreview?.type !== 'statement'"
    />

    <QuizSignatureAnswer
        v-else-if="field.question_type === $QUESTION_TYPES_ENUM.SIGNATURE"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.quizPreview?.type !== 'statement'"
        :userContext="userContext"
    />


</template>

<script>
import QuizCheckAnswer from './QuizCheckAnswer.vue';
import QuizRadioAnswer from './QuizRadioAnswer.vue';
import QuizTextAnswer from './QuizTextAnswer.vue';
import QuizRatingAnswer from './QuizRatingAnswer.vue';
import QuizLongTextAnswer from './QuizLongTextAnswer.vue';
import QuizNumberAnswer from './QuizNumberAnswer.vue';
import QuizImageAnswer from './QuizImageAnswer.vue';
import QuizSignatureAnswer from './QuizSignatureAnswer.vue';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import QuizDocumentAnswer from './QuizDocumentAnswer.vue';

export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    props: ['field', 'userContext'],
    emits: ['answer'],
    components: {
    QuizTextAnswer,
    QuizCheckAnswer,
    QuizRadioAnswer,
    QuizRatingAnswer,
    QuizLongTextAnswer,
    QuizNumberAnswer,
    QuizImageAnswer,
    QuizSignatureAnswer,
    QuizDocumentAnswer
},
    methods: {
        onAnswer(val) {
            this.$emit('answer', val);
        },
    },
};
</script>
