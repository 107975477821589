<template>
    <v-text-field
        v-model="value"
        @click:control="controlClicked"
        @click:clear="clearClicked"
        clearable
        clear-icon="mdi-close-circle-outline"
        variant="outlined"
        :label="$t('cluer.answer')"
        :class="field?.required ? 'required-field': ''"
        :disabled="disabled"
    >
    </v-text-field>
</template>

<script>
export default {
    props: ['field', 'disabled'],
    emits: ['answer'],
    data() {
        return {
            value: this.field?.answer || null,
        };
    },
    watch: {
        value(val) {
            this.$emit('answer', val);
        }
    }
};
</script>
