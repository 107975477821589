<template>
    <v-card class="mt-3 mx-3 mb-3 issue-documents">
        <template v-slot:title>
            <v-toolbar class="preview-header">
                <v-icon class="preview-header-icon"
                    >mdi-image-multiple-outline</v-icon
                >
                {{ $t('common.documents') }}
                <SignaloDynamicMenu
                    :menuItems="actions"
                    style="margin-left: auto"
                    v-if="
                        checkAllowUpdate
                            ? (authStore?.tenant?.attributes?.issue_update_documents || authStore?.isAllowed('issues.details', 'update'))
                            : true
                    "
                />
            </v-toolbar>
        </template>
        <SignaloFilesDocumentsContainer
            ref="filesDocumentsContainer"
            :endpoint="`/issues/${item.id}/documents`"
            :allow-upload="!disableEdit"
            :allow-remove="!disableEdit"
            :allow-edit="!disableEdit"
            :hideAddButton="true"
        />
    </v-card>
</template>
<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';

export default {
    setup() {
        const authStore = AUTH_STORE.default();
        return { authStore };
    },
    props: ['item', 'checkAllowUpdate', 'disableEdit'],
    computed: {
        actions() {
            return [
                {
                    text: this.$t('common.add'),
                    icon: 'mdi-plus',
                    action: () =>
                        this.$refs.filesDocumentsContainer.onFileUpload(),
                    access: [!this.disableEdit, !this.authStore.isTerminal]
                },
            ];
        },
    },
};
</script>

<style>
.issue-documents .wopi-document-preview-container {
    min-height: 600px;
}
</style>