<template>
    <SignaloAppBar
        search
        v-model:searchOpen="searchOpen"
        :searchFieldCount="searchFieldCount"
    >
        <template #title> {{ $t('notes.notes') }} </template>
        <template #bar>
            <v-btn @click="reloadClicked" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-btn
                @click="onCreate"
                v-if="$route.meta.canCreate"
                color="primary"
                variant="elevated"
                class="add-button"
            >
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloSearchContainer
            v-model:open="searchOpen"
            :config="searchConfig"
            @search="loadTable"
            v-model:fieldCount="searchFieldCount"
        />
        <SignaloBreadCrumbs />
        <PageLoading v-if="loading" />
        <div v-else>
            <EasyDataTable buttons-pagination
                :headers="tableHeaders"
                :items="items"
                :empty-message="$t('common.emptyTableMessage')"
                table-class-name="easy-table-style"
                @click-row="handleClickRow"
                
                :rows-per-page-message="$t('common.rowsPerPage')"
                :rows-of-page-separator-message="$t('common.rowsOfPage')"
            >
                <template #item-name="item">
                    {{ $getPropertyTranslation(item.name) }}
                </template>
                <template #item-archived="item">
                    <v-icon v-if="item?.archived">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                </template>
                <template #item-operation="item">
                    <div class="actions-column">
                        <v-menu offset-y v-if="$route.meta.canUpdate || $route.meta.canDelete">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    icon
                                    color="primary"
                                    v-bind="props"
                                    variant="outlined"
                                    class="table-action-icon-button"
                                >
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="onEdit(item)"
                                    v-if="$route.meta.canUpdate"
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-pencil
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.edit')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="onArchive(item)"
                                    v-if="$route.meta.canDelete"
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-archive
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.archive')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </EasyDataTable>
        </div>
    </v-main>
    <ManageNote
        v-if="manageDialog"
        v-model:open="manageDialog"
        :mode="dialogMode"
        :editedItem="editedItem"
        @closeDialog="onCloseDialogHandler"
    />
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import ManageNote from './ManageNote.vue';
import axios from 'axios';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: true,
            items: [],
            manageDialog: false,
            dialogMode: null,
            editedItem: null,
            searchOpen: false,
            searchFieldCount: 0,
            lastSearch: null,
        };
    },
    computed: {
        tableHeaders() {
            return [
                { text: this.$t('tableHeaders.data'), value: 'created_at' },
                { text: this.$t('tableHeaders.title'), value: 'title' },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
        searchConfig() {
            return [
                {
                    archived_equals: {
                        type: 'yesno',
                        label: this.$t('tableHeaders.archived'),
                        cols: 4,
                        default: 0,
                    },
                },
            ];
        },
    },
    methods: {
        async loadTable(search = null) {
            if (search !== null) {
                this.lastSearch = search;
            }
            this.loading = true;
            try {
                const data = await this.axios.get('/notes', {
                    params: {
                        ...this.lastSearch,
                    },
                });
                this.items = Object.values(data.data.items);
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        onCreate() {
            this.dialogMode = 'create';
            this.manageDialog = true;
        },
        onEdit(item) {
            this.dialogMode = 'edit';
            this.editedItem = item;
            this.manageDialog = true;
        },
        async onArchive(item) {
            try {
                await axios.patch(`notes/${item.id}`, { archived: true });
                await this.loadTable();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
        async onCloseDialogHandler(refetchData) {
            if (refetchData) {
                await this.loadTable();
            }
            this.manageDialog = false;
            this.editedItem = null;
            this.dialogMode = null;
        },
        handleClickRow(row) {
            this.onEdit(row);
        },
    },
    components: { ManageNote },
};
</script>
