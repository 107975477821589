<template>
    <SignaloAppBar search v-model:searchOpen="searchOpen">
        <template #title> {{ $t('common.configuration') }} </template>
        <template #bar>
            <v-btn @click="() => loadTable()" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-btn @click="() => openDialog()" v-if="$route.meta.canCreate" class="add-button" color="primary"
                variant="elevated">
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>

    <v-main>
        <SignaloSearchContainer ref="searchContainerComponent" v-model:open="searchOpen" :config="searchConfig"
            @search="loadTable" :tableName="TABLE_NAME" />
        <SignaloBreadCrumbs />
        <EasyDataTable buttons-pagination :headers="tableHeaders" :items="items"
            :empty-message="$t('common.emptyTableMessage')" :loading="loading" table-class-name="easy-table-style"
            :rows-per-page-message="$t('common.rowsPerPage')" :rows-of-page-separator-message="$t('common.rowsOfPage')"
            v-model:server-options="serverOptions" :server-items-length="serverItemsLength">
            <template #item-name="item">
                <div class="flex">
                    <NetworkStatusDot :networkStatus="item?.network_status" />
                    <div>
                        {{ $getPropertyTranslation(item.name) }}
                    </div>
                </div>
            </template>
            <template #item-equipment="item">
                <v-icon @click="goToTerminalEquipments(item)">
                    mdi-format-list-bulleted
                </v-icon>
            </template>
            <template #item-link="item">
                {{ linkBase + item.id }}
                <v-icon @click="copyLink(item.id)"> mdi-content-copy </v-icon>
            </template>
            <template #item-operation="item">
                <div class="actions-column">
                    <v-menu offset-y v-if="$route.meta.canUpdate || $route.meta.canDelete">
                        <template v-slot:activator="{ props }">
                            <v-btn color="primary" v-bind="props" variant="outlined" icon
                                class="table-action-icon-button">
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="onQR(item)">
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-qrcode
                                    </v-icon>
                                    <span class="Text Navigation-2"> QR </span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="onEdit(item)" v-if="$route.meta.canUpdate">
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-pencil
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.edit')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="onEditPermissions(item)" v-if="$route.meta.canUpdate">
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-account-key
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('staff.editAccess')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="onRemove(item)" v-if="$route.meta.canDelete">
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-delete
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.remove')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template #item-lock_pin="item">
                <v-icon v-if="item?.lock_pin">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
        </EasyDataTable>
    </v-main>

    <TerminalManage v-if="manageDialog" v-model:open="manageDialog" :mode="dialogMode" :editedItem="item"
        @closeDialog="closeDialog" />

    <!--PREMISSIONS DIALOG-->
    <SignaloAppAccessComponent v-if="permissionsDialog" :title="$t('staff.editAccess')" :open="permissionsDialog"
        @close="closePermissionsDialog" mode="terminal" v-model="item" />

    <SignaloConfirmationDialog :dialogOpened="removeDialog" @update:dialogOpened="(value) => removeTerminal(value)" />

    <QRDialog v-if="qrContent" :qrContent="qrContent" :terminal="item" @close="onQrClose" />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';
import TerminalManage from './TerminalManage.vue';
import tenant from '@/tenant.js';
import { SEARCH_FIELD_TYPES } from 'signalo-vue-search-container';
import QRDialog from './QRDialog.vue';
import NetworkStatusDot from '@/components/NetworkStatusDot.vue';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    components: {
        TerminalManage,
        QRDialog,
        NetworkStatusDot
    },
    data() {
        return {
            TABLE_NAME: 'terminalsIndexTable',
            loading: false,
            item: null,
            manageDialog: false,
            removeDialog: false,
            searchOpen: false,
            searchFieldCount: 0,
            items: [],
            permissionsDialog: false,
            serverOptions: this.$getServerOptions('terminalsIndexTable'),
            serverItemsLength: 0,
            lastSearch: null,
            qrContent: null,
        };
    },
    computed: {
        searchConfig() {
            return {
                id_equals: {
                    type: SEARCH_FIELD_TYPES.TEXT,
                    label: this.$t('searchConfig.id'),
                    cols: 4,
                },
                name: {
                    type: SEARCH_FIELD_TYPES.TEXT,
                    label: this.$t('searchConfig.name'),
                    cols: 4,
                },
            };
        },
        linkBase() {
            return tenant.getAppUrl() + 'terminal-device/';
        },
        tableHeaders() {
            const ths = [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                },
                {
                    text: this.$t('common.link'),
                    value: 'link',
                    width: 220,
                },
                {
                    text: this.$t('common.pin'),
                    value: 'pin',
                },

            ];

            if (this.authStore.isAllowed('terminals.pin-locking', 'read')) {
                ths.push({
                    text: this.$t('common.pinLocked'),
                    value: 'lock_pin',
                },)
            }

            ths.push({ value: 'operation', width: 54, fixed: true });

            return ths
        },
    },
    methods: {
        hexEndode(str) {
            let hex = '';
            for (let i = 0; i < str.length; i++) {
                hex += str.charCodeAt(i).toString(16).padStart(2, '0');
            }
            return hex;
        },
        onQR(item) {
            this.item = item;
            let url = this.linkBase + item.id;

            if (item.lock_pin) {
                url += `?xtc=${this.hexEndode(item.pin)}&clear=true`;
            } else {
                url += `?clear=true`;
            }
            this.qrContent = url;
        },
        onQrClose() {
            this.item = null;
            this.qrContent = null;
        },
        openDialog() {
            this.item = {};
            this.manageDialog = true;
        },
        onEdit(item) {
            this.item = item;
            this.manageDialog = true;
        },
        onEditPermissions(item) {
            this.item = item;
            this.permissionsDialog = true;
        },
        goToEntries(item) {
            this.$router.push({
                name: 'settings.groups.users',
                params: { group: item.id },
            });
        },
        onRemove(item) {
            this.item = item;
            this.removeDialog = true;
        },
        closeDialog(shouldRefetchData) {
            if (shouldRefetchData) {
                this.loadTable().finally(() => {
                    this.item = null;
                    this.manageDialog = false;
                });

                return;
            }
            this.item = null;
            this.manageDialog = false;
        },
        closePermissionsDialog() {
            this.permissionsDialog = false;
            this.item = null;
        },
        loadTable(search = null) {
            if (search) {
                this.lastSearch = search;
            }
            this.loading = true;
            const options = this.dataStore.makeServerOptions(
                this.serverOptions,
                this.lastSearch
            );
            return this.axios
                .get('terminals', options)
                .then((itemsData) => {
                    this.items = itemsData?.data?.items;
                    this.serverItemsLength = itemsData?.data?.meta?.total;
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async removeTerminal(shouldRemove) {
            if (shouldRemove) {
                try {
                    await this.axios.delete(`terminals/${this.item.id}`);
                    await this.loadTable();
                    this.helpersStore.snackbarSaved();
                } catch (err) {
                    if (err?.response.status === 401) return;
                }
            }

            this.item = null;
            this.removeDialog = false;
        },
        goToTerminalEquipments(item) {
            this.$router.push({
                name: 'settings.terminalEquipment',
                params: { id: item.id },
            });
        },
        copyLink(id) {
            this.helpersStore.copyToClipboard(this.linkBase + id);
        },
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences(this.TABLE_NAME, {
                default_size: val.rowsPerPage,
            });
            this.loadTable(this.lastSearch, false);
        },
    },
};
</script>
