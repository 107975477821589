<template>
    <SignaloDialog
        :open="open"
        :title="title"
        @close="() => closeDialog(false)"
        :constHeight="false"
        :dialogClasses="['reject-reason-dialog']"
    >
        <template #body>
            <v-form
                ref="rejectionReasonForm"
                v-model="dataValid"
                lazy-validation
            >
                <v-col cols="12" md="12" xl="12">
                    <v-textarea
                        :label="$t('common.comment')"
                        variant="outlined"
                        v-model="comment"
                    ></v-textarea>
                </v-col>
            </v-form>
        </template>
        <template #buttons>
            <v-btn @click="closeDialog(false)">{{ $t('common.cancel') }}</v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="save"
                :loading="saveLoading"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store'; 

export default {
    setup() {
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        return { helpersStore };
    },
    props: ['open', 'title', 'issueId'],
    emits: ['update:open', 'reload'],
    data() {
        return {
            dataValid: false,
            comment: null,
            saveLoading: false,
            required: [(v) => !!v || this.$t('common.fieldRequired')],
        };
    },
    methods: { 
        async save() {
            await this.$refs?.rejectionReasonForm.validate();
            if (!this.dataValid) return;
            this.saveLoading = true;
            
            try {
                await this.axios
                    .post(`/me/issues/${this.issueId}/finish`, {
                        comment: this.comment,
                    })
                    .then(() => {
                        this.helpersStore.snackbarSaved();
                        this.closeDialog(true)
                    })
                    .catch((e) => {
                        if (e?.response?.status == 401) return;
                        this.saveLoading = false;
                    });
                
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.saveLoading = false;
        },
        closeDialog(shouldRefetch = false) {
            if (shouldRefetch) this.$emit('reload');
            this.$emit('update:open', false);
        },
    },
};
</script>
<style>
.reject-reason-dialog {
    max-width: 600px;
}
</style>
