import i18n from '@/i18n';

export function getTaktTimeLabel(valueInSeconds) {
    if (!valueInSeconds) return '';
    const ONE_DAY_IN_SECONDS = 1440 * 60;
    const ONE_HOUR_IN_SECONDS = 60 * 60;
    const ONE_MINUTE_IN_SECONDS = 60;
    if (valueInSeconds >= ONE_DAY_IN_SECONDS) {
        const valueInDays = Math.floor(valueInSeconds / ONE_DAY_IN_SECONDS);
        let translationString = 'common.day';
        if (valueInDays > 1) {
            translationString += 's';
        }
        let rest = '';
        if (valueInSeconds % ONE_DAY_IN_SECONDS !== 0) {
            rest = getTaktTimeLabel(valueInSeconds % ONE_DAY_IN_SECONDS);
        }
        return valueInDays + ' ' + i18n.global.t(translationString) + ' ' + rest;
    } else if (
        valueInSeconds >= ONE_HOUR_IN_SECONDS
    ) {
        const valueInHours = Math.floor(valueInSeconds / ONE_HOUR_IN_SECONDS);
        let translationString = 'common.hour';
        if (valueInHours > 1) {
            translationString += 's';
        }
        let rest = '';
        if (valueInSeconds % ONE_HOUR_IN_SECONDS !== 0) {
            rest = getTaktTimeLabel(valueInSeconds % ONE_HOUR_IN_SECONDS);
        }
        return valueInHours + ' ' + i18n.global.t(translationString) + ' ' + rest;
    } else if (valueInSeconds >= ONE_MINUTE_IN_SECONDS) {
        const valueInMinutes = Math.floor(
            valueInSeconds / ONE_MINUTE_IN_SECONDS
        );
        let translationString = 'common.minute';
        if (valueInMinutes > 1) {
            translationString += 's';
        }
        let rest = '';
        if (valueInSeconds % 60 !== 0) {
            rest = getTaktTimeLabel(valueInSeconds % 60);
        }
        return (
            valueInMinutes + ' ' + i18n.global.t(translationString) + ' ' + rest
        );
    } else {
        let translationString = 'common.second';
        return valueInSeconds + ' ' + i18n.global.t(translationString);
    }
}
