<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.configuration') }} </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs :data="breadcrumb"/>
        <PageLoading v-if="loading"/>
        <PageError v-else-if="!template" :error-message="$t('common.notFound')"/>

        <v-card v-else>
            <SignaloNotificationsManagementIndex :mode="'template'" :templateId="$route?.params?.id"/>
        </v-card>
    </v-main>
</template>

<script>
import PageError from '@/components/global/PageError.vue';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: true,
            template: null
        };
    },
    mounted() {
        this.loadTemplate()
    },
    methods: {
        async loadTemplate() {
            if (!this.$route?.params?.id) throw new Error("no id provided");
            this.loading = true;
            try {
                const templateData = await this.axios.get(`user_templates/${this.$route.params.id}`);
                this.template = templateData.data.item;

            } catch (err) {
                this.helpersStore.snackbarError(err);
            }
            this.loading = false;
        }
    },
    computed: {
        breadcrumb() {
            let res = {};
            if (this.template) {
                res.id = {
                    text: `#${this.$route.params.id} - ${this.$getPropertyTranslation(this.template.name)}`,
                    value: this.$route.params.id,
                };

            } else {
                res.id = {
                    text: `#${this.$route.params.id}`,
                    value: this.$route.params.id,
                };
            }
            return res;
        }
    },
    components: { PageError }
};
</script>
