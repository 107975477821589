<style>
.v-card-text.issue-general-info-container {
    padding: 0;
    margin: 0;
}

.tabs-toggle-buttons-container {
    padding: 16px;
}

.issue-name-and-category-container {
    font-size: 16px;
    margin-bottom: 12px;
}

.issue-name-and-category-container .issue-category-info,
.issue-name-and-category-container .category-info,
.issue-name-and-category-container .category-info .issue-info .issue-name {
    display: flex;
    align-items: center;
}

.issue-name-and-category-container .category-info .category-icon {
    font-size: 64px;
}

.issue-another-general-info {
    border: 1px solid rgb(204, 204, 204);
    padding: 16px;
    border-radius: 4px;
}

.issue-another-general-info section {
    margin-bottom: 16px;
}

.issue-category-info {
    font-size: 12px;
}

.cost-calculator-card {
    overflow: visible !important;
}

.issue-card {
    padding: 8px !important;
}

@media screen and (max-width: 390px) {
    .formal-confirmation-button .formal-confirmation-button-label {
        font-size: 12px;
    }
}

.change-responsible-person-button {
    margin-left: auto;
}

.responsible-user-title-container {
    display: flex;
}

.calculator-image-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeline-image {
    width: 100%;
}

.rotate-screen-info {
    font-size: 32px;
    text-align: center;
    display: none;
}

@media screen and (orientation: portrait) {
    .timeline-image {
        display: none;
    }
}

@media screen and (orientation: portrait) {
    .rotate-screen-info {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}
</style>

<template>
    <SignaloAppBar>
        <template #title v-if="!helpersStore.isMobileView">
            {{ $t('issues.issues') }}
        </template>
        <template #bar>
            <v-btn @click="load" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <!-- <v-btn
                variant="elevated"
                color="primary"
                class="renew-button"
                @click="onExport"
                v-if="helpersStore.hacked"
            >
                <v-icon> mdi-file-download </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.export') }}
                </span>
            </v-btn> -->
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs :data="breadcrumb" />
        <PageLoading v-if="loading" />
        <PageError v-else-if="error" :errorMessage="errorMessage" />
        <PageNotFound v-else-if="!item" />
        <div v-else>
            <v-card class="mt-3 mx-3 mb-3 issue-card">
                <PageLoading v-if="loading" />
                <v-card-text v-else class="issue-general-info-container">
                    <div v-if="item?.deleted_at" class="Text HeadingH7-2430">
                        {{ $t('common.archived') }}
                    </div>
                    <v-row class="issue-name-and-category-container">
                        <v-col cols="12" md="6" xl="6">
                            <div
                                class="mb-1 ps-1 category-info"
                                :style="
                                    item.category?.color
                                        ? 'border-left: 12px solid ' +
                                          item.category.color
                                        : ''
                                "
                            >
                                <v-icon
                                    v-if="item.category?.icon"
                                    :icon="item.category.icon"
                                    class="category-icon"
                                />
                                <div class="issue-info">
                                    <div class="issue-name">
                                        <v-text-field
                                            v-show="editingField === 'title'"
                                            v-model="editingValue"
                                            :loading="editingIsSaving"
                                            :rules="titleRules"
                                            variant="outlined"
                                        />
                                        <p
                                            v-if="editingField !== 'title'"
                                            class="mb-1"
                                            v-html="
                                                (currentLocale
                                                    ? $getPropertyTranslation(
                                                          item?.attributes
                                                              ?.translations
                                                              ?.title,
                                                          currentLocale,
                                                          item?.attributes
                                                              ?.auto_translate,
                                                          'title'
                                                      )
                                                    : item?.title) || '-'
                                            "
                                        />
                                        <EditBar
                                            :disabled="
                                                item?.deleted_at ||
                                                !isAllowedToIssueAction(
                                                    'update'
                                                )
                                            "
                                            field="title"
                                            v-model="editingField"
                                            @save="saveField"
                                            :saveDisabled="
                                                editingField === 'title' &&
                                                (!editingValue ||
                                                    editingValue?.length > 400)
                                            "
                                        />
                                    </div>
                                    <div class="issue-category-info">
                                        {{ categoryPath }}
                                        <SignaloCategoryPicker
                                            v-if="!item?.deleted_at"
                                            v-model="item.category_id"
                                            @update:modelValue="
                                                saveItem(true, 'category_id')
                                            "
                                            v-slot="props"
                                            class="issue-category-picker"
                                            :source="
                                                dataStore
                                                    ?.categories_alter_structure
                                                    ?.items
                                            "
                                        >
                                            <EditBar
                                                :disabled="
                                                    item?.deleted_at ||
                                                    !isAllowedToIssueAction(
                                                        'update'
                                                    )
                                                "
                                                custom
                                                field="category_id"
                                                v-model="editingField"
                                                @save="saveField"
                                                @click:edit="props.onClick"
                                            />
                                        </SignaloCategoryPicker>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" md="2" xl="2">
                            <div
                                class="translation-selector-issue-container"
                                v-if="item?.attributes?.translations"
                            >
                                <SignaloTranslationSelector
                                    v-if="availableLocales"
                                    :availableLocales="availableLocales"
                                    @localeChange="
                                        (val) => (currentLocale = val)
                                    "
                                />
                            </div>
                        </v-col>
                    </v-row>
                    <div class="issue-another-general-info">
                        <v-row>
                            <v-col cols="12" md="12" xl="12">
                                <section>
                                    <h4 class="mb-1">
                                        {{ $t('issues.status') }}
                                        <v-tooltip class="ms-2" bottom>
                                            <template
                                                v-slot:activator="{ props }"
                                            >
                                                <v-icon
                                                    color="primary"
                                                    dark
                                                    v-bind="props"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </template>
                                            <span>{{
                                                $getPropertyTranslation(
                                                    item.flow?.name
                                                ) || $t('issues.noFlow')
                                            }}</span>
                                        </v-tooltip>
                                    </h4>
                                    <v-select
                                        v-if="item.flow_state"
                                        :disabled="
                                            item?.deleted_at ||
                                            !isAllowedToIssueAction('update')
                                        "
                                        variant="outlined"
                                        density="compact"
                                        :items="statuses"
                                        :modelValue="{
                                            id: item.flow_state_id,
                                            name: this.$getPropertyTranslation(
                                                item.flow_state?.name
                                            ),
                                            finished:
                                                item?.flow_state?.resolved,
                                        }"
                                        :loading="flowStateLoading"
                                        item-title="name"
                                        item-value="id"
                                        @update:modelValue="(x) => setState(x)"
                                        :no-data-text="
                                            $t('common.emptyTableMessage')
                                        "
                                    />
                                    <p v-else class="mb-1">-</p>
                                </section>
                                <section>
                                    <div
                                        class="responsible-user-title-container"
                                    >
                                        <h4 class="mb-1">
                                            {{ $t('issues.responsibleUser') }}
                                        </h4>
                                        <SignaloUserPicker
                                            @update:modelValue="
                                                responsibleUserPatch
                                            "
                                            v-slot="props"
                                            return-object
                                            v-if="
                                                authStore.isAllowed(
                                                    'issues',
                                                    'update'
                                                )
                                            "
                                            :filteredItems="[
                                                this.item?.responsible_user_id,
                                                !item?.access?.assign
                                                    ? authStore.user.id
                                                    : null,
                                            ]"
                                            :ormWithcountEnabled="false"
                                            :category_access="item?.category_id"
                                        >
                                            <v-btn
                                                class="change-responsible-person-button"
                                                size="x-small"
                                                variant="text"
                                                icon
                                                color="primary"
                                                @click="props.onClick"
                                                :disabled="
                                                    !item?.access?.assign ||
                                                    item.responsible_user_id ===
                                                        authStore.user.id
                                                        ? !item?.access
                                                              ?.unassign
                                                        : false
                                                "
                                                ><v-icon
                                                    >mdi-pencil</v-icon
                                                ></v-btn
                                            >
                                        </SignaloUserPicker>
                                    </div>
                                    <p
                                        class="mb-1"
                                        v-if="item?.responsible_user"
                                    >
                                        <a
                                            v-if="
                                                authStore.isAllowed(
                                                    'staff',
                                                    'read'
                                                )
                                            "
                                            href="#"
                                            @click.prevent="
                                                $router.push({
                                                    name: 'staff.show',
                                                    params: {
                                                        id: item
                                                            ?.responsible_user
                                                            ?.id,
                                                    },
                                                })
                                            "
                                        >
                                            {{ item?.responsible_user?.name }}
                                        </a>
                                        <span v-else>{{
                                            item?.responsible_user?.name
                                        }}</span>
                                    </p>
                                </section>
                                <section>
                                    <EditBar
                                        :disabled="
                                            item?.deleted_at ||
                                            !isAllowedToIssueAction('update')
                                        "
                                        :title="
                                            item?.category?.planned
                                                ? $t('issues.jobDescription')
                                                : $t(
                                                      'issues.applicantsDescription'
                                                  )
                                        "
                                        field="user_description"
                                        v-model="editingField"
                                        @save="saveField"
                                    />
                                    <v-textarea
                                        v-if="
                                            editingField === 'user_description'
                                        "
                                        v-model="editingValue"
                                        :loading="editingIsSaving"
                                    />
                                    <p
                                        v-else
                                        class="mb-1"
                                        :class="
                                            shouldDisplayFieldMultiline(
                                                'user_description'
                                            )
                                                ? 'multiline'
                                                : ''
                                        "
                                        v-html="
                                            (currentLocale
                                                ? $getPropertyTranslation(
                                                      item?.attributes
                                                          ?.translations
                                                          ?.user_description,
                                                      currentLocale,
                                                      item?.attributes
                                                          ?.auto_translate,
                                                      'user_description'
                                                  )
                                                : item?.user_description) || '-'
                                        "
                                    />
                                </section>
                                <section>
                                    <SignaloEquipmentPicker
                                        :disabled="editingField !== null"
                                        @update:modelValue="
                                            (x) => {
                                                this.editingValue = x;
                                                saveField('equipment_id');
                                            }
                                        "
                                        v-slot="props"
                                    >
                                        <EditBar
                                            :title="$t('issues.equipment')"
                                            custom
                                            field="equipment_id"
                                            v-model="editingField"
                                            @save="saveField"
                                            @click:edit="props.onClick"
                                            :disabled="
                                                !isAllowedToIssueAction(
                                                    'update'
                                                )
                                            "
                                        />
                                    </SignaloEquipmentPicker>
                                    <p class="mb-1">
                                        <span v-if="item.equipment">
                                            #{{ item?.equipment?.id }}:
                                            {{ item?.equipment?.name }}&nbsp;{{
                                                item?.equipment?.symbol
                                            }}
                                        </span>
                                        <span v-else>-</span>
                                    </p>
                                </section>
                                <section
                                    v-if="
                                        authStore?.tenant?.attributes
                                            ?.issue_kind !== 'hide'
                                    "
                                >
                                    <EditBar
                                        :title="$t('tableHeaders.kind')"
                                        field="kind"
                                        v-model="editingField"
                                        @save="saveField"
                                        :disabled="
                                            !isAllowedToIssueAction('update')
                                        "
                                    />
                                    <v-select
                                        v-if="editingField === 'kind'"
                                        :items="kinds"
                                        item-title="value"
                                        item-value="keyname"
                                        v-model="editingValue"
                                        density="compact"
                                        :loading="editingIsSaving"
                                        variant="outlined"
                                        :rules="kindRules"
                                        :class="
                                            authStore?.tenant?.attributes
                                                ?.issue_kind == 'require'
                                                ? 'required-field'
                                                : ''
                                        "
                                        :clearable="
                                            authStore?.tenant?.attributes
                                                ?.issue_kind !== 'require'
                                        "
                                    />
                                    <p v-else class="mb-1">
                                        {{
                                            $getDictionaryItemName(
                                                'issue_kind',
                                                item?.kind
                                            )
                                        }}
                                    </p>
                                </section>
                                <section
                                    v-if="
                                        authStore?.tenant?.attributes
                                            ?.issue_criticality !== 'hide'
                                    "
                                >
                                    <EditBar
                                        :title="$t('issues.criticality')"
                                        field="criticality"
                                        v-model="editingField"
                                        @save="saveField"
                                        :disabled="
                                            !isAllowedToIssueAction('update')
                                        "
                                    />
                                    <v-select
                                        v-if="editingField === 'criticality'"
                                        :items="criticalities"
                                        item-title="value"
                                        item-value="keyname"
                                        v-model="editingValue"
                                        density="compact"
                                        :loading="editingIsSaving"
                                        variant="outlined"
                                        :rules="criticalityRules"
                                        :class="
                                            authStore?.tenant?.attributes
                                                ?.issue_criticality == 'require'
                                                ? 'required-field'
                                                : ''
                                        "
                                        :clearable="
                                            authStore?.tenant?.attributes
                                                ?.issue_criticality !==
                                            'require'
                                        "
                                    />
                                    <p v-else class="mb-1">
                                        {{ issueCriticality }}
                                    </p>
                                </section>
                                <section>
                                    <h4 class="mb-1">
                                        {{ $t('common.productionLine') }}
                                    </h4>
                                    <v-autocomplete
                                        v-model="item.prod_line"
                                        :items="prod_lines"
                                        variant="outlined"
                                        color="var(--greyscale-100)"
                                        item-title="value"
                                        item-value="keyname"
                                        @update:modelValue="patchProdLine"
                                        clearable
                                        clear-icon="mdi-close-circle-outline"
                                        :disabled="
                                            !isAllowedToIssueAction('update')
                                        "
                                    ></v-autocomplete>
                                </section>
                                <section
                                    v-if="
                                        true ||
                                        (requiredHelpOptions?.length &&
                                            showHelp)
                                    "
                                >
                                    <h4 class="mb-1">
                                        {{ $t('common.service') }}
                                    </h4>
                                    <v-select
                                        v-model="item.groups"
                                        :items="requiredHelpOptions"
                                        multiple
                                        variant="outlined"
                                        color="var(--greyscale-100)"
                                        :item-title="`name[${$i18n.locale}]`"
                                        item-value="id"
                                        @update:modelValue="groupClicked"
                                        :disabled="
                                            !isAllowedToIssueAction('update')
                                        "
                                    ></v-select>
                                </section>
                                <section class="parameters-section">
                                    <h4 class="mb-1">
                                        {{ $t('common.parameters') }}
                                    </h4>
                                    <SignaloItemParametersManage
                                        :categoryId="item.category_id"
                                        :modelValue="item.parameters"
                                        :allowClose="true"
                                        @update:modelValue="
                                            singleValueDialogUpdateHandler
                                        "
                                        :disabled="
                                            !isAllowedToIssueAction('update')
                                        "
                                        :disableSetDefaultValue="true"
                                        :dialogSearchable="true"
                                    />
                                </section>
                                <section v-if="item?.received_decision_at">
                                    <h4 class="mb-1">
                                        {{ $t('issues.received_decision_at') }}
                                    </h4>
                                    <p class="mb-1">
                                        {{ item?.received_decision_at }}
                                    </p>
                                </section>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-tabs color="primary" v-model="tab">
                        <v-tab
                            value="images"
                            v-if="authStore.isAllowed('issues.details', 'read')"
                            >{{ $t('common.images') }}</v-tab
                        >
                        <v-tab
                            value="documents"
                            v-if="authStore.isAllowed('issues.details', 'read')"
                            >{{ $t('common.documents') }}</v-tab
                        >
                        <v-tab
                            value="changeHistory"
                            v-if="authStore.isAllowed('logs', 'read')"
                            >{{ $t('issues.changeHistory') }}</v-tab
                        >
                    </v-tabs>
                </v-card-actions>
                <v-window v-model="tab" :touch="false" :key="tab">
                    <v-window-item
                        value="images"
                        v-if="authStore.isAllowed('issues.details', 'read')"
                    >
                        <IssueImagesComponent
                            v-if="item !== null"
                            @load="load"
                            :item="item"
                            :checkAllowUpdate="true"
                            :disabled="
                                item?.deleted_at ||
                                !isAllowedToIssueAction('update')
                            "
                        />
                    </v-window-item>
                    <v-window-item
                        value="documents"
                        v-if="authStore.isAllowed('issues.details', 'read')"
                    >
                        <IssueDocumentsComponent
                            v-if="item !== null"
                            @load="load"
                            :item="item"
                            :checkAllowUpdate="true"
                            :disableEdit="
                                item?.deleted_at ||
                                !isAllowedToIssueAction('update')
                            "
                        />
                    </v-window-item>
                    <v-window-item
                        value="changeHistory"
                        v-if="authStore.isAllowed('logs', 'read')"
                    >
                        <IssueLogCard
                            v-model="item"
                            v-model:loaded="logsLoaded"
                        />
                    </v-window-item>
                </v-window>
            </v-card>
        </div>
        <SignaloDialog
            :open="calculatorTimeline"
            :hideActions="true"
            :fullscreen="true"
            @close="calculatorTimeline = false"
            ><template #body>
                <div class="calculator-image-container">
                    <img class="timeline-image" :src="calculatorLegendSrc" />
                    <div
                        class="rotate-screen-info Text ParagraphP1-1624-Semibold"
                    >
                        <v-icon style="margin-right: 8px"
                            >mdi-phone-rotate-landscape</v-icon
                        >{{ $t('common.rotateScreen') }}
                    </div>
                </div>
            </template></SignaloDialog
        >

        <RejectionReasonDialog
            v-if="rejectionReasonOpened"
            :open="rejectionReasonOpened"
            :title="$t('common.rejectIssue')"
            @close="(v) => onRejectionReasonDialogClose(v)"
            :issueId="item.id"
        />

        <IssueResolutionDialog
            v-if="resolutionDialog"
            :open="resolutionDialog"
            :title="$t('common.summary')"
            @close="(v) => onResolutionDialogClose(v)"
            :issueId="item.id"
            :stateId="this.issueFinishedStateId"
            :version="item?.version"
        />
        <SignaloExportDialog
            v-if="exportDialog"
            :open="exportDialog"
            :endpoint="exportEndpoint"
            @close="exportDialog = false"
            :allPagesAllowed="false"
        />
    </v-main>
    <StateChangeParametersDialog
        v-if="stateChangeParametersDialogOpened"
        @close="onCloseStateChangeParametersDialog"
        :parameters="stateParameters"
        @save="(params) => patchState(stateToChangeId, params)"
    />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

import IssueLogCard from './IssueLogCardComponent.vue';
import EditBar from './EditBar.vue';

import { toMySqlDate } from 'signalo-vue-utils';

import IssueImagesComponent from './IssueImagesComponent.vue';
import IssueDocumentsComponent from './IssueDocumentsComponent.vue';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import RejectionReasonDialog from './RejectionReasonDialog.vue';
import IssueResolutionDialog from './IssueResolutionDialog.vue';
import StateChangeParametersDialog from './StateChangeParametersDialog.vue';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    components: {
        IssueLogCard,
        EditBar,
        IssueImagesComponent,
        IssueDocumentsComponent,
        RejectionReasonDialog,
        IssueResolutionDialog,
        StateChangeParametersDialog,
    },
    async mounted() {
        await this.dataStore.categories_alter_structure.promise();
        await this.loadUsers();
        await this.load();
    },
    watch: {
        editingField(val) {
            if (val) {
                this.editingValue = this.item[val];
            }
        },
        fixingDate() {
            this.buildPredictedFinish();
        },
        fixingTime() {
            this.buildPredictedFinish();
        },
        serviceStartDate() {
            this.buildServiceStart();
        },
        serviceStartTime() {
            this.buildServiceStart();
        },
        serviceEndTime() {
            this.buildServiceEnd();
        },
        serviceEndDate() {
            this.buildServiceEnd();
        },
        scheduledDate() {
            this.buildScheduledOn();
        },
        scheduledTime() {
            this.buildScheduledOn();
        },
    },
    data() {
        return {
            loading: true,
            flowStateLoading: false,
            logsLoaded: false,

            id: this.$route.params.id,
            item: null,

            editingField: null,
            editingValue: null,
            editingIsSaving: false,

            fixingDate: null,
            fixingTime: null,
            fixingValid: true,

            scheduledTime: null,
            scheduledDate: null,

            serviceStartDate: null,
            serviceStartTime: null,
            serviceStartValid: true,

            serviceEndDate: null,
            serviceEndTime: null,
            serviceEndValid: true,
            singleValueDialog: {
                paramId: null,
                title: null,
                valueType: null,
                value: null,
                open: false,
            },
            tab: 'images',
            requiredHelpModel: null,
            requiredHelpOptions: [],
            previouslySelectedRequiredHelpIds: [],
            calculatorTimeline: false,
            error: false,
            errorMessage: null,
            rejectionReason: null,
            rejectionReasonOpened: false,
            issueFinishedStateId: null,
            resolutionDialog: false,
            exportDialog: '',
            users: [],
            stateChangeParametersDialogOpened: false,
            stateParameters: null,
            stateToChangeId: null,
            availableLocales: null,
            currentLocale: null,
        };
    },
    computed: {
        kinds() {
            return (
                this.dataStore?.dictionary
                    ?.group('issue_kind')
                    ?.filter((d) => d.pickable) ?? []
            );
        },
        categoryPath() {
            return this.getCategoryPath(this.item.category_id);
        },
        breadcrumb() {
            let res = {
                id: {
                    text: `#${this.id}`,
                    value: this.id,
                },
            };
            return res;
        },
        predictedTookTime() {
            if (!this.item.predicted_took_time_s) return '-';
            let res = '';
            let s = Math.round(this.item.predicted_took_time_s / 60);
            const tab = ['min', 'h', 'd'];
            const tab2 = [60, 24];
            let ti = 0;
            for (; ti < tab2?.length; ti++) {
                let r = s % tab2[ti];
                s -= r;
                s /= tab2[ti];
                res = r + tab[ti] + ' ' + res;
            }
            res = s + tab[ti] + ' ' + res;
            return res;
        },
        statuses() {
            return (
                this.item?.transitions
                    ?.map((x) => {
                        return {
                            ...x,
                            name: this.$getPropertyTranslation(x.name),
                        };
                    })
                    ?.sort((x, y) => {
                        if (x.sort > y.sort) return 1;
                        if (x.sort < y.sort) return -1;
                        if (x.id > y.id) return 1;
                        if (x.id < y.id) return -1;
                        return 0;
                    }) || []
            );
        },
        issueCriticality() {
            const criticality = this.dataStore?.dictionary
                ?.group('issue_criticality')
                ?.find((x) => x.keyname === this.item.criticality);

            if (!criticality) return '-';
            return `${criticality.keyname} - ${criticality.value}`;
        },
        criticalities() {
            return (
                this.dataStore?.dictionary
                    ?.group('issue_criticality')
                    ?.filter((d) => d.pickable) ?? []
            );
        },
        calculatorLegendSrc() {
            const images = require.context(
                '../../assets/calculator/',
                false,
                /\.svg$/
            );
            var r = document.querySelector(':root');
            r.style.setProperty(
                '--timeline-image-url',
                'url(./' + this.$i18n.locale + '.svg)'
            );
            return images('./' + this.$i18n.locale + '.svg');
        },
        prod_lines() {
            return this.dataStore.dictionary.group('prod_lines');
        },
        titleRules() {
            return [
                (v) => !!v || this.$t('common.fieldRequired'),
                (v) => v.length <= 400 || this.$t('errors.maxLength') + ' 400',
            ];
        },
        costCalculatorActions() {
            return [
                {
                    icon: 'mdi-information-variant',
                    action: () => {
                        this.calculatorTimeline = true;
                    },
                },
            ];
        },
    },
    methods: {
        shouldDisplayFieldMultiline(fieldName) {
            return Boolean(
                this.authStore.tenant?.attributes?.issueDefaults?.[
                    this.item?.category?.path
                ]?.displayOptions?.[fieldName]?.multiline
            );
        },
        async loadUsers() {
            const usersData = await this.axios.get('users');
            this.users = usersData?.data?.items;
        },
        async loadThis() {
            this.loading = true;
            try {
                const issueData = await this.axios.get('/issues/' + this.id);

                this.item = issueData?.data?.item;
                if (this.item?.attributes?.translations) {
                    this.availableLocales = [];
                    Object.keys(this.item?.attributes?.translations).forEach(
                        (tKey) => {
                            Object.keys(
                                this.item?.attributes?.translations[tKey]
                            ).forEach((lang) => {
                                if (!this.availableLocales.includes(lang)) {
                                    this.availableLocales.push(lang);
                                }
                            });
                        }
                    );
                }
            } catch (err) {
                console.error(err);
                this.error = true;
                this.errorMessage = this.$t('errors.error');
                this.loading = false;
            }
            this.loading = false;
        },
        load() {
            this.logsLoaded = false;
            this.loading = true;
            Promise.allSettled([
                this.loadThis(),
                this.dataStore.dictionary.promise(),
                this.dataStore.categories.promise(),
                this.dataStore.groups.promise(),
                this.dataStore.dictionary.promise(),
            ])
                .then(() => {
                    this.previouslySelectedRequiredHelpIds =
                        this.item?.groups.map((g) => g.id);
                    this.requiredHelpOptions =
                        this.dataStore.groups.index.filter(
                            (g) => g.group_type === 'service'
                        );
                })
                .catch((err) => {
                    if (err?.response?.status === 500) {
                        this.error = true;
                        this.errorMessage = this.$t('errors.error');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async saveItem(handle = true, fieldName = null) {
            let item = { id: this.item.id };
            if (fieldName === null) {
                item = this.item;
            } else {
                item[fieldName] = this.item[fieldName];
            }
            item.version = this.item.version;
            const promise = this.dataStore.issues.patchRequest(item.id, item);
            if (handle) {
                promise
                    .then(() => {
                        this.helpersStore.snackbarSaved();
                        this.loadThis();
                    })
                    .catch((e) => {
                        if (e.response?.status == 401) return;
                    });
            } else {
                return promise;
            }
        },
        getCategoryPath(id) {
            const item = this.dataStore.categories.getById(id);
            if (!item) return '-';
            if (item.parent_id)
                return (
                    this.getCategoryPath(item.parent_id) +
                    ' > ' +
                    this.$getPropertyTranslation(item.name)
                );
            else return this.$getPropertyTranslation(item.name);
        },
        setFlow(flow_id) {
            this.dataStore.issues
                .setFlow(this.item.id, flow_id)
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('common.saved'),
                        'success'
                    );
                    this.loadThis();
                })
                .catch((e) => {
                    if (e.response?.status === 401) return;
                });
        },
        onCloseStateChangeParametersDialog() {
            this.stateChangeParametersDialogOpened = false;
            this.stateToChangeId = null;
            this.stateParameters = null;
        },
        async setState(state_id) {
            this.stateToChangeId = state_id;
            this.issueFinishedStateId = null;
            if (this.statuses.find((s) => s.id == state_id)?.resolved) {
                this.issueFinishedStateId = state_id;
                this.resolutionDialog = true;
                return;
            }
            const anyParametersToFill = this.item?.transitions?.find(
                (t) => t.id === state_id
            )?.parameters;

            if (anyParametersToFill?.length) {
                this.stateParameters = anyParametersToFill;
                this.stateChangeParametersDialogOpened = true;
                return;
            }

            await this.patchState(state_id, null);
        },
        async patchProdLine(val) {
            try {
                await this.axios.patch(`issues/${this.item.id}`, {
                    prod_line: val,
                    version: this.item.version,
                });
                await this.loadThis();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
        async patchState(
            state_id = this.stateToChangeId,
            parameters = this.stateParameters
        ) {
            if (!state_id) {
                throw new Error('state_id is required');
            }
            this.flowStateLoading = true;
            try {
                if (parameters?.length) {
                    const stateParams = parameters
                        .filter((p) => p.value !== undefined)
                        .map((p) => {
                            if (
                                Array.isArray(p.value) &&
                                p?.value?.[0]?.uploadFile
                            ) {
                                return {
                                    parameter_id: p?.id || p?.parameter_id,
                                    file: p?.value?.[0]?.uploadFile,
                                };
                            }
                            return {
                                parameter_id: p?.id || p?.parameter_id,
                                value: p.value || p.default_value,
                            };
                        });

                    const data = {
                        version: this.item.version,
                        parameters: [...stateParams],
                    };
                    let currentParametersLength =
                        this.item?.parameters?.length || 0;
                    const formData = new FormData();
                    formData.append('_method', 'PATCH');
                    formData.append('version', data.version);

                    data.parameters.forEach((param) => {
                        if (
                            (param.value !== undefined &&
                                param.value !== null) ||
                            param?.file
                        ) {
                            formData.append(
                                `parameters[${currentParametersLength}][parameter_id]`,
                                param.parameter_id
                            );
                        }

                        if (param.value !== undefined && param.value !== null) {
                            if (typeof param?.value === 'object') {
                                Object.keys(param.value).forEach((key) => {
                                    const value = param.value[key];
                                    const formKey = `parameters[${currentParametersLength}][value][${key}]`;

                                    if (
                                        typeof value === 'object' &&
                                        value !== null
                                    ) {
                                        Object.keys(value).forEach((subKey) => {
                                            formData.append(
                                                `${formKey}[${subKey}]`,
                                                value[subKey]
                                            );
                                        });
                                    } else {
                                        formData.append(formKey, value);
                                    }
                                });
                            } else {
                                formData.append(
                                    `parameters[${currentParametersLength}][value]`,
                                    param?.value
                                );
                            }
                        } else if (param?.file) {
                            formData.append(
                                `parameters[${currentParametersLength}][file]`,
                                param?.file
                            );
                        }
                        currentParametersLength++;
                    });
                    //TAK POST BO LARAVEL JEST UPOŚLEDZONY
                    await this.axios.request({
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        url: `/issues/${this.item.id}`,
                        method: 'POST',
                        data: formData,
                    });
                }
                await this.loadThis();
                const patch = {
                    flow_state_id: state_id,
                    version: this.item.version,
                };
                await this.axios.patch(`/issues/${this.item.id}`, patch);
                this.flowStateLoading = false;
                await this.loadThis();
                this.onCloseStateChangeParametersDialog();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                this.helpersStore.snackbarError(err);
                this.onCloseStateChangeParametersDialog();
                console.error(err);
                this.flowStateLoading = false;
            }
        },
        // async patchState(
        //     state_id = this.stateToChangeId,
        //     parameters = this.stateParameters
        // ) {
        //     if (!state_id) {
        //         throw new Error('state_id is required');
        //     }
        //     this.flowStateLoading = true;
        //     try {
        //         if (parameters?.length) {
        //             await this.axios.patch(`/issues/${this.item.id}`, {
        //                 version: this.item.version,
        //                 parameters: [
        //                     ...(this.item?.parameters || [])?.map((p) => {
        //                         return {
        //                             parameter_id: p.id,
        //                             value: p.value || p.default_value,
        //                         };
        //                     }),
        //                     ...parameters,
        //                 ],
        //             });
        //         }
        //         await this.loadThis();
        //         const patch = {
        //             flow_state_id: state_id,
        //             version: this.item.version,
        //         };
        //         await this.axios.patch(`/issues/${this.item.id}`, patch);
        //         this.flowStateLoading = false;
        //         await this.loadThis();
        //         this.onCloseStateChangeParametersDialog();
        //         this.helpersStore.snackbarSaved();
        //     } catch (err) {
        //         this.helpersStore.snackbarError(err);
        //         this.onCloseStateChangeParametersDialog();
        //         console.error(err);
        //         this.flowStateLoading = false;
        //     }
        // },
        async saveField(fieldName) {
            const original = this.item[fieldName];
            this.item[fieldName] = this.editingValue;
            this.editingIsSaving = true;
            try {
                await this.saveItem(false, fieldName);
                if (this.editingField) {
                    this.item[fieldName] = original;
                }
                this.editingField = null;
                await this.loadThis();
                this.helpersStore.snackbar(this.$t('common.saved'), 'success');
            } catch (err) {
                if (err?.response?.status == 401) return;
                this.item[fieldName] = original;
            }
            this.editingIsSaving = false;
        },
        parseScheduledOnTime() {
            if (this.item.scheduled_on === null) return;
            const [date, time] = this.item.scheduled_on.split(' ');
            const [hours, minutes] = time.split(':');
            this.scheduledDate = Date.parse(date);
            this.scheduledTime = {
                HH: hours,
                mm: minutes,
            };
        },

        parsePredictedTookTime() {
            if (this.item.predicted_finish === null) return;
            const [date, time] = this.item.predicted_finish.split(' ');
            const [hours, minutes] = time.split(':');
            this.fixingDate = Date.parse(date);
            this.fixingTime = {
                HH: hours,
                mm: minutes,
            };
        },
        parseServiceStartTime() {
            if (!this.item.took_at) return;
            const [date, time] = this.item.took_at.split(' ');
            const [hours, minutes] = time.split(':');
            this.serviceStartDate = Date.parse(date);
            this.serviceStartTime = {
                HH: hours,
                mm: minutes,
            };
        },
        parseServiceEndTime() {
            if (this.item?.finished_at === null) return;
            const [date, time] = this.item?.finished_at?.split(' ');
            const [hours, minutes] = time.split(':');
            this.serviceEndDate = Date.parse(date);
            this.serviceEndTime = {
                HH: hours,
                mm: minutes,
            };
        },
        buildPredictedFinish() {
            if (!this.fixingValid || !this.fixingDate || !this.fixingTime)
                return;

            const predicted_took_time = new Date(
                new Date(this.fixingDate).setHours(
                    this.fixingTime.HH,
                    this.fixingTime.mm
                )
            );
            this.editingValue = toMySqlDate(predicted_took_time);
        },
        buildScheduledOn() {
            if (!this.scheduledDate || !this.scheduledTime) return;

            const scheduled_on = new Date(
                new Date(this.scheduledDate).setHours(
                    this.scheduledTime.HH,
                    this.scheduledTime.mm
                )
            );
            this.editingValue = toMySqlDate(scheduled_on);
        },
        buildServiceStart() {
            if (
                !this.serviceStartValid ||
                !this.serviceStartDate ||
                !this.serviceStartTime
            )
                return;

            const time = new Date(
                new Date(this.serviceStartDate).setHours(
                    this.serviceStartTime.HH,
                    this.serviceStartTime.mm
                )
            );
            this.editingValue = toMySqlDate(time);
        },
        buildServiceEnd() {
            if (
                !this.serviceEndValid ||
                !this.serviceEndDate ||
                !this.serviceEndTime
            )
                return;

            const time = new Date(
                new Date(this.serviceEndDate).setHours(
                    this.serviceEndTime.HH,
                    this.serviceEndTime.mm
                )
            );
            this.editingValue = toMySqlDate(time);
        },

        singleValueDialogUpdateHandler(val) {
            this.dataStore.issues
                .upsertParameter(this.id, val?.id, val.value, val?.type)
                .then(() => {
                    return this.loadThis();
                })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('common.saved'),
                        'success'
                    );
                })
                .catch((e) => {
                    if (e.response?.status == 401) return;
                });
        },
        groupClicked(group) {
            const shouldDetachGroupId =
                this.previouslySelectedRequiredHelpIds.filter(
                    (id) => !group.includes(id)
                )[0];

            const shouldAttachGroupId = group.filter(
                (id) => !this.previouslySelectedRequiredHelpIds.includes(id)
            )[0];
            let req;
            if (shouldAttachGroupId !== undefined) {
                req = this.dataStore.issues.attachGroup(
                    this.item.id,
                    shouldAttachGroupId
                );
            } else if (shouldDetachGroupId !== undefined) {
                req = this.dataStore.issues.detachGroup(
                    this.item.id,
                    shouldDetachGroupId
                );
            } else {
                return;
            }
            req.then(() => {
                this.helpersStore.snackbarSaved();
                this.previouslySelectedRequiredHelpIds = group;
                this.load();
            }).catch((res) => {
                if (res?.response?.status === 401) return;
            });
        },
        getDictionaryNameById(dictionaryId, entryKeyname) {
            if (dictionaryId === null || dictionaryId === undefined) return '-';
            const dictionary = this.dataStore.dictionary.find(
                (d) => d.dictionary_id == dictionaryId
            );
            if (!dictionary) return '-';
            return this.$getDictionaryItemName(dictionary.name, entryKeyname);
        },
        isPlannedCategory(id) {
            return !!this.dataStore.categories.getById(id)?.planned;
        },
        async onResolutionDialogClose(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadThis();
            }
            this.issueFinishedStateId = null;
            this.resolutionDialog = false;
        },
        onExport() {
            this.exportEndpoint = 'issues';
            this.exportEndpoint += `/${this.id}`;
            this.exportDialog = true;
        },
        getUserNameById(id) {
            return this.users?.find((u) => u.id == id)?.name;
        },
        async responsibleUserPatch(user) {
            let item = {
                id: this.item.id,
                responsible_user_id: user.id,
                version: this.item?.version,
            };
            try {
                await this.dataStore.issues.patchRequest(item.id, item);
                this.helpersStore.snackbarSaved();
                this.loadThis();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
        isAllowedToIssueAction(action) {
            return Boolean(this.item?.access?.[action]);
        },
    },
};
</script>
<style>
.parameters-section .v-card-text {
    padding: 0 !important;
}
</style>
