<template>
    <SignaloDialog
        :open="true"
        :icon="'mdi-cog-outline'"
        :title="$t('common.synchronization') + ' - CMMS'"
        @close="$emit('close', false)"
    >
        <template #body>
            <v-form ref="form" v-model="dataValid" lazy-validation>
                <v-card-text>
                    <v-row>
                        <!-- <v-col cols="12" md="6" xl="6" v-if="!editedItem?.id">
                            <v-text-field
                                :label="$t('synchronization.systemKeyname')"
                                v-model="item.system_keyname"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                class="required-field"
                                :rules="[$REQUIRED_RULE, systemKeynameRule]"
                            >
                            </v-text-field>
                        </v-col> -->
                        <v-col cols="12" md="6" xl="6">
                            <v-text-field
                                :label="$t('synchronization.address')"
                                v-model="item.address"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                class="required-field"
                                :rules="[$REQUIRED_RULE]"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" xl="6" v-if="!editedItem?.id">
                            <v-text-field
                                :label="$t('common.key')"
                                v-model="item.key"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                class="required-field"
                                :rules="[$REQUIRED_RULE]"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" xl="6" v-if="!editedItem?.id">
                            <v-text-field
                                :label="$t('synchronization.token')"
                                v-model="item.token"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                class="required-field"
                                :rules="[$REQUIRED_RULE]"
                            >
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" xl="6">
                            <SignaloCategoryPicker
                                v-model="
                                    item.attributes.categories_root_category_id
                                "
                                class="issue-category-picker"
                                v-slot="props"
                                :allAvailable="true"
                                :filter="(item) => !item?.external"
                            >
                                <v-text-field
                                    variant="outlined"
                                    color="var(--greyscale-100)"
                                    :modelValue="props.text"
                                    @click:control="props.onClick"
                                    @click:clear="props.onClear"
                                    readonly
                                    clearable
                                    :label="
                                        $t(
                                            'synchronization.categoriesRootCategoryId'
                                        )
                                    "
                                    :rules="[$REQUIRED_RULE]"
                                    class="required-field"
                                >
                                </v-text-field>
                            </SignaloCategoryPicker>
                        </v-col>
                        <v-col cols="12" md="6" xl="6">
                            <v-switch
                                :label="$t('common.enabled')"
                                color="primary"
                                v-model="item.enabled"
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
        </template>
        <template #buttons>
            <v-btn @click="$emit('close', false)">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                color="primary"
                @click="save"
                :loading="dataSaving"
                variant="elevated"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    props: ['editedItem'],
    emits: ['close'],

    setup() {
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { authStore, helpersStore };
    },
    data() {
        return {
            dataValid: false,
            dataSaving: false,
            item: this.editedItem
                ? {
                      ...this.editedItem,
                      attributes: this.editedItem?.attributes
                          ? { ...this.editedItem?.attributes }
                          : {},
                  }
                : {
                      enabled: false,
                      attributes: this.editedItem?.attributes
                          ? { ...this.editedItem?.attributes }
                          : {},
                  },
        };
    },
    methods: {
        async save() {
            await this.$refs.form.validate();
            if (!this.dataValid) return;
            this.dataSaving = true;

            try {
                if (this.editedItem?.id) {
                    if (this.editedItem?.attributes?.categories_root_category_id !== this.item?.attributes?.categories_root_category_id) {
                        this.item.attributes.category_structure = [];
                    }
                    await this.axios.patch(
                        `external/signalo_cmms_config/${this.editedItem?.id}`,
                        this.item
                    );
                } else {
                    await this.axios.post(
                        `external/signalo_cmms_config`,
                        this.item
                    );
                }
                this.$emit('close', true);
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
            } finally {
                this.dataSaving = false;
            }
        },
        systemKeynameRule(value) {
            /* eslint-disable */
            if (/^[a-z]+([_\-][a-z]+)*$/.test(value)) {
                return true;
            }
            return this.$t('errors.wrongValue');
        },
    },
};
</script>
