<template>
    <v-btn
        color="primary"
        variant="elevated"
        @click="onButtonClick"
        :disabled="disabled"
        >{{ buttonLabel }}</v-btn
    >
    <SignaloDialog
        v-if="dialogVisible"
        :open="dialogVisible"
        :title="$t('common.filesManager')"
        @close="closeDialog"
        :constHeight="true"
        :hideActions="false"
        :dialogClasses="['signalo-files-manager-dialog']"
        :fullscreen="true"
    >
        <template #title>
            <v-toolbar class="app-dialog-header">
                <v-toolbar-title>
                    <v-btn
                        color="primary"
                        icon=" mdi-folder-multiple-outline"
                        @click="drawer = !drawer"
                    ></v-btn>

                    <div
                        class="app-dialog-title signalo-files-manager-dialog-title hidden-sm-and-down icon-button-label"
                    >
                        {{ $t('common.filesManager') }}
                    </div>
                </v-toolbar-title>
                <div class="signalo-files-manager-actions-bar">
                    <v-btn
                        variant="text"
                        color="primary"
                        @click="onManageFolder"
                    >
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.addFolder') }}
                        </span>

                        <v-icon class="ml-2">mdi-folder-plus</v-icon>
                    </v-btn>

                    <SignaloFilesDocumentsContainer
                        ref="filesUploader"
                        class="files-manager-files-document-container"
                        :endpoint="'files'"
                        :newItemDocuments="false"
                        :allowUpload="true"
                        :multiple="true"
                        :uploadAcceptExtensions="'*'"
                        :allowEdit="false"
                        :allowPreview="false"
                        :allowDownload="false"
                        :allowRemove="false"
                        :addDocumentButtonLabel="$t('common.uploadFromDrive')"
                        :disableInitLoad="true"
                        :hideFilesList="true"
                        @uploadInProgress="onUploadInProgressChange"
                        :fileDirectoryId="selectedFolder?.id"
                        :buttonIcon="'mdi-upload'"
                    />
                </div>
                <v-btn
                    class="close-app-dialog-button"
                    variant="text"
                    icon="mdi-close"
                    @click="closeDialog"
                >
                </v-btn>
            </v-toolbar>
        </template>
        <template #body>
            <div class="folders-breadcrumb-container">
                <v-btn
                    icon
                    class="return-button"
                    color="primary"
                    variant="elevated"
                    size="small"
                    @click="onFolderUp"
                    :disabled="loading || !this.selectedFolder"
                    :loading="loading"
                >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <SignaloBreadCrumbs
                    class="folders-breadcrumb"
                    :localBreadcrumb="true"
                    :items="breadcrumbItems"
                    :key="JSON.stringify(allFolders)"
                />
            </div>
            <div
                class="folders-breadcrumb-container-placeholder"
                style="visibility: hidden; display: flex"
            >
                <v-btn
                    icon
                    class="return-button"
                    color="primary"
                    variant="elevated"
                    size="small"
                    @click="onFolderUp"
                    :disabled="loading || !this.selectedFolder"
                    :loading="loading"
                >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <SignaloBreadCrumbs
                    class="folders-breadcrumb"
                    :localBreadcrumb="true"
                    :items="breadcrumbItems"
                    :key="JSON.stringify(allFolders)"
                />
            </div>
            <div class="signalo-files-manager-container">
                <v-navigation-drawer
                    v-if="drawer"
                    class="signalo-files-manager-container-navigation-drawer"
                    v-model="drawer"
                    :style="
                        drawer
                            ? 'left: 0 !important;'
                            : 'left: -256px !important;'
                    "
                >
                    <v-text-field
                        class="ma-2"
                        :label="$t('common.search')"
                        v-model="searchFolders"
                        variant="outlined"
                        color="var(--greyscale-100)"
                        density="compact"
                        hide-details
                        single-line
                    >
                    </v-text-field>
                    <div
                        class="message"
                        v-if="!currentFolderFoldersFiltered?.length"
                    >
                        {{ $t('common.noFolders') }}
                    </div>
                    <v-list density="compact" v-else>
                        <v-list-item
                            v-for="folder in currentFolderFoldersFiltered"
                            :key="folder"
                            prepend-icon="mdi-folder-outline"
                            :title="folder?.name"
                            @click="() => onFolderSelected(folder)"
                        >
                        </v-list-item>
                    </v-list>
                </v-navigation-drawer>
                <div class="signalo-files-manager-content">
                    <div class="folders-container mb-4">
                        <div class="Label-Text mb-2">
                            {{ $t('common.folders') }}:
                            <v-text-field
                                class="mt-2"
                                :label="$t('common.search')"
                                v-model="searchFolders"
                                variant="outlined"
                                color="var(--greyscale-100)"
                            >
                            </v-text-field>
                        </div>

                        <div class="folders">
                            <SignaloPageLoading v-if="loading" />
                            <div
                                class="message"
                                v-else-if="
                                    !currentFolderFoldersFiltered?.length
                                "
                            >
                                {{ $t('common.noFolders') }}
                            </div>
                            <v-row v-else>
                                <v-col
                                    cols="12"
                                    md="3"
                                    xl="3"
                                    v-for="folder in currentFolderFoldersFiltered"
                                    :key="folder"
                                >
                                    <SignaloFolderTile
                                        :folder="folder"
                                        draggable="true"
                                        @edit="() => onManageFolder(folder)"
                                        @reload="loadFolders"
                                        @click="() => onFolderSelected(folder)"
                                        @dragstart="
                                            ($event) =>
                                                onFolderDragStart($event)
                                        "
                                        @dragend="
                                            ($event) => onFolderDragEnd($event)
                                        "
                                        @dragenter="
                                            ($event) =>
                                                onFolderDragEnter($event)
                                        "
                                        @dragleave="
                                            ($event) =>
                                                onFolderDragLeave($event)
                                        "
                                        @dragover="
                                            ($event) => $event.preventDefault()
                                        "
                                        @drop="($event) => onFolderDrop($event)"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="files-container mb-4">
                        <div class="Label-Text mb-2">
                            {{ $t('common.files') }}:

                            <v-text-field
                                class="mt-2 mb-2"
                                :label="$t('common.search')"
                                v-model="searchFiles"
                                variant="outlined"
                                color="var(--greyscale-100)"
                            >
                            </v-text-field>

                            <v-select
                                variant="outlined"
                                color="var(--greyscale-100)"
                                :label="$t('common.type')"
                                :items="[
                                    {
                                        value: 'video',
                                        label: this.$t('common.video'),
                                    },
                                    {
                                        value: 'image',
                                        label: this.$t('tableHeaders.image'),
                                    },
                                    {
                                        value: 'document',
                                        label: this.$t('common.document'),
                                    },
                                ]"
                                item-title="label"
                                item-value="value"
                                v-model="filesType"
                                clearable
                                class="mt-2"
                            ></v-select>
                        </div>

                        <div class="files">
                            <SignaloPageLoading v-if="filesLoading" />
                            <div
                                class="message"
                                v-else-if="!currentFolderFiles?.length"
                            >
                                {{ $t('common.noFiles') }}
                            </div>
                            <v-row v-else>
                                <v-col
                                    cols="12"
                                    md="3"
                                    xl="3"
                                    v-for="file in currentFolderFilesFiltered"
                                    :key="JSON.stringify(file)"
                                >
                                    <SignaloFileTile
                                        :file="file"
                                        draggable="true"
                                        @dragstart="
                                            ($event) => onFileDragStart($event)
                                        "
                                        @dragend="
                                            ($event) => onFileDragEnd($event)
                                        "
                                        @reload="loadFiles"
                                        @click="() => select(file)"
                                        :class="
                                            (
                                                returnObject
                                                    ? selectedFiles.find(
                                                          (f) =>
                                                              f.id === file?.id
                                                      )
                                                    : selectedFiles.includes(
                                                          file?.id
                                                      )
                                            )
                                                ? 'selected-file'
                                                : ''
                                        "
                                        @goToFolder="
                                            onFolderSelected(
                                                file.file_directory_id
                                            )
                                        "
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #buttons>
            <v-btn @click="() => closeDialog()">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn color="primary" variant="elevated" @click="onSave">
                {{ $t('common.select') }}&nbsp;<span
                    >({{ selectedFiles?.length }})</span
                >
            </v-btn>
        </template>
    </SignaloDialog>

    <SignaloSingleValueDialog
        v-if="manageFolderDialog"
        :open="true"
        :title="$t('common.folder')"
        :valueType="'text'"
        @update:modelValue="onManageFolderDialogSave"
        @close="onManageFolderDialogClose"
        :required="true"
        :label="$t('common.name')"
        :modelValue="editedFolder.name"
    />
</template>

<script>
import i18n from '@/i18n';
import SignaloFolderTile from './FolderTile/FolderTile.vue';
import SignaloFileTile from './FileTile/FileTile.vue';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    name: 'SignaloFilesManager',
    components: { SignaloFolderTile, SignaloFileTile },
    props: {
        buttonLabel: {
            type: String,
            default: i18n?.global?.t('common.manageFiles'),
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            loading: false,
            filesLoading: false,
            dialogVisible: false,
            drawer: false,
            currentFolderFolders: [],
            currentFolderFiles: [],
            manageFolderDialog: false,
            editedFolder: false,
            selectedFolder: null,
            allFolders: [],
            dragElementId: null,
            dragFileId: null,
            selectedFiles: [],
            filesType: null,
            searchFolders: '',
            searchFiles: '',
            debounceHandle: null,
        };
    },
    mounted() {
        if (Array.isArray(this.modelValue) && this.modelValue?.length) {
            this.selectedFiles = [...this.modelValue];
        } else if (this.modelValue && !Array.isArray(this.modelValue)) {
            this.selectedFiles = [this.modelValue];
        } else {
            this.selectedFiles = [];
        }
        this.loadFolders();
    },
    methods: {
        async loadAllFolders() {
            try {
                const allFoldersData = await this.axios.get(
                    'file_directories_all'
                );
                this.allFolders = allFoldersData?.data?.items || [];
            } catch (err) {
                console.error(err);
            }
        },
        async loadFolders(parentId = this.selectedFolder?.id) {
            try {
                this.loading = true;
                let url = 'file_directories';
                if (parentId) {
                    url += `?parent_id_equals=${parentId}`;
                } else {
                    url += `?parent_id_equals=${null}`;
                }
                await this.loadAllFolders();
                await this.loadFiles();
                const directoriesData = await this.axios.get(url);
                this.currentFolderFolders = directoriesData?.data?.items || [];
            } catch (err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        onButtonClick() {
            this.dialogVisible = true;
        },
        closeDialog() {
            this.dialogVisible = false;
        },
        onFolderSelected(folder) {
            //folder or id
            this.searchFolders = '';
            if (typeof folder !== 'object') {
                const f = this.allFolders.find((f) => f.id == folder);
                if (f) {
                    this.selectedFolder = f;
                }
            } else {
                this.selectedFolder = folder;
            }
            this.loadFolders(this.selectedFolder?.id);
        },
        onFolderDragStart($event) {
            $event.target.classList.add('dragstart');
            this.dragElementId = $event.srcElement.id;
        },
        onFolderDragEnd($event) {
            $event.target.classList.remove('dragstart');
            this.dragElementId = null;
            $event.preventDefault();
        },
        onFolderDragEnter($event) {
            if (this.dragElementId !== $event.target.id) {
                $event.target.classList.add('dragover');
            }
            $event.preventDefault();
        },
        onFolderDragLeave($event) {
            $event.target.classList.remove('dragover');
            $event.preventDefault();
        },
        async onFolderDrop($event) {
            if (this.dragFileId) {
                return this.onFileDrop($event);
            }
            if (
                !Array.from($event.target.classList).includes(
                    'signalo-folder-tile'
                )
            )
                return;
            if ($event.target.id == this.dragElementId) return;
            try {
                if (this.dragElementId) {
                    await this.axios.patch(
                        `file_directories/${this.dragElementId}`,
                        {
                            parent_id: $event.target.id,
                        }
                    );
                    $event.target.classList.remove('dragover');
                    this.helpersStore.snackbarSaved();
                    this.loadFolders();
                }
            } catch (err) {
                $event.target.classList.remove('dragover');
                console.error(err);
                this.helpersStore.snackbarError(err);
            }
        },
        onManageFolder(folder = null) {
            this.editedFolder = folder;
            this.manageFolderDialog = true;
        },
        async onManageFolderDialogSave(value) {
            try {
                this.loading = true;
                const data = {
                    name: value,
                };
                if (this.selectedFolder?.id) {
                    data.parent_id = this.selectedFolder?.id;
                }
                if (this.editedFolder?.id) {
                    await this.axios.patch(
                        `file_directories/${this.editedFolder.id}`,
                        data
                    );
                } else {
                    await this.axios.post('file_directories', data);
                }
                await this.loadFolders();
                this.manageFolderDialog = false;
                this.editedFolder = null;
                this.helpersStore.snackbarSaved();
            } catch (err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        onManageFolderDialogClose() {
            this.manageFolderDialog = false;
            this.editedFolder = null;
        },
        onFolderUp() {
            this.selectedFolder =
                this.allFolders.find(
                    (f) => f.id == this.selectedFolder?.parent_id
                ) || null;
            this.loadFolders();
        },
        async loadFiles() {
            try {
                this.filesLoading = true;
                let url = `files?sort_by=created_at&sort_dir=DESC`;

                if (this.searchFiles) {
                    url += `&name=${this.searchFiles}`;
                } else {
                    url += `&file_directory_id_equals=${
                        this.selectedFolder?.id || null
                    }`;
                }
                const currentFolderFilesData = await this.axios.get(url);

                this.currentFolderFiles =
                    currentFolderFilesData?.data?.items || [];
            } catch (err) {
                console.error(err);
            } finally {
                this.filesLoading = false;
            }
        },
        onUploadInProgressChange(isUploading) {
            if (isUploading && !this.filesLoading) {
                this.filesLoading = true;
            } else if (!isUploading && this.filesLoading) {
                this.loadFiles();
            }
        },
        onFileDragStart($event) {
            $event.target.classList.add('dragstart');
            const fileId =
                String($event.srcElement.id).split('file-')?.[1] || null;
            if (fileId) {
                this.dragFileId = $event.srcElement.id;
            }
        },
        onFileDragEnd($event) {
            $event.target.classList.remove('dragstart');
            this.dragFileId = null;
            $event.preventDefault();
        },
        async onFileDrop($event) {
            if (
                !this.dragFileId ||
                !String(this.dragFileId).includes('file-') ||
                !$event?.target?.id
            )
                return;
            try {
                const fileId = String(this.dragFileId).split('file-')?.[1];
                await this.axios.patch(`files/${Number(fileId)}`, {
                    file_directory_id: $event.target.id,
                });
                await this.loadFiles();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                console.error(err);
            } finally {
                $event.target.classList.remove('dragover');
            }
        },
        select(file) {
            if (this.returnObject) {
                const index = this.selectedFiles.findIndex(
                    (f) => f.id === file.id
                );
                if (index > -1) {
                    this.selectedFiles.splice(index, 1);
                } else {
                    this.selectedFiles.push(file);
                }
            } else {
                const index = this.selectedFiles.indexOf(file.id);
                if (index > -1) {
                    this.selectedFiles.splice(index, 1);
                } else {
                    this.selectedFiles.push(file.id);
                }
            }
        },
        onSave() {
            this.$emit('update:modelValue', this.selectedFiles);
            this.closeDialog();
        },
        debounce(func, delay = 250) {
            if (this.debounceHandle !== null) clearTimeout(this.debounceHandle);
            this.debounceHandle = setTimeout(func, delay);
        },
    },
    computed: {
        breadcrumbItems() {
            let bcs = [];
            bcs.push({
                text: '*',
                value: null,
                onClick: () => this.onFolderSelected(null),
            });
            if (this.selectedFolder) {
                const path = this.selectedFolder.path.split('/');
                path.filter((p) => Boolean(p)).forEach((parentId) => {
                    const parent = this.allFolders.find(
                        (f) => f.id == parentId
                    );
                    if (parent) {
                        bcs.push({
                            text: parent.name,
                            value: parent.id,
                            onClick: () => this.onFolderSelected(parent),
                        });
                    }
                });
            }
            return bcs;
        },
        currentFolderFilesFiltered() {
            let items = [...this.currentFolderFiles];
            if (this.filesType) {
                items = items.filter((f) => f.type == this.filesType);
            }
            return items;
        },
        currentFolderFoldersFiltered() {
            let items = [...this.currentFolderFolders];
            if (this.searchFolders) {
                items = items.filter((i) =>
                    String(i.name)
                        .toLowerCase()
                        .includes(this.searchFolders.toLowerCase())
                );
            }
            return items;
        },
    },
    watch: {
        modelValue: {
            handler(val) {
                if (Array.isArray(val) && val?.length) {
                    this.selectedFiles = [...val];
                } else if (val && !Array.isArray(val)) {
                    this.selectedFiles = [val];
                } else {
                    this.selectedFiles = [];
                }
            },
            deep: true,
        },
        searchFiles() {
            this.debounce(() => {
                this.loadFiles();
            }, 500);
        },
    },
};
</script>

<style scoped>
.signalo-files-manager-dialog-title {
    display: flex;
    align-items: center;
}
.signalo-files-manager-actions-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.signalo-files-manager-container {
    position: relative;
    display: flex;
    min-height: calc(90vh - 72px);
}

.signalo-files-manager-container-navigation-drawer {
    position: absolute !important;
    top: 0 !important;
    height: 100% !important;
    min-height: calc(90vh - 72px) !important;
}
.signalo-files-manager-content {
    flex: 1;
    padding: 1rem;
}

.message {
    font-size: 0.825rem;
    text-align: center;
}

.folders-breadcrumb-container {
    display: flex;
    position: fixed;
    width: 100%;
    background-color: var(--ui-general-background-01);
    z-index: 9999;
}
</style>

<style>
.signalo-files-manager-dialog.v-card.app-dialog {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.signalo-files-manager-dialog .app-dialog-content-container {
    padding-bottom: 0 !important;
}

.folders-breadcrumb .v-breadcrumbs-item {
    cursor: pointer;
}
.signalo-files-manager-actions-bar .v-table {
    background-color: transparent !important;
}
.signalo-files-manager-actions-bar .v-table tbody tr:not(:first-of-type) {
    display: none;
}
</style>
