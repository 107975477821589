<template>
    <SignaloDialog
        :icon="'mdi-key-chain'"
        :title="$t('common.manageStorageAccess')"
        :open="true"
        @close="(x) => $emit('close', x)"
        :constHeight="false"
        :hideActions="true"
    >
        <template #body>
            <SignaloPageLoading v-if="loading" />
            <div v-else>
                <v-card-text>
                    <table class="access-table">
                        <tr>
                            <th class="access-table-header">
                                {{ $t('common.warehouse') }}
                            </th>
                            <th
                                v-for="access in Object.values(byKeyname)[0]"
                                :key="JSON.stringify(access)"
                            >
                                {{
                                    $getPropertyTranslation(
                                        translations?.[access?.access]
                                    )
                                }}
                            </th>
                        </tr>
                        <tr v-for="row in rows" :key="JSON.stringify(row)">
                            <td>
                                {{ row?.value }}
                                {{
                                    $getPropertyTranslation(
                                        translations?.[row?.value]
                                    )
                                }}
                            </td>
                            <td
                                v-for="access in byKeyname[row?.keyname]"
                                :key="JSON.stringify(access)"
                                class="access-checkbox-container"
                            >
                                <v-checkbox
                                    :modelValue="access.allowed"
                                    hide-details
                                    @change="changed(row.keyname, access)"
                                    :disabled="checkboxLoading"
                                    color="primary"
                                />
                            </td>
                        </tr>
                    </table>
                </v-card-text>
            </div>
        </template>
    </SignaloDialog>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as _ from 'lodash';
export default {
    name: 'SignaloManageStorageAccessDialog',
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { dataStore, helpersStore };
    },
    props: ['id'],
    data() {
        return {
            loading: true,
            rows: [],
            storages: [],
            checkboxLoading: false,
            byKeyname: [],
            translations: [],
        };
    },
    mounted() {
        this.loadStoragesAccess();
    },
    methods: {
        async loadStoragesAccess() {
            try {
                this.loading = true;
                await this.dataStore.dictionary.promise();
                const userStorageAccess = await this.axios.get(
                    `users/${this.id}/storage_access`
                );
                this.translations = userStorageAccess?.data?.translations || {};
                this.byKeyname = _.groupBy(
                    userStorageAccess?.data?.items,
                    'storage'
                );

                const storages = new Set(
                    userStorageAccess?.data?.items?.map(
                        (access) => access?.storage
                    )
                );

                this.rows = Array.from(storages.keys()).map((keyname) =>
                    this.dataStore.dictionary
                        .group('storages')
                        ?.filter(d => d.pickable)
                        .find((storage) => storage.keyname === keyname)
                );

                this.loading = false;
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
        },
        async changed(keyname, access) {
            this.checkboxLoading = true;
            try {
                access.allowed = !access.allowed;

                await this.axios.post(`users/${this.id}/storage_access`, {
                    storage: keyname,
                    allowed: access.allowed,
                    access: access?.access,
                });
                this.helpersStore.snackbarSaved();
                this.checkboxLoading = false;
            } catch (err) {
                console.error(err);
                this.checkboxLoading = false;
            }
        },
    },
};
</script>

<style>
.access-checkbox-container {
    min-width: 120px;
    justify-content: center;
}
.access-checkbox-container .v-checkbox .v-selection-control {
    justify-content: center;
}
</style>