export const uk = {
    common: {
        ok: 'Ok',
        authorizationRequiredDescriptionTerminal:
            'Використовуйте свій @:common.PIN_CODE_LABEL доступу, щоб авторизувати дію.',
        countersValues: 'Значення лічильників',
        countableId: 'Ідентифікатор поля для підрахунку',
        defaultValuesForCategory: 'Типові значення для категорії',
        check_user: 'Чи користувач',
        check_terminal: 'Чи термінал',
        bannedEmployees: 'Заборонені співробітники',
        banEmployee: 'Заборонити доступ',
        banned: 'Заборонено',
        employeeBans: 'Заборони співробітників',
        commentAboutEmployee: 'Стосується співробітника',
        comments: 'Коментарі',
        visitLog: 'Журнал відвідувань',
        categoryStructure: 'Структура категорій',
        appVersionMismatch: 'Несумісна версія програми',
        appVersionMismatchMessage:
            'Оновіть програму, щоб синхронізувати версію.',
        PIN_CODE_LABEL: 'PIN-код',
        comment: 'Коментар',
        year: 'Рік',
        configuration: 'Конфігурація',
        cancel: 'Скасувати',
        search: 'Пошук',
        remove: 'Видалити',
        confirmationDialogTitle: 'Ви впевнені, що хочете видалити цей елемент?',
        selectDate: 'Виберіть дату',
        select: 'Виберіть',
        notFound: 'Не знайдено',
        equipmentPickerPlaceholder: 'Об’єкт обладнання',
        searchPlaceholder: 'Пошук',
        equipmentTypePickerPlaceholder: 'Тип обладнання',
        equipmentTypePickerTitle: 'Виберіть тип обладнання',
        emptyTableMessage: 'Немає даних',
        flowPikcerTitle: 'Виберіть потік',
        selectIcon: 'Виберіть іконку',
        locationPickerTitle: 'Виберіть місце',
        locationPlaceholder: 'Місце',
        noSublocations: 'Немає підлокацій',
        locationsTitle: 'Місця',
        edit: 'Редагувати',
        save: 'Зберегти',
        added: 'Додано',
        updated: 'Оновлено',
        add: 'Додати',
        removed: 'Видалено',
        reload: 'Перезавантаження',
        confirm: 'Підтвердити',
        rollBack: 'Відмінити',
        images: 'Зображення',
        documents: 'Документи',
        download: 'Завантажити',
        saved: 'Збережено',
        date: 'Дата',
        hour: 'Година',
        preview: 'Попередній перегляд',
        data: 'Дані',
        attributes: 'Атрибути',
        checkList: 'Список перевірки',
        name: 'Назва',
        type: 'Тип',
        status: 'Статус',
        designation: 'Маркування',
        symbol: 'Символ',
        serialNumber: 'Серійний номер',
        registryNumber: 'Реєстраційний номер',
        machineNumber: 'Номер машини',
        udtNumber: 'UDT номер',
        manufacturedYear: 'Рік виробництва',
        manufacturer: 'Виробник',
        supplier: 'Постачальник',
        mainSupplier: 'Головний постачальник',
        attribute: 'Атрибут',
        nameRequired: 'Необхідно вказати назву',
        yearGreaterThan: 'Рік повинен бути більшим ніж',
        location: 'Місце розташування',
        fieldRequired: 'Це поле є обов`язковим',
        description: 'Опис',
        minimumQuantity: 'Мінімальна кількість',
        optimalQuantity: 'Оптимальна кількість',
        priority: 'Пріоритет',
        formatYYYY: 'Формат YYYY',
        storageQuantity: 'Кількість на складі',
        quantity: 'Кількість',
        quantityGreaterThan: 'Кількість має бути більше',
        machine: 'Машина',
        tool: 'Інструмент',
        order: 'Замовлення',
        required: 'Обов`язково',
        dateFormat: 'Формат дати',
        selectDictionary: 'Виберіть словник',
        return: 'Повернути',
        parameters: 'Параметри',
        editCategory: 'Редагувати категорію',
        addCategory: 'Додати нову категорію',
        changeColor: 'Змінити колір',
        noColor: 'Без кольору',
        shift: 'Зміна',
        start: 'Початок',
        end: 'Кінець',
        shiftSchema: 'Схема змін',
        shifts: 'Зміни',
        dictionary: 'Словник',
        subEntries: 'Підзаписи',
        key: 'Ключ',
        value: 'Значення',
        moveUp: 'Перемістити вгору',
        moveDown: 'Перемістити вниз',
        addLocation: 'Додати нове місце розташування',
        editLocation: 'Редагувати місце розташування',
        companiesDatabase: 'База даних компаній',
        flows: 'Потоки',
        read: 'Читати',
        write: 'Писати',
        noImage: 'Немає зображення',
        email: 'E-mail',
        function: 'Функція',
        active: 'Активний',
        notActive: 'Неактивний',
        disabled: 'Відключено',
        entryNumber: 'Номер запису',
        issuedDate: 'Дата видачі',
        expiresDate: 'Дата закінчення терміну дії',
        move: 'Пересунути',
        separateForm: 'Відокремити від',
        separate: 'Відокремити',
        warehouse: 'Склад',
        actions: 'Дії',
        putInCurrentLocation: 'Розмістити у поточному місці',
        selectLocationFormRightMenu:
            'Вибрати місце розташування з правого меню',
        showUnassignedItems: 'Показати непризначені елементи',
        separated: 'Відокремлено',
        moved: 'Пересунуто',
        categories: 'Категорії',
        welcome: 'Ласкаво просимо',
        loginToSignaloTpm: 'Увійти в Signalo TPM',
        login: 'Увійти',
        loginByEmailAndPassword:
            'Увійти за допомогою електронної пошти та паролю',
        loginByCode: 'Увійти за допомогою @:common.PIN_CODE_LABEL',
        accessCode: 'Код доступу',
        enterEmail: 'Введіть електронну пошту',
        enterPassword: 'Введіть пароль',
        enterCode: 'Введіть @:common.PIN_CODE_LABEL',
        userNotFound: 'Користувача не знайдено',
        userNotActivated: 'Користувач не активовано',
        userDisabled: 'Користувач відключено',
        wrongPassword: 'Неправильний пароль',
        locations: 'Розташування',
        pageNotFound: 'Сторінку не знайдено',
        group: 'Група',
        yes: 'Так',
        no: 'Ні',
        hourlyCost: 'Погодинна вартість',
        price: 'Ціна',
        newEntry: 'Новий запис',
        predictedFinishTime: 'Очікуваний час завершення',
        archive: 'Архів',
        costs: 'Вартість',
        companyManagement: 'Керування компанією',
        companyData: 'Дані компанії',
        supportedLanguages: 'Підтримувані мови',
        en: 'Англійська',
        pl: 'Польська',
        es: 'Іспанська',
        de: 'Німецька',
        it: 'Італійська',
        fr: 'Французька',
        ru: 'Російська мова',
        uk: 'Українська',
        pt: 'Португальська',
        tr: 'турецька',
        nl: 'голландська',
        sr: 'сербська',
        ar: 'арабська',
        he: 'іврит',
        zh: 'китайська',
        defaultLanguage: 'Мова за замовчуванням',
        companyLogo: 'Логотип компанії',
        notificationsManagement: 'Керування сповіщеннями',
        category: 'Категорія',
        today: 'Сьогодні',
        month: 'Місяць',
        week: 'Тиждень',
        day: 'День',
        close: 'Закрити',
        show: 'Показати',
        rollBackFormalConfirmation: 'Підтвердження формального відкату',
        signUp: 'Реєстрація',
        nameSurname: 'Ім`я та прізвище',
        phone: 'Номер телефону',
        password: 'Пароль',
        password_confirmation: 'Підтвердження пароля',
        unassignedItems: 'Неприсвоєні елементи',
        currency: 'Валюта',
        general: 'Загальні',
        timezone: 'Часовий пояс',
        users: 'Користувачі',
        groups: 'Групи',
        productionLine: 'Лінія виробництва',
        part: 'Частина',
        fittingParts: 'Відповідні частини',
        workSchema: 'Робочий схема',
        workSchemas: 'Робочі схеми',
        holiday: 'Відпустка',
        changePassword: 'Змінити пароль',
        showRest: 'Показати все',
        pageNotPermitted: 'Сторінка не дозволена',
        waitingForActivation: 'Очікування активації',
        editGroup: 'Редагувати групу',
        addGroup: 'Додати групу',
        reportProblem: 'Повідомити про проблему',
        describeOccuredIssue: 'Опишіть сталу проблему',
        markAllAsRead: 'Позначити все як прочитане',
        unitType: 'Тип одиниці',
        unit: 'Одиниця',
        unitPrice: 'Ціна за одиницю',
        notConfigured: 'Не налаштовано',
        rowsPerPage: 'Рядків на сторінці',
        rowsOfPage: 'із',
        true: 'Так',
        false: 'Ні',
        export: 'Експорт',
        chooseFileFormat: 'Виберіть формат файлу',
        exported: 'Експортовано',
        terminals: 'Термінали',
        terminal: 'Термінал',
        selectEquipment: 'Виберіть обладнання',
        futureIssues: 'Майбутні проблеми',
        scheduledOn: 'Заплановано на',
        planned: 'Заплановано',
        notPlanned: 'Не заплановано',
        showOpenIssues: 'Показати відкриті проблеми',
        pin: 'PIN',
        assignedEquipment: 'Присвоєне обладнання',
        terminalEquipment: 'Обладнання терміналу',
        detach: 'Від`єднати',
        attach: 'Приєднати',
        dashboardConfig: 'Конфігурація панелі',
        addColumn: 'Додати стовпець',
        addRow: 'Додати рядок',
        removeRow: 'Видалити рядок',
        registerTerminal: 'Зареєструвати термінал',
        issueGeneratesCost: 'Збій працює за вартістю',
        selectColor: 'Виберіть колір',
        belowMinimum: 'Нижче мінімуму',
        belowOptimum: 'Нижче оптимуму',
        synchronization: 'Синхронізація',
        synchronizationExport: 'Експорт запасних частин',
        synchronizationImport: 'Імпорт запасних частин',
        mapping: 'Відображення',
        returnTo: 'Повернутися до',
        jobStatusses: 'Процеси',
        hidden: 'Приховано',
        editEvent: 'Редагувати подію',
        threeDays: '3 дні',
        addEvent: 'Додати подію',
        plannedIssues: 'Заплановані проблеми',
        currentService: 'Поточна послуга',
        searchLocation: 'Знайти місцезнаходження',
        all: 'Всі',
        unfinished: 'Незавершено',
        numberOfIssues: 'Кількість проблем',
        unknown: 'Невідомо',
        partEntry: 'Частина (вхід)',
        foundItems: 'Знайдені елементи',
        output: 'Вихід',
        copied: 'Скопійовано',
        link: 'Посилання',
        authorizationRequired: 'Необхідна авторизація',
        authorizationRequiredDescriptionUser:
            'На жаль, у вас недостатньо повноважень. Зверніться до особи, яка має право на затвердження дії.',
        showAll: 'Показати все',
        showClassicView: 'Показати класичний вигляд',
        noIssuesInCategory: 'Немає проблем в цій категорії.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Кількість проблем в цій категорії та підкатегоріях.',
        numberOfIssuesInThisCategory: 'Кількість проблем в цій категорії.',
        minBarcodeLength: 'Мінімальна довжина штрих-коду',
        currentPage: 'Поточна сторінка',
        allPages: 'Усі сторінки',
        selectFormat: 'Виберіть формат',
        provided_currency: 'Надана валюта',
        provided_quantity: 'Надана кількість',
        provided_unit: 'Надана одиниця',
        provided_unit_price: 'Надана ціна за одиницю',
        unit_price: 'Ціна за одиницю',
        quantity_available: 'Доступна кількість',
        editBarcode: 'Редагувати штрих-код',
        crews: 'Команди',
        scheduleEventTypes: 'Типи розкладу подій',
        crew: 'Команда',
        scheduleEvent: 'Розклад подій',
        available: 'Доступно',
        'App\\Models\\Crew': 'Команда',
        'App\\Models\\User': 'Користувач',
        'App\\Models\\Equipment': 'Обладнання',
        Crew: 'Команда',
        User: 'Користувач',
        Equipment: 'Обладнання',
        scheduleEvents: 'Розклад подій',
        event: 'Подія',
        externalSystems: 'Зовнішні системи',
        user: 'Користувач',
        availability: 'Доступність',
        inheritColorFromParentIfCustomNotSet:
            'Наслідувати колір від батьківського елементу, якщо користувацький не встановлено',
        pickFromAllParts: 'Вибрати з усіх частин',
        takeTool: 'Взяти інструмент',
        returnTool: 'Повернути інструмент',
        personWhoTake: 'Особа, яка взяла',
        personWhoReturn: 'Особа, яка повернула',
        takenAt: 'Взято в',
        takenBy: 'Взято',
        toolIssuedBy: 'Інструмент видано',
        commentTake: 'Коментар при взятті',
        commentReturn: 'Коментар при поверненні',
        change: 'Змінити',
        onlyWithActiveRMIssues: 'Тільки з активними запитами RM',
        externalSystem: 'Зовнішня система',
        rejectIssue: 'Відхилити проблему',
        withdrawIssueRejection: 'Скасувати відхилення проблеми',
        rejectionReason: 'Причина відхилення',
        rejected: 'Відхилено',
        showCrewMembers: 'Показати членів команди',
        crewMembers: 'Члени команди',
        helpGroup: 'Служба',
        partsTakenByUser: 'Частини, взяті користувачем',
        pickParts: 'Видати частини',
        selectPersonWhoTakes: 'Вибрати особу, яка взяла',
        changePersonWhoTakes: 'Змінити особу, яка взяла',
        quantityToPick: 'Кількість для видачі',
        pickLocation: 'Місце видачі',
        selectPartsToPick: 'Вибрати частини для видачі',
        partSelected: 'Вибрана частина',
        scanCodeOr: 'Сканувати код або',
        returnPart: 'Повернути частину',
        myTakenParts: 'Частини, які я взяв',
        takenFromLocation: 'Взято з місця',
        takenQuantity: 'Взята кількість',
        providedUnit: 'Наданий одиниці',
        providedQuantity: 'Надана кількість',
        selectFromStorage: 'Вибрати з зберігання',
        selectFromMyPickedParts: 'Вибрати з моїх виданих частин',
        exportProcessInProgressMessage:
            'Процес експорту даних тільки що почався, коли він завершиться, ви побачите відповідне повідомлення, яке містить посилання на файл.',
        yourDocumentIsReady: 'Ваш документ готовий',
        rotateScreen: 'Повернути екран',
        addRule: 'Додати правило',
        editRule: 'Редагувати правило',
        summary: 'Резюме',
        onlyAvailableEmployees: 'Показати тільки доступних співробітників',
        calculated_unit_price: 'Перерахована ціна за одиницю',
        calculated_quantity: 'Кількість перетворено',
        next: 'наступний',
        maintenanceMode: 'Режим технічного обслуговування',
        applicationMayBeUnavailableForFewMinutes:
            'Додаток може бути недоступним протягом декількох хвилин',
        appWillRefreshIn: 'Додаток перезавантажиться в',
        landscape: 'альбом',
        portrait: 'портрет',
        orientation: 'орієнтація',
        format: 'формат',
        service: 'слуги',
        leader: 'майстри',
        defaultView: 'вигляд за замовчуванням',
        view: 'вид',
        oneDay: 'один день',
        object: 'об`єкт',
        finished: 'закінчено',
        onlyNotFinished: 'Тільки не закінчено',
        generate: 'генерувати',
        error: 'помилка',
        cell: 'клітинка',
        resolutionSummary: 'Кінцевий підсумок',
        rejected_at: 'дата відмови',
        rejected_by: 'Відхилено',
        finishedByUser: 'Виконав',
        formalConfirmByUser: 'Офіційне підтвердження',
        formalConfirmAt: 'Дата офіційного підтвердження',
        valueDeleted: '[Значення видален]',
        hasNotPlannedIssue: 'Не має запланованих випусків',
        showInStorage: 'Показати в сховищі',
        changeCode: 'Змініть @:common.PIN_CODE_LABEL',
        staffProfiles: 'Профілі співробітників',
        profile: 'Профіль',
        user_bug: 'помилка програми',
        user_kaizen: 'Пропозиція зміни',
        changeSuggestions: 'Змінити пропозиції',
        showOnlyCurrentlyAvailable: 'показати лише доступні на даний момент',
        resetPassword: 'Нагадати пароль',
        forgotPassword: 'Забули пароль',
        resetPasswordMailSent:
            'Ми надіслали посилання для встановлення нового пароля на вказану адресу електронної пошти',
        manyEventsThisTime: 'Багато подій у цей час',
        importScheduleCheckboxLabel:
            'Видалити заплановані події на вибраний місяць для співробітників, зазначених у файлі',
        inheritedEvent: 'Успадкована подія',
        inheritedModifiedEvent: 'Змінена успадкована подія',
        equipmentId: 'ID обладнання',
        entryId: 'ID запису',
        partId: 'ID частини',
        suggestionsEmail:
            'Електронна адреса, на яку надсилатимуться пропозиції змін',
        meters: 'лічильники',
        scanCardToAuth: 'Сканувати картку для авторизації',
        scanCard: 'Сканувати картку',
        cart: 'Ящик для обміну',
        emptyCardMessage:
            'Почніть сканувати продукти або виберіть їх зі списку, щоб додати їх у кошик.',
        terminalMode: 'Режим терміналу',
        askPermittedUserToConfirmPartsExchange:
            'Запитати дозволеного користувача для підтвердження обміну частинами',
        partsIssued: 'Частини видано',
        areYouSureAboutCancel: 'Ви впевнені, що хочете скасувати?',
        areYouSureAboutRemoveFromCart:
            'Ви впевнені, що хочете видалити з кошика?',
        standard: 'стандарт',
        idNumber: 'ID номер',
        takeParts: 'Взяти частини',
        returnParts: 'Повернути частини',
        quantityToReturn: 'Кількість для повернення',
        partsPendingApproval: 'Частини, що очікують схвалення',
        partsReturned: 'Частини повернуто',
        partsAddedToPendingApproval: 'Частини додано до очікування схвалення',
        approvePartsReturn: 'Схвалити повернення частин',
        partReturnApproved: 'Повернення частин схвалено',
        partUsage: 'Використання частин',
        awaitsReturn: 'Очікує повернення',
        entrySelection: 'Вибір запису',
        correctQuantity: 'Виправити кількість',
        quantityCorrection: 'Коригування кількості',
        entrySelectionAsc: 'Вибір запису (за зростанням)',
        entrySelectionDesc: 'Вибір запису (за спаданням)',
        metersSettings: 'Налаштування лічильників',
        countableFields: 'Поля для лічильників',
        equipmentTypes: 'Типи обладнання',
        fieldName: 'Назва поля',
        countableField: 'Поле для лічильника',
        partsStatusTaken: 'Взято',
        rejectPartsReturn: 'Відхилити повернення частин',
        areYouSureAboutRejectPartsReturn:
            'Ви впевнені, що хочете відхилити повернення частин?',
        partReturnRejected: 'Повернення частин відхилено',
        counterFields: 'Поля лічильників',
        counterName: 'Назва лічильника',
        counterFieldName: 'Назва поля лічильника',
        counterId: 'ID лічильника',
        counterUUID: 'UUID лічильника',
        counterPickerPlaceholder: 'Виберіть лічильник',
        pickFromPartsList: 'Вибрати зі списку частин',
        changeLocation: 'Змінити місцезнаходження',
        isPartStorageRoot: 'Частини зберігаються в корені',
        areYouSureAboutArchiveIssue:
            'Ви впевнені, що хочете архівувати це видання?',
        ganttChart: 'Діаграма Ганта',
        table: 'Таблиця',
        fittingEquipment: 'Обладнання для встановлення',
        notAssigned: 'Не призначено',
        counters: 'Лічильники',
        machineHourlyCostNotProvided: 'Погодинна вартість машини не вказана',
        eventTypesMightBeDefinedHere: 'Тут можна визначити типи подій',
        toolsTakenByUser: 'Інструменти, взяті користувачем',
        predictedReturnDate: 'Прогнозована дата повернення',
        estimatedTimeOfUsingToolInHours:
            'Орієнтовний час використання інструменту в годинах',
        showOnlyAvailable: 'Показувати тільки доступні',
        toolTakeLog: 'Журнал взяття інструментів',
        takeDate: 'Дата взяття',
        returnDate: 'Дата повернення',
        personWhoAcceptReturn: 'Особа, яка прийняла повернення',
        statisticStrategy: 'Стратегія доступності',
        EquipmentPlannedStrategy: 'Відповідно до плану роботи',
        EquipmentConstantAvailabilityStrategy: 'Завжди в наявності',
        actionAfterIssueFinish: 'Дія після завершення видання',
        enabled: 'Włączone',
        categoryOfNewIssue: 'Категорія нового видання',
        workNotStarted: 'Робота не розпочата',
        colorInherited: 'Колір успадкований',
        abcClassification: 'ABC класифікація',
        xyzClassification: 'XYZ класифікація',
        classification: 'Класифікація',
        filters: 'Фільтри',
        wipeFilters: 'Очистити фільтри',
        takePartsFromUser: 'Взяти частини у користувача',
        selectPersonWhoReturns: 'Виберіть особу, яка повертає частини',
        changePersonWhoReturns: 'Змінити особу, яка повертає частини',
        returnLocation: 'Місце повернення',
        returnQuantity: 'Кількість для повернення',
        globalSettings: 'Глобальні налаштування',
        delimiter: 'Роздільник',
        fieldRules: 'Правила поля',
        fieldValue: 'Значення поля',
        user_email_regex: 'Регулярний вираз для e-mail',
        barcodeLength: 'Довжина штрих-коду',
        partLine: 'Лінія частини',
        partFamily: 'Сімейство частини',
        partBatch: 'Партия частини',
        testValueValid: 'Тестове значення дійсне',
        testValueNotValid: 'Тестове значення недійсне',
        canBePicked: 'Можна вибрати',
        issue_create_photos: 'Додавання фотографій до нового випуску',
        issue_create_documents: 'Додавання документів до нового випуску',
        propagateValues: 'Поширення цінності',
        archived: 'заархівований',
        escalations_tab: 'Ескалації',
        minute: 'хвилина',
        days: 'днів',
        hours: 'години',
        minutes: 'хвилин',
        warnDays: 'Сповіщення до закінчення терміну дії (днів)',
        licenseType: 'Тип ліцензії',
        licenseGroup: 'Ліцензійна група',
        attachments: 'Додатки',
        attachment: 'вкладення',
        revisionChanges: 'Внесено зміни',
        document: 'документ',
        noInstructionSelected: 'Інструкція не вибрана',
        instructionGroups: 'Групи інструкцій',
        instructionGroup: 'Навчальна група',
        addOption: 'Додайте опцію',
        ordinal: 'Порядкове значення',
        parameterName: 'Назва параметра',
        fontColor: 'Колір шрифту',
        backgroundColor: 'Колір фону',
        columns: 'Кількість стовпців',
        colspan: 'Ширина (стовпці)',
        products: 'Продукти',
        controlsTemplates: 'Шаблони керування',
        controlType: 'Тип управління',
        controls: 'перевірки',
        controlTemplate: 'Контрольний шаблон',
        product: 'Продукт',
        checklistsAttachedToControlTemplate:
            'Контрольні листи, призначені для перевірок',
        removeChecklistFromControlTitle:
            'Ви впевнені, що хочете видалити цей список із-під вашого контролю?',
        attachChecklistToControl: 'Призначити контрольний список',
        pickedItem: 'Вибраний предмет',
        pick: 'Виберіть',
        fillControl: 'Завершіть перевірку',
        back: 'povratak',
        control: 'КОНТРОЛЬ',
        duplicate: 'дублікат',
        isStorageRoot: 'Основне розташування складу',
        manageStorageAccess: 'Керуйте доступом до складів',
        manageVisibleColumns: 'Налаштувати видимість стовпця',
        columnName: 'Назва стовпця',
        visible: 'Видно',
        entriesLocation: 'розташування записів',
        details: 'Подробиці',
        otherData: 'Інші дані',
        moveAllEntriesFromCurrentLocation:
            'Перемістити всі записи з цього місця',
        searchInCurrentLocation: 'Пошук у вашому поточному місці',
        transition: 'Перехід',
        notification: 'Сповіщення',
        emails: 'Адреси електронної пошти',
        phones: 'Номери телефонів',
        addAction: 'Додайте дію',
        message: 'повідомлення',
        application: 'додаток',
        always: 'Завжди',
        sms: 'СМС',
        loginTo: 'Увійти в',
        appName: 'Signalo TPM',
        index: 'Індекс',
        showCodeOwnerNameDuringParing:
            'Показувати ім’я власника @:common.PIN_CODE_LABEL під час сполучення з карткою/тегом',
        showUsernameInCart: 'Показати ім`я користувача в кошику',
        sampleButtonPlaceholder: 'Зразок кнопки',
        preferences: 'Уподобання',
        buttonsSize: 'Розмір кнопки',
        erase: 'стерти',
        selectDictionaryValue: 'вибрати значення словника',
        conditions: 'Умови',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Вимагати підтвердження або відхилення при поверненні завантажених частин',
        issueCreated: 'Квиток створено',
        allowedIssuesCategories: 'Дозволені категорії проблем',
        hiddenFields: 'Приховані поля',
        defaultValues: 'Значення за замовчуванням',
        paddingBottom: 'Порожнє місце внизу',
        allVisibleFieldsRequired: 'Вимагати заповнення всіх видимих ​​полів',
        url: 'URL`si',
        kind: 'Добрий',
        forkfleetConfigId: 'Конфігурація Forkfleet',
        create: 'створити',
        terminate: 'припинити',
        parent: 'Батько',
        phoneNumbers: 'Номери телефонів',
        storageDefaults: 'Значення зберігання за замовчуванням',
        clientSignatureEnabled: 'Підпис особи, яка збирає',
        warehousemanSignatureEnabled: 'Підпис комірника',
        storage_client_signature_enabled:
            'Підпис особи, яка приймає предмети зі складу',
        storage_client_signature_required:
            'Вимагати підпис особи, яка приймає речі зі складу',
        storage_warehouseman_signature_enabled:
            'Підпис особи, яка видає товари зі складу',
        storage_warehouseman_signature_required:
            'Вимагати підпис особи, яка видає товари зі складу',
        clientReturnSignatureEnabled: 'Підпис особи, яка повертається',
        warehousemanReturnSignatureEnabled:
            'Підпис особи, яка приймає повернення',
        storage_client_return_signature_enabled:
            'Підпис особи, яка повертає речі',
        storage_client_return_signature_required:
            'Вимагайте підпис від особи, яка повертає речі',
        storage_warehouseman_return_signature_enabled:
            'Підпис особи, яка приймає товар',
        storage_warehouseman_return_signature_required:
            'Вимагайте підпис особи, яка приймає товар',
        channels: 'Канали',
        manageState: 'Керувати станом',
        assignWorker: 'Призначити працівника',
        mergeEntriesOfTheSameLocation: 'Об’єднайте записи з одного місця',
        permission: 'Дозвіл',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'Ви впевнені, що бажаєте об’єднати всі записи в цьому місці?',
        manageFilters: 'Керуйте фільтрами',
        responsible_assign_creator_when_no_rule:
            '[Звіти] - призначити творця відповідальним, якщо правило не відповідає',
        recreateIssue: 'Повторно створіть квиток',
        addNewDictionary: 'Додайте новий словник',
        label: 'Мітка',
        issuesWithRelatedCategories: 'Додайте підкатегорії',
        issuesLimit: 'Ліміт випусків',
        issuesLimits: 'Проблеми - межі',
        issueDefaults: 'Звіти - значення за замовчуванням',
        flatCategoryPickerLabel: 'Плоска структура категорії',
        statementsTemplates: 'Шаблони заяв',
        statementTemplate: 'Шаблон заяви',
        content: 'Зміст',
        readonly: 'Лише для читання',
        defaultValue: 'Значення за замовчуванням',
        fillParametersToChangeState:
            'Заповніть значення для параметрів нижче, щоб змінити статус',
        formatPattern: 'шаблон формату',
        countableIncFields: 'Налаштування нумерації документів',
        mainShift: 'Основна зміна',
        singleEvent: 'Одноразовий вхід',
        seriesOfEvents: 'Серія записів',
        omitWeekends: 'Уникайте вихідних',
        allEntriesSaved: 'Усі записи додано',
        surveyResults: 'Результати опитування',
        qrActionsSettings: 'Налаштування дій QR-коду',
        pendingStatements: 'Декларації для підписання',
        statementsResults: 'Заяви',
        statements: 'Заяви',
        statement: 'Заява',
        userCanChangeContent: 'Користувач може змінювати вміст',
        statementsEvents: 'Конфігурація події - заяви',
        helpdesk: 'Довідкова',
        terminalId: 'Ідентифікатор терміналу',
        operatorId: 'ID оператора',
        newConversation: 'Нова тема',
        formula: 'Формула',
        fill: 'Повний',
        webMessage: 'Веб-повідомлення',
        noResponsibleUser: 'Немає відповідального користувача',
        filledAtDate: 'Дата завершення',
        filledBy: 'Виконав',
        parts_quantity_alert_rule:
            '[Кількість деталей і матеріалів] - Попередження',
        employeeNumber: 'номер працівника',
        groupsAssignedToCategory: 'Групи, призначені категоріям',
        number: 'Номер',
        customers: 'Клієнти',
        customer: 'Замовник',
        projects: 'Проекти',
        project: 'Демонструвати',
        report: 'звіт',
        reportGenerationInProgress: 'Звіт генерується',
        storageStatementGroup: 'Група складських декларацій',
        simplifiedHandling: 'Спрощена обробка сповіщень',
        selectWorkArea: 'Виберіть робочу зону',
        workAreaSelection: 'Вибір робочої зони',
        access: 'Доступ',
        restrictAccess: 'Обмежити доступ',
        anchor: 'Анкерування',
        issueCreator: 'Особа, яка створює звіт',
        hideSideMenu: 'Приховати бічне меню',
        appendGroups: 'Групи для додавання',
        revokeGroups: 'Групи для видалення',
        override: 'Перезаписати',
        restrictGroupAccess: 'Обмежити доступ до груп',
        flag: 'Прапор',
        flags: 'Прапори',
        manageFlags: 'Керувати прапорами',
        icon: 'Іконка',
        appendFlags: 'Прапори для додавання',
        revokeFlags: 'Прапори, які потрібно видалити',
        noFlagsConfigured: 'Прапори не визначено',
        checkIsTerminal: 'Перевірка терміналу',
        noConditionsDefined: 'Умови не визначені',
        checkIsResponsible: 'Перевірка відповідальності',
        createdOn: 'Повідомляється з',
        replaceTimespan: 'Термін придатності з моменту випуску',
        months: 'Місяці',
        handoversTemplates: 'Шаблони вручення',
        handoverTemplate: 'Шаблон передачі',
        employeeFunction: 'Функція працівника',
        takeHandover: 'Прийміть передачу',
        handovers: 'Передачі',
        expiringHandovers: 'Предмети, термін придатності яких закінчується',
        years: 'років',
        taken_parts_expiry_warning:
            'Попередження перед закінченням терміну дії завантажених елементів',
        andonView: 'Вид Андон',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'інший',
        tags: 'картки / Теги',
        tag: 'картку / Тег',
        userPinCode: '@:common.PIN_CODE_LABEL користувача картки',
        emailConfirmation: 'Підтвердження електронної адреси',
        from: 'Від',
        to: 'до',
        handoversHistory: 'Історія передачі',
        partUsagesHistory: 'Історія використання деталей',
        signaturePinTagConfirmationText1: 'Підпишіться @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'або відскануйте картку.',
        signedWithPinCode: 'Підписано @:common.PIN_CODE_LABEL',
        signedWithTAG: 'Підписано карткою/біркою',
        test_mode: 'Тестовий режим',
        confirmWorkFinish: 'Підтвердити закінчення роботи',
        workStarted: 'Початок роботи',
        workFinished: 'Завершення роботи',
        workTimeRegister: 'Реєстр робочого часу',
        measureTypes: 'Види вимірювань',
        measureType: 'Тип вимірювання',
        measurable: 'Вимірний показник',
        measurables: 'Вимірні показники',
        measurements: 'Вимірювання',
        measurement: 'Вимірювання',
        measuredAt: 'Дата вимірювання',
        currentVersion: 'Поточна версія',
        employee: 'Співробітник',
        pin_code_label: 'ПІН ім`я',
        orders: 'Замовлення',
        productionPlan: 'Üretim planı',
        productionPlans: 'Üretim planları',
        taktTime: 'Такт часу',
        target: 'Цільова',
        seconds: 'секунд',
        inheritValuesFromTenantSettings:
            'Успадкувати значення за замовчуванням із глобальних налаштувань',
        matrices: 'Матриці',
        manageMatrice: 'Керуйте Matrix',
        deletionOfMatrixColumnWarnMessage:
            'Видалення стовпця видаляє значення.',
        removeColumn: 'Видалити стовпець',
        editColumn: 'Редагувати стовпець',
        column: 'Колонка',
        row: 'рядок',
        evaluated: 'оцінено',
        of: 'з',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - Автоматична генерація',
        or: 'або',
        rename: 'Перейменувати',
        check_only_on_transition: 'Перевіряти лише при зміні статусу',
        license: 'Повноваження',
        confirmWorkStart: 'Підтвердити початок роботи',
        workStartAt: 'Ви починаєте роботу о',
        workFinishAt: 'Ви закінчуєте свою роботу о',
        workStartedAt: 'Починаємо',
        workTimeMessage: 'Ви перевтомилися',
        haveANiceDay: 'Гарного дня!',
        farewellText: 'Текст на останньому екрані',
        namedayLabel: 'Святкують іменини',
        sportEventNearby: 'Наступна спортивна подія',
        autoTranslate: 'Автоматичний переклад',
        auto_translation: 'Автоматичний переклад',
        awaitingAutoTranslation: 'Очікування автоматичного перекладу',
        inheritAllDefaultValues:
            'Успадкувати всі значення за замовчуванням із глобальних налаштувань',
        escalations: 'Ескалації',
        escalation: 'Ескалація',
        notTaken: 'Не розпочато',
        notFinished: 'Незакінчений',
        issueLastChange: 'Остання зміна',
        mode: 'Режим',
        repeatLastEscalation: 'Повторіть останнє значення ескалації',
        manageActions: 'Керуйте своїми діями',
        productionLines: 'Виробничі лінії',
        productionTerminal: 'Виробничий термінал',
        currently: 'В даний час',
        currentPlan: 'Поточний план',
        currentlyInProduction: 'В даний час виробляється',
        setProduct: 'Встановити продукт',
        second: 'Секунди',
        work: 'Робота',
        chartConfiguration: 'Конфігурація діаграми',
        revisionsToConsider: 'Ревізії для розгляду',
        allInstructions: 'Всі інструкції',
        decider: 'Визначник',
        considerRevision: 'Розглянемо перегляд',
        and: 'i',
        acceptInstruction: 'Прийміть інструкції',
        accept: 'прийняти',
        instructionsToAccept: 'Інструкції до читання',
        open: 'ВІДЧИНЕНО',
        assignedAt: 'Дата призначення',
        confirmationAt: 'Дата підтвердження',
        mainInstruction: 'Інструкція за замовчуванням',
        setAsDefault: 'Встановити за замовчуванням',
        instructionsFlows: 'Інструкції - потоки',
        newRevision: 'Нова редакція',
        playlist: 'Список відео',
        selectVideo: 'Виберіть фільм',
        selectVideos: 'Виберіть фільми',
        uploadNewVideo: 'Завантажити нове відео',
        upload_date: 'Дату додано',
        thumbnail: 'мініатюра',
        duration: 'Тривалість',
        uploadFromDrive: 'Завантажити з диска',
        selectAssets: 'Виберіть матеріали',
        assets: 'Матеріали',
        video: 'фільм',
        doYouWantToAddNewMarker: 'Бажаєте додати сюди новий тег?',
        areYouSureAboutRemoveMarker: 'Ви впевнені, що хочете видалити цей тег?',
        plants: 'Фабричні заводи',
        plant: 'Фабрика',
        buildings: 'Будівлі',
        building: 'будівля',
        floors: 'Підлоги',
        floor: 'Поверх',
        map: 'Карта',
        areYouSureAboutMoveMarker:
            'Ви впевнені, що бажаєте перемістити цей маркер?',
        marker: 'Маркер',
        addNewMarkerTooltipMessage:
            'Щоб додати новий маркер, клацніть правою кнопкою миші на карті.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Нелінійні навички',
        skillLevelConfig: 'Рівні кваліфікації',
        indirectSkillGroups: 'Нелінійні групи навичок',
        approved: 'Підтверджено',
        approvedBy: 'Затверджувач',
        skillLevel: 'Рівень майстерності',
        requiredSkillLevel: 'Необхідний рівень кваліфікації',
        step: 'Крок',
        image: 'Фото',
        skills: 'Навички',
        visits: 'Відвідування',
        responsiblePerson: 'Відповідальна особа',
        company: 'Компанія',
        contactData: 'Контактні дані',
        visit: 'Відвідати',
        inProgress: 'Виконується',
        gatehouse: 'Ворота',
        confirmEnter: 'Підтвердити введення',
        confirmExit: 'Підтвердити вихід',
        enter: 'Вхід',
        exit: 'Вихід',
        entryGuard: 'Особа, яка впускає',
        exitGuard: 'Людина, яка випускає',
        loginAsThisUser: 'Увійдіть як цей користувач',
        minBrowserVersion: 'Потрібна мінімальна версія браузера',
        approve: 'Підтвердити',
        cancelApproval: 'Скасувати фіксацію',
        workPermit: 'Дозвіл на роботу',
        workPermits: 'Дозволи на роботу',
        visit_summary: 'Підведення підсумків візиту',
        createVisit: 'Створити візит на основі дозволу',
        employeesAtThePlant: 'Робітники заводу',
        purpose: 'Цільова',
        allowedAreas: 'Дозволені території',
        switchEmployees: 'Замінити працівника',
        localIssues: 'Місцева звітність',
        cmmsCategoriesSync: 'Синхронізація категорії CMMS',
        terminalsGroups: 'Термінальні групи',
        isTree: 'дерево',
        isReporter: 'Є репортером',
        isAnchored: 'Закріплений',
        closable: 'Закриття квитків',
        areYouSureAboutCloseIssue: 'Ви впевнені, що хочете закрити заявку?',
        simplifiedIssuesRefreshLabel: 'Час оновлення списку - секунди',
        reloadApp: 'Перезавантажити',
        hideWorkAreasButton: 'Сховати кнопку вибору робочої області',
        hideClassicViewButton: 'Сховати кнопку класичного виду',
        hideAddIssueButton: 'Сховати кнопку додавання нового питання',
        filesManager: 'Менеджер файлів',
        addFolder: 'Додати папку',
        upload: 'Завантажити',
        noFolders: 'Немає папок',
        noFiles: 'Немає файлів',
        folders: 'Папки',
        folder: 'Папка',
        files: 'Файли',
        file: 'Файл',
        removeFolderFilesQuestion: 'Що робити з файлами в цій папці?',
        targetFolder: 'Цільова папка',
        addInstruction: 'Додати інструкцію',
        goToFolder: 'Перейти до папки',
        defaultProdLine: 'Стандартна виробнича лінія',
        pin_code_field_type: 'Тип PIN-коду',
        instructionsAccess: 'Дозволи',
        noSections: 'Без секцій',
        requireReadingInstruction: 'Вимагати ознайомлення з інструкцією',
        hide_parameter_types: 'Не відображати типи параметрів',
        unknownVersion: 'Невідомо',
        displayInstructionEveryTime: 'Відображати інструкцію щоразу',
        requireSigningInstructionEveryTime:
            'Вимагати підписання інструкції щоразу',
        signedInstructions: 'Підписані інструкції',
        signedAt: 'Дата підписання',
        signatures: 'Підписи',
        signature: 'Підпис',
        systemGroup: 'Системна група',
        pickable: 'Вибиральний',
        leaf: 'Вибраний елемент',
        path: 'Шлях',
        displayMode: 'Режим відображення',
        chart: 'діаграма',
        preSignedStatement: 'Декларація підписана на папері (заповнена)',
        myOpenIssues: 'Мої відкриті проблеми',
        myAssignedIssues: 'Проблеми, призначені мені',
        displayParametersOnMainScreen:
            'Перегляньте параметри на головному екрані',
        lockPin: 'Заблокувати зміну PIN-коду',
        pinLocked: 'PIN-код заблоковано',
        sortChange: 'Порядок відображення',
        asc: 'За зростанням',
        desc: 'За спаданням',
        alphanumeric: 'Алфавітно-цифровий',
        numeric: 'Цифровий',
        modificationDate: 'Дата зміни',
        fromNewest: 'Від новіших',
        fromOldest: 'Від старіших',
        negation: 'Заперечення',
        sortOrderService: 'Обслуговування',
        newIssueIgnoreFilters:
            'Ігнорувати застосовані фільтри під час створення нового запиту',
        refreshInterval: 'Час оновлення списку',
        multiline: 'Багаторядковий',
        expanded: 'Розширений',
        quizzes: 'Вікторини',
        courses: 'Курси',
        course: 'Курс',
        quiz: 'Вікторина',
        maxPoints: 'Максимальна кількість балів',
        minPointsToPass: 'Мінімальна кількість балів для проходження',
        questionFailQuiz: 'Помилка в питанні дискваліфікує всю вікторину',
        points: 'Бали',
        currentVersionPreview: 'Попередній перегляд поточної версії',
        docs_sidebar_hidden_by_default:
            'Сховати бічну панель у документах за замовчуванням',
        allCategories: 'Всі категорії',
        test: 'Тест',
        tests: 'Тести',
        copy: 'копія',
        trusted_device_code_login_users_limit: "Швидкий вхід - кількість відображених користувачів"
    },
    placeholders: {
        part: 'Виберіть частину',
        nameSurname: 'Ім`я та прізвище',
        phone: 'Номер телефону',
        password: 'Пароль',
        password_confirmation: 'Підтвердження пароля',
        email: 'E-mail',
    },
    auth: {
        notYourAccountQuestion: 'Це не ваш аккаунт?',
    },
    signup: {
        registered: 'Успішно!',
        emailConfirmed: 'Електронну адресу підтверджено.',
        registered_info:
            'Запитайте у свого керівника про активацію вашого акаунту',
        confirmEmailAddress:
            'Ваш обліковий запис створено, натисніть посилання в електронному листі, щоб підтвердити свою адресу.',
        tokenNotFoundError:
            'Електронну адресу вже підтверджено або посилання для підтвердження недійсне.',
    },
    paramTypes: {
        text: 'Текст',
        note: 'Примітка',
        integer: 'Ціле число',
        decimal: 'Десяткове число',
        date: 'Дата',
        time: 'Час',
        datetime: 'Дата і час',
        color: 'Колір',
        icon: 'Іконка',
        boolean: 'Ні / Так',
        dictionary: 'Словник',
        user: 'Користувач',
        radioGroup: 'Один вибір',
        checkboxGroup: 'Кілька виборів',
        signature: 'Підпис',
        number: 'Число',
        rating: 'Оцінка',
        document: 'Документ',
        image: 'Зображення',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Назва',
        path: 'Шлях до файлу',
        category: 'Категорія',
        equipment: 'Обладнання',
        criticality: 'Критичність',
        status: 'Статус',
        created: 'Створено',
        createdBy: 'Створено',
        fp: 'FP',
        type: 'Тип',
        designation: 'Ознака',
        symbol: 'Символ',
        serialNumber: 'Серійний номер',
        registryNumber: 'Реєстраційний номер',
        udtNumber: 'UDT номер',
        manufacturedYear: 'Рік виготовлення',
        actions: 'Дії',
        title: 'Заголовок',
        data: 'Дата',
        archived: 'Архівовано',
        manufacturerSymbol: 'Символ виробника',
        min: 'Min',
        opt: 'Opt',
        quantity: 'Кількість',
        priority: 'Пріоритет',
        price: 'Ціна',
        kind: 'Тип',
        description: 'Опис',
        default: 'За замовчуванням',
        warning: 'Попередження',
        problem: 'Проблема',
        fixing: 'Розв`язання',
        changeOrder: 'Зміна порядку',
        required: 'Необхідно',
        icon: 'Іконка',
        color: 'Колір',
        subCategories: 'Підкатегорії',
        barCode: 'Штрих-код',
        subLocations: 'Підрозділи',
        categories: 'Категорії',
        image: 'Зображення',
        nameSurname: 'Ім’я та прізвище',
        email: 'Email',
        phone: 'Номер телефону',
        function: 'Функція',
        department: 'Департамент',
        accountStatus: 'Статус облікового запису',
        shift: 'Зміна',
        licenseNumber: 'Номер ліцензії',
        issuedDate: 'Дата видачі',
        expiresDate: 'Дата закінчення дії',
        entries: 'Записи',
        entryNumber: 'Номер запису',
        buyPrice: 'Вартість покупки',
        currentLocationQuantity: 'Кількість в поточному розташуванні',
        group: 'Група',
        string: 'Текст',
        integer: 'Ціле число',
        toolsGroup: 'Група інструментів',
        manufacturer: 'Виробник',
        shortName: 'Коротке ім`я',
        locationName: 'Назва розташування',
        subLocationsQuantity: 'Кількість у підрозділах',
        isWorkingOnIssue: 'Робота над проблемою',
        openIssues: 'Відкриті проблеми',
        notificationDate: 'Дата повідомлення',
        titleAndDescription: 'Заголовок та опис',
        read: 'Прочитано',
        created_at: 'Створено',
        received_at: 'Отримано',
        started_at: 'Розпочато',
        arrived_at: 'Прибуло',
        finished_at: 'Завершено',
        country: 'Країна',
        city: 'Місто',
        progress: 'Прогрес',
        deleted_at: 'Видалити дату',
        about_issue: 'Відноситься до звіту.',
        action: 'Поділіться',
    },
    searchConfig: {
        id: 'ID',
        name: 'Назва',
        shortName: 'Коротка назва',
        country: 'Країна',
        email: 'Email',
        group: 'Група',
        planned: 'Заплановано',
        category: 'Категорія',
        object: 'Об’єкт',
        criticality: 'Критичність',
        fp: 'FP',
        type: 'Тип',
        designation: 'Ознака',
        symbol: 'Символ',
        serialNumber: 'Серійний номер',
        registryNumber: 'Реєстраційний номер',
        udtNumber: 'UDT номер',
        manufacturedYear: 'Рік виробництва',
        manufacturerSymbol: 'Символ виробника',
        priority: 'Пріоритет',
        quantity: 'Кількість',
        buyPrice: 'Ціна купівлі',
        kind: 'Вид',
        toolsGroup: 'Група інструментів',
        function: 'Функція',
        department: 'Відділ',
        status: 'Статус',
        nameSurname: 'Ім’я та прізвище',
        phone: 'Телефон',
        barCode: 'Штрих-код',
        color: 'Колір',
        icon: 'Іконка',
        machine: 'Машина',
        tool: 'Інструмент',
        urgentAndOutdated: 'Терміново та застаріло',
        urgent: 'Терміново',
        expired: 'Застаріло',
        manufacturer: 'Виробник',
    },
    errors: {
        uploadError: 'Помилка завантаження',
        finishedSuccessfully: 'Успішно завершено',
        partsAvailabilityError:
            'Доступність деяких вибраних елементів змінилася. Відредагуйте вміст своєї обмінної скриньки і спробуйте ще раз.',
        noFlagsConfigured: 'Прапорці не налаштовані',
        partMustHaveEntriesToCorrectQuantity:
            'Частина повинна мати записи для виправлення кількості.',
        error: 'Виникла помилка',
        wrongEmail: 'Недійсний email',
        wrongPasswordLength: 'Довжина пароля повинна бути не менше 8 символів.',
        wrongCodeLength:
            'Довжина @:common.PIN_CODE_LABEL повинна бути не менше 5 символів.',
        passwordsNotMatch: 'Паролі не збігаються',
        codesNotMatch: '@:common.PIN_CODE_LABEL не збігаються',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL вже використовується',
        expiresDateLowerThanIssued:
            'Дата закінчення не може бути менше, ніж дата видачі.',
        cannotMoveToTheSameLocation: 'Не можливо переміститися в ту ж локацію.',
        locationRequired: 'Локація обов`язкова.',
        putQuantity: 'Введіть кількість для розділення.',
        valueGreaterThanQuantity: 'Значення перевищує загальну кількість.',
        wholeCannotBeDistinguished: 'Весь не може бути розділений.',
        yearMustBeGreaterThan: 'Рік повинен бути більше, ніж ',
        passwordConfirmationNotMatch: 'Паролі не збігаються',
        mustBeInteger: 'Значення повинно бути цілим числом',
        valueTooLong: 'Значення занадто довге',
        wrongPhoneNumber: 'Неправильний номер телефону',
        phoneNumberAlreadyTaken: 'Номер телефону вже використовується',
        emailAlreadyTaken: 'Email вже використовується',
        notFound: 'Не знайдено',
        sessionExpired: 'Сесія закінчилася',
        tenantNotFound:
            'Вказаної конфігурації не існує. Перевірте адресу сайту та спробуйте ще раз.',
        selectEquipmentTypeToConfigureParameters:
            'Виберіть тип обладнання для налаштування параметрів.',
        noParametersToConfigure:
            'Обраний тип обладнання не має параметрів для налаштування.',
        terminalAlreadyRegistered: 'Термінал вже зареєстровано.',
        invalidPin: 'Недійсний @:common.PIN_CODE_LABEL',
        eventCollision:
            'Час події, яку ви хочете додати, перетинається з часом іншої події.',
        mustStartWithSlash: 'Має починатися з /',
        mustBeBetween: 'Значення має бути між:',
        minLength: 'Мінімальна довжина: ',
        maxLength: 'Максимальна довжина: ',
        invalidInputForCodeType: 'Недійсний ввід для обраного типу коду',
        invalidCodeOrNoPermission:
            '@:common.PIN_CODE_LABEL недійсний або у вас недостатньо прав для виконання цієї дії.',
        endDateLowerThanStart:
            'Дата закінчення не може бути нижче дати початку',
        iconAlreadyTaken: 'Іконка вже використовується',
        notEnoughQuantityInStorage: 'Недостатньо кількості в складі.',
        valueTooLow: 'Значення занадто низьке',
        valueTooHigh: 'Значення занадто високе',
        maxAvailableQuantity: 'Максимальна кількість',
        someFilesRejectedDueToSize:
            'Деякі файли було відхилено через розмір. Максимальний розмір одного файлу:',
        cameraDeviceNotFound: 'пристрій камери не знайдено',
        cameraPermissionDenied:
            'Доступ до камери пристрою заблоковано в налаштуваннях сайту, скиньте налаштування сайту і дозвольте використовувати камеру.',
        passwordResetLinkExpired:
            'Термін дії посилання для зміни пароля закінчився',
        noInternetConnection: 'Немає підключення до Інтернету',
        minimum: 'Мінімум',
        mustBeGreaterThan: 'Має бути більше, ніж',
        noEventTypesDefined: 'Не визначено жодного типу події',
        regexPatternDelimiterError:
            'За допомогою символу \\ роздільник має бути пропущений, наприклад: \\/',
        emailNotMatchPatternDefiniedInTenant:
            'Email не відповідає шаблону, визначеному в орендарі',
        max: 'Максимальне значення',
        min: 'мінімальне значення',
        allLanguagesRequired: 'Заповніть значення всіма мовами',
        fileTooBigToOpenWillBeDownloaded:
            'Файл завеликий для відкриття, файл буде завантажено на ваш пристрій.',
        surveyNotFound: 'Опитування не знайдено',
        actionNotEnabled: 'Код неактивний',
        loginRequired: 'Необхідно ввійти',
        noPermissionForSelectedFolder:
            'Ви не маєте дозволу переглядати вміст цього каталогу',
        noFoldersAssignedForTerminal:
            'Немає призначених каталогів з інструкціями',
        noChecklistsAssignedToControl:
            'Для вибраного елемента керування не призначено контрольний список',
        maximum: 'Максимум',
        versionErrorMessage:
            'Ваші останні зміни не було збережено, оскільки хтось інший вніс зміни в той же час. Оновіть сторінку та повторіть спробу.',
        duplicateError: 'Це значення вже існує',
        fillAllRequiredParameters: 'Заповніть усі необхідні параметри.',
        wrongValue: 'Недійсне значення',
        noParametersDefined: 'Параметри не визначено',
        noEnoughParts: 'Недостатньо елементів',
        pinNotBelongsTo: 'Цей @:common.PIN_CODE_LABEL не належить',
        tagNotBelongsTo: 'Ця картка/тег не належить',
        noDictionaryEntries: 'Вибраний словник не містить статей',
        noConnectionWithServer: 'Немає підключення до сервера',
    },
    navDrawer: {
        dashboard: 'Панель',
        issues: 'Проблеми',
        timetable: 'Розклад',
        machines: 'Машини',
        tools: 'Інструменти',
        parts: 'Частини',
        storage: 'Склад',
        staff: 'Персонал',
        configuration: 'Налаштування',
        notes: 'Нотатки',
        notifications: 'Сповіщення',
        profile: 'Профіль',
        supervision: 'Нагляд',
    },
    barcodeScanner: {
        barCode: 'Штрих-код',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Категорія',
        selectCategory: 'Виберіть категорію',
        noSubcategories: 'Немає підкатегорій',
    },
    companies: {
        addCompany: 'Додати компанію',
        editCompany: 'Редагувати компанію',
        fullName: 'Повне найменування',
        shortName: 'Скорочене найменування',
        group: 'Група',
        country: 'Країна',
        city: 'Місто',
        street: 'Вулиця',
        postCode: 'Поштовий індекс',
        phone: 'Телефон',
        email: 'Електронна пошта',
        website: 'Веб-сайт',
        NIP: 'Налоговий номер (NIP)',
        REGON: 'Національний реєстр підприємств (REGON)',
        active: 'Активно',
        nameRequired: 'Потрібно вказати найменування',
        shortNameRequired: 'Потрібно вказати скорочене найменування',
    },
    issues: {
        tookAt: 'Прийнято о',
        changeHistory: 'Історія змін',
        load: 'Завантажити',
        date: 'Дата',
        createdBy: 'Створено',
        action: 'Дія',
        data: 'Дані',
        created: 'Створено',
        updated: 'Оновлено',
        deleted: 'Видалено',
        restored: 'Відновлено',
        issues: 'Проблеми',
        issue: 'Проблема',
        by: 'від',
        name: 'Назва',
        applicantsDescription: 'Опис заявника',
        accidentDescription: 'Опис події',
        jobDescription: 'Опис роботи',
        jobSummary: 'Підсумок роботи',
        category: 'Категорія',
        status: 'Статус',
        noFlow: '[Немає потоку]',
        equipment: 'Обладнання',
        criticality: 'Критичність',
        hour: 'Година',
        formalConfirmation: 'Формальне підтвердження',
        formalConfirmationShortcut: 'Потрібне Фп',
        formalConfirmationRequired: 'RO gereklidir',
        parameter: 'Параметр',
        value: 'Значення',
        addIssue: 'Додати проблему',
        expectedCompletionDate: 'Очікувана дата завершення',
        issueDescription: 'Опис проблеми',
        partsUsage: 'Використання деталей',
        assignedEmployees: 'Призначені співробітники',
        requiredHelp: 'Необхідна допомога',
        returnParts: 'Повернути деталь',
        confirmUnassignEmployeeWhoTakenIssue:
            'Цей співробітник взяв вибрану проблему. Ви хочете відмінити його призначення?',
        responsibleUser: 'Відповідальний користувач',
        responsibleRules: 'Правила відповідальності користувачів',
        received_decision_at:
            'Дата, коли нотифікатору стало відомо про рішення',
        groupRules: 'Групи - правила',
        watchersRules: 'Спостерігачі - правила',
    },
    machines: {
        machines: 'Машини',
        xIssuesNotPlanned: ' (не заплановано)',
        noIssues: 'Немає проблем',
    },
    maintenance: {
        maintenance: 'Технічне обслуговування',
    },
    notes: {
        notes: 'Нотатки',
        note: 'Нотатка',
        title: 'Заголовок',
        archived: 'Заархівовано',
    },
    parts: {
        parts: 'Деталі',
        warehouseOutgoings: 'Видачі зі складу',
        fittingEquipmentTypes: 'Підходящі типи обладнання',
        goToEquipmentType: 'Перейти до типу обладнання',
        selectPart: 'Вибрати деталь',
        usageReason: 'Причина',
        usageOtherReason: 'Інше',
        replacements: 'Заміни',
        partEntry: 'Введення деталі',
        goToPart: 'Перейти до деталі',
    },
    costCenter: {
        costCenter: 'Центр витрат',
    },
    equipmentTypes: {
        equipment: 'Обладнання',
        newEquipmentType: 'Новий тип обладнання',
        fittingParts: 'Підходящі деталі',
        goToParts: 'Перейти до деталей',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'Визначення машин та інструментів',
    },
    flows: {
        flow: 'Потік',
        states: 'Стани',
        transitions: 'Переходи',
        state: 'Стан',
        default: 'За замовчуванням',
        warning: 'Попередження',
        problem: 'Проблема',
        fixing: 'Виправлення',
        finished: 'иконано',
        access: {
            create: 'Створення',
            read: 'Читання',
            update: 'Модифікація',
            delete: 'Видалення',
            read_unassigned: 'Прочитати без призначення',
            assign: 'призначення',
            unassign: 'Видалити завдання',
            close: 'Закриття',
        },
        instructions: {
            default: 'За замовчуванням',
            fixing: 'Розгляд',
            finished: 'Готово',
            creation: 'Створення',
            verification: 'Перевірка',
            acceptation: 'Прийняття',
            publication: 'Публікація',
        },
        accessLabels: {
            terminal: 'Термінал, який повідомив про проблему',
            creator: 'Користувач, який повідомив про проблему',
            responsible: 'Користувач, відповідальний за проблему',
            group: 'Група користувачів, відповідальна за проблему',
            anchor: 'Термінал, до якого закріплено звіт',
            watchers: 'Користувачі, які спостерігають за проблемою',
        },
        sync_returned_rejected: 'Синхронізація - відхилено',
        sync_returned_finished: 'Синхронізація - завершено',
        current: 'поточний',
    },
    parameters: {
        categoryParameter: 'Параметр категорії',
        equipmentTypeParameter: 'Параметр типу обладнання',
    },
    staff: {
        staff: 'Персонал',
        editUserAccess: 'Редагувати доступ користувача',
        permissionsUpdated: 'Дозволи оновлено',
        passwordUpdated: 'Пароль оновлено',
        codeUpdated: '@:common.PIN_CODE_LABEL оновлено',
        changePasswordAndCode: 'Змінити пароль та @:common.PIN_CODE_LABEL',
        goToLicenses: 'Перейти до ліцензій',
        editAccess: 'Редагувати доступ',
        editUser: 'Редагувати користувача',
        addUser: 'Додати нового користувача',
        nameAndLastname: 'Ім`я та прізвище',
        accountStatus: 'Статус акаунту',
        phoneNumber: 'Номер телефону',
        department: 'Відділ',
        passwordChange: 'Зміна пароля',
        codeChange: 'Зміна @:common.PIN_CODE_LABEL',
        newPassword: 'Новий пароль',
        confirmNewPassword: 'Підтвердити новий пароль',
        changePassword: 'Змінити пароль',
        newCode: 'Новий @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Підтвердити новий @:common.PIN_CODE_LABEL',
        changeCode: 'Змінити @:common.PIN_CODE_LABEL',
        addEntry: 'Додати запис',
        editEntry: 'Редагувати запис',
        editLicense: 'Редагувати ліцензію',
        addLicense: 'Додати ліцензію',
        licenseNumber: 'Номер ліцензії',
        prefferedLocale: 'Бажана локаль',
        oldPassword: 'Старий пароль',
        newPasswordConfirmation: 'Підтвердити новий пароль',
        reading: 'Відображення',
        creating: 'Створення',
        updating: 'Оновлення',
        deleting: 'Видалення',
        importScheduleFile: 'Імпорт розкладу з файлу',
        importSchedule: {
            errors: {
                user_not_found: 'Користувач не знайдений [{user}]',
                user_found_many: 'Знайдено {count} користувачів [{user}]',
                event_collision: 'зіткнення подій',
                hour_cell_parse: 'Помилка аналізу клітинки',
            },
        },
        oldCode: 'старий @:common.PIN_CODE_LABEL',
        newCodeConfirmation: 'Підтвердження нового @:common.PIN_CODE_LABEL',
        setAccessBasedOnProfile: 'Надати доступ на основі профілю',
        selectAccessMergeType: 'Виберіть спосіб об’єднання дозволів',
        noAccessesInProfileMessage:
            'Вибраний профіль не має дозволів. Ви все одно хочете перезаписати?',
    },
    breadCrumb: {
        configuration: 'Конфігурація',
        locations: 'Місця',
        home: 'Домашня сторінка',
        machines: 'Машини',
        tools: 'Інструменти',
        issues: 'Проблеми',
        notes: 'Нотатки',
        maintenance: 'Обслуговування',
        parts: 'Частини',
        warehouse: 'Склад',
        dictionary: 'Словник',
        categories: 'Категорії',
        parameters: 'Параметри',
        costCenter: 'Центр витрат',
        flows: 'Потоки',
        states: 'Стани',
        transitions: 'Переходи',
        shiftSchemas: 'Графіки змін',
        shifts: 'Зміни',
        definitionsOfMachinesAndToolsTypes:
            'Визначення типів машин та інструментів',
        add: 'Додати',
        edit: 'Редагувати',
        staff: 'Персонал',
        licenses: 'Ліцензії',
        entries: 'Записи',
        companiesDatabase: 'База даних компаній',
        companyManagement: 'Керування компанією',
        companyData: 'Дані компанії',
        supportedLanguages: 'Підтримувані мови',
        en: 'Англійська',
        pl: 'Польська',
        es: 'Іспанська',
        de: 'Німецька',
        it: 'Італійська',
        fr: 'Французька',
        ru: 'Російська мова',
        uk: 'Українська',
        pt: 'Португальська',
        tr: 'турецька',
        nl: 'голландська',
        sr: 'сербська',
        ar: 'арабська',
        he: 'іврит',
        defaultLanguage: 'Мова за замовчуванням',
        notifications: 'Сповіщення',
        notificationsManagement: 'Керування сповіщеннями',
        myProfile: 'Мій профіль',
    },
    attributeTypes: {
        text: 'Текст',
        note: 'Нотатка',
        integer: 'Ціле число',
        decimal: 'Десяткове число',
        date: 'Дата',
        time: 'Час',
        dateTime: 'Дата та час',
        color: 'Колір',
        icon: 'Іконка',
        boolean: 'Так/Ні',
    },
    costCalculator: {
        costCalculator: 'Калькулятор вартості',
        waitTime: 'Час очікування',
        serviceTime: 'Час сервісу',
        suspendTime: 'Час призупинення',
        totalTime: 'Загальний час',
        estimatedCost: 'Оцінена вартість',
        calculate: 'Обчислити',
        withoutParts: 'Без деталей',
        timeOfReportingIssue: 'Час повідомлення про проблему',
        timeOfServiceStart: 'Час початку сервісу',
        timeOfReportingEnd: 'Час закінчення сервісу',
        days: 'Дні',
        hours: 'Години',
        minutes: 'Хвилини',
        enterNewTime: 'Введіть новий час',
        fromIssueReportToServiceStart:
            'Час від повідомлення про проблему до початку сервісу',
        fromServiceStartToServiceEnd:
            'Час від початку сервісу до закінчення сервісу',
        workTime: 'Час роботи',
        includeAwaitingTime: 'Включити час очікування',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'Мої проблеми',
                assignedToMe: 'Присвоєно мені',
                assignedToGroup: 'Присвоєно моєму групі',
                tookByMe: 'Взято мною',
                takeIssueBtn: 'Взяти',
                arriveIssueBtn: 'Я прибув',
                finishIssueBtn: 'Завершити',
                history: 'Історія',
                myResponsible: 'Я відповідаю за',
                unassignIssue: 'Скасувати знімок',
            },
        },
    },
    synchronization: {
        enabled: 'Увімкнено',
        hasHeaders: 'Має заголовки',
        leaveFile: 'Залишити файл',
        host: 'Хост',
        port: 'Порт',
        protocol: 'Протокол',
        username: 'Ім`я користувача',
        password: 'Пароль',
        root: 'Кореневий каталог',
        encoding: 'Кодування',
        separator: 'Роздільник',
        externalSystemId: 'ID зовнішньої системи',
        cronExpression: 'Вираз Cron',
        appendFullStock: 'Додати повний запас',
        includeHeaders: 'Включити заголовки',
        withTrashed: 'З видаленими даними',
        filePattern: 'Шаблон файлу',
        categoriesRootCategoryId:
            'Коренева категорія для імпортованих категорій',
        systemKeyname: 'Ідентифікатор системи',
        address: 'Адреса',
        token: 'Токен',
    },
    processes: {
        created_at: 'Створено в',
        started_at: 'Почато в',
        updated_at: 'Оновлено в',
        finished_at: 'Завершено в',
        attempts: 'Спроби',
        queue: 'Черга',
        job_id: 'ID завдання',
        job_uuid: 'UUID завдання',
        progress_max: 'Макс. прогрес',
        progress_now: 'Поточний прогрес',
        outputMessage: 'Вихідне повідомлення',
        status: {
            queued: 'у черзі',
            executing: 'виконується',
            finished: 'завершено',
            failed: 'не вдалося',
            retrying: 'повторна спроба',
            undefined: 'невизначений',
        },
    },
    weekDays: {
        mo: 'Понеділок',
        tu: 'Вівторок',
        we: 'Середа',
        th: 'Четвер',
        fr: 'П`ятниця',
        sa: 'Субота',
        su: 'Неділя',
    },
    reports: {
        reports: 'Звіти',
        dateFrom: 'Дата початку',
        dateTo: 'Дата закінчення',
        types: {
            operation_sheet: 'Операційний аркуш',
            issue_summary: 'підсумок проблеми',
            grouped_summary: 'підсумковий звіт',
            storage_operations: 'Складські операції',
            part_quantity_export: 'Кількість запчастин',
        },
        reportFor: 'Звіт за',
        includeQuantityLeft0: 'Включіть частини з нульовою кількістю',
        dayMinusN: 'День мінус n',
        currentWeek: 'Поточний тиждень',
        lastWeek: 'Минулий тиждень',
        currentMonth: 'Поточний місяць',
        lastMonth: 'Минулий місяць',
        currentYear: 'Поточний рік',
        lastYear: 'Минулий рік',
        nDays: 'Кількість днів',
        todayMinusN: 'Сьогодні мінус n',
    },
    logs: {
        comment: 'коментар',
        issues: {
            escalation: 'Ескалація ({minutes} хв) - надіслано повідомлення',
        },
        issueEmployee: {
            assignedAt: 'Працівник призначений:',
            receivedAt: 'Працівник отримав:',
            tookAt: 'Працівник взяв на себе',
            finishedAt: 'Працівник завершив',
            deletedAt: 'Працівник видалений з звернення',
        },
    },
    units: {
        no: 'шматок',
        st: 'встановити',
        pr: 'пара',
    },
    rrule: {
        frequency: 'частота',
        dtstart: 'Дата початку',
        tzid: 'Часовий пояс',
        until: 'доки',
        count: 'Рахувати',
        interval: 'інтервал',
        wkst: 'День початку',
        byweekday: 'По буднях',
        bymonth: 'По місяцях',
        yearly: 'щорічно',
        monthly: 'щомісяця',
        weekly: 'щотижня',
        daily: 'щодня',
        hourly: 'щогодини',
        minutely: 'щохвилини',
        secondly: 'По друге',
        infinitePeriodicityMessage:
            'Періодичність нескінченна, через продуктивність відображається лише 500 рядків',
    },
    months: {
        jan: 'січня',
        feb: 'лютий',
        mar: 'березень',
        apr: 'квітень',
        may: 'Може',
        jun: 'червень',
        jul: 'липень',
        aug: 'серпень',
        sep: 'Вересень',
        oct: 'жовтень',
        nov: 'Листопад',
        dec: 'Грудень',
    },
    charts: {
        charts: 'діаграми',
        count: 'Кількість питань',
        total: 'Загальний час випуску (від звіту до завершення)',
        serw: 'Загальний час ремонту (Ttotal - Twait)',
        mttr1: 'Середній час від звіту до завершення',
        mttr2: 'Середній час від знімка до завершення',
        mtbf: 'Середній час роботи між проблемами',
        mttf: 'Середній час до відмови',
        type: 'Тип діаграми',
        pie: 'Кругова діаграма',
        bar: 'Гістограма',
        query: 'Обсяг даних',
        groupBy: 'Групувати за',
        sum: 'сума',
        avg: 'Середній',
        min: 'мінімум',
        max: 'Максимум',
        eq: 'Дорівнює',
        neq: 'Це не дорівнює',
        gt: 'Більш ніж',
        lt: 'Менше ніж',
        gte: 'Більше або рівно',
        lte: 'Менше або рівно',
        in: 'Містить',
        nin: 'Не містять',
        btw: 'Між',
        chartOptions: 'Параметри діаграми',
        line: 'Лінійний графік',
        mixed: 'змішаний',
        chartDisplayOptions: 'Параметри відображення',
        fillGaps: 'Заповнити прогалини',
    },
    regexBuilder: {
        regexValue: 'Значення',
        global: 'Глобальний',
        multiline: 'Багаторядковий',
        insensitive: 'Нечутливий до регістру',
        extended: 'Розширений',
        singleline: 'Однорядковий',
        unicode: 'Юнікод',
        Ungreedy: 'Ліниві кількості',
        Anchored: 'Закріплений на початку шаблону або в кінці останнього збігу',
        Jchanged: 'Дозволити повторювані імена підшаблонів',
        DollarEndOnly: '$ відповідає лише кінцю шаблону',
        testValue: 'Тестове значення',
    },
    tpm: {
        groupableHeadersPlaceholder:
            'Перетягніть заголовок таблиці для групування даних.',
        steps: 'Кроки',
        step: 'Крок',
        content: 'Зміст',
        history: 'Історія',
        standardObstacle: 'Стандартна перешкода',
        customObstacle: 'Спеціальна перешкода',
        bhp: 'Безпека та гігієна праці',
        returnToMainPage: 'Повернутися до головної сторінки',
        bhpMessage:
            'Якщо ви помічаєте ризики для безпеки, негайно повідомте про це вашого безпосереднього керівника та скористайтеся формою повідомлення, що знаходиться під оголошувальною дошкою.',
        ok: 'ОК',
        send: 'Надіслати',
        description: 'Опис',
        title: 'Назва',
        realization: 'Реалізація',
        answer: 'Відповідь',
        pendingSurveys: 'Очікуючі опитування',
        surveys: 'Опитування',
        systemSurvey: 'Системне опитування',
        addPage: 'Додати сторінку',
        createSurvey: 'Створити опитування',
        addSection: 'Додати розділ',
        addQuestion: 'Додати питання',
        questionContent: 'Зміст питання',
        answerOption: 'Варіант відповіді',
        addQuestionAnswer: 'Додати варіант відповіді',
        surveyName: 'Назва опитування',
        sectionName: 'Назва розділу',
        noSurveyAnswers: 'Немає відповідей',
        removeSection: 'Видалити розділ',
        published: 'Опубліковано',
        unpublished: 'Неопубліковано',
        pendingPublication: 'Зміни очікують на публікацію',
        removeQuestion: 'Видалити питання',
        removeAnswer: 'Видалити варіант відповіді',
        publish: 'Опублікувати',
        unpublish: 'Зняти з публікації',
        previewResults: 'Переглянути результати',
        supervisior: 'Керівник',
        surveyResults: 'Результати опитування',
        surveysEvents: 'Конфігурація подій - опитування',
        operatedBy: 'Управляється',
        confirmReadInformations: 'Підтверджую, що прочитав інформацію',
        noticeContent: 'Вміст повідомлення',
        notice: 'Повідомлення',
        surveyEventName: 'Назва події',
        survey: 'Опитування',
        newIssue: 'Нове питання',
        surveyEventConfigurationAlreadyExists: 'Конфігурація події вже існує',
        lastComment: 'Останній коментар відповідальної особи',
        surveyNotConfigured: 'Опитування не налаштовано',
        fillSurvey: 'Заповнити опитування',
        singleSurveyEntries: 'одиничні результати',
        groupedSurveyEntries: 'Згруповані результати',
        publicationId: 'ID публікації',
        publicationDate: 'Дата публікації',
        versionId: 'ідентифікатор версії',
        answersCount: 'Кількість відповідей',
        answers: 'Відповіді',
        withoutSupervisor: 'Немає наглядача',
        defaultSupervisor: 'Наглядач за замовчуванням',
        flowStateFlags: 'Прапори стану',
        qrActionsSettings: 'Налаштування дій QR-коду',
        loginRequired: 'Потрібен вхід',
        questionContext: 'контекст питання',
        currentSurveyVersionPreview:
            'Попередній перегляд поточної версії опитування',
        surveyDescription: 'Опис опитування',
        sectionDescription: 'Опис розділу',
        instructions: 'Інструкції',
        instruction: 'інструкція',
        instructionRevisions: 'Ревізії',
        instructionRevision: 'Ревізія',
        checklists: 'Контрольні списки',
        checklist: 'Контрольний список',
        checklistItem: 'Пункт списку',
        manageChecklistFields: 'Керуйте елементами списку',
        checklistItemDisplayOptions: 'Показані елементи',
        revisionAutoRejectedInfo: '[Система] Відхилено – створено нову версію',
    },
    accessMergeValues: {
        sum: 'сума',
        product: 'Продукт',
        overwrite: 'перезаписати',
    },
    aggregates: {
        count: 'сума',
        min: 'мінімум',
        max: 'Максимум',
    },
    checklists: {
        itemBasic: 'Базовий елемент',
        itemSeparator: 'Роздільник',
        itemSection: 'Розділ',
        publish: 'Опублікувати',
        previewCurrentVersion: 'Попередній перегляд поточної версії',
    },
    cluer: {
        groupableHeadersPlaceholder:
            'Перетягніть заголовок таблиці для групування даних.',
        history: 'Історія',
        standardObstacle: 'Стандартна перешкода',
        customObstacle: 'Спеціальна перешкода',
        bhp: 'Безпека та гігієна праці',
        returnToMainPage: 'Повернутися до головної сторінки',
        bhpMessage:
            'Якщо ви помічаєте ризики для безпеки, негайно повідомте про це вашого безпосереднього керівника та скористайтеся формою повідомлення, що знаходиться під оголошувальною дошкою.',
        ok: 'ОК',
        send: 'Надіслати',
        description: 'Опис',
        title: 'Назва',
        realization: 'Реалізація',
        answer: 'Відповідь',
        pendingSurveys: 'Очікуючі опитування',
        surveys: 'Опитування',
        systemSurvey: 'Системне опитування',
        addPage: 'Додати сторінку',
        createSurvey: 'Створити опитування',
        addSection: 'Додати розділ',
        addQuestion: 'Додати питання',
        questionContent: 'Зміст питання',
        answerOption: 'Варіант відповіді',
        addQuestionAnswer: 'Додати варіант відповіді',
        surveyName: 'Назва опитування',
        sectionName: 'Назва розділу',
        noSurveyAnswers: 'Немає відповідей',
        removeSection: 'Видалити розділ',
        published: 'Опубліковано',
        unpublished: 'Неопубліковано',
        pendingPublication: 'Зміни очікують на публікацію',
        removeQuestion: 'Видалити питання',
        removeAnswer: 'Видалити варіант відповіді',
        publish: 'Опублікувати',
        unpublish: 'Зняти з публікації',
        previewResults: 'Переглянути результати',
        supervisior: 'Керівник',
        surveyResults: 'Результати опитування',
        surveysEvents: 'Конфігурація подій - опитування',
        operatedBy: 'Управляється',
        confirmReadInformations: 'Підтверджую, що прочитав інформацію',
        noticeContent: 'Вміст повідомлення',
        notice: 'Повідомлення',
        surveyEventName: 'Назва події',
        survey: 'Опитування',
        newIssue: 'Нове питання',
        surveyEventConfigurationAlreadyExists: 'Конфігурація події вже існує',
        lastComment: 'Останній коментар відповідальної особи',
        surveyNotConfigured: 'Опитування не налаштовано',
        fillSurvey: 'Заповнити опитування',
        singleSurveyEntries: 'одиничні результати',
        groupedSurveyEntries: 'Згруповані результати',
        publicationId: 'ID публікації',
        publicationDate: 'Дата публікації',
        versionId: 'ідентифікатор версії',
        answersCount: 'Кількість відповідей',
        answers: 'Відповіді',
        withoutSupervisor: 'Немає наглядача',
        defaultSupervisor: 'Наглядач за замовчуванням',
        flowStateFlags: 'Прапори стану',
        qrActionsSettings: 'Налаштування дій QR-коду',
        loginRequired: 'Потрібен вхід',
        questionContext: 'контекст питання',
        currentSurveyVersionPreview:
            'Попередній перегляд поточної версії опитування',
        surveyDescription: 'Опис опитування',
        sectionDescription: 'Опис розділу',
    },
    orders: {
        order: 'порядок',
        releaseDate: 'Дата Виконання',
        orderDate: 'дата замовлення',
    },
    production: {
        good: 'Кількість хороших',
        bad: 'Кількість поганих',
        productionRecords: 'Виробничий реєстр',
    },
};
