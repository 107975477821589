<template>
    <SignaloDialog :open="true" :title="$getPropertyTranslation(editedItem?.name)" :icon="'mdi-playlist-edit'"
        @close="closeDialog" @save="save">
        <template #body>
            <v-card-text>
                <SignaloPageLoading :loading="loading" v-if="loading" />
                <SignaloPlaylistManager v-else @update:model-value="onUpdatePlaylist" :modelValue="playlist_content"
                    @playlistValid="onPlaylistValidChange" />
            </v-card-text> </template>
    </SignaloDialog>
</template>
<script>
import SignaloPlaylistManager from '@/components/SignaloPlaylistManager/SignaloPlaylistManager.vue';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    components: { SignaloPlaylistManager },
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    props: ['editedItem', 'courseId'],
    data() {
        return {
            loading: true,
            dataValid: false,
            playlist_content: null
        };
    },
    mounted() {
        this.loadPlaylistContent();
    },
    methods: {
        async loadPlaylistContent() {
            try {
                this.loading = true;
                const response = await this.axios.get(`courses/${this.courseId}/steps/${this.editedItem.id}`);
                this.playlist_content = response?.data?.item?.playlist?.content;
            } catch (err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        onUpdatePlaylist(value) {
            this.playlist_content = value;
        },
        async save() {
            try {

                this.loading = true;
                await this.axios.patch(`courses/${this.courseId}/steps/${this.editedItem.id}`, {
                    playlist_content: this.playlist_content
                });

                this.helpersStore.snackbarSaved();
                this.loading = false;
                this.closeDialog(true);
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
        },

        closeDialog(shouldRefetch = false) {
            this.$emit('close', shouldRefetch);
        },
    },
};
</script>
