<template>
    <h4 class="mb-1 d-flex">
        <span>{{ title }}</span>
        <v-spacer />
        <v-btn
            v-if="modelValue !== field && !disabled"
            :disabled="modelValue !== null"
            size="x-small"
            variant="text"
            icon
            color="primary"
            @click="editClicked"
        >
            <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
            v-if="modelValue === field"
            size="x-small"
            variant="text"
            icon
            color="warning"
            @click="
                $emit('update:modelValue', null);
                $emit('click:cancel');
            "
        >
            <v-icon> mdi-close </v-icon>
        </v-btn>
        <v-btn
            v-if="modelValue === field"
            size="x-small"
            variant="text"
            icon
            color="success"
            @click="
                $emit('click:save');
                $emit('save', field);
            "
            :disabled="saveDisabled"
        >
            <v-icon> mdi-check </v-icon>
        </v-btn>
    </h4>
</template>

<script>
export default {
    props: {
        title: String,
        field: String,
        modelValue: Object,
        custom: {
            type: Boolean,
            default: false,
        },
        saveDisabled: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'update:modelValue',
        'save',
        'click:edit',
        'click:cancel',
        'click:save',
    ],
    methods: {
        editClicked() {
            this.$emit('click:edit');
            if (this.custom) return;
            this.$emit('update:modelValue', this.field);
        },
    },
};
</script>
