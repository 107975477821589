<template>
    <SignaloPageLoading v-if="loading" />
    <div v-else>
        <div class="add-new-equipment-type-parameter-button-container">
            <v-btn
                color="primary"
                :style="'margin-left: auto;'"
                variant="elevated"
                @click="addItemClicked"
                v-if="$route.meta.canCreate"
                >{{ $t('common.add') }}</v-btn
            >
        </div>
        <EasyDataTable
            buttons-pagination
            :headers="tableHeaders"
            :items="items"
            :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style margin-0"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            :rows-items="[5, 10, 25, 50, 75, 100]"
            :rows-per-page="5"
        >
            <template #item-name="item">
                {{ $getPropertyTranslation(item.name) }}
            </template>
            <template #item-type="item">
                {{
                    $getPropertyTranslation(
                        parametersTypes.find((t) => t.value === item.type)
                            ?.label
                    )
                }}
            </template>
            <template #item-required="item">
                <v-icon v-if="item?.required">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <template #item-operation="item">
                <div class="actions-column">
                    <v-menu
                        offset-y
                        v-if="$route.meta.canUpdate || $route.meta.canDelete"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                color="primary"
                                v-bind="props"
                                variant="outlined"
                                icon
                                class="table-action-icon-button"
                            >
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="onEdit(item)"
                                v-if="$route.meta.canUpdate"
                            >
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-pencil
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.edit')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click="onRemove(item)"
                                v-if="$route.meta.canDelete"
                            >
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-delete
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.remove')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </EasyDataTable>
        <SignaloParametersManageDialog
            v-if="manageDialog"
            :open="manageDialog"
            :mode="dialogMode"
            :editedItem="editedItem"
            @closeDialog="onCloseDialogHandler"
            @onSave="onParameterSaveHandler"
            :title="$t('parameters.equipmentTypeParameter')"
        />
    </div>
</template>

<script>
import AVAILABLE_PARAMETER_TYPES from '@/CONFIG/AVAILABLE_PARAMETER_TYPES';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    props: ['itemId'],
    // emits: ['update:modelValue'],
    setup() {
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        return { helpersStore };
    },
    data() {
        return {
            loading: true,
            items: [],
            manageDialog: false,
            editedItem: null, //
            parametersManageLoading: false,
            dialogMode: null,
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.loading = true;
            try {
                const data = await this.axios.get(
                    `/equipment_types/${this.itemId}/parameters`
                );
                this.items = data?.data?.items || [];
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        async onParameterSaveHandler(data) {
            this.parametersManageLoading = true;
            let url = `/equipment_types/${this.itemId}/parameters`;
            let method;
            this.dialogMode === 'edit' ? (method = 'PUT') : (method = 'POST');
            if (this.dialogMode === 'edit') {
                url += '/' + this.editedItem.id;
            }
            await this.axios
                .request({ url, method, data })
                .then((/*{ data }*/) => {
                    this.helpersStore.snackbar(
                        this.$t('common.saved'),
                        'success'
                    );
                    this.manageDialog = false;
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                })
                .finally(() => {
                    this.parametersManageLoading = false;
                    this.loadData();
                });
        },
        onCloseDialogHandler() {
            this.dialogMode = null;
            this.manageDialog = false;
        },
        addItemClicked() {
            this.editedItem = null;
            this.dialogMode = 'create';
            this.manageDialog = true;
        },
        onEdit(item) {
            this.editedItem = item;
            this.dialogMode = 'edit';
            this.manageDialog = true;
        },
    },
    computed: {
        parametersTypes() {
            return AVAILABLE_PARAMETER_TYPES;
        },
        tableHeaders() {
            return [
                { text: this.$t('tableHeaders.name'), value: 'name' },
                { text: this.$t('tableHeaders.type'), value: 'type' },
                { text: this.$t('tableHeaders.required'), value: 'required' },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
    },
};
</script>

<style>
.add-new-equipment-type-parameter-button-container {
    display: flex;
}
.margin-0 {
    margin: 0;
}
</style>
