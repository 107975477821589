<template>
    <SignaloDialog
        :icon="'mdi-cog-outline'"
        :title="`${user?.name} ` + $t('common.notificationsManagement')"
        :open="true"
        @close="$emit('close')"
        :constHeight="true"
        :hideActions="true"
    >
        <template #body>
            <v-card-text>
                <SignaloNotificationsManagementIndex :user="user" :mode="'staff'"/>
            </v-card-text>
        </template>
    </SignaloDialog>
</template>

<script>

export default {
    props: ["user"],
};
</script>
