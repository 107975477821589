<template>
    <SignaloDialog
        :open="open"
        :title="
            currentlyEditedCategory?.id === undefined
                ? $t('common.addCategory')
                : $t('common.editCategory')
        "
        @close="$emit('close')"
        :constHeight="false"
    >
        <template #body>
            <div v-if="currentlyEditedCategory">
                <v-form ref="categoryDataForm">
                    <SignaloTranslatableTabs
                        v-slot="{ value, update, disabled }"
                        v-model="currentlyEditedCategory.name"
                        :currentLanguage="$i18n?.locale"
                        :availableLocales="getAvailableLocales()"
                        :autoTranslateEnabled="true"
                    >
                        <v-card-text>
                            <v-text-field
                                :label="$t('common.name')"
                                :modelValue="value"
                                @update:modelValue="update"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                required
                                class="required-field"
                                hide-details
                                single-line
                                :disabled="disabled"
                            >
                            </v-text-field>
                        </v-card-text>
                    </SignaloTranslatableTabs>
                    <v-card-text class="category-form-inputs">
                        <v-checkbox
                            v-model="currentlyEditedCategory.can_be_picked"
                            hide-details
                            color="primary"
                            :label="$t('common.canBePicked')"
                            single-line
                        />
                        <v-checkbox
                            color="primary"
                            v-model="
                                currentlyEditedCategory.restrict_group_access
                            "
                            :label="$t('common.restrictGroupAccess')"
                            hide-details
                            single-line
                        />
                        <v-checkbox
                            color="primary"
                            v-model="
                                currentlyEditedCategory.closable
                            "
                            :label="$t('common.closable')"
                            hide-details
                            single-line
                        />
                        <v-checkbox
                            color="primary"
                            v-model="
                                currentlyEditedCategory.hidden
                            "
                            :label="$t('common.hidden')"
                            hide-details
                            single-line
                        />
                        <SignaloIconPicker
                            v-model="icon"
                            :required="false"
                            :errorMessages="iconErrorMessage"
                        />
                        <div
                            class="category-color-container category-color-container-inherited"
                            v-if="
                                currentlyEditedCategory.color_inherited &&
                                currentlyEditedCategory.color === null
                            "
                        >
                            <SignaloColorPicker
                                label="common.colorInherited"
                                :disabled="true"
                                :modelValue="
                                    currentlyEditedCategory.color_inherited
                                "
                            />
                        </div>
                        <div class="category-color-container">
                            <SignaloColorPicker
                                :modelValue="currentlyEditedCategory.color"
                                @update:modelValue="updateColorHandler"
                            />
                        </div>
                    </v-card-text>
                </v-form>
            </div>
        </template>
        <template #buttons>
            <v-btn text @click="$emit('close')">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                variant="elevated"
                @click="saveCategory"
                color="primary"
                :loading="saveLoading"
                :disabled="
                    currentlyEditedCategory?.name?.auto_translate  ? !currentlyEditedCategory?.name?.[$i18n.locale] : !authStore.tenant.supportedLocales.every(
                        (al) => !!currentlyEditedCategory?.name?.[al]
                    )
                "
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const authStore = AUTH_STORE.default();
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { authStore, dataStore, helpersStore };
    },
    emits: ['close'],
    props: ['open', 'category', 'currentId'],
    data() {
        return {
            saveLoading: false,
            currentlyEditedCategory: this.category ? { ...this.category } : {},
            editCategoryFormValid: false,
            icon: this.category?.icon || null,
            colorPickerOpened: false,
            iconErrorMessage: {},
        };
    },
    watch: {
        icon() {
            this.iconErrorMessage = [];
        },
    },
    mounted() {
        this.dataStore.groups.promise();
    },
    methods: {
        async saveCategory() {
            this.iconErrorMessage = {};
            this.saveLoading = true;
            this.currentlyEditedCategory.icon = this.icon || null;
            try {
                if (this.currentlyEditedCategory?.id) {
                    await this.axios.put(
                        `/categories/${this.currentlyEditedCategory.id}`,
                        this.currentlyEditedCategory
                    );
                } else {
                    if (this.currentId !== 0) {
                        this.currentlyEditedCategory.parent_id = this.currentId;
                    }
                    await this.axios.post(
                        `/categories`,
                        this.currentlyEditedCategory
                    );
                }
                await this.dataStore.categories.reload();
                this.helpersStore.snackbar(
                    this.$t('common.updated'),
                    'success'
                );
                this.$emit('close');
                this.saveLoading = false;
            } catch (err) {
                console.error(err);
                this.saveLoading = false;
                if (err?.response?.status == 401) return;
                if (
                    err?.response?.status === 422 &&
                    err?.response?.data?.message?.includes(
                        'The icon has already been taken.'
                    )
                ) {
                    this.iconErrorMessage = this.$t('errors.iconAlreadyTaken');
                }
            }
        },
        updateColorHandler(value) {
            this.currentlyEditedCategory.color = value;
        },
    },
};
</script>

<style>
.category-color-container-inherited .v-input {
    min-width: 160px;
}
.category-color-container-inherited {
    cursor: not-allowed !important;
}
.category-color-container-inherited .v-field--disabled {
    opacity: 1 !important;
}
</style>
