<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.jobStatusses') }} </template>
        <template #bar>
            <v-btn @click="() => loadData()" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <EasyDataTable buttons-pagination
            :headers="tableHeaders"
            :items="items"
            :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            @click-row="handleClickRow"
            v-model:server-options="serverOptions"
            :server-items-length="serverItemsLength"
            :rows-items="[5, 10, 25, 50, 75, 100]"
            :rows-per-page="5"
            :loading="loading"
            name="jobStatusesIndexTable"
        >
            <template #item-created_at="item">{{
                new Date(item.created_at).toLocaleString()
            }}</template>
            <template #item-started_at="item">{{
                new Date(item.started_at).toLocaleString()
            }}</template>
            <template #item-updated_at="item">{{
                new Date(item.updated_at).toLocaleString()
            }}</template>
            <template #item-finished_at="item">{{
                new Date(item.finished_at).toLocaleString()
            }}</template>
            <template #item-status="item">{{
                $t(`processes.status.${item.status}`)
            }}</template>
            <template #item-progress_now="item">
                <SignaloProcessProgressBar
                    :jobStatusId="item.id"
                    :progress_now="item.progress_now"
                />
            </template>
        </EasyDataTable>
    </v-main>
</template>
<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, authStore };
    },
    data() {
        return {
            items: [],
            loading: false,
            serverOptions: this.$getServerOptions('jobStatusesIndexTable'), 
            serverItemsLength: 0,
            refreshIntervalId: null,
            refreshCounter: 60,
        };
    },
    mounted() {
        this.loadData();
        this.setRefreshInterval();
    },
    methods: {
        async loadData() {
            this.refreshCounter = 60;
            this.loading = true;
            const options = this.dataStore.jobStatuses.makeServerOptions(
                this.serverOptions
            );
            this.$router.replace({ query: options.params });
            const jobStatussesData = await this.axios.get(
                'process/job_statuses',
                options
            );
            this.items = jobStatussesData?.data?.items || [];
            this.serverItemsLength = jobStatussesData?.data?.meta?.total;
            this.loading = false;
        },
        handleClickRow(item) {
            this.$router.push({
                name: 'jobStatusses.show',
                params: {
                    id: item.id,
                },
            });
        },
        setRefreshInterval() {
            if (this.refreshIntervalId) {
                clearInterval(this.refreshIntervalId);
            }
            this.refreshIntervalId = setInterval(() => {
                this.refreshCounter--;
                if (this.refreshCounter <= 0) {
                    this.authStore.isLoggedIn
                        ? this.loadData()
                        : clearInterval(this.refreshIntervalId);
                }
            }, 1000);
        },
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences('jobStatusesIndexTable', {
                default_size: val.rowsPerPage,
            });
            this.loadData();
        },
    },
    computed: { 
        tableHeaders() {
            return [
                { text: this.$t('tableHeaders.id'), value: 'job_id' },
                {
                    text: this.$t('processes.created_at'),
                    value: 'created_at',
                },
                {
                    text: this.$t('processes.started_at'),
                    value: 'started_at',
                },
                {
                    text: this.$t('processes.updated_at'),
                    value: 'updated_at',
                },
                {
                    text: this.$t('processes.finished_at'),
                    value: 'finished_at',
                },
                { text: this.$t('processes.attempts'), value: 'attempts' },
                {
                    text: this.$t('tableHeaders.progress'),
                    value: 'progress_now',
                },
                { text: this.$t('tableHeaders.status'), value: 'status' },
                { text: this.$t('tableHeaders.type'), value: 'type' },
            ];
        },
    },
    unmounted() {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }
    },
};
</script>
