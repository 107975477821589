<template>
    <SignaloDialog
        :open="open"
        @close="closeDialog"
        :title="$t('common.externalSystem')"
        :icon="'mdi-cog-outline'"
        :dialogClasses="['external-system-dialog']"
    >
        <template #body>
            <PageLoading v-if="loading" />
            <v-form ref="dataForm" v-model="dataValid" lazy-validation v-else>
                <v-col cols="12" md="12" xl="12">
                    <v-text-field
                        :label="$t('common.name')"
                        v-model="name"
                        :rules="required"
                        variant="outlined"
                        required
                        color="var(--greyscale-100)"
                        class="required-field"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="12" xl="12">
                    <v-text-field
                        :label="$t('common.key')"
                        v-model="key"
                        variant="outlined"
                        color="var(--greyscale-100)"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="12" xl="12">
                    <v-select
                        :label="$t('common.timezone')"
                        v-model="timezone"
                        :items="timezones"
                        variant="outlined"
                        color="var(--greyscale-100)"
                        @click:clear="timezone = null"
                        clearable
                        :no-data-text="$t('common.emptyTableMessage')"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" md="12" xl="12">
                    <v-checkbox
                        color="primary"
                        v-model="enabled"
                        :label="$t('common.active')"
                        hide-details
                        single-line
                    ></v-checkbox>
                </v-col>
            </v-form>
        </template>
        <template #buttons>
            <v-btn @click="closeDialog(false)">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                :loading="dataSaving"
                color="primary"
                @click="saveData"
                variant="elevated"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import PageLoading from '@/components/global/PageLoading.vue';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { authStore, helpersStore };
    },
    props: ['mode', 'editedItem'],
    data() {
        return {
            name: this.editedItem?.name || null,
            enabled: this.editedItem?.enabled || false,
            key: this.editedItem?.key || null,
            timezone: this.editedItem?.timezone || this.authStore.user.timezone,
            timezones: [],
            loading: false,
            dataValid: false,
        };
    },
    created() {
        this.loadTimezones();
    },
    methods: {
        closeDialog(refetchData = false) {
            this.$emit('closeDialog', refetchData);
        },
        async loadTimezones() {
            this.loading = true;
            try {
                const tzData = await this.axios.get('global/timezones');
                this.timezones = tzData?.data?.items;
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        async saveData() {
            this.loading = true;
            await this.$refs?.dataForm?.validate();
            if (!this.dataValid) return;
            try {
                if (this.editedItem?.id) {
                    await this.axios.put(`systems/${this.editedItem.id}`, {
                        name: this.name,
                        timezone: this.timezone,
                        key: this.key,
                        enabled: this.enabled,
                    });
                } else {
                    await this.axios.post('systems', {
                        name: this.name,
                        timezone: this.timezone,
                        key: this.key,
                        enabled: this.enabled,
                    });
                }
                this.helpersStore.snackbarSaved();
                this.closeDialog(true);
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
    },
    computed: {
        required() {
            return [(v) => !!v || this.$t('common.fieldRequired')];
        },
    },
    components: { PageLoading },
};
</script>
<style>
.external-system-dialog {
    max-width: 600px;
}
</style>
