<template>
    <div class="playlist-manager-container">
        <div class="playlist-manager-sections">
            <v-form ref="dataForm" v-model="dataValid" lazy-validation>
                <v-switch
                    v-model="singleSection"
                    :label="$t('common.noSections')"
                    color="primary"
                ></v-switch>
                <v-expansion-panels
                    variant="accordion"
                    class="draggable-section-item-container"
                    v-model="openedPanel"
                    :readonly="singleSection"
                >
                    <draggable v-model="sections">
                        <template #item="section">
                            <v-expansion-panel>
                                <template #title>
                                    <v-icon
                                        v-if="!singleSection"
                                        class="grab-section-icon"
                                        >mdi-drag</v-icon
                                    >
                                    <v-text-field
                                        v-if="!singleSection"
                                        :label="$t('tpm.sectionName')"
                                        v-model="section.element.name"
                                        color="var(--greyscale-100)"
                                        :rules="[$REQUIRED_RULE]"
                                        class="required-field playlist-section-title-v-text-field"
                                        compact="compact"
                                        hide-details
                                        variant="outlined"
                                        @click="
                                            ($event) => {
                                                $event.stopPropagation();
                                                $event.preventDefault();
                                            }
                                        "
                                    >
                                    </v-text-field>
                                    <v-btn
                                        v-if="!singleSection"
                                        @click="
                                            ($event) =>
                                                onRemoveSection(
                                                    $event,
                                                    section?.element
                                                )
                                        "
                                        variant="text"
                                        color="warning"
                                        style="cursor: pointer"
                                        :icon="'mdi-delete'"
                                        siez="small"
                                    ></v-btn>
                                </template>

                                <template #text>
                                    <div
                                        class="playlist-section-assets-container"
                                    >
                                        <div
                                            class="playlist-section-assets-actions-container"
                                        >
                                            <SignaloFilesManager
                                                :returnObject="true"
                                                @update:modelValue="
                                                    (assets) =>
                                                        onSelectAssets(
                                                            assets,
                                                            section?.element
                                                        )
                                                "
                                                :modelValue="
                                                    section?.element.assets
                                                "
                                                :buttonLabel="
                                                    $t('common.selectAssets')
                                                "
                                            />
                                        </div>
                                        <div
                                            class="playlist-section-assets mt-4"
                                        >
                                            <draggable
                                                v-model="section.element.assets"
                                            >
                                                <template #item="asset">
                                                    <v-card
                                                        class="section-asset-card"
                                                    >
                                                        <v-list-item
                                                            class="section-asset"
                                                            :title="
                                                                asset?.element
                                                                    ?.name
                                                            "
                                                            :prepend-icon="'mdi-drag'"
                                                        >
                                                            <template #prepend>
                                                                <v-icon
                                                                    class="grab-section-icon"
                                                                    >mdi-drag</v-icon
                                                                >
                                                                <div
                                                                    v-if="
                                                                        asset
                                                                            ?.element
                                                                            ?.path_thumbnail &&
                                                                        [
                                                                            'image',
                                                                            'video',
                                                                        ].includes(
                                                                            asset
                                                                                ?.element
                                                                                ?.type
                                                                        )
                                                                    "
                                                                    class="asset-thumbnail"
                                                                >
                                                                    <div
                                                                        class="signalo-playlist-manager-image-container"
                                                                        v-if="
                                                                            asset
                                                                                ?.element
                                                                                ?.path_thumbnail
                                                                        "
                                                                    >
                                                                        <img
                                                                            :src="
                                                                                storageUrl +
                                                                                asset
                                                                                    ?.element
                                                                                    ?.path_thumbnail
                                                                            "
                                                                            class="signalo-playlist-manager-table-image"
                                                                            :height="
                                                                                108
                                                                            "
                                                                            :width="
                                                                                192
                                                                            "
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        class="signalo-playlist-manager-image-placeholder"
                                                                        v-else
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'common.noImage'
                                                                            )
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </template>
                                                            <template #append>
                                                                <v-btn
                                                                    @click="
                                                                        () =>
                                                                            onRemoveAsset(
                                                                                asset
                                                                                    ?.element
                                                                                    ?.id,
                                                                                section?.element
                                                                            )
                                                                    "
                                                                    variant="text"
                                                                    color="warning"
                                                                    style="
                                                                        cursor: pointer;
                                                                    "
                                                                    :icon="'mdi-delete'"
                                                                    siez="small"
                                                                ></v-btn>
                                                            </template>
                                                        </v-list-item>
                                                    </v-card>
                                                </template>
                                            </draggable>
                                        </div>
                                    </div>
                                </template>
                            </v-expansion-panel>
                        </template>
                    </draggable>
                </v-expansion-panels>
            </v-form>

            <SignaloConfirmationDialog
                v-if="removeAssetDialog"
                :dialogOpened="removeAssetDialog"
                @update:dialogOpened="(value) => onRemoveAssetHandler(value)"
            />
            <SignaloConfirmationDialog
                v-if="removeSectionDialog"
                :dialogOpened="removeSectionDialog"
                @update:dialogOpened="(value) => onRemoveSectionHandler(value)"
            />
        </div>
        <div class="playlist-manager-actions-container" v-if="!singleSection">
            <v-btn variant="elevated" @click="addSection">
                {{ $t('tpm.addSection') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { SEARCH_FIELD_TYPES } from 'signalo-vue-search-container';
import { v4 as uuidv4 } from 'uuid';
export default {
    props: ['modelValue'],
    emits: ['update:modelValue', 'playlistValid'],
    data() {
        return {
            editedItem: null,
            removeAssetDialog: false,
            removeSectionDialog: false,
            sections: this.modelValue ? [...this.modelValue] : [],
            assetsPickerSortOptions: {
                sortBy: 'created_at',
                sortType: 'desc',
            },
            dataValid: false,
            storageUrl: this.getFileStorageUrl() + '/',
            openedPanel: this.modelValue?.[0]?.singleSection ? 0 : null,
            singleSection: this.modelValue?.[0]?.singleSection,
        };
    },
    mounted() {
        this.checkPlaylistValid();
    },
    methods: {
        addSection() {
            this.sections.push({ id: uuidv4(), assets: null, name: '' });
            this.checkPlaylistValid();
        },
        onSelectAssets(assets, section) {
            section.assets = [...assets];
        },
        onRemoveAssetHandler(shouldRemove) {
            if (shouldRemove) {
                const assetIndex = this.editedItem.section.assets.findIndex(
                    (v) => v.id === this.editedItem.assetId
                );
                const assetsCpy = [...this.editedItem.section.assets];
                if (assetIndex > -1) {
                    assetsCpy.splice(this.editedItem.assetIndex, 1);
                    this.editedItem.section.assets = [...assetsCpy];
                }
            }
            this.editedItem = null;
            this.removeAssetDialog = false;
        },
        onRemoveAsset(assetId, section) {
            this.editedItem = { assetId, section };
            this.removeAssetDialog = true;
            this.$forceUpdate();
        },
        onRemoveSection($event, section) {
            $event.preventDefault();
            $event.stopPropagation();
            this.editedItem = section;
            this.removeSectionDialog = true;
        },
        onRemoveSectionHandler(shouldRemove) {
            if (shouldRemove) {
                const sectionIndex = this.sections.findIndex(
                    (s) => s.id === this.editedItem.id
                );
                if (sectionIndex > -1) {
                    this.sections.splice(sectionIndex, 1);
                }
            }

            this.editedItem = null;
            this.removeSectionDialog = false;
        },
        checkPlaylistValid() {
            if (!this.sections.length) {
                this.$emit('playlistValid', true);
                return;
            }
            const isValid = Boolean(
                this.dataValid &&
                    this.sections.every((s) => s.assets?.length > 0)
            );
            this.$emit('playlistValid', isValid);
        },
    },
    computed: {
        assetsPickerTableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                    extractor: (item) => {
                        return this.$getPropertyTranslation(item?.name);
                    },
                },
                {
                    text: this.$t('common.thumbnail'),
                    value: 'path_thumbnail',
                    image: true,
                },
                {
                    text: this.$t('common.duration'),
                    value: 'duration_s',
                },
                {
                    text: this.$t('common.upload_date'),
                    value: 'created_at',
                    extractor: (item) => {
                        return this.$displayDateWithTimezone(item?.created_at);
                    },
                },
                {
                    value: 'operation',
                    fixed: true,
                    width: 54,
                },
            ];
        },
        searchConfig() {
            return {
                type: {
                    type: SEARCH_FIELD_TYPES.CUSTOM_SELECT,
                    label: this.$t('common.type'),
                    selectOptions: [
                        {
                            value: 'video',
                            label: this.$t('common.video'),
                        },
                        {
                            value: 'image',
                            label: this.$t('tableHeaders.image'),
                        },
                        {
                            value: 'document',
                            label: this.$t('common.document'),
                        },
                    ],
                },
            };
        },
    },
    watch: {
        sections: {
            handler(val) {
                this.$emit('update:modelValue', val);
                this.checkPlaylistValid();
            },
            deep: true,
        },
        dataValid() {
            this.checkPlaylistValid();
        },
        modelValue: {
            handler(val, prevVal) {
                if (JSON.stringify(val) !== JSON.stringify(prevVal)) {
                    this.sections = [...val];
                }
                this.checkPlaylistValid();
            },
            deep: true,
        },
        singleSection(val) {
            if (val) {
                const assets = [];
                this.sections.forEach((s) => {
                    assets.push(...s.assets);
                });
                this.sections = [];
                this.sections.push({
                    id: uuidv4(),
                    assets: [...assets],
                    name: '',
                    singleSection: true,
                });
                this.openedPanel = 0;
            } else {
                this.sections.forEach((s) => {
                    delete s.singleSection;
                });
            }
        },
    },
};
</script>

<style scoped>
.playlist-manager-actions-container,
.playlist-section-assets-actions-container {
    display: flex;
    justify-content: flex-end;
}

.playlist-manager-sections {
    padding: 1rem;
}

.section-asset-card.v-card--variant-elevated.v-card {
    margin-bottom: 1rem;
    border-radius: 0 !important;
    box-shadow: 0px 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
        0px 4px 5px 0px
            var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
        0px 1px 10px 0px
            var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12)) !important;
}

.section-index {
    font-size: 1.25rem;
    font-weight: bold;
    margin-right: 1rem;
    color: var(--ui-text-text-02);
}

.draggable-section-item-container > div {
    width: 100%;
}

.signalo-playlist-manager-image-container,
.signalo-playlist-manager-image-placeholder {
    height: 108px;
    width: 192px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--easy-table-row-border);
}

.signalo-playlist-manager-image-placeholder {
    border-radius: 0;
    cursor: unset;
}
</style>

<style>
.playlist-section-title-v-text-field,
.playlist-section-title-v-text-field .v-input__control {
    width: 300px;
}

.playlist-manager-actions-container td {
    border: none !important;
    display: block;
    padding: 0 !important;
}

.playlist-manager-actions-container .files-documents-container-add-button {
    margin-top: 0 !important;
}

.playlist-manager-actions-container .files-documents-container-table-container {
    display: block;
}

.playlist-manager-actions-container
    .files-documents-container-table-container
    tbody {
    display: flex;
    flex-direction: row-reverse;
}

.section-asset .v-list-item__prepend .v-icon,
.grab-section-icon {
    font-size: 2.5rem;
    cursor: grab;
    color: var(--ui-icon-icon-01);
    opacity: 1;
}
.grab-section-icon {
    position: relative;
    left: -0.25rem;
}

.playlist-section-assets .v-list-item {
    padding-left: 0;
}
</style>
