<template>
    <SignaloAppBar>
        <template #title>
            <v-btn variant="text" icon="mdi-chevron-left" color="primary" @click="navigateToList"></v-btn>
            {{ $t('issues.issues') }}
        </template>
        <template #bar>
            <div class="translation-selector-container" v-if="
                authStore?.terminal?.terminal_mode !==
                'simplified_handling' &&
                authStore?.tenant?.attributes?.auto_translation
            ">
                <SignaloTranslationSelector v-if="authStore?.tenant?.supportedLocales"
                    :availableLocales="authStore.tenant.supportedLocales"
                    @localeChange="(val) => onCurrentLocaleChange(val)" />
            </div>
            <v-btn @click="() => loadData()" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main :class="nestedView ? 'v-main-no-spacing' : ''">
        <SignaloBreadCrumbs :data="breadcrumb" v-if="breadcrumb && !nestedView" />
        <div class="andon-tile-container">
            <SignaloAndonRequestTile v-if="issue" :key="JSON.stringify(issue)" :loading="loading" :details="true"
                :chipsVisible="true" :data="issue" @updateIssue="(index, update) => loadData(true, update)" :read="true"
                @reload="loadData" :currentLocale="currentLocale" />
        </div>
    </v-main>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import SignaloAndonRequestTile from './SignaloAndonRequestTile.vue';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: false,
            issue: null,
            debounceHandle: null,
            id: String(this.$route?.params?.id),
            breadcrumb: {
                id: {
                    text: `#${String(this.$route?.params?.id)}`,
                    value: String(this.$route?.params?.id),
                },
            },
            nestedView: location.href.includes('production_terminal'),
            currentLocale: null
        };
    },
    async mounted() {
        await this.dataStore.dictionary.promise();
        await this.dataStore.dictionaries.promise();
        await this.loadData();
        this.listenEvents();
    },
    methods: {
        async onCurrentLocaleChange(val) {
            try {
                this.currentLocale = val;
                if (!this.currentLocale) return;
                this.$i18n.locale = this.currentLocale;
                if (
                    this.authStore?.terminal ||
                    this.authStore.user?.authenticatable === 'Terminal'
                )
                    return;

                await this.axios.patch('me', {
                    preferred_locale: this.currentLocale,
                });
                await this.authStore.getAuth();
            } catch (err) {
                console.error(err);
            }
        },
        async loadData(afterUpdate = false, update = null) {
            try {
                if (update) {
                    this.issue = {
                        ...update,
                        version: Math.max(update.version, this.issue.version),
                    };
                } else {
                    this.loading = true;
                    const issueData = await this.axios.get(`issues/${this.id}`);
                    this.issue = issueData?.data?.item;
                    this.loading = false;
                }
                if (afterUpdate) {
                    this.helpersStore.snackbarSaved();
                }
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
        },
        debounce(func, delay = 250) {
            if (this.debounceHandle !== null) clearTimeout(this.debounceHandle);
            this.debounceHandle = setTimeout(func, delay);
        },
        listenEvents() {
            const userId = this.authStore?.user?.id;
            if (userId) {
                const myPrivateChannel = `private-${userId}.user`;
                this.emitter.on(myPrivateChannel, (data) => {
                    this.debounce(() => {
                        this.dispatchEvent(data?.data);
                    });
                });
            }

            const terminalId = this.authStore?.terminal?.id;
            if (terminalId) {
                if (this.authStore.terminal.requires_user_login) {
                    const terminalUserId = this.authStore?.user?.id;
                    if (userId !== terminalUserId) {
                        const terminalPrivateNotificationChannel = `private-${terminalUserId}.user`;
                        this.emitter.on(
                            terminalPrivateNotificationChannel,
                            (data) => {
                                this.debounce(() => {
                                    this.dispatchEvent(data?.data);
                                });
                            }
                        );
                    }
                }

                const terminalPrivateChannel = `private-${terminalId}.terminal`;
                this.emitter.on(terminalPrivateChannel, (data) => {
                    this.debounce(() => {
                        this.dispatchEvent(data?.data);
                    });
                });
            }
        },
        navigateToList() {
            if (this.nestedView) {
                this.$router.push({
                    name: 'production_terminal.issues',
                    replace: true,
                    force: true,
                });
            } else {
                this.$router.push({
                    name: 'simplified_handling',
                    replace: true,
                    force: true,
                });
            }
        },
        async dispatchEvent(eventData) {
            const allowedEventType = [
                'issueupdated',
                'issuefinished',
                'issuedeleted',
            ];
            const eventType = String(eventData?.data?.type).toLocaleLowerCase();

            const shouldDispatchEvent = allowedEventType.some((aet) =>
                eventType.includes(aet)
            );
            if (!shouldDispatchEvent) return;

            switch (eventData?.data?.operation) {
                case 'updated':
                    if (!eventData?.data?.issue?.access?.read) {
                        this.navigateToList();
                        return;
                    }
                    this.issue = {
                        ...this.issue,
                        ...eventData?.data?.issue,
                        version: Math.max(
                            this.issue.version,
                            eventData?.data?.issue?.version
                        ),
                    };
                    break;
                case 'deleted':
                    this.navigateToList();
                    break;
                case 'hide':
                    this.navigateToList();
                    break;
                default:
                    break;
            }
        },
    },
    components: { SignaloAndonRequestTile },
};
</script>

<style scoped>
.andon-tile-container {
    padding: 0px 16px;
}
</style>

<style>
.bar .translation-selector-container {
    margin-right: 1rem;
}
</style>
