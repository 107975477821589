export default [
    {
        text: 'tableHeaders.id',
        value: 'id',
    },
    {
        text: 'tableHeaders.name',
        value: 'name',
    },
    {
        text: 'common.buildings',
        value: 'buildings',
    },
    { value: 'operation', fixed: true, width: 54 },
];
