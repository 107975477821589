<template>
    <SignaloDialog
        :open="true"
        :title="`${$t('common.move')} - ${folder?.name} `"
        @close="closeDialog"
        :constHeight="false"
    >
        <template #body>
            <SignaloPageLoading v-if="loading" />
            <v-form
                v-else-if="allFolders?.length"
                ref="form"
                v-model="dataValid"
                lazy-validation
            >
                <v-col cols="12" md="12" xl="12">
                    <SignaloItemPicker
                        :dialogTitle="$t('common.folder')"
                        v-model="destination"
                        :placeholder="$t('common.targetFolder')"
                        :nameValue="'name'"
                        :tree="true"
                        :source="allFolders"
                        :treeProperty="'parent_id'"
                        :clearable="true"
                        :filteredItems="[folder?.id]"
                    />
                </v-col>
            </v-form>
        </template>

        <template #buttons>
            <v-btn @click="closeDialog">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="onMove"
                :loading="saveLoading"
                :disabled="filesAction === 'move' && !destination"
            >
                {{ $t('common.confirm') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    emits: ['close', 'reload'],
    props: ['folder'],
    data() {
        return {
            filesAction: null,
            destination: this.folder?.parent_id,
            allFolders: [],
            loading: false,
        };
    },
    mounted() {
        this.loadAllFolders();
    },

    methods: {
        closeDialog() {
            this.$emit('close');
        },
        async loadAllFolders() {
            try {
                this.loading = true;
                const allFoldersData = await this.axios.get(
                    'file_directories_all'
                );
                this.allFolders = allFoldersData?.data?.items || [];
            } catch (err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        async onMove() {
            try {
                if (this.folder?.id == this.destination) return;
                this.loading = true;

                await this.axios.patch(`file_directories/${this.folder?.id}`, {
                    parent_id: this.destination,
                });
                this.$emit('reload');
                this.helpersStore.snackbarSaved();
                this.$emit('close');
            } catch (err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
