<template>
    <SignaloDialog
        :open="true"
        :title="
            $t('common.banEmployee') +
            ' - ' +
            (employee?.employee_name || employee?.name)
        "
        @close="() => closeDialog()"
        :constHeight="false"
        @save="onSave"
        :loading="saveLoading"
    >
        <template #body>
            <v-form ref="dataForm" v-model="dataValid" lazy-validation>
                <v-card-text>
                    <v-textarea
                        :label="$t('common.comment')"
                        v-model="comment"
                        variant="outlined"
                        color="var(--greyscale-100)"
                        :rules="[$REQUIRED_RULE]"
                        class="required-field"
                    >
                    </v-textarea>
                </v-card-text>
            </v-form>
        </template>
    </SignaloDialog>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    name: 'BanEmployeeDialog',
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    props: ['employee', 'editedItem'],
    emits: ['close'],
    data() {
        return {
            saveLoading: false,
            dataValid: false,
            comment: this.editedItem?.comment || null,
        };
    },
    methods: {
        closeDialog(shouldRefetch = false) {
            this.$emit('close', shouldRefetch);
        },
        async onSave() {
            try {
                await this.$refs.dataForm.validate();
                if (!this.dataValid) return;

                this.saveLoading = true;
                const data = {
                    banned_to: null,
                    comment: this.comment,
                };
                if (this.editedItem?.id) {
                    await this.axios.put(
                        `employees/${this.employee?.employee_id || this.employee?.id}/bans/${this.editedItem.id}`,
                        data
                    );
                } else {
                    await this.axios.post(
                        `employees/${this.employee?.employee_id || this.employee?.id}/bans`,
                        data
                    );
                }
                this.helpersStore.snackbarSaved();
                this.closeDialog(true);
            } catch (err) {
                console.error(err);
            } finally {
                this.saveLoading = false;
            }
        },
    },
};
</script>
