<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.configuration') }} </template>
        <template #bar>
            <v-btn
                @click="onCreate"
                v-if="$route.meta.canCreate"
                color="primary"
                variant="elevated"
                class="add-button"
            >
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <PageLoading v-if="loading" />
        <div v-else>
            <EasyDataTable buttons-pagination
                :headers="tableHeaders"
                :items="items"
                :empty-message="$t('common.emptyTableMessage')"
                table-class-name="easy-table-style"
                :rows-per-page-message="$t('common.rowsPerPage')"
                :rows-of-page-separator-message="$t('common.rowsOfPage')"
                v-model:server-options="serverOptions"
                :server-items-length="serverItemsLength"
                :rows-items="[5, 10, 25, 50, 75, 100]"
                name="userTemplatesTable"
            >
                <template #item-name="item">
                    {{ $getPropertyTranslation(item.name) }}
                </template>
                <template #item-operation="item">
                    <div class="actions-column">
                        <v-menu
                            offset-y
                            v-if="
                                $route.meta.canUpdate || $route.meta.canDelete
                            "
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    color="primary"
                                    v-bind="props"
                                    variant="outlined"
                                    icon
                                    class="table-action-icon-button"
                                >
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="onEdit(item)"
                                    v-if="$route.meta.canUpdate"
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-pencil
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.edit')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    @click="onGoToTemplateGroups(item)"
                                    v-if="
                                        authStore.isAllowed(
                                            'settings.groups',
                                            'read'
                                        )
                                    "
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-account-group
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.groups')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="onGoToTemplateDashboardConfig(item)"
                                    v-if="$route.meta.canUpdate"
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-view-dashboard
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.dashboardConfig')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="$route.meta.canUpdate"
                                    @click="onManageTemplatePermissions(item)"
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-account-key
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('staff.editAccess')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="$route.meta.canUpdate"
                                    @click="onGoToNotificationPreferences(item)"
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-bell-cog
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.notificationsManagement')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    @click="onRemove(item)"
                                    v-if="$route.meta.canDelete"
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-delete
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.remove')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </EasyDataTable>
        </div>
    </v-main>
    <UserTemplateManage
        v-if="manageDialog"
        v-model:open="manageDialog"
        :item="editedItem"
        @close="onCloseDialogHandler"
        :mode="dialogMode"
    />
    <SignaloConfirmationDialog
        v-if="removeDialog"
        :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => remove(value)"
    />
    <SignaloAppAccessComponent
        v-if="permissionsDialog"
        :title="$t('staff.editAccess')"
        :open="permissionsDialog"
        @close="closePermissionsDialog"
        mode="template"
        v-model="editedItem"
    />
    <DashboardConfigDialog
        v-if="dashboardConfigDialogOpened"
        :open="dashboardConfigDialogOpened"
        @close="(shouldRefetch) => onCloseDashboardConfigDialog(shouldRefetch)"
        :userTemplate="editedItem"
    />
</template>

<script>
/* eslint-disable */
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import UserTemplateManage from './UserTemplateManage.vue';
import DashboardConfigDialog from './DashboardConfigDialog.vue';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: true,
            items: [],
            manageDialog: false,
            dialogMode: null,
            editedItem: null,
            removeDialog: false,
            itemToRemove: null,
            permissionsDialog: false,
            lastSearch: null,
            serverOptions: this.$getServerOptions('userTemplatesTable'),
            serverItemsLength: 0,
            dashboardConfigDialogOpened: false,
        };
    },
    computed: {
        tableHeaders() {
            return [
                { text: this.$t('tableHeaders.name'), value: 'name' },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
    },
    created() {
        this.loadTable();
    },
    methods: {
        async onCloseDashboardConfigDialog(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadTable();
            }
            this.dashboardConfigDialogOpened = false;
            this.editedItem = null;
        },
        onGoToTemplateDashboardConfig(item) {
            this.editedItem = item;
            this.dashboardConfigDialogOpened = true;
        },
        async loadTable(search) {
            this.loading = true;

            try {
                if (search !== null) {
                    this.lastSearch = search;
                }

                const config = this.dataStore.userTemplates.makeServerOptions(
                    this.serverOptions,
                    this.lastSearch
                );
                this.$router.replace({ query: config.params });
                return this.dataStore.userTemplates
                    .indexRequest(config)
                    .then((x) => {
                        if (x.data?.status === 'ok') {
                            this.items = Object.values(x.data.items);
                            this.serverItemsLength = x?.data?.meta?.total;
                        }
                    })
                    .catch((e) => {
                        if (e.response.status == 401) return;
                        this.helpersStore.snackbarError(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (err) {
                if (err?.response?.status === 401) return;
                this.helpersStore.snackbarError(err);
            }
            this.loading = false;
        },
        onCreate() {
            this.dialogMode = 'create';
            this.manageDialog = true;
        },
        onEdit(item) {
            this.dialogMode = 'edit';
            this.editedItem = item;
            this.manageDialog = true;
        },
        onRemove(item) {
            this.itemToRemove = item;
            this.removeDialog = true;
        },
        async onCloseDialogHandler(refetchData) {
            if (refetchData) {
                await this.loadTable();
            }
            this.manageDialog = false;
            this.editedItem = null;
            this.dialogMode = null;
        },
        async remove(shouldRemove) {
            if (!shouldRemove) {
                this.removeDialog = false;
                this.itemToRemove = null;
                return;
            }
            this.loading = true;
            try {
                await this.axios.delete(
                    `user_templates/${this.itemToRemove.id}`
                );
                await this.loadTable();
                this.removeDialog = false;
                this.itemToRemove = null;
                this.helpersStore.snackbar(
                    this.$t('errors.removed'),
                    'success'
                );
            } catch (err) {
                if (err?.response?.status == 401) return;
                this.helpersStore.snackbarError(err);
            }

            this.loading = false;
        },
        onManageTemplatePermissions(template) {
            this.editedItem = template;
            this.permissionsDialog = true;
        },
        closePermissionsDialog() {
            this.permissionsDialog = false;
            this.editedItem = null;
        },
        onGoToNotificationPreferences(item) {
            this.$router.push({
                name: 'settings.userProfilesNotificationPreferences',
                params: {
                    id: item.id,
                },
            });
        },
        onGoToTemplateGroups(item) {
            this.$router.push({
                name: 'settings.userProfilesGroups',
                params: {
                    id: item.id,
                },
            });
        },
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences('userTemplatesTable', {
                default_size: val.rowsPerPage,
            });
            this.loadTable();
        },
    },
    components: {
        UserTemplateManage,
        DashboardConfigDialog,
    },
};
</script>
