<template>
    <SignaloAppBar>
        <template #title> {{ $t('equipmentTypes.equipment') }} </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs :data="breadcrumb" />
        <SignaloPageLoading v-if="loading" />
        <PageNotFound v-else-if="!item" />
        <div v-else class="mx-1 mb-3 py-4 px-4">
            <v-card class="mx-auto">
                <template v-slot:title>
                    <v-toolbar class="preview-header">
                        <v-icon class="preview-header-icon"
                            >mdi-cog-outline</v-icon
                        >
                        {{ item.name }}
                    </v-toolbar>
                </template>

                <v-tabs v-model="tabs" color="primary">
                    <v-tab value="data">
                        {{ $t('common.data') }}
                    </v-tab>
                    <v-tab value="parameters">
                        {{ $t('common.parameters') }}
                    </v-tab>
                    <v-tab value="photos">
                        {{ $t('common.images') }}
                    </v-tab>
                    <v-tab value="documents">
                        {{ $t('common.documents') }}
                    </v-tab>
                    <v-tab value="checklist" v-if="item.type === 'machine'">
                        {{ $t('common.checkList') }}
                    </v-tab>
                </v-tabs>
                <v-card-text class="manage-card-content" v-if="item">
                    <v-window v-model="tabs" class="ma-0" :key="tabs">
                        <v-window-item value="data">
                            <v-form
                                ref="dataForm"
                                v-model="dataValid"
                                lazy-validation
                            >
                                <div class="equipment-type-tabs-toggle">
                                    <SignaloTabsToggle
                                        :modelValue="item.type"
                                        :buttons="typeButtons"
                                        :defaultTab="currentType"
                                        :mandatory="true"
                                        :key="currentType"
                                        @update:modelValue="
                                            (value) => {
                                                item.type = value;
                                                onTypeChange();
                                            }
                                        "
                                    />
                                </div>

                                <v-text-field
                                    v-model="item.name"
                                    :counter="250"
                                    :rules="nameRules"
                                    :label="$t('common.name')"
                                    required
                                    :disabled="!$route.meta.canUpdate"
                                    variant="outlined"
                                    color="var(--greyscale-100)"
                                    class="required-field"
                                >
                                </v-text-field>
                                <SignaloDictionaryTreePicker
                                    :group="dictionaryTreePickerGroup"
                                    v-model="item.item_group"
                                    v-slot="props"
                                    ref="dictionaryTreePicker"
                                >
                                    <v-text-field
                                        :modelValue="
                                            getItemGroupFromKeyname?.value
                                        "
                                        @click:control="props.onClick"
                                        @click:clear="props.onClear"
                                        clearable
                                        readonly
                                        hide-details
                                        :label="$t('common.group')"
                                        :disabled="!$route.meta.canUpdate"
                                        clear-icon="mdi-close-circle-outline"
                                        variant="outlined"
                                        color="var(--greyscale-100)"
                                    />
                                </SignaloDictionaryTreePicker>
                                <SignaloBarCode
                                    ref="barCodeEditComponent"
                                    :barcode="item?.barcode"
                                    :editable="true"
                                    @onChange="onBarcodeChange"
                                />
                            </v-form>
                        </v-window-item>
                        <v-window-item value="parameters">
                            <EquipmentTypeParametersComponent
                                :itemId="item.id"
                            />
                        </v-window-item>
                        <v-window-item value="photos">
                            <SignaloFilesImagesContainer
                                :endpoint="`/equipment_types/${item.id}/photos`"
                                allow-upload
                                allow-remove
                            />
                        </v-window-item>
                        <v-window-item value="documents">
                            <SignaloFilesDocumentsContainer
                                :endpoint="`/equipment_types/${item.id}/documents`"
                                allow-upload
                                allow-remove
                            />
                        </v-window-item>
                        <v-window-item
                            value="checklist"
                            v-if="item.type === 'machine'"
                        >
                            <v-textarea
                                v-model="item.checklist"
                                :label="$t('common.checkList')"
                                variant="outlined"
                                color="var(--greyscale-100)"
                            >
                            </v-textarea>
                        </v-window-item>
                    </v-window>
                </v-card-text>
                <v-card-actions v-if="$route.meta.canUpdate">
                    <v-spacer> </v-spacer>
                    <v-btn
                        v-if="tabs !== 'parameters'"
                        :loading="dataSaving"
                        color="primary"
                        @click="saveData"
                        style="float: right"
                        variant="elevated"
                        :disabled="barcode?.value && !barcode?.valid"
                    >
                        {{ $t('common.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>

            <PartsComponent
                :equipment_type="item"
                @reload="load"
                class="parts-component"
            />
        </div>
    </v-main>
</template>

<script>
import EquipmentTypeParametersComponent from './EquipmentTypeParametersComponent.vue';
import PartsComponent from './PartsComponent.vue';

import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        return { dataStore, helpersStore };
    },
    components: {
        EquipmentTypeParametersComponent,
        PartsComponent,
    },
    data() {
        return {
            id: this.$route.params.id,

            item: null,
            loading: true,

            tabs: 'data',
            barcode: null,

            // DATA
            dataValid: true,
            dataSaving: false,
            nameRules: [(v) => !!v || this.$t('common.nameRequired')],
            currentType: null,
        };
    },
    computed: {
        dictionaryTreePickerGroup: function () {
            return this.item.type === 'machine'
                ? 'machine_groups'
                : 'tool_groups';
        },
        breadcrumb() {
            let res = {};

            if (this.item) {
                res.id = {
                    text: `#${this.id}: ${this.item.name}`,
                    value: this.id,
                    disabled: true,
                    href: '#',
                };
            } else {
                res.id = {
                    text: `#${this.id}`,
                    value: this.id,
                    disabled: true,
                    href: '#',
                };
            }

            return res;
        },
        typeButtons() {
            return [
                {
                    value: 'machine',
                    label: this.$t('common.machine'),
                },
                {
                    value: 'tool',
                    label: this.$t('common.tool'),
                },
            ];
        },
        getItemGroupFromKeyname() {
            return (
                this.dataStore.dictionary
                    .group(this.dictionaryTreePickerGroup)
                    .find(
                        (groupItem) => groupItem.keyname == this.item.item_group
                    ) || null
            );
        },
    },
    created() {
        this.load();
    },
    methods: {
        onBarcodeChange(val) {
            this.barcode = val;
        },
        load() {
            this.loading = true;
            return this.axios
                .get('/equipment_types/' + this.$route.params.id)
                .then((x) => {
                    if (x.data.status == 'ok') {
                        this.item = x.data.item;
                        this.currentType = this.item.type;
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        async saveData() {
            await this.$refs.dataForm.validate();
            if (this.dataValid) {
                this.dataSaving = true;
                const data = {
                    name: this.item.name,
                    type: this.item.type,
                    item_group: this.item.item_group,
                    parameters: this.item.parameters,
                };
                if (this.item.type === 'machine') {
                    data.checklist = this.item.checklist;
                }
                if (this.barcode?.value && this.barcode?.valid) {
                    data.barcode = {
                        value: this.barcode.value,
                        type: this.barcode.type
                    };
                } 
                this.axios
                    .request({
                        url: `/equipment_types/${this.id}`,
                        method: 'PUT',
                        data,
                    })
                    .then((/*{data}*/) => {
                        this.helpersStore.snackbarSaved();
                        return this.load();
                    })
                    .catch((e) => {
                        if (e?.response?.status == 401) return;
                    })
                    .finally(() => {
                        this.dataSaving = false;
                    });
            }
        },
        onTypeChange() {
            this.item.item_group = null;
            this.$refs.dictionaryTreePicker.clearClicked();
        },
    },
};
</script>

<style>
.eq-photo {
    position: relative;
    margin: 10px;
    width: 150px;
    height: 150px;
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
    z-index: 101;
    overflow: hidden;
}

.eq-photo .cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.eq-photo .cover:hover {
    background: rgba(200, 200, 200, 0.6);
}

.type-toggle {
    margin: 1rem 0;
}

.manage-card-content {
    margin-top: 16px;
}

.parts-component {
    margin-top: 16px;
}
.equipment-type-tabs-toggle {
    padding-bottom: 16px;
}
</style>
