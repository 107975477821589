<template>
    <SignaloDialog
        :open="dialogOpened"
        @close="() => $emit('close', false)"
        :title="$t('common.dashboardConfig')"
    >
        <template #body>
            <DashboardConfig
                :dialogMode="true"
                :userTemplate="userTemplate"
                @close="(v) => $emit('close', v)"
                ref="dsConfig"
                :hideButton="true"
            />
        </template>
        <template #buttons>
            <div></div>
            <v-btn @click="() => $emit('close', false)">{{
                $t('common.cancel')
            }}</v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="$refs.dsConfig.save()"
                :loading="saveLoading"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import DashboardConfig from '../DashboardConfig/DashboardConfig.vue';
export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    props: {
        dialogOpened: Boolean,
        eventTypes: null,
        item: null,
        userTemplate: null,
    },
    data() {
        return {
            name: this.item?.name || '',
            dataValid: false,
            saveLoading: false,
            isDefault: this.item?.is_default || false,
        };
    },
    components: { DashboardConfig },
    emits: ['close'],
    methods: {},
    computed: {
        required() {
            return [(v) => !!v || this.$t('common.fieldRequired')];
        },
    },
};
</script>
<style>
.schedule-event-types-dialog-width {
    max-width: 600px;
}
</style>
