export default [
    {
        text: 'common.partId',
        value: 'id',
        sortable: true,
    },
    {
        text: 'tableHeaders.name',
        value: 'name',
        sortable: true,
    },
    {
        text: 'tableHeaders.registryNumber',
        value: 'registry_no',
        sortable: true,
    },
    {
        text: 'tableHeaders.quantity',
        value: 'total_quantity',
    },
    {
        text: 'common.unit',
        value: 'preferred_unit',
        sortable: true,
    },

    {
        text: 'common.entriesLocation',
        value: 'entries_location',
    },
    { value: 'operation', width: 54, fixed: true },
];
