<template>
    <div class="issue-defaults-container">
        <div class="Text Label-Text issue-defaults-label">
            {{ $t('common.defaultValues') }}
        </div>
        <v-row>
            <v-col cols="12" md="12" xl="12" class="issue-defauls-column">
                <SignaloNewValueSelect
                    :selectableType="SELECTABLE_TYPES.EQUIPMENT"
                    :label="$t('tableHeaders.equipment')"
                    v-model="storage_terminal_defaults.equipment_id"
                    clearable
                />

                <v-checkbox
                    color="primary"
                    :label="$t('common.hidden')"
                    v-model="hidden_fields.equipment"
                ></v-checkbox>
            </v-col>

            <v-col cols="12" md="12" xl="12" class="issue-defauls-column">
                <SignaloNewValueSelect
                    :selectableType="SELECTABLE_TYPES.COST_CENTER"
                    :label="$t('costCenter.costCenter')"
                    v-model="storage_terminal_defaults.cost_center"
                    clearable
                />
                <v-checkbox
                    color="primary"
                    :label="$t('common.hidden')"
                    v-model="hidden_fields.cost_center"
                ></v-checkbox>
            </v-col>

            <v-col cols="12" md="12" xl="12" class="issue-defauls-column">
                <SignaloNewValueSelect
                    :selectableType="SELECTABLE_TYPES.DICTIONARY"
                    :label="$t('tableHeaders.department')"
                    :dictionary="'departments'"
                    v-model="storage_terminal_defaults.department"
                    clearable
                />
                <v-checkbox
                    color="primary"
                    :label="$t('common.hidden')"
                    v-model="hidden_fields.department"
                ></v-checkbox>
            </v-col>

            <v-col cols="12" md="12" xl="12" class="issue-defauls-column">
                <SignaloNewValueSelect
                    :selectableType="SELECTABLE_TYPES.LONG_TEXT"
                    :label="$t('common.comment')"
                    v-model="storage_terminal_defaults.comment"
                    clearable
                />
                <v-checkbox
                    color="primary"
                    :label="$t('common.hidden')"
                    v-model="hidden_fields.comment"
                ></v-checkbox>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';

export default {
    name: 'StorageTerminalDefaultValues',
    props: ['defaults', 'hiddenFields', 'displayOptions'],
    setup() {
        const authStore = AUTH_STORE.default();
        const dataStore = DATA_STORE.default();
        return { authStore, dataStore };
    },
    data() {
        return {
            storage_terminal_defaults: this.defaults
                ? { ...this.defaults }
                : {},
            hidden_fields: this.hiddenFields ? { ...this.hiddenFields } : {},
            display_options: this.displayOptions ? { ...this.displayOptions } : {},
        };
    },
    methods: {},
    watch: {
        storage_terminal_defaults: {
            handler(val) {
                this.$emit('update:modelValue:defaults', val);
            },
            deep: true,
        },
        hidden_fields: {
            handler(val) {
                this.$emit('update:modelValue:hidden_fields', val);
            },
            deep: true,
        },
        display_options: {
            handler(val) {
                this.$emit('update:modelValue:display_options', val);
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.issue-defaults-container {
    padding: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.issue-defaults-container .v-row {
    width: 100%;
}
.issue-defaults-label {
    margin-bottom: 12px;
}
.issue-defauls-column {
    display: flex;
}
</style>

<style>
.issue-defaults-container .signalo-new-value-select,
.issue-defaults-container .v-input:not(.v-checkbox, .v-textarea) {
    width: -webkit-fill-available;
    max-width: 300px;
}
</style>
