<template>
    <v-card style="padding: 0 !important" class="overflow-visible">
        <template v-slot:title v-if="mode === 'myParts'">
            <v-toolbar color="#fff">
                <v-toolbar-title>{{
                    $t('common.myTakenParts')
                }}</v-toolbar-title>
            </v-toolbar>
        </template>
        <EasyDataTable buttons-pagination
            :loading="loading"
            :items="takenParts"
            :headers="tableHeaders"
            :empty-message="$t('common.emptyTableMessage')"
            :table-class-name="
                mode === 'myParts'
                    ? 'easy-table-style m-0'
                    : 'easy-table-style parts-taken-by-user-table'
            "
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            @click-row="goToPartEntry"
            :rows-items="[5, 10, 25, 50]"
            v-model:server-options="partsTableServerOptions"
            :server-items-length="partsTableServerItemsLength"
            :body-row-class-name="getRowClass"
        >
            <!-- <template #item-id="item">{{ item?.id }}</template> -->
            <template #item-name="item">{{ item?.part?.name }}</template>
            <template #item-expiry_date="item">{{
                item?.expiry_date || '-'
            }}</template>
            <template #item-symbol="item">{{ item?.part?.symbol }}</template>
            <template #item-awaits_return="item">
                <span v-if="item?.awaits_return">{{
                    $t('common.awaitsReturn')
                }}</span>
                <span v-else>{{ $t('common.partsStatusTaken') }}</span>
            </template>
            <template #item-unit="item">
                <span v-html="$displayDimension(item.unit)"></span
            ></template>
            <template #item-taken_location="item">{{
                dataStore.locations.getById(item?.part_entry?.location_id)?.name
            }}</template>
            <template #item-manufacturer_id="item">
                {{
                    item.manufacturer_id
                        ? dataStore.companies.getById(
                              item?.part?.manufacturer_id
                          )?.name
                        : '-'
                }}
            </template>
            <template #item-predicted_equipment_id="item">
                <span v-if="item?.predicted_equipment?.name">
                    {{ item?.predicted_equipment?.name || '-' }}&nbsp;({{
                        item?.predicted_equipment?.symbol
                    }})
                </span>
                <span v-else>-</span>
            </template>
            <template #item-predicted_department="item">
                {{
                    departments?.find(
                        (d) => d.keyname == item?.predicted_department
                    )?.value || '-'
                }}
            </template>
            <template #item-cost_center_id="item">
                {{ getCostCenterName(item?.cost_center_id) || '-' }}
            </template>
            <template #item-user_signature="item">
                <SignaloSignaturePreviewComponent
                    v-if="item?.take_user_signature?.value"
                    :value="item?.take_user_signature?.value"
                />
            </template>
            <template #item-approved_by_signature="item">
                <SignaloSignaturePreviewComponent
                    v-if="item?.take_approved_signature?.value"
                    :value="item?.take_approved_signature?.value"
                />
            </template>
            <template #item-operation="item">
                <div class="actions-column">
                    <v-tooltip
                        v-if="
                            !mode &&
                            authStore.isAllowed('staff.parts', 'delete')
                        "
                        :text="$t('common.returnPart')"
                        open-on-click
                        open-on-focus
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                icon
                                color="primary"
                                variant="elevated"
                                @click="
                                    ($event) => onReturnPartUsage($event, item)
                                "
                                class="table-action-icon-button"
                            >
                                <v-icon>mdi-undo-variant</v-icon>
                            </v-btn>
                        </template>
                    </v-tooltip>

                    <v-tooltip
                        v-else-if="mode === 'select'"
                        :text="$t('common.select')"
                        open-on-click
                        open-on-focus
                    >
                        <template v-slot:activator="{ props }">
                            <v-checkbox
                                v-bind="props"
                                color="primary"
                                @click.stop="
                                    ($event) => onPartSelect($event, item)
                                "
                                single-line
                                hide-details
                            ></v-checkbox>
                        </template>
                    </v-tooltip>
                </div>
            </template>
        </EasyDataTable>
    </v-card>

    <ReturnPartDialog
        v-if="returnPartDialog"
        :open="returnPartDialog"
        :userId="id"
        :partUsage="partUsageToReturn"
        @close="onReturnPartDialogClose"
    />
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import ReturnPartDialog from './ReturnPartDialog.vue';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, authStore };
    },
    components: { ReturnPartDialog },
    props: ['id', 'mode'], // mode === null | 'myParts' | 'select'
    data() {
        return {
            loading: false,
            takenParts: [],
            returnPartDialog: false,
            partUsage: null,
            tableName:
                this.mode === 'myParts'
                    ? 'dashboardMyTakenParts'
                    : 'partsTakenByUser',
            partsTableServerOptions: {
                ...this.$getServerOptions(
                    this.mode === 'myParts'
                        ? 'dashboardMyTakenParts'
                        : 'partsTakenByUser'
                ),
                sortBy: 'entry_date',
                sortType: 'desc',
            },
            partsTableServerItemsLength: 0,
            costCenters: [],
        };
    },
    mounted() {
        this.loading = true;
        this.dataStore.locations.reload().then(() => {
            this.loading = false;
        });
        this.loadData();
    },
    methods: {
        async loadData() {
            this.loading = true;
            try {
                const costCentersData = await this.axios.get('cost_centers');
                this.costCenters = costCentersData?.data?.items;
                const options = this.dataStore.issues.makeServerOptions(
                    this.partsTableServerOptions
                );
                if (this.mode !== 'myParts') {
                    const partsData = await this.axios.get(
                        `users/${this.id}/parts`,
                        options
                    );
                    this.takenParts =
                        Object.values(partsData?.data?.items) || [];

                    this.partsTableServerItemsLength =
                        partsData?.data?.meta?.total;
                } else {
                    const partsData = await this.axios.get(
                        'me/parts_taken',
                        options
                    );
                    if (
                        partsData?.data?.items &&
                        Object.values(partsData.data.items)?.length
                    ) {
                        this.takenParts = Object.values(partsData?.data?.items);
                        this.partsTableServerItemsLength =
                            partsData?.data?.meta?.total;
                    }
                }
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        onReturnPartUsage($event, item) {
            $event.stopPropagation();
            $event.preventDefault();
            this.partUsageToReturn = item;
            this.returnPartDialog = true;
        },
        async onReturnPartDialogClose(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadData();
            }
            this.returnPartDialog = false;
            this.partUsageToReturn = null;
        },
        goToPartEntry(item) {
            if (this.mode === 'select') {
                this.$emit('partSelected', item);
                return;
            }
            this.$router.push({
                name: 'parts.entries.show',
                params: {
                    id: item.part.id,
                    entryId: item.part_entry.id,
                },
            });
        },
        onPartSelect($event, item) {
            $event.stopPropagation();
            $event.preventDefault();
            this.$emit('partSelected', item);
        },
        getCostCenterName(id) {
            return this.$getPropertyTranslation(
                this.costCenters?.find((cc) => cc.id === id)?.name
            );
        },
        getRowClass(item) {
            if (!item.expiry_date) return;
            const expiry = this.$extractISO8601(
                this.authStore?.tenant?.attributes?.taken_parts_expiry_warning
            );
            let alertDate = new Date(item.expiry_date);

            if (expiry.years) {
                alertDate = this.subtractDate(
                    new Date(alertDate),
                    expiry.years,
                    'y'
                );
            }
            if (expiry.months) {
                alertDate = this.subtractDate(
                    new Date(alertDate),
                    expiry.months,
                    'm'
                );
            }
            if (expiry.days) {
                alertDate = this.subtractDate(
                    new Date(alertDate),
                    expiry.days,
                    'd'
                );
            }
            if (new Date().getTime() >= alertDate.getTime()) {
                return 'expiration-date-warning';
            }
        },
        subtractDate(oldDate, offset, offsetType) {
            /* eslint-disable */
            let year = parseInt(oldDate.getFullYear());
            let month = parseInt(oldDate.getMonth());
            let date = parseInt(oldDate.getDate());
            let hour = parseInt(oldDate.getHours());
            let newDate;
            switch (offsetType) {
                case 'Y':
                case 'y':
                    newDate = new Date(year - offset, month, date, hour);
                    break;

                case 'M':
                case 'm':
                    let yearOffset = 0;
                    let monthOffset = 0;
                    if (offset < 12) {
                        yearOffset = Math.floor((month + offset) / 12);
                        monthOffset = (month + offset) % 12;
                    } else {
                        yearOffset = Math.floor(offset / 12);
                        monthOffset = (month % 12) + (offset % 12);
                    }
                    newDate = new Date(
                        year - yearOffset,
                        month - monthOffset,
                        date,
                        hour
                    );
                    break;

                case 'D':
                case 'd':
                    var o = oldDate.getTime();
                    var n = o - offset * 24 * 3600 * 1000;
                    newDate = new Date(n);
                    break;

                case 'H':
                case 'h':
                    var o = oldDate.getTime();
                    var n = o - offset * 3600 * 1000;
                    newDate = new Date(n);
                    break;
                default:
                    newDate = new Date(year - offset, month, date, hour);
            }
            return newDate;
        },
    },
    watch: {
        partsTableServerOptions(val) {
            this.authStore.patchTablePreferences(this.tableName, {
                default_size: val.rowsPerPage,
            });
            this.loadData();
        },
    },
    computed: {
        departments() {
            return this.dataStore.dictionary.group('departments');
        },
        tableHeaders() {
            const headers = [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                },
                {
                    text: this.$t('common.quantity'),
                    value: 'quantity_available',
                },
                {
                    text: this.$t('common.unit'),
                    value: 'unit',
                },
                {
                    text: this.$t('common.takenAt'),
                    value: 'entry_date',
                },
                {
                    text: this.$t('common.expiresDate'),
                    value: 'expiry_date',
                },
                {
                    text: this.$t('common.takenFromLocation'),
                    value: 'taken_location',
                },
                {
                    text: this.$t('tableHeaders.manufacturerSymbol'),
                    value: 'symbol',
                },
                {
                    text: this.$t('tableHeaders.manufacturer'),
                    value: 'manufacturer_id',
                },
                {
                    text: this.$t('common.status'),
                    value: 'awaits_return',
                },
            ];
            if (
                !this.authStore?.tenant?.attributes?.storageDefaults
                    ?.hiddenFields?.equipment
            ) {
                headers.push({
                    text: this.$t('tableHeaders.equipment'),
                    value: 'equipment',
                    width: 220,
                });
            }

            if (
                !this.authStore?.tenant?.attributes?.storageDefaults
                    ?.hiddenFields?.cost_center
            ) {
                headers.push({
                    text: this.$t('costCenter.costCenter'),
                    value: 'costCenter',
                    width: 220,
                });
            }

            if (
                !this.authStore?.tenant?.attributes?.storageDefaults
                    ?.hiddenFields?.department
            ) {
                headers.push({
                    text: this.$t('tableHeaders.department'),
                    value: 'department',
                    width: 220,
                });
            }

            if (
                !this.authStore?.tenant?.attributes?.storageDefaults
                    ?.hiddenFields?.comment
            ) {
                headers.push({
                    text: this.$t('common.comment'),
                    value: 'comment',
                    width: 180,
                });
            }
            if (this.takenParts.some((tp) => tp?.take_user_signature)) {
                headers.push({
                    text: this.$t('common.clientSignatureEnabled'),
                    value: 'user_signature',
                    width: 220,
                });
            }
            if (this.takenParts.some((tp) => tp?.take_approved_signature)) {
                headers.push({
                    text: this.$t('common.warehousemanSignatureEnabled'),
                    value: 'approved_by_signature',
                    width: 220,
                });
            }
            if (this.mode !== 'myParts') {
                headers.push({
                    value: 'operation',
                    width: 54,
                    fixed: true,
                });
            }
            return headers;
        },
    },
};
</script>

<style scoped>
.parts-taken-by-user-table {
    margin: 4px 0 !important;
}
</style>
<style>
.vue3-easy-data-table.easy-table-style.parts-taken-by-user-table
    tr.expiration-date-warning
    td,
.vue3-easy-data-table.easy-table-style.parts-taken-by-user-table
    tr.even-row.expiration-date-warning
    td {
    background-color: var(--warning-secondary) !important;
}
</style>
