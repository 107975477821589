<template>
    <SignaloDialog
        :open="true"
        :title="$t('common.defaultValues')"
        :constHeight="false"
        @save="onSave"
        @close="$emit('close')"
    >
        <template #body>
            <StorageTerminalDefaultValues
                :defaults="tenantStorageDefaults"
                :hiddenFields="tenantStorageHiddenFields"
                @update:modelValue:defaults="
                    (val) => (tenantStorageDefaults = val)
                "
                @update:modelValue:hidden_fields="
                    (val) => (tenantStorageHiddenFields = val)
                "
                @update:modelValue:display_options="
                    (val) => (tenantStorageHiddenFields = val)
                "
            />
        </template>
    </SignaloDialog>
</template>

<script>

export default {
    name: 'StorageTerminalDefaultsDialog',
    props: ['modelValue'],
    emits: ['save'],
    data() {
        return {
            tenantStorageDefaults: this.modelValue?.defaults ? {...this.modelValue?.defaults} : {},
            tenantStorageHiddenFields: this.modelValue?.hiddenFields ? {...this.modelValue?.hiddenFields} : {},
            tenantStorageDisplayOptions: this.modelValue?.hiddenFields ? {...this.modelValue?.displayOptions} : {}
        }
    },
    methods: {
        onSave() {
            this.$emit('save', {defaults: this.tenantStorageDefaults, hiddenFields: this.tenantStorageHiddenFields, displayOptions: this.tenantStorageDisplayOptions})
        }
    }
}
</script>
