<template>
    <v-card class="mx-auto" v-if="equipment_type">
        <template v-slot:title>
            <v-toolbar class="preview-header d-flex flex-row">
                <v-icon class="preview-header-icon">mdi-abacus</v-icon>
                {{ $t('equipmentTypes.fittingParts') }}
                <SignaloPartPicker
                    v-slot="props"
                    @update:modelValue="(x) => attachPart(x)"
                    :filteredItems="
                        equipment_type.fitting_parts?.map((i) => i.id)
                    "
                >
                    <v-btn
                        color="primary"
                        @click="props.onClick"
                        :style="'margin-left: auto;'"
                        variant="elevated"
                    >
                        <v-icon> mdi-plus </v-icon>
                        <span class="hidden-sm-and-down">{{
                            $t('common.add')
                        }}</span></v-btn
                    >
                </SignaloPartPicker>
            </v-toolbar>
        </template>
        <v-table>
            <thead>
                <tr>
                    <th>{{ $t('tableHeaders.name') }}</th>
                    <th>{{ $t('tableHeaders.manufacturerSymbol') }}</th>
                    <th>{{ $t('tableHeaders.registryNumber') }}</th>
                    <th>{{ $t('equipmentTypes.goToParts') }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="part in equipment_type.fitting_parts" :key="part.id">
                    <td>{{ part.name }}</td>
                    <td>{{ part.symbol }}</td>
                    <td>{{ part.registry_no }}</td>
                    <td>
                        <v-btn
                            icon
                            variant="plain"
                            @click="
                                $router.push({
                                    name: 'parts.show',
                                    params: { id: part.id },
                                })
                            "
                        >
                            <v-icon> mdi-arrow-right-circle </v-icon>
                        </v-btn>
                    </td>
                    <td>
                        <v-btn
                            icon
                            variant="plain"
                            @click="detachPart(part.id)"
                        >
                            <v-icon> mdi-delete </v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-card>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store'; 


export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        return { dataStore, helpersStore };
    },
    props: ['equipment_type'],
    emits: ['reload'],
    data() {
        return {
            loading: true,
        };
    },
    methods: {
        attachPart(part_id) {
            this.loading = true;
            this.dataStore.equipment_types
                .attachPart(this.equipment_type.id, part_id)
                .then(() => {
                    this.helpersStore.snackbarSaved();
                    this.$emit('reload');
                })
                .catch((e) => {
                    if (e.response?.status === 401) return;
                });
        },
        detachPart(part_id) {
            this.loading = true;
            this.dataStore.equipment_types
                .detachPart(this.equipment_type.id, part_id)
                .then(() => {
                    this.helpersStore.snackbarSaved();
                    this.$emit('reload');
                })
                .catch((e) => {
                    if (e.response?.status === 401) return;
                });
        },
    },
};
</script>
