<template>
    <div class="dashboard-config-row-container">
        <div class="dashboard-config-row-actions">
            <v-btn
                icon="mdi-arrow-up"
                variant="text"
                color="primary"
                v-if="index > 0"
                @click="$emit('moveUp')"
            ></v-btn>
            <v-btn
                icon="mdi-arrow-down"
                variant="text"
                color="primary"
                v-if="index !== totalRows - 1"
                @click="$emit('moveDown')"
            ></v-btn>
        </div>
        <v-row>
            <v-col
                v-for="(col, index) in rowData.cols"
                v-bind:key="index"
                class="dashboard-config-row-column"
            >
                <v-text-field
                    class="col-size-input-field"
                    type="number"
                    :max="maxColumnSize(index)"
                    min="1"
                    variant="outlined"
                    v-model="col.size"
                    hide-details
                ></v-text-field>

                <v-select
                    variant="outlined"
                    hide-details
                    v-model="col.component"
                    :items="dashboardComponents"
                    item-value="value"
                    ref="formatSelect"
                    :no-data-text="$t('common.emptyTableMessage')"
                >
                    <template #selection="{ item }">
                        {{ $getPropertyTranslation(item.props.title.name) }}
                    </template>
                    <template #item="{ item }">
                        <v-list-item
                            @click="
                                {
                                    col.component = item.value;
                                    $refs.formatSelect.menu = false;
                                }
                            "
                            style="cursor: pointer"
                        >
                            {{ $getPropertyTranslation(item.props.title.name) }}
                        </v-list-item>
                    </template>
                </v-select>
                <v-btn
                    :disabled="rowData.cols.length === 1"
                    icon="mdi-delete"
                    color="var(--warning-main)"
                    variant="text"
                    @click="() => removeColumn(index)"
                ></v-btn>
            </v-col>
        </v-row>
        <div class="dashboard-config-row-actions-container">
            <v-btn
                color="var(--warning-main)"
                class="remove-row-button"
                variant="text"
                @click="() => $emit('removeRow')"
            >
                <v-icon>mdi-plus-circle-outline</v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.removeRow') }}
                </span>
            </v-btn>
            <v-btn
                color="primary"
                @click="() => addColumn()"
                :disabled="addNewColDisabled"
            >
                <v-icon>mdi-plus-circle-outline</v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.addColumn') }}
                </span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { DASHBOARD_COMPONENTS } from './DashboardRowComponents';
import { v4 as uuidv4 } from 'uuid';
export default {
    props: ['row', 'index', 'totalRows'],
    emits: ['removeRow'],
    data() {
        return {
            rowData: this.row,
            dashboardComponents: DASHBOARD_COMPONENTS,
        };
    },
    methods: {
        addColumn() {
            this.rowData.cols.push({
                id: uuidv4(),
                size: 1,
                component: null,
            });
        },
        removeColumn(colIndex) {
            this.rowData.cols.splice(colIndex, 1);
        },
        maxColumnSize(columnIndex) {
            let totalCols = 0;
            this.rowData.cols.forEach((c, index) => {
                if (index !== columnIndex) {
                    totalCols += Number(c.size);
                }
            });
            return 12 - totalCols;
        },
    },
    computed: {
        addNewColDisabled() {
            let totalCols = 0;
            this.rowData.cols.forEach((c) => {
                totalCols += Number(c.size);
            });
            return totalCols >= 12;
        },
    },
};
</script>

<style>
.col-size-input-field {
    width: 68px;
    max-width: 68px;
}

.dashboard-config-row-container {
    border: 1px solid var(--ui-general-outline-01);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.dashboard-config-row-column {
    display: flex;
    align-items: center;
}

.dashboard-config-row-actions-container {
    margin-top: 32px;
    display: flex;
}
.dashboard-config-row-actions-container .v-btn {
    flex: 1;
}
.dashboard-config-row-actions-container .v-btn.remove-row-button {
    color: var(--greyscale-5-white);
}

.dashboard-config-row-actions {
    display: flex;
}
</style>
