<template>
    <v-app class="v-app-container" v-if="tenantError">
        <div class="login-header">
            <div class="logo">
                <img
                    :src="logoUrl"
                    class="login-view-app-logo"
                    v-if="authStore?.tenant?.logo?.path"
                />
                <img
                    src="../../assets/Signalo-Logo.svg"
                    class="login-view-app-logo"
                    v-else
                />
            </div>
        </div>
        <div class="centercontainer">
            <div class="center">
                <h2>{{ $t('errors.error') }}:</h2>
                <h3>{{ errorMessage }}</h3>
            </div>
        </div>
    </v-app>
    <div class="centercontainer" v-else>
        <div class="center">
            <h2>{{ errorMessage ? errorMessage : $t('errors.error') }}</h2>
        </div>
    </div>
</template>
<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
export default {
    setup() {
        return {
            authStore: AUTH_STORE.default(),
        };
    },
    props: ['errorMessage', 'tenantError'],
    data() {
        return {
            storageUrl: this.getFileStorageUrl() + '/',
        };
    },
    computed: {
        logoUrl() {
            return this.storageUrl + this.authStore?.tenant?.logo?.path;
        },
    },
};
</script>
<style>
.login-view-app-logo {
    max-width: 200px;
    max-height: 64px;
}
</style>
