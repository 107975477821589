<template>
    <SignaloAppBar v-if="!dialogMode">
        <template #title> {{ $t('common.dashboardConfig') }} </template>
    </SignaloAppBar>

    <v-main :class="dialogMode ? 'dashboard-config-dialog-mode' : ''">
        <SignaloBreadCrumbs v-if="!dialogMode" />
        <PageLoading v-if="loading" />
        <v-card class="dashboard-config-container">
            <v-card-text>
                <DashboardConfigRow
                    v-for="(row, index) in rows.sort((a, b) => a.sort - b.sort)"
                    :row="row"
                    v-bind:key="row"
                    @removeRow="() => removeRowHandler(index)"
                    :index="index"
                    :totalRows="rows?.length"
                    @moveDown="() => onMove(row, index, 'down')"
                    @moveUp="() => onMove(row, index, 'up')"
                />
                <v-btn
                    color="primary"
                    @click="addRow"
                    class="add-dashboard-row-button"
                >
                    <v-icon>mdi-plus-circle-outline</v-icon>
                    <span class="hidden-sm-and-down icon-button-label">
                        {{ $t('common.addRow') }}
                    </span>
                </v-btn>
            </v-card-text>
            <v-card-actions v-if="!hideButton">
                <v-spacer></v-spacer>
                <v-btn
                    @click="save"
                    color="primary"
                    variant="elevated"
                    :loading="loading"
                    >{{ $t('common.save') }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-main>
</template>
<script>
import DashboardConfigRow from './DashboardConfigRow.vue';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import { DASHBOARD_COMPONENTS } from './DashboardRowComponents';
import { v4 as uuidv4 } from 'uuid';
import { asyncForEach } from 'signalo-vue-utils';
export default {
    props: ['dialogMode', 'userTemplate', 'hideButton'],
    emits: ['close'],
    components: { DashboardConfigRow },
    data() {
        return {
            loading: false,
            rows: [],
            dashboardComponents: DASHBOARD_COMPONENTS,
        };
    },
    setup() {
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { helpersStore, authStore };
    },
    mounted() {
        this.getConfig();
    },
    methods: {
        async getConfig() {
            this.loading = true;
            try {
                let meData;
                if (this.userTemplate?.id) {
                    meData = await this.axios.get(
                        `user_templates/${this.userTemplate.id}`
                    );
                } else {
                    meData = await this.axios.get('me/');
                }
                this.rows = meData?.data?.item?.dashboard_config?.rows || [];
                this.setItemsSort();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }

            this.loading = false;
        },
        addRow() {
            this.rows.push({
                id: uuidv4(),
                sort: this.rows?.length
                    ? Math.max(...this.rows.map((r) => r.sort)) + 1
                    : 1,
                cols: [
                    {
                        size: 1,
                        component: this.dashboardComponents[0].value,
                        id: uuidv4(),
                    },
                ],
            });
        },
        removeRowHandler(index) {
            this.rows.splice(index, 1);
        },
        async save() {
            this.loading = true;
            try {
                if (this.userTemplate?.id) {
                    await this.axios.patch(
                        `user_templates/${this.userTemplate.id}`,
                        {
                            dashboard_config: { rows: this.rows },
                        }
                    );
                    this.$emit('close', true);
                } else {
                    await this.axios.put('me', {
                        dashboard_config: { rows: this.rows },
                    });
                }

                await this.removeDeprecatedChartAttributes();
                await this.authStore.getAuth();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        async removeDeprecatedChartAttributes() {
            try {
                const dashboardChartsIds = [];

                this.rows.forEach((row) => {
                    row.cols.forEach((col) => {
                        if (col.component === 'chart') {
                            dashboardChartsIds.push(`CHART-${col.id}`);
                        }
                    });
                });

                const userChartAttributes = Object.keys(
                    this.authStore.user.attributes
                ).filter((key) => key.startsWith('CHART-'));

                await asyncForEach(
                    userChartAttributes,
                    async (chartAttributeId) => {
                        if (!dashboardChartsIds.includes(chartAttributeId)) {
                            await this.authStore.removeTablePreferences(
                                chartAttributeId
                            );
                        }
                    }
                );
            } catch (err) {
                console.error(err);
            }
        },
        setItemsSort() {
            this.rows = this.rows.sort((a, b) => a.sort - b.sort).map((r, index) => {
                return {
                    ...r,
                    sort: index
                }
            })
        },
        onMove(row, index, dir) {
            let direction = dir == 'down' ? 1 : -1;
            const currentRowCpy = { ...row };
            const siblingRowCpy = { ...this.rows[index + direction] };

            this.rows[index].sort = siblingRowCpy.sort || index + direction;
            this.rows[index + direction].sort = currentRowCpy.sort || index;
        },
    },
};
</script>
<style>
.dashboard-config-container {
    margin: 8px;
    display: flex;
    flex-direction: column;
}

.dashboard-config-container .v-card-text {
    display: flex;
    flex-direction: column;
}
.add-dashboard-row-button {
    margin: auto;
    margin-top: 16px;
}

.dashboard-config-dialog-mode {
    padding: 0 !important;
    margin: 0 !important;
}
</style>
