<template>
    <SignaloDialog
        :open="true"
        :title="$t('common.instructionsAccess')"
        @close="closeDialog"
        :constHeight="false"
        :icon="'mdi-cog-outline'"
    >
        <template #body>
            <v-card-text>
                <v-form ref="form" v-model="dataValid" lazy-validation>
                    <v-row>
                        <v-col cols="12" md="6" xl="6">
                            <v-autocomplete
                                v-model="item.accessable_id"
                                variant="outlined"
                                :label="
                                    $t('common.group') +
                                    ' - ' +
                                    $t('common.users')
                                "
                                :items="usersGroups"
                                :loading="loading"
                                :item-title="
                                    (item) => $getPropertyTranslation(item.name)
                                "
                                item-value="id"
                                :rules="[$REQUIRED_RULE]"
                                class="required-field"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" xl="6">
                            <SignaloDictionaryTreePicker
                                :group="'instruction_group'"
                                v-model="item.instruction_group"
                                v-slot="props"
                                ref="dictionaryTreePicker"
                                :required="true"
                            >
                                <v-text-field
                                    :modelValue="props.text"
                                    @click:control="props.onClick"
                                    @click:clear="props.onClear"
                                    clearable
                                    :label="$t('common.instructionGroup')"
                                    clear-icon="mdi-close-circle-outline"
                                    variant="outlined"
                                    color="var(--greyscale-100)"
                                    class="required-field"
                                    :rules="[$REQUIRED_RULE]"
                                />
                            </SignaloDictionaryTreePicker>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </template>
        <template #buttons>
            <v-btn @click="closeDialog">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="onSave()"
                :loading="saveLoading"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { helpersStore, authStore };
    },
    props: ['editedItem'],
    emits: ['close'],
    data() {
        return {
            dataValid: false,
            loading: false,
            usersGroups: [],
            item: this.editedItem
                ? { ...this.editedItem }
                : { accessable_type: 'Group' },
            saveLoading: false,
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            try {
                this.loading = true;
                const groupsData = await this.axios.get('groups');
                this.usersGroups = groupsData?.data?.items || [];
            } catch (err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        closeDialog() {
            this.$emit('close', false);
        },
        async onSave() {
            try {
                await this.$refs.form.validate();
                if (!this.dataValid) return;

                this.saveLoading = true;

                if (this.item?.id) {
                    await this.axios.put(
                        `instruction_accesses/${this.item?.id}`,
                        this.item
                    );
                } else {
                    await this.axios.post('instruction_accesses', this.item);
                }

                await this.authStore.getAuth();

                this.helpersStore.snackbarSaved();
                this.$emit('close', true);
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>
