<template>
    <!-- <div v-if="field?.type === 'rating'">TODO</div> -->
    <div class="chart-container">
        <Bar
            :data="data"
            :options="chartOptions"
            :key="data.datasets"
            v-if="displayChart"
        />
    </div>
</template>

<script>
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
import { Bar } from 'vue-chartjs';
export default {
    props: ['field'],
    components: { Bar },
    mounted() {
        if (
            !this.field.required &&
            this.field.usersAnswers.some((ua) => !ua.answer_id && !ua.value)
        ) {
            this.data.datasets.push({
                label: 'Brak odpowiedzi',
                borderColor: '#ff0000',
                backgroundColor: '#ff0000',
                data: [
                    this.field.usersAnswers.filter(
                        (ua) => !ua.answer_id && !ua.value
                    ).length,
                ],
            });
        }
        this.displayChart = true;
    },
    methods: {
        randomColor() {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            var a = Math.random() * (1 - 0.5) + 0.5;
            return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
        },
    },
    data() {
        return {
            displayChart: false,
            data: {
                labels: [this.$t('cluer.answers')],
                datasets: this.field?.answers?.map((a) => {
                    const barColor = this.randomColor();
                    return {
                        label: this.$getPropertyTranslation(a.name) || a?.value,
                        data: [
                            this.field.usersAnswers.filter((ua) => {
                                if (this.field.type === this.$QUESTION_TYPES_ENUM.CHECK) {
                                    return ua.answer_id?.includes(a.id);
                                } else if (this.field.type === this.$QUESTION_TYPES_ENUM.RADIO) {
                                    return ua.answer_id === a.id;
                                } else if (this.field.type === this.$QUESTION_TYPES_ENUM.RATING) {
                                    return ua.value === a?.value;
                                }
                            }).length,
                        ],
                        borderColor: barColor,
                        backgroundColor: barColor,
                    };
                }),
            },
            chartOptions: {
                responsive: true,         
                scales: {
                    y: {
                        ticks: {
                            precision: 0,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: true,
                    },
                },
            },
        };
    },
};
</script>
<style>
.chart-container {
    max-height: 50vh;
    display: flex;
    justify-content: center;
}
</style>