<template>
    <div class="survey-document-answer">
        <SignaloWopiDocumentEditor
            v-if="documentUrl"
            :documentUrl="documentUrl"
            :token="token"
        />
    </div>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
export default {
    setup() {
        const authStore = AUTH_STORE.default();
        return { authStore };
    },
    props: ['field', 'disabled'],
    emits: ['answer'],
    data() {
        return {
            documentUrl: null,
        };
    },
    mounted() {
        this.$emit('answer', true); //TODO nie jestem pewien, po prostu żeby walidator mnie puścił;
        this.loadDocument();
    },
    computed: {
        token() {
            return this.authStore._token;
        },
    },
    watch: {
        field() {
            this.loadDocument();
        },
    },
    methods: {
        loadDocument() {
            this.documentUrl = this.field?.file?.wopi?.url;
        },
    },
};
</script>

<style>
.survey-document-answer,
.survey-document-answer #collabora-online-viewer {
    min-height: 600px;
}
</style>
