<template>
    <div class="survey-question">
        <div class="Text Label-Text">
            <span class="Required-Asterisk" v-if="field?.required">*</span>
            {{ $getPropertyTranslation(field?.name) }}
            <!-- <span v-if="field?.context_parameter_id">({{ $t('issues.parameter') }}:&nbsp;{{
                getParameterName(field?.context_parameter_id)
            }})</span> -->
            <br>
            <span v-if="field?.max_points" class="question-info">({{ $t('common.maxPoints') }}:&nbsp;{{ field?.max_points }})<br></span>
            
            <span v-if="field?.min_points_to_pass" class="question-info">({{ $t('common.minPointsToPass') }}:&nbsp;{{ field?.min_points_to_pass }})</span>
        </div>
    </div>
    <QuizGroupedAnswer :field="field" v-if="groupedPreview" />
    <QuizAnswer :field="field" @answer="onAnswer" v-else :userContext="userContext" />
</template>

<script>
import QuizAnswer from './QuizAnswers/QuizAnswer.vue';
import QuizGroupedAnswer from './QuizGroupedAnswers/QuizGroupedAnswer.vue';

export default {
    props: ['field', 'groupedPreview', 'parameters', 'userContext'],
    emits: ['answer'],
    components: { QuizAnswer, QuizGroupedAnswer },
    methods: {
        onAnswer(val) {
            this.$emit('answer', val);
        },
        getParameterName(parameterId) {
            const param = this.parameters?.find((p) => p.id === parameterId);

            if (!param) return '';
            return this.$getPropertyTranslation(param?.name);
        },
    },
};
</script>

<style>
.survey-question {
    padding-top: 16px;
    margin-bottom: 16px;
}

.question-info {
    color: var(--ui-text-text-03);
    font-size: 0.75rem;
    margin-top: 4px;
    display: block;
}
</style>
