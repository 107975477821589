<template>
    <v-icon :class="currentStatusClass">mdi-circle-small</v-icon>
</template>

<script>
export default {
    props: ['networkStatus'],
    computed: {
        currentStatusClass() {
            if (this.networkStatus?.state === 'online') {
                return 'network-status-dot network-status-online';
            } else if (this.networkStatus?.state === 'offline') {
                return 'network-status-dot network-status-offline';
            }
            return 'network-status-dot network-status-unknown';
        },
    },
};
</script>

<style scoped>
.network-status-dot {
    font-size: 48px;
}
.network-status-online {
    color: var(--success-main);
}
.network-status-offline {
    color: var(--warning-main);
}
.network-status-unknown {
    color: var(--ui-icon-icon-deactivated);
}
</style>
