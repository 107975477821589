<template>
    <SignaloDialog
        :open="open"
        :icon="'mdi-cog-outline'"
        :title="$t('common.synchronizationExport')"
        @close="$emit('update:open', false)"
    >
        <template #body>
            <v-tabs v-model="tabs" color="primary">
                <v-tab value="general">
                    {{ $t('common.general') }}
                </v-tab>
                <v-tab value="mapping">
                    {{ $t('common.mapping') }}
                </v-tab>
            </v-tabs>
            <PageLoading v-if="loading" />
            <v-card-text v-else class="sync-card-text-content">
                <v-window v-model="tabs" :touch="false" :key="tabs">
                    <v-window-item key="general" value="general">
                        <SynchronizationExportForm
                            ref="syncFormComponent"
                            :sync="editedItem"
                            :protocols="protocols"
                            :encodings="encodings"
                        />
                    </v-window-item>
                    <v-window-item key="mapping" value="mapping">
                        <JsonEditorVue
                            ref="jsonEditor"
                            v-model="mappingData"
                            :onChange="onMappingChange"
                            :mainMenuBar="true"
                            mode="text"
                        />
                    </v-window-item>
                </v-window>
            </v-card-text>
        </template>
        <template #buttons>
            <v-btn @click="$emit('update:open', false)">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                :loading="dataSaving"
                color="primary"
                @click="save"
                variant="elevated"
                :disabled="jsonEditorError"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import JsonEditorVue from 'json-editor-vue';

import SynchronizationExportForm from './SynchronizationExportForm.vue';
export default {
    props: ['open', 'editedItem'],
    emits: ['update:open', 'update:modelValue', 'save'],
    components: {
        JsonEditorVue,
        SynchronizationExportForm,
    },
    setup() {
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { authStore, helpersStore };
    },
    data() {
        return {
            tabs: 'general',
            mappingData: this.editedItem?.mapping || {},
            protocols: [],
            encodings: [],
            dataSaving: false,
            jsonEditorError: false
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.loading = true;
            try {
                const protocolsData = await this.axios.get('sync/protocols');
                const encodingsData = await this.axios.get('global/encodings');
                this.protocols = protocolsData.data?.items || [];
                this.encodings = encodingsData.data?.items || [];
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        onMappingChange(val) {
            try {
                this.jsonEditorError = !(this.$refs?.jsonEditor?.jsonEditor?.validate()?.validationErrors?.length === 0);
                if (val.text) {
                    this.$refs.syncFormComponent.item.mapping = JSON.parse(
                        val.text
                    );
                } else {
                    this.$refs.syncFormComponent.item.mapping = val.json;
                }
            } catch (err) {
                this.jsonEditorError = true;
            }
        },
        async save() {
            if (!(await this.$refs.syncFormComponent.validateForm())) return;
            this.dataSaving = true;

            try {
                if (this.editedItem.id) {
                    await this.axios.put(
                        `sync/export_stock_movement_configs/${this.editedItem.id}`,
                        this.$refs.syncFormComponent.item
                    );
                } else {
                    if (typeof this.mappingData === 'string') {
                        this.$refs.syncFormComponent.item.mapping = JSON.parse(
                            this.mappingData
                        );
                    } else {
                        this.$refs.syncFormComponent.item.mapping =
                            this.mappingData;
                    }
                    await this.axios.post(
                        'sync/export_stock_movement_configs',
                        this.$refs.syncFormComponent.item
                    );
                }
                this.$emit('save');
                this.$emit('update:open', false);
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.dataSaving = false;
        },
    },
};
</script>
<style>
.v-toolbar.synchronization-card-header {
    background-color: var(--ui-general-card-01);
}
.sync-card-text-content {
    height: 100%;
}
</style>
