<template>
    <div class="Text Label-Text issue-defaults-label mb-4">
        {{ $t('common.defaultValuesForCategory') }}
    </div>
    <SignaloCategoryPicker
        v-model="category"
        v-slot="props"
        return-object
        :allowedCategoriesPaths="allowedCategoriesPaths"
        :flat="flat"
    >
        <v-text-field
            :modelValue="$getPropertyTranslation(category?.name)"
            @click:control="props.onClick"
            @click:clear="props.onClear"
            readonly
            clearable
            clear-icon="mdi-close-circle-outline"
            hide-details
            :label="$t('common.defaultValuesForCategory')"
            variant="outlined"
            color="var(--greyscale-100)"
        >
        </v-text-field>
    </SignaloCategoryPicker>
    <v-divider class="mt-4 mb-2" thickness="2"></v-divider>
    <IssuesDefaultValues 
        :terminal="terminal"
        :key="category?.path"
        v-show="category?.path"
        ref="issueDefaultValuesComponentRef"
        :defaults="issueDefaults?.[this.category?.path]?.defaults"
        :hiddenFields="issueDefaults?.[this.category?.path]?.hiddenFields"
        :requiredFields="issueDefaults?.[this.category?.path]?.requiredFields"
        :displayOptions="issueDefaults?.[this.category?.path]?.displayOptions"
        @update:modelValue:defaults="
            (val) => this.category?.path ? (issueDefaults[this.category.path].defaults = val) : () => {}
        "
        @update:modelValue:hidden_fields="
            (val) => this.category?.path ? (issueDefaults[this.category.path].hiddenFields = val)  : () => {}
        "
        @update:modelValue:required_fields="
            (val) => this.category?.path ? (issueDefaults[this.category.path].requiredFields = val)  : () => {}
        "
        @update:modelValue:display_options="
            (val) => this.category?.path ? (issueDefaults[this.category.path].displayOptions = val)  : () => {}
        "
        @everyHiddenAndRequiredFieldHasDefaultValue="
            onEveryHiddenAndRequiredFieldHasDefaultValue
        "
    />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import IssuesDefaultValues from '@/components/IssueDefaults/IssuesDefaultValues.vue';

export default {
    props: ['defaults', 'allowedCategoriesPaths', 'flat', 'terminal'],
    emits: ['update:modelValue'],
    setup() {
        const dataStore = DATA_STORE.default();
        return { dataStore };
    },
    data() {
        return {
            everyHiddenAndRequiredFieldHasDefaultValue: true,
            category: null,
            issueDefaults: this.defaults ? { ...this.defaults } : {},
        };
    },
    mounted() {
        if (
            typeof this.$refs?.issueDefaultValuesComponentRef
                ?.checkEveryHiddenAndRequiredFieldHasDefaultValue === 'function'
        ) {
            this.everyHiddenAndRequiredFieldHasDefaultValue =
                this.$refs?.issueDefaultValuesComponentRef?.checkEveryHiddenAndRequiredFieldHasDefaultValue();
        }
        this.initIssueDefaults();
    },
    methods: {
        initIssueDefaults() {
            if (!this.category?.id) return;
            this.issueDefaults[this.category.path] =
                this.findCategoryNearestDefaults(this.category.id);
        },
        findCategoryNearestDefaults(categoryId) {
            if (!categoryId) return {};
            let currentCategory = this.dataStore.categories.getById(categoryId);
            const categoryDefaults = this.defaults?.[currentCategory?.path];
            if (categoryDefaults) return { ...categoryDefaults };
            if (!categoryDefaults && !currentCategory.parent_id) {
                return {};
            }
            let parentDefaults;
            let parentCategory = this.dataStore.categories.getById(
                currentCategory.parent_id
            );
            while (!parentDefaults) {
                parentDefaults = this.defaults?.[parentCategory?.path];
                if (parentDefaults) return { ...parentDefaults };
                let parentId = parentCategory?.parent_id;
                if (!parentId) return {};
                parentCategory = this.dataStore.categories.getById(parentId);
            }
            return {};
        },
        onEveryHiddenAndRequiredFieldHasDefaultValue(val) {
            this.everyHiddenAndRequiredFieldHasDefaultValue = val;
            this.$emit(
                'everyHiddenAndRequiredFieldHasDefaultValue',
                this.everyHiddenAndRequiredFieldHasDefaultValue
            );
        },
    },
    watch: {
        category: {
            handler() {
                this.initIssueDefaults();
                if (
                    typeof this.$refs?.issueDefaultValuesComponentRef
                        ?.checkEveryHiddenAndRequiredFieldHasDefaultValue ===
                    'function'
                ) {
                    this.$refs.issueDefaultValuesComponentRef.checkEveryHiddenAndRequiredFieldHasDefaultValue();
                }
            },
            deep: true,
        },
        issueDefaults: {
            handler() {
                this.$emit(
                    'update:modelValue',
                    this.issueDefaults[this.category.path],
                    this.category.path
                );
            },
            deep: true,
        },
    },
    components: { IssuesDefaultValues },
};
</script>
