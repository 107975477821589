<template>
    <SignaloDialog
        :open="dialogOpened"
        @close="() => closeDialog(false)"
        :title="$t('common.profile')"
    >
        <template #body>
            <v-form ref="form" v-model="dataValid" lazy-validation>
                <v-row>
                    <v-col cols="12" md="12" xl="12">
                        <SignaloTranslatableTabs
                            v-slot="{ value, update }"
                            v-model="name"
                            :currentLanguage="$i18n?.locale"
                        :availableLocales="getAvailableLocales()"
                        >
                            <v-text-field
                                :label="$t('common.name')"
                                :modelValue="value"
                                @update:modelValue="update"
                                :rules="required"
                                required
                                variant="outlined"
                                color="var(--greyscale-100)"
                                class="required-field"
                            >
                            </v-text-field>
                        </SignaloTranslatableTabs>
                    </v-col>
                    <v-col cols="12" md="12" xl="12">
                        <v-checkbox
                            color="primary"
                            v-model="isDefault"
                            single-line
                            hide-details
                            :label="$t('tableHeaders.default')"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template #buttons>
            <v-btn @click="() => closeDialog(false)">{{
                $t('common.cancel')
            }}</v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="saveData"
                :loading="saveLoading"
            >
                {{ $t('common.save') }}
            </v-btn></template
        >
    </SignaloDialog>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    props: {
        dialogOpened: Boolean,
        eventTypes: null,
        item: null,
    },
    data() {
        return {
            name: this.item?.name || '',
            dataValid: false,
            saveLoading: false,
            isDefault: this.item?.is_default || false
        };
    },
    emits: ['close'],
    methods: {
        closeDialog(shouldRefech = false) {
            this.$emit('close', shouldRefech);
        },
        parseScheduableType(type) {
            if (!type) return null;
            return this.eventTypes.find((et) => et.value.includes(type));
        },
        async saveData() {
            await this.$refs.form.validate();
            if (!this.dataValid) return;
            this.saveLoading = true;
            try {
                const payload = {
                    name: this.name,
                    is_default: this.isDefault
                };
                if (this.item?.id !== undefined) {
                    await this.axios.put(
                        `user_templates/${this.item.id}`,
                        payload
                    );
                } else {
                    await this.axios.post('user_templates', payload);
                }
                this.closeDialog(true);
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
                this.saveLoading = false;
            }
        },
    },
    computed: {
        required() {
            return [(v) => !!v || this.$t('common.fieldRequired')];
        },
    },
};
</script>
<style>
.schedule-event-types-dialog-width {
    max-width: 600px;
}
</style>
