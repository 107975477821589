<template>
    <SignaloAppBar>
        <template #title>
            <SignaloBreadCrumbs
                :data="breadcrumb"
                class="user-preview-breadcrumb"
            />
        </template>
        <template #bar>
            <v-menu offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn
                        color="primary"
                        v-bind="props"
                        variant="elevated"
                        class="submain-button renew-button"
                    >
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-if="authStore.isAllowed('staff.schedule', 'read')"
                        @click="
                            $router.push({
                                name: 'staff.scheduled-events',
                                params: {
                                    id: item.id,
                                },
                            })
                        "
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-calendar-month
                            </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('common.availability')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="$route.meta.canUpdate"
                        @click="openChangeUserCredentialsDialog(item)"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-key-variant
                            </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('staff.changePasswordAndCode')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="$route.meta.canUpdate"
                        @click="
                            $router.push({
                                name: 'staff.tags',
                                params: {
                                    id: item.id,
                                },
                            })
                        "
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon"> mdi-card </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('common.tags')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="$route.meta.canUpdate"
                        @click="permissionsButtonClicked()"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-account-key
                            </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('staff.editAccess')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="$route.meta.canUpdate"
                        @click="openStorageAccessManage()"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-key-chain
                            </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('common.manageStorageAccess')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="openManageDialog"
                        v-if="$route.meta.canUpdate"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon"> mdi-pencil </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('common.edit')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <!--TODO ACCESS-->
                    <v-list-item
                        @click="goToUserStatements"
                        v-if="authStore.isAllowed('staff', 'read')"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-text-box-multiple-outline
                            </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('common.statements')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                        @click="goToUserStatementsResults"
                        v-if="authStore.isAllowed('statements', 'read')"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-file-document-check-outline
                            </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('common.statementsResults')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item> -->

                    <v-list-item
                        v-if="$route.meta.canUpdate"
                        @click="
                            $router.push({
                                name: 'staff.licenses',
                                params: {
                                    id: item.id,
                                },
                            })
                        "
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-card-account-details-outline
                            </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('staff.goToLicenses')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="userNotificationsDialogOpened = true"
                        v-if="$route.meta.canUpdate"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-bell-cog
                            </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('common.notificationsManagement')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="() => onPartsExport(item.id)">
                        <v-list-item-title
                            class="menu-action"
                            v-if="$route.meta.canRead"
                        >
                            <v-icon class="operation-icon">
                                mdi-file-download
                            </v-icon>
                            <span class="Text Navigation-2">
                                {{ $t('common.export') }} -
                                {{ $t('common.partsTakenByUser') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="() => onTakeHandover(item)"
                        v-if="authStore.isAllowed('parts', 'update')"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-tshirt-crew-outline
                            </v-icon>
                            <span class="Text Navigation-2">
                                {{ $t('common.takeHandover') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="() => impersonateUser(item)"
                        v-if="
                            authStore?.user?.superuser &&
                            item?.id !== authStore?.user?.id
                        "
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon">
                                mdi-account-switch
                            </v-icon>
                            <span class="Text Navigation-2">
                                {{ $t('common.loginAsThisUser') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                        @click="openRemoveUserDialog(item)"
                        v-if="$route.meta.canDelete"
                    >
                        <v-list-item-title class="menu-action">
                            <v-icon class="operation-icon"> mdi-delete </v-icon>
                            <span class="Text Navigation-2">{{
                                $t('common.remove')
                            }}</span>
                        </v-list-item-title>
                    </v-list-item> -->
                </v-list>
            </v-menu>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloPageLoading v-if="loading" />
        <PageError v-else-if="error" :errorMessage="errorMessage" />
        <PageNotFound v-else-if="!item" />
        <div v-else>
            <EquipmentPreviewHeader
                :equipment="item"
                :type="'user'"
                :photo="userPhoto"
            />
            <div class="user-show-container">
                <v-row>
                    <v-col cols="12" md="12" xl="12">
                        <EquipmentMainDataContainer
                            :type="'user'"
                            :user="item"
                            :visibleFields="userVisibleFields"
                    /></v-col>
                    <v-col cols="12" md="12" xl="12">
                        <EquipmentOtherDataContainer
                            :equipment="item"
                            @reload="() => load()"
                            :type="'user'"
                        />
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-main>
    <ManageStaffModal
        v-if="manageDialog"
        :open="manageDialog"
        @close="(shouldRefetch) => closeDialog(shouldRefetch)"
        :user="this.item"
    />
    <SignaloExportDialog
        v-if="exportDialog"
        :open="exportDialog"
        :endpoint="exportEndpoint"
        @close="exportDialog = false"
        :allPagesAllowed="false"
    />
    <!--CREDENTIALS Dialog-->
    <SignaloDialog
        :icon="'mdi-key-variant'"
        :title="$t('staff.changePasswordAndCode')"
        :open="changeUserCredentialsDialog"
        @close="closeChangeUserCredentialsDialog"
        :constHeight="false"
    >
        <template #body>
            <v-col class="tabs-container-col">
                <v-row>
                    <v-tabs v-model="tabs" color="primary">
                        <v-tab value="password">
                            {{ $t('staff.passwordChange') }}
                        </v-tab>
                        <v-tab value="code">
                            {{ $t('staff.codeChange') }}
                        </v-tab>
                    </v-tabs>
                </v-row>
            </v-col>
            <v-col>
                <v-window
                    v-model="tabs"
                    class="ma-0 password-change-window"
                    :key="tabs"
                >
                    <v-window-item
                        value="password"
                        class="password-change-window-item"
                    >
                        <div
                            class="password-change-container"
                            v-if="
                                newCredentials &&
                                newCredentials.userId !== undefined &&
                                newCredentials.userId !== null
                            "
                        >
                            <v-form ref="dataForm" v-model="dataValid">
                                <v-row class="change-credentials-row">
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="$t('staff.newPassword')"
                                            v-model="newCredentials.password"
                                            :rules="passwordRules"
                                            type="password"
                                            variant="outlined"
                                            color="var(--greyscale-100)"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="
                                                $t('staff.confirmNewPassword')
                                            "
                                            v-model="
                                                newCredentials.confirmation
                                            "
                                            :rules="passwordConfirmationRules"
                                            type="password"
                                            variant="outlined"
                                            color="var(--greyscale-100)"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </v-window-item>
                    <v-window-item
                        value="code"
                        class="password-change-window-item"
                    >
                        <div
                            class="password-change-container"
                            v-if="
                                newCredentials &&
                                newCredentials?.userId !== undefined &&
                                newCredentials?.userId !== null
                            "
                        >
                            <v-form ref="codeForm" v-model="codeDataValid">
                                <v-row class="change-credentials-row">
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="$t('staff.newCode')"
                                            v-model="newCredentials.code"
                                            :rules="codeRules"
                                            type="password"
                                            :error-messages="
                                                codeAlreadyTakenErrorMessage
                                            "
                                            variant="outlined"
                                            color="var(--greyscale-100)"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="$t('staff.confirmNewCode')"
                                            v-model="
                                                newCredentials.codeConfirmation
                                            "
                                            :rules="codeConfirmationRules"
                                            type="password"
                                            variant="outlined"
                                            color="var(--greyscale-100)"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </v-window-item>
                </v-window>
            </v-col>
        </template>
        <template #buttons>
            <div v-if="changeUserCredentialsDialog && tabs === 'code'">
                <v-btn text @click="closeChangeUserCredentialsDialog">
                    {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                    variant="elevated"
                    @click="changeUserCode"
                    color="primary"
                    :disabled="
                        newCredentials?.code === '' ||
                        newCredentials?.code !== newCredentials.codeConfirmation
                    "
                >
                    {{ $t('staff.changeCode') }}
                </v-btn>
            </div>
            <div v-if="changeUserCredentialsDialog && tabs === 'password'">
                <v-btn text @click="closeChangeUserCredentialsDialog">
                    {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                    variant="elevated"
                    @click="changeUserCredentials"
                    color="primary"
                    :disabled="
                        newCredentials?.password === '' ||
                        newCredentials?.password !== newCredentials.confirmation
                    "
                >
                    {{ $t('staff.changePassword') }}
                </v-btn>
            </div>
        </template>
    </SignaloDialog>
    <!--END OF CREDENTIALS Dialog-->

    <!--PREMISSIONS DIALOG-->
    <SignaloAppAccessComponent
        v-if="permissionsDialog"
        :title="$t('staff.editAccess')"
        :open="permissionsDialog"
        @close="closePermissionsDialog"
        mode="user"
        v-model="item"
    />
    <!--END OF PREMISSIONS DIALOG-->

    <SignaloManageStorageAccessDialog
        v-if="item?.id && storageAccessManageDialogOpened"
        :id="item?.id"
        @close="storageAccessManageDialogOpened = false"
    />

    <ManageUserNotificationsDialog
        v-if="userNotificationsDialogOpened"
        :user="item"
        @close="onManageUserNotificationsDialogClose"
    />

    <TakeHandoverDialog
        v-if="takeHandoverDialogOpened"
        :user="item"
        @close="
            () => {
                takeHandoverDialogOpened = false;
                currentlyEditedUser = null;
            }
        "
    />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

import ManageStaffModal from './ManageStaffModal.vue';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import SignaloManageStorageAccessDialog from '@/components/SignaloManageStorageAccessDialog/SignaloManageStorageAccessDialog.vue';
import ManageUserNotificationsDialog from './ManageUserNotificationsDialog.vue';
import TakeHandoverDialog from '@/components/TakeHandoverDialog.vue';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    components: {
        ManageStaffModal,
        SignaloManageStorageAccessDialog,
        ManageUserNotificationsDialog,
        TakeHandoverDialog,
    },
    data() {
        return {
            id: this.$route.params.id,

            item: null,
            loading: true,
            manageDialog: false,
            error: false,
            errorMessage: null,
            // crews: [],
            tab: 'parts',
            exportDialog: false,
            newCredentials: null,
            changeUserCredentialsDialog: false,
            tabs: 'password',
            permissionsDialog: false,
            currencies: [],
            storageAccessManageDialogOpened: false,
            userVisibleFields: [
                'name',
                'email',
                'phone',
                'timezone',
                'status',
                'groups',
                'currency_id',
                'department',
                'account_status',
            ],
            userNotificationsDialogOpened: false,
            takeHandoverDialogOpened: false,
        };
    },
    computed: {
        breadcrumb() {
            let res = {};

            if (this.item) {
                res.id = {
                    text: `#${this.id}: ${this.item.name}`,
                    value: this.id,
                };
            } else {
                res.id = {
                    text: `#${this.id}`,
                    value: this.id,
                };
            }

            return res;
        },
        userFunctions() {
            return Object.values(
                this.dataStore.dictionary.group('user_functions')
            );
        },
        departments() {
            return Object.values(
                this.dataStore.dictionary.group('departments')
            );
        },
        statuses() {
            return Object.values(
                this.dataStore.dictionary.group('employee_status')
            );
        },
        passwordRules() {
            return [
                (v) =>
                    v?.trim()?.length >= 8 ||
                    this.$t('errors.wrongPasswordLength'),
            ];
        },
        passwordConfirmationRules() {
            return [
                (c) =>
                    !c ||
                    c === this.newCredentials.password ||
                    this.$t('errors.passwordsNotMatch'),
            ];
        },
        codeRules() {
            return [
                (v) =>
                    v?.trim()?.length >= 5 || this.$t('errors.wrongCodeLength'),
            ];
        },
        codeConfirmationRules() {
            return [
                (c) =>
                    !c ||
                    c === this.newCredentials.code ||
                    this.$t('errors.codesNotMatch'),
            ];
        },
    },
    created() {
        this.axios.get('global/currencies').then((res) => {
            this.currencies = res?.data?.items;
        });
        this.load();
    },
    watch: {
        '$route.params.id': {
            handler(val) {
                if (val && this.$route.name === 'staff.show') {
                    this.id = this.$route.params.id;
                    this.load();
                }
            },
        },
    },
    methods: {
        load() {
            this.loading = true;

            Promise.allSettled([
                this.axios.get('/users/' + this.id).then((x) => {
                    this.item = x.data.item;
                    if (this.item.photo) {
                        this.userPhoto = this.item.photo;
                    } else {
                        this.userPhoto = null;
                    }
                }),
                // this.axios.get('/crews').then((x) => {
                //     this.crews = x.data.items;
                // }),
            ])
                .catch((err) => {
                    if (err?.response?.status === 500) {
                        this.error = true;
                        this.errorMessage = this.$t('errors.error');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async closeDialog(shouldRefetch) {
            if (shouldRefetch) {
                await this.load();
                this.helpersStore.snackbarSaved();
            }
            this.manageDialog = false;
        },
        openManageDialog() {
            this.manageDialog = true;
        },
        // getCrewName(id) {
        //     const crew = this.crews.find((c) => c.id === id);
        //     return this.$getPropertyTranslation(crew?.name) || '-';
        // },
        getUserFunctionName(functionId) {
            if (functionId === null || functionId === undefined) return '-';
            return (
                this.userFunctions.find((x) => x.keyname === functionId)
                    ?.value || '-'
            );
        },
        getUserStatusName(statusId) {
            if (statusId === null || statusId === undefined) return '-';
            return (
                this.statuses.find((x) => x.keyname === statusId)?.value || '-'
            );
        },
        getUserCriticality(keyname) {
            return this.$getPropertyTranslation(
                this.dataStore.dictionary
                    .group('user_criticality')
                    .find((uc) => uc.keyname === keyname)?.value || '-'
            );
        },
        getAccountStatusName(status) {
            switch (status) {
                case 'not_activated':
                    return this.$t('common.notActive');
                case 'active':
                    return this.$t('common.active');
                case 'disabled':
                    return this.$t('common.disabled');
                default:
                    return '-';
            }
        },
        onExport(id = null) {
            this.exportEndpoint = 'users';
            if (id !== null) {
                this.exportEndpoint += `/${id}`;
            }
            this.exportDialog = true;
        },
        onPartsExport(id = null) {
            this.exportEndpoint = `users/${id}/part_operations`;
            this.exportDialog = true;
        },
        openChangeUserCredentialsDialog(item) {
            this.newCredentials = {
                userId: item.id,
                password: '',
                confirmation: '',
                code: '',
                codeConfirmation: '',
                user: item,
            };
            this.changeUserCredentialsDialog = true;
        },
        closeChangeUserCredentialsDialog() {
            this.changeUserCredentialsDialog = false;
            this.newCredentials = null;
        },
        async changeUserCredentials() {
            if (
                this.newCredentials.password !==
                this.newCredentials.confirmation
            )
                return;

            await this.$refs.dataForm.validate();
            if (!this.dataValid) return;
            this.axios
                .patch(`/users/${this.newCredentials.userId}`, {
                    password: this.newCredentials.password,
                })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('staff.passwordUpdated'),
                        'success'
                    );
                    this.changeUserCredentialsDialog = false;
                    this.newCredentials = null;
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                });
        },
        async changeUserCode() {
            this.codeAlreadyTakenErrorMessage = [];
            if (
                this.newCredentials.code !==
                this.newCredentials.codeConfirmation
            )
                return;
            await this.$refs.codeForm.validate();
            if (!this.codeDataValid) return;
            this.axios
                .patch(`/users/${this.newCredentials.userId}`, {
                    code: this.newCredentials.code,
                })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('staff.codeUpdated'),
                        'success'
                    );
                    this.changeUserCredentialsDialog = false;
                    this.newCredentials = null;
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                    if (
                        e?.response?.status === 422 &&
                        e?.response?.data?.errors?.code[0] ===
                            'The code has already been taken.'
                    ) {
                        this.codeAlreadyTakenErrorMessage = [
                            this.$t('errors.codeAlreadyInUse'),
                        ];
                    }
                });
        },
        permissionsButtonClicked() {
            this.permissionsDialog = true;
        },
        closePermissionsDialog() {
            this.permissionsDialog = false;
        },
        openStorageAccessManage() {
            this.storageAccessManageDialogOpened = true;
        },
        async onManageUserNotificationsDialogClose() {
            this.userNotificationsDialogOpened = false;
        },
        goToUserStatementsResults() {
            this.$router.push({
                name: 'statements_results',
                query: {
                    user_id_equals: this.item.id,
                    s_id: 'null',
                },
            });
        },
        goToUserStatements() {
            this.$router.push({
                name: 'staff.statements',
                params: {
                    id: this.item.id,
                },
            });
        },
        onTakeHandover(user) {
            this.item = user;
            this.takeHandoverDialogOpened = true;
        },
        async impersonateUser(item) {
            try {
                const res = await this.axios.post(
                    `/auth/impersonate/${item.id}`,
                    {},
                    { permitAll: true }
                );
                await this.authStore.logout();
                await this.authStore.login(res.data);

                location.href = '/';
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>

<style scoped>
.user-preview-breadcrumb {
    background-color: transparent;
    overflow-x: visible;
}

.user-show-container {
    margin: 16px;
}
</style>
