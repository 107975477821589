import { createRouter, createWebHistory } from 'vue-router';

import Issues from '@/views/Issues/all.js';
import Maintenance from '@/views/Maintenance/all.js';
import Staff from '@/views/Staff/all.js';
import Notes from '@/views/Notes/all.js';
import Reports from '@/views/Reports/all.js';

import Settings from '@/views/settings/all.js';
import MyProfile from '@/views/MyProfile/all.js';

import PageNotFound from '@/components/global/PageNotFound.vue';
import NotificationsView from '@/views/Notifications/all.js';
import PageError from '@/components/global/PageError.vue';

import JobStatussesIndex from '@/views/Processes/JobStatusses/JobStatussesIndex';
import JobStatusPreview from '@/views/Processes/JobStatusses/JobStatusPreview';
import ChangeSuggestions from '@/views/ChangeSuggestions/all';

import MyIssues from '@/views/Issues/MyIssuesIndex';
import settingsRouter from './settingsRouter';
import terminalsRoutes from './terminalsRoutes';
import skillmatrixSettings from './skillmatrixSettings';

export const APP_ROUTES = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/Dashboard/DashboardMainView.vue'),
        meta: {
            availableAsTerminalDefaultView: true,
            permitAll: true,
            bc: [{ text: 'breadCrumb.home', to: { name: 'dashboard' } }],
        },
    },
    {
        path: '/my_issues',
        name: 'my_issues',
        component: MyIssues,
        meta: {
            //TODO to powinno byc dostepne tylko jeśli nie jest terminal
            permitAll: true,
            bc: [
                {
                    text: 'dashboard.components.myIssues.title',
                    to: { name: 'my_issues' },
                },
            ],
        },
    },
    {
        path: '/my_issues/:id',
        name: 'my_issues.show',
        component: Issues.MyIssueShow,
        meta: {
            permitAll: true,
            bc: [
                {
                    text: 'dashboard.components.myIssues.title',
                    to: { name: 'my_issues' },
                },
                { key: ':id', to: { name: 'my_issues.show' } },
            ],
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: NotificationsView.Index,
        meta: {
            availableAsTerminalDefaultView: true,
            permitAll: true,
            bc: [
                {
                    text: 'breadCrumb.notifications',
                    to: { name: 'notifications' },
                },
            ],
        },
    },
    {
        path: '/instruction-preview/:id/r/:rid',
        name: 'instruction.show',
        component: () => import('@/views/Instructions/InstructionPreview.vue'),
        meta: {
            currentAccessName: 'settings.instructions.preview',
            bc: [{ text: 'tpm.instructions', to: { name: 'instructions' } }],
        },
    },
    {
        path: '/instructions/:id?', //:id === instruction_group keyname
        name: 'instructions',
        component: () => import('@/views/Instructions/InstructionsIndex.vue'),
        meta: {
            currentAccessName: 'settings.instructions',
            availableAsTerminalDefaultView: true,
            bc: [{ text: 'tpm.instructions', to: { name: 'instructions' } }],
        },
    },
    {
        path: '/instruction/:iid?/revisions',
        name: 'instructions.revisions',
        component: () =>
            import(
                '@/views/Instructions/Revisions/InstructionRevisionsIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.instructions',
            availableAsTerminalDefaultView: true,
            bc: [
                { text: 'tpm.instructions', to: { name: 'instructions' } },
                // { key: ':iid', to: { name: 'instructions', params: {id: 258} } }, //TODO trzeba bedzie wyszukac grupe danej instrukcji
                // { text: 'tpm.instructionRevisions', to: { name: 'instructions.revisions' } },
            ],
        },
    },
    {
        path: '/issues',
        name: 'issues',
        component: Issues.Index,
        meta: {
            availableAsTerminalDefaultView: true,
            bc: [{ text: 'breadCrumb.issues', to: { name: 'issues' } }],
        },
    },
    {
        path: '/notes',
        name: 'notes',
        component: Notes.Index,
        meta: { bc: [{ text: 'breadCrumb.notes', to: { name: 'notes' } }] },
    },
    {
        path: '/changes_suggestions',
        name: 'changeSuggestions.index',
        component: ChangeSuggestions.Index,
        meta: {
            permitAll: true,
            bc: [
                {
                    text: 'common.changeSuggestions',
                    to: { name: 'changeSuggestions.index' },
                },
            ],
        },
    },
    {
        path: '/issue/:id',
        name: 'issues.show',
        component: Issues.Show,
        meta: {
            bc: [
                { text: 'breadCrumb.issues', to: { name: 'issues' } },
                { key: ':id', to: { name: 'issues.show' } },
            ],
        },
    },
    {
        path: '/timetable',
        name: 'timetable',
        component: Maintenance.Index,
        meta: {
            availableAsTerminalDefaultView: true,
            bc: [
                {
                    text: 'breadCrumb.maintenance',
                    to: { name: 'timetable' },
                },
            ],
        },
    },

    {
        path: '/reports',
        name: 'reports',
        component: Reports.Index,
        meta: {
            bc: [
                {
                    text: 'reports.reports',
                    to: { name: 'reports' },
                },
            ],
        },
    },
    {
        path: '/dashboard-config',
        name: 'settings.dashboardConfig',
        component: Settings.DashboardConfig,
        meta: {
            permitAll: true,
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.dashboardConfig',
                    to: { name: 'settings.dashboardConfig' },
                },
            ],
        },
    },

    {
        path: '/job-statuses',
        name: 'jobStatusses',
        component: JobStatussesIndex,
        meta: {
            availableAsTerminalDefaultView: true,
            currentAccessName: 'process',
            bc: [
                {
                    text: 'common.jobStatusses',
                    to: { name: 'jobStatusses' },
                },
            ],
        },
    },
    {
        path: '/job-statuses/:id',
        name: 'jobStatusses.show',
        component: JobStatusPreview,
        meta: {
            currentAccessName: 'process',
            bc: [
                {
                    text: 'common.jobStatusses',
                    to: { name: 'jobStatusses' },
                },
                { key: ':id', to: { name: 'jobStatusses.show' } },
            ],
        },
    },
    {
        path: '/staff',
        name: 'staff',
        component: Staff.Index,
        meta: {
            availableAsTerminalDefaultView: true,
            bc: [{ text: 'breadCrumb.staff', to: { name: 'staff' } }],
        },
    },
    {
        path: '/staff/users_work-time-register',
        name: 'staff_work-time-register',
        component: () => import('@/views/Staff/WorkTimeRegisterView.vue'),
        meta: {
            currentAccessName: 'work-time-register',
            bc: [
                {
                    text: 'breadCrumb.staff',
                    to: { name: 'staff' },
                },
                {
                    text: 'common.workTimeRegister',
                    to: { name: 'staff_work-time-register' },
                },
            ],
        },
    },

    {
        path: '/staff/:id',
        name: 'staff.show',
        component: Staff.Show,
        meta: {
            bc: [
                { text: 'breadCrumb.staff', to: { name: 'staff' } },
                { key: ':id' },
            ],
        },
    },
    {
        path: '/staff/:id/licenses',
        name: 'staff.licenses',
        component: () => import('signalo-vue-settings-staff-licenses-view'),
        meta: {
            currentAccessName: 'staff.edit',
            bc: [
                { text: 'breadCrumb.staff', to: { name: 'staff' } },
                { key: ':id', to: { name: 'staff.show' } },
                { text: 'breadCrumb.licenses' },
            ],
        },
    },
    {
        path: '/staff/:id/statements',
        name: 'staff.statements',
        component: () => import('@/views/Staff/StaffStatementsIndex.vue'),
        meta: {
            currentAccessName: 'staff',
            bc: [
                { text: 'breadCrumb.staff', to: { name: 'staff' } },
                { key: ':id', to: { name: 'staff.show' } },
                { text: 'common.statements' },
            ],
        },
    },
    {
        path: '/staff/:id/statements/:sid/content',
        name: 'staff.statements.content',
        component: () => import('@/views/Staff/StaffStatementContentIndex.vue'),
        meta: {
            currentAccessName: 'staff',
            bc: [
                { text: 'breadCrumb.staff', to: { name: 'staff' } },
                { key: ':id', to: { name: 'staff.show' } },
                { text: 'common.statements' },
                { key: ':sid', to: { name: 'staff.statements.content' } },
                { text: 'common.content' },
            ],
        },
    },
    {
        path: '/staff/:id/tags',
        name: 'staff.tags',
        component: () => import('@/views/Tags/TagsIndex.vue'),
        meta: {
            currentAccessName: 'staff.edit',
            bc: [
                { text: 'breadCrumb.staff', to: { name: 'staff' } },
                { key: ':id', to: { name: 'staff.show' } },
                { text: 'common.tags' },
            ],
        },
    },
    {
        path: '/staff/:id/instructions',
        name: 'staff.instructions',
        component: () => import('@/views/Staff/StaffInstructionsIndex.vue'),
        meta: {
            currentAccessName: 'staff.edit',
            bc: [
                { text: 'breadCrumb.staff', to: { name: 'staff' } },
                { key: ':id', to: { name: 'staff.show' } },
                { text: 'tpm.instructions' },
            ],
        },
    },
    {
        path: '/staff/all_scheduled_events',
        name: 'staff.all-scheduled-events',
        component: Settings.ScheduleEvents,
        meta: {
            currentAccessName: 'staff.edit',
            bc: [
                {
                    text: 'breadCrumb.staff',
                    to: { name: 'staff' },
                },
                {
                    text: 'common.availability',
                    to: { name: 'staff.scheduled-events' },
                },
            ],
        },
    },
    {
        path: '/staff/:id/scheduled_events',
        name: 'staff.scheduled-events',
        component: Settings.ScheduleEvents,
        meta: {
            currentAccessName: 'staff.schedule',
            bc: [
                {
                    text: 'breadCrumb.staff',
                    to: { name: 'staff' },
                },
                { key: ':id', to: { name: 'staff.show' } },
                {
                    text: 'common.availability',
                    to: { name: 'staff.scheduled-events' },
                },
            ],
        },
    },

    {
        path: '/terminal-instructions/:id?',
        name: 'terminal-instructions',
        component: () =>
            import('@/views/TerminalInstructions/TerminalInstructionView.vue'),
        meta: {
            availableAsTerminalDefaultView: true,
            permitAll: true, //TODO
        },
    },

    {
        path: '/my-profile',
        name: 'my_profile',
        component: MyProfile.Index,
        meta: {
            permitAll: true,
            bc: [
                {
                    text: 'breadCrumb.myProfile',
                    to: { name: 'my_profile' },
                },
            ],
        },
    },
    {
        path: '/me/issue_history',
        name: 'me.issues.history',
        component: () => import('@/views/MyProfile/Issues/IssueHistory.vue'),
        meta: {
            permitAll: true,
            bc: [
                {
                    text: 'breadCrumb.myProfile',
                    to: { name: 'my_profile' },
                },
                {
                    text: 'breadCrumb.me.issue_history',
                    disabled: true,
                    href: '#',
                },
            ],
        },
    },
    {
        path: '/storage/:id?',
        name: 'storage',
        component: () => import('@/views/Storage/StorageIndex.vue'), //TODO z liby
        meta: {
            bc: [],
        },
    },
    {
        path: '/parts',
        name: 'parts',
        component: async () =>
            await (
                await import('signalo-vue-parts-view')
            ).PartsIndex,
        meta: {
            availableAsTerminalDefaultView: true,
            bc: [{ text: 'breadCrumb.parts', to: { name: 'parts' } }],
        },
    },
    {
        path: '/parts/:id',
        name: 'parts.show',
        component: async () =>
            await (
                await import('signalo-vue-parts-view')
            ).PartsShow,
        meta: {
            availableAsTerminalDefaultView: true,
            bc: [
                { text: 'breadCrumb.parts', to: { name: 'parts' } },
                { key: ':id', to: { name: 'parts.show' } },
            ],
        },
    },
    {
        path: '/parts/:id/entry/:entryId',
        name: 'parts.entries.show',
        component: async () =>
            await (
                await import('signalo-vue-part-entry-view')
            ).PartEntryShow,
        meta: {
            currentAccessName: 'parts.details',
            bc: [
                { text: 'breadCrumb.parts', to: { name: 'parts' } },
                { key: ':partId', to: { name: 'parts.show' } },
                { text: 'parts.partEntry', disabled: true },
                { key: ':id', to: { name: 'parts.entries.show' } },
            ],
        },
    },
    {
        path: '/expiring-parts',
        name: 'expiring_handovers',
        component: async () =>
            import('@/views/ExpiringHandovers/ExpiringHandovers.vue'),
        meta: {
            currentAccessName: 'parts',
            availableAsTerminalDefaultView: true,
            bc: [
                { text: 'breadCrumb.parts', to: { name: 'parts' } },
                {
                    text: 'common.expiringHandovers',
                    to: { name: 'expiring_handovers' },
                },
            ],
        },
    },
    {
        path: '/parts-usages-history',
        name: 'part_usages_history',
        component: async () =>
            import('@/views/PartsUsagesHistory/PartsUsagesHistory.vue'),
        meta: {
            currentAccessName: 'parts',
            availableAsTerminalDefaultView: true,
            bc: [
                { text: 'breadCrumb.parts', to: { name: 'parts' } },
                {
                    text: 'common.handoversHistory',
                    to: { name: 'part_usages_history' },
                },
            ],
        },
    },
    {
        path: '/counters',
        name: 'counters',
        component: async () =>
            await (
                await import('signalo-vue-counters-view')
            ).CountersIndex,
        meta: {
            currentAccessName: 'counters',
            bc: [
                {
                    text: 'common.counters',
                    to: { name: 'counters' },
                },
            ],
        },
    },
    ...terminalsRoutes,
    ...settingsRouter,
    ...skillmatrixSettings,
    {
        path: '/controls',
        name: 'controls',
        component: () => import('@/views/Controls/ControlsIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'common.controls',
                    to: { name: 'controls' },
                },
            ],
        },
    },
    {
        path: '/statements-results',
        name: 'statements_results',
        component: async () =>
            await import('@/components/Statements/StatementsResults.vue'),
        meta: {
            currentAccessName: 'statements', //TODO
            bc: [
                {
                    text: 'common.statementsResults',
                    to: { name: 'statements_results' },
                },
            ],
        },
    },
    {
        path: '/orders',
        name: 'orders',
        component: async () => await import('@/views/Orders/OrdersIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'common.orders',
                    to: { name: 'orders' },
                },
            ],
        },
    },
    {
        path: '/production_plans',
        name: 'production_plans',
        component: async () =>
            await import('@/views/ProductionPlans/ProductionPlansIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'common.productionPlans',
                    to: { name: 'production_plans' },
                },
            ],
        },
    },
    {
        path: '/production_plans/:id/production_records',
        name: 'production_records',
        component: async () =>
            await import(
                '@/views/ProductionPlans/ProductionRecords/ProductionPlanRecordsIndex.vue'
            ),
        meta: {
            currentAccessName: 'production',
            bc: [
                {
                    text: 'common.productionPlans',
                    to: { name: 'production_plans' },
                },
                { key: ':id' },
                {
                    text: 'production.productionRecords',
                    to: { name: 'production_records' },
                },
            ],
        },
    },
    // {
    //     path: '/settings/statements-events',
    //     name: 'settings.statements_events',
    //     // component: async () => import("signalo-vue-settings-surveys-events-view"),
    //     component: async () =>
    //         await import('@/components/Statements/StatementsEventsIndex.vue'),
    //     meta: {
    //         currentAccessName: 'settings.survey_events',
    //         bc: [
    //             {
    //                 text: 'breadCrumb.configuration',
    //                 to: { name: 'settings' },
    //             },
    //             {
    //                 text: 'common.statementsEvents',
    //                 to: { name: 'settings.statements_events' },
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: '/settings/qr_actions',
    //     name: 'settings.qr_actions',
    //     component: async () =>
    //         await import('@/components/Statements/QRActionsIndex.vue'),
    //     meta: {
    //         bc: [
    //             {
    //                 text: 'breadCrumb.configuration',
    //                 to: { name: 'settings' },
    //             },
    //             {
    //                 text: 'cluer.qrActionsSettings',
    //                 to: { name: 'settings.qr_actions' },
    //             },
    //         ],
    //     },
    // },
    {
        path: '/simplified_handling',
        name: 'simplified_handling',
        component: () =>
            import('@/views/SimplifiedHandling/SimplifiedHandlingIndex.vue'),
        meta: {
            permitAll: true,
        },
    },
    {
        path: '/issue-simple/:id',
        name: 'issues.show_simple',
        component: Issues.ShowSimple,
        meta: {
            permitAll: true,
            bc: [
                {
                    text: 'breadCrumb.issues',
                    to: { name: 'simplified_handling' },
                },
                { key: ':id', to: { name: 'issues.show_simple' } },
            ],
        },
    },
    {
        path: '/production_lines',
        name: 'production_lines',
        component: () => import('@/views/ProductionLines/ProductionLinesIndex'),
        meta: {
            currentAccessName: 'production_plans',
            bc: [
                {
                    text: 'common.productionLines',
                    to: { name: 'production_lines' },
                },
            ],
        },
    },
    {
        path: '/skillmatrix',
        name: 'skillmatrix',
        component: () => import('@/views/Skillmatrix/SkillmatrixIndex.vue'),
        meta: {
            currentAccessName: 'skillmatrix',
            bc: [
                {
                    text: 'common.skillmatrix',
                    to: { name: 'skillmatrix' },
                },
            ],
        },
    },
    {
        path: '/instructions-revision-to-consider', //:id === instruction_group keyname
        name: 'instructionsConsider',
        component: () =>
            import(
                '@/views/InstructionRevisionsToConsider/InstructionRevisionsToConsiderIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.instructions',
            availableAsTerminalDefaultView: true,
            bc: [{ text: 'tpm.instructions', to: { name: 'instructions' } }],
        },
    },
    {
        path: '/visits/:id/employees',
        name: 'visits_employees',
        component: () =>
            import('@/views/WorkPermits/Employees/EmployeesIndex.vue'),
        meta: {
            currentAccessName: 'visits',
            bc: [
                {
                    text: 'common.visits',
                    to: { name: 'visits' },
                },
                { key: ':id', to: { name: 'visits' } },
                {
                    text: 'issues.assignedEmployees',
                    to: { name: 'visits_employees' },
                },
            ],
        },
    },
    {
        path: '/visits/:id/work-permits',
        name: 'visit_work_permits',
        component: () =>
            import('@/views/Visits/WorkPermits/WorkPermitsIndex.vue'),
        meta: {
            currentAccessName: 'visits',
            bc: [
                {
                    text: 'common.visits',
                    to: { name: 'visits' },
                },
                { key: ':id', to: { name: 'visits' } },
                {
                    text: 'common.workPermits',
                    to: { name: 'visit_work_permits' },
                },
            ],
        },
    },
    {
        path: '/visits',
        name: 'visits',
        component: () => import('@/views/Visits/VisitsIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'common.visits',
                    to: { name: 'visits' },
                },
            ],
        },
    },
    {
        path: '/visit/:id',
        name: 'visit',
        component: () => import('@/views/Visits/VisitShow.vue'),
        meta: {
            currentAccessName: 'visits',
            bc: [
                {
                    text: 'common.visits',
                    to: { name: 'visits' },
                },
                { key: ':id', to: { name: 'visit' } },
            ],
        },
    },
    {
        path: '/work-permits/:id/employees',
        name: 'work_permits_employees',
        component: () =>
            import('@/views/WorkPermits/Employees/EmployeesIndex.vue'),
        meta: {
            currentAccessName: 'work_permits',
            bc: [
                {
                    text: 'common.workPermits',
                    to: { name: 'work_permits' },
                },
                { key: ':id', to: { name: 'work_permits' } },
                {
                    text: 'issues.assignedEmployees',
                    to: { name: 'work_permits_employees' },
                },
            ],
        },
    },
    {
        path: '/work-permits',
        name: 'work_permits',
        component: () => import('@/views/WorkPermits/WorkPermitsIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'common.workPermits',
                    to: { name: 'work_permits' },
                },
            ],
        },
    },
    {
        path: '/error',
        name: 'error',
        component: PageError,
        meta: {
            permitAll: true,
        },
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: PageNotFound,
        meta: {
            permitAll: true,
        },
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes: [...APP_ROUTES],
});

export default router;
