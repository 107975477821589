var currentSubdomain = null;
function getSubdomain() {
    if (currentSubdomain == null) {
        let url = process.env.VUE_APP_URL;
        if (url === undefined) {
            throw new Error('VUE_APP_URL not defined in .env file');
        }

        const pre = url.replace('*', '([a-zA-Z0-9\\-\\_]+)').replace('.', '\\.');
        const re = new RegExp(`^${pre}$`);
        let res = window.location.hostname.match(re);
        if (res && res.length > 1) {
            currentSubdomain = res[1];
        }
        if (window.location.hostname.includes('localhost')) {
            currentSubdomain = 'test';
        }
    }
    return currentSubdomain;
}

function getAppUrl() {
    return `${window.location.protocol}//${process.env.VUE_APP_URL.replace('*', getSubdomain())}/`;
}

function getApiUrl(subdomain) {
    return process.env.VUE_APP_API_URL.replace('{tenant}', subdomain) + '/' + subdomain;
}

function getStorageUrl(subdomain) {
    return process.env.VUE_APP_STORAGE_URL.replace('{tenant}', subdomain);
}

export default { getSubdomain, getAppUrl, getApiUrl, getStorageUrl };
