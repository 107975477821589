export default [
    {
        text: 'tableHeaders.id',
        value: 'id',
        sortable: true,
    },
    {
        text: 'tableHeaders.name',
        value: 'title',
        width: 200,
        sortable: true,
    },
    {
        text: 'tableHeaders.category',
        value: 'category_id',
        sortable: true,
        width: 140,
    },

    {
        text: 'tableHeaders.status',
        value: 'flow_state_name',
        sortable: false,
    },
    {
        text: 'tableHeaders.department',
        value: 'department',
        sortable: true,
    },
    {
        text: 'common.productionLine',
        value: 'prod_line',
    },
    {
        text: 'tableHeaders.criticality',
        value: 'criticality',
    },
    {
        text: 'tableHeaders.kind',
        value: 'kind',
    },
    {
        text: 'tableHeaders.equipment',
        value: 'equipment_id',
    },
    {
        text: 'tableHeaders.symbol',
        value: 'equipment_symbol',
    },

    {
        text: 'tableHeaders.created',
        value: 'created_at',
        sortable: true,
    },


    // {
    //     text: 'processes.finished_at',
    //     value: 'finished_at',
    //     sortable: true,
    // },
    {
        text: 'tableHeaders.createdBy',
        value: 'created_by',
        sortable: true,
    },
    {
        text: 'issues.responsibleUser',
        value: 'responsible_user',
        sortable: true,
    },
    // {
    //     text: 'issues.responsibleUser',
    //     value: 'responsible_user',
    //     sortable: true,
    // },
    {
        text: 'tableHeaders.finished_at',
        value: 'finished_at',
        sortable: true,
    },
    { value: 'operation', fixed: true, width: 54 },
];
