<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.configuration') }} </template>
        <template #bar>>
            <v-btn
                @click="onCreate"
                v-if="$route.meta.canCreate"
                color="primary"
                variant="elevated"
                class="add-button"
            >
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <PageLoading v-if="loading" />
        <div v-else>
            <EasyDataTable buttons-pagination
                :headers="tableHeaders"
                :items="items"
                :empty-message="$t('common.emptyTableMessage')"
                table-class-name="easy-table-style"
                
                :rows-per-page-message="$t('common.rowsPerPage')"
                :rows-of-page-separator-message="$t('common.rowsOfPage')"
            >
                <template #item-name="item">
                    {{ $getPropertyTranslation(item.name) }}
                </template>
                <template #item-mo="item">
                    <v-icon v-if="item?.mo">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                </template>
                <template #item-tu="item">
                    <v-icon v-if="item?.tu">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                </template>
                <template #item-we="item">
                    <v-icon v-if="item?.we">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                </template>
                <template #item-th="item">
                    <v-icon v-if="item?.th">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                </template>
                <template #item-fr="item">
                    <v-icon v-if="item?.fr">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                </template>
                <template #item-sa="item">
                    <v-icon v-if="item?.sa">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                </template>
                <template #item-su="item">
                    <v-icon v-if="item?.su">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                </template>
                <template #item-holiday="item">
                    <v-icon v-if="item?.holiday">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                </template>
                <template #item-operation="item">
                    <div class="actions-column">
                        <v-menu offset-y v-if="$route.meta.canUpdate || $route.meta.canDelete">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    color="primary"
                                    v-bind="props"
                                    variant="outlined"
                                    icon
                                    class="table-action-icon-button"
                                >
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="onEdit(item)"
                                    v-if="$route.meta.canUpdate"
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-pencil
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.edit')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="onRemove(item)"
                                    v-if="$route.meta.canDelete"
                                >
                                    <v-list-item-title class="menu-action">
                                        <v-icon class="operation-icon">
                                            mdi-delete
                                        </v-icon>
                                        <span class="Text Navigation-2">{{
                                            $t('common.remove')
                                        }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </EasyDataTable>
        </div>
    </v-main>
    <WorkSchemaManage
        v-if="manageDialog"
        v-model:open="manageDialog"
        :mode="dialogMode"
        :editedItem="editedItem"
        @closeDialog="onCloseDialogHandler"
    />
    <SignaloConfirmationDialog
        :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => remove(value)"
    />
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import WorkSchemaManage from './WorkSchemaManage.vue';

export default {
    components: { WorkSchemaManage },
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    data() {
        return {
            loading: true,
            items: [],
            manageDialog: false,
            dialogMode: null,
            editedItem: null,
            itemToRemove: null,
            removeDialog: false,
        };
    },
    computed: {
        tableHeaders() {
            return [
                { text: this.$t('common.name'), value: 'name' },
                { text: this.$t('common.mo'), value: 'mo' },
                { text: this.$t('common.tu'), value: 'tu' },
                { text: this.$t('common.we'), value: 'we' },
                { text: this.$t('common.th'), value: 'th' },
                { text: this.$t('common.fr'), value: 'fr' },
                { text: this.$t('common.sa'), value: 'sa' },
                { text: this.$t('common.su'), value: 'su' },
                { text: this.$t('common.holiday'), value: 'holiday' },
                {  value: 'operation', width: 54, fixed: true },
            ];
        },
    },
    created() {
        this.loadTable();
    },
    methods: {
        async loadTable() {
            this.loading = true;
            try {
                const data = await this.axios.get(`work_schemas`);
                this.items = Object.values(data?.data?.items);
            } catch (err) {
                if (err?.response?.status == 401) return;
            }
            this.loading = false;
        },
        onCreate() {
            this.editedItem = {
                name: {},
                mo: false,
                tu: false,
                we: false,
                th: false,
                fr: false,
                sa: false,
                su: false,
                friday: false,
            };
            this.dialogMode = 'create';
            this.manageDialog = true;
        },
        async onCloseDialogHandler(refetchData) {
            if (refetchData) {
                await this.loadTable();
            }
            this.manageDialog = false;
            this.editedItem = null;
            this.dialogMode = null;
        },
        onEdit(item) {
            this.editedItem = item;
            this.dialogMode = 'edit';
            this.manageDialog = true;
        },
        onRemove(item) {
            this.itemToRemove = item;
            this.removeDialog = true;
        },
        async remove(shouldRemove) {
            if (!shouldRemove) {
                this.removeDialog = false;
                this.itemToRemove = null;
                return;
            }
            this.loading = true;
            try {
                await this.axios.delete(`work_schemas/${this.itemToRemove.id}`);
                await this.loadTable();
                this.removeDialog = false;
                this.itemToRemove = null;
                this.helpersStore.snackbar(
                    this.$t('common.removed'),
                    'success'
                );
            } catch (err) {
                if (err?.response?.status == 401) return;
            }

            this.loading = false;
        },
    },
};
</script>
