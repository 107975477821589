<template>
    <QuizGroupedAnswerTable
        v-if="
            field.type === $QUESTION_TYPES_ENUM.TEXT ||
            field.type === $QUESTION_TYPES_ENUM.LONGTEXT ||
            field.type === $QUESTION_TYPES_ENUM.PHOTO
        "
        :usersAnswers="field.usersAnswers"
        :field="field"
    />
    <QuizGroupedAnswerChart v-else :field="field" />
</template>

<script>
import QuizGroupedAnswerTable from './QuizGroupedAnswerTable.vue';
import QuizGroupedAnswerChart from './QuizGroupedAnswerChart.vue';
export default {
    props: ['field'],
    components: { QuizGroupedAnswerChart, QuizGroupedAnswerTable },
};
</script>
