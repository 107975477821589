export default {
    STORAGE_OPERATIONS: {
        value: 'Signalo\\Laravel\\Exports\\Reports\\PartOperationsExport',
        label: 'reports.types.storage_operations',
    },
    PART_QUANTITY_EXPORT: {
        value: 'Signalo\\Laravel\\Exports\\Reports\\PartQuantityExport',
        label: 'reports.types.part_quantity_export',
    },
};
