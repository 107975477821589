import { toMySqlDate } from 'signalo-vue-utils';

export function getToDate(date) {
    return toMySqlDate(new Date(date).setHours(23, 59, 59));
}
export function getFromDate(date) {
    return toMySqlDate(new Date(date).setHours(0, 0, 0));
}

export function addDaysToDate(
    date,
    nDays,
    limit // 'from' | 'to'
) {
    let n = nDays;
    const result = new Date(date);
    result.setDate(result.getDate() + n);
    if (limit === 'from') {
        result.setHours(0, 0, 0, 0);
    } else if (limit === 'to') {
        result.setHours(23, 59, 59);
    }
    return new Date(result);
}
export function getMondayOfWeek(date) {
    const monday = new Date(date);
    const day = monday.getDay();
    const diff = (day === 0 ? -6 : 1) - day;
    monday.setDate(monday.getDate() + diff);
    return monday;
}

export function getLastDayOfCurrentWeek(date = new Date()) {
    const lastDayOfWeek = new Date(date);
    const daysUntilSunday = date.getDay() === 0 ? 0 : 7 - date.getDay();

    lastDayOfWeek.setDate(date.getDate() + daysUntilSunday);
    return lastDayOfWeek;
}
export function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
}

export function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0);
}
export function getFirstDayOfYear(year) {
    return new Date(year, 0, 1);
}
export function getLastDayOfYear(year) {
    return new Date(year, 11, 31);
}
