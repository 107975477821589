<template>
    <v-card class="mx-3 overflow-visible" style="padding: 0 !important">
        <template v-slot:title>
            <v-toolbar color="#fff">
                <v-toolbar-title>
                    <v-icon class="app-dialog-title-icon">mdi-draw-pen</v-icon>
                    {{ $t('common.pendingStatements') }}</v-toolbar-title
                >
            </v-toolbar>
        </template>
        <EasyDataTable buttons-pagination
            :headers="tableHeaders"
            :items="items"
            :loading="loading"
            :empty-message="$t('common.emptyTableMessage')"
            @click-row="rowClicked"
            table-class-name="easy-table-style"
            sort-by="created_at"
            sort-type="asc"
            :rows-items="[5, 10, 25, 50, 75, 100]"
            :rows-per-page="5"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
        >
            <template #item-operation="item">
                <SignaloDynamicMenu :rowItem="item" :menuItems="tableActions" />
            </template>
            <template #item-created_at="item">
                {{
                    new Date(item.created_at).toLocaleString(
                        authStore?.tenant?.default_locale || 'pl',
                        { timeZone: authStore.tenant?.default_timezone }
                    )
                }}
            </template>
            <template #item-survey="item">
                <p>
                    {{ $getPropertyTranslation(item.survey.name) }}
                </p>
            </template>
            <template #item-issue_id="item">
                <p v-if="item.issue_id">
                    <router-link
                        @click.stop="() => {}"
                        :to="{
                            name: 'my_issues.show',
                            params: {
                                id: item.issue_id,
                            },
                        }"
                    >
                        #{{ item.issue_id }}
                    </router-link>
                </p>
                <p v-else>-</p>
            </template>
        </EasyDataTable>
    </v-card>
</template>
<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    name: 'SignaloPendingStatements',
    emits: ['reload'],
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = HELPERS_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    mounted() {
        this.load();
    },
    data() {
        return {
            items: [],
            loading: true,
        };
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'survey',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.created'),
                    value: 'created_at',
                    sortable: true,
                },
                {
                    fixed: true,
                    value: 'operation',
                    width: 100,
                    text: this.$t('tableHeaders.actions'),
                },
            ];
        },
        tableActions() {
            return [
                {
                    label: this.$t('common.fill'),
                    action: this.openSurvey,
                },
            ];
        },
    },
    methods: {
        async load() {
            this.loading = true;
            try {
                const { data } = await this.axios.get('/me/surveys', {
                    completed_at_equals: 'null',
                });
                this.items = Object.values(data?.items);
            } catch (err) {
                console.error(err);
            }
            this.loading = false;
        },
        async openSurvey(item) {
            const mySurveyData = await this.axios.get(`me/surveys/${item.id}`);
            const surveyData = await this.axios.get(
                `surveys/${item.survey_id}`
            );
            this.helpersStore.runSurvey(
                surveyData?.data.item,
                mySurveyData?.data?.item?.data,
                item.id,
                {
                    type: 'statement',
                    userName: '',
                },
                null,
                null,
                this.load
            );
        },
        rowClicked(item) {
            this.openSurvey(item);
        },
    },
};
</script>

<style>
.part-statuses-container {
    border: 1px solid var(--ui-general-outline-01);
    border-radius: 8px;

    max-width: 200px;
    margin: 8px;
}
.part-status {
    display: flex;
    justify-content: space-between;
}

.part-status-value {
    font-weight: bold;
}
</style>
