export const sr = {
    common: {
        ok: 'U redu',
        authorizationRequiredDescriptionTerminal:
            'Koristite svoj @:common.PIN_CODE_LABEL za pristup da biste autorizovali radnju.',
        countersValues: 'Vrednosti brojača',
        countableId: 'ID brojivog polja',
        defaultValuesForCategory: 'Podrazumevane vrednosti za kategoriju',
        check_user: 'Da li korisnik',
        check_terminal: 'Da li terminal',
        bannedEmployees: 'Zabranjeni zaposleni',
        banEmployee: 'Zabrani ulaz',
        banned: 'Zabranjen',
        employeeBans: 'Zabrane za zaposlene',
        commentAboutEmployee: 'Odnosi se na zaposlenog',
        comments: 'Komentari',
        visitLog: 'Istorija poseta',
        categoryStructure: 'Struktura kategorije',
        appVersionMismatch: 'Neispravna verzija aplikacije',
        appVersionMismatchMessage: 'Osvežite aplikaciju da ažurirate verziju.',
        PIN_CODE_LABEL: 'Pin kod',
        comment: 'Komentar',
        year: 'Godina',
        configuration: 'Konfiguracija',
        cancel: 'Otkaži',
        search: 'Pretraga',
        remove: 'Ukloni',
        confirmationDialogTitle:
            'Da li ste sigurni da želite da uklonite ovaj predmet?',
        selectDate: 'Izaberite datum',
        select: 'Izaberite',
        notFound: 'Nije pronađeno',
        equipmentPickerPlaceholder: 'Objekat opreme',
        searchPlaceholder: 'Pretraži',
        equipmentTypePickerPlaceholder: 'Tip opreme',
        equipmentTypePickerTitle: 'Izaberite tip opreme',
        emptyTableMessage: 'Nema podataka',
        flowPikcerTitle: 'Izaberite protok',
        selectIcon: 'Izaberite ikonicu',
        locationPickerTitle: 'Izaberite lokaciju',
        locationPlaceholder: 'Lokacija',
        noSublocations: 'Nema podlokacija',
        locationsTitle: 'Lokacije',
        edit: 'Uredi',
        save: 'Sačuvaj',
        added: 'Dodato',
        updated: 'Ažurirano',
        add: 'Dodaj',
        removed: 'Uklonjeno',
        reload: 'Ponovo učitaj',
        confirm: 'Potvrdi',
        rollBack: 'Vrati',
        images: 'Slike',
        documents: 'Dokumenti',
        download: 'Preuzmi',
        saved: 'Sačuvano',
        date: 'Datum',
        hour: 'Sat',
        preview: 'Pregled',
        data: 'Podaci',
        attributes: 'Atributi',
        checkList: 'Lista za proveru',
        name: 'Ime',
        type: 'Tip',
        status: 'Status',
        designation: 'Oznaka',
        symbol: 'Simbol',
        serialNumber: 'Serijski broj',
        registryNumber: 'Registarski broj',
        machineNumber: 'Broj mašine',
        udtNumber: 'UDT broj',
        manufacturedYear: 'Godina proizvodnje',
        manufacturer: 'Proizvođač',
        supplier: 'Dobavljač',
        mainSupplier: 'Glavni dobavljač',
        attribute: 'Atribut',
        nameRequired: 'Ime je obavezno',
        yearGreaterThan: 'Godina mora biti veća od',
        location: 'Lokacija',
        fieldRequired: 'Ovo polje je obavezno',
        description: 'Opis',
        minimumQuantity: 'Minimalna količina',
        optimalQuantity: 'Optimalna količina',
        priority: 'Prioritet',
        formatYYYY: 'Format YYYY',
        storageQuantity: 'Količina u skladištu',
        quantity: 'Količina',
        quantityGreaterThan: 'Količina mora biti veća od',
        machine: 'Mašina',
        tool: 'Alat',
        order: 'Narudžbina',
        required: 'Obavezno',
        dateFormat: 'Format datuma',
        selectDictionary: 'Odaberite rečnik',
        return: 'Vrati',
        parameters: 'Parametri',
        editCategory: 'Izmeni kategoriju',
        addCategory: 'Dodaj novu kategoriju',
        changeColor: 'Promeni boju',
        noColor: 'Bez boje',
        shift: 'Smena',
        start: 'Početak',
        end: 'Kraj',
        shiftSchema: 'Šema smene',
        shifts: 'Smena',
        dictionary: 'Rečnik',
        subEntries: 'Podstavke',
        key: 'Ključ',
        value: 'Vrednost',
        moveUp: 'Pomeri gore',
        moveDown: 'Pomeri dole',
        addLocation: 'Dodaj novu lokaciju',
        editLocation: 'Izmeni lokaciju',
        companiesDatabase: 'Baza podataka kompanija',
        flows: 'Tokovi',
        read: 'Čitaj',
        write: 'Piši',
        noImage: 'Nema slike',
        email: 'E-pošta',
        function: 'Funkcija',
        active: 'Aktivno',
        notActive: 'Nije aktivirano',
        disabled: 'Onemogućeno',
        entryNumber: 'Broj stavke',
        issuedDate: 'Datum izdavanja',
        expiresDate: 'Datum isteka',
        move: 'Pomeri',
        separateForm: 'Odvoji od',
        separate: 'Odvojiti',
        warehouse: 'Skladište',
        actions: 'Akcije',
        putInCurrentLocation: 'Stavite u trenutnu lokaciju',
        selectLocationFormRightMenu:
            'Odaberite lokaciju iz menija s desne strane',
        showUnassignedItems: 'Prikaži nepridružene stavke',
        separated: 'Odvojeno',
        moved: 'Pomereno',
        categories: 'Kategorije',
        welcome: 'Dobrodošli',
        loginToSignaloTpm: 'Prijavite se na Signalo TPM',
        login: 'Prijava',
        loginByEmailAndPassword: 'Prijava putem e-pošte i lozinke',
        loginByCode: 'Prijavite se putem @:common.PIN_CODE_LABEL',
        accessCode: 'Kod za pristup',
        enterEmail: 'Unesite e-poštu',
        enterPassword: 'Unesite lozinku',
        enterCode: 'Unesite @:common.PIN_CODE_LABEL',
        userNotFound: 'Korisnik nije pronađen',
        userNotActivated: 'Korisnik nije aktiviran',
        userDisabled: 'Korisnik je onemogućen',
        wrongPassword: 'Pogrešna lozinka',
        locations: 'Lokacije',
        pageNotFound: 'Stranica nije pronađena',
        group: 'Grupa',
        yes: 'Da',
        no: 'Ne',
        hourlyCost: 'Satišnji trošak',
        price: 'Cena',
        newEntry: 'Nova stavka',
        predictedFinishTime: 'Predviđeno vreme završetka',
        archive: 'Arhiva',
        costs: 'Troškovi',
        companyManagement: 'Upravljanje kompanijom',
        companyData: 'Podaci o kompaniji',
        supportedLanguages: 'Podržani jezici',
        en: 'Engleski',
        pl: 'Poljski',
        es: 'Španski',
        de: 'Nemački',
        it: 'Italijanski',
        fr: 'Francuski',
        ru: 'Ruski',
        uk: 'Ukrajinski',
        pt: 'Portugalski',
        tr: 'Turski',
        nl: 'Holandski',
        sr: 'Srpski',
        ar: 'Arapski',
        he: 'Hebrejski',
        zh: 'Kineski',
        defaultLanguage: 'Podrazumevani jezik',
        companyLogo: 'Logo kompanije',
        notificationsManagement: 'Upravljanje obaveštenjima',
        category: 'Kategorija',
        today: 'Danas',
        month: 'Mesec',
        week: 'Nedelja',
        day: 'Dan',
        close: 'Zatvori',
        show: 'Prikaži',
        rollBackFormalConfirmation: 'Potvrda formalnog vraćanja',
        signUp: 'Registruj se',
        nameSurname: 'Ime i prezime',
        phone: 'Broj telefona',
        password: 'Lozinka',
        password_confirmation: 'Potvrdi lozinku',
        unassignedItems: 'Nepridružene stavke',
        currency: 'Valuta',
        general: 'Opšte',
        timezone: 'Vremenska zona',
        users: 'Korisnici',
        groups: 'Grupe',
        productionLine: 'Linija za proizvodnju',
        part: 'Deo',
        fittingParts: 'Pribori',
        workSchema: 'Šema rada',
        workSchemas: 'Šeme rada',
        holiday: 'Praznik',
        changePassword: 'Promeni lozinku',
        showRest: 'Prikaži ostalo',
        pageNotPermitted: 'Stranica nije dozvoljena',
        waitingForActivation: 'Čeka se aktivacija',
        editGroup: 'Izmeni grupu',
        addGroup: 'Dodaj grupu',
        reportProblem: 'Prijavi problem',
        describeOccuredIssue: 'Opisite nastali problem',
        markAllAsRead: 'Označi sve kao pročitano',
        unitType: 'Tip jedinice',
        unit: 'Jedinica',
        unitPrice: 'Jedinična cena',
        notConfigured: 'Nije konfigurisano',
        rowsPerPage: 'Redova po stranici',
        rowsOfPage: 'od',
        true: 'Tačno',
        false: 'Netačno',
        export: 'Izvoz',
        chooseFileFormat: 'Odaberite format datoteke',
        exported: 'Izvezeno',
        terminals: 'Terminali',
        terminal: 'Terminal',
        selectEquipment: 'Odaberite opremu',
        futureIssues: 'Budući problemi',
        scheduledOn: 'Zakazano za',
        planned: 'Planirano',
        notPlanned: 'Nenajavljeno',
        showOpenIssues: 'Prikaži otvorene probleme',
        pin: 'PIN',
        assignedEquipment: 'Dodeljena oprema',
        terminalEquipment: 'Terminal oprema',
        detach: 'Odvojiti',
        attach: 'Prikvačiti',
        dashboardConfig: 'Konfiguracija kontrolne table',
        addColumn: 'Dodaj kolonu',
        addRow: 'Dodaj red',
        removeRow: 'Ukloni red',
        registerTerminal: 'Registruj terminal',
        issueGeneratesCost: 'Vreme neefikasnosti generiše troškove',
        selectColor: 'Odaberite boju',
        belowMinimum: 'Ispod minimuma',
        belowOptimum: 'Ispod optimalnog',
        synchronization: 'Sinhronizacija',
        synchronizationExport: 'Izvoz rezervnih delova',
        synchronizationImport: 'Uvoz rezervnih delova',
        mapping: 'Mapiranje',
        returnTo: 'Vrati se na',
        jobStatusses: 'Procesi',
        hidden: 'Skriveno',
        editEvent: 'Izmeni događaj',
        threeDays: '3 dana',
        addEvent: 'Dodaj događaj',
        plannedIssues: 'Planirani problemi',
        currentService: 'Trenutna usluga',
        searchLocation: 'Pronađi lokaciju',
        all: 'Sve',
        unfinished: 'Nedovršeno',
        numberOfIssues: 'Broj problema',
        unknown: 'Nepoznato',
        partEntry: 'Deo (stavka)',
        foundItems: 'Pronađene stavke',
        output: 'Izlaz',
        copied: 'Kopirano',
        link: 'Veza',
        authorizationRequired: 'Potrebna autorizacija',
        authorizationRequiredDescriptionUser:
            'Nažalost, nemate dovoljnu autorizaciju. Zatražite od ovlašćene osobe da odobri radnju.',
        showAll: 'Prikaži sve',
        showClassicView: 'Prikaži klasični prikaz',
        noIssuesInCategory: 'Nema problema u ovoj kategoriji.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Broj problema u ovoj kategoriji i podkategorijama.',
        numberOfIssuesInThisCategory: 'Broj problema u ovoj kategoriji.',
        minBarcodeLength: 'Minimalna dužina barkoda',
        currentPage: 'Trenutna stranica',
        allPages: 'Sve stranice',
        selectFormat: 'Odaberite format',
        provided_currency: 'Pružena valuta',
        provided_quantity: 'Pružena količina',
        provided_unit: 'Pružena jedinica',
        provided_unit_price: 'Pružena cena po jedinici',
        unit_price: 'Cena po jedinici',
        quantity_available: 'Dostupna količina',
        editBarcode: 'Izmeni barkod',
        crews: 'Ekipe',
        scheduleEventTypes: 'Tipovi planiranih događaja',
        crew: 'Ekipa',
        scheduleEvent: 'Planirani događaj',
        available: 'Dostupno',
        'App\\Models\\Crew': 'Ekipa',
        'App\\Models\\User': 'Korisnik',
        'App\\Models\\Equipment': 'Oprema',
        Crew: 'Ekipa',
        User: 'Korisnik',
        Equipment: 'Oprema',
        scheduleEvents: 'Planirani događaji',
        event: 'Događaj',
        externalSystems: 'Spoljni sistemi',
        user: 'Korisnik',
        availability: 'Dostupnost',
        inheritColorFromParentIfCustomNotSet:
            'Nasledi boju od roditelja ako nije prilagođena',
        pickFromAllParts: 'Izaberi iz svih delova',
        takeTool: 'Uzmi alat',
        returnTool: 'Vrati alat',
        personWhoTake: 'Osoba koja uzima',
        personWhoReturn: 'Osoba koja vraća',
        takenAt: 'Uzeto u',
        takenBy: 'Uzeto od',
        toolIssuedBy: 'Alat izdat od',
        commentTake: 'Komentar uzimanja',
        commentReturn: 'Komentar vraćanja',
        change: 'Promeni',
        onlyWithActiveRMIssues: 'Samo sa aktivnim RM zahtevima',
        externalSystem: 'Spoljni sistem',
        rejectIssue: 'Odbaci problem',
        withdrawIssueRejection: 'Povuci odbijanje problema',
        rejectionReason: 'Razlog odbijanja',
        rejected: 'Odbijeno',
        showCrewMembers: 'Prikaži članove ekipe',
        crewMembers: 'Članovi ekipe',
        helpGroup: 'Usluga',
        partsTakenByUser: 'Delovi uzeti od strane korisnika',
        pickParts: 'Izdaj delove',
        selectPersonWhoTakes: 'Odaberi osobu koja uzima',
        changePersonWhoTakes: 'Promeni osobu koja uzima',
        quantityToPick: 'Količina za izdavanje',
        pickLocation: 'Lokacija preuzimanja',
        selectPartsToPick: 'Izaberite delove za preuzimanje',
        partSelected: 'Izabrani deo',
        scanCodeOr: 'Skenirajte šifru ili',
        returnPart: 'Vrati deo',
        myTakenParts: 'Delovi koje sam preuzeo',
        takenFromLocation: 'Preuzeto sa lokacije',
        takenQuantity: 'Preuzeta količina',
        providedUnit: 'Ponuđena jedinica',
        providedQuantity: 'Ponuđena količina',
        selectFromStorage: 'Izaberite iz skladišta',
        selectFromMyPickedParts: 'Izaberite iz preuzetih delova',
        exportProcessInProgressMessage:
            'Proces izvoza podataka je upravo počeo, kada se završi, videt ćete odgovarajuću obaveštenje sa linkom do fajla.',
        yourDocumentIsReady: 'Vaš dokument je spreman',
        rotateScreen: 'Rotiraj ekran',
        addRule: 'Dodaj pravilo',
        editRule: 'Izmeni pravilo',
        summary: 'Sažetak',
        onlyAvailableEmployees: 'Prikaži samo dostupne zaposlene',
        calculated_unit_price: 'Preračunata jedinična cena',
        calculated_quantity: 'Preračunati iznos',
        next: 'Sledeći',
        maintenanceMode: 'Režim održavanja',
        applicationMayBeUnavailableForFewMinutes:
            'Aplikacija može biti nedostupna nekoliko minuta',
        appWillRefreshIn: 'Aplikacija će se ponovo učitati',
        landscape: 'Pejzaž',
        portrait: 'Portret',
        orientation: 'Orijentacija',
        format: 'Format',
        service: 'Služba',
        leader: 'Brigadisti',
        defaultView: 'Podrazumevani prikaz',
        view: 'Pogled',
        oneDay: '1 dana',
        object: 'Objekat',
        finished: 'Gotov',
        onlyNotFinished: 'Samo što nije završeno',
        generate: 'Generiši',
        error: 'Greška',
        cell: 'Ćelija',
        resolutionSummary: 'Kraj rezimea',
        rejected_at: 'Datum odbijanja',
        rejected_by: 'Odbijeno od',
        finishedByUser: 'Završen',
        formalConfirmByUser: 'Formalna potvrda od',
        formalConfirmAt: 'Datum zvanične potvrde',
        valueDeleted: '[Vrednost je obrisana]',
        hasNotPlannedIssue: 'Nije planirao probleme',
        showInStorage: 'Prikaži u skladištu',
        changeCode: 'Promenite @:common.PIN_CODE_LABEL',
        staffProfiles: 'Profili zaposlenih',
        profile: 'Profil',
        user_bug: 'Greška u aplikaciji',
        user_kaizen: 'Predlog promene',
        changeSuggestions: 'Promenite predloge',
        showOnlyCurrentlyAvailable: 'Prikaži samo trenutno dostupno',
        resetPassword: 'Podseti lozinku',
        forgotPassword: 'Zaboravili ste lozinku',
        resetPasswordMailSent:
            'Poslali smo vezu za postavljanje nove lozinke na navedenu adresu e-pošte',
        manyEventsThisTime: 'Mnogo događaja u ovo vreme',
        importScheduleCheckboxLabel:
            'Izbrišite zakazane događaje u izabranom mesecu za zaposlene navedene u datoteci',
        inheritedEvent: 'Nasleđeni događaj',
        inheritedModifiedEvent: 'Izmenjeni nasleđeni događaj',
        equipmentId: 'ID opreme',
        entryId: 'Entri ID',
        partId: 'Part ID',
        suggestionsEmail: 'Imejl na koji će se slati predlozi za promene',
        meters: 'Brojači',
        scanCardToAuth: 'Skenirajte karticu za autorizaciju',
        scanCard: 'Skenirajte karticu',
        cart: 'Kutija za razmenu',
        emptyCardMessage:
            'Počnite da skenirate proizvode ili ih izaberite sa liste da biste ih dodali u korpu.',
        terminalMode: 'Terminalni režim',
        askPermittedUserToConfirmPartsExchange:
            'Pitajte dozvoljenog korisnika da potvrdi zamenu delova',
        partsIssued: 'Delovi izdati',
        areYouSureAboutCancel: 'Da li ste sigurni da želite da otkažete?',
        areYouSureAboutRemoveFromCart:
            'Da li ste sigurni da želite da uklonite iz korpe?',
        standard: 'Standard',
        idNumber: 'Broj identifikacije',
        takeParts: 'Preuzmi delove',
        returnParts: 'Vrati delove',
        quantityToReturn: 'Količina za vraćanje',
        partsPendingApproval: 'Delovi na čekanju odobrenja',
        partsReturned: 'Delovi vraćeni',
        partsAddedToPendingApproval: 'Delovi dodati na čekanje odobrenja',
        approvePartsReturn: 'Odobri vraćanje delova',
        partReturnApproved: 'Vraćanje delova odobreno',
        partUsage: 'Upotreba delova',
        awaitsReturn: 'Čeka se povratak',
        entrySelection: 'Selekcija unosa',
        correctQuantity: 'Ispravite količinu',
        quantityCorrection: 'Korekcija količine',
        entrySelectionAsc: 'Selekcija unosa (rastuće)',
        entrySelectionDesc: 'Selekcija unosa (opadajuće)',
        metersSettings: 'Podešavanja brojača',
        countableFields: 'Brojiva polja',
        equipmentTypes: 'Tipovi opreme',
        fieldName: 'Ime polja',
        countableField: 'Brojivo polje',
        partsStatusTaken: 'Preuzeto',
        rejectPartsReturn: 'Odbij vraćanje delova',
        areYouSureAboutRejectPartsReturn:
            'Da li ste sigurni da želite da odbijete vraćanje delova?',
        partReturnRejected: 'Vraćanje delova odbijeno',
        counterFields: 'Polja brojača',
        counterName: 'Ime brojača',
        counterFieldName: 'Ime polja brojača',
        counterId: 'ID brojača',
        counterUUID: 'UUID brojača',
        counterPickerPlaceholder: 'Odaberite brojač',
        pickFromPartsList: 'Izaberite iz liste delova',
        changeLocation: 'Promenite lokaciju',
        isPartStorageRoot: 'Korijensko skladište delova',
        areYouSureAboutArchiveIssue:
            'Da li ste sigurni da želite da arhivirate izdavanje?',
        ganttChart: 'Gantogram',
        table: 'Tabela',
        fittingEquipment: 'Oprema za montažu',
        notAssigned: 'Nije dodeljeno',
        counters: 'Brojači',
        machineHourlyCostNotProvided: 'Cena mašine po satu nije navedena',
        eventTypesMightBeDefinedHere:
            'Tipovi događaja mogu biti definisani ovde',
        toolsTakenByUser: 'Alati uzeti od strane korisnika',
        predictedReturnDate: 'Predviđeni datum povratka',
        estimatedTimeOfUsingToolInHours:
            'Procenjeno vreme korišćenja alata (u satima)',
        showOnlyAvailable: 'Prikaži samo dostupno',
        toolTakeLog: 'Dnevnik uzimanja alata',
        takeDate: 'Datum uzimanja',
        returnDate: 'Datum povratka',
        personWhoAcceptReturn: 'Osoba koja prihvata povrat',
        statisticStrategy: 'Strategija pristupačnosti',
        EquipmentPlannedStrategy: 'Prema planu rada',
        EquipmentConstantAvailabilityStrategy: 'Uvek na raspolaganju',
        actionAfterIssueFinish: 'Akcija nakon završetka izdavanja',
        enabled: 'Omogućeno',
        categoryOfNewIssue: 'Kategorija novog izdavanja',
        workNotStarted: 'Rad nije započet',
        colorInherited: 'Nasleđena boja',
        abcClassification: 'ABC klasifikacija',
        xyzClassification: 'XYZ klasifikacija',
        classification: 'Klasifikacija',
        filters: 'Filteri',
        wipeFilters: 'Obriši filtere',
        takePartsFromUser: 'Uzmi delove od korisnika',
        selectPersonWhoReturns: 'Izaberite osobu koja vraća delove',
        changePersonWhoReturns: 'Promenite osobu koja vraća delove',
        returnLocation: 'Lokacija povratka',
        returnQuantity: 'Količina povratka',
        globalSettings: 'Globalna podešavanja',
        delimiter: 'Delimiter',
        fieldRules: 'Pravila polja',
        fieldValue: 'Vrednost polja',
        user_email_regex: 'E-mail korisnika',
        barcodeLength: 'Dužina barkoda',
        partLine: 'Linija delova',
        partFamily: 'Porodica delova',
        partBatch: 'Serija delova',
        testValueValid: 'Вредност теста је важећа',
        testValueNotValid: 'Вредност теста није важећа',
        canBePicked: 'Može se birati',
        issue_create_photos: 'Dodavanje fotografija u novi broj',
        issue_create_documents: 'Dodavanje dokumenata novom izdanju',
        propagateValues: 'Propagacija vrednosti',
        archived: 'arhivirano',
        escalations_tab: 'Eskalacije',
        minute: 'Minuta',
        days: 'Dani',
        hours: 'Sati',
        minutes: 'Minuta',
        warnDays: 'Upozorenje pre isteka (dana)',
        licenseType: 'Tip licence',
        licenseGroup: 'Grupa licenci',
        attachments: 'Prilozi',
        attachment: 'Prilog',
        revisionChanges: 'Uvedene promene',
        document: 'Dokument',
        noInstructionSelected: 'Nije izabrana instrukcija',
        instructionGroups: 'Grupe instrukcija',
        instructionGroup: 'Grupa za instrukcije',
        addOption: 'Dodajte opciju',
        ordinal: 'Redna vrednost',
        parameterName: 'Naziv parametra',
        fontColor: 'Boja fonta',
        backgroundColor: 'Boja pozadine',
        columns: 'Broj kolona',
        colspan: 'Širina (kolone)',
        products: 'Proizvodi',
        controlsTemplates: 'Kontrolni šabloni',
        controlType: 'Tip kontrole',
        controls: 'Inspekcije',
        controlTemplate: 'Kontrolni šablon',
        product: 'Proizvod',
        checklistsAttachedToControlTemplate:
            'Kontrolne liste dodeljene inspekcijama',
        removeChecklistFromControlTitle:
            'Da li ste sigurni da želite da uklonite ovu listu iz svoje kontrole?',
        attachChecklistToControl: 'Dodelite listu za kontrolu',
        pickedItem: 'Izabrana stavka',
        pick: 'Izaberi',
        fillControl: 'Dovršite proveru',
        back: 'povratak',
        control: 'kontrolu',
        duplicate: 'Duplikat',
        isStorageRoot: 'Glavna lokacija magacina',
        manageStorageAccess: 'Upravljajte pristupom skladištima',
        manageVisibleColumns: 'Podesite vidljivost kolone',
        columnName: 'Naziv kolone',
        visible: 'Vidljivo',
        entriesLocation: 'lokacija unosa',
        details: 'Detalji',
        otherData: 'Ostali podaci',
        moveAllEntriesFromCurrentLocation: 'Premesti sve unose sa ove lokacije',
        searchInCurrentLocation: 'Pretražite na svojoj trenutnoj lokaciji',
        transition: 'Prelaz',
        notification: 'Obaveštenje',
        emails: 'Imejl adrese',
        phones: 'Brojevi telefona',
        addAction: 'Dodajte akciju',
        message: 'Poruka',
        application: 'Aplikacija',
        always: 'Uvek',
        sms: 'SMS',
        loginTo: 'Prijavite se na',
        appName: 'Signalo TPM',
        index: 'Indeks',
        showCodeOwnerNameDuringParing:
            'Prikažite ime vlasnika @:common.PIN_CODE_LABEL prilikom uparivanja sa karticom/oznakom',
        showUsernameInCart: 'Prikaži korisničko ime u korpi',
        sampleButtonPlaceholder: 'Dugme uzorka',
        preferences: 'Preferences',
        buttonsSize: 'Veličina dugmeta',
        erase: 'obrisati',
        selectDictionaryValue: 'izaberite vrednost rečnika',
        conditions: 'Uslovi',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Zahtevajte potvrdu ili odbijanje prilikom vraćanja preuzetih delova',
        issueCreated: 'Karta je napravljena',
        allowedIssuesCategories: 'Kategorije dozvoljenih pitanja',
        hiddenFields: 'Skrivena polja',
        defaultValues: 'Default vrednosti',
        paddingBottom: 'Prazan prostor na dnu',
        allVisibleFieldsRequired: 'Zahtevajte da se popune sva vidljiva polja',
        url: 'URL-адрес',
        kind: 'Vrsta',
        forkfleetConfigId: 'Konfiguracija Forkfleet',
        create: 'Kreiraj',
        terminate: 'prekinuti',
        parent: 'Roditelj',
        phoneNumbers: 'Brojevi telefona',
        storageDefaults: 'Podrazumevane vrednosti skladištenja',
        clientSignatureEnabled: 'Potpis osobe koja prikuplja',
        warehousemanSignatureEnabled: 'Potpis magacionera',
        storage_client_signature_enabled:
            'Potpis lica koje uzima artikle iz magacina',
        storage_client_signature_required:
            'Zahtevati potpis osobe koja uzima artikle iz magacina',
        storage_warehouseman_signature_enabled:
            'Potpis lica koje pušta artikle iz magacina',
        storage_warehouseman_signature_required:
            'Zahtevati potpis osobe koja pušta artikle iz magacina',
        clientReturnSignatureEnabled: 'Potpis osobe koja se vraća',
        warehousemanReturnSignatureEnabled:
            'Potpis osobe koja prihvata povraćaj',
        storage_client_return_signature_enabled:
            'Potpis osobe koja vraća predmete',
        storage_client_return_signature_required:
            'Zahtevajte potpis od osobe koja vraća artikle',
        storage_warehouseman_return_signature_enabled:
            'Potpis osobe koja prihvata vraćanje predmeta',
        storage_warehouseman_return_signature_required:
            'Zahtevajte potpis osobe koja prihvata vraćanje predmeta',
        channels: 'kanaly',
        manageState: 'Upravljajte stanjem',
        assignWorker: 'Odredite zaposlenog',
        mergeEntriesOfTheSameLocation: 'Spoji unose sa iste lokacije',
        permission: 'Dozvola',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'Da li ste sigurni da želite da spojite sve unose na ovoj lokaciji?',
        manageFilters: 'Upravljajte filterima',
        responsible_assign_creator_when_no_rule:
            '[Izveštaji] – Dodelite kreatora kao odgovornog ako se pravilo ne podudara',
        recreateIssue: 'Поново креирајте карту',
        addNewDictionary: 'Dodajte novi rečnik',
        label: 'Etiketa',
        issuesWithRelatedCategories: 'Uključite potkategorije',
        issuesLimit: 'Ograničenje pitanja',
        issuesLimits: 'Pitanja – granice',
        issueDefaults: 'Izveštaji - podrazumevane vrednosti',
        flatCategoryPickerLabel: 'Struktura ravne kategorije',
        statementsTemplates: 'Šabloni izjava',
        statementTemplate: 'Šablon izjave',
        content: 'Sadržaj',
        readonly: 'Samo za čitanje',
        defaultValue: 'Zadana vrijednost',
        fillParametersToChangeState:
            'Popunite vrednosti za parametre ispod da biste promenili status',
        formatPattern: 'obrazac formata',
        currentVersion: 'Trenutna verzija',
        countableIncFields: 'Konfiguracija numerisanja dokumenata',
        mainShift: 'Glavna smena',
        singleEvent: 'Jedan ulazak',
        seriesOfEvents: 'Serija unosa',
        omitWeekends: 'Izbegavajte vikende',
        allEntriesSaved: 'Svi unosi su dodati',
        surveyResults: 'Rezultati ankete',
        qrActionsSettings: 'Podešavanja akcije QR koda',
        pendingStatements: 'Deklaracije za potpisivanje',
        statementsResults: 'Izjave',
        statements: 'Izjave',
        statement: 'Izjava',
        userCanChangeContent: 'Korisnik može da promeni sadržaj',
        statementsEvents: 'Konfiguracija događaja – izjave',
        helpdesk: 'šalter za informacije',
        terminalId: 'Terminal Id',
        operatorId: 'Operator Id',
        newConversation: 'Nova tema',
        formula: 'Formula',
        fill: 'kompletan',
        webMessage: 'Veb poruka',
        noResponsibleUser: 'Nema odgovornog korisnika',
        filledAtDate: 'Datum završetka',
        filledBy: 'Završen',
        parts_quantity_alert_rule:
            '[Količina delova i materijala] - Upozorenje',
        employeeNumber: 'Broj radnika',
        groupsAssignedToCategory: 'Grupe dodeljene kategorijama',
        number: 'Broj',
        customers: 'Kupci',
        customer: 'Customer',
        projects: 'Projekti',
        project: 'Projekat',
        report: 'Izveštaj',
        reportGenerationInProgress: 'Izveštaj se generiše',
        storageStatementGroup: 'Grupa magacinskih deklaracija',
        simplifiedHandling: 'Pojednostavljeno rukovanje obaveštenjima',
        selectWorkArea: 'Izaberite svoju radnu oblast',
        workAreaSelection: 'Izbor radnog područja',
        access: 'Pristup',
        restrictAccess: 'Ograničite pristup',
        anchor: 'Sidrenje',
        issueCreator: 'Osoba koja kreira izveštaj',
        hideSideMenu: 'Sakrij bočni meni',
        appendGroups: 'Grupe za dodavanje',
        revokeGroups: 'Grupe za uklanjanje',
        override: 'Overvrite',
        restrictGroupAccess: 'Ograničite pristup grupama',
        flag: 'Zastava',
        flags: 'Zastave',
        manageFlags: 'Upravljajte zastavama',
        icon: 'Ikona',
        appendFlags: 'Zastavice za dodavanje',
        revokeFlags: 'Zastave koje treba ukloniti',
        noFlagsConfigured: 'Nema definisanih zastavica',
        checkIsTerminal: 'Provera terminala',
        noConditionsDefined: 'Nema definisanih uslova',
        checkIsResponsible: 'Provera odgovornosti',
        createdOn: 'Saopšteno iz',
        replaceTimespan: 'Rok upotrebe od trenutka izdavanja',
        months: 'meseci',
        handoversTemplates: 'Šabloni primopredaje',
        handoverTemplate: 'Šablon za primopredaju',
        employeeFunction: 'Funkcija zaposlenih',
        takeHandover: 'Uzmi primopredaju',
        handovers: 'Primopredaje',
        expiringHandovers: 'Predmeti kojima se bliži rok trajanja',
        years: 'Godine',
        taken_parts_expiry_warning:
            'Upozorenje pre isteka roka trajanja preuzetih stavki',
        andonView: 'Andon viev',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'drugo',
        tags: 'Kartice / Tags',
        tag: 'Kartica / Tag',
        userPinCode: '@:common.PIN_CODE_LABEL korisnika kartice',
        emailConfirmation: 'Potvrda adrese e-pošte',
        from: 'Od',
        to: 'Do',
        handoversHistory: 'Istorija primopredaje',
        partUsagesHistory: 'Istorija upotrebe delova',
        signaturePinTagConfirmationText1:
            'Potpišite se @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'ili skenirajte svoju karticu.',
        signedWithPinCode: 'Potpisano @:common.PIN_CODE_LABEL',
        signedWithTAG: 'Potpisano karticom/tagom',
        test_mode: 'Test Mode',
        confirmWorkFinish: 'Potvrdite završetak posla',
        workStarted: 'Početak rada',
        workFinished: 'Rad završi',
        workTimeRegister: 'Registar radnog vremena',
        measureTypes: 'Vrste merenja',
        measureType: 'Vrsta merenja',
        measurable: 'Merljiv indikator',
        measurables: 'Merljivi indikatori',
        measurements: 'Measurement',
        measurement: 'Measurement',
        measuredAt: 'Datum merenja',
        employee: 'Zaposleni',
        pin_code_label: 'PIN ime',
        orders: 'Naređenja',
        productionPlan: 'Plan proizvodnje',
        productionPlans: 'Planovi proizvodnje',
        taktTime: 'Takt vreme',
        target: 'Target',
        seconds: 'Sekunde',
        inheritValuesFromTenantSettings:
            'Nasledite podrazumevane vrednosti iz globalnih podešavanja',
        matrices: 'Matrice',
        manageMatrice: 'Manage Matrik',
        deletionOfMatrixColumnWarnMessage:
            'Brisanjem kolone brišete vrednosti.',
        removeColumn: 'Izbrišite kolonu',
        editColumn: 'Uredi kolonu',
        column: 'Kolona',
        row: 'Red',
        evaluated: 'procenjeno',
        of: 'of',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - Automatsko generisanje',
        or: 'ili',
        rename: 'Preimenuj',
        check_only_on_transition: 'Proverite samo kada se status promeni',
        license: 'Ovlašćenja',
        confirmWorkStart: 'Potvrdite početak rada',
        workStartAt: 'Počinjete sa radom u',
        workFinishAt: 'Završite svoj posao o',
        workStartedAt: 'Početak',
        workTimeMessage: 'Prezaposlio si se',
        haveANiceDay: 'Želim vam prijatan dan!',
        farewellText: 'Tekst na poslednjem ekranu',
        namedayLabel: 'Slave imendan',
        sportEventNearby: 'Sledeći sportski događaj',
        autoTranslate: 'Automatski prevod',
        auto_translation: 'Automatski prevod',
        awaitingAutoTranslation: 'Čeka se automatski prevod',
        inheritAllDefaultValues:
            'Nasledite sve podrazumevane vrednosti iz globalnih podešavanja',
        escalations: 'Eskalacije',
        escalation: 'Eskalacija',
        notTaken: 'Nije počeo',
        notFinished: 'Nedovršeno',
        issueLastChange: 'Poslednja promena',
        mode: 'Režim',
        repeatLastEscalation: 'Ponovite poslednju vrednost eskalacije',
        manageActions: 'Upravljajte svojim radnjama',
        productionLines: 'Proizvodne linije',
        productionTerminal: 'proizvodni terminal',
        currently: 'Trenutno',
        currentPlan: 'Trenutni plan',
        currentlyInProduction: 'Trenutno proizveden',
        setProduct: 'Postavi proizvod',
        second: 'sekunde',
        work: 'posao',
        chartConfiguration: 'Konfiguracija grafikona',
        revisionsToConsider: 'Revizije za razmatranje',
        allInstructions: 'Sva uputstva',
        decider: 'Determiner',
        considerRevision: 'Razmislite o reviziji',
        and: 'i',
        acceptInstruction: 'Prihvatite uputstva',
        accept: 'prihvati',
        instructionsToAccept: 'Uputstva za čitanje',
        open: 'otvoreno',
        assignedAt: 'Datum zadatka',
        confirmationAt: 'Datum potvrde',
        mainInstruction: 'Podrazumevana instrukcija',
        setAsDefault: 'Fabrički podešen',
        instructionsFlows: 'Uputstva - tokovi',
        newRevision: 'Nova revizija',
        playlist: 'Lista video snimaka',
        selectVideo: 'Izaberite film',
        selectVideos: 'Izaberite filmove',
        uploadNewVideo: 'Otpremite novi video',
        upload_date: 'Datum dodavanja',
        thumbnail: 'sličica',
        duration: 'Trajanje',
        uploadFromDrive: 'Otpremite sa diska',
        selectAssets: 'Izaberite materijale',
        assets: 'Materijali',
        video: 'Film',
        doYouWantToAddNewMarker: 'Da li želite da dodate novu oznaku ovde?',
        areYouSureAboutRemoveMarker:
            'Da li ste sigurni da želite da uklonite ovu oznaku?',
        plants: 'Fabrika postrojenja',
        plant: 'Fabrika',
        buildings: 'Zgrade',
        building: 'Zgrada',
        floors: 'Podovi',
        floor: 'Pod',
        map: 'Mapa',
        areYouSureAboutMoveMarker:
            'Da li ste sigurni da želite da premestite ovaj marker?',
        marker: 'Marker',
        addNewMarkerTooltipMessage:
            'Da biste dodali novi marker, kliknite desnim tasterom miša na mapu.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Nelinearne veštineи',
        skillLevelConfig: 'Nivoi veština',
        indirectSkillGroups: 'Grupe nelinearnih veština',
        approved: 'Potvrđeno',
        approvedBy: 'Odobravač',
        skillLevel: 'Nivo veština',
        requiredSkillLevel: 'Potreban nivo veština',
        step: 'Korak',
        image: 'Photo',
        skills: 'Veštine',
        visits: 'Posete',
        responsiblePerson: 'Odgovorno lice',
        company: 'Kompanija',
        contactData: 'Kontakt detalji',
        visit: 'Poseta',
        inProgress: 'U toku',
        gatehouse: 'kapija',
        confirmEnter: 'Potvrdi unos',
        confirmExit: 'Potvrdi izlaz',
        enter: 'Unos',
        exit: 'Izlaz',
        entryGuard: 'Osoba koja pušta unutra',
        exitGuard: 'Osoba koja pušta',
        loginAsThisUser: 'Prijavite se kao ovaj korisnik',
        minBrowserVersion: 'Potrebna je minimalna verzija pretraživača',
        approve: 'Potvrdi',
        cancelApproval: 'Otkaži urezivanje',
        workPermit: 'Radna dozvola',
        workPermits: 'Radne dozvole',
        visit_summary: 'Rezime posete',
        createVisit: 'Napravite posetu na osnovu dozvole',
        employeesAtThePlant: 'Radnici u fabrici',
        purpose: 'Target',
        allowedAreas: 'Dozvoljene površine',
        switchEmployees: 'Zamenite zaposlenog',
        localIssues: 'Lokalno izveštavanje',
        cmmsCategoriesSync: 'Sinhronizacija CMMS kategorija',
        terminalsGroups: 'Terminalne grupe',
        isTree: 'Drvo',
        isReporter: 'Da li je reporter',
        isAnchored: 'Je usidren',
        closable: 'Karte za zatvaranje',
        areYouSureAboutCloseIssue:
            'Da li ste sigurni da želite da zatvorite kartu?',
        simplifiedIssuesRefreshLabel: 'Vreme osvežavanja liste - sekunde',
        reloadApp: 'Ponovo učitaj',
        hideWorkAreasButton: 'Sakrij dugme za izbor radne oblasti',
        hideClassicViewButton: 'Sakrij dugme za klasični prikaz',
        hideAddIssueButton: 'Sakrij dugme za dodavanje novog problema',
        filesManager: 'Menadžer fajlova',
        addFolder: 'Dodaj folder',
        upload: 'Otpremi',
        noFolders: 'Nema foldera',
        noFiles: 'Nema fajlova',
        folders: 'Folderi',
        folder: 'Folder',
        files: 'Fajlovi',
        file: 'Fajl',
        removeFolderFilesQuestion: 'Šta uraditi sa fajlovima u ovom folderu?',
        targetFolder: 'Ciljani folder',
        addInstruction: 'Dodaj uputstvo',
        goToFolder: 'Idi u folder',
        defaultProdLine: 'Podrazumevana proizvodna linija',
        pin_code_field_type: 'Tip PIN koda',
        instructionsAccess: 'Dozvole',
        noSections: 'Nema sekcija',
        requireReadingInstruction: 'Zahtevaj čitanje uputstva',
        hide_parameter_types: 'Ne prikazuj tipove parametara',
        unknownVersion: 'Nepoznato',
        displayInstructionEveryTime: 'Prikazuj uputstvo svaki put',
        requireSigningInstructionEveryTime:
            'Zahtevaj potpisivanje uputstva svaki put',
        signedInstructions: 'Potpisana uputstva',
        signedAt: 'Datum potpisivanja',
        signatures: 'Potpisi',
        signature: 'Potpis',
        systemGroup: 'Sistem grupa',
        pickable: 'Izborni',
        leaf: 'Izabrani element',
        path: 'Putanja',
        displayMode: 'Način prikaza',
        chart: 'Grafikon',
        preSignedStatement: 'Deklaracija potpisana na papiru (potpuna)',
        myOpenIssues: 'Moji otvoreni problemi',
        myAssignedIssues: 'Problemi dodeljeni meni',
        displayParametersOnMainScreen:
            'Pregledajte parametre na glavnom ekranu',
        lockPin: 'Закључај промену ПИН-а',
        pinLocked: 'ПИН закључан',
        sortChange: 'Редослед приказа',
        asc: 'Растуће',
        desc: 'Опадајуће',
        alphanumeric: 'Алфанумерички',
        numeric: 'Нумерички',
        modificationDate: 'Датум измене',
        fromNewest: 'Od najnovijih',
        fromOldest: 'Do najstarijeg',
        negation: 'Negacija',
        sortOrderService: 'Usluga',
        newIssueIgnoreFilters:
            'Zanemarite primenjene filtere kada kreirate novu kartu',
        refreshInterval: 'Vreme osvežavanja liste',
        multiline: 'Mnogo pesama',
        expanded: 'Developed',
        quizzes: 'Kvizovi',
        courses: 'Kursevi',
        course: 'Kurs',
        quiz: 'Kviz',
        maxPoints: 'Maksimalni broj poena',
        minPointsToPass: 'Minimalni broj poena za prolaz',
        questionFailQuiz: 'Nepolaganje pitanja diskvalifikuje ceo kviz',
        points: 'Poeni',
        currentVersionPreview: 'Pregled trenutne verzije',
        docs_sidebar_hidden_by_default:
            'Sakrij bočnu traku u dokumentima po podrazumevanim postavkama',
        allCategories: 'Sve kategorije',
        test: 'Test',
        tests: 'Testovi',
        copy: 'Kopija',
        trusted_device_code_login_users_limit: "Brza prijava - broj prikazanih korisnika"
    },
    placeholders: {
        part: 'Odaberite deo',
        nameSurname: 'Ime i prezime',
        phone: 'Broj telefona',
        password: 'Lozinka',
        password_confirmation: 'Potvrdite lozinku',
        email: 'E-mail',
    },
    auth: {
        notYourAccountQuestion: 'Nije vaš nalog?',
    },
    signup: {
        registered: 'Uspešno!',
        emailConfirmed: 'Imejl je potvrđen.',
        registered_info: 'Zamolite svog nadređenog da aktivira vaš nalog',
        confirmEmailAddress:
            'Vaš nalog je kreiran, kliknite na vezu u e-poruci da potvrdite svoju adresu.',
        tokenNotFoundError:
            'E-pošta je već potvrđena ili je veza za potvrdu nevažeća.',
    },
    paramTypes: {
        text: 'Tekst',
        note: 'Napomena',
        integer: 'Ceo broj',
        decimal: 'Decimalni broj',
        date: 'Datum',
        time: 'Vreme',
        datetime: 'Datum i vreme',
        color: 'Boja',
        icon: 'Ikona',
        boolean: 'Ne / Da',
        dictionary: 'Rečnik',
        user: 'Korisnik',
        radioGroup: 'Jedan izbor',
        checkboxGroup: 'Više izbora',
        signature: 'Potpis',
        number: 'Broj',
        rating: 'Ocena',
        document: 'Dokument',
        image: 'Slika',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Ime',
        path: 'Putanja do datoteke',
        category: 'Kategorija',
        equipment: 'Oprema',
        criticality: 'Kritičnost',
        status: 'Status',
        created: 'Kreirano',
        createdBy: 'Kreirano od',
        fp: 'FP',
        type: 'Tip',
        designation: 'Oznaka',
        symbol: 'Simbol',
        serialNumber: 'Serijski broj',
        registryNumber: 'Registarski broj',
        udtNumber: 'UDT broj',
        manufacturedYear: 'Godina proizvodnje',
        actions: 'Akcije',
        title: 'Naslov',
        data: 'Datum',
        archived: 'Arhivirano',
        manufacturerSymbol: 'Simbol proizvođača',
        min: 'Min',
        opt: 'Opt',
        quantity: 'Količina',
        priority: 'Prioritet',
        price: 'Cena',
        kind: 'Vrsta',
        description: 'Opis',
        default: 'Podrazumevano',
        warning: 'Upozorenje',
        problem: 'Problem',
        fixing: 'Решавање',
        changeOrder: 'Promena naloga',
        required: 'Potrebno',
        icon: 'Ikona',
        color: 'Boja',
        subCategories: 'Podkategorije',
        barCode: 'Bar kod',
        subLocations: 'Podlokacije',
        categories: 'Kategorije',
        image: 'Slika',
        nameSurname: 'Ime i prezime',
        email: 'E-mail',
        phone: 'Broj telefona',
        function: 'Funkcija',
        department: 'Odeljenje',
        accountStatus: 'Status naloga',
        shift: 'Smena',
        licenseNumber: 'Broj licence',
        issuedDate: 'Datum izdavanja',
        expiresDate: 'Datum isteka',
        entries: 'Unosi',
        entryNumber: 'Broj unosa',
        buyPrice: 'Cena kupovine',
        currentLocationQuantity: 'Količina na trenutnoj lokaciji',
        group: 'Grupa',
        string: 'Tekst',
        integer: 'Ceo broj',
        toolsGroup: 'Grupa alata',
        manufacturer: 'Proizvođač',
        shortName: 'Kratko ime',
        locationName: 'Ime lokacije',
        subLocationsQuantity: 'Količina na podlokacijama',
        isWorkingOnIssue: 'Radi na problemu',
        openIssues: 'Otvoreni problemi',
        notificationDate: 'Datum obaveštenja',
        titleAndDescription: 'Naslov i opis',
        read: 'Pročitano',
        created_at: 'Kreirano',
        received_at: 'Primljeno',
        started_at: 'Uzet',
        arrived_at: 'Stigao',
        finished_at: 'Završen',
        country: 'Zemlja',
        city: 'Grad',
        progress: 'Napredak',
        deleted_at: 'Izbriši datum',
        about_issue: 'Odnosi se na obaveštenje.',
        action: 'Objavi',
    },
    searchConfig: {
        id: 'ID',
        name: 'Ime',
        shortName: 'Kratko ime',
        country: 'Država',
        email: 'Email',
        group: 'Grupa',
        planned: 'Planirano',
        category: 'Kategorija',
        object: 'Objekat',
        criticality: 'Kritičnost',
        fp: 'FP',
        type: 'Tip',
        designation: 'Naziv',
        symbol: 'Simbol',
        serialNumber: 'Serijski broj',
        registryNumber: 'Registarski broj',
        udtNumber: 'UDT broj',
        manufacturedYear: 'Godina proizvodnje',
        manufacturerSymbol: 'Simbol proizvođača',
        priority: 'Prioritet',
        quantity: 'Količina',
        buyPrice: 'Cena kupovine',
        kind: 'Vrsta',
        toolsGroup: 'Grupa alata',
        function: 'Funkcija',
        department: 'Odeljenje',
        status: 'Status',
        nameSurname: 'Ime i prezime',
        phone: 'Telefon',
        barCode: 'Bar kod',
        color: 'Boja',
        icon: 'Ikona',
        machine: 'Mašina',
        tool: 'Alat',
        urgentAndOutdated: 'Hitno i zastarelo',
        urgent: 'Hitno',
        expired: 'Isteklo',
        manufacturer: 'Proizvođač',
    },
    errors: {
        uploadError: 'Greška pri otpremanju',
        finishedSuccessfully: 'Uspešno završeno',
        partsAvailabilityError:
            'Dostupnost nekih izabranih elemenata se promenila. Izmenite sadržaj svoje kutije za razmenu i pokušajte ponovo.',
        noFlagsConfigured: 'Zastavice nisu konfigurisane',
        partMustHaveEntriesToCorrectQuantity:
            'Deo mora imati unose za ispravku količine.',
        error: 'Desila se greška',
        wrongEmail: 'Neispravna e-mail adresa',
        wrongPasswordLength: 'Lozinka mora imati najmanje 8 karaktera.',
        wrongCodeLength:
            '@:common.PIN_CODE_LABEL mora imati najmanje 5 karaktera.',
        passwordsNotMatch: 'Lozinke se ne poklapaju',
        codesNotMatch: '@:common.PIN_CODE_LABEL se ne poklapaju',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL je već u upotrebi',
        expiresDateLowerThanIssued:
            'Datum isteka ne može biti manji od datuma izdavanja.',
        cannotMoveToTheSameLocation: 'Ne može se premestiti na isto mesto.',
        locationRequired: 'Lokacija je obavezna.',
        putQuantity: 'Unesite količinu za odvajanje.',
        valueGreaterThanQuantity: 'Vrednost je veća od ukupne količine.',
        wholeCannotBeDistinguished: 'Ceo ne može biti razdvojen.',
        yearMustBeGreaterThan: 'Godina mora biti veća od ',
        passwordConfirmationNotMatch: 'Lozinke se ne poklapaju',
        mustBeInteger: 'Vrednost mora biti celi broj',
        valueTooLong: 'Vrednost je prekomerna',
        wrongPhoneNumber: 'Pogrešan broj telefona',
        phoneNumberAlreadyTaken: 'Broj telefona je već zauzet',
        emailAlreadyTaken: 'E-mail adresa je već zauzeta',
        notFound: 'Nije pronađeno',
        sessionExpired: 'Sesija je istekla',
        tenantNotFound:
            'Navedena konfiguracija ne postoji. Proverite adresu sajta i pokušajte ponovo.',
        selectEquipmentTypeToConfigureParameters:
            'Odaberite tip opreme za konfigurisanje parametara.',
        noParametersToConfigure:
            'Odabrani tip opreme nema parametre za konfigurisanje.',
        terminalAlreadyRegistered: 'Terminal je već registrovan.',
        invalidPin: 'Neispravan @:common.PIN_CODE_LABEL',
        eventCollision:
            'Vreme događaja koje želite da dodate se preklapa sa vremenom drugog događaja.',
        mustStartWithSlash: 'Mora početi sa /',
        mustBeBetween: 'Vrednost mora biti između:',
        minLength: 'Minimalna dužina: ',
        maxLength: 'Maksimalna dužina: ',
        invalidInputForCodeType: 'Neispravan unos za odabrani tip koda',
        invalidCodeOrNoPermission:
            '@:common.PIN_CODE_LABEL nije validan ili nemate dovoljno dozvola za izvršavanje ovog radnje.',
        endDateLowerThanStart:
            'Krajnji datum ne može biti manji od početnog datuma',
        iconAlreadyTaken: 'Ikona je već zauzeta',
        notEnoughQuantityInStorage: 'Nedovoljna količina u skladištu.',
        valueTooLow: 'Vrednost je preniska',
        valueTooHigh: 'Vrednost je previsoka',
        maxAvailableQuantity: 'Maksimalna količina',
        someFilesRejectedDueToSize:
            'Neke datoteke su odbijene zbog veličine. Maksimalna veličina jedne datoteke:',
        cameraDeviceNotFound: 'Kamera nije otkrivena',
        cameraPermissionDenied:
            'Pristup kameri uređaja je blokiran u podešavanjima sajta, resetujte podešavanja sajta i dozvolite korišćenje kamere.',
        passwordResetLinkExpired: 'Veza za resetovanje lozinke je istekla',
        noInternetConnection: 'Nema internet veze',
        minimum: 'Minimalna vrednost: ',
        mustBeGreaterThan: 'Vrednost mora biti veća od ',
        noEventTypesDefined: 'Nema definisanih tipova događaja',
        regexPatternDelimiterError:
            'Razdjelnik mora biti izostavljen znakom \\, npr.: \\/',
        emailNotMatchPatternDefiniedInTenant:
            'E-mail adresa ne odgovara šablonu definisanom u konfiguraciji',
        max: 'Maksimalna vrednost',
        min: 'Minimalna vrednost',
        allLanguagesRequired: 'Popunite vrednost na svim jezicima',
        fileTooBigToOpenWillBeDownloaded:
            'Datoteka je prevelika za otvaranje, datoteka će biti preuzeta na vaš uređaj.',
        surveyNotFound: 'Anketa nije pronađena',
        actionNotEnabled: 'Kod je neaktivan',
        loginRequired: 'Zahteva se prijava',
        noPermissionForSelectedFolder:
            'Nemate dozvolu da vidite sadržaj ovog direktorijuma',
        noFoldersAssignedForTerminal:
            'Nema dodeljenih direktorijuma sa uputstvima',
        noChecklistsAssignedToControl:
            'Izabranoj kontroli nema dodeljenu kontrolnu listu',
        maximum: 'Maksimum',
        versionErrorMessage:
            'Vaše nedavne promene nisu sačuvane jer je neko drugi uneo izmene u isto vreme. Osvežite stranicu i pokušajte ponovo.',
        duplicateError: 'Ova vrednost već postoji',
        fillAllRequiredParameters: 'Popunite sve potrebne parametre.',
        wrongValue: 'Pogrešna vrednost',
        noParametersDefined: 'Nema definisanih parametara',
        noEnoughParts: 'Nema dovoljno stavki',
        pinNotBelongsTo: 'Ovaj @:common.PIN_CODE_LABEL ne pripada',
        tagNotBelongsTo: 'Ova kartica/oznaka ne pripada',
        noDictionaryEntries: 'Izabrani rečnik ne sadrži unose',
        noConnectionWithServer: 'Nema veze sa serverom',
    },
    navDrawer: {
        dashboard: 'Kontrolna tabla',
        issues: 'Problemi',
        timetable: 'Raspored',
        machines: 'Mašine',
        tools: 'Alati',
        parts: 'Delovi',
        storage: 'Skladište',
        staff: 'Osoblje',
        configuration: 'Konfiguracija',
        notes: 'Bilješke',
        notifications: 'Obaveštenja',
        profile: 'Profil',
        supervision: 'Nadzor',
    },
    barcodeScanner: {
        barCode: 'Bar kod',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Kategorija',
        selectCategory: 'Izaberite kategoriju',
        noSubcategories: 'Nema podkategorija',
    },
    companies: {
        addCompany: 'Dodajte kompaniju',
        editCompany: 'Izmjeni kompaniju',
        fullName: 'Puno ime',
        shortName: 'Kratko ime',
        group: 'Grupa',
        country: 'Zemlja',
        city: 'Grad',
        street: 'Ulica',
        postCode: 'Poštanski broj',
        phone: 'Telefon',
        email: 'E-mail',
        website: 'Veb stranica',
        NIP: 'NIP',
        REGON: 'Registar privrednih subjekata',
        active: 'Aktivan',
        nameRequired: 'Ime je obavezno',
        shortNameRequired: 'Ime je obavezno',
    },
    issues: {
        tookAt: 'Uzeto u',
        changeHistory: 'Istorija promene',
        load: 'Učitaj',
        date: 'Datum',
        createdBy: 'Kreirao',
        action: 'Akcija',
        data: 'Podaci',
        created: 'Kreirano',
        updated: 'Ažurirano',
        deleted: 'Uklonjeno',
        restored: 'Vraćeno',
        issues: 'Problemi',
        issue: 'Problem',
        by: 'od',
        name: 'Ime',
        applicantsDescription: 'Opis zahteva',
        accidentDescription: 'Opis nesreće',
        jobDescription: 'Opis posla',
        jobSummary: 'Sažetak posla',
        category: 'Kategorija',
        status: 'Status',
        noFlow: '[Bez toka]',
        equipment: 'Oprema',
        criticality: 'Kritičnost',
        hour: 'Sat',
        formalConfirmation: 'Formalna potvrda',
        formalConfirmationShortcut: 'FP',
        formalConfirmationRequired: 'Potrebna FP',
        parameter: 'Parametar',
        value: 'Vrednost',
        addIssue: 'Dodaj problem',
        expectedCompletionDate: 'Očekivani datum završetka',
        issueDescription: 'Opis problema',
        partsUsage: 'Upotreba delova',
        assignedEmployees: 'Dodeljeni zaposleni',
        requiredHelp: 'Potrebna pomoć',
        returnParts: 'Vrati deo',
        confirmUnassignEmployeeWhoTakenIssue:
            'Ovaj zaposleni je preuzeo izabrani problem. Da li želite da ga uklonite?',
        responsibleUser: 'Odgovorni korisnik',
        responsibleRules: 'Odgovorni korisnici - pravila',
        received_decision_at:
            'Datum kada se podnosilac predstavke upoznao sa odlukom',
        groupRules: 'Grupe – pravila',
        watchersRules: 'Čuvari – pravila',
    },
    machines: {
        machines: 'Mašine',
        xIssuesNotPlanned: ' (nije planirano)',
        noIssues: 'Nema problema',
    },
    maintenance: {
        maintenance: 'Održavanje',
    },
    notes: {
        notes: 'Beleške',
        note: 'Beleška',
        title: 'Naslov',
        archived: 'Arhivirano',
    },
    parts: {
        parts: 'Delovi',
        warehouseOutgoings: 'Izlaz robe iz magacina',
        fittingEquipmentTypes: 'Tipovi opreme za ugradnju',
        goToEquipmentType: 'Idi na tip opreme',
        selectPart: 'Izaberite deo',
        usageReason: 'Razlog',
        usageOtherReason: 'Drugo',
        replacements: 'Zamene',
        partEntry: 'Unos dela',
        goToPart: 'Idi na deo',
    },
    costCenter: {
        costCenter: 'Troškovni centar',
    },
    equipmentTypes: {
        equipment: 'Oprema',
        newEquipmentType: 'Novi tip opreme',
        fittingParts: 'Delovi za ugradnju',
        goToParts: 'Idi na deo',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'Definicije mašina i alata',
    },
    flows: {
        flow: 'Tok',
        states: 'Stanja',
        transitions: 'Prelazi',
        state: 'Stanje',
        default: 'Podrazumevano',
        warning: 'Upozorenje',
        problem: 'Problem',
        fixing: 'Popravka',
        finished: 'Gotov',
        access: {
            create: 'Stvaranje',
            read: 'Čitanje',
            update: 'Modifikacija',
            delete: 'Uklanjanje',
            read_unassigned: 'Nedodeljeno čitanje',
            assign: 'Dodeljivanje',
            unassign: 'Izbrišite zadatak',
            close: 'Zatvaranje',
        },
        instructions: {
            default: 'Uobičajeno',
            fixing: 'Razmatranje',
            finished: 'Gotov',
            creation: 'Kreacija',
            verification: 'Verifikacija',
            acceptation: 'Prihvatanje',
            publication: 'Publikacija',
        },
        accessLabels: {
            terminal: 'Terminal koji je prijavio problem',
            creator: 'Korisnik koji je prijavio problem',
            responsible: 'Korisnik koji je odgovoran za problem',
            group: 'Korisnička grupa koja je odgovorna za problem',
            anchor: 'Terminal na koji je izveštaj zakačen',
            watchers: 'Korisnici koji posmatraju problem',
        },
        sync_returned_rejected: 'Sinhronizacija - odbijena',
        sync_returned_finished: 'Sinhronizacija - završena',
        current: 'Struja',
    },
    parameters: {
        categoryParameter: 'Parametar kategorije',
        equipmentTypeParameter: 'Parametar tipa opreme',
    },
    staff: {
        staff: 'Osoblje',
        editUserAccess: 'Uredi pristup korisnika',
        permissionsUpdated: 'Dozvole ažurirane',
        passwordUpdated: 'Lozinka ažurirana',
        codeUpdated: '@:common.PIN_CODE_LABEL ažuriran',
        changePasswordAndCode: 'Promena lozinke i @:common.PIN_CODE_LABEL',
        goToLicenses: 'Idi na licence',
        editAccess: 'Uredi pristup',
        editUser: 'Uredi korisnika',
        addUser: 'Dodaj novog korisnika',
        nameAndLastname: 'Ime i prezime',
        accountStatus: 'Status naloga',
        phoneNumber: 'Broj telefona',
        department: 'Odeljenje',
        passwordChange: 'Promena lozinke',
        codeChange: 'Promena @:common.PIN_CODE_LABEL',
        newPassword: 'Nova lozinka',
        confirmNewPassword: 'Potvrdi novu lozinku',
        changePassword: 'Promeni lozinku',
        newCode: 'Novi @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Potvrdi novi @:common.PIN_CODE_LABEL',
        changeCode: 'Promeni @:common.PIN_CODE_LABEL',
        addEntry: 'Dodaj unos',
        editEntry: 'Uredi unos',
        editLicense: 'Uredi licencu',
        addLicense: 'Dodaj licencu',
        licenseNumber: 'Broj licence',
        prefferedLocale: 'Preferirani jezik',
        oldPassword: 'Stara lozinka',
        newPasswordConfirmation: 'Potvrdi novu lozinku',
        reading: 'Prikazivanje',
        creating: 'Kreiranje',
        updating: 'Ažuriranje',
        deleting: 'Brisanje',
        importScheduleFile: 'Uvezite raspored iz datoteke',
        importSchedule: {
            errors: {
                user_not_found: 'Korisnik nije pronađen [{user}]',
                user_found_many: '{count} korisnika pronađeno [{user}]',
                event_collision: 'Sukob događaja',
                hour_cell_parse: 'Greška pri raščlanjivanju ćelije',
            },
        },
        oldCode: 'Stari @:common.PIN_CODE_LABEL',
        newCodeConfirmation: 'Potvrda novog @:common.PIN_CODE_LABEL',
        setAccessBasedOnProfile: 'Odobrite pristup na osnovu profila',
        selectAccessMergeType: 'Izaberite kako da kombinujete dozvole',
        noAccessesInProfileMessage:
            'Izabrani profil nema dozvole. Da li ipak želite da prepišete?',
    },
    breadCrumb: {
        configuration: 'Konfiguracija',
        locations: 'Lokacije',
        home: 'Početna',
        machines: 'Mašine',
        tools: 'Alati',
        issues: 'Problemi',
        notes: 'Beleške',
        maintenance: 'Održavanje',
        parts: 'Delovi',
        warehouse: 'Magacin',
        dictionary: 'Rečnik',
        categories: 'Kategorije',
        parameters: 'Parametri',
        costCenter: 'Troškovni centar',
        flows: 'Tokovi',
        states: 'Stanja',
        transitions: 'Prelazi',
        shiftSchemas: 'Šeme smena',
        shifts: 'Smena',
        definitionsOfMachinesAndToolsTypes: 'Definicije tipova mašina i alata',
        add: 'Dodaj',
        edit: 'Uredi',
        staff: 'Osoblje',
        licenses: 'Licence',
        entries: 'Unosi',
        companiesDatabase: 'Baza kompanija',
        companyManagement: 'Upravljanje kompanijom',
        companyData: 'Podaci o kompaniji',
        supportedLanguages: 'Podržani jezici',
        en: 'Engleski',
        pl: 'Poljski',
        es: 'Španski',
        de: 'Nemački',
        defaultLanguage: 'Podrazumevani jezik',
        notifications: 'Obaveštenja',
        notificationsManagement: 'Upravljanje obaveštenjima',
        myProfile: 'Moj profil',
    },
    attributeTypes: {
        text: 'Tekst',
        note: 'Beleška',
        integer: 'Ceo broj',
        decimal: 'Decimalni broj',
        date: 'Datum',
        time: 'Vreme',
        dateTime: 'Datum i vreme',
        color: 'Boja',
        icon: 'Ikona',
        boolean: 'Da/Ne',
    },
    costCalculator: {
        costCalculator: 'Kalkulator troškova',
        waitTime: 'Vreme čekanja',
        serviceTime: 'Vreme usluge',
        suspendTime: 'Vreme pauze',
        totalTime: 'Ukupno vreme',
        estimatedCost: 'Procenjeni troškovi',
        calculate: 'Izračunaj',
        withoutParts: 'Bez delova',
        timeOfReportingIssue: 'Vreme prijave problema',
        timeOfServiceStart: 'Vreme početka usluge',
        timeOfReportingEnd: 'Vreme završetka usluge',
        days: 'Dani',
        hours: 'Sati',
        minutes: 'Minuti',
        enterNewTime: 'Unesite novo vreme',
        fromIssueReportToServiceStart:
            'Vreme od prijave problema do početka usluge',
        fromServiceStartToServiceEnd:
            'Vreme od početka usluge do završetka usluge',
        workTime: 'Radno vreme',
        includeAwaitingTime: 'Uključi vreme čekanja',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'Moji problemi',
                assignedToMe: 'Dodeljeno meni',
                assignedToGroup: 'Dodeljeno mojoj grupi',
                tookByMe: 'Preuzeto od mene',
                takeIssueBtn: 'Preuzmi',
                arriveIssueBtn: 'Stigao sam',
                finishIssueBtn: 'Završi',
                history: 'Istorija',
                myResponsible: 'Ja sam odgovoran za',
                unassignIssue: 'Otkaži snimanje',
            },
        },
    },
    synchronization: {
        enabled: 'Omogućeno',
        hasHeaders: 'Ima zaglavlja',
        leaveFile: 'Ostavi fajl',
        host: 'Host',
        port: 'Port',
        protocol: 'Protokol',
        username: 'Korisničko ime',
        password: 'Lozinka',
        root: 'Koren',
        encoding: 'Enkodiranje',
        separator: 'Separator',
        externalSystemId: 'ID eksternog sistema',
        cronExpression: 'Cron izraz',
        appendFullStock: 'Dodaj kompletnu zalihu',
        includeHeaders: 'Uključi zaglavlja',
        withTrashed: 'Sa uklonjenim podacima',
        filePattern: 'Obrazac fajla',
        categoriesRootCategoryId: 'Osnovna kategorija za uvezene kategorije',
        systemKeyname: 'Identifikator sistema',
        address: 'Adresa',
        token: 'Token',
    },
    processes: {
        created_at: 'Datum kreiranja',
        started_at: 'Datum početka',
        updated_at: 'Datum ažuriranja',
        finished_at: 'Datum završetka',
        attempts: 'Pokušaji',
        queue: 'Red',
        job_id: 'ID zadatka',
        job_uuid: 'UUID zadatka',
        progress_max: 'Maksimalan napredak',
        progress_now: 'Trenutni napredak',
        outputMessage: 'Izlazna poruka',
        status: {
            queued: 'U redu',
            executing: 'Izvršavanje',
            finished: 'Završeno',
            failed: 'Neuspešno',
            retrying: 'Ponovo pokušaj',
            undefined: 'Nedefinisano',
        },
    },
    weekDays: {
        mo: 'Ponedeljak',
        tu: 'Utorak',
        we: 'Sreda',
        th: 'Četvrtak',
        fr: 'Petak',
        sa: 'Subota',
        su: 'Nedelja',
    },
    reports: {
        reports: 'Izveštaji',
        dateFrom: 'Datum od',
        dateTo: 'Datum do',
        types: {
            operation_sheet: 'Operativni list',
            issue_summary: 'Rezime problema',
            grouped_summary: 'Rezime izveštaj',
            storage_operations: 'Skladišne operacije',
            part_quantity_export: 'Količina delova',
        },
        reportFor: 'Izveštaj za',
        includeQuantityLeft0: 'Uključite delove sa nultom količinom',
        dayMinusN: 'Dan minus n',
        currentWeek: 'Tekuća nedelja',
        lastWeek: 'Prošla nedelja',
        currentMonth: 'Tekući mesec',
        lastMonth: 'Prošli mesec',
        currentYear: 'Tekuća godina',
        lastYear: 'Prošla godina',
        nDays: 'Broj dana',
        todayMinusN: 'Danas minus n',
    },
    logs: {
        comment: 'Komentar',
        issues: {
            escalation: 'Eskalacija ({minutes} min) - obaveštenje poslato',
        },
        issueEmployee: {
            assignedAt: 'Zaposleni dodeljen:',
            receivedAt: 'Zaposleni primio:',
            tookAt: 'Zaposleni preuzeo:',
            finishedAt: 'Zaposleni završio:',
            deletedAt: 'Zaposleni uklonjen iz prijave',
        },
    },
    units: {
        no: 'Komad',
        st: 'комплет',
        pr: 'Par',
    },
    rrule: {
        frequency: 'Frekvencija',
        dtstart: 'Datum početka',
        tzid: 'Vremenska zona',
        until: 'Sve dok',
        count: 'grof',
        interval: 'interval',
        wkst: 'Početak dana',
        byweekday: 'do radnog dana',
        bymonth: 'Po mesecu',
        yearly: 'godišnje',
        monthly: 'mesečno',
        weekly: 'nedeljno',
        daily: 'dnevno',
        hourly: 'Po satu',
        minutely: 'minut',
        secondly: 'Drugo',
        infinitePeriodicityMessage:
            'Periodičnost je beskonačna, zbog performansi se prikazuje samo 500 redova',
    },
    months: {
        jan: 'januara',
        feb: 'februar',
        mar: 'mart',
        apr: 'April',
        may: 'Može',
        jun: 'jun',
        jul: 'Jul',
        aug: 'avgust',
        sep: 'Septembar',
        oct: 'oktobar',
        nov: 'novembar',
        dec: 'decembar',
    },
    charts: {
        charts: 'grafikon',
        count: 'Broj izdanja',
        total: 'Ukupno vreme problema (od izveštaja do kraja)',
        serw: 'Ukupno vreme popravke (Ttotal - Twait)',
        mttr1: 'Prosečno vreme od izveštaja do kraja',
        mttr2: 'Prosečno vreme od snimanja do kraja',
        mtbf: 'Prosečno vreme rada između problema',
        mttf: 'Srednje vreme do neuspeha',
        type: 'Tip grafikona',
        pie: 'Pie chart',
        bar: 'Trakasti grafikon',
        query: 'Obim podataka',
        groupBy: 'Grupa od',
        sum: 'Sum',
        avg: 'Značiti',
        min: 'Minimum',
        max: 'Maksimum',
        eq: 'Je jednako',
        neq: 'To nije jednako',
        gt: 'Više od',
        lt: 'Manje od',
        gte: 'Više ili jednako',
        lte: 'Manje ili jednako',
        in: 'Sadrži',
        nin: 'Ne sadrže',
        btw: 'Između',
        chartOptions: 'Opcije grafikona',
        line: 'Linijski grafikon',
        mixed: 'Pomešan',
        chartDisplayOptions: 'Opcije prikaza',
        fillGaps: 'Popunite prazna polja',
    },
    regexBuilder: {
        regexValue: 'Vrednost',
        global: 'Globalno',
        multiline: 'Višelinijski',
        insensitive: 'Bez osetljivosti na velika slova',
        extended: 'Prošireno',
        singleline: 'Jednolinijski',
        unicode: 'Unicode',
        Ungreedy: 'Lenji kvantifikatori',
        Anchored: 'Sidren na početku obrasca ili kraju poslednjeg poklapanja',
        Jchanged: 'Dozvoli duplikate naziva podšablona',
        DollarEndOnly: '$ odgovara samo kraju obrasca',
        testValue: 'Test vrednost',
    },
    tpm: {
        groupableHeadersPlaceholder:
            'Prevucite zaglavlje tabele da grupišete podatke.',
        steps: 'Koraci',
        step: 'Korak',
        content: 'Sadržaj',
        history: 'Istorija',
        standardObstacle: 'Standardna prepreka',
        customObstacle: 'Prilagođena prepreka',
        bhp: 'Bezbednost i zdravlje na radu',
        returnToMainPage: 'Povratak na glavnu stranicu',
        bhpMessage:
            'Ako primetite rizike po bezbednost, odmah obavestite nadređenog i koristite prijavni obrazac koji se nalazi ispod oglasne table.',
        ok: 'U redu',
        send: 'Pošalji',
        description: 'Opis',
        title: 'Naslov',
        realization: 'Realizacija',
        answer: 'Odgovor',
        pendingSurveys: 'Nerešene ankete',
        surveys: 'Ankete',
        systemSurvey: 'Sistematska anketa',
        addPage: 'Dodaj stranicu',
        createSurvey: 'Kreiraj anketu',
        addSection: 'Dodaj sekciju',
        addQuestion: 'Dodaj pitanje',
        questionContent: 'Sadržaj pitanja',
        answerOption: 'Opcija odgovora',
        addQuestionAnswer: 'Dodaj opciju odgovora',
        surveyName: 'Naziv ankete',
        sectionName: 'Naziv sekcije',
        noSurveyAnswers: 'Nema odgovora',
        removeSection: 'Ukloni sekciju',
        published: 'Objavljeno',
        unpublished: 'Nije objavljeno',
        pendingPublication: 'Promene čekaju objavu',
        removeQuestion: 'Ukloni pitanje',
        removeAnswer: 'Ukloni opciju odgovora',
        publish: 'Objavi',
        unpublish: 'Povuci objavu',
        previewResults: 'Pregledaj rezultate',
        supervisior: 'Nadređeni',
        surveyResults: 'Rezultati ankete',
        surveysEvents: 'Konfiguracija događaja - ankete',
        operatedBy: 'Pokreće',
        confirmReadInformations: 'Potvrđujem da sam pročitao informacije',
        noticeContent: 'Sadržaj obaveštenja',
        notice: 'Obaveštenje',
        surveyEventName: 'Naziv događaja',
        survey: 'Anketa',
        newIssue: 'Novi problem',
        surveyEventConfigurationAlreadyExists:
            'Konfiguracija događaja već postoji',
        lastComment: 'Poslednji komentar odgovorne osobe',
        surveyNotConfigured: 'Anketa nije konfigurisana',
        fillSurvey: 'Popuni anketu',
        singleSurveyEntries: 'pojedinačni rezultati.',
        groupedSurveyEntries: 'Grupisani rezultati.',
        publicationId: 'ID publikacije.',
        publicationDate: 'Datum izdavanja.',
        versionId: 'ID verzije.',
        answersCount: 'Broj odgovora.',
        answers: 'Odgovori',
        withoutSupervisor: 'Nema nadzornika',
        defaultSupervisor: 'Podrazumevani nadzornik',
        flowStateFlags: 'Statusne zastavice',
        qrActionsSettings: 'Podešavanja akcije QR koda',
        loginRequired: 'Zahteva prijavu',
        questionContext: 'Kontekst pitanja',
        currentSurveyVersionPreview: 'Pregled trenutne verzije ankete',
        surveyDescription: 'Opis ankete',
        sectionDescription: 'Opis sekcije',
        instructions: 'Uputstva',
        instruction: 'uputstvo',
        instructionRevisions: 'Revizije',
        instructionRevision: 'revizija',
        checklists: 'Kontrolne liste',
        checklist: 'Kontrolna lista',
        checklistItem: 'Lista stvari',
        manageChecklistFields: 'Upravljajte stavkama liste',
        checklistItemDisplayOptions: 'Prikazane stavke',
        revisionAutoRejectedInfo: '[Sistem] Odbijeno - kreirana nova revizija',
    },
    accessMergeValues: {
        sum: 'Sum',
        product: 'Proizvod',
        overwrite: 'Prepisati',
    },
    aggregates: {
        count: 'Sum',
        min: 'Minimum',
        max: 'Maksimum',
    },
    checklists: {
        itemBasic: 'Osnovni element',
        itemSeparator: 'Separator',
        itemSection: 'Sekcija',
        publish: 'Objavite',
        previewCurrentVersion: 'Pregled trenutne verzije',
    },
    cluer: {
        groupableHeadersPlaceholder:
            'Prevucite zaglavlje tabele da grupišete podatke.',
        history: 'Istorija',
        standardObstacle: 'Standardna prepreka',
        customObstacle: 'Prilagođena prepreka',
        bhp: 'Bezbednost i zdravlje na radu',
        returnToMainPage: 'Povratak na glavnu stranicu',
        bhpMessage:
            'Ako primetite rizike po bezbednost, odmah obavestite nadređenog i koristite prijavni obrazac koji se nalazi ispod oglasne table.',
        ok: 'U redu',
        send: 'Pošalji',
        description: 'Opis',
        title: 'Naslov',
        realization: 'Realizacija',
        answer: 'Odgovor',
        pendingSurveys: 'Nerešene ankete',
        surveys: 'Ankete',
        systemSurvey: 'Sistematska anketa',
        addPage: 'Dodaj stranicu',
        createSurvey: 'Kreiraj anketu',
        addSection: 'Dodaj sekciju',
        addQuestion: 'Dodaj pitanje',
        questionContent: 'Sadržaj pitanja',
        answerOption: 'Opcija odgovora',
        addQuestionAnswer: 'Dodaj opciju odgovora',
        surveyName: 'Naziv ankete',
        sectionName: 'Naziv sekcije',
        noSurveyAnswers: 'Nema odgovora',
        removeSection: 'Ukloni sekciju',
        published: 'Objavljeno',
        unpublished: 'Nije objavljeno',
        pendingPublication: 'Promene čekaju objavu',
        removeQuestion: 'Ukloni pitanje',
        removeAnswer: 'Ukloni opciju odgovora',
        publish: 'Objavi',
        unpublish: 'Povuci objavu',
        previewResults: 'Pregledaj rezultate',
        supervisior: 'Nadređeni',
        surveyResults: 'Rezultati ankete',
        surveysEvents: 'Konfiguracija događaja - ankete',
        operatedBy: 'Pokreće',
        confirmReadInformations: 'Potvrđujem da sam pročitao informacije',
        noticeContent: 'Sadržaj obaveštenja',
        notice: 'Obaveštenje',
        surveyEventName: 'Naziv događaja',
        survey: 'Anketa',
        newIssue: 'Novi problem',
        surveyEventConfigurationAlreadyExists:
            'Konfiguracija događaja već postoji',
        lastComment: 'Poslednji komentar odgovorne osobe',
        surveyNotConfigured: 'Anketa nije konfigurisana',
        fillSurvey: 'Popuni anketu',
        singleSurveyEntries: 'pojedinačni rezultati.',
        groupedSurveyEntries: 'Grupisani rezultati.',
        publicationId: 'ID publikacije.',
        publicationDate: 'Datum izdavanja.',
        versionId: 'ID verzije.',
        answersCount: 'Broj odgovora.',
        answers: 'Odgovori',
        withoutSupervisor: 'Nema nadzornika',
        defaultSupervisor: 'Podrazumevani nadzornik',
        flowStateFlags: 'Statusne zastavice',
        qrActionsSettings: 'Podešavanja akcije QR koda',
        loginRequired: 'Zahteva prijavu',
        questionContext: 'Kontekst pitanja',
        currentSurveyVersionPreview: 'Pregled trenutne verzije ankete',
        surveyDescription: 'Opis ankete',
        sectionDescription: 'Opis sekcije',
    },
    orders: {
        order: 'Red',
        releaseDate: 'Datum završetka',
        orderDate: 'datum narudžbe',
    },
    production: {
        good: 'Broj dobrih',
        bad: 'Broj loših',
        productionRecords: 'Registar proizvodnje',
    },
};
