<template>
    <SignaloDialog
        :open="open"
        :title="title"
        @close="() => closeDialog(false)"
        :constHeight="false"
        :dialogClasses="['reject-reason-dialog']"
    >
        <template #body>
            <v-form
                ref="rejectionReasonForm"
                v-model="dataValid"
                lazy-validation
            >
                <v-col cols="12" md="12" xl="12">
                    <v-textarea
                        :label="$t('common.rejectionReason')"
                        variant="outlined"
                        v-model="reason"
                        class="required-field"
                        :rules="required"
                    ></v-textarea>
                </v-col>
            </v-form>
        </template>
        <template #buttons>
            <v-btn @click="closeDialog(false)">{{ $t('common.cancel') }}</v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="save"
                :loading="saveLoading"
                :disabled="!reason"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    props: ['open', 'title', 'issueId'],
    data() {
        return {
            dataValid: false,
            reason: null,
            saveLoading: false,
            required: [(v) => !!v || this.$t('common.fieldRequired')],
        };
    },
    methods: {
        async save() {
            await this.$refs?.rejectionReasonForm.validate();
            if (!this.dataValid) return;
            this.saveLoading = true;
            try {
                await this.axios.patch(`issues/${this.issueId}`, {
                    rejected: true,
                    rejection_reason: this.reason
                });
                this.helpersStore.snackbarSaved();
                this.closeDialog(true)
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.saveLoading = false;
        },
        closeDialog(shouldRefetch = false) {
            this.$emit('close', shouldRefetch);
        },
    },
};
</script>
<style>
.reject-reason-dialog {
    max-width: 600px;
}
</style>
