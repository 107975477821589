<template>
    <SignaloDialog
        :open="open"
        @close="() => close(false)"
        :title="$t('common.returnPart')"
        :constHeight="false"
        :dialogClasses="['return-part-dialog']"
    >
        <template #body>
            <v-form ref="dataForm" v-model="dataValid" lazy-validation>
                <v-col cols="12" md="12" xl="12">
                    <v-text-field
                        min="1"
                        type="number"
                        :label="$t('common.quantity')"
                        v-model="quantity"
                        variant="outlined"
                        :error-messages="quantityError"
                        :rules="[$REQUIRED_RULE, $MIN_RULE(quantity, 1)]"
                        class="required-field"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="12" xl="12">
                    <v-select
                        :label="$t('common.unit')"
                        v-model="unit"
                        :items="units"
                        item-value="value"
                        variant="outlined"
                        color="var(--greyscale-100)"
                        class="required-field padding-0-select"
                        :rules="unitRules"
                        ref="unitSelectRef"
                        :no-data-text="$t('common.emptyTableMessage')"
                    >
                        <template #selection="{ item }">
                            <v-list-item :value="item.value">
                                <span
                                    v-html="$displayDimension(item.value)"
                                ></span>
                            </v-list-item>
                        </template>
                        <template #item="{ item }">
                            <v-list-item
                                @click="setUnit(item)"
                                style="cursor: pointer"
                            >
                                <span
                                    v-html="$displayDimension(item.value)"
                                ></span>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="12" xl="12">
                    <SignaloLocationPicker v-model="locationId" v-slot="props">
                        <v-text-field
                            :modelValue="props.text"
                            @click:control="props.onClick"
                            @click:clear="props.onClear"
                            readonly
                            clearable
                            :label="$t('common.location')"
                            variant="outlined"
                            color="var(--greyscale-100)"
                            clear-icon="mdi-close-circle-outline"
                            :rules="[$REQUIRED_RULE]"
                            class="required-field"
                        >
                        </v-text-field>
                    </SignaloLocationPicker>
                </v-col>
            </v-form>
        </template>
        <template #buttons>
            <v-btn @click="() => close(false)">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                :loading="dataSaving"
                color="primary"
                @click="saveData"
                variant="elevated"
                :disabled="!quantity"
            >
                {{ $t('common.returnPart') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        return { dataStore };
    },
    props: ['open', 'partUsage', 'userId'],
    data() {
        return {
            locationId: null,
            quantity: null,
            dataValid: false,
            dataSaving: false,
            unit: null,
            quantityError: {},
        };
    },
    async mounted() {
        await this.dataStore.locations.promise();
        this.locationId = this.dataStore?.locations?.items[0]?.id
        this.loadUnits(this.partUsage.part_entry);
    },
    methods: {
        close(shouldRefetch = false) {
            this.$emit('close', shouldRefetch);
        },
        async saveData() {
            this.quantityError = {};
            try {
                await this.$refs?.dataForm.validate();
                if (!this.dataValid) return;
                this.dataSaving = true;

                const data = {
                    provided_quantity: this.quantity,
                    provided_unit: this.unit,
                    location_id: this.locationId,
                };

                await this.axios.delete(
                    `users/${this.userId}/parts/${this.partUsage.id}`,
                    { data: data }
                );
                this.close(true);
            } catch (err) {
                if (
                    Object.keys(err?.response?.data?.errors).includes(
                        'provided_quantity'
                    )
                ) {
                    this.quantityError = this.$t('errors.valueTooHigh');
                }
                this.dataSaving = false;
            }
            this.dataSaving = false;
        },
        async loadUnits(partEntry) {
            this.loading = true;
            try {
                const unitsData = await this.axios.get('global/dimensions');
                const unitTypes = Object.keys(unitsData?.data)
                    ?.slice(0, -1)
                    ?.map((key) => {
                        return {
                            value: key,
                            translations: unitsData?.data[key]?.translations,
                            units: unitsData?.data[key]?.units,
                        };
                    });
                this.units =
                    unitTypes.find((ut) => ut.units.includes(partEntry.unit))
                        ?.units || [];
                this.unit = partEntry.unit;
            } catch (err) {
                if (err?.response?.status === 401) return;
            }

            this.loading = false;
        },
        setUnit(val) {
            this.unit = val.value;
            this.$refs.unitSelectRef.menu = false;
        },
    },
};
</script>
<style>
.return-part-dialog {
    max-width: 600px;
}
</style>
