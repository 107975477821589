<template>
    <SignaloDialog
        :open="true"
        :title="$t('common.fillParametersToChangeState')"
        @close="closeDialog"
        :constHeight="false"
    >
        <template #body>
            <SignaloItemParametersManage
                class="state-change-parameters"
                :modelValue="parameters"
                @update:modelValue="onUpdateParams"
                @everyRequiredParamHasValue="onEveryRequiredParamHasValue"
            />
        </template>
        <template #buttons>
            <v-btn @click="$emit('close', false)">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                color="primary"
                @click="onSave"
                variant="elevated"
                :disabled="!everyRequiredParamHasValue"
                :loading="saveLoading"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
export default {
    props: ['parameters'],
    data() {
        return {
            params: [...this.parameters],
            everyRequiredParamHasValue: false,
            saveLoading: false,
        };
    },
    methods: {
        onUpdateParams(params) {
            this.params = params;
        },
        onSave() {
            if (this.saveLoading) return;
            this.saveLoading = true;
            this.$emit(
                'save',
                this.params.map((p) => {
                    return {
                        parameter_id: p.id,
                        value: p.value || p.default_value,
                    };
                })
            );
        },
        onEveryRequiredParamHasValue(everyRequiredParamHasValue, parameters) {
            this.everyRequiredParamHasValue = everyRequiredParamHasValue;
            this.params = [...parameters];
        },
    },
};
</script>

<style>
.state-change-parameters.v-card-text {
    padding: 0 !important;
}
</style>
