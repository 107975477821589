<template>
    <v-card class="mx-3 overflow-visible" style="padding: 0 !important">
        <template v-slot:title>
            <v-toolbar color="#fff">
                <v-toolbar-title>
                    <v-icon class="app-dialog-title-icon">mdi-draw-pen</v-icon>
                    {{ $t('common.instructionsToAccept') }}</v-toolbar-title
                >
            </v-toolbar>
        </template>
        <EasyDataTable buttons-pagination
            :headers="tableHeaders"
            :items="items"
            :loading="loading"
            :empty-message="$t('common.emptyTableMessage')"
            @click-row="rowClicked"
            table-class-name="easy-table-style"
            sort-by="created_at"
            sort-type="asc"
            :rows-items="[5, 10, 25, 50, 75, 100]"
            :rows-per-page="5"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
        >
            <template #item-name="item">
                <span>{{
                    $getPropertyTranslation(
                        item?.instruction_revision?.instruction?.name
                    )
                }}</span>
            </template>
            <template #item-description="item">
                <span>
                    {{
                        $getPropertyTranslation(
                            item?.instruction_revision?.instruction?.description
                        )
                    }}
                </span>
            </template>
            <template #item-instruction_group="item">
                {{
                    getInstructionGroupName(
                        item?.instruction_revision?.instruction
                            ?.instruction_group
                    )
                }}
            </template>
            <template #item-revision="item">
                {{ item?.instruction_revision?.revision }}
            </template>
            <template #item-changes="item">
                {{
                    $getPropertyTranslation(item?.instruction_revision?.changes)
                }}
            </template>
            <template #item-operation="item">
                <SignaloDynamicMenu :rowItem="item" :menuItems="tableActions" />
            </template>
        </EasyDataTable>
    </v-card>

    <SignaloInstructionRevisionAcceptationDialog
        v-if="revisionToAccept"
        :revisions="[revisionToAccept]"
        @close="
            (shouldRefetch) =>
                onCloseInstructionAcceptationDialog(shouldRefetch)
        "
    />
</template>
<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import SignaloInstructionRevisionAcceptationDialog from './SignaloInstructionRevisionAcceptationDialog.vue';
export default {
    name: 'SignaloInstructionsToAccept',
    emits: ['reload'],
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = HELPERS_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    mounted() {
        this.load();
    },
    data() {
        return {
            items: [],
            loading: true,
            revisionToAccept: null,
        };
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: `${this.$t('common.assignedAt')}`,
                    value: 'assigned_at',
                },
                {
                    text: `${this.$t('tpm.instruction')} - ${this.$t(
                        'tableHeaders.name'
                    )}`,
                    value: 'name',
                },
                {
                    text: this.$t('tableHeaders.description'),
                    value: 'description',
                },
                {
                    text: this.$t('common.instructionGroup'),
                    value: 'instruction_group',
                },
                {
                    text: this.$t('tpm.instructionRevision'),
                    value: 'revision',
                },
                {
                    text: this.$t('common.revisionChanges'),
                    value: 'changes',
                },
                {
                    fixed: true,
                    value: 'operation',
                    width: 100,
                    text: this.$t('tableHeaders.actions'),
                },
            ];
        },
        tableActions() {
            return [
                {
                    label: this.$t('common.open'),
                    action: this.openInstruction,
                },
            ];
        },
    },
    methods: {
        async load() {
            this.loading = true;
            try {
                const { data } = await this.axios.get(
                    '/me/instructions?user_confirmed_at_equals=null'
                );
                this.items = Object.values(data?.items);
            } catch (err) {
                console.error(err);
            }
            this.loading = false;
        },
        getInstructionGroupName(keyname) {
            return Object.values(
                this.dataStore.dictionary.group('instruction_group')
            ).find((d) => d.keyname == keyname)?.value;
        },
        async openInstruction(item) {
            if (!item?.instruction_revision) return;
            this.revisionToAccept = item?.instruction_revision;
        },
        rowClicked(item) {
            this.openInstruction(item);
        },
        async onCloseInstructionAcceptationDialog(shouldRefetch) {
            if (shouldRefetch) {
                await this.load();
            }
            this.revisionToAccept = null;
        },
    },
    components: { SignaloInstructionRevisionAcceptationDialog },
};
</script>

<style>
.part-statuses-container {
    border: 1px solid var(--ui-general-outline-01);
    border-radius: 8px;

    max-width: 200px;
    margin: 8px;
}
.part-status {
    display: flex;
    justify-content: space-between;
}

.part-status-value {
    font-weight: bold;
}
</style>
