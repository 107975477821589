<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.configuration') }} </template>
        <template #bar>
            <v-btn
                @click="manageDialogOpened = true"
                v-if="$route.meta.canCreate"
                color="primary"
                variant="elevated"
            >
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>

    <v-main>
        <SignaloBreadCrumbs />
        <EasyDataTable
            buttons-pagination
            :headers="tableHeaders"
            :items="items"
            :empty-message="$t('common.emptyTableMessage')"
            :loading="loading"
            table-class-name="easy-table-style"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            v-model:server-options="serverOptions"
            :server-items-length="serverItemsLength"
            :rows-items="[5, 10, 25, 50, 75, 100]"
            :name="this.TABLE_NAME"
        >
            <template #item-accessable_id="item">
                {{ $getPropertyTranslation(item?.accessable?.name) }}
            </template>
            <template #item-instruction_group="item">
                {{
                    $getDictionaryItemName(
                        'instruction_group',
                        item?.instruction_group
                    )
                }}
            </template>

            <template #item-operation="item">
                <SignaloDynamicMenu :rowItem="item" :menuItems="tableActions" />
            </template>
        </EasyDataTable>
    </v-main>

    <ManageInstructionsAccessesDialog
        v-if="manageDialogOpened"
        :editedItem="item"
        @close="(shouldRefetch) => closeDialog(shouldRefetch)"
    />

    <SignaloConfirmationDialog
        v-if="removeDialog"
        :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => remove(value)"
    />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import ManageInstructionsAccessesDialog from './ManageInstructionsAccessesDialog.vue';
export default {
    components: { ManageInstructionsAccessesDialog },
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            TABLE_NAME: 'instruction_accesses',
            loading: false,
            item: null,
            manageDialogOpened: false,
            removeDialog: false,
            searchOpen: false,
            searchFieldCount: 0,
            items: [],
            exportEndpoint: null,
            exportDialog: false,
            serverOptions: this.$getServerOptions('instruction_accesses'),
            serverItemsLength: 0,
            lastSearch: null,
        };
    },
    mounted() {
        this.loadTable()
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text:
                        this.$t('common.group') +
                        ' - ' +
                        this.$t('common.users'),
                    value: 'accessable_id',
                },
                {
                    text: this.$t('common.instructionGroup'),
                    value: 'instruction_group',
                },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
        searchConfig() {
            return {
                name: { type: 'text', label: this.$t('searchConfig.name') },
            };
        },
        tableActions() {
            return [
                {
                    icon: 'mdi-pencil',
                    label: this.$t('common.edit'),
                    action: this.onEdit,
                },

                {
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    action: this.onRemove,
                },
            ];
        },
    },

    methods: {
        openDialog() {
            this.item = {};
            this.manageDialogOpened = true;
        },
        onEdit(item) {
            this.item = item;
            this.manageDialogOpened = true;
        },
        onRemove(item) {
            this.item = item;
            this.removeDialog = true;
        },
        async closeDialog(shouldRefetchData) {
            if (shouldRefetchData) {
                await this.loadTable();
                this.item = null;
                this.manageDialogOpened = false;

                return;
            }
            this.item = null;
            this.manageDialogOpened = false;
        },
        async remove(shouldRemove) {
            if (shouldRemove && this.item?.id) {
                await this.axios.delete(`instruction_accesses/${this.item.id}`);
                await this.authStore.getAuth();
                await this.loadTable();
                this.helpersStore.snackbarSaved();
            }
            this.item = null;
            this.removeDialog = false;
        },
        async loadTable(search, resetPage) {
            this.loading = true;
            if (resetPage) {
                this.serverOptions.page = 1;
            }
            if (search !== null) {
                this.lastSearch = search;
            }

            const config = this.dataStore.makeServerOptions(
                this.serverOptions,
                this.lastSearch
            );
            this.$router.replace({ query: config.params });

            try {
                const handoversTemplatesData = await this.axios.get(
                    'instruction_accesses',
                    config
                );
                this.items = handoversTemplatesData?.data?.items;
                this.serverItemsLength =
                    handoversTemplatesData?.data?.meta?.total;
                this.loading = false;
            } catch (err) {
                console.error(err);
                this.loading = false;
            }
        },
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences(this.TABLE_NAME, {
                default_size: val.rowsPerPage,
            });
            this.loadTable();
        },
    },
};
</script>
