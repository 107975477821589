<template>
    <div class="signalo-folder-tile" ref="tile" :id="folder.id">
        <v-icon class="signalo-folder-tile-move-to-icon"
            >mdi-subdirectory-arrow-right</v-icon
        >
        <v-icon class="signalo-folder-tile-default-icon">mdi-folder</v-icon>
        <div class="folder-name mx-2">{{ folder?.name }}</div>
        <v-spacer />
        <SignaloDynamicMenu
            :menuItems="folderActions"
            class="signalo-folder-actions-menu"
        />
        <div v-if="removeDialog">
            <RemoveFolderDialog
                @remove="onRemove"
                @close="onClose"
                :folder="folder"
                @reload="() => $emit('reload')"
            />
        </div>
        <div v-if="moveDialog">
            <MoveFolderDialog
                @remove="onRemove"
                @close="onMoveDialogClose"
                :folder="folder"
                @reload="() => $emit('reload')" 
            />
        </div>
    </div>
</template>

<script>
import MoveFolderDialog from './MoveFolderDialog.vue';
import RemoveFolderDialog from './RemoveFolderDialog.vue';

export default {
    props: ['folder', 'actions'],
    emits: ['reload', 'remove', 'edit'], 
    data() {
        return {
            removeDialog: false,
            moveDialog: false,
        };
    },
    methods: {
        onMove() {
            this.moveDialog = true;
        },
        onRemove() {
            this.removeDialog = true;
        },
        onClose() {
            this.removeDialog = false;
        },
        onMoveDialogClose() {
            this.moveDialog = false;
        },
        onEdit() {
            this.$emit('edit');
        },

    },
    computed: {
        folderActions() {
            if (this.actions) return this.actions;
            return [
                {
                    icon: 'mdi-pencil',
                    label: this.$t('common.rename'),
                    action: this.onEdit,
                },
                {
                    icon: 'mdi-folder-move',
                    label: this.$t('common.move'),
                    action: this.onMove,
                },
                {
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    action: this.onRemove,
                },
            ];
        },
    },
    components: { RemoveFolderDialog, MoveFolderDialog },
};
</script>

<style scoped>
.signalo-folder-tile {
    width: 100%;
    display: flex;
    background-color: var(--greyscale-30);
    min-height: 48px;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 2px solid transparent;
    transition: 0.25s;
}
.signalo-folder-tile:not(.dragstart, .dragover):hover {
    background-color: var(--greyscale-40);
}

.folder-name {
    font-size: 0.825rem;
}

.dragstart.signalo-folder-tile {
    background-color: var(--primary-10);
    border: 2px solid var(--primary-50);
}

.dragover.signalo-folder-tile {
    min-height: 96px;
    background-color: var(--tertiary-10);
    border: 2px solid var(--tertiary-50);
}
.signalo-folder-tile-move-to-icon {
    display: none;
}

.dragover .signalo-folder-tile-move-to-icon {
    display: block;
}

.signalo-folder-actions-menu {
    align-self: baseline;
}
</style>
