export const de = {
    common: {
        ok: 'Ok',
        authorizationRequiredDescriptionTerminal:
            'Verwenden Sie Ihren @:common.PIN_CODE_LABEL zur Autorisierung der Operation.',
        countersValues: 'Zählerwerte',
        countableId: 'Zählbares Feld-ID',
        defaultValuesForCategory: 'Standardwerte für die Kategorie',
        check_user: 'Ist der Benutzer',
        check_terminal: 'Ist das Terminal',
        bannedEmployees: 'Gesperrte Mitarbeiter',
        banEmployee: 'Zugang verbieten',
        banned: 'Gesperrt',
        employeeBans: 'Mitarbeiterverbote',
        commentAboutEmployee: 'Bezieht sich auf den Mitarbeiter',
        comments: 'Kommentare',
        visitLog: 'Besuchsprotokoll',
        categoryStructure: 'Kategorienstruktur',
        appVersionMismatch: 'App-Version stimmt nicht überein',
        appVersionMismatchMessage:
            'Aktualisieren Sie die App, um die Version zu aktualisieren.',
        PIN_CODE_LABEL: 'PIN-Code',
        comment: 'Kommentar',
        year: 'Jahr',
        configuration: 'Konfiguration',
        cancel: 'Abbrechen',
        search: 'Suche',
        remove: 'Entfernen',
        confirmationDialogTitle:
            'Sind Sie sicher, dass Sie diesen Artikel entfernen möchten?',
        selectDate: 'Datum auswählen',
        select: 'Auswählen',
        notFound: 'Nicht gefunden',
        equipmentPickerPlaceholder: 'Ausrüstungsobjekt',
        searchPlaceholder: 'Suche nach',
        equipmentTypePickerPlaceholder: 'Art der Ausrüstung',
        equipmentTypePickerTitle: 'Ausrüstungsart auswählen',
        emptyTableMessage: 'Keine Daten',
        flowPikcerTitle: 'Fluss auswählen',
        selectIcon: 'Symbol auswählen',
        locationPickerTitle: 'Ort auswählen',
        locationPlaceholder: 'Standort',
        noSublocations: 'Es gibt keine Sublokationen',
        locationsTitle: 'Standorte',
        edit: 'Bearbeiten',
        save: 'Speichern',
        added: 'Hinzugefügt',
        updated: 'Aktualisiert',
        add: 'Hinzufügen',
        reload: 'Neu laden',
        removed: 'Entfernt',
        confirm: 'Bestätigen',
        rollBack: 'Zurückrollen',
        images: 'Bilder',
        documents: 'Dokumente',
        download: 'Herunterladen',
        saved: 'Gespeichert',
        date: 'Datum',
        hour: 'Stunde',
        preview: 'Vorschau',
        data: 'Daten',
        attributes: 'Attribute',
        checkList: 'Checkliste',
        name: 'Name',
        type: 'Typ',
        status: 'Status',
        designation: 'Bezeichnung',
        symbol: 'Symbol',
        serialNumber: 'Seriennummer',
        registryNumber: 'Registrierungsnummer',
        machineNumber: 'Maschinennummer',
        udtNumber: 'UDT-Nummer',
        manufacturedYear: 'Jahr der Herstellung',
        manufacturer: 'Hersteller',
        supplier: 'Lieferant',
        mainSupplier: 'Hauptlieferant',
        attribute: 'Attribut',
        nameRequired: 'Name ist erforderlich',
        yearGreaterThan: 'Das Jahr muss größer sein als',
        location: 'Platz',
        fieldRequired: 'Dieses Feld ist erforderlich',
        description: 'Beschreibung',
        minimumQuantity: 'Mindestmenge',
        optimalQuantity: 'Optimale Menge',
        priority: 'Priorität',
        formatYYYY: 'Format YYYY',
        storageQuantity: 'Lagermenge',
        quantity: 'Menge',
        quantityGreaterThan: 'Die Menge muss größer sein als',
        machine: 'Maschine',
        tool: 'Werkzeug',
        order: 'Bestellung',
        required: 'Erforderlich',
        dateFormat: 'Datumsformat',
        selectDictionary: 'Wörterbuch auswählen',
        return: 'Rückgabe',
        parameters: 'Parameter',
        editCategory: 'Kategorie bearbeiten',
        addCategory: 'Neue Kategorie hinzufügen',
        changeColor: 'Farbe ändern',
        noColor: 'Keine Farbe',
        shift: 'Verschiebung',
        start: 'Start',
        end: 'Ende',
        shiftSchema: 'Schicht schema',
        shifts: 'Schichten',
        dictionary: 'Wörterbuch',
        subEntries: 'SubEntries',
        key: 'Schlüssel',
        value: 'Wert',
        moveUp: 'Aufwärts bewegen',
        moveDown: 'Abwärts bewegen',
        addLocation: 'Neuen Platz hinzufügen',
        editLocation: 'Platz bearbeiten',
        companiesDatabase: 'Firmenliste',
        flows: 'Durchfluss',
        read: 'Lesen',
        write: 'Schreiben',
        noImage: 'Kein Bild',
        email: 'E-mail',
        function: 'Funktion',
        active: 'Aktiv',
        notActive: 'Nicht aktiv',
        disabled: 'Deaktiviert',
        entryNumber: 'Eintragsnummer',
        issuedDate: 'Datum der Ausstellung',
        expiresDate: 'Verfallsdatum',
        move: 'Bewegen',
        separateForm: 'Getrennt von',
        separate: 'Getrennt',
        warehouse: 'Lagerung',
        actions: 'Aktionen',
        putInCurrentLocation: 'An den aktuellen Standort setzen',
        selectLocationFormRightMenu: 'Platz aus dem rechten Menü auswählen',
        showUnassignedItems: 'Nicht zugewiesene Posten anzeigen',
        separated: 'Getrennt',
        moved: 'Verschoben',
        categories: 'Kategorien',
        welcome: 'Willkommen',
        loginToSignaloTpm: 'Anmelden bei Signalo TPM',
        login: 'Anmeldung',
        loginByEmailAndPassword: 'Anmeldung per E-Mail und Passwort',
        loginByCode: 'Anmeldung nach @:common.PIN_CODE_LABEL',
        accessCode: 'Zugangscode',
        enterEmail: 'E-Mail eingeben',
        enterPassword: 'Passwort eingeben',
        enterCode: '@:common.PIN_CODE_LABEL eingeben',
        userNotFound: 'Benutzer nicht gefunden',
        userNotActivated: 'Benutzer nicht aktiviert',
        userDisabled: 'Benutzer deaktiviert',
        wrongPassword: 'Falsches Passwort',
        locations: 'Plätze',
        pageNotFound: 'Seite nicht gefunden',
        group: 'Gruppe',
        yes: 'Ja',
        no: 'Nein',
        hourlyCost: 'Stündliche Kosten',
        price: 'Preis',
        newEntry: 'Neuer Eintrag',
        predictedFinishTime: 'Voraussichtliche Zeit für die Fertigstellung',
        archive: 'Archiv',
        costs: 'Kosten',
        companyManagement: 'Unternehmensleitung',
        companyData: 'Unternehmensdaten',
        supportedLanguages: 'Unterstützte Sprachen',
        en: 'Englisch',
        pl: 'Polnisch',
        es: 'Spanisch',
        de: 'Deutsch',
        it: 'Italienisch',
        fr: 'Französisch',
        ru: 'Russisch',
        uk: 'Ukrainisch',
        pt: 'Portugiesisch',
        tr: 'Türkisch',
        nl: 'Niederländisch',
        sr: 'Serbisch',
        ar: 'Arabisch',
        he: 'Hebräisch',
        zh: 'Chinesisch',
        defaultLanguage: 'Standardsprache',
        companyLogo: 'Firmenlogo',
        notificationsManagement: 'Verwaltung von Benachrichtigungen',
        category: 'Kategorie',
        today: 'Heute',
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
        close: 'Schließen',
        show: 'Anzeigen',
        rollBackFormalConfirmation: 'Formelle Bestätigung zurücknehmen',
        signUp: 'Registrieren',
        nameSurname: 'Vor- und Nachname',
        phone: 'Telefonnummer',
        password: 'Passwort',
        password_confirmation: 'Passwort wiederholen',
        unassignedItems: 'Nicht zugewiesene Posten',
        currency: 'Währung',
        general: 'Allgemein',
        timezone: 'Zeitzone',
        users: 'Benutzer',
        groups: 'Gruppen',
        productionLine: 'Produktionslinie',
        part: 'Teil',
        fittingParts: 'Passende Ersatzteile',
        workSchema: 'Arbeitsschema',
        workSchemas: 'Arbeitsschemata',
        holiday: 'Ferien',
        changePassword: 'Passwort ändern',
        showRest: 'Pause anzeigen',
        pageNotPermitted: 'Seite nicht erlaubt',
        waitingForActivation: 'Warten auf Aktivierung',
        editGroup: 'Gruppe bearbeiten',
        addGroup: 'Gruppe hinzufügen',
        reportProblem: 'Problem melden',
        describeOccuredIssue: 'Beschreiben Sie das aufgetretene Problem',
        markAllAsRead: 'Alle als gelesen markieren',
        unitType: 'Art der Einheit',
        unit: 'Einheit',
        unitPrice: 'Preis pro Einheit',
        notConfigured: 'Nicht konfiguriert',
        rowsPerPage: 'Zeilen pro Seite',
        rowsOfPage: 'auf',
        true: 'Wahr',
        false: 'Falsch',
        export: 'Exportieren',
        chooseFileFormat: 'Wählen Sie das Dateiforma',
        exported: 'Exportiert',
        terminals: 'Terminals',
        terminal: 'Terminal',
        selectEquipment: 'Ausrüstung auswählen',
        futureIssues: 'Zukünftige Ausgaben',
        scheduledOn: 'Geplant am',
        planned: 'Geplant',
        notPlanned: 'Nicht geplant',
        showOpenIssues: 'Offene Ausgaben anzeigen',
        pin: 'PIN',
        assignedEquipment: 'Zugewiesene Ausrüstung',
        terminalEquipment: 'Endgeräte',
        detach: 'Detach',
        attach: 'Attach',
        dashboardConfig: 'Dashboard-Konfiguration',
        addColumn: 'Spalte hinzufügen',
        addRow: 'Eine Zeile hinzufügen',
        removeRow: 'Zeile entfernen',
        registerTerminal: 'Terminal registrieren',
        issueGeneratesCost: 'Ausfallzeiten verursachen Kosten',
        selectColor: 'Wählen Sie eine Farbe',
        belowMinimum: 'Unter dem Minimum',
        belowOptimum: 'Unterhalb des Optimums',
        synchronization: 'Synchronisation',
        synchronizationExport: 'Export von Lagerteilen',
        synchronizationImport: 'Import von Lagerteilen',
        mapping: 'Zuordnung',
        returnTo: 'Zurückkehren nach',
        jobStatusses: 'Prozesse',
        hidden: 'Versteckt',
        editEvent: 'Ereignis bearbeiten',
        threeDays: '3 Tage',
        addEvent: 'Ereignis hinzufügen',
        plannedIssues: 'Geplante Aufgaben',
        currentService: 'Aktueller Dienstl',
        searchLocation: 'Suchplatz',
        all: 'Alle',
        unfinished: 'Unvollendet',
        numberOfIssues: 'Anzahl der Ausgaben',
        unknown: 'Unbekannt',
        partEntry: 'Teil (Eintrag)',
        foundItems: 'Gefundene Gegenstände',
        output: 'Output',
        copied: 'Kopiert',
        link: 'Link',
        authorizationRequired: 'Autorisierung erforderlich',
        authorizationRequiredDescriptionUser:
            'Sie verfügen leider nicht über eine ausreichende Genehmigung. Bitte bitten Sie eine befugte Person, die Maßnahme zu genehmigen',
        authorizationRequiredTerminal:
            'Benutzen Sie Ihren @:common.PIN_CODE_LABEL , um den Vorgang zu autorisieren',
        showAll: 'Alles anzeigen',
        showClassicView: 'Klassische Ansicht anzeigen',
        externalSystems: 'Externe Systeme',
        noIssuesInCategory: 'Es gibt keine Probleme in dieser Kategorie.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Anzahl der Ausgaben in dieser Kategorie und Unterkategorien',
        numberOfIssuesInThisCategory:
            'Anzahl der Ausgaben in dieser Kategorie.',
        minBarcodeLength: 'Mindestlänge des Barcodes',
        currentPage: 'Aktuelle Seite',
        allPages: 'Alle Seiten',
        selectFormat: 'Format auswählen',
        provided_currency: 'Bereitgestellte Währung',
        provided_quantity: 'Bereitgestellte Menge',
        provided_unit: 'Bereitgestellte Einheit',
        provided_unit_price: 'Bereitgestellter Preis pro Einheit',
        unit_price: 'Einzelpreis',
        quantity_available: 'Verfügbare Menge',
        editBarcode: 'Barcode bearbeiten',
        crews: 'Ausrüstung',
        scheduleEventTypes: 'Geplante Ereignistypen',
        crew: 'Besatzung',
        scheduleEvent: 'Zeitplan-Ereignis',
        available: 'Verfügbar',
        'App\\Models\\Crew': 'Besatzung',
        'App\\Models\\User': 'Benutzer',
        'App\\Models\\Equipment': 'Ausrüstung',
        Crew: 'Besatzung',
        User: 'Benutzer',
        Equipment: 'Ausrüstung',
        scheduleEvents: 'Zeitplan für Ereignisse',
        event: 'Ereignis',
        user: 'Benutzer',
        availability: 'Verfügbarkeit',
        inheritColorFromParentIfCustomNotSet:
            'Erbt die Farbe vom übergeordneten Element, wenn keine eigene Farbe definiert wurde',
        pickFromAllParts: 'Aus allen Teilen auswählen',
        takeTool: 'Nimm das Werkzeug',
        returnTool: 'Rückholwerkzeug',
        personWhoTake: 'Person, die nimmt',
        personWhoReturn: 'Person, die zurückkehrt',
        takenAt: 'Nehmen Datum',
        takenBy: 'Sammler',
        toolIssuedBy: 'Disponent',
        commentTake: 'Kommentar beim Herunterladen',
        commentReturn: 'Kommentar zurückgeben',
        change: 'Ändern',
        onlyWithActiveRMIssues: 'Nur bei aktiven RM-Anforderungen',
        externalSystem: 'Externes System',
        rejectIssue: 'Ausgabe abgelehnt',
        withdrawIssueRejection: 'Abgelehnte Ausgabe zurückziehen',
        rejectionReason: 'Grund für die Ablehnung',
        rejected: 'Abgelehnt',
        showCrewMembers: 'Mitglieder der Besatzung',
        crewMembers: 'Besatzungsmitglieder',
        helpGroup: 'Dienst',
        partsTakenByUser: 'Vom Benutzer entnommene Teile',
        pickParts: 'Teileausbeute',
        selectPersonWhoTakes: 'Person auswählen, die nimmt',
        changePersonWhoTakes: 'Person ändern, die nimmt',
        quantityToPick: 'Zu wählende Menge',
        pickLocation: 'Abholplatz',
        selectPartsToPick: 'Wählen Sie die zu entnehmenden Teile aus',
        partSelected: 'Ausgewählter Teil',
        scanCodeOr: 'Code scannen oder',
        returnPart: 'ReturnPart',
        myTakenParts: 'Von mir heruntergeladene Teile',
        takenFromLocation: 'vom Platz genommen',
        takenQuantity: 'Entnommene Menge',
        providedUnit: 'Bereitgestellte Einheit',
        providedQuantity: 'Bereitgestellte Menge',
        selectFromStorage: 'Speicher auswählen',
        selectFromMyPickedParts: 'Wählen Sie aus meinen ausgewählten Teilen',
        exportProcessInProgressMessage:
            'Der Datenexport hat soeben begonnen. Wenn er abgeschlossen ist, erhalten Sie eine entsprechende Benachrichtigung mit einem Link zu der Datei',
        yourDocumentIsReady: 'Das Dokument ist fertig',
        rotateScreen: 'Bildschirm rotieren',
        addRule: 'Regel hinzufügen',
        editRule: 'Regel bearbeiten',
        summary: 'Zusammenfassung',
        onlyAvailableEmployees: 'Nur verfügbare Mitarbeiter zeigen',
        calculated_unit_price: 'Umgerechneter Preis pro Einheit',
        calculated_quantity: 'Umgerechnete Menge',
        next: 'Nächs',
        maintenanceMode: 'Wartungsmodus',
        applicationMayBeUnavailableForFewMinutes:
            'Die Anwendung kann für einige Minuten nicht verfügbar sein',
        appWillRefreshIn: 'Die Anwendung wird aktualisiert in',
        landscape: 'Querformat',
        portrait: 'Hochformat',
        orientation: 'Ausrichtung',
        format: 'Format',
        service: 'Bedienstete',
        leader: 'Vorarbeiter',
        defaultView: 'Standardansicht',
        view: 'Ansicht',
        oneDay: '1 Tag',
        object: 'Objekt',
        finished: 'Fertig',
        onlyNotFinished: 'Nur nicht fertig',
        generate: 'Generieren',
        error: 'Fehler',
        cell: 'Zelle',
        resolutionSummary: 'Ende Zusammenfassung',
        rejected_at: 'Ablehnungsdatum',
        rejected_by: 'Zurückgewiesen von',
        finishedByUser: 'Vervollständigt von',
        formalConfirmByUser: 'Formelle Bestätigung durch',
        formalConfirmAt: 'Datum der förmlichen Bestätigung',
        valueDeleted: '[Wert gelöscht]',
        hasNotPlannedIssue: 'Hat keine Ausgaben geplant',
        showInStorage: 'Im Speicher anzeigen',
        changeCode: 'Ändern Sie den @:common.PIN_CODE_LABEL',
        staffProfiles: 'Mitarbeiterprofile',
        profile: 'Profil',
        user_bug: 'Anwendungsfehler',
        user_kaizen: 'Änderungsvorschlag',
        changeSuggestions: 'Änderungsvorschläge',
        showOnlyCurrentlyAvailable: 'Nur aktuell verfügbar anzeigen',
        resetPassword: 'Passwort erinnern',
        forgotPassword: 'Passwort vergessen',
        resetPasswordMailSent:
            'Wir haben einen Link zum Setzen eines neuen Passworts an die angegebene E-Mail-Adresse gesendet',
        manyEventsThisTime: 'Viele Veranstaltungen in dieser Zeit',
        importScheduleCheckboxLabel:
            'Geplante Ereignisse im ausgewählten Monat für in der Datei aufgeführte Mitarbeiter löschen',
        inheritedEvent: 'Geerbtes Ereignis',
        inheritedModifiedEvent: 'Geändertes geerbtes Ereignis',
        equipmentId: 'Geräte ID',
        entryId: 'Eintrags ID',
        partId: 'Teil ID',
        suggestionsEmail: 'E-Mail, an die Änderungsvorschläge gesendet werden',
        meters: 'Zähler',
        scanCardToAuth:
            'Halten Sie die Karte an das Lesegerät oder geben Sie Ihren Zugangscode ein, um Ihre Identität zu bestätigen.',
        scanCard: 'Scannen Sie die Karte',
        cart: 'Austauschbox',
        emptyCardMessage:
            'Beginnen Sie mit dem Scannen von Produkten oder wählen Sie sie aus der Liste aus, um sie der Handelsbox hinzuzufügen.',
        terminalMode: 'Terminaltyp',
        askPermittedUserToConfirmPartsExchange:
            'Bitten Sie die autorisierte Person, die Freigabe des Teils zu bestätigen, indem Sie die Karte scannen oder den @:common.PIN_CODE_LABEL eingeben',
        partsIssued: 'Teile freigegeben',
        areYouSureAboutCancel:
            'Sind Sie sicher, dass Sie die Aktion abbrechen möchten?',
        areYouSureAboutRemoveFromCart:
            'Sind Sie sicher, dass Sie das Produkt aus dem Austauschbox entfernen möchten?',
        standard: 'Standard',
        idNumber: 'Identifikationsnummer',
        takeParts: 'Teile entnehmen',
        returnParts: 'Teile zurückgeben',
        quantityToReturn: 'Zurückzugebende Menge',
        partsPendingApproval: 'Teile in Bearbeitung',
        partsReturned: 'Teile zurückgegeben',
        partsAddedToPendingApproval: 'Teile zur Genehmigung hinzugefügt',
        approvePartsReturn: 'Genehmigen Sie die Rückgabe von Teilen',
        partReturnApproved: 'Teilrückgabe genehmigt',
        partUsage: 'Teilnutzung',
        awaitsReturn: 'Warten auf Rückgabe',
        entrySelection: 'Eintragsauswahl',
        correctQuantity: 'Menge korrigieren',
        quantityCorrection: 'Mengenkorrektur',
        entrySelectionAsc: 'Eintragsauswahl (aufsteigend)',
        entrySelectionDesc: 'Eintragsauswahl (absteigend)',
        metersSettings: 'Zähler Einstellungen',
        countableFields: 'Zählbare Felder',
        equipmentTypes: 'Gerätetypen',
        fieldName: 'Feldname',
        countableField: 'Zählbares Feld',
        partsStatusTaken: 'Teile entnommen',
        rejectPartsReturn: 'Teilrückgabe ablehnen',
        areYouSureAboutRejectPartsReturn:
            'Sind Sie sicher, dass Sie die Teilrückgabe ablehnen möchten?',
        partReturnRejected: 'Teilrückgabe abgelehnt',
        counterFields: 'Zählerfelder',
        counterName: 'Zählername',
        counterFieldName: 'Zählerfeldname',
        counterId: 'Zähler ID',
        counterUUID: 'Zähler UUID',
        counterPickerPlaceholder: 'Wählen Sie einen Zähler aus',
        pickFromPartsList: 'Wählen Sie aus der Teilliste aus',
        changeLocation: 'Standort ändern',
        isPartStorageRoot: 'Teil des Speichers',
        areYouSureAboutArchiveIssue:
            'Sind Sie sicher, dass Sie die Ausgabe archivieren möchten?',
        ganttChart: 'Gantt-Diagramm',
        table: 'Tabelle',
        fittingEquipment: 'Passendes Gerät',
        notAssigned: 'Nicht zugewiesen',
        counters: 'Zähler',
        machineHourlyCostNotProvided:
            'Der stündliche Maschinenkostensatz wurde nicht angegeben',
        eventTypesMightBeDefinedHere:
            'Ereignistypen können hier definiert werden',
        toolsTakenByUser: 'Werkzeuge vom Benutzer entnommen',
        predictedReturnDate: 'Voraussichtliches Rückgabedatum',
        estimatedTimeOfUsingToolInHours:
            'Geschätzte Nutzungsdauer des Werkzeugs (in Stunden)',
        showOnlyAvailable: 'Nur verfügbare anzeigen',
        toolTakeLog: 'Werkzeugentnahme-Protokoll',
        takeDate: 'Entnahmedatum',
        returnDate: 'Rückgabedatum',
        personWhoAcceptReturn: 'Person, die die Rückgabe akzeptiert',
        statisticStrategy: 'Barrierefreiheitsstrategie',
        EquipmentPlannedStrategy: 'Laut Arbeitsplan',
        EquipmentConstantAvailabilityStrategy: 'Immer verfügbar',
        actionAfterIssueFinish: 'Aktion nach Abschluss der Ausgabe',
        enabled: 'Aktiviert',
        categoryOfNewIssue: 'Kategorie der neuen Ausgabe',
        workNotStarted: 'Arbeit nicht begonnen',
        colorInherited: 'Farbe geerbt',
        abcClassification: 'ABC-Klassifizierung',
        xyzClassification: 'XYZ-Klassifizierung',
        classification: 'Klassifizierung',
        filters: 'Filter',
        wipeFilters: 'Filter löschen',
        takePartsFromUser: 'Teile vom Benutzer entnehmen',
        selectPersonWhoReturns:
            'Wählen Sie die Person aus, die die Teile zurückgibt',
        changePersonWhoReturns:
            'Ändern Sie die Person, die die Teile zurückgibt',
        returnLocation: 'Rückgabeort',
        returnQuantity: 'Rückgabemenge',
        globalSettings: 'Globale Einstellungen',
        delimiter: 'Trennzeichen',
        fieldRules: 'Feldregeln',
        fieldValue: 'Feldwert',
        user_email_regex: 'E-Mail-Regex',
        barcodeLength: 'Barcode-Länge',
        partLine: 'Teilzeile',
        partFamily: 'Teilfamilie',
        partBatch: 'Teilcharge',
        testValueValid: 'Testwert gültig',
        testValueNotValid: 'Testwert ungültig',
        canBePicked: 'Kann gewählt werden',
        issue_create_photos: 'Hinzufügen von Fotos zu einer neuen Einreichung',
        issue_create_documents: 'Dokumente zu einer neuen Bewerbung hinzufügen',
        propagateValues: 'Wertverbreitung',
        archived: 'Archiviert',
        escalations_tab: 'Eskalationen',
        minute: 'Minute',
        days: 'Tage',
        hours: 'Stunden',
        minutes: 'Minuten',
        warnDays: 'Benachrichtigung vor Ablauf (Tage)',
        licenseType: 'Lizenz-Typ',
        licenseGroup: 'Lizenzgruppe',
        attachments: 'Anhänge',
        attachment: 'Anhang',
        revisionChanges: 'Änderungen eingeführt',
        document: 'Dokumentieren',
        noInstructionSelected: 'Keine Anweisung ausgewählt',
        instructionGroups: 'Gruppen von Anweisungen',
        instructionGroup: 'Instruktionsgruppe',
        addOption: 'Fügen Sie eine Option hinzu',
        ordinal: 'Ordnungswert',
        parameterName: 'Parametername',
        fontColor: 'Schriftfarbe',
        backgroundColor: 'Hintergrundfarbe',
        columns: 'Anzahl der Spalten',
        colspan: 'Breite (Spalten)',
        products: 'Produkte',
        controlsTemplates: 'Kontrollvorlagen',
        controlType: 'Kontrolltyp',
        controls: 'Inspektionen',
        controlTemplate: 'Kontrollvorlage',
        product: 'Produkt',
        checklistsAttachedToControlTemplate:
            'Den Inspektionen zugeordnete Checklisten',
        removeChecklistFromControlTitle:
            'Sind Sie sicher, dass Sie diese Liste aus Ihrer Kontrolle entfernen möchten?',
        attachChecklistToControl: 'Weisen Sie eine Liste zur Steuerung zu',
        pickedItem: 'Ausgewähltes Objekt',
        pick: 'Wählen',
        fillControl: 'Schließen Sie die Prüfung ab',
        back: 'Komm zurück',
        control: 'Kontrolle',
        duplicate: 'Duplikat',
        isStorageRoot: 'Hauptstandort des Lagerhauses',
        manageStorageAccess: 'Verwalten Sie den Zugang zu Lagern',
        manageVisibleColumns: 'Passen Sie die Sichtbarkeit der Spalte an',
        columnName: 'Spaltenname',
        visible: 'Sichtbar',
        entriesLocation: 'Ort der Einträge',
        details: 'Einzelheiten',
        otherData: 'Andere Daten',
        moveAllEntriesFromCurrentLocation:
            'Alle Einträge von diesem Ort verschieben',
        searchInCurrentLocation: 'Suchen Sie an Ihrem aktuellen Standort',
        transition: 'Übergang',
        notification: 'Benachrichtigung',
        emails: 'E-mailadressen',
        phones: 'Telefonnummern',
        addAction: 'Fügen Sie eine Aktion hinzu',
        message: 'Nachricht',
        application: 'App',
        always: 'Stets',
        sms: 'SMS',
        loginTo: 'Einloggen in',
        appName: 'Signalo TPM',
        index: 'Index',
        showCodeOwnerNameDuringParing:
            'Beim Koppeln mit Karte/Tag den Namen des @:common.PIN_CODE_LABEL anzeigen',
        showUsernameInCart: 'Benutzernamen im Warenkorb anzeigen',
        buttonsSize: 'Knopfgröße',
        sampleButtonPlaceholder: 'Beispielschaltfläche',
        preferences: 'Präferenzen',
        erase: 'löschen',
        selectDictionaryValue: 'Wörterbuchwert auswählen',
        conditions: 'Bedingungen',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Bei der Rücksendung heruntergeladener Teile ist eine Bestätigung oder Ablehnung erforderlich',
        issueCreated: 'Ein Ticket wurde erstellt',
        allowedIssuesCategories: 'Zulässige Berichtskategorien',
        hiddenFields: 'Versteckte Felder',
        defaultValues: 'Standardwerte',
        paddingBottom: 'Leerer Raum unten',
        allVisibleFieldsRequired:
            'Alle sichtbaren Felder müssen ausgefüllt sein',
        url: 'URL',
        kind: 'Art',
        forkfleetConfigId: 'Forkfleet Konfiguration',
        create: 'Erstellen',
        terminate: 'Beenden',
        parent: 'Elternteil',
        phoneNumbers: 'Telefonnummern',
        storageDefaults: 'Standardspeicherwerte',
        clientSignatureEnabled: 'Unterschrift der sammelnden Person',
        warehousemanSignatureEnabled: 'Unterschrift des Lageristen',
        storage_client_signature_enabled:
            'Unterschrift der Person, die Artikel aus dem Lager entnimmt',
        storage_client_signature_required:
            'Erfordern Sie die Unterschrift der Person, die Artikel aus dem Lager entnimmt',
        storage_warehouseman_signature_enabled:
            'Unterschrift der Person, die die Artikel aus dem Lager freigibt',
        storage_warehouseman_signature_required:
            'Erfordern die Unterschrift der Person, die die Artikel aus dem Lager freigibt',
        clientReturnSignatureEnabled: 'Unterschrift der zurückkehrenden Person',
        warehousemanReturnSignatureEnabled:
            'Unterschrift der Person, die die Rücksendung entgegennimmt',
        storage_client_return_signature_enabled:
            'Unterschrift der Person, die die Artikel zurücksendet',
        storage_client_return_signature_required:
            'Verlangen Sie eine Unterschrift der Person, die die Artikel zurücksendet',
        storage_warehouseman_return_signature_enabled:
            'Unterschrift der Person, die die Rücksendung des Artikels akzeptiert',
        storage_warehouseman_return_signature_required:
            'Erfordern die Unterschrift der Person, die die Rücksendung des Artikels akzeptiert',
        channels: 'Kanäle',
        manageState: 'Status verwalten',
        assignWorker: 'Weisen Sie einen Mitarbeiter zu',
        mergeEntriesOfTheSameLocation:
            'Einträge vom gleichen Ort zusammenführen',
        permission: 'Erlaubnis',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'Sind Sie sicher, dass Sie alle Einträge an diesem Ort zusammenführen möchten?',
        manageFilters: 'Filter verwalten',
        responsible_assign_creator_when_no_rule:
            '[Berichte] – Weisen Sie den Ersteller als verantwortlich zu, wenn die Regel nicht übereinstimmt',
        recreateIssue: 'Erstellen Sie das Ticket neu',
        addNewDictionary: 'Fügen Sie ein neues Wörterbuch hinzu',
        label: 'Etikett',
        issuesWithRelatedCategories: 'Unterkategorien einschließen',
        issuesLimit: 'Begrenzung der Probleme',
        issuesLimits: 'Probleme – Grenzen',
        issueDefaults: 'Berichte – Standardwerte',
        flatCategoryPickerLabel: 'Flache Kategorienstruktur',
        statementsTemplates: 'Erklärungsvorlagen',
        statementTemplate: 'Erklärungsvorlage',
        content: 'Inhalt',
        readonly: 'Schreibgeschützt',
        defaultValue: 'Standardwert',
        fillParametersToChangeState:
            'Geben Sie die Werte für die Parameter unten ein, um den Status zu ändern',
        formatPattern: 'Formatmuster',
        countableIncFields: 'Konfiguration der Dokumentnummerierung',
        mainShift: 'Hauptschicht',
        singleEvent: 'Einzeleintritt',
        seriesOfEvents: 'Eine Reihe von Einträgen',
        omitWeekends: 'Vermeiden Sie Wochenenden',
        allEntriesSaved: 'Alle Einträge wurden hinzugefügt',
        surveyResults: 'Umfrageergebnisse',
        qrActionsSettings: 'Einstellungen für QR-Code-Aktionen',
        pendingStatements: 'Erklärungen zur Unterzeichnung',
        statementsResults: 'Aussagen',
        statements: 'Aussagen',
        statement: 'Stellungnahme',
        userCanChangeContent: 'Der Benutzer kann den Inhalt ändern',
        statementsEvents: 'Ereigniskonfiguration – Anweisungen',
        helpdesk: 'Beratungsstell',
        terminalId: 'Terminal-ID',
        operatorId: 'Betreiber-ID',
        newConversation: 'Neues Thema',
        formula: 'Formel',
        fill: 'Vollständig',
        webMessage: 'Web-Nachricht',
        noResponsibleUser: 'Kein Verantwortlicher',
        filledAtDate: 'Datum der Fertigstellung',
        filledBy: 'Vervollständigt von',
        parts_quantity_alert_rule:
            '[Anzahl der Teile und Materialien] – Warnung',
        employeeNumber: 'Mitarbeiternummer',
        groupsAssignedToCategory: 'Gruppen, die Kategorien zugeordnet sind',
        number: 'Nummer',
        customers: 'Kunden',
        customer: 'Kunde',
        projects: 'Projekte',
        project: 'Projekt',
        report: 'Bericht',
        reportGenerationInProgress: 'Der Bericht wird generiert',
        storageStatementGroup: 'Lagerdeklarationsgruppe',
        simplifiedHandling: 'Vereinfachte Benachrichtigungsverarbeitung',
        selectWorkArea: 'Wählen Sie Ihren Arbeitsbereich',
        workAreaSelection: 'Auswählen eines Arbeitsbereichs',
        access: 'Zugang',
        restrictAccess: 'Zugriff einschränken',
        anchor: 'Verankerung',
        issueCreator: 'Die Person, die den Bericht erstellt',
        hideSideMenu: 'Seitenmenü ausblenden',
        appendGroups: 'Gruppen zum Hinzufügen',
        revokeGroups: 'Zu entfernende Gruppen',
        override: 'Überschreiben',
        restrictGroupAccess: 'Beschränken Sie den Zugriff auf Gruppen',
        flag: 'Flagge',
        flags: 'Flaggen',
        manageFlags: 'Flaggen verwalten',
        icon: 'Icon',
        appendFlags: 'Flags zum Hinzufügen',
        revokeFlags: 'Flaggen müssen entfernt werden',
        checkIsTerminal: 'Überprüfung des Terminals',
        noConditionsDefined: 'Keine Bedingungen definiert',
        checkIsResponsible: 'Verantwortungsprüfung',
        createdOn: 'Berichtet von',
        replaceTimespan: 'Haltbarkeit ab Ausstellungszeitpunkt',
        months: 'Monate',
        handoversTemplates: 'Ausgabevorlagen',
        handoverTemplate: 'Publisher-Vorlage',
        employeeFunction: 'Mitarbeiterfunktion',
        takeHandover: 'Machen Sie ein Thema',
        handovers: 'Ausgaben',
        expiringHandovers: 'Artikel, deren Verfallsdatum bald abgelaufen ist',
        years: 'Jahre',
        taken_parts_expiry_warning:
            'Warnung vor dem Ablaufdatum heruntergeladener Artikel',
        andonView: 'Ansicht von Andon',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'Andere',
        tags: 'Karten / Stichworte',
        tag: 'Karte / Etikett',
        userPinCode: 'Kartenbenutzer @:common.PIN_CODE_LABEL',
        emailConfirmation: 'Bestätigung der E-Mail-Adresse',
        from: 'Aus',
        to: 'Zu',
        handoversHistory: 'Übergabegeschichte',
        partUsagesHistory: 'Teilverwendungsverlauf',
        signaturePinTagConfirmationText1:
            'Mit @:common.PIN_CODE_LABEL unterschreiben',
        signaturePinTagConfirmationText2: 'oder scannen Sie Ihre Karte.',
        signedWithPinCode: 'mit @:common.PIN_CODE_LABEL signiert',
        signedWithTAG: 'signiert mit Karte/Tag',
        test_mode: 'Testmodus',
        confirmWorkFinish: 'Bestätigen Sie den Abschluss der Arbeiten',
        workStarted: 'Arbeitsbeginn',
        workFinished: 'Arbeitsende',
        workTimeRegister: 'Arbeitszeitregister',
        measureTypes: 'Arten von Messungen',
        measureType: 'Messart',
        measurable: 'Messbarer Indikator',
        measurables: 'Messbare Indikatoren',
        measurements: 'Messung',
        measurement: 'Messung',
        measuredAt: 'Messdatum',
        currentVersion: 'Aktuelle Version',
        employee: 'Mitarbeiter',
        pin_code_label: 'PIN-Name',
        orders: 'Aufträge',
        productionPlan: 'Produktionsplan',
        productionPlans: 'Produktionspläne',
        taktTime: 'Taktzeit',
        target: 'Zielsetzung',
        seconds: 'Sekunden',
        inheritValuesFromTenantSettings:
            'Standardwerte aus globalen Einstellungen erben',
        matrices: 'Matrizen',
        manageMatrice: 'Verwalten Sie Ihre Matrix',
        deletionOfMatrixColumnWarnMessage:
            'Durch das Löschen einer Spalte wird der Wert gelöscht.',
        removeColumn: 'Eine Spalte löschen',
        editColumn: 'Spalte bearbeiten',
        column: 'Spalte',
        row: 'Reihe',
        evaluated: 'Berechnet',
        of: 'Mit',
        auto_pin_generation:
            '@:common.PIN_CODE_LABEL - Automatische Generierung',
        or: 'oder',
        rename: 'Umbenennen',
        check_only_on_transition: 'Prüfen Sie nur, wenn sich der Status ändert',
        license: 'Befugnisse',
        confirmWorkStart: 'Arbeitsbeginn bestätigen',
        workStartAt: 'Sie beginnen mit der Arbeit um',
        workFinishAt: 'Du beendest deine Arbeit o',
        workStartedAt: 'Erste Schritte',
        workTimeMessage: 'Du hast überarbeitet',
        haveANiceDay: 'Einen schönen Tag noch!',
        farewellText: 'Text auf dem letzten Bildschirm',
        namedayLabel: 'Sie feiern ihren Namenstag',
        sportEventNearby: 'Das nächste Sportereignis',
        autoTranslate: 'Automatische Übersetzung',
        auto_translation: 'Automatische Übersetzung',
        awaitingAutoTranslation: 'Warten auf automatische Übersetzung',
        inheritAllDefaultValues:
            'Erben Sie alle Standardwerte aus den globalen Einstellungen',
        escalations: 'Eskalationen',
        escalation: 'Eskalation',
        notTaken: 'Nicht angefangen',
        notFinished: 'Unvollendet',
        issueLastChange: 'Letzte Bearbeitung',
        mode: 'Modus',
        repeatLastEscalation: 'Wiederholen Sie den letzten Eskalationswert',
        manageActions: 'Verwalten Sie Ihre Aktionen',
        productionLines: 'Fertigungslinien',
        productionTerminal: 'Produktionsterminal',
        currently: 'Momentan',
        currentPlan: 'Derzeitiger Plan',
        currentlyInProduction: 'Derzeit produziert',
        setProduct: 'Produkt einstellen',
        second: 'Sekunden',
        work: 'Arbeiten',
        chartConfiguration: 'Diagrammkonfiguration',
        revisionsToConsider: 'Überarbeitungen zur Prüfung',
        allInstructions: 'Alle Anweisungen',
        decider: 'Bestimmender',
        considerRevision: 'Erwägen Sie eine Überarbeitung',
        and: 'Und',
        acceptInstruction: 'Akzeptieren Sie die Anweisungen',
        accept: 'akzeptieren',
        instructionsToAccept: 'Anleitung zum Lesen',
        open: 'Offen',
        assignedAt: 'Datum der Zuweisung',
        confirmationAt: 'Bestätigungsdatum',
        mainInstruction: 'Standardanweisung',
        setAsDefault: 'Als Standard einstellen',
        instructionsFlows: 'Anweisungen – Abläufe',
        newRevision: 'Neue Überarbeitung',
        playlist: 'Videoliste',
        selectVideo: 'Wählen Sie einen Film aus',
        selectVideos: 'Filme auswählen',
        uploadNewVideo: 'Neues Video hochladen',
        upload_date: 'Datum hinzugefügt',
        thumbnail: 'Miniaturansicht',
        duration: 'Dauer',
        uploadFromDrive: 'Von der Festplatte hochladen',
        selectAssets: 'Wählen Sie Materialien',
        assets: 'Materialien',
        video: 'Film',
        doYouWantToAddNewMarker: 'Möchten Sie hier einen neuen Tag hinzufügen?',
        areYouSureAboutRemoveMarker:
            'Möchten Sie dieses Tag wirklich entfernen?',
        plants: 'Fabrikanlagen',
        plant: 'Fabrik',
        buildings: 'Gebäude',
        building: 'Gebäude',
        floors: 'Böden',
        floor: 'Boden',
        map: 'Karte',
        areYouSureAboutMoveMarker:
            'Sind Sie sicher, dass Sie diese Markierung verschieben möchten?',
        marker: 'Marker',
        addNewMarkerTooltipMessage:
            'Um eine neue Markierung hinzuzufügen, klicken Sie mit der rechten Maustaste auf die Karte.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Nichtlineare Fähigkeiten',
        skillLevelConfig: 'Fähigkeitsstufen',
        indirectSkillGroups: 'Nichtlineare Kompetenzgruppen',
        approved: 'Bestätigt',
        approvedBy: 'Genehmiger',
        skillLevel: 'Fähigkeits Level',
        requiredSkillLevel: 'Erforderliches Maß an Fähigkeiten',
        step: 'Schritt',
        image: 'Foto',
        skills: 'Fähigkeiten',
        visits: 'Besuche',
        responsiblePerson: 'Verantwortliche',
        company: 'Geschäft',
        contactData: 'Kontaktdetails',
        visit: 'Besuchen',
        inProgress: 'Während',
        gatehouse: 'Pförtnerhaus',
        confirmEnter: 'Eingabe bestätigen',
        confirmExit: 'Bestätigen Sie das Beenden',
        enter: 'Eintrag',
        exit: 'Ausfahrt',
        entryGuard: 'Die Person, die hereinkommt',
        exitGuard: 'Person freilassen',
        loginAsThisUser: 'Melden Sie sich als dieser Benutzer an',
        minBrowserVersion: 'Mindestbrowserversion erforderlich',
        approve: 'Bestätigen',
        cancelApproval: 'Commit abbrechen',
        workPermit: 'Arbeitserlaubnis',
        workPermits: 'Arbeitserlaubnisse',
        visit_summary: 'Zusammenfassung des Besuchs',
        createVisit:
            'Erstellen Sie einen Besuch auf der Grundlage einer Genehmigung',
        employeesAtThePlant: 'Arbeiter im Werk',
        purpose: 'Ziel',
        allowedAreas: 'Zugelassene Bereiche',
        switchEmployees: 'Mitarbeiter ersetzen',
        localIssues: 'Lokale Berichterstattung',
        cmmsCategoriesSync: 'Synchronisierung von CMMS-Kategorien',
        terminalsGroups: 'Terminalgruppen',
        isTree: 'Baum',
        isReporter: 'Ist der Reporter',
        isAnchored: 'Ist verankert',
        closable: 'Schlusskarten',
        areYouSureAboutCloseIssue: 'Möchten Sie das Ticket wirklich schließen?',
        simplifiedIssuesRefreshLabel:
            'Aktualisierungszeit der Liste – Sekunden',
        reloadApp: 'Neu laden',
        hideWorkAreasButton: 'Arbeitsbereich-Auswahlknopf ausblenden',
        hideClassicViewButton: 'Klassische Ansicht-Schaltfläche ausblenden',
        hideAddIssueButton:
            'Schaltfläche zum Hinzufügen eines neuen Problems ausblenden',
        filesManager: 'Dateimanager',
        addFolder: 'Ordner hinzufügen',
        upload: 'Hochladen',
        noFolders: 'Keine Ordner',
        noFiles: 'Keine Dateien',
        folders: 'Ordner',
        folder: 'Ordner',
        files: 'Dateien',
        file: 'Datei',
        removeFolderFilesQuestion:
            'Was soll mit den Dateien in diesem Ordner geschehen?',
        targetFolder: 'Zielordner',
        addInstruction: 'Anweisung hinzufügen',
        goToFolder: 'Zum Ordner gehen',
        defaultProdLine: 'Standard-Produktionslinie',
        pin_code_field_type: 'PIN-Code-Typ',
        instructionsAccess: 'Berechtigungen',
        noSections: 'Keine Abschnitte',
        requireReadingInstruction: 'Lesen der Anweisung erforderlich',
        hide_parameter_types: 'Parameterarten nicht anzeigen',
        unknownVersion: 'Unbekannt',
        displayInstructionEveryTime: 'Anweisung jedes Mal anzeigen',
        requireSigningInstructionEveryTime:
            'Unterschrift der Anweisung jedes Mal erforderlich',
        signedInstructions: 'Unterschriebene Anweisungen',
        signedAt: 'Unterschriftsdatum',
        signatures: 'Unterschriften',
        signature: 'Unterschrift',
        systemGroup: 'Systemgruppe',
        pickable: 'Wählbar',
        leaf: 'Ausgewähltes Element',
        path: 'Pfad',
        displayMode: 'Anzeigemodus',
        chart: 'Diagramm',
        preSignedStatement: 'Auf Papier unterzeichnete Erklärung (vollständig)',
        myOpenIssues: 'Meine offenen Probleme',
        myAssignedIssues: 'Mir zugewiesene Probleme',
        displayParametersOnMainScreen:
            'Parameter auf dem Hauptbildschirm anzeigen',
        lockPin: 'PIN-Änderung sperren',
        pinLocked: 'PIN gesperrt',
        sortChange: 'Anzeigereihenfolge',
        asc: 'Aufsteigend',
        desc: 'Absteigend',
        alphanumeric: 'Alphanumerisch',
        numeric: 'Numerisch',
        modificationDate: 'Änderungsdatum',
        fromNewest: 'Von den neuesten',
        fromOldest: 'Von den ältesten',
        negation: 'Negation',
        sortOrderService: 'Service',
        newIssueIgnoreFilters:
            'Filter beim Erstellen eines neuen Tickets ignorieren',
        refreshInterval: 'Aktualisierungszeit der Liste',
        multiline: 'Mehrzeilig',
        expanded: 'Erweitert',
        quizzes: 'Quizze',
        courses: 'Kurse',
        course: 'Kurs',
        quiz: 'Quiz',
        maxPoints: 'Maximale Punktzahl',
        minPointsToPass: 'Mindestpunktzahl zum Bestehen',
        questionFailQuiz:
            'Ein falsches Frageantwort disqualifiziert den gesamten Quiz',
        points: 'Punkte',
        currentVersionPreview: 'Vorschau der aktuellen Version',
        docs_sidebar_hidden_by_default:
            'Seitenleiste in Dokumenten standardmäßig ausblenden',
        allCategories: 'Alle Kategorien',
        test: 'Test',
        tests: 'Tests',
        copy: 'Kopie',
        trusted_device_code_login_users_limit: "Schnellanmeldung – Anzahl der angezeigten Benutzer"
    },
    placeholders: {
        part: 'Wählen Sie ein Teil',
        nameSurname: 'Vorname und Nachname',
        phone: 'Telefonnummer',
        password: 'Passwort',
        password_confirmation: 'Passwort wiederholen',
        email: 'E-Mail',
    },
    auth: {
        notYourAccountQuestion: 'Nicht Ihr Konto?',
    },
    signup: {
        registered: 'Registriert!',
        emailConfirmed: 'Die E-Mail wurde bestätigt.',
        registered_info:
            'Bitten Sie Ihren Vorgesetzten, Ihr Konto zu aktivieren',
        confirmEmailAddress:
            'Zwei Benutzer haben ein Konto erstellt, klicken Sie auf den Link mit Ihrer E-Mail-Adresse und geben Sie die gewünschte Adresse ein.',
        tokenNotFoundError:
            'Die E-Mail wurde bereits bestätigt oder der Bestätigungslink ist ungültig.',
    },
    paramTypes: {
        text: 'Text',
        note: 'Notiz',
        integer: 'Ganze Zahl',
        decimal: 'Dezimalzahl',
        date: 'Datum',
        time: 'Uhrzeit',
        datetime: 'Datum und Uhrzeit',
        color: 'Farbe',
        icon: 'Symbol',
        boolean: 'Nein / Ja',
        dictionary: 'Wörterbuch',
        user: 'Benutzer',
        radioGroup: 'Eine Auswahl',
        checkboxGroup: 'Mehrere Auswahlmöglichkeiten',
        signature: 'Unterschrift',
        number: 'Zahl',
        rating: 'Bewertung',
        document: 'Dokument',
        image: 'Bild',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Name',
        path: 'Pfad zur Datei',
        category: 'Kategorie',
        equipment: 'Ausrüstung',
        criticality: 'Kritikalität',
        status: 'Status',
        created: 'Erstellt',
        createdBy: 'Erstellt von',
        fp: 'FP',
        type: 'Typ',
        designation: 'Bezeichnung',
        symbol: 'Symbol',
        serialNumber: 'SerialNumber',
        registryNumber: 'Registrierungsnummer',
        udtNumber: 'UDT-Nummer',
        manufacturedYear: 'Jahr der Herstellung',
        actions: 'Aktionen',
        title: 'Titel',
        data: 'Datum',
        archived: 'archiviert',
        manufacturerSymbol: 'Symbol des Herstellers',
        min: 'Min',
        opt: 'Opt',
        quantity: 'Menge',
        priority: 'Priorität',
        price: 'Preis',
        kind: 'Art',
        description: 'Beschreibung',
        default: 'Standard',
        warning: 'Warnung',
        problem: 'Problem',
        fixing: 'Lösen',
        changeOrder: 'Änderungsauftrag',
        required: 'Erforderlich',
        icon: 'Icon',
        color: 'Farbe',
        subCategories: 'Unterkategorien',
        barCode: 'Strichcode',
        subLocations: 'SubLocations',
        categories: 'Kategorien',
        image: 'Bilder',
        nameSurname: 'Vor- und Nachname',
        email: 'E-Mail',
        phone: 'Telefonnummer',
        function: 'Funktion',
        department: 'Abteilung',
        accountStatus: 'Kontostatus',
        shift: 'Schicht',
        licenseNumber: 'Lizenznummer',
        issuedDate: 'Ausgabedatum',
        expiresDate: 'Auslaufdatum',
        entries: 'Einträge',
        entryNumber: 'Eintragsnummer',
        buyPrice: 'Kaufpreis',
        currentLocationQuantity: 'Menge am aktuellen Standort',
        group: 'Gruppe',
        string: 'Text',
        integer: 'Ganzzahl',
        toolsGroup: 'Werkzeuggruppe',
        manufacturer: 'Hersteller',
        shortName: 'Kurzname',
        locationName: 'Ortsname',
        subLocationsQuantity: 'Menge der Unterstandorte',
        isWorkingOnIssue: 'Arbeitet an einer Ausgabe',
        openIssues: 'Offene Fragen',
        notificationDate: 'Datum der Benachrichtigung',
        titleAndDescription: 'Titel und Beschreibung',
        read: 'Gelesen',
        created_at: 'Erstellt',
        received_at: 'Empfangen',
        started_at: 'Angefangen',
        arrived_at: 'Angekommen',
        finished_at: 'Beendet',
        country: 'Land',
        city: 'Stadt',
        progress: 'Fortschritt',
        deleted_at: 'Datum löschen',
        about_issue: 'Gilt für den Bericht',
        action: 'Aktie',
    },
    searchConfig: {
        id: 'Id',
        name: 'Name',
        shortName: 'Kurzname',
        country: 'Land',
        email: 'E-Mail',
        group: 'Gruppe',
        planned: 'Geplant',
        category: 'Kategorie',
        object: 'Objekt',
        criticality: 'Kritikalität',
        fp: 'FP',
        type: 'Typ',
        designation: 'Bezeichnung',
        symbol: 'Symbol',
        serialNumber: 'Seriennummer',
        registryNumber: 'Registriernummer',
        udtNumber: 'UDT-Nummer',
        manufacturedYear: 'Herstellungsjahr',
        manufacturerSymbol: 'Herstellerkennzeichen',
        priority: 'Priorität',
        quantity: 'Menge',
        buyPrice: 'Einkaufspreis',
        kind: 'Art',
        toolsGroup: 'Werkzeuggruppe',
        function: 'Funktion',
        department: 'Abteilung',
        status: 'Status',
        nameSurname: 'Vorname und Nachname',
        phone: 'Telefon',
        barCode: 'Strichcode',
        color: 'Farbe',
        icon: 'Symbol',
        machine: 'Maschine',
        tool: 'Werkzeug',
        urgentAndOutdated: 'Dringend und veraltet',
        urgent: 'Dringend',
        expired: 'Abgelaufen',
        manufacturer: 'Hersteller',
    },
    errors: {
        partMustHaveEntriesToCorrectQuantity:
            'Das Teil muss über Einträge verfügen, um den Zustand zu korrigieren.',
        error: 'Ein Fehler ist aufgetreten',
        wrongEmail: 'Ungültige E-Mail',
        wrongPasswordLength:
            'Das Passwort muss mindestens 8 Zeichen lang sein.',
        wrongCodeLength:
            'Der @:common.PIN_CODE_LABEL muss mindestens 5 Zeichen lang sein.',
        passwordsNotMatch: 'Passwörter stimmen nicht überein',
        codesNotMatch: '@:common.PIN_CODE_LABEL stimmen nicht überein',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL wird bereits verwendet',
        expiresDateLowerThanIssued:
            'Das Ablaufdatum kann nicht niedriger als das Ausgabedatum sein.',
        cannotMoveToTheSameLocation:
            'Kann nicht an denselben Standort verschoben werden.',
        locationRequired: 'Standort erforderlich.',
        putQuantity: 'Menge zur Trennung angeben.',
        valueGreaterThanQuantity: 'Der Wert ist größer als die Gesamtmenge.',
        wholeCannotBeDistinguished: 'Ganzes kann nicht unterschieden werden.',
        yearMustBeGreaterThan: 'Das Jahr muss größer sein als ',
        passwordConfirmationNotMatch: 'Passwörter stimmen nicht überein',
        mustBeInteger: 'Wert muss eine Ganzzahl sein',
        valueTooLong: 'Wert zu lang',
        wrongPhoneNumber: 'Falsche Telefonnummer',
        phoneNumberAlreadyTaken: 'Telefonnummer bereits vergeben',
        emailAlreadyTaken: 'E-Mail bereits vergeben',
        notFound: 'Nicht gefunden',
        sessionExpired: 'Sitzung abgelaufen',
        tenantNotFound:
            'Die angegebene Konfiguration existiert nicht. Überprüfen Sie die Website-Adresse und versuchen Sie es erneut.',
        selectEquipmentTypeToConfigureParameters:
            'Wählen Sie den Gerätetyp zur Konfiguration der Parameter aus.',
        noParametersToConfigure:
            'Der ausgewählte Gerätetyp hat keine zu konfigurierenden Parameter.',
        terminalAlreadyRegistered: 'Terminal bereits registriert.',
        invalidPin: 'Ungültiger @:common.PIN_CODE_LABEL',
        eventCollision:
            'Die Zeit des von Ihnen hinzuzufügenden Ereignisses überschneidet sich mit der Zeit eines anderen Ereignisses.',
        mustStartWithSlash: 'Muss mit / beginnen',
        mustBeBetween: 'Wert muss zwischen liegen:',
        minLength: 'Minimale Länge: ',
        maxLength: 'Maximale Länge: ',
        invalidInputForCodeType:
            'Ungültige Eingabe für den ausgewählten Codetyp',
        invalidCodeOrNoPermission:
            'Der @:common.PIN_CODE_LABEL ist ungültig oder Sie haben nicht die erforderlichen Berechtigungen, um diese Aktion auszuführen.',
        endDateLowerThanStart:
            'Das Enddatum darf nicht früher als das Startdatum sein',
        iconAlreadyTaken: 'Icon ist bereits vergeben',
        notEnoughQuantityInStorage: 'Nicht ausreichende Menge im Lager.',
        valueTooLow: 'Wert zu niedrig',
        valueTooHigh: 'Wert zu hoch',
        maxAvailableQuantity: 'Höchstzahl',
        someFilesRejectedDueToSize:
            'Einige Dateien wurden aufgrund ihrer Größe abgelehnt. Maximale Größe einer einzelnen Datei:',
        cameraDeviceNotFound: 'Kameragerät nicht gefunden',
        cameraPermissionDenied:
            'Der Zugriff auf die Gerätekamera wurde in den Website-Einstellungen blockiert. Setzen Sie die Website-Einstellungen zurück und erlauben Sie die Verwendung der Kamera.',
        passwordResetLinkExpired:
            'Der Link zum Zurücksetzen des Passworts ist abgelaufen',
        noInternetConnection: 'Keine Internetverbindung',
        minimum: 'Minimum',
        mustBeGreaterThan: 'Muss größer sein als',
        noEventTypesDefined: 'Keine Ereignistypen definiert',
        regexPatternDelimiterError:
            'Das Trennzeichen muss mit einem \\ weggelassen werden, z. B: \\/',
        emailNotMatchPatternDefiniedInTenant:
            'Die E-Mail-Adresse entspricht nicht dem im Mandanten definierten Muster',
        max: 'Höchster Wert',
        min: 'Mindestwert',
        allLanguagesRequired: 'Vervollständigen Sie den Wert in allen Sprachen',
        fileTooBigToOpenWillBeDownloaded:
            'Die Datei ist zu groß zum Öffnen. Die Datei wird auf Ihr Gerät heruntergeladen.',
        surveyNotFound: 'Umfrage nicht gefunden',
        actionNotEnabled: 'Der Code ist inaktiv',
        loginRequired: 'Anmeldung erforderlich',
        noPermissionForSelectedFolder:
            'Sie sind nicht berechtigt, den Inhalt dieses Verzeichnisses anzuzeigen',
        noFoldersAssignedForTerminal:
            'Es sind keine Verzeichnisse mit Anweisungen zugewiesen',
        noChecklistsAssignedToControl:
            'Der ausgewählten Kontrolle ist keine Checkliste zugeordnet',
        maximum: 'Maximal',
        versionErrorMessage:
            'Ihre letzten Änderungen wurden nicht gespeichert, weil jemand anderes gleichzeitig Änderungen vorgenommen hat. Aktualisieren Sie die Seite und versuchen Sie es erneut.',
        duplicateError: 'Dieser Wert existiert bereits',
        fillAllRequiredParameters:
            'Vervollständigen Sie alle erforderlichen Parameter.',
        wrongValue: 'Ungültiger Wert',
        noParametersDefined: 'Keine Parameter definiert',
        noFlagsConfigured: 'Keine Flags konfiguriert',
        noEnoughParts: 'Not enough parts',
        pinNotBelongsTo: 'Diese @:common.PIN_CODE_LABEL gehört nicht zu',
        tagNotBelongsTo: 'Diese Karte/dieser Anhänger gehört nicht zu',
        noDictionaryEntries:
            'Das ausgewählte Wörterbuch enthält keine Einträge',
        noConnectionWithServer: 'Keine Verbindung zum Server',
        uploadError: 'Fehler beim Hochladen',
        finishedSuccessfully: 'Erfolgreich abgeschlossen',
        partsAvailabilityError:
            'Die Verfügbarkeit einiger ausgewählter Elemente hat sich geändert. Bearbeiten Sie den Inhalt Ihrer Tauschbox und versuchen Sie es erneut.',
    },
    navDrawer: {
        dashboard: 'Übersicht',
        issues: 'Probleme',
        timetable: 'Zeitplan',
        machines: 'Maschinen',
        tools: 'Werkzeuge',
        parts: 'Teile',
        storage: 'Lager',
        staff: 'Mitarbeiter',
        configuration: 'Konfiguration',
        notes: 'Notizen',
        notifications: 'Benachrichtigungen',
        profile: 'Profil',
        supervision: 'Überwachung',
    },
    barcodeScanner: {
        barCode: 'Barcode',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Kategorie',
        selectCategory: 'Kategorie auswählen',
        noSubcategories: 'Keine Unterkategorien',
    },
    companies: {
        addCompany: 'Firma hinzufügen',
        editCompany: 'Firma bearbeiten',
        fullName: 'Vollständiger Name',
        shortName: 'Kurzname',
        group: 'Gruppe',
        country: 'Land',
        city: 'Stadt',
        street: 'Straße',
        postCode: 'Postleitzahl',
        phone: 'Telefon',
        email: 'E-Mail',
        website: 'Website',
        NIP: 'NIP',
        REGON: 'Nationales Handelsregister',
        active: 'Aktiv',
        nameRequired: 'Name ist erforderlich',
        shortNameRequired: 'Name ist erforderlich',
    },
    issues: {
        tookAt: 'Getroffen um',
        changeHistory: 'Änderungsverlauf',
        load: 'Laden',
        date: 'Datum',
        createdBy: 'Erstellt von',
        action: 'Aktion',
        data: 'Daten',
        created: 'Erstellt',
        updated: 'Aktualisiert',
        deleted: 'Gelöscht',
        restored: 'Wiederhergestellt',
        issues: 'Probleme',
        issue: 'Problem',
        by: 'von',
        name: 'Name',
        applicantsDescription: 'Beschreibung des Antragstellers',
        accidentDescription: 'Unfallbeschreibung',
        jobDescription: 'Aufgabenbeschreibung',
        jobSummary: 'Zusammenfassung der Aufgabe',
        category: 'Kategorie',
        status: 'Status',
        noFlow: '[Kein Ablauf]',
        equipment: 'Ausrüstung',
        criticality: 'Kritikalität',
        hour: 'Stunde',
        formalConfirmation: 'Formelle Bestätigung',
        formalConfirmationShortcut: 'FB',
        formalConfirmationRequired: 'FB erforderlich',
        parameter: 'Parameter',
        value: 'Wert',
        addIssue: 'Problem hinzufügen',
        expectedCompletionDate: 'Geplanter Abschlussdatum',
        issueDescription: 'Problem Beschreibung',
        partsUsage: 'Verwendung von Teilen',
        assignedEmployees: 'Zugewiesene Mitarbeiter',
        requiredHelp: 'Benötigte Hilfe',
        returnParts: 'Teil zurückgeben',
        confirmUnassignEmployeeWhoTakenIssue:
            'Dieser Mitarbeiter hat das ausgewählte Problem übernommen. Möchten Sie ihn entfernen?',
        responsibleUser: 'Verantwortlicher Benutzer',
        responsibleRules: 'Verantwortliche Benutzer - Regeln',
        received_decision_at:
            'Das Datum, an dem der Antragsteller von der Entscheidung Kenntnis erlangt hat',
        groupRules: 'Gruppen - Regeln',
        watchersRules: 'Beobachter - Regeln',
    },
    machines: {
        machines: 'Maschinen',
        xIssuesNotPlanned: ' (nicht geplant)',
        noIssues: 'Keine Probleme',
    },
    maintenance: {
        maintenance: 'Wartung',
    },
    notes: {
        notes: 'Notizen',
        note: 'Notiz',
        title: 'Titel',
        archived: 'Archiviert',
    },
    parts: {
        parts: 'Teile',
        warehouseOutgoings: 'Lagerabgänge',
        fittingEquipmentTypes: 'Passende Ausrüstungstypen',
        goToEquipmentType: 'Gehe zu Ausrüstungstyp',
        selectPart: 'Teil auswählen',
        usageReason: 'Grund',
        usageOtherReason: 'Andere',
        replacements: 'Ersatzteile',
        partEntry: 'Teileintrag',
        goToPart: 'Gehe zu Teil',
    },
    costCenter: {
        costCenter: 'Kostenstelle',
    },
    equipmentTypes: {
        equipment: 'Ausrüstung',
        newEquipmentType: 'Neuer Ausrüstungstyp',
        fittingParts: 'Passende Teile',
        goToParts: 'Gehe zu Teilen',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'Maschinen- und Werkzeugdefinitionen',
    },
    flows: {
        flow: 'Flow',
        states: 'Zustände',
        transitions: 'Übergänge',
        state: 'Zustand',
        default: 'Standard',
        warning: 'Warnung',
        problem: 'Problem',
        fixing: 'Behebung',
        finished: 'Fertig',
        access: {
            create: 'Schaffung',
            read: 'Lektüre',
            update: 'Änderung',
            delete: 'Entfernung',
            read_unassigned: 'Nicht zugewiesen lesen',
            assign: 'Abtretung',
            unassign: 'Eine Aufgabe löschen',
            close: 'Schließen',
        },
        instructions: {
            default: 'Standard',
            fixing: 'Rücksichtnahme',
            finished: 'Fertig',
            creation: 'Erstellung',
            verification: 'Überprüfung',
            acceptation: 'Annahme',
            publication: 'Veröffentlichung',
        },
        accessLabels: {
            terminal: 'Das Terminal, das das Problem gemeldet hat',
            creator: 'Der Benutzer, der das Problem gemeldet hat',
            responsible: 'Der Benutzer, der für das Problem verantwortlich ist',
            group: 'Die Benutzergruppe, die für das Problem verantwortlich ist',
            anchor: 'Das Terminal, an das der Bericht angeheftet ist',
            watchers: 'Benutzer, die das Problem beobachten',
        },
        sync_returned_rejected: 'Synchronisierung - abgelehnt',
        sync_returned_finished: 'Synchronisierung - abgeschlossen',
        current: 'Aktuell',
    },
    parameters: {
        categoryParameter: 'Kategorieparameter',
        equipmentTypeParameter: 'Ausrüstungstypparameter',
    },
    staff: {
        staff: 'Personal',
        editUserAccess: 'Benutzerzugriff bearbeiten',
        permissionsUpdated: 'Berechtigungen aktualisiert',
        passwordUpdated: 'Passwort aktualisiert',
        codeUpdated: '@:common.PIN_CODE_LABEL aktualisiert',
        changePasswordAndCode: 'Passwort und @:common.PIN_CODE_LABEL ändern',
        goToLicenses: 'Gehe zu Lizenzen',
        editAccess: 'Zugriff bearbeiten',
        editUser: 'Benutzer bearbeiten',
        addUser: 'Neuen Benutzer hinzufügen',
        nameAndLastname: 'Name und Nachname',
        accountStatus: 'Kontostatus',
        phoneNumber: 'Telefonnummer',
        department: 'Abteilung',
        passwordChange: 'Passwortänderung',
        codeChange: '@:common.PIN_CODE_LABEL änderung',
        newPassword: 'Neues Passwort',
        confirmNewPassword: 'Neues Passwort bestätigen',
        changePassword: 'Passwort ändern',
        newCode: 'Neuer @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Neuen @:common.PIN_CODE_LABEL bestätigen',
        changeCode: '@:common.PIN_CODE_LABEL ändern',
        addEntry: 'Eintrag hinzufügen',
        editEntry: 'Eintrag bearbeiten',
        editLicense: 'Lizenz bearbeiten',
        addLicense: 'Lizenz hinzufügen',
        licenseNumber: 'Lizenznummer',
        prefferedLocale: 'Bevorzugte Spracheinstellung',
        oldPassword: 'Altes Passwort',
        newPasswordConfirmation: 'Neues Passwort bestätigen',
        reading: 'Anzeigen',
        creating: 'Erstellen',
        updating: 'Aktualisieren',
        deleting: 'Löschen',
        importScheduleFile: 'Importieren Sie einen Zeitplan aus einer Datei',
        importSchedule: {
            errors: {
                user_not_found: 'Benutzer nicht gefunden [{user}]',
                user_found_many: '{count} benutzer gefunden [{user}]',
                event_collision: 'Kollision von Ereignissen',
                hour_cell_parse: 'Zellparsing-Fehler',
            },
        },
        oldCode: 'Alter @:common.PIN_CODE_LABEL',
        newCodeConfirmation: 'Bestätigung des neuen @:common.PIN_CODE_LABEL',
        setAccessBasedOnProfile:
            'Gewähren Sie Zugriff basierend auf dem Profil',
        selectAccessMergeType:
            'Wählen Sie aus, wie Berechtigungen kombiniert werden sollen',
        noAccessesInProfileMessage:
            'Das ausgewählte Profil hat keine Berechtigungen. Möchten Sie trotzdem überschreiben?',
    },
    breadCrumb: {
        configuration: 'Konfiguration',
        locations: 'Standorte',
        home: 'Zuhause',
        machines: 'Maschinen',
        tools: 'Werkzeuge',
        issues: 'Probleme',
        notes: 'Notizen',
        maintenance: 'Wartung',
        parts: 'Teile',
        warehouse: 'Lagerhaus',
        dictionary: 'Wörterbuch',
        categories: 'Kategorien',
        parameters: 'Parameter',
        costCenter: 'Kostenstelle',
        flows: 'Flüsse',
        states: 'Zustände',
        transitions: 'Übergänge',
        shiftSchemas: 'Schichtschemata',
        shifts: 'Schichten',
        definitionsOfMachinesAndToolsTypes:
            'Maschinen- und Werkzeugtypen-Definitionen',
        add: 'Hinzufügen',
        edit: 'Bearbeiten',
        staff: 'Personal',
        licenses: 'Lizenzen',
        entries: 'Einträge',
        companiesDatabase: 'Firmen-Datenbank',
        companyManagement: 'Firmenverwaltung',
        companyData: 'Unternehmensdaten',
        supportedLanguages: 'Unterstützte Sprachen',
        en: 'Englisch',
        pl: 'Polnisch',
        es: 'Spanisch',
        de: 'Deutsch',
        defaultLanguage: 'Standard-Sprache',
        notifications: 'Benachrichtigungen',
        notificationsManagement: 'Benachrichtigungsverwaltung',
        myProfile: 'Mein Profil',
    },
    attributeTypes: {
        text: 'Text',
        note: 'Notiz',
        integer: 'Ganzzahl',
        decimal: 'Dezimal',
        date: 'Datum',
        time: 'Uhrzeit',
        dateTime: 'Datum und Uhrzeit',
        color: 'Farbe',
        icon: 'Symbol',
        boolean: 'Ja/Nein',
    },
    costCalculator: {
        costCalculator: 'Kostenrechner',
        waitTime: 'Wartezeit',
        serviceTime: 'Servicezeit',
        suspendTime: 'Stillstandzeit',
        totalTime: 'Gesamtzeit',
        estimatedCost: 'Geschätzte Kosten',
        calculate: 'Berechnen',
        withoutParts: 'Ohne Teile',
        timeOfReportingIssue: 'Zeitpunkt des Meldens des Problems',
        timeOfServiceStart: 'Zeitpunkt des Service-Starts',
        timeOfReportingEnd: 'Zeitpunkt des Endes des Services',
        days: 'Tage',
        hours: 'Stunden',
        minutes: 'Minuten',
        enterNewTime: 'Neue Zeit eingeben',
        fromIssueReportToServiceStart:
            'Zeit vom Melden des Problems bis zum Service-Start',
        fromServiceStartToServiceEnd:
            'Zeit vom Service-Start bis zum Ende des Services',
        workTime: 'Arbeitszeit',
        includeAwaitingTime: 'Wartezeit einbeziehen',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'Meine Probleme',
                assignedToMe: 'Mir zugewiesen',
                assignedToGroup: 'Meiner Gruppe zugewiesen',
                tookByMe: 'Von mir übernommen',
                takeIssueBtn: 'Übernehmen',
                arriveIssueBtn: 'Ich bin da',
                finishIssueBtn: 'Beenden',
                history: 'Verlauf',
                myResponsible: 'Ich bin verantwortlich für',
                unassignIssue: 'Aufnahme abbrechen',
            },
        },
    },
    synchronization: {
        enabled: 'Aktiviert',
        hasHeaders: 'Hat Überschriften',
        leaveFile: 'Datei behalten',
        host: 'Host',
        port: 'Port',
        protocol: 'Protokoll',
        username: 'Benutzername',
        password: 'Passwort',
        root: 'Root',
        encoding: 'Kodierung',
        separator: 'Trennzeichen',
        externalSystemId: 'Externes System-ID',
        cronExpression: 'Cron-Ausdruck',
        appendFullStock: 'Vollständigen Bestand anhängen',
        includeHeaders: 'Überschriften einbeziehen',
        withTrashed: 'Mit gelöschten Daten',
        filePattern: 'Dateimuster',
        categoriesRootCategoryId: 'Stammkategorie für importierte Kategorien',
        systemKeyname: 'Systembezeichner',
        address: 'Adresse',
        token: 'Token',
    },
    processes: {
        created_at: 'Erstellt am',
        started_at: 'Gestartet am',
        updated_at: 'Aktualisiert am',
        finished_at: 'Beendet am',
        attempts: 'Versuche',
        queue: 'Warteschlange',
        job_id: 'Job ID',
        job_uuid: 'Job UUID',
        progress_max: 'Maximaler Fortschritt',
        progress_now: 'Aktueller Fortschritt',
        outputMessage: 'Ausgabenachricht',
        status: {
            queued: 'wartend',
            executing: 'ausführend',
            finished: 'beendet',
            failed: 'fehlgeschlagen',
            retrying: 'wiederholend',
            undefined: 'undefiniert',
        },
    },
    weekDays: {
        mo: 'Montag',
        tu: 'Dienstag',
        we: 'Mittwoch',
        th: 'Donnerstag',
        fr: 'Freitag',
        sa: 'Samstag',
        su: 'Sonntag',
    },
    reports: {
        reports: 'Berichte',
        dateFrom: 'Startdatum',
        dateTo: 'Enddatum',
        types: {
            operation_sheet: 'Betriebsblatt',
            issue_summary: 'Zusammenfassung des Problems',
            grouped_summary: 'Kurzbericht',
            storage_operations: 'Lagerbetrieb',
            part_quantity_export: 'Teilemenge',
        },
        reportFor: 'Bericht für',
        includeQuantityLeft0: 'Teile mit Nullmenge einbeziehen',
        dayMinusN: 'Tag minus n',
        currentWeek: 'Aktuelle Woche',
        lastWeek: 'Letzte Woche',
        currentMonth: 'Aktueller Monat',
        lastMonth: 'Letzter Monat',
        currentYear: 'Aktuelles Jahr',
        lastYear: 'Letztes Jahr',
        nDays: 'Anzahl der Tage',
        todayMinusN: 'Heute minus n',
    },
    logs: {
        comment: 'Kommentar',
        issues: {
            escalation:
                'Eskalation ({minutes} Min.) - Benachrichtigung gesendet',
        },
        issueEmployee: {
            assignedAt: 'Mitarbeiter zugewiesen:',
            receivedAt: 'Mitarbeiter hat empfangen:',
            tookAt: 'Mitarbeiter hat übernommen:',
            finishedAt: 'Mitarbeiter hat abgeschlossen:',
            deletedAt: 'Mitarbeiter aus Meldung gelöscht',
        },
    },
    units: {
        no: 'Stück',
        st: 'Satz',
        pr: 'Paar',
    },
    rrule: {
        frequency: 'Frequenz',
        dtstart: 'Startdatum',
        tzid: 'Zeitzone',
        until: 'Bis',
        count: 'Zählen',
        interval: 'Intervall',
        wkst: 'Starttag',
        byweekday: 'Bis Wochentag',
        bymonth: 'Nach Monaten',
        yearly: 'Jährlich',
        monthly: 'Monatlich',
        weekly: 'Wöchentlich',
        daily: 'Täglich',
        hourly: 'Stündlich',
        minutely: 'Minutiös',
        secondly: 'Zweitens',
        infinitePeriodicityMessage:
            'Die Periodizität ist unendlich, aus Performancegründen werden nur 500 Zeilen angezeigt',
    },
    months: {
        jan: 'Januar',
        feb: 'Februar',
        mar: 'Marsch',
        apr: 'April',
        may: 'Dürfen',
        jun: 'Juni',
        jul: 'Juli',
        aug: 'August',
        sep: 'September',
        oct: 'Oktober',
        nov: 'November',
        dec: 'Dezember',
    },
    charts: {
        charts: 'Diagramme',
        count: 'Anzahl der Ausgaben',
        total: 'Gesamtausgabenzeit (vom Bericht bis zum Abschluss)',
        serw: 'Gesamtreparaturzeit (Ttotal - Twait)',
        mttr1: 'Durchschnittliche Zeit vom Bericht bis zum Ende',
        mttr2: 'Durchschnittliche Zeit von der Aufnahme bis zum Ende',
        mtbf: 'Durchschnittliche Betriebszeit zwischen Ausgaben',
        mttf: 'Durchschnittliche Zeit zwischen Ausgaben',
        type: 'Diagramm Typ',
        pie: 'Kuchendiagramm',
        bar: 'Ein Balkendiagramm',
        query: 'Der Umfang der Daten',
        groupBy: 'Gruppiere nach',
        sum: 'Summe',
        avg: 'Bedeuten',
        min: 'Minimum',
        max: 'Maximal',
        eq: 'Ist gleich',
        neq: 'Es ist nicht gleich',
        gt: 'Mehr als',
        lt: 'Weniger als',
        gte: 'Mehr oder gleich',
        lte: 'Weniger oder gleich',
        in: 'Enthält',
        nin: 'Nicht enthalten',
        btw: 'Zwischen',
        chartOptions: 'Diagrammoptionen',
        line: 'Liniendiagramm',
        mixed: 'Gemischt',
        chartDisplayOptions: 'Anzeigeoptionen',
        fillGaps: 'Gülle die Lücken',
        groupableHeadersPlaceholder:
            'Ziehen Sie die Tabellenüberschrift, um die Daten zu gruppieren.',
        steps: 'Schritte',
        step: 'Schritt',
        content: 'Inhalt',
    },
    regexBuilder: {
        regexValue: 'Wert',
        global: 'Global',
        multiline: 'Mehrzeilig',
        insensitive: 'Groß-/Kleinschreibung ignorieren',
        extended: 'Erweitert',
        singleline: 'Einzeilig',
        unicode: 'Unicode',
        Ungreedy: 'Lazy Quantifiers',
        Anchored: 'Anfang oder Ende des Musters verankert',
        Jchanged: 'Doppelte Submuster-Namen erlauben',
        DollarEndOnly: '$ passt nur am Musterende',
        testValue: 'Testwert',
    },
    tpm: {
        history: 'Geschichte',
        standardObstacle: 'Standardhindernis',
        customObstacle: 'Benutzerdefiniertes Hindernis',
        bhp: 'Arbeitssicherheit und Gesundheitsschutz',
        returnToMainPage: 'Zurück zur Hauptseite',
        bhpMessage:
            'Wenn Sie Sicherheitsrisiken feststellen, melden Sie diese bitte unverzüglich Ihrem direkten Vorgesetzten und verwenden Sie das Meldungsformular unterhalb des Anschlagbretts.',
        ok: 'Ok',
        send: 'Senden',
        description: 'Beschreibung',
        title: 'Titel',
        realization: 'Realisierung',
        answer: 'Antwort',
        pendingSurveys: 'Laufende Umfragen',
        surveys: 'Umfragen',
        systemSurvey: 'Systemumfrage',
        addPage: 'Seite hinzufügen',
        createSurvey: 'Umfrage erstellen',
        addSection: 'Abschnitt hinzufügen',
        addQuestion: 'Frage hinzufügen',
        questionContent: 'Fragetext',
        answerOption: 'Antwortoption',
        addQuestionAnswer: 'Antwortoption hinzufügen',
        surveyName: 'Name der Umfrage',
        sectionName: 'Name des Abschnitts',
        noSurveyAnswers: 'Keine Antworten vorhanden',
        removeSection: 'Abschnitt entfernen',
        published: 'Veröffentlicht',
        unpublished: 'Nicht veröffentlicht',
        pendingPublication: 'Änderungen warten auf Veröffentlichung',
        removeQuestion: 'Frage entfernen',
        removeAnswer: 'Antwortoption entfernen',
        publish: 'Veröffentlichen',
        unpublish: 'Zurückziehen',
        previewResults: 'Ergebnisse anzeigen',
        supervisior: 'Vorgesetzter',
        surveyResults: 'Umfrageergebnisse',
        surveysEvents: 'Konfiguration von Ereignissen - Umfragen',
        operatedBy: 'Betrieben von',
        confirmReadInformations: 'Ich habe die Informationen gelesen',
        noticeContent: 'Inhalt der Bekanntmachung',
        notice: 'Bekanntmachung',
        surveyEventName: 'Name des Ereignisses',
        survey: 'Umfrage',
        newIssue: 'Neues Problem',
        surveyEventConfigurationAlreadyExists:
            'Diese Konfiguration ist bereits vorhanden',
        lastComment: 'Letzter Kommentar der verantwortlichen Person',
        surveyNotConfigured: 'Umfrage nicht konfiguriert',
        fillSurvey: 'Füllen Sie die Umfrage aus',
        singleSurveyEntries: 'einzelne Ergebnisse',
        groupedSurveyEntries: 'Gruppierte Ergebnisse',
        publicationId: 'Publikations-ID',
        publicationDate: 'Veröffentlichungsdatum',
        versionId: 'Versions-ID',
        answersCount: 'Anzahl der Antworten',
        answers: 'Antworten',
        withoutSupervisor: 'Kein Vorgesetzter',
        defaultSupervisor: 'Standard-Supervisor',
        flowStateFlags: 'Statusflags',
        qrActionsSettings: 'Einstellungen für QR-Code-Aktionen',
        loginRequired: 'Erfordert eine Anmeldung',
        questionContext: 'Den Kontext der Frage',
        currentSurveyVersionPreview:
            'Vorschau auf die aktuelle Version der Umfrage',
        surveyDescription: 'Beschreibung der Umfrage',
        sectionDescription: 'Abschnittsbeschreibung',
        instructions: 'Anweisungen',
        instruction: 'Anweisung',
        instructionRevisions: 'Überarbeitungen',
        instructionRevision: 'Revision',
        checklists: 'Checklisten',
        checklist: 'Checkliste',
        checklistItem: 'Listenpunkt',
        manageChecklistFields: 'Listenelemente verwalten',
        checklistItemDisplayOptions: 'Angezeigte Artikel',
        revisionAutoRejectedInfo: '[System] Abgelehnt – neue Revision erstellt',
        steps: 'Stufen',
        step: 'Bühne',
        content: 'Inhalt',
        groupableHeadersPlaceholder:
            'Ziehen Sie einen Tabellenkopf, um die Daten zu gruppieren',
    },
    accessMergeValues: {
        sum: 'Summe',
        product: 'Produkt',
        overwrite: 'überschreiben',
    },
    aggregates: {
        count: 'Summe',
        min: 'Minimum',
        max: 'Maximal',
    },
    checklists: {
        itemBasic: 'Basiselement',
        itemSeparator: 'Separator',
        itemSection: 'Abschnitt',
        publish: 'Veröffentlichen',
        previewCurrentVersion: 'Vorschau der aktuellen Version',
    },
    cluer: {
        groupableHeadersPlaceholder:
            'Ziehen Sie die Tabellenüberschrift, um die Daten zu gruppieren.',
        history: 'Geschichte',
        standardObstacle: 'Standardhindernis',
        customObstacle: 'Benutzerdefiniertes Hindernis',
        bhp: 'Arbeitssicherheit und Gesundheitsschutz',
        returnToMainPage: 'Zurück zur Hauptseite',
        bhpMessage:
            'Wenn Sie Sicherheitsrisiken feststellen, melden Sie diese bitte unverzüglich Ihrem direkten Vorgesetzten und verwenden Sie das Meldungsformular unterhalb des Anschlagbretts.',
        ok: 'Ok',
        send: 'Senden',
        description: 'Beschreibung',
        title: 'Titel',
        realization: 'Realisierung',
        answer: 'Antwort',
        pendingSurveys: 'Laufende Umfragen',
        surveys: 'Umfragen',
        systemSurvey: 'Systemumfrage',
        addPage: 'Seite hinzufügen',
        createSurvey: 'Umfrage erstellen',
        addSection: 'Abschnitt hinzufügen',
        addQuestion: 'Frage hinzufügen',
        questionContent: 'Fragetext',
        answerOption: 'Antwortoption',
        addQuestionAnswer: 'Antwortoption hinzufügen',
        surveyName: 'Name der Umfrage',
        sectionName: 'Name des Abschnitts',
        noSurveyAnswers: 'Keine Antworten vorhanden',
        removeSection: 'Abschnitt entfernen',
        published: 'Veröffentlicht',
        unpublished: 'Nicht veröffentlicht',
        pendingPublication: 'Änderungen warten auf Veröffentlichung',
        removeQuestion: 'Frage entfernen',
        removeAnswer: 'Antwortoption entfernen',
        publish: 'Veröffentlichen',
        unpublish: 'Zurückziehen',
        previewResults: 'Ergebnisse anzeigen',
        supervisior: 'Vorgesetzter',
        surveyResults: 'Umfrageergebnisse',
        surveysEvents: 'Konfiguration von Ereignissen - Umfragen',
        operatedBy: 'Betrieben von',
        confirmReadInformations: 'Ich habe die Informationen gelesen',
        noticeContent: 'Inhalt der Bekanntmachung',
        notice: 'Bekanntmachung',
        surveyEventName: 'Name des Ereignisses',
        survey: 'Umfrage',
        newIssue: 'Neues Problem',
        surveyEventConfigurationAlreadyExists:
            'Diese Konfiguration ist bereits vorhanden',
        lastComment: 'Letzter Kommentar der verantwortlichen Person',
        surveyNotConfigured: 'Umfrage nicht konfiguriert',
        fillSurvey: 'Füllen Sie die Umfrage aus',
        singleSurveyEntries: 'einzelne Ergebnisse',
        groupedSurveyEntries: 'Gruppierte Ergebnisse',
        publicationId: 'Publikations-ID',
        publicationDate: 'Veröffentlichungsdatum',
        versionId: 'Versions-ID',
        answersCount: 'Anzahl der Antworten',
        answers: 'Antworten',
        withoutSupervisor: 'Kein Vorgesetzter',
        defaultSupervisor: 'Standard-Supervisor',
        flowStateFlags: 'Statusflags',
        qrActionsSettings: 'Einstellungen für QR-Code-Aktionen',
        loginRequired: 'Erfordert eine Anmeldung',
        questionContext: 'Den Kontext der Frage',
        currentSurveyVersionPreview:
            'Vorschau auf die aktuelle Version der Umfrage',
        surveyDescription: 'Beschreibung der Umfrage',
        sectionDescription: 'Abschnittsbeschreibung',
    },
    orders: {
        order: 'Befehl',
        releaseDate: 'Fertigstellungstermin',
        orderDate: 'Datum der Bestellung',
    },
    production: {
        good: 'Anzahl der Guten',
        bad: 'Anzahl der schlechten',
        productionRecords: 'Produktionsregister',
    },
};
