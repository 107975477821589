<template>
    <div class="survey-builder-section">
        <div class="remove-section-button-container">
            <v-btn @click="() => removeSection()" color="error" type="button" variant="text"
                class="remove-section-button">
                {{ $t('cluer.removeSection') }}
            </v-btn>
        </div>
        <v-form v-model="sectionFormValid" lazy-validation ref="sectionForm">
            <SignaloTranslatableTabs v-slot="{ value, update }" v-model="section.name" :currentLanguage="$i18n?.locale"
                :availableLocales="getAvailableLocales()">
                <v-card-text>
                    <v-text-field :label="$t('cluer.sectionName')" :modelValue="value" @update:modelValue="update"
                        :rules="translatableRequired" required variant="outlined" color="var(--greyscale-100)"
                        class="required-field">
                    </v-text-field>
                </v-card-text>
            </SignaloTranslatableTabs>
            <v-expansion-panels v-model="panel">
                <v-expansion-panel :title="$t('cluer.sectionDescription')" variant="accordion" :value="1">
                    <template #text>
                        <SignaloTranslatableTabs v-slot="{ value, update }" v-model="section.description"
                            :currentLanguage="$i18n?.locale" :availableLocales="getAvailableLocales()">
                            <v-card-text>
                                <v-textarea :label="$t('cluer.sectionDescription')" :modelValue="value"
                                    @update:modelValue="update" variant="outlined" color="var(--greyscale-100)"
                                    :rules="maxLengthRules">
                                </v-textarea>
                            </v-card-text>
                        </SignaloTranslatableTabs>
                    </template>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-card-text>
                <v-text-field type="number" :label="$t('common.order')" variant="outlined" min="1"
                    v-model="section.order" class="required-field" clearable></v-text-field>
            </v-card-text>
        </v-form>
        <div class="survey-builder-section-questions">
            <QuizQuestionBuilder v-for="question in [...section.questions].sort((a, b) => a.order - b.order)"
                :key="question.id" :modelValue="question" ref="sectionQuestion" @update:modelValue="onQuestionUpdate"
                @removeQuestion="onRemoveQuestion" :surveyId="surveyId" :sectionId="section.id" />
            <div class="add-section-question-button-container">
                <v-btn @click="() => addQuestion()" color="primary" variant="text">
                    {{ $t('cluer.addQuestion') }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import QuizQuestionBuilder from './QuizQuestionBuilder.vue';
import { v4 as uuidv4 } from 'uuid';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import { asyncForEach, CheckIsUUID } from 'signalo-vue-utils';
export default {
    props: ['modelValue', 'surveyId', 'isFirstSection', 'isLastSection'],
    emits: ['sectionValid', 'update:modelValue', 'removeSection', 'rebuild'],
    components: { QuizQuestionBuilder },
    setup() {
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { authStore, helpersStore };
    },
    data() {
        return {
            section: { ...this.modelValue },
            sectionFormValid: undefined,
            panel: this.modelValue?.description?.[this.$i18n.locale] ? [1] : [],
        };
    },
    methods: {
        addQuestion() {
            this.section.questions.push({
                id: uuidv4(),
                type: null,
                name: null,
                answers: null,
                order: this.section?.questions?.length ? (Math.max(...this.section?.questions?.map(q => q.order)) + 1) : 1,
                required: true
            });
        },
        async validate() {
            const validators = [];
            if (typeof this.$refs.sectionForm?.validate === 'function') {
                const sectionValidationResult = await this.$refs.sectionForm.validate();
                validators.push(sectionValidationResult);
            }

            if (this.$refs.sectionQuestion?.length) {

                await asyncForEach(this.$refs.sectionQuestion, async (sq) => {
                    if (typeof sq.validate === 'function') {
                        const questionValidationResult = await sq.validate();
                        validators.push(...questionValidationResult);
                    }
                });

            } else if (this.$refs.sectionQuestion) {
                if (typeof this.$refs.sectionQuestion?.validate === 'function') {
                    const questionValidationResult = await this.$refs.sectionQuestion?.validate();
                    validators.push(...questionValidationResult);
                }
            }
            return validators;
        },
        async onQuestionUpdate(question) {
            const questionIndex = this.section.questions.findIndex(
                (q) => q.id === question.id
            );
            if (questionIndex === -1)
                throw new Error('Question index not found');
            if (!question.valid) return;
            delete question.valid;

            if (JSON.stringify(this.section.questions[questionIndex]) === JSON.stringify(question)) return;



            this.helpersStore.debounce(async () => {
                if (this.surveyId && !CheckIsUUID(this.section.id)) {
                    const questionData = {
                        name: question.name,
                        question_type: question.question_type,
                        required: !!question.required,
                        order: question.order,
                        max_points: question.max_points !== undefined ? Number(question.max_points) : null,
                        min_points_to_pass: question.min_points_to_pass !== undefined ? Number(question.min_points_to_pass) : null,
                        fail_quiz: !!question.fail_quiz,
                        // context_parameter_id:
                        //     question.context_parameter_id || null,
                    };
                    if (question.question_type === 'RADIO' || question.question_type === 'CHECK') {
                        delete questionData.max_points;
                    }
                    if (CheckIsUUID(question.id)) {
                        const createdQuestion = await this.axios.post(
                            `quizzes/${this.surveyId}/sections/${this.section.id}/questions`,
                            questionData
                        );
                        this.section.questions[questionIndex] = { ...question };
                        this.section.questions[questionIndex].id =
                            createdQuestion?.data?.item?.id;
                    } else {
                        await this.axios.put(
                            `quizzes/${this.surveyId}/sections/${this.section.id}/questions/${question.id}`,
                            questionData
                        );
                        this.section.questions[questionIndex] = { ...question };
                    }
                    this.helpersStore.snackbarSaved();
                }
            });
        },
        removeQuestionLocally(questionId) {
            const questionIndex = this.section.questions.findIndex(
                (q) => q.id === questionId
            );
            if (questionIndex === -1) throw new Error('Question not found');
            this.section.questions.splice(questionIndex, 1);
        },
        async onRemoveQuestion(questionId) {
            try {
                if (
                    this.surveyId &&
                    !CheckIsUUID(this.section.id) &&
                    !CheckIsUUID(questionId)
                ) {
                    await this.axios.delete(
                        `quizzes/${this.surveyId}/sections/${this.section.id}/questions/${questionId}`
                    );
                    this.removeQuestionLocally(questionId);
                    this.helpersStore.snackbarSaved();
                } else {
                    this.removeQuestionLocally(questionId);
                }
            } catch (err) {
                console.error(err);
            }
        },
        removeSection() {
            this.$emit('removeSection', this.section.id);
        },

    },
    computed: {
        maxLengthRules() {
            return [
                (v) => {
                    if (v?.length > 1024) {
                        return this.$t('errors.maxLength') + '1024';
                    }
                    return null;
                },
            ];
        },
        required() {
            return [(v) => !!v || this.$t('common.fieldRequired')];
        },
        translatableRequired() {
            return [
                () =>
                    this.authStore.tenant.supportedLocales.every(
                        (v) => this.section?.name?.[v]
                    ) || this.$t('errors.allLanguagesRequired'),
            ];
        },
    },
    watch: {
        sectionFormValid(val) {
            this.$emit('update:modelValue', {
                ...this.section,
                valid: val,
            });
        },
        section: {
            handler(val) {
                this.$refs?.sectionForm?.validate();
                this.$emit('update:modelValue', {
                    ...val,
                    valid: this.sectionFormValid,
                });
            },
            deep: true,
        },
        modelValue(val) {
            this.section = { ...val }
        }
    },
};
</script>

<style>
.survey-builder-section {
    border: 1px solid var(--greyscale-50);
    border-radius: 8px;
    margin-bottom: 8px;
}

.add-section-question-button-container {
    display: flex;
    justify-content: flex-end;
}

.remove-section-button-container {
    display: flex;
    /* justify-content: flex-end; */
}

.remove-section-button {
    margin-left: auto;
}
</style>
