<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.configuration') }} </template>
        <template #bar>
            <v-btn
                @click="() => openDialog()"
                v-if="$route.meta.canUpdate"
                class="add-button"
                color="primary"
                variant="elevated"
            >
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.attach') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>

    <v-main>
        <SignaloBreadCrumbs />
        <EasyDataTable buttons-pagination
            :headers="tableHeaders"
            :items="items"
            :empty-message="$t('common.emptyTableMessage')"
            :loading="loading"
            table-class-name="easy-table-style"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
        >
            <template #item-name="item">
                {{ item.name }}
            </template>

            <template #item-operation="item">
                <div class="actions-column">
                    <v-menu offset-y v-if="$route.meta.canUpdate">
                        <template v-slot:activator="{ props }">
                            <v-btn
                                color="primary"
                                v-bind="props"
                                variant="outlined"
                                icon
                                class="table-action-icon-button"
                            >
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="onEquipmentDetach(item.id)"
                                v-if="$route.meta.canUpdate"
                            >
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-delete
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.detach')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </EasyDataTable>
    </v-main>
    <SignaloEquipmentPicker
        v-slot="props"
        :multiple="true"
        @update:modelValue="onEquipmentAttach"
        :filteredEntries="items?.map((i) => i.id)"
        kind="machine"
    >
        <v-text-field
            ref="equipmentPickerTextField"
            variant="outlined"
            color="var(--greyscale-100)"
            :modelValue="props.text"
            @click:control="props.onClick"
            @click:clear="props.onClear"
            readonly
            :label="$t('searchConfig.machine')"
            class="hidden-equipment-picker"
        >
        </v-text-field>
    </SignaloEquipmentPicker>

    <SignaloConfirmationDialog
        :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => removeTerminal(value)"
    />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store'; 

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        return { dataStore, helpersStore };
    },
    data() {
        return {
            loading: false,
            removeDialog: false,
            items: [],
            equipmentPickerDialog: false,
        };
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                    sortable: true,
                },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
    },
    mounted() {
        this.loading = true;

        Promise.allSettled([this.loadTable()]).finally(() => {
            this.loading = false;
        });
    },
    methods: {
        openDialog() {
            this.equipmentPickerDialog = true;
            this.$nextTick(() => {
                this.$refs.equipmentPickerTextField.click();
            });
        },
        loadTable() {
            this.loading = true;
            return this.axios
                .get(`terminals/${this.$route.params.id}/equipment`)
                .then((x) => {
                    if (x.data?.status === 'ok') {
                        this.items = Object.values(x.data.items);
                    }
                })
                .catch((e) => {
                    if (e?.response?.status == 401) return;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onEquipmentAttach(equipmentId) {
            try {
                await this.axios.put(
                    `terminals/${this.$route.params.id}/equipment/${equipmentId}`
                );
                await this.loadTable();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
        async onEquipmentDetach(equipmentId) {
            try {
                await this.axios.delete(
                    `terminals/${this.$route.params.id}/equipment/${equipmentId}`
                );
                await this.loadTable();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
    },
};
</script>
<style>
.hidden-equipment-picker {
    position: absolute;
    top: -100vh;
    left: -100vw;
    visibility: hidden;
}
</style>
