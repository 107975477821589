<template>
    <div v-if="field?.answers?.length">
        <v-checkbox
            v-model="selected"
            v-for="item in [...field.answers].sort((a,b) => a.order - b.order)"
           :label="$getPropertyTranslation(item.name) + ((disabled && item.points) ? ` | (${$t('common.points')}: ${item.points})` : '')"
            :value="item.id"
            :key="item.id"
            color="primary"
            hide-details
            single-line
            :disabled="disabled"
        ></v-checkbox>
    </div>
    <div v-else class="error-message">{{ $t('cluer.noSurveyAnswers') }}</div>
</template>

<script>
export default {

    props: ['field', 'disabled'],
    emits: ['answer'],
    data() {
        return {
            selected: this.field?.answer || [],
        };
    },
    watch: {
        selected(val) {
            this.$emit('answer', val);
        },
    },
};
</script>
