<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.jobStatusses') }} </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs :data="breadcrumb" />
        <PageLoading v-if="loading" />
        <PageError v-else-if="error" :errorMessage="errorMessage" />
        <PageNotFound v-else-if="!item" />
        <div v-else>
            <v-card class="mt-3 mx-3 mb-3 job-status-preview-card">
                <template v-slot:title>
                    <v-tabs v-model="tab" color="primary">
                        <v-tab value="general">{{
                            $t('common.general')
                        }}</v-tab>
                        <v-tab value="output" v-if="item?.output">{{
                            $t('common.output')
                        }}</v-tab>
                    </v-tabs>
                </template>

                <v-card-text>
                    <div class="progress-bar-container">
                        <SignaloProcessProgressBar :jobStatusId="item.id" :progress_now="item.progress_now"/>
                    </div>
                    <v-window v-model="tab" :touch="false" :key="tab">
                        <v-window-item value="general">
                            <table class="proccess-data-table">
                                <tr v-if="item?.job_id">
                                    <td>{{ $t('processes.job_id') }}:</td>
                                    <td>{{ item.job_id }}</td>
                                </tr>
                                <tr v-if="item?.job_uuid">
                                    <td>{{ $t('processes.job_uuid') }}:</td>
                                    <td>{{ item.job_uuid }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('processes.created_at') }}:</td>
                                    <td>
                                        {{
                                            new Date(
                                                item.created_at
                                            ).toLocaleString()
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('processes.started_at') }}:</td>
                                    <td>
                                        {{
                                            new Date(
                                                item.started_at
                                            ).toLocaleString()
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('processes.updated_at') }}:</td>
                                    <td>
                                        {{
                                            new Date(
                                                item.updated_at
                                            ).toLocaleString()
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('processes.finished_at') }}:</td>
                                    <td>
                                        {{
                                            new Date(
                                                item.finished_at
                                            ).toLocaleString()
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('processes.attempts') }}:</td>
                                    <td>{{ item.attempts }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('common.status') }}:</td>
                                    <td>
                                        {{
                                            $t(
                                                `processes.status.${item.status}`
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('common.type') }}:</td>
                                    <td>{{ item.type }}</td>
                                </tr>
                            </table>
                        </v-window-item>
                        <v-window-item value="output">
                            <JsonEditorVue
                                v-model="item.output"
                                :mainMenuBar="false"
                                :readOnly="true"
                            />
                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
        </div>
    </v-main>
</template>

<script>
import JsonEditorVue from 'json-editor-vue';
export default {
    components: {
        JsonEditorVue,
    },
    data() {
        return {
            loading: false,
            id: this.$route.params.id,
            item: null,
            tab: 'general',
            error: false,
        };
    },
    mounted() {
        if (!this.id) {
            throw new Error('Id not found');
        }
        this.getJob(this.id);
    },
    methods: {
        async getJob(id) {
            this.loading = true;
            const jobData = await this.axios.get(`process/job_statuses/${id}`);
            try {
                this.item = jobData?.data?.item;
            } catch (err) {
                this.error = true;
                this.errorMessage = this.$t('errors.error');
            }
            this.loading = false;
        },
    },
    computed: {
        breadcrumb() {
            let res = {
                id: {
                    text: `#${this.$route.params.id}`,
                    value: this.$route.params.id,
                },
            };
            return res;
        },
    },
};
</script>
<style>
.proccess-data-table tr td:last-of-type {
    padding-left: 16px;
}
.job-status-preview-card {
    padding-top: 0 !important;
}
.progress-bar-container {
    margin-bottom: 8px;
}
</style>
