<template>
    <div class="simple-issues-view-container">
        <div class="quick-category-filters-container">
            <SignaloTabsToggle
                :modelValue="filteredCategories"
                :buttons="categoryFiltersButtons"
                :defaultTab="filteredCategories"
                @update:modelValue="(val) => (filteredCategories = val)"
                :mandatory="false"
                :multiple="true"
            />
        </div>
        <div class="simple-issue-cards-container">
            <SignaloSimpleIssueCard
                v-for="issue in filteredIssues"
                :key="issue.id"
                :issue="issue"
                @reload="$emit('reload')"
                @assignEmployeeToIssue="onAssignEmployeeToIssue"
                :currentLocale="currentLocale"
            />
        </div>
    </div>
    <!-- <div class="hidden-signalo-user-picker">
        <SignaloUserPicker
            @update:modelValue="onUserToAssignSelected"
            return-object
            :filteredItems="currentIssue?.employees?.map((e) => e?.user?.id)"
            ref="assignUserUserPickerRef"
            
        >
        </SignaloUserPicker>
    </div> -->
</template>

<script>
import SignaloSimpleIssueCard from './SignaloSimpleIssueCard.vue';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    props: ['issuesByCategory', 'currentLocale'],
    emits: ['reload'],
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { dataStore, helpersStore };
    },
    data() {
        return {
            filteredCategories: [],
            currentIssue: null,
        };
    },
    methods: {
        onAssignEmployeeToIssue(issue) {
            this.currentIssue = issue;
            this.$refs.assignUserUserPickerRef?.$?.ctx?.controlClicked();
        },
        async onUserToAssignSelected(user) {
            if (!user?.id || !this.currentIssue?.id) return;

            try {
                // await this.dataStore.issues.addEmployee(
                //     this.currentIssue.id,
                //     user.id
                // );
                await this.axios.post(
                    `issues/${this.currentIssue.id}/employees`,
                    {
                        user_id: user.id,
                        version: this.currentIssue.version,
                    }
                );
                this.helpersStore.snackbarSaved();
                this.currentIssue = null;
                this.$emit('reload');
            } catch (err) {
                console.error(err);
            }
        },
    },
    computed: {
        categoryFiltersButtons() {
            const b = this.dataStore.categories.items
                .filter((category) =>
                    Object.keys(this.issuesByCategory).includes(
                        String(category.id)
                    )
                )
                .map((category) => {
                    return {
                        color: category?.color,
                        label: this.$getPropertyTranslation(category?.name),
                        tooltip: this.$getPropertyTranslation(category?.name),
                        value: category?.id,
                        badgeValue: this.issuesByCategory[category?.id]?.length,
                        icon: category?.icon,
                        iconButton: true,
                    };
                });
            return b;
        },
        filteredIssues() {
            const flatten = [];
            Object.keys(this.issuesByCategory).forEach((key) => {
                flatten.push(...this.issuesByCategory[key]);
            });
            if (
                this.filteredCategories === null ||
                this.filteredCategories?.length === 0
            ) {
                return flatten;
            }
            return flatten.filter((issue) =>
                this.filteredCategories.includes(issue.category_id)
            );
        },
    },
    components: { SignaloSimpleIssueCard },
};
</script>

<style scoped>
.quick-category-filters-container {
    border-top: 1px solid var(--ui-general-outline-01);
    border-bottom: 1px solid var(--ui-general-outline-01);
}
.simple-issue-cards-container {
    padding: 16px 16px 0 16px;
}
.hidden-signalo-user-picker {
    display: none;
}
</style>

<style>
.quick-category-filters-container .v-btn-group.tabs-toggle {
    margin-top: 0;
    padding: 0 4px;
    overflow: visible;
}
</style>
