<template>
    <SignaloAppBar :search="showDesktopView" v-model:searchOpen="searchOpen" :searchFieldCount="searchFieldCount">
        <template #title>
            {{ $t('issues.issues') }}
        </template>
        <template #bar>
            <div class="translation-selector-container" v-if="authStore?.tenant?.attributes?.auto_translation">
                <SignaloTranslationSelector v-if="authStore?.tenant?.supportedLocales"
                    :availableLocales="authStore.tenant.supportedLocales"
                    @localeChange="(val) => onCurrentLocaleChange(val)" />
            </div>
            <v-btn @click="() => loadItems()" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" color="primary" variant="elevated" class="submain-button renew-button">
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="addButtonClicked" v-if="$route.meta.canCreate">
                        <v-list-item-title>
                            <v-icon start> mdi-plus </v-icon>
                            <span>
                                {{ $t('common.add') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onExport()">
                        <v-list-item-title>
                            <v-icon start> mdi-file-download </v-icon>
                            <span>
                                {{ $t('common.export') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="goToAndonView">
                        <v-list-item-title>
                            <v-icon start> mdi-cellphone-text </v-icon>
                            <span>
                                {{ $t('common.andonView') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showDesktopView = !showDesktopView" v-if="helpersStore.isMobile">
                        <v-list-item-title>
                            <div class="show-desktop-view-menu-item-container">
                                <v-switch hide-details v-model="showDesktopView" color="primary"
                                    class="show-classic-view-switch"></v-switch>
                                <div>{{ $t('common.showClassicView') }}</div>
                            </div>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="() =>
                            $openManageTableColumnsvisibilityDialog(
                                TABLE_NAME
                            )
                        ">
                        <v-list-item-title>
                            <v-icon start> mdi-table-cog </v-icon>
                            <span>
                                {{ $t('common.manageVisibleColumns') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- <v-btn
                color="primary"
                variant="elevated"
                @click="
                    () => $openManageTableColumnsvisibilityDialog(TABLE_NAME)
                "
                class="submain-button renew-button"
            >
                <v-icon> mdi-table-cog</v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.manageVisibleColumns') }}
                </span>
            </v-btn>
            <v-btn
                color="primary"
                variant="elevated"
                @click="() => addButtonClicked()"
                class="submain-button renew-button"
            >
                <v-icon> mdi-plus</v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn> -->
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloSearchContainer v-if="searchConfig" ref="searchContainerComponent" v-model:open="searchOpen"
            :config="searchConfig" @search="loadItems" :tableName="TABLE_NAME" />
        <SignaloBreadCrumbs />
        <div class="issues-content-wrapper">
            <EasyDataTable buttons-pagination v-if="showDesktopView" :headers="tableHeaders" :items="items"
                :empty-message="$t('common.emptyTableMessage')" @click-row="handleClickRow"
                table-class-name="easy-table-style" :rows-per-page-message="$t('common.rowsPerPage')"
                :rows-of-page-separator-message="$t('common.rowsOfPage')" :loading="loading"
                v-model:server-options="serverOptions" :server-items-length="serverItemsLength"
                :rows-items="[5, 10, 25, 50, 75, 100]" name="issuesIndexTable">
                <template #item-title="item">
                    <p class="issue-title" v-if="authStore?.tenant?.attributes?.auto_translation">
                        <span v-html="(currentLocale ? $getPropertyTranslation(
                            item?.attributes?.translations?.title,
                            currentLocale,
                            item?.attributes?.auto_translate,
                            'title'
                        ) : item.title) ||
                            item?.title ||
                            '-'
                            "></span>
                    </p>
                    <p class="issue-title" v-else>{{ item.title }}</p>
                </template>
                <template #item-category_id="item">
                    <div class="category-name-template">
                        <div class="category-color" v-if="getCategoryColor(item.category_id)"
                            :style="`background: ${getCategoryColor(
                                item.category_id
                            )}; height: 18px; width: 18px; min-width: 18px; border-radius:50%; margin-top: auto; margin-bottom: auto; margin-right: 4px;`"></div>
                        <v-icon class="category-icon" v-if="!!getCategoryIcon(item.category_id)"
                            v-bind:key="getCategoryIcon(item.category_id)">
                            {{ getCategoryIcon(item.category_id) }}
                        </v-icon>
                        <div>
                            {{
                                $getPropertyTranslation(
                                    dataStore.categories.getById(
                                        item.category_id
                                    )?.name
                                )
                            }}
                        </div>
                    </div>
                </template>
                <template #item-department="item">
                    {{
                        this.departments?.find(
                            (x) => x.keyname === item.department
                        )?.value ?? '-'
                    }}
                </template>
                <template #item-flow_state_name="item">
                    {{ $getPropertyTranslation(item.flow_state_name) ?? '-' }}
                </template>
                <template #item-created_by="item">
                    {{ item.created_by_user_name }}
                </template>
                <template #item-formal_confirm_at="item">
                    <v-icon v-if="item.formal_confirm_at"> mdi-check </v-icon>
                    <v-badge v-else-if="
                        item.formal_confirm_required &&
                        item.flow_state_finished
                    " color="error" :content="$t('issues.formalConfirmationRequired')" inline></v-badge>
                </template>
                <template #item-current_employee="item">
                    {{ item?.current_employee?.name || '-' }}</template>
                <template #item-responsible_user="item">
                    {{ item?.responsible_user?.name }}
                </template>
                <template #item-operation="item">
                    <SignaloDynamicMenu :rowItem="item" :menuItems="getTableActions(item)" />
                </template>
                <template #item-criticality="item">
                    {{
                        $getDictionaryItemName(
                            'issue_criticality',
                            item?.criticality
                        ) || '-'
                    }}
                </template>
                <template #item-prod_line="item">
                    {{
                        $getDictionaryItemName('prod_lines', item?.prod_line) ||
                        '-'
                    }}
                </template>
                <template #item-kind="item">
                    {{
                        $getDictionaryItemName('issue_kind', item?.kind) || '-'
                    }}
                </template>
                <template #item-equipment_symbol="item">
                    {{ item?.equipment?.symbol || '-' }}
                </template>
                <template #item-equipment_id="item">
                    {{ item?.equipment?.name || '-' }}
                </template>
                <template #item-flags="item">
                    <SignaloTableItemFlagsComponent :flags="item.flags" :key="item.id + JSON.stringify(item.flags)" />
                </template>
            </EasyDataTable>
            <IssuesIndexSimpleMobile v-else-if="issuesByCategory?.length !== 0" :issuesByCategory="issuesByCategory"
                @reload="() => loadItems()" :currentLocale="currentLocale" />
        </div>
    </v-main>
    <NewIssueDialog v-if="manageDialog" v-model:open="manageDialog" @close="onDialogClose"
        @item:created="onDialogClose" />
    <SignaloExportDialog v-if="exportDialog" :open="exportDialog" :endpoint="exportEndpoint"
        @close="exportDialog = false" :serverOptions="singleEntryExport ? null : serverOptions"
        :allPagesAllowed="exportAllPagesAllowed" :allowOrientationChange="true"
        :query="singleEntryExport ? null : $route.query" />
    <SignaloConfirmationDialog v-if="archiveDialog" :dialogOpened="archiveDialog"
        @update:dialogOpened="(value) => onArchiveHandler(value)" :confirmButtonText="$t('common.archive')"
        :text="$t('common.areYouSureAboutArchiveIssue')" />

    <ManageItemFlags v-if="manageItemFlagsDialogOpened" :type="'issue'" :item="editedItem"
        @close="onManageItemFlagsDialogClose" />
</template>

<script>
import FLAG_TYPES from '@/CONSTANTS/FLAG_TYPES';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import IssuesIndexSimpleMobile from './IssuesIndexSimpleMobile/IssuesIndexSimpleMobile.vue';

import NewIssueDialog from './NewIssueDialog';
import searchConfig from './searchConfig';
// import { toMySqlDate } from 'signalo-vue-utils';

const _ = require('lodash');

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    components: {
        NewIssueDialog,
        IssuesIndexSimpleMobile,
    },
    data() {
        return {
            loading: false,
            items: [],
            TABLE_NAME: 'issuesIndexTable',
            serverOptions: this.$getServerOptions('issuesIndexTable'),
            serverItemsLength: 0,
            searchOpen: false,
            searchFieldCount: 0,
            lastSearch: null,

            manageDialog: false,
            refreshIntervalId: null,
            refreshCounter: 60,
            exportEndpoint: null,
            exportDialog: false,
            issuesByCategory: [],
            notPlannedAll: 0,
            notPlannedUnfinished: 0,
            plannedAll: 0,
            plannedUnfinished: 0,
            exportAllPagesAllowed: false,
            singleEntryExport: false,
            desktopInitLoadDone: false,
            archiveDialog: false,
            itemToArchive: null,
            issueChartDialog: false,
            issueChartData: null,
            showDesktopView: !this.helpersStore.isMobileView,
            FLAG_TYPES: Object.freeze(FLAG_TYPES),
            manageItemFlagsDialogOpened: false,
            editedItem: null,
            currentLocale: null,
        };
    },
    computed: {
        issue_criticality() {
            return this.dataStore.dictionary.group('issue_criticality');
        },
        tableHeaders() {
            const ths = this.$getPreferredTableColumns(this.TABLE_NAME);
            ths.unshift({ value: 'flags', fixed: true, width: 8 });
            return ths;
        },
        tableHeadersMobile() {
            return [
                {
                    text: this.$t('tableHeaders.title'),
                    value: 'title',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.criticality'),
                    value: 'criticality',
                    sortable: true,
                },
                { value: 'operation', fixed: true, width: 54 },
            ];
        },
        searchConfig() {
            return searchConfig;
        },
        departments() {
            return Object.values(
                this.dataStore.dictionary.group('departments')
            );
        },
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences('issuesIndexTable', {
                default_size: val.rowsPerPage,
            });
            this.loadItems();
        },
    },
    async mounted() {
        await this.init();
    },
    unmounted() {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }
    },
    methods: {
        async onCurrentLocaleChange(val) {
            try {
                this.currentLocale = val;
                if (!this.currentLocale) return;
                this.$i18n.locale = this.currentLocale;
                if (
                    this.authStore?.terminal ||
                    this.authStore.user?.authenticatable === 'Terminal'
                )
                    return;

                await this.axios.patch('me', {
                    preferred_locale: this.currentLocale,
                });
                await this.authStore.getAuth();
            } catch (err) {
                console.error(err);
            }
        },
        async init() {
            const defaultView =
                this.authStore.user?.attributes?.['issues_view']?.defaultView;
            if (defaultView && defaultView !== this.$route.name) {
                if (this.refreshIntervalId) {
                    clearInterval(this.refreshIntervalId);
                }
                await setTimeout(async () => {
                    await this.$router.push({
                        name: defaultView,
                        force: true,
                        replace: true,
                    });
                });
                return;
            } else {
                this.loading = true;

                Promise.allSettled([
                    this.dataStore.dictionary.promise(),
                    this.dataStore.categories.promise(),
                ]).then(() => {
                    this.setRefreshInterval();
                });
            }
        },
        async goToAndonView() {
            if (this.refreshIntervalId) {
                clearInterval(this.refreshIntervalId);
            }
            await this.authStore.patchTablePreferences('issues_view', {
                defaultView: 'simplified_handling',
            });
            this.$router.push({
                name: 'simplified_handling',
            });
        },
        async assignYourselfAsResponsible(issue) {
            try {
                await this.axios.patch(`issues/${issue.id}`, {
                    id: issue.id,
                    responsible_user_id: this.authStore.user.id,
                    version: issue.version,
                });
                await this.loadItems();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                this.helpersStore.snackbarError(err);
                console.error(err);
            }
        },
        getTableActions(issue) {
            let actions = [];

            if (this.helpersStore.hacked) {
                actions.push({
                    icon: 'mdi-download',
                    tooltipText: this.$t('common.export'),
                    label: this.$t('common.export'),
                    action: this.onExport,
                });
            }

            if (issue?.access?.delete) {
                actions.push({
                    icon: 'mdi-archive',
                    label: this.$t('common.archive'),
                    tooltipText: this.$t('common.archive'),
                    action: this.onArchive,
                    access: [Boolean(issue?.access?.delete)],
                    color: 'warning',
                });
            }

            if (
                issue?.access?.update &&
                issue?.responsible_user_id !== this.authStore?.user?.id
            ) {
                actions.push({
                    icon: 'mdi-account-arrow-left',
                    label: this.$t(
                        'dashboard.components.myIssues.takeIssueBtn'
                    ),
                    tooltipText: this.$t(
                        'dashboard.components.myIssues.takeIssueBtn'
                    ),
                    action: this.assignYourselfAsResponsible,
                    access: [issue?.access?.update],
                });
            }
            actions.push({
                icon: 'mdi-flag',
                tooltipText: this.$t('common.manageFlags'),
                label: this.$t('common.manageFlags'),
                action: this.onManageFlags,
                access: [issue?.access?.update],
            });

            return actions;
        },
        onManageFlags(issue) {
            this.editedItem = issue;
            this.manageItemFlagsDialogOpened = true;
        },
        async onManageItemFlagsDialogClose(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadItems();
            }
            this.manageItemFlagsDialogOpened = false;
            this.editedItem = null;
        },
        async loadItems(search = null, resetPage) {
            this.loading = true;
            if (resetPage) {
                this.serverOptions.page = 1;
            }
            if (search !== null) {
                this.lastSearch = search;
            }
            this.refreshCounter = 60;
            const config = this.dataStore.issues.makeServerOptions(
                this.serverOptions,
                this.lastSearch
            );
            try {
                let itemsData;
                if (this.showDesktopView) {
                    this.$router.replace({ query: config.params });
                } else {
                    config.params.p = 1;
                    config.params.pp = 100;
                    config.params.with_trans = 1;
                }
                itemsData = await this.dataStore.issues.indexRequest(config);
                this.items = itemsData?.data?.items;
                this.issuesByCategory = _.groupBy(this.items, 'category_id');
                this.serverItemsLength = itemsData?.data?.meta?.total;
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        addButtonClicked() {
            this.manageDialog = true;
        },
        handleClickRow(row) {
            this.$router.push({ name: 'issues.show', params: { id: row.id } });
        },
        getCategoryIcon(categoryId) {
            return this.dataStore.categories.find((c) => c.id === categoryId)
                ?.icon;
        },
        getCategoryColor(categoryId) {
            return this.dataStore.categories.find((c) => c.id === categoryId)
                ?.color;
        },
        setRefreshInterval() {
            if (this.refreshIntervalId) {
                clearInterval(this.refreshIntervalId);
            }
            this.refreshIntervalId = setInterval(() => {
                this.refreshCounter--;
                if (this.refreshCounter <= 0) {
                    this.refreshCounter = 20;
                    this.authStore.isLoggedIn
                        ? this.loadItems()
                        : clearInterval(this.refreshIntervalId);
                }
            }, 1000);
        },
        onExport(item = null) {
            const id = item?.id;
            this.singleEntryExport = !!id;
            this.exportAllPagesAllowed = !id;
            this.exportEndpoint = 'issues';
            if (id !== null && id !== undefined) {
                this.exportEndpoint += `/${id}`;
            }
            this.exportDialog = true;
        },
        getIssuesForCategory(id) {
            return this.items.filter((i) => i.category_id == id);
        },
        onArchive(item) {
            this.itemToArchive = item;
            this.archiveDialog = true;
        },
        async onArchiveHandler(shouldArchive) {
            if (shouldArchive) {
                try {
                    await this.axios.delete(`issues/${this.itemToArchive.id}`);
                    await this.loadItems();
                    this.helpersStore.snackbar(
                        this.$t('tableHeaders.archived'),
                        'success'
                    );
                } catch (err) {
                    console.error(err);
                }
            }
            this.archiveDialog = false;
            this.itemToArchive = null;
        },
        showIssueChart(issue) {
            this.issueChartData = issue;
            this.issueChartDialog = true;
        },
        issueChartDialogClose() {
            this.issueChartDialog = false;
            this.issueChartData = null;
        },
        async onDialogClose(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadItems();
            }
            this.manageDialog = false;
        },
    },
};
</script>
<style>
.category-icon {
    margin-right: 8px;
}

.category-name-template {
    display: flex;
    align-items: center;
}

.tabs-toggle-container {
    margin: 32px;
    margin-bottom: 0;
}

.issues-extension-panel-text .v-expansion-panel-text__wrapper {
    padding: 0;
}

.issues-v-expansion-panels {
    margin-top: 12px;
}

.tabs-toggle-margin-left {
    margin-left: 8px;
}

.show-desktop-view-switch-container {
    padding: 0 12px;
}

.no-data-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.show-desktop-view-menu-item-container {
    display: flex;
    align-items: center;
}

.show-desktop-view-menu-item-container .v-input__control {
    margin-right: 8px;
}

.show-classic-view-switch {
    flex: unset !important;
}

.bar .translation-selector-container {
    margin-right: 1rem;
}
</style>
