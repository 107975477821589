import i18n from '@/i18n';
export default Object.freeze([
    {
        value: 'service',
        get label() {
            return i18n?.global?.t('common.service');
        },
    },
    {
        value: 'leaders',
        get label() {
            return i18n?.global?.t('common.leader');
        },
    },
    {
        value: 'personnel',
        get label() {
            return i18n?.global?.t('navDrawer.staff');
        },
    },
    {
        value: 'helpdesk',
        get label() {
            return i18n?.global?.t('common.helpdesk');
        },
    },
    {
        value: 'terminal',
        get label() {
            return i18n?.global?.t('common.terminal');
        },
    },
]);
