<template>
    <SignaloDialog
        :icon="'mdi-cog-outline'"
        :title="
            currentlyEditedUser?.id ? $t('staff.editUser') : $t('staff.addUser')
        "
        :open="open"
        @close="() => closeDialog()"
    >
        <template #body>
            <PageLoading v-if="loading" />
            <v-card-text v-else>
                <v-form ref="userDataForm" v-model="formValid">
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <div
                                class="d-flex flex-wrap"
                                v-if="currentlyEditedUser.id !== undefined"
                            >
                                <SignaloImageUpload
                                    v-if="userPhoto"
                                    v-model="userPhoto"
                                    :uploadEndpoint="`/users/${currentlyEditedUser?.id}/photos`"
                                    :removeEndpoint="`/users/${currentlyEditedUser?.id}/photos/${userPhoto.id}`"
                                    @image-removed="onRemoveImageHandler"
                                    @image-uploaded="onImageUploadedHandler"
                                    :allowDraw="false"
                                    :storageUrl="this.getFileStorageUrl() + '/'"
                                />
                                <div
                                    v-else
                                    class="eq-photo d-flex justify-center align-center"
                                    @click="addPhotoClicked"
                                >
                                    <v-icon size="x-large">
                                        mdi-plus-circle-outline
                                    </v-icon>
                                    <div class="cover"></div>
                                    <input
                                        type="file"
                                        ref="fileInput"
                                        @change="filesSelected"
                                        style="display: none"
                                        accept="image/png, image/jpeg, image/bmp, image/webp"
                                    />
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                :label="$t('staff.nameAndLastname')"
                                required
                                :rules="[$REQUIRED_RULE]"
                                v-model="currentlyEditedUser.name"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                class="required-field"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-select
                                :label="$t('common.timezone')"
                                v-model="currentlyEditedUser.timezone"
                                :items="timezones"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                class="required-field"
                                :no-data-text="$t('common.emptyTableMessage')"
                                :rules="[$REQUIRED_RULE]"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                :label="$t('staff.phoneNumber')"
                                maxlength="13"
                                v-model="currentlyEditedUser.phone"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                :rules="[contactRequiredRule, $PHONE_NUMBER_RULE]"
                                :disabled="
                                    !authStore.isAllowed(
                                        'staff.contact',
                                        'update'
                                    )
                                "
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                :label="$t('common.email')"
                                maxlength="255"
                                :rules="[contactRequiredRule, ..._emailRules]"
                                v-model="currentlyEditedUser.email"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                :disabled="
                                    !authStore.isAllowed(
                                        'staff.contact',
                                        'update'
                                    )
                                "
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6" xl="6">
                            <v-select
                                :label="$t('staff.accountStatus')"
                                :items="accountStatusOptions"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                v-model="currentlyEditedUser.account_status"
                                item-title="label"
                                item-value="value"
                                class="required-field"
                                :no-data-text="$t('common.emptyTableMessage')"
                                :rules="[$REQUIRED_RULE]"
                            ></v-select
                        ></v-col>
                        <v-col cols="12" sm="6" md="6">
                            <SignaloNewValueSelect
                                :selectableType="SELECTABLE_TYPES.CREW"
                                v-model="currentlyEditedUser.crew_id"
                                clearable
                            />
                        </v-col>
                        <!-- <v-col cols="12" md="6" xl="6">
                            <SignaloNewValueSelect
                                :selectableType="SELECTABLE_TYPES.DICTIONARY"
                                dictionary="user_criticality"
                                v-model="currentlyEditedUser.user_criticality"
                                clearable
                                :label="$t('searchConfig.criticality')"
                        /></v-col> -->
                        <v-col cols="12" sm="6" md="6">
                            <SignaloNewValueSelect
                                :selectableType="SELECTABLE_TYPES.DICTIONARY"
                                dictionary="employee_status"
                                v-model="currentlyEditedUser.status"
                                clearable
                                :label="$t('common.status')"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <SignaloNewValueSelect
                                :selectableType="SELECTABLE_TYPES.DICTIONARY"
                                :dictionary="'departments'"
                                :label="$t('staff.department')"
                                v-model="currentlyEditedUser.department"
                                clearable
                            />
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="
                            !this.currentlyEditedUser?.id &&
                            dataStore?.userTemplates?.items?.length
                        "
                    >
                        <v-col cols="12" md="12" xl="12">
                            <v-select
                                variant="outlined"
                                color="var(--greyscale-100)"
                                :label="$t('common.profile')"
                                v-model="userTemplate"
                                :items="dataStore.userTemplates.items"
                                :item-title="
                                    (item) =>
                                        $getPropertyTranslation(item?.name)
                                "
                                return-object
                                clearable
                                @click:clear="userTemplate = null"
                                clear-icon="mdi-close-circle-outline"
                                :no-data-text="$t('common.emptyTableMessage')"
                            >
                            </v-select
                        ></v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-select
                                :label="$t('common.function')"
                                v-model="currentlyEditedUser.function"
                                :items="userFunctions"
                                item-title="value"
                                item-value="keyname"
                                clearable
                                @click:clear="
                                    currentlyEditedUser.function = null
                                "
                                clear-icon="mdi-close-circle-outline"
                                variant="outlined"
                                color="var(--greyscale-100)"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <SignaloUserPicker
                                v-model="currentlyEditedUser.supervisor_user_id"
                                v-slot="props"
                                :filteredItems="[this.currentlyEditedUser?.id]"
                                :ormWithcountEnabled="false"
                            >
                                <v-text-field
                                    :modelValue="props.text"
                                    @click:control="props.onClick"
                                    @click:clear="props.onClear"
                                    readonly
                                    clearable
                                    clear-icon="mdi-close-circle-outline"
                                    hide-details
                                    :label="$t('tpm.supervisior')"
                                    variant="outlined"
                                    color="var(--greyscale-100)"
                                >
                                </v-text-field>
                            </SignaloUserPicker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- <v-col cols="12" sm="6" md="6">
                            <v-select
                                :label="$t('common.status')"
                                v-model="currentlyEditedUser.status"
                                :items="statuses"
                                item-title="value"
                                item-value="keyname"
                                clearable
                                @click:clear="currentlyEditedUser.status = null"
                                clear-icon="mdi-close-circle-outline"
                                variant="outlined"
                                color="var(--greyscale-100)"
                            >
                            </v-select>
                        </v-col> -->
                        <!-- <v-col cols="12" sm="6" md="6">
                            <v-select
                                :label="$t('staff.department')"
                                v-model="currentlyEditedUser.department"
                                :items="departments"
                                item-title="value"
                                item-value="keyname"
                                clearable
                                @click:clear="
                                    currentlyEditedUser.department = null
                                "
                                clear-icon="mdi-close-circle-outline"
                                variant="outlined"
                                color="var(--greyscale-100)"
                            >
                            </v-select>
                        </v-col> -->

                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                :label="$t('common.employeeNumber')"
                                maxlength="255"
                                v-model="currentlyEditedUser.no"
                                variant="outlined"
                                color="var(--greyscale-100)"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" xl="6">
                            <v-select
                                :label="$t('common.currency')"
                                v-model="currentlyEditedUser.currency_id"
                                :items="currencies"
                                :item-title="`name[${$i18n.locale}]`"
                                item-value="id"
                                variant="outlined"
                                color="var(--greyscale-100)"
                                clear-icon="mdi-close-circle-outline"
                                class="required-field"
                                :no-data-text="$t('common.emptyTableMessage')"
                                :rules="[$REQUIRED_RULE]"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </template>
        <template #buttons>
            <v-btn text @click="() => closeDialog()">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                @click="save"
                color="primary"
                variant="elevated"
                :loading="dataSaving"
            >
                {{ $t('common.save') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import PageLoading from '@/components/global/PageLoading.vue';
export default {
    props: ['user', 'open'],
    emits: ['close'],
    components: { PageLoading },
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            dataSaving: false,
            formValid: false,
            userPhoto: null,
            timezones: [],
            crews: [],
            currentlyEditedUser: this.user ? { ...this.user } : {},
            loading: false,
            defaultShouldRefetch: false,
            selectedCrew: null,
            // minHourlyCostValue: [
            //     (v) => !v || v >= 0 || this.$t('errors.valueTooLow'),
            // ],
            currencies: [],
            userTemplate: null,
            mounted: false
        };
    },
    mounted() {
        if (this.user.photo) {
            this.userPhoto = this.user.photo;
        }
        this.axios.get('global/currencies').then((res) => {
            this.currencies = res?.data?.items;
            if (!this.currentlyEditedUser?.currency_id) {
                this.currentlyEditedUser.currency_id =
                    this.authStore.tenant.default_currency_id;
            }
        });
        this.loadData();
        this.mounted = true;
    },
    methods: {
        loadData() {
            this.loading = true;
            Promise.allSettled([
                this.dataStore.dictionary.promise(),
                this.axios.get('global/timezones'),
                this.axios.get('crews'),
                this.dataStore.userTemplates.promise(),
            ]).then((res) => {
                this.timezones = res[1]?.value?.data?.items;
                this.crews = res[2]?.value?.data?.items;
                if (this.user?.crew_id) {
                    this.selectedCrew = this.crews.find(
                        (c) => c.id === this.user.crew_id
                    );
                }
                const defaultProfile = this.dataStore.userTemplates.items.find(
                    (ut) => ut.is_default
                );
                if (!this.currentlyEditedUser?.id && defaultProfile) {
                    this.userTemplate = defaultProfile;
                }
                this.loading = false;
            });
        },
        closeDialog(shouldRefetch = this.defaultShouldRefetch) {
            this.$emit('close', shouldRefetch);
        },
        onRemoveImageHandler() {
            this.userPhoto = null;
            this.defaultShouldRefetch = true;
        },
        onImageUploadedHandler() {
            this.defaultShouldRefetch = true;
        },
        async save() {
            await this.$refs.userDataForm.validate();
            if (!this.formValid) return;
            this.dataSaving = true;
            // if (this.selectedCrew) {
            //     this.currentlyEditedUser.crew_id = this.selectedCrew.id;
            // }
            if (this.userTemplate) {
                this.currentlyEditedUser.user_template_id =
                    this.userTemplate.id;
            }
            if (!this.authStore.isAllowed('staff.contact', 'update')) {
                delete this.currentlyEditedUser.email;
                delete this.currentlyEditedUser.phone;
            }
            if (!this.currentlyEditedUser.id) {
                this.axios
                    .post('/users', this.currentlyEditedUser)
                    .then(() => {
                        this.closeDialog(true);
                    })
                    .catch((e) => {
                        if (e?.response?.status == 401) return;
                        this.helpersStore.snackbarError(e);
                    })
                    .finally(() => {
                        this.dataSaving = false;
                    });
            } else {
                this.axios
                    .put(
                        `/users/${this.currentlyEditedUser.id}`,
                        this.currentlyEditedUser
                    )
                    .then(() => {
                        this.closeDialog(true);
                    })
                    .catch((e) => {
                        if (e.response.status == 401) return;
                        this.helpersStore.snackbarError(e);
                    })
                    .finally(() => {
                        this.dataSaving = false;
                    });
            }
        },
        addPhotoClicked() {
            this.$refs.fileInput.click();
        },
        filesSelected(e) {
            const file = e.target.files[0];
            if (file.size > this.helpersStore.MAX_FILE_SIZE) {
                this.helpersStore.snackbarWarning(
                    this.$t('errors.someFilesRejectedDueToSize') +
                        ` ${this.helpersStore.getMaxFileSizeInMB()}MB`
                );
                return;
            }
            this.userPhoto = {
                uploadFile: file,
            };
            this.defaultShouldRefetch = true;
        },
    },
    computed: {
        nameRules() {
            return [(v) => !!v || this.$t('common.nameRequired')];
        },
        accountStatusOptions() {
            return [
                { value: 'active', label: this.$t('common.active') },
                { value: 'not_activated', label: this.$t('common.notActive') },
                { value: 'disabled', label: this.$t('common.disabled') },
            ];
        },
        userFunctions() {
            return Object.values(
                this.dataStore.dictionary.group('user_functions')?.filter(d => d.pickable)
            );
        },
        departments() {
            return Object.values(
                this.dataStore.dictionary.group('departments')?.filter(d => d.pickable)
            );
        },
        statuses() {
            return Object.values(
                this.dataStore.dictionary.group('employee_status')?.filter(d => d.pickable)
            );
        },
        contactRequiredRule() {
            if (!this.mounted) return;
            if (this.authStore?.tenant?.attributes?.auto_pin_generation === true && !this.currentlyEditedUser?.phone && !this.currentlyEditedUser?.email) return `${this.$t('common.phone')} ${this.$t('common.or')} ${this.$t('common.email')} - ${this.$t('common.required')} `;
            return true;
        }
    },
};
</script>
