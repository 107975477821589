<template>
    <div class="survey-container" id="survey-container">
        <div v-if="$getPropertyTranslation(survey?.description)"
            class="survey-section-name Text HeadingH8-2024 survey-description">
            {{ $getPropertyTranslation(quiz.description) }}
        </div>
        <div class="multi-page-survey">
            <!--TODO chwilowo zakomentowane bo przy kreatorze nie dziala walidacja wielu stron-->
            <!-- <MyStepper :steps="steps" v-model="step" /> -->
            <div class="survey-section" v-for="section in [...currentPageSections].sort(
                (a, b) => a.order - b.order
            )" :key="section.id">
                <div v-if="$getPropertyTranslation(section?.name)" class="survey-section-name Text HeadingH8-2024">
                    {{ $getPropertyTranslation(section.name) }}
                </div>
                <div v-if="$getPropertyTranslation(section?.description)"
                    class="survey-section-name Text HeadingH8-2024 section-description">
                    {{ $getPropertyTranslation(section.description) }}
                </div>

                <QuizQuestion v-for="question in [...section.questions].sort(
                    (a, b) => a.order - b.order
                )" :field="question" :key="question.id" :groupedPreview="preview?.type === 'grouped'" @answer="(val) =>
                    onQuestionAnswer(
                        section.id,
                        question.id,
                        val
                    )
                    " :parameters="survey?.parameters" :userContext="userContext" />
            </div>
        </div>
    </div>
</template>

<script>
import QuizQuestion from './QuizQuestion.vue';
export default {
    props: ['quiz', 'preview', 'userContext', 'currentPageSections'],
    components: {
        QuizQuestion
    },
}
</script>