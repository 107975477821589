<template>
    <SignaloDialog
        :open="true"
        :title="`${$t('common.remove')} - ${folder?.name} `"
        @close="closeDialog"
        :constHeight="false"
    >
        <template #body>
            <v-card-text>
                <div class="Label-Text">
                    {{ $t('common.removeFolderFilesQuestion') }}
                </div>
            </v-card-text>
            <SignaloPageLoading v-if="loading" />
            <v-form
                v-else
                ref="rejectionReasonForm"
                v-model="dataValid"
                lazy-validation
            >
                <v-col cols="12" md="12" xl="12">
                    <SignaloTabsToggle
                        class="remove-folder-tabs-toggle"
                        :buttons="[
                            {
                                value: 'delete',
                                label: $t(`common.remove`),
                            },
                            {
                                value: 'move',
                                label: $t(`common.move`),
                            },
                        ]"
                        v-model="filesAction"
                        :mandatory="true"
                    />
                    <div
                        class="move-files-dictionary-container"
                        v-if="filesAction === 'move'"
                    >
                        <SignaloItemPicker
                            :dialogTitle="$t('common.folder')"
                            v-model="filesDestination"
                            :placeholder="$t('common.targetFolder')"
                            :nameValue="'name'"
                            :tree="true"
                            :source="allFolders"
                            :treeProperty="'parent_id'"
                            :clearable="true"
                            :filteredItems="[folder?.id]"
                        />
                    </div>
                </v-col>
            </v-form>
        </template>

        <template #buttons>
            <v-btn @click="closeDialog">
                {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
                color="error"
                variant="elevated"
                @click="onRemove"
                :loading="saveLoading"
                :disabled="!filesAction"
            >
                {{ $t('common.confirm') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    emits: ['close'],
    props: ['folder'],
    data() {
        return {
            filesAction: 'delete',
            filesDestination: null,
            allFolders: [],
            loading: false,
        };
    },
    mounted() {
        this.loadAllFolders();
    },

    methods: {
        closeDialog() {
            this.$emit('close');
        },
        async loadAllFolders() {
            try {
                this.loading = true;
                const allFoldersData = await this.axios.get(
                    'file_directories_all'
                );
                this.allFolders = allFoldersData?.data?.items || [];
            } catch (err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        async onRemove() {
            try {
                let url = `file_directories/${this.folder?.id}?files_mode=${this.filesAction}`;
                if (this.filesAction === 'move' && this.filesDestination) {
                    url += `&move_to=${this.filesDestination}`;
                }
                await this.axios.delete(url);
                this.helpersStore.snackbarSaved();
                this.$emit('reload');
                this.closeDialog();
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>

<style>
.remove-folder-tabs-toggle {
    margin-top: 0;
    min-height: unset;
}
</style>
