<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.configuration') }} </template>
        <template #bar>
            <v-btn @click="() => loadData()" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-btn
                @click="() => onManage()"
                v-if="$route.meta.canCreate"
                color="primary"
                variant="elevated"
                class="add-button"
            >
                <v-icon> mdi-plus </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.add') }}
                </span>
            </v-btn>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <EasyDataTable buttons-pagination
            :headers="tableHeaders"
            :items="items"
            :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            :loading="loading"
        >
            <template #item-enabled="item">
                <v-icon v-if="item?.enabled">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <template #item-has_headers="item">
                <v-icon v-if="item?.has_headers">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <template #item-leave_file="item">
                <v-icon v-if="item?.leave_file">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <template #item-operation="item">
                <div class="actions-column">
                    <v-menu offset-y v-if="$route.meta.canUpdate || $route.meta.canDelete">
                        <template v-slot:activator="{ props }">
                            <v-btn
                                color="primary"
                                v-bind="props"
                                variant="outlined"
                                icon
                                class="table-action-icon-button"
                            >
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="onManage(item)"
                                v-if="$route.meta.canUpdate"
                            >
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-pencil
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.edit')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click="onRemove(item)"
                                v-if="$route.meta.canDelete"
                            >
                                <v-list-item-title class="menu-action">
                                    <v-icon class="operation-icon">
                                        mdi-delete
                                    </v-icon>
                                    <span class="Text Navigation-2">{{
                                        $t('common.remove')
                                    }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </EasyDataTable>
    </v-main>
    <SynchronizationImportManageVue
        v-if="manageModal"
        v-model:open="manageModal"
        :editedItem="editedItem"
        @save="loadData"
    />
</template>

<script>
import SynchronizationImportManageVue from './SynchronizationImportManage.vue';
export default {
    components: {
        SynchronizationImportManageVue,
    },
    data() {
        return {
            loading: false,
            items: [],
            manageModal: false,
            editedItem: null,
        };
    },
    mounted() {
        this.loadData();
    },
    computed: {
        tableHeaders() {
            return [
                { text: this.$t('synchronization.enabled'), value: 'enabled' },
                {
                    text: this.$t('synchronization.hasHeaders'),
                    value: 'has_headers',
                },
                {
                    text: this.$t('synchronization.leaveFile'),
                    value: 'leave_file',
                },
                {
                    text: this.$t('synchronization.encoding'),
                    value: 'encoding',
                },
                {
                    text: this.$t('synchronization.protocol'),
                    value: 'protocol',
                },
                {
                    text: this.$t('synchronization.username'),
                    value: 'username',
                },
                { text: this.$t('synchronization.host'), value: 'host' },
                { text: this.$t('synchronization.port'), value: 'port' },
                { text: this.$t('synchronization.root'), value: 'root' },
                {
                    text: this.$t('synchronization.separator'),
                    value: 'separator',
                },
                {
                    text: this.$t('synchronization.externalSystemId'),
                    value: 'external_system_id',
                },
                {
                    text: this.$t('synchronization.cronExpression'),
                    value: 'cron_expression',
                },
                {
                    value: 'operation',
                    width: 54,
                    fixed: true,
                },
            ];
        },
    },
    methods: {
        async loadData() {
            this.loading = true;
            try {
                const imports = await this.axios.get(
                    'sync/import_stock_configs'
                );

                this.items = imports?.data?.items || [];
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        onManage(item = {}) {
            this.editedItem = item;
            this.manageModal = true;
        },
        async onRemove(item) {
            try {
                await this.axios.delete(`sync/import_stock_configs/${item.id}`);
                await this.loadData();
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
        },
    },
};
</script>
