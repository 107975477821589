<template>
    <div class="issue-defaults-container">
        <v-switch
            v-if="terminal"
            :label="$t('common.inheritValuesFromTenantSettings')"
            color="primary"
            v-model="issue_defaults.inheritValuesFromTenantSettings"
        ></v-switch>
        <div v-if="!issue_defaults?.inheritValuesFromTenantSettings">
            <div class="Text Label-Text issue-defaults-label">
                {{ $t('common.defaultValues') }}
            </div>
            <v-row>
                <v-col cols="12" md="6" xl="6" class="issue-defauls-column">
                    <v-text-field
                        v-model="issue_defaults.title"
                        variant="outlined"
                        color="var(--greyscale-100)"
                        :label="$t('tableHeaders.title')"
                        :rules="[
                            isDefaultValueRequired('title')
                                ? $REQUIRED_RULE
                                : null,
                        ]"
                        :class="
                            isDefaultValueRequired('title')
                                ? 'required-field'
                                : ''
                        "
                    >
                    </v-text-field>

                    <v-checkbox
                        color="primary"
                        :label="$t('common.hidden')"
                        v-model="hidden_fields.title"
                    ></v-checkbox>

                    <!--Tytuł jest zawsze wymagany bo walidacja sie wywala-->
                    <v-checkbox
                        color="primary"
                        :label="$t('common.required')"
                        :modelValue="true"
                        :disabled="true"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" xl="6" class="issue-defauls-column">
                    <SignaloNewValueSelect
                        :selectableType="SELECTABLE_TYPES.EQUIPMENT"
                        :label="
                            preferedEquipmentKind === null
                                ? $t('tableHeaders.equipment')
                                : $t(`common.${preferedEquipmentKind}`)
                        "
                        v-model="issue_defaults.equipment"
                        :equipmentKind="preferedEquipmentKind"
                        :rules="[
                            isDefaultValueRequired('equipment')
                                ? $REQUIRED_RULE
                                : null,
                        ]"
                        :required="!!isDefaultValueRequired('equipment')"
                        :clearable="true"
                    />

                    <v-checkbox
                        color="primary"
                        :label="$t('common.hidden')"
                        v-model="hidden_fields.equipment"
                    ></v-checkbox>
                    <v-checkbox
                        color="primary"
                        :label="$t('common.required')"
                        v-model="required_fields.equipment"
                    ></v-checkbox>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    xl="6"
                    v-if="
                        authStore?.tenant?.attributes
                            ?.create_issue_criticality !== 'hide'
                    "
                    class="issue-defauls-column"
                >
                    <SignaloNewValueSelect
                        :selectableType="SELECTABLE_TYPES.DICTIONARY"
                        :dictionary="'issue_criticality'"
                        :label="$t('searchConfig.criticality')"
                        v-model="issue_defaults.criticality"
                        :required="isDefaultValueRequired('criticality')"
                        :clearable="!isDefaultValueRequired('criticality')"
                        :rules="[
                            isDefaultValueRequired('criticality')
                                ? $REQUIRED_RULE
                                : null,
                        ]"
                    />
                    <v-checkbox
                        color="primary"
                        :label="$t('common.hidden')"
                        v-model="hidden_fields.criticality"
                    ></v-checkbox>

                    <v-checkbox
                        color="primary"
                        :label="$t('common.required')"
                        v-model="required_fields.criticality"
                    ></v-checkbox>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    xl="6"
                    v-if="authStore?.tenant?.attributes?.issue_kind !== 'hide'"
                    class="issue-defauls-column"
                >
                    <v-select
                        variant="outlined"
                        color="var(--greyscale-100)"
                        :label="$t('tableHeaders.kind')"
                        v-model="issue_defaults.kind"
                        :items="
                            Object.values(
                                dataStore.dictionary
                                    .group('issue_kind')
                                    ?.filter((d) => d.pickable)
                            )
                        "
                        item-title="value"
                        item-value="keyname"
                        :class="[
                            isDefaultValueRequired('kind')
                                ? 'required-field'
                                : '',
                        ]"
                        :clearable="!isDefaultValueRequired('kind')"
                        :rules="[
                            isDefaultValueRequired('kind')
                                ? $REQUIRED_RULE
                                : null,
                        ]"
                    >
                    </v-select>
                    <v-checkbox
                        color="primary"
                        :label="$t('common.hidden')"
                        v-model="hidden_fields.kind"
                    ></v-checkbox>
                    <v-checkbox
                        color="primary"
                        :label="$t('common.required')"
                        v-model="required_fields.kind"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6" xl="6" class="issue-defauls-column">
                    <SignaloNewValueSelect
                        :selectableType="SELECTABLE_TYPES.DICTIONARY"
                        :dictionary="'prod_lines'"
                        :label="$t('common.productionLine')"
                        v-model="issue_defaults.prod_line"
                        :rules="[
                            isDefaultValueRequired('prod_line')
                                ? $REQUIRED_RULE
                                : null,
                        ]"
                        :required="isDefaultValueRequired('prod_line')"
                        :clearable="true"
                    />
                    <v-checkbox
                        color="primary"
                        :label="$t('common.hidden')"
                        v-model="hidden_fields.prod_line"
                    ></v-checkbox>

                    <v-checkbox
                        color="primary"
                        :label="$t('common.required')"
                        v-model="required_fields.prod_line"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6" xl="6" class="issue-defauls-column">
                    <SignaloNewValueSelect
                        :selectableType="SELECTABLE_TYPES.DICTIONARY"
                        :dictionary="'departments'"
                        :label="$t('tableHeaders.department')"
                        v-model="issue_defaults.department"
                        :rules="[
                            isDefaultValueRequired('department')
                                ? $REQUIRED_RULE
                                : null,
                        ]"
                        :required="isDefaultValueRequired('department')"
                        :clearable="true"
                    />
                    <v-checkbox
                        color="primary"
                        :label="$t('common.hidden')"
                        v-model="hidden_fields.department"
                    ></v-checkbox>

                    <v-checkbox
                        color="primary"
                        :label="$t('common.required')"
                        v-model="required_fields.department"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6" xl="6" class="issue-defauls-column">
                    <SignaloNewValueSelect
                        :selectableType="SELECTABLE_TYPES.GROUPS"
                        :groupTypes="['service']"
                        :label="$t('common.service')"
                        multiple
                        v-model="issue_defaults.groups"
                        hide-details
                        :rules="[
                            isDefaultValueRequired('groups')
                                ? $REQUIRED_RULE
                                : null,
                        ]"
                        :required="isDefaultValueRequired('groups')"
                        :clearable="true"
                    />
                    <v-checkbox
                        color="primary"
                        :label="$t('common.hidden')"
                        v-model="hidden_fields.groups"
                    ></v-checkbox>
                    <v-checkbox
                        color="primary"
                        :label="$t('common.required')"
                        v-model="required_fields.groups"
                    ></v-checkbox>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="10" xl="10" class="issue-defauls-column">
                    <v-textarea
                        v-model="issue_defaults.user_description"
                        variant="outlined"
                        color="var(--greyscale-100)"
                        :label="$t('issues.issueDescription')"
                        :rules="[
                            isDefaultValueRequired('user_description')
                                ? $REQUIRED_RULE
                                : null,
                        ]"
                        :class="
                            isDefaultValueRequired('user_description')
                                ? 'required-field'
                                : ''
                        "
                    >
                    </v-textarea>
                    <v-checkbox
                        color="primary"
                        :label="$t('common.hidden')"
                        v-model="hidden_fields.user_description"
                    ></v-checkbox>
                    <v-checkbox
                        color="primary"
                        :label="$t('common.required')"
                        v-model="required_fields.user_description"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="display_options"
                        color="primary"
                        :label="$t('common.multiline')"
                        v-model="display_options.user_description.multiline"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="display_options"
                        color="primary"
                        :label="$t('common.expanded')"
                        v-model="display_options.user_description.expanded"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';

export default {
    name: 'IssuesDefaultValues',
    props: [
        'defaults',
        'hiddenFields',
        'requiredFields',
        'issueAllFieldsRequired',
        'terminal',
        'displayOptions',
    ],
    setup() {
        const authStore = AUTH_STORE.default();
        const dataStore = DATA_STORE.default();
        return { authStore, dataStore };
    },
    data() {
        return {
            issue_defaults: this.defaults ? { ...this.defaults } : {},
            hidden_fields: this.hiddenFields ? { ...this.hiddenFields } : {},
            required_fields: this.requiredFields
                ? { ...this.requiredFields }
                : {},
            display_options: null,
            fields: [
                'title',
                'equipment',
                'criticality',
                'prod_line',
                'user_description',
                'kind',
                'groups',
            ],
            everyHiddenAndRequiredFieldHasDefaultValue: false,
        };
    },
    mounted() {
        this.initDisplayOptions();
        this.checkEveryHiddenAndRequiredFieldHasDefaultValue();
    },
    methods: {
        initDisplayOptions() {
            if (
                !this.displayOptions ||
                JSON.stringify(this.displayOptions) === '{}'
            ) {
                this.display_options = {};
                this.fields.forEach((field) => {
                    this.display_options[field] = {};
                });
            } else {
                this.display_options = {...this.displayOptions}
            }
        },
        isValueRequired(fieldName) {
            return this.required_fields?.[fieldName] === true;
        },
        isDefaultValueRequired(fieldName) {
            return (
                this.isValueRequired(fieldName) &&
                this.hidden_fields?.[fieldName] == true
            );
        },
        checkEveryHiddenAndRequiredFieldHasDefaultValue() {
            this.everyHiddenAndRequiredFieldHasDefaultValue = this.fields
                .filter((f) => this.hidden_fields[f] && this.required_fields[f])
                .every((hiddenAndRequired) => {
                    if (Array.isArray(this.issue_defaults[hiddenAndRequired]))
                        return (
                            this.issue_defaults[hiddenAndRequired]?.length > 0
                        );
                    else {
                        return this.issue_defaults[hiddenAndRequired];
                    }
                });
            return this.everyHiddenAndRequiredFieldHasDefaultValue;
        },
    },
    computed: {
        preferedEquipmentKind() {
            if (
                !this.authStore?.tenant?.attributes?.[
                    'new_issue_equipment_kind'
                ] ||
                this.authStore?.tenant?.attributes?.[
                    'new_issue_equipment_kind'
                ] === 'all'
            )
                return null;
            return this.authStore?.tenant?.attributes?.[
                'new_issue_equipment_kind'
            ];
        },
        criticalityRules() {
            if (
                this.authStore?.tenant?.attributes?.issue_criticality ==
                'require'
            ) {
                return [(v) => !!v || this.$t('common.fieldRequired')];
            }
            return [];
        },
        kindRules() {
            if (this.authStore?.tenant?.attributes?.issue_kind == 'require') {
                return [(v) => !!v || this.$t('common.fieldRequired')];
            }
            return [];
        },
    },
    watch: {
        display_options: {
            handler(val) {
                this.checkEveryHiddenAndRequiredFieldHasDefaultValue();
                this.$emit('update:modelValue:display_options', val); 
            },
            deep: true,
        },
        issue_defaults: {
            handler(val) {
                this.checkEveryHiddenAndRequiredFieldHasDefaultValue();
                this.$emit('update:modelValue:defaults', val);
            },
            deep: true,
        },
        hidden_fields: {
            handler(val) {
                this.checkEveryHiddenAndRequiredFieldHasDefaultValue();
                this.$emit('update:modelValue:hidden_fields', val);
            },
            deep: true,
        },
        required_fields: {
            handler(val) {
                this.checkEveryHiddenAndRequiredFieldHasDefaultValue();
                this.$emit('update:modelValue:required_fields', val);
            },
            deep: true,
        },
        everyHiddenAndRequiredFieldHasDefaultValue(val) {
            this.$emit('everyHiddenAndRequiredFieldHasDefaultValue', val);
        },
    },
};
</script>

<style scoped>
.issue-defaults-container {
    padding: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.issue-defaults-container .v-row {
    width: 100%;
}
.issue-defaults-label {
    margin-bottom: 12px;
}
.issue-defauls-column {
    display: flex;
}
</style>

<style>
.issue-defaults-container .signalo-new-value-select,
.issue-defaults-container .v-input:not(.v-checkbox, .v-textarea) {
    width: -webkit-fill-available;
    max-width: 300px;
}
</style>
