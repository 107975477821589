<template>
    <SignaloAppBar>
        <template #title v-if="!helpersStore.isMobileView">
            {{ $t('common.configuration') }}
        </template>
        <template #bar>
            <v-btn
                @click="() => reloadClicked()"
                class="submain-button renew-button"
            >
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-menu offset-y v-if="$route.meta.canCreate">
                <template v-slot:activator="{ props }">
                    <v-btn
                        v-bind="props"
                        color="primary"
                        variant="elevated"
                        class="submain-button renew-button"
                    >
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <!-- <v-list-item @click="onCategoriesExport()">
                        <v-list-item-title>
                            <v-icon> mdi-file-download </v-icon>
                            <span>
                                {{ $t('common.export') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item
                        @click="openAddCategoryDialog"
                        v-if="$route.meta.canCreate"
                    >
                        <v-list-item-title>
                            <v-icon> mdi-plus </v-icon>
                            <span>
                                {{ $t('common.add') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </SignaloAppBar>
    <v-main>
        <div class="categories-breadcrumbs-container">
            <v-btn
                :disabled="!this.currentId"
                icon
                class="return-button"
                color="primary"
                variant="elevated"
                @click="
                    currentId =
                        this.dataStore.categories.getById(this.currentId)
                            .parent_id ?? 0
                "
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <SignaloBreadCrumbs :items="breadcrumb" />
        </div>
        <PageLoading v-if="loading" />
        <v-row>
            <v-col>
                <div class="py-3">
                    <!-- <div class="search-category-input-container">
                        <v-text-field
                            :placeholder="$t('common.search')"
                            class="table-search-input"
                            v-model="searchText"
                            @click:clear="searchText = ''"
                            clearable
                            clear-icon="mdi-close-circle-outline"
                            variant="outlined"
                            hide-details
                        ></v-text-field>
                    </div> -->
                    <EasyDataTable
                        buttons-pagination
                        :headers="tableHeaders"
                        :items="items"
                        :search-value="searchText"
                        :search-field="'name'"
                        :empty-message="$t('common.emptyTableMessage')"
                        theme-color="#fd9d09"
                        @click-row="handleSelectCategory"
                        table-class-name="easy-table-style"
                        :rows-per-page-message="$t('common.rowsPerPage')"
                        :rows-of-page-separator-message="
                            $t('common.rowsOfPage')
                        "
                    >
                        <template #item-name="item">{{
                            $getPropertyTranslation(item?.name)
                        }}</template>
                        <template #item-children="item">
                            <div class="operation-wrapper">
                                <v-icon
                                    class="operation-icon"
                                    @click="
                                        ($event) =>
                                            handleSelectCategory(item, $event)
                                    "
                                >
                                    mdi-folder-arrow-right-outline
                                </v-icon>
                            </div>
                        </template>
                        <template #item-icon="item">
                            <v-icon :icon="item.icon" v-if="item.icon"></v-icon>
                        </template>
                        <template #item-color="item">
                            <div class="picked-color-container">
                                <div
                                    v-if="item.color || item.color_inherited"
                                    :style="`background:${
                                        item.color ?? item.color_inherited
                                    };`"
                                    class="picked-color"
                                ></div>
                            </div>
                        </template>
                        <template #item-sort-change="item">
                            <div class="sort-change-container">
                                <v-icon
                                    class="sort-change-arrow"
                                    @click="
                                        onMove(
                                            $event,
                                            item,
                                            'up',
                                            items
                                                .map((x) => x.id)
                                                .indexOf(item.id)
                                        )
                                    "
                                    v-if="
                                        items
                                            .map((x) => x.id)
                                            .indexOf(item.id) !== 0
                                    "
                                    >mdi-arrow-up</v-icon
                                >
                                <v-icon
                                    class="sort-change-arrow"
                                    @click="
                                        onMove(
                                            $event,
                                            item,
                                            'down',
                                            items
                                                .map((x) => x.id)
                                                .indexOf(item.id)
                                        )
                                    "
                                    v-if="
                                        items
                                            .map((x) => x.id)
                                            .indexOf(item.id) !==
                                        items?.length - 1
                                    "
                                    >mdi-arrow-down</v-icon
                                >
                            </div>
                        </template>
                        <template #item-hidden="item">
                            <v-icon v-if="item?.hidden">mdi-check</v-icon>
                            <v-icon v-else>mdi-close</v-icon>
                        </template>
                        <template #item-operation="item">
                            <div class="actions-column">
                                <SignaloDynamicMenu
                                    :rowItem="item"
                                    :menuItems="tableActions"
                                />
                            </div>
                        </template>
                    </EasyDataTable>
                </div>
            </v-col>
        </v-row>
        <CategoryManage
            v-if="categoryDialog"
            :open="categoryDialog"
            :category="currentlyEditedCategory"
            @close="closeEditCategoryDialog"
            :currentId="currentId"
        />

        <SignaloConfirmationDialog
            v-if="removeDialog"
            :dialogOpened="removeDialog"
            @update:dialogOpened="handleRemoveCategory"
        >
        </SignaloConfirmationDialog>

        <SignaloExportDialog
            v-if="exportDialog"
            :open="exportDialog"
            :endpoint="exportEndpoint"
            @close="exportDialog = false"
            :allPagesAllowed="exportAllPagesAllowed"
        />
    </v-main>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

import CategoryManage from './CategoryManage.vue';

export default {
    components: {
        CategoryManage,
    },
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: false,
            searchText: '',
            currentId: 0,
            prevId: 0,
            editingId: null,
            editingLoading: false,

            categoryDialog: false,
            currentlyEditedCategory: null,
            removeDialog: false,
            itemToRemove: null,

            saveLoading: false,
            exportEndpoint: null,
            exportDialog: false,

            exportAllPagesAllowed: false,
        };
    },
    computed: {
        tableActions() {
            return [
                {
                    icon: 'mdi-account-group',
                    label: this.$t('common.groups'),
                    action: this.goToGroups,
                    access: [this.$route.meta.canUpdate],
                },
                {
                    icon: 'mdi-tune',
                    label: this.$t('common.parameters'),
                    action: this.goToParameters,
                    access: [this.$route.meta.canUpdate],
                },
                {
                    icon: 'mdi-pencil',
                    label: this.$t('common.edit'),
                    action: this.openEditCategoryDialog,
                    access: [this.$route.meta.canUpdate],
                },
                {
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    action: this.openRemoveDialog,
                    access: [this.$route.meta.canDelete],
                },
            ];
        },
        items() {
            return this.dataStore.categories
                .filter((x) => (x.parent_id ?? 0) == this.currentId)
                .sort((x, y) => {
                    if (x.sort > y.sort) return 1;
                    if (x.sort < y.sort) return -1;
                    if (x.id > y.id) return 1;
                    if (x.id < y.id) return -1;
                    return 0;
                });
        },
        currentName() {
            if (this.currentId == 0) return this.$t('common.locationsTitle');
            return this.dataStore.categories.getById(this.currentId).name;
        },
        breadcrumb() {
            let bcs = [];
            let nextID = this.currentId;

            if (!nextID) return [];

            do {
                let next = this.dataStore.categories.getById(nextID);
                bcs.unshift({
                    text: this.$getPropertyTranslation(next.name),
                    disabled: true,
                    href: '#',
                });
                nextID = next.parent_id;
            } while (nextID);
            return bcs;
        },
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.name'),
                    value: `name`,
                },
                { text: this.$t('common.hidden'), value: 'hidden' },
                { text: this.$t('tableHeaders.icon'), value: 'icon' },
                { text: this.$t('tableHeaders.color'), value: 'color' },
                {
                    text: this.$t('tableHeaders.subCategories'),
                    value: 'children',
                },
                {
                    text: this.$t('tableHeaders.changeOrder'),
                    value: 'sort-change',
                },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
    },
    mounted() {
        this.loading = true;
        Promise.allSettled([this.dataStore.categories.promise()]).then(() => {
            this.loading = false;
        });
    },

    methods: {
        updateCategory(item) {
            this.editingLoading = true;

            this.axios
                .put(`/categories/${item.id}`, item)
                .then(() => {
                    this.editingId = null;
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                })
                .then(() => {
                    this.editingLoading = false;
                });
        },
        handleSelectCategory(item, $event = null) {
            if ($event) {
                $event.stopPropagation();
                $event.preventDefault();
            }
            if (this.editingId != item.id) {
                this.prevId = this.currentId;
                this.currentId = item.id;
            }
        },
        handleRemoveCategory(shouldRemove) {
            if (!shouldRemove) {
                this.closeRemoveDialog();
                return;
            }
            this.axios
                .delete(`/categories/${this.itemToRemove.id}`)
                .then(() => {
                    return this.dataStore.categories.reload();
                })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('common.removed'),
                        'success'
                    );
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                })
                .finally(() => {
                    this.closeRemoveDialog();
                });
        },
        openEditCategoryDialog(item) {
            this.currentlyEditedCategory = {
                issue_generates_cost: false,
                ...item,
                color: item.is_color_inherited ? null : item.color,
            };
            this.categoryDialog = true;
        },

        closeEditCategoryDialog() {
            this.currentlyEditedCategory = null;
            this.categoryDialog = false;
        },
        onMove($event, item, direction, rowIndex) {
            $event.stopPropagation();

            let siblingElement, siblingElementSort, siblingElementUpdate;
            direction === 'up'
                ? (siblingElement = this.items[rowIndex - 1])
                : (siblingElement = this.items[rowIndex + 1]);
            if (!siblingElement) return;

            siblingElementSort = siblingElement.sort;
            siblingElement.sort = item.sort;
            item.sort = siblingElementSort;
            siblingElementUpdate = this.axios.put(
                `categories/${siblingElement.id}`,
                siblingElement
            );
            const elementUpdate = this.axios.put(`categories/${item.id}`, item);
            Promise.all([siblingElementUpdate, elementUpdate])
                .then(() => {
                    return this.dataStore.categories.reload();
                })
                .then(() => {
                    this.helpersStore.snackbar(
                        this.$t('common.updated'),
                        'success'
                    );
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                });
        },
        openAddCategoryDialog() {
            this.currentlyEditedCategory = {
                name: { pl: '' },
                issue_generates_cost: false,
                planned: false,
            };
            this.categoryDialog = true;
        },
        reloadClicked() {
            this.loading = true;
            this.dataStore.categories.reload().finally(() => {
                this.loading = false;
            });
        },
        openRemoveDialog(item) {
            this.itemToRemove = item;
            this.removeDialog = true;
        },
        closeRemoveDialog() {
            this.removeDialog = false;
            this.itemToRemove = null;
        },
        goToParameters(item) {
            this.$router.push({
                name: 'settings.categories-parameters',
                params: { id: item.id },
            });
        },
        goToGroups(item) {
            this.$router.push({
                name: 'settings.categories-groups',
                params: { id: item.id },
            });
        },

        onCategoriesExport(id = null) {
            this.exportAllPagesAllowed = !id;
            this.exportEndpoint = 'categories';
            if (id !== null) {
                this.exportEndpoint += `/${id}`;
            }
            this.exportDialog = true;
        },
    },
};
</script>

<style>
.vue3-easy-data-table__body tr:hover {
    cursor: pointer;
}

.operation-icon {
    cursor: pointer;
}

.operation-icon:not(:last-of-type) {
    margin-right: 4px;
}
.category-edit-content {
    margin-top: 16px;
}
.category-color-container {
    margin-top: 12px;
    display: flex;
    align-items: center;
}
.color-preview {
    margin-left: 12px;
}
.picked-color {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid var(--greyscale-50);

    min-width: 32px;
}

.categories-breadcrumbs-container {
    display: flex;
    align-items: center;
}
.categories-breadcrumbs-container .v-btn.return-button {
    width: 32px;
    height: 32px !important;
    font-size: 12px !important;
    margin-right: 4px;
}

.search-category-input-container {
    padding: 8px;
    padding-bottom: 0;
}
.v-card-text.category-form-inputs {
    padding-top: 0;
}

.picked-color-container {
    display: flex;
    align-items: center;
}
</style>
