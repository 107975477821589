<template>
    <SignaloAppBar>
        <template #title> {{ $t('equipmentTypes.equipment') }} </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <v-card class="mx-auto" width="800">
            <template v-slot:title>
                <v-toolbar class="preview-header">
                    <v-icon class="preview-header-icon">mdi-cog-outline</v-icon>
                    {{ $t('equipmentTypes.newEquipmentType') }}
                </v-toolbar>
            </template>

            <v-card-text>
                <v-window>
                    <v-form ref="dataForm" v-model="dataValid" lazy-validation>
                        <div class="equipment-type-tabs-toggle">
                            <SignaloTabsToggle
                                :modelValue="type"
                                :buttons="typeButtons"
                                :defaultTab="currentType"
                                :mandatory="true"
                                :key="currentType"
                                @update:modelValue="(value) => (type = value)"
                            />
                        </div>

                        <v-text-field
                            v-model="name"
                            :counter="250"
                            :rules="[$REQUIRED_RULE]"
                            :label="$t('common.name')"
                            required
                            variant="outlined"
                            color="var(--greyscale-100)"
                            class="required-field"
                        >
                        </v-text-field>

                        <SignaloDictionaryTreePicker
                            :group="dictionaryTreePickerGroup"
                            v-model="group"
                            v-slot="props"
                            ref="dictionaryTreePicker"
                        >
                            <v-text-field
                                :modelValue="props.text"
                                @click:control="props.onClick"
                                @click:clear="props.onClear"
                                clearable
                                readonly
                                hide-details
                                :label="$t('common.group')"
                                clear-icon="mdi-close-circle-outline"
                                variant="outlined"
                                color="var(--greyscale-100)"
                            />

                            <SignaloBarCode
                                ref="barCodeEditComponent"
                                :barcode="barcode"
                                :editable="true"
                                @onChange="onBarcodeChange"
                            />
                        </SignaloDictionaryTreePicker>
                    </v-form>
                </v-window>
            </v-card-text>
            <v-card-actions>
                <v-spacer> </v-spacer>
                <v-btn
                    :loading="dataSaving"
                    color="primary"
                    class="mr-4"
                    @click="saveData"
                    variant="elevated"
                    :disabled="barcode?.value && !barcode?.valid"
                >
                    {{ $t('common.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-main>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        return { dataStore, helpersStore };
    },
    data() {
        return {
            name: '',
            type: 'machine',
            group: null,
            barcode: null,

            // DATA
            dataValid: true,
            dataSaving: false,
            currentType: 'machine',
        };
    },
    computed: {
        dictionaryTreePickerGroup: function () {
            return this.type === 'machine' ? 'machine_groups' : 'tool_groups';
        },
        typeButtons() {
            return [
                {
                    value: 'machine',
                    label: this.$t('common.machine'),
                },
                {
                    value: 'tool',
                    label: this.$t('common.tool'),
                },
            ];
        },
    },
    methods: {
        onBarcodeChange(val) {
            this.barcode = val;
        },
        async saveData() {
            await this.$refs.dataForm.validate();
            if (this.dataValid) {
                this.dataSaving = true;
                const data = {
                    name: this.name,
                    type: this.type,
                    item_group: this.group,
                };
                if (this.barcode?.value && this.barcode?.valid) {
                    data.barcode = {
                        value: this.barcode.value,
                        type: this.barcode.type
                    };
                } 
                this.axios
                    .request({
                        url: `/equipment_types`,
                        method: 'POST',
                        data,
                    })
                    .then(({ data }) => {
                        this.helpersStore.snackbarSaved();
                        if (data.status == 'ok') {
                            setTimeout(() => {
                                this.$router.push({
                                    name: 'settings.equipment-types.edit',
                                    params: { id: data.item.id },
                                });
                            }, 1000);
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status == 401) return;
                    })
                    .finally(() => {
                        this.dataSaving = false;
                    });
            }
        },
        onTypeChange() {
            this.$refs.dictionaryTreePicker.clearClicked();
        },
    },
};
</script>

<style>
.equipment-type-tabs-toggle {
    padding-bottom: 16px;
}
</style>
