<template>
    <SignaloDialog
        :open="true"
        :title="
            $t('common.terminal') +
            ' ' +
            terminal.id +
            ' - ' +
            $getPropertyTranslation(terminal.name)
        "
        @close="closeDialog"
        :constHeight="false"
    >
        <template #body>
            <v-card-text class="terminal-qr-content">
                <qrcode-vue
                    style="cursor: pointer"
                    :value="qrContent"
                    :size="256"
                    level="H"
                    @click="() => copyLink(qrContent)"
                />
            </v-card-text>
        </template>

        <template #buttons>
            <v-btn @click="closeDialog">
                {{ $t('common.cancel') }}
            </v-btn>
        </template>
    </SignaloDialog>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    components: { QrcodeVue },
    props: ['terminal', 'qrContent'],
    emits: ['close'],
    methods: {
        copyLink(link) {
            this.helpersStore.copyToClipboard(link);
        },
        closeDialog() {
            this.$emit('close')
        }
    },
};
</script>
<style scoped>
.terminal-qr-content {
    display: flex;
    justify-content: center;
}
</style>