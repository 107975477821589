<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.courses') }} </template>
        <template #bar>
            <v-btn @click="() => loadData()" class="submain-button renew-button">
                <v-icon> mdi-autorenew </v-icon>
                <span class="hidden-sm-and-down icon-button-label">
                    {{ $t('common.reload') }}
                </span>
            </v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" color="primary" variant="elevated" class="submain-button renew-button">
                        <span class="hidden-sm-and-down icon-button-label">
                            {{ $t('common.actions') }}
                        </span>
                        <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="addButtonClicked">
                        <v-list-item-title>
                            <v-icon start> mdi-plus </v-icon>
                            <span>
                                {{ $t('common.add') }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs />
        <EasyDataTable :headers="tableHeaders" :items="items" :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style" :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')" @click-row="handleClickRow"
            v-model:server-options="serverOptions" :server-items-length="serverItemsLength"
            :rows-items="[5, 10, 25, 50, 75, 100]" :rows-per-page="5" :loading="loading" name="quizzesTable">
            <template #item-name="item">

                {{ $getPropertyTranslation(item?.name) }}


            </template>
            <template #item-operation="item">
                <SignaloDynamicMenu :rowItem="item" :menuItems="getTableActions(item)" />
            </template>
        </EasyDataTable>
    </v-main>


    <SignaloConfirmationDialog v-if="removeDialog" :dialogOpened="removeDialog"
        @update:dialogOpened="(value) => removeQuiz(value)" />

    <ManageCourseDialog v-if="manageCourseDialogOpened" @close="closeManageDialog" :editedItem="editedItem" />
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import ManageCourseDialog from './ManageCourseDialog.vue';
export default {
    components: { ManageCourseDialog },
    setup() {
        const dataStore = DATA_STORE.default();
        const authStore = AUTH_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        return { dataStore, authStore, helpersStore };
    },
    data() {
        return {
            loading: false,
            items: [],
            serverItemsLength: 0,
            serverOptions: this.$getServerOptions('coursesTable'),
            manageCourseDialogOpened: false,
            removeDialog: false,
            itemToRemove: null,
            editedItem: null,
        };
    },
    mounted() {
        this.loadData();
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.id'),
                    value: 'id',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                    sortable: true,
                },

                {
                    value: 'operation',
                    width: 54,
                    fixed: true,
                    text: this.$t('tableHeaders.actions'),
                },
            ];
        },
    },
    methods: {
        async closeManageDialog(shouldRefetch = false) {
            try {
                if (shouldRefetch) {
                    await this.loadData();
                }
                this.manageCourseDialogOpened = false;
                this.editedItem = null;

            } catch (err) {
                console.error(err);
            }
        },
        getTableActions() {
            return [

                {
                    icon: 'mdi-format-list-numbered',
                    label: this.$t('tpm.steps'),
                    action: this.goToCourseSteps,
                },
                {
                    icon: 'mdi-pencil',
                    label: this.$t('common.edit'),
                    action: this.onEdit,
                    access: [this.$route.meta.canCreate],
                },
                {
                    action: this.openRemoveCourseDialog,
                    icon: 'mdi-delete',
                    label: this.$t('common.remove'),
                    access: [this.$route.meta.canDelete],
                },

            ];
        },

        goToCourseSteps(item) {
            this.$router.push({ name: 'settings.courses.steps', params: { id: item.id } });
        },

        onEdit(item) {
            this.editedItem = item;
            this.manageCourseDialogOpened = true;
        },

        addButtonClicked() {
            this.manageCourseDialogOpened = true;
        },
        openRemoveCourseDialog(item) {
            this.removeDialog = true;
            this.itemToRemove = item;
        },
        async removeQuiz(shouldRemove) {
            try {
                if (shouldRemove) {
                    await this.axios.delete(`courses/${this.itemToRemove.id}`);
                    this.helpersStore.snackbarSaved();
                    await this.loadData();
                }

                this.removeDialog = false;
                this.itemToRemove = null;
            } catch (err) {
                console.error(err);
            }
        },
        async loadData(search, resetPage) {
            this.loading = true;
            try {
                if (resetPage) {
                    this.serverOptions.page = 1;
                }
                if (search !== null) {
                    this.lastSearch = search;
                }
                const config = this.dataStore.makeServerOptions(
                    this.serverOptions,
                    this.lastSearch
                );
                const data = await this.axios.get('courses', config);

                this.items = data.data.items || [];
                this.serverItemsLength = data?.data?.meta?.total;
            } catch (err) {
                console.error(err);
            }
            this.loading = false;
        },


    },

    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences('quizzesTable', {
                default_size: val.rowsPerPage,
            });
            this.loadData();
        },
    },
};
</script>
