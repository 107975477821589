export default [
    {
        text: 'tableHeaders.name',
        value: 'name',
        sortable: true,
    },
    {
        text: 'tableHeaders.shortName',
        value: 'short_name',
        sortable: true,
    },
    {
        text: 'tableHeaders.group',
        value: 'customer_group',
        sortable: true,
    },
    {
        text: 'tableHeaders.phone',
        value: 'phone',
    },
    {
        text: 'tableHeaders.email',
        value: 'email',
        sortable: true,
    },
    {
        text: 'tableHeaders.country',
        value: 'country',
        sortable: true,
    },
    {
        text: 'tableHeaders.city',
        value: 'city',
        sortable: true,
    },
    {
        text: 'companies.postCode',
        value: 'post_code',
    },
    {
        text: 'companies.street',
        value: 'street',
    },
    {
        text: 'companies.NIP',
        value: 'tax_no',
    },
    {
        text: 'companies.REGON',
        value: 'tax_no2',
    },
    {
        text: 'companies.website',
        value: 'website',
    },
    {
        text: 'common.active',
        value: 'active',
    },

    { value: 'operation', fixed: true, width: 54 },
];
