<template>
    <v-card style="padding: 0 !important" class="overflow-visible">
        <EasyDataTable buttons-pagination
            :loading="loading"
            :items="takenParts"
            :headers="tableHeaders"
            :empty-message="$t('common.emptyTableMessage')"
            :table-class-name="'easy-table-style parts-taken-by-user-table'"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            @click-row="goToPartEntry"
            :rows-items="[5, 10, 25, 50]"
            v-model:server-options="partsTableServerOptions"
            :server-items-length="partsTableServerItemsLength"
        >
            <!-- <template #item-id="item">{{ item?.id }}</template> -->
            <template #item-name="item">{{ item?.part?.name }}</template>
            <template #item-expiry_date="item">{{
                item?.expiry_date || '-'
            }}</template>
            <template #item-symbol="item">{{ item?.part?.symbol }}</template>
            <template #item-awaits_return="item">
                <span v-if="item?.awaits_return">{{
                    $t('common.awaitsReturn')
                }}</span>
                <span v-else>{{ $t('common.partsStatusTaken') }}</span>
            </template>
            <template #item-unit="item">
                <span v-html="$displayDimension(item.unit)"></span
            ></template>
            <template #item-taken_location="item">{{
                dataStore.locations.getById(item?.part_entry?.location_id)?.name
            }}</template>
            <template #item-manufacturer_id="item">
                {{
                    item.manufacturer_id
                        ? dataStore.companies.getById(
                              item?.part?.manufacturer_id
                          )?.name
                        : '-'
                }}
            </template>
            <template #item-predicted_equipment_id="item">
                <span v-if="item?.predicted_equipment?.name">
                    {{ item?.predicted_equipment?.name || '-' }}&nbsp;({{
                        item?.predicted_equipment?.symbol
                    }})
                </span>
                <span v-else>-</span>
            </template>
            <template #item-predicted_department="item">
                {{
                    departments?.find(
                        (d) => d.keyname == item?.predicted_department
                    )?.value || '-'
                }}
            </template>
            <template #item-cost_center_id="item">
                {{ getCostCenterName(item?.cost_center_id) || '-' }}
            </template>
            <template #item-user_signature="item">
                <SignaloSignaturePreviewComponent
                    v-if="item?.take_user_signature?.value"
                    :value="item?.take_user_signature?.value"
                />
            </template>
            <template #item-approved_by_signature="item">
                <SignaloSignaturePreviewComponent
                    v-if="item?.take_approved_signature?.value"
                    :value="item?.take_approved_signature?.value"
                />
            </template>
        </EasyDataTable>
    </v-card>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, authStore };
    },
    props: ['id'],
    data() {
        return {
            loading: false,
            takenParts: [],
            returnPartDialog: false,
            partUsage: null,
            tableName: 'USER_HANDOVERS',
            partsTableServerOptions: {
                ...this.$getServerOptions('USER_HANDOVERS'),
                sortBy: 'entry_date',
                sortType: 'desc',
            },
            partsTableServerItemsLength: 0,
        };
    },
    mounted() {
        this.loading = true;
        this.dataStore.locations.reload().then(() => {
            this.loading = false;
        });
        this.loadData();
    },
    methods: {
        async loadData() {
            this.loading = true;
            try {
                const options = this.dataStore.issues.makeServerOptions(
                    this.partsTableServerOptions
                );
                options.params.expires_soon = '1';
                const partsData = await this.axios.get(
                    `users/${this.id}/parts`,
                    options
                );
                this.takenParts = Object.values(partsData?.data?.items) || [];

                this.partsTableServerItemsLength = partsData?.data?.meta?.total;
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        onReturnPartUsage($event, item) {
            $event.stopPropagation();
            $event.preventDefault();
            this.partUsageToReturn = item;
            this.returnPartDialog = true;
        },
        async onReturnPartDialogClose(shouldRefetch) {
            if (shouldRefetch) {
                await this.loadData();
            }
            this.returnPartDialog = false;
            this.partUsageToReturn = null;
        },
        goToPartEntry(item) {
            if (this.mode === 'select') {
                this.$emit('partSelected', item);
                return;
            }
            this.$router.push({
                name: 'parts.entries.show',
                params: {
                    id: item.part.id,
                    entryId: item.part_entry.id,
                },
            });
        },
        onPartSelect($event, item) {
            $event.stopPropagation();
            $event.preventDefault();
            this.$emit('partSelected', item);
        },
        getCostCenterName(id) {
            return this.$getPropertyTranslation(
                this.costCenters?.find((cc) => cc.id === id)?.name
            );
        },
    },
    watch: {
        partsTableServerOptions(val) {
            this.authStore.patchTablePreferences(this.tableName, {
                default_size: val.rowsPerPage,
            });
            this.loadData();
        },
    },
    computed: {
        departments() {
            return this.dataStore.dictionary.group('departments');
        },
        tableHeaders() {
            const headers = [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                },
                {
                    text: this.$t('common.quantity'),
                    value: 'quantity_available',
                },
                {
                    text: this.$t('common.unit'),
                    value: 'unit',
                },
                {
                    text: this.$t('common.takenAt'),
                    value: 'entry_date',
                },
                {
                    text: this.$t('common.expiresDate'),
                    value: 'expiry_date',
                },
                // {
                //     text: this.$t('common.takenFromLocation'),
                //     value: 'taken_location',
                // },
                {
                    text: this.$t('tableHeaders.manufacturerSymbol'),
                    value: 'symbol',
                },
                {
                    text: this.$t('tableHeaders.manufacturer'),
                    value: 'manufacturer_id',
                },
                {
                    text: this.$t('common.status'),
                    value: 'awaits_return',
                },
            ];
            return headers;
        },
    },
};
</script>

<style scoped>
.parts-taken-by-user-table {
    margin: 4px 0 !important;
}
</style>
