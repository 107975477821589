<template>
    <div class="synchronization-form-container">
        <v-form ref="syncForm" v-model="syncFormValid">
            <v-row>
                <v-col cols="12" md="4" xl="4">
                    <v-switch
                        v-model="item.enabled"
                        color="primary"
                        :label="$t('synchronization.enabled')"
                    ></v-switch>
                </v-col>
                <v-col cols="12" md="4" xl="4">
                    <v-switch
                        v-model="item.has_headers"
                        color="primary"
                        :label="$t('synchronization.hasHeaders')"
                    ></v-switch>
                </v-col>
                <v-col cols="12" md="4" xl="4">
                    <v-switch
                        v-model="item.leave_file"
                        color="primary"
                        :label="$t('synchronization.leaveFile')"
                    ></v-switch>
                </v-col>
                <v-col cols="12" md="12" xl="12">
                    <cron-vuetify
                        v-model="item.cron_expression"
                        :chip-props="{ color: 'primary', textColor: 'white' }"
                        @error="error = $event"
                        :locale="$i18n.locale"
                    />
                    <v-text-field
                        class="cron-expression-input required-field"
                        v-model="item.cron_expression"
                        :label="$t('synchronization.cronExpression')"
                        variant="outlined"
                        :rules="requiredField"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" xl="6">
                    <v-text-field
                        :label="$t('synchronization.host')"
                        variant="outlined"
                        class="required-field"
                        :rules="requiredField"
                        v-model="item.host"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" xl="6">
                    <v-text-field
                        :label="$t('synchronization.port')"
                        variant="outlined"
                        type="number"
                        max="65535"
                        min="1"
                        v-model="item.port"
                        :rules="portRules"
                        class="required-field"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" xl="6">
                    <v-select
                        :label="$t('synchronization.protocol')"
                        :items="protocols"
                        variant="outlined"
                        class="required-field"
                        :rules="requiredField"
                        v-model="item.protocol"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="6" xl="6">
                    <v-text-field
                        :label="$t('synchronization.username')"
                        variant="outlined"
                        v-model="item.username"
                        class="required-field"
                        :rules="requiredField"
                    ></v-text-field
                ></v-col>
                <v-col cols="12" md="6" xl="6">
                    <v-text-field
                        :label="$t('synchronization.password')"
                        variant="outlined"
                        v-model="item.password"
                        class="required-field"
                        :rules="requiredField"
                        type="password"
                    ></v-text-field
                ></v-col>
                <v-col cols="12" md="6" xl="6">
                    <v-text-field
                        :label="$t('synchronization.root')"
                        variant="outlined"
                        v-model="item.root"
                        :rules="startsWithSlash"
                        class="required-field"
                    ></v-text-field
                ></v-col>
                <v-col cols="12" md="6" xl="6">
                    <v-select
                        :label="$t('synchronization.encoding')"
                        :items="encodings"
                        variant="outlined"
                        class="required-field"
                        :rules="requiredField"
                        v-model="item.encoding"
                    ></v-select
                ></v-col>
                <v-col cols="12" md="6" xl="6">
                    <v-text-field
                        :label="$t('synchronization.separator')"
                        variant="outlined"
                        v-model="item.separator"
                    ></v-text-field
                ></v-col>
                <v-col cols="12" md="6" xl="6">
                    <v-text-field
                        :label="$t('synchronization.externalSystemId')"
                        variant="outlined"
                        class="required-field"
                        :rules="requiredField"
                        v-model="item.external_system_id"
                    ></v-text-field
                ></v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
export default {
    props: ['mode', 'sync', 'protocols', 'encodings'],
    data() {
        return {
            item: this.sync || {},
            cronExpression: '',
            syncFormValid: false,
            requiredField: [(v) => !!v || this.$t('common.fieldRequired')],
            startsWithSlash: [
                (v) => v?.[0] === '/' || this.$t('errors.mustStartWithSlash'),
            ],
            portRules: [
                (v) =>
                    (v < 65535 && v >= 1) ||
                    this.$t('errors.mustBeBetween') + '1 - 65534',
            ],
        };
    },
    methods: {
        async validateForm() {
            await this.$refs.syncForm.validate();
            return this.syncFormValid;
        },
    },
};
</script>

<style>
.cron-expression-input {
    margin-top: 32px;
}
</style>
