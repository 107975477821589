<template>
    <div
        style="
            display: flex;
            height: 100%;
            overflow: visible;
            flex-direction: column;
        "
        @click="() => onGroupEventClick(events[0])"
    >
        <div class="group-events-title" style="overflow: hidden">
            <i class="mdi mdi-format-list-group many-events-icon"></i>

            <div class="many-events-title">
                {{ ctx.$t('common.manyEventsThisTime') }}...
            </div>
        </div>
        <div class="events-list-container">
            <i
                :class="`mdi mdi-chevron-${
                    listOpened ? 'up' : 'down'
                } toggle-events-button`"
                @click.stop="
                    ($event) => {
                        {
                            $event.stopPropagation();
                            $event.preventDefault();
                            listOpened = !listOpened;
                        }
                    }
                "
            ></i>
            <div
                :class="
                    listOpened ? 'events-list events-list-open' : 'events-list'
                "
            >
                <GroupEventsInfoSingleEvent
                    v-for="ev in events"
                    :eventInfo="ev"
                    :key="ev.id"
                    class="group-single-event"
                    @groupEventClick="onGroupEventClick"
                />
            </div>
        </div>
    </div>
</template>

<script>
import GroupEventsInfoSingleEvent from './GroupEventsInfoSingleEvent.vue';

export default {
    props: ['events', 'ctx'],
    emits: ['groupEventClick'],
    components: { GroupEventsInfoSingleEvent },
    data() {
        return {
            listOpened: false,
            hover: false,
        };
    },
    methods: {
        onGroupEventClick(event) {
            this.$emit('groupEventClick', event, this.events);
        },
    },
};
</script>
<style>
.many-events-title {
    font-size: 12px;
    text-align: center;
    width: 100%;
}
.group-single-event {
    padding-bottom: 4px;
}
.events-list {
    background-color: white;
    overflow: hidden;
    display: none;
    border: 1px solid rgb(152, 162, 179);
    border-radius: 4px;
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    width: calc(100% + 2px);
    left: -1px;
    position: absolute;
    top: 100%;
    padding-top: 8px;
}
.events-list-open {
    height: auto;
    display: block;
}
.toggle-events-button {
    position: absolute;
    right: 4px;
    bottom: 0px;
    font-size: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}
.group-single-event:not(:last-of-type) {
    border-bottom: 1px solid rgb(152, 162, 179);
}
.group-events-title {
    height: 100%;
    display: flex;
}
.many-events-icon {
    display: flex;
    align-items: center;
    font-size: 18px;
    position: relative;
    justify-content: center;
    height: 100%;
}
</style>
