<template>
    <v-card style="padding: 0 !important">
        <EasyDataTable buttons-pagination
            :loading="loading"
            :items="takenTools"
            :headers="tableHeaders"
            :empty-message="$t('common.emptyTableMessage')"
            table-class-name="easy-table-style"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            @click-row="goToTool"
        >
            <template #item-id="item">{{ item?.equipment?.id }}</template>
            <template #item-name="item">{{ item?.equipment?.name }}</template>
            <template #item-created_at="item">{{
                new Date(item?.created_at).toLocaleString()
            }}</template>
            <template #item-predicted_return_at="item">{{
                item?.predicted_return_at
                    ? new Date(item?.predicted_return_at).toLocaleString([], {
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                      })
                    : '-'
            }}</template>
            <template #item-take_created_by_user="item">
                <router-link
                    @click="
                        ($event) => {
                            $event.stopPropagation();
                        }
                    "
                    :to="{
                        name: 'staff.show',
                        params: { id: item.take_created_by_user_id },
                    }"
                >
                    {{ item?.take_created_by_user?.name }}
                </router-link>
            </template>
        </EasyDataTable>
    </v-card>
</template>

<script>
import * as AUTH_STORE from 'signalo-vue-auth-store'
import * as DATA_STORE from 'signalo-vue-data-store';
export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, authStore };
    },
    props: ['id'],
    data() {
        return {
            loading: false,
            takenTools: [],
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.loading = true;
            try {
                const toolsData = await this.axios.get(
                    `users/${this.id}/equipment_take`
                );
                this.takenTools = toolsData?.data?.items || [];
            } catch (err) {
                if (err?.response?.status === 401) return;
            }
            this.loading = false;
        },
        goToTool(item) {
            if (this.authStore.isAllowed('tools', 'read')) {
                this.$router.push({
                    name: 'tools.show',
                    params: {
                        id: item.equipment.id,
                    },
                });
            }
        },
    },
    computed: {
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.id'),
                    value: 'id',
                    sortable: true,
                },
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                    sortable: true,
                },
                {
                    text: this.$t('common.takenAt'),
                    value: 'created_at',
                },
                {
                    text: this.$t('common.commentTake'),
                    value: 'comment_take',
                },
                {
                    text: this.$t('common.toolIssuedBy'),
                    value: 'take_created_by_user',
                },
                {
                    text: this.$t('common.predictedReturnDate'),
                    value: 'predicted_return_at',
                },
            ];
        },
    },
};
</script>
