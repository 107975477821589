<template>
    <SurveyGroupedAnswerTable
        v-if="
            field.type === $QUESTION_TYPES_ENUM.TEXT ||
            field.type === $QUESTION_TYPES_ENUM.LONGTEXT ||
            field.type === $QUESTION_TYPES_ENUM.PHOTO
        "
        :usersAnswers="field.usersAnswers"
        :field="field"
    />
    <SurveyGroupedAnswerChart v-else :field="field" />
</template>

<script>
import SurveyGroupedAnswerTable from './SurveyGroupedAnswerTable.vue';
import SurveyGroupedAnswerChart from './SurveyGroupedAnswerChart.vue';
export default {
    props: ['field'],
    components: { SurveyGroupedAnswerChart, SurveyGroupedAnswerTable },
};
</script>
