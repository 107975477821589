<template>
    <div v-if="disabled">
        <SignaloSignaturePreviewComponent :value="value"/>
    </div>
    <SignaloSignatureComponent
        v-else
        :value="value"
        ref="signatureComponent"
        @update:modelValue="(val) => (value = val)"
        :userId="userContext?.id"
        :username="userContext?.name"
    />
</template>

<script>

export default {
    props: ["field", "disabled", "userContext"],
    emits: ["answer"],
    data() {
        return {
            value: this.field?.answer || null,
            signatureValid: false,
        };
    },
    watch: {
        value(val) {
            this.$emit("answer", val);
        },
        signatureValid() {
            this.value = this.$refs.signatureComponent?.save() || null;
        },
    },
};
</script>

<style scoped>
.signature-img-container {
    height: 120px;
    width: 200px;
    border: 1px solid var(--ui-general-outline-01);
    box-sizing: content-box;
}
</style>
