<template>
    <SignaloAppBar>
        <template #title> {{ $t('common.configuration') }} </template>
    </SignaloAppBar>
    <v-main>
        <SignaloBreadCrumbs :data="breadcrumb" />
        <PageError v-if="!template" :error-message="$t('common.notFound')" />
        <EasyDataTable buttons-pagination
            :headers="tableHeaders"
            :items="groups"
            :empty-message="$t('common.emptyTableMessage')"
            :loading="loading"
            table-class-name="easy-table-style"
            :rows-per-page-message="$t('common.rowsPerPage')"
            :rows-of-page-separator-message="$t('common.rowsOfPage')"
            v-model:server-options="serverOptions"
            :server-items-length="serverItemsLength"
            :rows-items="[5, 10, 25, 50, 75, 100]"
            name="groupsIndexTable"
        >
            <template #item-name="item">
                {{ $getPropertyTranslation(item.name) }}
            </template>

            <template #item-operation="item">
                <div class="actions-column">
                    <v-checkbox
                        color="primary"
                        :modelValue="
                            !!assignedGroups.find((ag) => ag.id === item.id)
                        "
                        single-line
                        hide-details
                        @click="() => toggleAttachGroup(item.id)"
                    ></v-checkbox>
                </div>
            </template>
        </EasyDataTable>
    </v-main>
</template>

<script>
import PageError from '@/components/global/PageError.vue';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    data() {
        return {
            loading: true,
            template: null,
            groups: [],
            lastSearch: null,
            serverOptions: this.$getServerOptions('userTemplateGroupsTable'),
            serverItemsLength: 0,
            assignedGroups: [],
        };
    },
    mounted() {
        this.loadTemplate();
    },
    methods: {
        async loadTemplate() {
            if (!this.$route?.params?.id) throw new Error('no id provided');
            this.loading = true;
            try {
                const templateData = await this.axios.get(
                    `user_templates/${this.$route.params.id}`
                );
                this.template = templateData.data.item;
                this.assignedGroups = this.template.groups;
            } catch (err) {
                this.helpersStore.snackbarError(err);
            }
            this.loading = false;
            this.getGroups();
        },
        getGroups(search) {
            this.loading = true;
            if (search !== null) {
                this.lastSearch = search;
            }

            const config = this.dataStore.userTemplates.makeServerOptions(
                this.serverOptions,
                this.lastSearch
            );
            this.$router.replace({ query: config.params });
            return this.dataStore.groups
                .indexRequest(config)
                .then((x) => {
                    if (x.data?.status === 'ok') {
                        this.groups = Object.values(x.data.items);
                        this.serverItemsLength = x?.data?.meta?.total;
                    }
                })
                .catch((e) => {
                    if (e.response.status == 401) return;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async toggleAttachGroup(itemId) {
            this.loading = true;
            try {
                if (this.assignedGroups.find((ag) => ag.id == itemId)) {
                    await this.axios.delete(
                        `user_templates/${this.$route.params.id}/groups/${itemId}/detach`
                    );
                } else {
                    await this.axios.put(
                        `user_templates/${this.$route.params.id}/groups/${itemId}/attach`
                    );
                }
                await this.loadTemplate();
                this.helpersStore.snackbarSaved();
            } catch (err) {
                this.helpersStore.snackbarError(err);
            }
            this.loading = false;
        },
    },
    computed: {
        breadcrumb() {
            let res = {};
            if (this.template) {
                res.id = {
                    text: `#${
                        this.$route.params.id
                    } - ${this.$getPropertyTranslation(this.template.name)}`,
                    value: this.$route.params.id,
                };
            } else {
                res.id = {
                    text: `#${this.$route.params.id}`,
                    value: this.$route.params.id,
                };
            }
            return res;
        },
        tableHeaders() {
            return [
                {
                    text: this.$t('tableHeaders.name'),
                    value: 'name',
                    sortable: true,
                },
                { value: 'operation', width: 54, fixed: true },
            ];
        },
    },
    watch: {
        serverOptions(val) {
            this.authStore.patchTablePreferences('userTemplateGroupsTable', {
                default_size: val.rowsPerPage,
            });
            this.getGroups();
        },
    },
    components: { PageError },
};
</script>
