<template>
    <SurveyTextAnswer
        v-if="field.type === $QUESTION_TYPES_ENUM.TEXT"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.surveyPreview?.type !== 'statement'"
    />
    <SurveyCheckAnswer
        v-else-if="field.type === $QUESTION_TYPES_ENUM.CHECK"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.surveyPreview?.type !== 'statement'"
    />
    <SurveyRadioAnswer
        v-else-if="field.type === $QUESTION_TYPES_ENUM.RADIO"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.surveyPreview?.type !== 'statement'"
    />
    <SurveyRatingAnswer
        v-else-if="field.type === $QUESTION_TYPES_ENUM.RATING"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.surveyPreview?.type !== 'statement'"
    />
    <SurveyLongTextAnswer
        v-else-if="field.type === $QUESTION_TYPES_ENUM.LONGTEXT"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.surveyPreview?.type !== 'statement' || field?.disabled"
    />
    <SurveyNumberAnswer
        v-else-if="field.type === $QUESTION_TYPES_ENUM.NUMBER"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.surveyPreview?.type !== 'statement'"
    />

    <SurveyImageAnswer
        v-else-if="field.type === $QUESTION_TYPES_ENUM.PHOTO"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.surveyPreview?.type !== 'statement'"
    />

    <SurveyDocumentAnswer
        v-else-if="field.type === $QUESTION_TYPES_ENUM.DOCUMENT"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.surveyPreview?.type !== 'statement'"
    />

    <SurveySignatureAnswer
        v-else-if="field.type === $QUESTION_TYPES_ENUM.SIGNATURE"
        :field="field"
        @answer="onAnswer"
        :disabled="helpersStore.surveyPreview?.type !== 'statement'"
        :userContext="userContext"
    />


</template>

<script>
import SurveyCheckAnswer from './SurveyCheckAnswer.vue';
import SurveyRadioAnswer from './SurveyRadioAnswer.vue';
import SurveyTextAnswer from './SurveyTextAnswer.vue';
import SurveyRatingAnswer from './SurveyRatingAnswer.vue';
import SurveyLongTextAnswer from './SurveyLongTextAnswer.vue';
import SurveyNumberAnswer from './SurveyNumberAnswer.vue';
import SurveyImageAnswer from './SurveyImageAnswer.vue';
import SurveySignatureAnswer from './SurveySignatureAnswer.vue';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import SurveyDocumentAnswer from './SurveyDocumentAnswer.vue';

export default {
    setup() {
        const helpersStore = HELPERS_STORE.default();
        return { helpersStore };
    },
    props: ['field', 'userContext'],
    emits: ['answer'],
    components: {
    SurveyTextAnswer,
    SurveyCheckAnswer,
    SurveyRadioAnswer,
    SurveyRatingAnswer,
    SurveyLongTextAnswer,
    SurveyNumberAnswer,
    SurveyImageAnswer,
    SurveySignatureAnswer,
    SurveyDocumentAnswer
},
    methods: {
        onAnswer(val) {
            this.$emit('answer', val);
        },
    },
};
</script>
